import { createSelector } from 'reselect';

import { getInterventionById } from '../interventions/selectors';

/**
 * Created by lpostula on 12/05/17.
 */
const EMPTY_OBJECT = {};
const getMaintenanceEvents = (state) => state.maintenance_events;
const getMaintenanceEvent = (state, id) => state.maintenance_events[id];

const getMaintenanceEventsForAsset = (state, asset_id) => _.filter(
    state.maintenance_events,
    ((a) => a.asset === asset_id),
);

export const makeMaintenanceEventsForAsset = () => createSelector(
    [getMaintenanceEventsForAsset],
    (items) => {
        const out = {};
        _.map(items, (i) => { out[i.id] = i; });
        return out;
    },
);

export const makeOpenMaintenanceEventsForAsset = () => createSelector(
    [getMaintenanceEventsForAsset],
    (items) => {
        const out = {};
        const filtered_items = _.filter(items, (i) => i.state === 'open');
        _.map(filtered_items, (i) => { out[i.id] = i; });
        return out;
    },
);

export const makeCorrectiveMaintenanceEventsForAsset = () => createSelector(
    [getMaintenanceEventsForAsset],
    (items) => {
        const out = {};
        const filtered_items = _.filter(items, (i) => i.maintenance_type === 'corrective');
        _.map(filtered_items, (i) => { out[i.id] = i; });
        return out;
    },
);

export const makeMaintenanceEventsListForAsset = () => createSelector(
    [getMaintenanceEventsForAsset],
    (items) => _.values(items),
);

export const makeMaintenanceEventsForIntervention = () => createSelector(
    [getMaintenanceEvents, getInterventionById],
    (events, intervention) => {
        if (!intervention) return EMPTY_OBJECT;
        if (!Object.keys(events).length === 0) return EMPTY_OBJECT;
        const ieas = intervention.interventioneventaction;
        if (!ieas) return EMPTY_OBJECT;
        const event_ids = [];
        for (const iea of ieas) {
            if (typeof iea === 'number') {
                event_ids.push(iea);
            } else if (typeof iea === 'object') {
                if (iea.hasOwnProperty('event')) {
                    event_ids.push(iea.event);
                }
            }
        }
        return _.pick(events, event_ids);
    },
);

export default {
    getMaintenanceEvents,
    getMaintenanceEvent,
    getMaintenanceEventsForAsset,
    makeMaintenanceEventsForIntervention,
    makeOpenMaintenanceEventsForAsset,
    makeCorrectiveMaintenanceEventsForAsset,
    makeMaintenanceEventsForAsset,
    makeMaintenanceEventsListForAsset,
};
