import React from 'react';
import BinLogo from './bin.svg';

import './TrashButton.scss';

const TrashButton = (props) => (
    <div
        className={`trash-button ${props.className} ${props.disabled ? ' disabled' : ''} ${props.label ? ' labeled' : ''}`}
        onClick={!props.disabled ? props.onClick : () => null}
    >
        <img src={BinLogo} alt="clear logo bin" />
        {
            props.label && (
                <span className="trash-label">{props.label}</span>
            )
        }
    </div>
);

export default TrashButton;
