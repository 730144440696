import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { faultCategoriesOperations, faultCategoriesSelectors } from 'railfleet_state/ducks/fault_categories';
import { maintenanceEventsSelectors } from 'railfleet_state/ducks/maintenance_events';
import ReactSelectWrapper from '../ReactSelectWrapper';
import { StepContext } from '../step/Step';
import FormLabel from '../formLabel/FormLabel';
import './FormFaultCategory.scss';
import FaultCategorySelector from '../../../selectors/faultCategorySelector/FaultCategorySelector';

const FormFaultCategory = (props) => {
    const [_topn, setTopn] = useState(null);
    const [_topnRequestController, setTopnRequestController] = useState(null);
    const { errors } = useFormContext();
    const valueError = errors && errors[props.id];

    const stepContext = useContext(StepContext);

    useEffect(() => {
        if (stepContext && stepContext.addKey) stepContext.addKey(stepContext.step, props.id);
        props.fetchFaultCategories();
    }, []);

    useEffect(() => {
        async function load_topn() {
            const raw_filters = [];
            raw_filters.push(`event_ids=${props.event_id}`);
            raw_filters.push('topn=3');
            raw_filters.push('percent_cutoff=10');
            const filters = raw_filters.join('&');

            if (_topnRequestController) {
                _topnRequestController.abort();
            }

            const topnRequestController = new AbortController();
            setTopnRequestController(topnRequestController);

            if (!props.event_id) {
                setTopn({});
                return;
            }

            try {
                const { signal } = topnRequestController;
                const r = await fetch(`/api/v2/faultcategory/topn_suggested?${filters}`, { signal });
                const d = await r.json();
                setTopn(d && d[0]);
            } catch (e) {
                if (e.name !== 'AbortError') {
                    console.error(e);
                }
            }
        }
        if (props.event_id) {
            load_topn();
        }
    }, [props.fault_categories, props.event_id]);
    if (_.isEmpty(props.fault_categories)) return null;
    return (
        <div className={`form form_faultCategory ${props.className ? props.className : ''} ${props.id} ${!props.label ? 'no-label' : ''}${props.large ? ' large' : ''}`} key={props.id}>
            <FormLabel {...props} />
            <ReactSelectWrapper
                classname="faultCategory"
                // eslint-disable-next-line react/no-children-prop
                children={FaultCategorySelector}
                name={props.id}
                validate={(data) => data}
                maintenance_events={props.maintenance_events}
                raw_topn={_topn && !Array.isArray(_topn) ? [_topn] : _topn}
                event_id={props.event_id}
                {...props}
            />
            {valueError && (
                <div className="form-validation-error">
                    <div className="form-validation-error-arrow" />
                    <div className="form-error-message">
                        {
                            (valueError.message)
                                ? <div>{valueError.message}</div>
                                : <div>Please enter a value</div>
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const { event_id } = props;
    const { asset_id } = props;
    const event = event_id && maintenanceEventsSelectors.getMaintenanceEvent(state, event_id);
    let maintenance_events;
    if (event_id) {
        maintenance_events = [event];
    } else {
        maintenance_events = asset_id
            && maintenanceEventsSelectors.getMaintenanceEventsForAsset(state, props.asset_id);
    }
    return {
        maintenance_events: maintenance_events || [],
        fault_categories: faultCategoriesSelectors.getFaultCategoriesForCurrentAsset(
            state, asset_id,
        ),
        event_id: parseInt(event_id, 10),
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchFaultCategories: () => dispatch(faultCategoriesOperations.fetchList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormFaultCategory);
