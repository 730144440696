import React, { useEffect, useState } from 'react';
import map_components from 'asset_map/components';
import AssetMarker from 'asset_map/AssetMarker';
import 'asset_map/AssetMap.scss';
import './small_map.scss';

const { MapWrapper, Map, TileLayers } = map_components;

const NoMap = () => (
    <div style={{ height: '100%' }}>
        <Map center={[47.29, 4.04]} zoom={3}>
            <TileLayers
                position="topleft"
            />
        </Map>
    </div>
);

const AssetSmallMapClass = (props) => {
    const [, forceUpdate] = useState();
    useEffect(() => {
        if (props.asset) {
            // We need to force update when we change the asset
            // Used for board_book.js
            forceUpdate(null);
        }
    }, [props.asset]);
    const { live_data } = props.asset;
    let latitude = (live_data['position.latitude'] && live_data['position.latitude'][0]) || 0;
    let longitude = (live_data['position.longitude'] && live_data['position.longitude'][0]) || 0;
    latitude /= 1000000;
    longitude /= 1000000;

    if (latitude === 0) {
        return <NoMap />;
    }
    return (
        <div style={{ height: '100%' }}>
            {
                props.fixed
                    && (
                        <div className="fixed_map_tooltip">
                            <AssetMarker
                                asset={props.asset}
                                fixed={props.fixed}
                                opened
                            />
                        </div>
                    )
            }
            <Map center={[latitude, longitude]} zoom={13}>
                <TileLayers
                    position="topleft"
                />
                <AssetMarker
                    asset={props.asset}
                    hideTooltip={props.fixed}
                    opened
                />
            </Map>
        </div>
    );
};

const AssetSmallMap = (props) => (
    <MapWrapper>
        <AssetSmallMapClass {...props} />
    </MapWrapper>
);

export default AssetSmallMap;
