import React, { useEffect, useRef, useState } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import DateTimeSelector from '../dateTimeSelector/DateTimeSelector';

import { concatDateTimeFormats } from '../utils';
import { QUICK_RANGES_DETAILS } from '../../selectors/quick_ranges';

import './TimeRangeFilter.scss';
import WithPopper from '../../app/WithPopper';
import Button from '../../buttons/button/Button';

const CustomRange = (props) => {
    const [_from, setFrom] = useState(props.from);
    const [_valid_from, setValidFrom] = useState(true);
    const [_to, setTo] = useState(props.to);
    const [_valid_to, setValidTo] = useState(true);

    const onFromChange = ({ value, valid }) => {
        if (value !== _from) {
            setFrom(value);
            if (props.synchronous) props.onChangeFrom(value);
        }
        if (valid !== _valid_from) {
            setValidFrom(valid);
            if (props.synchronous) props.onValidityChange(valid && _valid_to);
        }
    };

    const onToChange = ({ value, valid }) => {
        if (value !== _to) {
            setTo(value);
            if (props.synchronous) props.onChangeTo(value);
        }
        if (valid !== _valid_to) {
            setValidTo(valid);
            if (props.synchronous) props.onValidityChange(valid && _valid_from);
        }
    };

    const onApply = () => {
        props.onApply(_from, _to);
    };

    return (
        <div className="custom_range">
            <div className="cr_title">{i18n.t('Custom range')}</div>
            <DateTimeSelector
                id="From"
                label="From"
                className="trs-datepicker-container"
                onChange={onFromChange}
                selected={_from}
                name="from"
                dateFormat={props.dateFormat}
                timeFormat={props.timeFormat}
                required={props.from_required}
            />
            <DateTimeSelector
                id="To"
                label="To"
                className="trs-datepicker-container"
                onChange={onToChange}
                selected={_to}
                minDate={_from}
                name="to"
                dateFormat={props.dateFormat}
                timeFormat={props.timeFormat}
                required={props.to_required}
            />
            {
                !props.synchronous
                && (
                    <div className="trs-apply-button">
                        <Button
                            onClick={onApply}
                            primary
                            label={i18n.t('Apply time range')}
                            disabled={!(_valid_from && _valid_to)}
                        />
                    </div>
                )
            }
        </div>
    );
};

CustomRange.propTypes = {
    from: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    timezone: PropTypes.string,
    onChangeFrom: PropTypes.func.isRequired,
    onChangeTo: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    onValidityChange: PropTypes.func,
    from_required: PropTypes.bool,
    to_required: PropTypes.bool,
    synchronous: PropTypes.bool,
};

CustomRange.defaultProps = {
    from: moment()
        .subtract(1, 'hours'),
    to: moment(),
    dateFormat: 'DD/MM/YYYY',
    timeFormat: 'HH:mm',
    timezone: moment.tz.guess(true),
    from_required: false,
    to_required: false,
    synchronous: true,
};

const SWITCH_QUICK_RANGE = 'QUICK-RANGE';
const SWITCH_CUSTOM_RANGE = 'CUSTOM-RANGE';

const TimeRangeFilter = (props) => {
    const [_display_form, setDisplayForm] = useState(false);
    const [_displayPopperReduced, setDisplayPopperReduced] = useState(false);
    const [_from, setFrom] = useState(moment());
    const [_to, setTo] = useState(moment());
    const [_valid, setValid] = useState(true);
    const [_quick_range, setQuickRange] = useState(null);
    const [_hover, setHover] = useState(false);
    const [_popperReducedSwitchState, setPopperReducedSwitchState] = useState(null);

    const onHover = () => {
        setHover(true);
    };

    const onBlur = () => {
        setHover(false);
    };

    const main_container = useRef(null);
    const button_container = useRef(null);

    const handleClickOutside = (event) => {
        if (!_display_form || !main_container.current) return;
        if (main_container.current && !main_container.current.contains(event.target)) {
            setDisplayForm(false);
        }
    };

    const setDates = (from, to) => {
        setFrom(from);
        setTo(to);
        props.onChange(from, to);
    };

    const onCustomRangeChange = (from, to) => {
        setDates(from, to);
        setQuickRange(null);
    };

    const onCustomRangeValidityChange = (valid) => {
        setValid(valid);
    };

    const onChangeQuickRange = (evt, range) => {
        evt.preventDefault();
        setQuickRange(range.id);
        const { from, to } = range.getter(range.params);
        setDates(from, to);
        setValid(true);
        setDisplayForm(false);
    };

    const getFieldLabel = () => (
        <div className="labels">
            <span className="field-label">{props.label ? props.label : i18n.t('Date range')}</span>
            {_quick_range
                ? <span className="field-label-quick-range">{_quick_range}</span> : null}
        </div>
    );

    const getDateLabel = (date) => {
        if (date) {
            return date.format(concatDateTimeFormats(props.dateFormat, props.timeFormat));
        }
        return 'Date not selected';
    };

    const getTimeRangeLabel = () => {
        if ((props.from_required || props.to_required) || (_from && _to)) {
            return [<span key="from-out" className="date-label">{getDateLabel(_from)}</span>,
                <span key="range-glyph" className="glyphicons glyphicons-arrow-right" />,
                <span key="to-out" className="date-label">{getDateLabel(_to)}</span>,
            ];
        }
        if (!_from && _to) {
            return [
                <span key="from-out" className="date-label">
                    {i18n.t('Everything before')}
                </span>,
                <span key="space" className="label-whitespace">&nbsp;</span>,
                <span key="to-out" className="date-label">
                    {getDateLabel(_to)}
                </span>,
            ];
        } if (_from && !_to) {
            return [
                <span key="from-out" className="date-label">
                    {i18n.t('Everything after')}
                </span>,
                <span key="space" className="label-whitespace">&nbsp;</span>,
                <span key="to-out" className="date-label">
                    {getDateLabel(_from)}
                </span>,
            ];
        }
        return <span key="out" className="date-label">{i18n.t('All')}</span>;
    };

    const renderQuickRange = () => (
        _.map(QUICK_RANGES_DETAILS, (group, index) => {
            const display_range = _.map(group, (value) => props.quickranges.includes(value.id));
            const groupIsRelevant = _.some(display_range);
            if (!groupIsRelevant) return (null);
            return (
                <div className="quick_range_group" key={index}>
                    {
                        _.map(_.zip(group, display_range), ([option, display], index2) => {
                            if (!display) return (null);
                            return (
                                <a
                                    href="#"
                                    onClick={(e) => (
                                        onChangeQuickRange(e, option)
                                    )}
                                    className={_quick_range === option.id ? 'active' : ''}
                                    key={index2}
                                >
                                    {option.label}
                                </a>
                            );
                        })
                    }
                </div>
            );
        })
    );

    const manageOverflow = (overflows) => {
        // there is no coming back to normal display once activated
        if (!_displayPopperReduced && overflows) {
            setDisplayPopperReduced(overflows);
            setPopperReducedSwitchState(SWITCH_QUICK_RANGE);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    useEffect(() => setFrom(props.from ? moment(props.from) : props.from), [props.from]);

    useEffect(() => setTo(props.to ? moment(props.to) : props.to), [props.to]);

    return (
        <div
            className={`timerangeselector_main ${props.reduced ? 'reduced' : ''}`}
            ref={main_container}
        >
            <WithPopper
                dontOpenOnHover
                openCallback={(r) => setDisplayForm(r)}
                extraClass="timerangeselector_popup"
                disabled={props.disabled}
                visible={_display_form}
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 5],
                        },
                    },
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: ['bottom', 'right', 'left', 'top'],
                        },
                    },
                ]}
                placement={props.openDirection}
                popperOverflow={manageOverflow}
            >
                <div
                    className={`timerangeselector_display_block ${!_valid ? 'has-error' : ''} ${_display_form ? 'focus' : ''} ${_hover ? 'hover' : ''} ${props.disabled ? 'disabled' : ''} ${props.noLabel ? 'inline' : ''}`}
                    ref={button_container}
                    onMouseEnter={onHover}
                    onMouseLeave={onBlur}
                >
                    <div className="timerangeselector_display_holder">
                        {!props.noLabel && getFieldLabel()}
                        <div className="dates">
                            {getTimeRangeLabel()}
                        </div>
                    </div>
                    {!props.noLabel
                    && (
                        <>
                            <span className="separator" />
                            <div className="timerangeselector_icon">
                                <span className="glyphicon glyphicon-calendar" />
                            </div>
                        </>
                    )}
                </div>
                <div className="timerangeselector_form_holder">
                    <div className={`flex-in ${_displayPopperReduced ? 'reduced-popper' : ''}`}>
                        {_displayPopperReduced
                        && (
                            <div className="switch">
                                <div
                                    className={`switch-element ${_popperReducedSwitchState === SWITCH_QUICK_RANGE ? 'selected' : ''}`}
                                    onClick={() => setPopperReducedSwitchState(SWITCH_QUICK_RANGE)}
                                >
                                    Quick Ranges
                                </div>
                                <div
                                    className={`switch-element ${_popperReducedSwitchState === SWITCH_CUSTOM_RANGE ? 'selected' : ''}`}
                                    onClick={() => setPopperReducedSwitchState(SWITCH_CUSTOM_RANGE)}
                                >
                                    Custom Ranges
                                </div>
                            </div>
                        )}
                        {(!_displayPopperReduced
                            || _popperReducedSwitchState === SWITCH_CUSTOM_RANGE)
                            && (
                                <CustomRange
                                    from={_from}
                                    to={_to}
                                    onChangeFrom={(from) => onCustomRangeChange(from, _to)}
                                    onChangeTo={(to) => onCustomRangeChange(_from, to)}
                                    onApply={(from, to) => {
                                        onCustomRangeChange(from, to);
                                        setDisplayForm(false);
                                    }}
                                    onValidityChange={onCustomRangeValidityChange}
                                    dateFormat={props.dateFormat}
                                    timeFormat={props.timeFormat}
                                    from_required={props.from_required}
                                    to_required={props.to_required}
                                    synchronous={props.synchronous}
                                />
                            )}
                        {(props.quickranges && (!_displayPopperReduced
                            || _popperReducedSwitchState === SWITCH_QUICK_RANGE))
                            && (
                                <div className="quick_range">
                                    <div className="qr_title">{i18n.t('Quick ranges')}</div>
                                    <div className="range_options">
                                        {renderQuickRange()}
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </WithPopper>
        </div>
    );
};

TimeRangeFilter.propTypes = {
    from: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    timezone: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    quickranges: PropTypes.array,
    reduced: PropTypes.bool,
    from_required: PropTypes.bool,
    to_required: PropTypes.bool,
    disabled: PropTypes.bool,
    synchronous: PropTypes.bool,
    opensOnThe: PropTypes.string,
    noLabel: PropTypes.bool,
};

TimeRangeFilter.defaultProps = {
    from: moment()
        .subtract(1, 'hours'),
    to: moment(),
    dateFormat: 'DD/MM/YYYY',
    timeFormat: 'HH:mm',
    timezone: moment.tz.guess(true),
    quickranges: null,
    reduced: false,
    from_required: false,
    to_required: false,
    disabled: false,
    synchronous: true,
    opensOnThe: null,
    noLabel: false,
};

export default TimeRangeFilter;
