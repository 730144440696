import React from 'react';
import { connect } from 'react-redux';
import './menu_product.css';
import useCurrentPage from '../../hooks/useCurrentPage';
import { usersSelectors } from '../../../../state/ducks/users';

function MenuItem(props) {
    if (props.visible === false) return null;
    return (
        <div
            className={`menu-item ${props.selected ? props.product : ''}-color`}
            key={`${props.id}}`}
        >
            { props.selected ? (
                <div className="arrow-wrapper">
                    <span className="glyphicon glyphicon-chevron-right" />
                </div>
            ) : null}
            <a href={props.url} alt={props.title} onClick={props.onClick}>
                {props.title}
            </a>
            {props.beta && <span className="box beta-box">BETA</span>}
            {props.new && <span className="box new-box">NEW</span>}
        </div>
    );
}

function MenuGroup(props) {
    const current_page = useCurrentPage(props.all_pages);
    return (
        <div className="menu-group">
            {props.pages.map((page) => (
                <MenuItem
                    id={page.id}
                    key={`${page.id}_${page.title.split(' ').join('_')}`}
                    title={page.title}
                    url={page.url}
                    selected={page.title === current_page}
                    new={page.new}
                    beta={page.beta}
                    product={props.product}
                    visible={page.visible}
                    onClick={props.onClick}
                >
                </MenuItem>
            ))}
        </div>
    );
}

const MenuProduct = ({
    category, logo, onClick, all_pages,
}) => (
    <div className={`menu-product ${category.id}`} key={`menu-product-${category.id}`}>
        <div className="menu-header">
            <img className="menu-logo" src={logo} alt={`${category.id} logo`} />
            <div className="menu-text-wrapper">
                <div className={`menu-title ${category.id}-color`}>{category.id}</div>
                <div className="menu-subtitle">{category.title}</div>
            </div>
        </div>
        <MenuGroup
            pages={category.pages}
            product={category.id}
            onClick={onClick}
            all_pages={all_pages}
        />
    </div>
);

const mapStateToProps = (state) => ({
    all_pages: usersSelectors.getCurrentUserPage(state),
});

export default connect(mapStateToProps, null)(MenuProduct);
