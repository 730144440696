import React from 'react';
import i18n from 'i18next';
import default_image from '../../img/default_image.png';
import RenderNoData from './RenderNoData';
import CutForm from '../cutForm/CutForm';
import excel_image from '../../img/excel_image.png';
import word_image from '../../img/word_image.png';
import pdf_image from '../../img/pdf_image.png';

const file_type_mapping = [
    {
        extensions: ['xls', 'xlsx', 'csv'],
        image: excel_image,
    },
    {
        extensions: ['doc', 'docx', 'txt'],
        image: word_image,
    },
    {
        extensions: ['pdf'],
        image: pdf_image,
    },
    {
        extensions: ['png', 'jpg', 'jpeg', 'gif'],
    },
];

const RenderDocuments = ({
    label, data, documents_for_event, device,
}) => {
    if (!data || data.length === 0) {
        return (
            <RenderNoData
                label={label}
            />
        );
    }
    const documents = _.map(data, (file_id) => {
        const file = documents_for_event[file_id];
        if (file !== undefined && file !== null && file.file_url !== null) {
            let file_img = default_image;
            const file_extension = file.file_url.split('.')
                .slice(-1)[0].split('?')[0];
            for (const cand of file_type_mapping) {
                if (file_extension !== undefined
                    && file_extension !== null
                    && _.includes(cand.extensions, file_extension.toLowerCase())
                ) {
                    if (cand.image) {
                        file_img = cand.image;
                    } else {
                        file_img = file.file_url;
                    }
                    break;
                }
            }
            return (
                <div
                    key={`file_${file_id}`}
                    onClick={(evt) => evt.stopPropagation()}
                >
                    <div className="thumbnail">
                        <a href={file.file_url} download={file.file_name}>
                            <img src={file_img} alt="" />
                        </a>
                        <div className="caption row">
                            <div className="col-xs-12 small text-muted text-center">
                                {file.file_name}
                                {' ● '}
                                <a
                                    className="caption-link"
                                    href={file.file_url}
                                    download={file.file_name}
                                >
                                    {i18n.t('Download')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    });
    return (
        <div className="element-holder">
            <div className="element-label">{label}</div>
            <div className="data-documents">
                <CutForm
                    device={device}
                >
                    { documents }
                </CutForm>
            </div>
        </div>
    );
};

export default RenderDocuments;
