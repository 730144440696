import React from 'react';
import Selector from '../../selectors/selector/Selector';

const selectorDarkStyle = {
    control: {
        fontSize: 13,
        fontFamily: 'Roboto',
        backgroundColor: 'rgba(227, 232, 239, 0.2)',
        borderColor: 'rgba(193, 200, 211, 0.5)',
        '&:hover': {
            borderColor: '#c1c8d3',
        },
        focus: {
            borderColor: '#c1c8d3',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
        },
        disabled: {
            backgroundColor: 'rgba(227, 232, 239, 0)',
            borderColor: 'rgba(193, 200, 211, 0.3)',
        },
        error: {
            background: 'rgba(255, 62, 98, 0.2)',
            borderColor: '#FF3E62',
        },
    },
    input: {
        color: '#ffffff',
        padding: 0,
        margin: 0,
        fontWeight: 400,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '25px',
        height: 26,
        letterSpacing: 'normal',
    },
    placeholder: {
        fontWeight: 400,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '25px',
        height: 26,
        letterSpacing: 'normal',
        color: '#ffffff',
        opacity: 0.7,
        top: null,
        WebkitTransform: 'translateY(0%)',
        msTransform: 'translateY(0%)',
        transform: 'translateY(0%)',
        focus: {
            opacity: 0.9,
        },
        disabled: {
            opacity: 0.3,
        },
    },
    dropdownIndicator: {
        color: 'rgba(255, 255, 255, 0.6)',
        focus: {
            color: 'rgba(255, 255, 255, 1)',
        },
        '&:hover': {
            color: 'rgba(255, 255, 255, 0.8)',
        },
        disabled: {
            color: 'rgba(255, 255, 255, 0.3)',
        },
    },
    clearIndicator: {
        paddingLeft: 2,
        color: 'rgba(255, 255, 255, 0.6)',
        focus: {
            color: 'rgba(255, 255, 255, 1)',
        },
        '&:hover': {
            color: 'rgba(255, 255, 255, 0.8)',
        },
        disabled: {
            color: 'rgba(255, 255, 255, 0.3)',
        },
    },
    indicatorSeparator: {
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        focus: {
            backgroundColor: 'rgba(255, 255, 255, 1)',
        },
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
        },
        disabled: {
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
        },
    },
    multiValue: {
        backgroundColor: '#c7e3f6',
        fontFamily: 'Roboto',
        disabled: {
            opacity: 0.3,
        },
    },
    singleValue: {
        color: '#ffffff',
        borderRadius: 2,
        fontFamily: 'Roboto',
        position: 'static',
        top: 0,
        WebkitTransform: 'translateY(0%)',
        msTransform: 'translateY(0%)',
        transform: 'translateY(0%)',
        textAlign: 'left',
    },
    multiValueLabel: {
        color: '#027aff',
        fontWeight: 500,
        textAlign: 'left',
    },
    multiValueRemove: {
        color: '#027aff',
        fontWeight: 500,
    },
    indicatorsContainer: {},
    valueContainer: {
        padding: '2px 4px 2px 8px',
    },
    menu: {
        marginTop: 4,
        borderRadius: 3,
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.3)',
        border: 'solid 1px rgba(0,0,0,0.6)',
    },
    inlineLabel: {
        fontFamily: 'Roboto',
        fontSize: 10,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#ffffff',
        height: 11,
        focus: {
            opacity: 0.5,
        },
        disabled: {
            opacity: 0.3,
        },
    },
    option: {
        whiteSpace: 'normal',
    },
};

const selectorLightStyle = {
    ...selectorDarkStyle,
    inlineLabel: {
        ...selectorDarkStyle.inlineLabel,
        color: '#000000',
        disabled: {
            ...selectorDarkStyle.inlineLabel.disabled,
            backgroundColor: 'rgb(197,208,222)',
            color: 'rgb(128,139,153)',
            opacity: 1,
        },
    },
    input: {
        ...selectorDarkStyle.input,
        color: '#000000',
    },
    singleValue: {
        ...selectorDarkStyle.singleValue,
        color: '#000000',
        disabled: {
            ...selectorDarkStyle.singleValue.disabled,
            color: '#000000',
            fontWeight: 500,
        },
    },
    placeholder: {
        ...selectorDarkStyle.placeholder,
        color: '#000000',
    },
    control: {
        ...selectorDarkStyle.control,
        backgroundColor: '#ffffff',
        borderColor: '#000000',
        '&:hover': {
            ...selectorDarkStyle.control['&:hover'],
            borderColor: '#000000',
        },
        focus: {
            ...selectorDarkStyle.control.focus,
            borderColor: '#000000',
            backgroundColor: '#ffffff',
        },
        disabled: {
            ...selectorDarkStyle.control.disabled,
            borderColor: 'rgb(136,147,161)',
            opacity: '1',
            backgroundColor: 'rgb(197,208,222)',
        },
        error: {
            background: '#FFECEF',
            borderColor: '#FF3E62',
        },
    },
    clearIndicator: {
        ...selectorDarkStyle.clearIndicator,
        color: 'rgba(0, 0, 0, 0.7)',
        '&:hover': {
            ...selectorDarkStyle.control['&:hover'],
            color: 'rgba(0, 0, 0, 0.8)',
        },
        focus: {
            ...selectorDarkStyle.clearIndicator.focus,
            color: 'rgba(0, 0, 0, 1)',
        },
        disabled: {
            ...selectorDarkStyle.clearIndicator.disabled,
            color: 'rgba(0, 0, 0, 0.3)',
        },
    },
    indicatorSeparator: {
        ...selectorDarkStyle.indicatorSeparator,
        backgroundColor: '#adbdd1',
        '&:hover': {
            ...selectorDarkStyle.control['&:hover'],
            backgroundColor: '#adbdd1',
        },
        focus: {
            ...selectorDarkStyle.indicatorSeparator.focus,
            backgroundColor: '#adbdd1',
        },
        disabled: {
            ...selectorDarkStyle.indicatorSeparator.disabled,
            backgroundColor: '#adbdd1',
        },
    },
    dropdownIndicator: {
        ...selectorDarkStyle.dropdownIndicator,
        color: 'rgba(0, 0, 0, 0.7)',
        '&:hover': {
            ...selectorDarkStyle.dropdownIndicator['&:hover'],
            color: 'rgba(0, 0, 0, 0.8)',
        },
        focus: {
            ...selectorDarkStyle.dropdownIndicator.focus,
            color: 'rgba(0, 0, 0, 1)',
        },
        disabled: {
            ...selectorDarkStyle.dropdownIndicator.disabled,
            color: '#000000',
            fontWeight: 500,
        },
    },
};

const formLightStyle = {
    ...selectorLightStyle,
    control: {
        ...selectorLightStyle.control,
        borderColor: '#717b8c',
        disabled: {
            ...selectorLightStyle.control.disabled,
            borderColor: '#717B8C',
            opacity: '0.3',
            backgroundColor: '#FFFFFF',
        },
    },
    placeholder: {
        ...selectorLightStyle.placeholder,
        opacity: 0.5,
    },
    singleValue: {
        ...selectorLightStyle.singleValue,
        disabled: {
            ...selectorLightStyle.singleValue.disabled,
            color: '#000000',
            opacity: '0.3',
        },
    },
};

export const THEMES = {
    FORM_LIGHT: formLightStyle,
    LIGHT: selectorLightStyle,
    DARK: selectorDarkStyle,
};

// FLO:
// this is necessary as numbers with spaces (193 186) will be reversed in order by rtl but not if
// it contains text.
// Inserting the unicode character for spaces forces direction:rtl to display this as all other text
// thanks css consistency
const replaceSpaceForLeftEllipsis = (f, item) => (
    f(item)?.replace(' ', '\u00A0')
);

// since we introduce unicode character we need to replace them back in the filter function
// by default we can only revert this on arbitrary elements of option so we apply to default label
const replaceUnicodeForLeftEllipsis = (f, option, input) => {
    const new_label = { ...option };
    if (new_label.hasOwnProperty('label')) {
        new_label.label = option.label.replace('\u00A0', ' ');
    }
    return f(new_label, input);
};

// we provide default filtering function (see Selector _filterOption) should be equal
const _filterOption = (option, input) => (
    option.label.toLowerCase()
        .includes(input.toLowerCase())
);

const SelectFilter = (props) => {
    const style = props.theme ? props.theme : THEMES.DARK;
    if (props.style && !props.theme) {
        for (const key in props.style) {
            const elm = style[key];
            const newElm = { ...elm, ...props.style[key] };
            style[key] = newElm;
        }
    }
    return (
        <Selector
            {...props}
            style={style}
            labelizer={(item) => replaceSpaceForLeftEllipsis(props.labelizer, item)}
            filterOption={(o, i) => replaceUnicodeForLeftEllipsis(props.filterOption, o, i)}
        />
    );
};

SelectFilter.defaultProps = {
    labelizer(item) {
        return item.label;
    }, // same as Selector must provide default for replaceSpaceForLeftEllipsis
    filterOption: _filterOption,
};

export default SelectFilter;
