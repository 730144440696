import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import { uxSelectors, uxOperations } from 'railfleet_state/ducks/ux';
import components from 'components';
import './close_preventive_event.scss';
import CloseEventForm from '../../../../railfleet/lib/compounds/forms/closeEventForm/CloseEventForm';

const { MultiDeviceLayout } = components.app;
const { HeaderModal } = components.headers;

const ClosePreventiveEventComponent = (props) => {
    const [_pushInfos, setPushInfos] = useState(null);

    useEffect(() => {
        setPushInfos(props.push_infos);
        props.setPushInfos(null, 'close preventive');
    }, []);

    const closeCallback = (event) => {
        if (_pushInfos && _pushInfos.from) {
            window.location.href = _pushInfos.from;
        } else {
            props.router.push(
                `/asset/${props.asset_id}/events/${event.id}/view`,
            );
        }
    };

    const onClickPrevious = () => {
        if (_pushInfos && _pushInfos.from) {
            window.location.href = _pushInfos.from;
        } else {
            props.router.push(
                `/asset/${props.asset_id}/events/${props.event_id}/view`,
            );
        }
    };

    return (
        <HeaderModal
            title={i18n.t('Preventive event')}
            subtitle={i18n.t('Close')}
            onCloseModal={props.onCloseModal}
            previous_link={props.onClickPrevious || onClickPrevious}
        >
            <CloseEventForm
                asset_id={props.asset_id}
                event_id={props.event_id}
                callback={props.closeCallback || closeCallback}
                textSubmit={i18n.t('Close preventive event')}
                standalone
            />
        </HeaderModal>
    );
};

const ClosePreventiveEvent = (props) => (
    <MultiDeviceLayout>
        <ClosePreventiveEventComponent {...props} />
    </MultiDeviceLayout>
);

const mapStateToProps = (state) => ({
    push_infos: uxSelectors.getPushInfos(state),
});

const mapDispatchToProps = (dispatch) => ({
    setPushInfos: (push_infos, from = null) => dispatch(uxOperations.setPushInfos(push_infos, from)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClosePreventiveEvent);
