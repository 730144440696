/**
 * Created by gknuts on 27/09/18.
 */

export const getTelematics = (state) => {
    let telematics = [];
    if (state.telematics.telematics.name !== 'RequestError') {
        telematics = state.telematics.telematics;
    }
    return telematics;
};
export const getAdvancedKey = (state) => state.telematics.advanced_key;

export default {
    getTelematics,
    getAdvancedKey,
};
