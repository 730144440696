import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { faultCategoriesOperations, faultCategoriesSelectors } from 'railfleet_state/ducks/fault_categories';
import TextDisplay from '../../../display/textDisplay/TextDisplay';

const RenderFaultCategory = (props) => {
    useEffect(() => {
        props.fetchFaultCategories();
    }, []);

    return (
        <TextDisplay
            key="fault_category"
            label={props.label}
            data={props.fault_category_name || (props.fault_category && props.fault_category.name)}
        />
    );
};

const mapStateToProps = (state, props) => {
    if (props.loginless) return props.mapStateToProps(state, props);
    const { fault_category_id } = props;
    const fault_category = fault_category_id
        && faultCategoriesSelectors.getFaultCategory(state, fault_category_id);
    const fault_category_name = fault_category && fault_category.name;
    return {
        fault_category_name,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    if (props.loginless) return props.mapDispatchToProps(dispatch, props);
    return ({
        fetchFaultCategories: () => dispatch(faultCategoriesOperations.fetchList()),
    });
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderFaultCategory);
