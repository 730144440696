/**
 * Created by lpostula on 11/05/17.
 */
import types from './types';
import { createReducer } from '../../utils';

/* State shape
{
    {company_id: company},
}
 */

const initialState = {};

const listReducer = createReducer(initialState)({
    [types.FETCH_LIST_COMPLETED]: (state, action) => {
        const new_state = { ...state };
        if (!action?.payload) return new_state;
        for (const company of action.payload) {
            new_state[company.id] = company;
        }
        return new_state;
    },
});

export default listReducer;
