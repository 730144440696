/**
 * Created by lpostula on 17/05/18.
 */
const getDocuments = (state) => state.campaign_documents;

export const getDocumentsForCampaign = (state, campaign_id) => {
    if (campaign_id === undefined || campaign_id === null) {
        return [];
    }
    const campaign = state.campaigns[campaign_id];
    if (campaign) {
        return _.pick(state.campaign_documents, campaign.documents);
    }
    return [];
};

export default {
    getDocuments,
    getDocumentsForCampaign,
};
