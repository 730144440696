/**
 * Created by lpostula on 18/05/17.
 */
import { default as actions } from './actions';

export const { fetchList } = actions;
export const { fetchForEvent } = actions;
export const { updateDocument } = actions;
export const { createDocument } = actions;
export const { deleteDocument } = actions;
export const { setDocumentInStore } = actions;

export default {
    fetchList,
    fetchForEvent,
    updateDocument,
    createDocument,
    deleteDocument,
    setDocumentInStore,
};
