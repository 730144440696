import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useFormContext } from 'react-hook-form';

import './StepManager.scss';
import Button from '../../buttons/button/Button';
import TextAction from '../../buttons/textAction/TextAction';
import MultiDeviceLayout, { LAYOUT } from '../../app/MultiDeviceLayout';

const StepManagerComponent = (props) => {
    const { trigger, clearErrors, setDisplayCreate } = useFormContext();
    const [_keys, setKeys] = useState({});
    const [_currentStep, setCurrentStep] = useState(1);
    const [_steps, setSteps] = useState({});
    const isLastStep = () => {
        const lastStep = Object.keys(_keys).length;
        return _currentStep === lastStep;
    };

    const isFirstStep = () => _currentStep === 1;

    useEffect(() => {
        if (props.disabled) setDisplayCreate(true);
        else if (props.textSubmitButton) setDisplayCreate(false);
        else if (isLastStep()) setDisplayCreate(true);
        else setDisplayCreate(false);
    }, [_currentStep, props.disabled, _keys]);

    useEffect(() => {
        for (const child of props.children) {
            const stepName = child.props.name;
            const stepId = child.props.id;
            setSteps((prevSteps) => {
                const newSteps = { ...prevSteps };
                if (!newSteps[stepId]) {
                    newSteps[stepId] = {};
                }
                newSteps[stepId].id = stepId;
                newSteps[stepId].name = stepName;
                newSteps[stepId].index = stepId - 1;
                return newSteps;
            });
        }
        if (props.debug) {
            if (props.debug.step) setCurrentStep(props.debug.step);
        }
    }, []);

    const addKey = (step, key) => {
        setKeys((prevKeys) => {
            const newKeys = { ...prevKeys };
            if (!newKeys[step]) newKeys[step] = [];
            if (!newKeys[step].includes(key)) {
                newKeys[step].push(key);
            }
            return newKeys;
        });
    };

    const deleteKey = (step, key) => {
        setKeys((prevKeys) => {
            const temp = [];
            const newKeys = { ...prevKeys };
            if (!newKeys[step]) newKeys[step] = [];
            for (const _key of newKeys[step]) {
                if (_key !== key) {
                    temp.push(_key);
                }
            }
            newKeys[step] = temp;
            return newKeys;
        });
    };

    const onClickNext = async () => {
        const ids_current_step = _keys[_currentStep];
        let cpt_errors = 0;
        for (const id of ids_current_step) {
            // eslint-disable-next-line no-loop-func, no-await-in-loop
            await trigger(id).then((d) => {
                if (!d) cpt_errors += 1;
            });
        }
        if (cpt_errors === 0) {
            clearErrors();
            setCurrentStep(_currentStep + 1);
        }
    };

    const onClickPrevious = async (step = null) => {
        clearErrors();
        if (step) {
            setCurrentStep(step);
        } else {
            setCurrentStep(_currentStep - 1);
        }
    };

    const hasData = (id) => Object.keys(_keys).includes(`${id}`);
    const renderStepHeader = () => {
        const stepsLeft = Object.keys(_steps).length - _currentStep;
        return (
            <div className={`step-manager-header ${props.device} ${props.vertical ? 'vertical' : ''}`} key="step-header">
                { props.additionalInfos && props.additionalInfos() }
                <div className="step-manager-holder">
                    <div className="step-manager-bar" />
                    <div>
                        {
                            Object.keys(_steps).map((key) => {
                                const { id } = _steps[key];
                                const { name } = _steps[key];
                                const { index } = _steps[key];
                                if (!hasData(id)) return null;
                                return (
                                    <div className={`step-manager-elm ${index + 1 === _currentStep && !props.confirmationView ? 'active' : ''}`} key={name}>
                                        <div className="step-manager-bullet" />
                                        <div
                                            className={`${index + 1 < _currentStep ? 'previous-step' : ''}`}
                                            onClick={() => {
                                                if (index + 1 < _currentStep) {
                                                    return onClickPrevious(index + 1);
                                                }
                                                return null;
                                            }}
                                        >
                                            { name }
                                        </div>
                                    </div>
                                );
                            })
                        }
                        {
                            props.confirmationView !== undefined && (
                                <div className={`step-manager-elm ${props.confirmationView ? 'active' : ''}`} key="confirmation-view">
                                    <div className="step-manager-bullet" />
                                    <div>
                                        Confirmation
                                    </div>
                                </div>
                            )
                        }
                        {
                            (props.device === LAYOUT.MOBILE && stepsLeft > 0)
                            && (
                                <div className="step-manager-elm com">
                                    <div className="step-manager-bullet" />
                                    <div>{`+${stepsLeft} ${i18n.t('steps')}`}</div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    };

    if (props.disabled) {
        return props.children;
    }
    if (!Array.isArray(props.children) && _currentStep === 1) {
        return props.children;
    }

    const stepHeader = renderStepHeader();
    const ret = [];
    ret.push(stepHeader);

    const childrens = props.children.map((child, index) => {
        if (index + 1 === _currentStep && !props.confirmationView) {
            return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index}>
                    { React.cloneElement(child, { addKey, deleteKey }) }
                    <div className="navigation-buttons">
                        {
                            props.onCancel
                                && (
                                    <TextAction
                                        onClick={props.onCancel}
                                        text={i18n.t('Cancel')}
                                    />
                                )
                        }
                        {
                            props.previousIsText ? (
                                <TextAction
                                    text={i18n.t('Previous')}
                                    onClick={() => onClickPrevious()}
                                    disabled={isFirstStep()}
                                />
                            ) : (
                                <Button
                                    secondary
                                    label={i18n.t('Previous')}
                                    onClick={() => onClickPrevious()}
                                    disabled={isFirstStep()}
                                />
                            )
                        }
                        {
                            !isLastStep()
                                && (
                                    <Button
                                        primary
                                        label={i18n.t('Next')}
                                        onClick={onClickNext}
                                    />
                                )
                        }
                        {
                            isLastStep() && props.textSubmitButton
                                && (
                                    <Button
                                        primary
                                        submitButton
                                        label={props.textSubmitButton}
                                        disabled={props.disabled}
                                    />
                                )
                        }
                    </div>
                </div>
            );
        }
        return (
            // eslint-disable-next-line react/no-array-index-key
            <div className="step not-allowed" key={index}>
                { React.cloneElement(child, { addKey }) }
            </div>
        );
    });

    ret.push(childrens);
    return ret;
};

const StepManager = (props) => (
    <MultiDeviceLayout>
        <StepManagerComponent {...props} />
    </MultiDeviceLayout>
);

export default StepManager;
