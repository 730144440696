import React from 'react';
import i18n from 'i18next';
import './LoadingOverlay.scss';

const Loader = () => (
    <div className="loading-holder">
        <div className="spinner-rolling">
            <div className="spinner">
                <div className="outer" />
                <div className="inner" />
            </div>
        </div>
    </div>
);

const LoadingOverlay = (props) => {
    if (props.loaded) return null;
    return (
        <div className="loading-overlay">
            <div className="loader-block">
                <Loader />
                <span>{ props.text || i18n.t('LOADING') }</span>
            </div>
        </div>
    );
};

LoadingOverlay.defaultProps = {
    loaded: false,
};

export default LoadingOverlay;
