import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import i18n from 'i18next';
import usePost from 'components/hooks/usePost';
import Button from 'components/buttons/button/Button';
import useFetch from 'components/hooks/useFetch';
import Loader from 'components/animations/loader/Loader';
import Warning from './warning.svg';
import Outside from './outside.svg';
import Confirm from './confirm.svg';
import Deny from './deny.svg';
import './DuplicateSummary.scss';

const DuplicateSummary = (props) => {
    const [_events, setEvents] = useState({});
    const [_candidates, setCandidates] = useState(null);
    const { postAll } = usePost();
    const { fetchAll } = useFetch();

    useEffect(() => {
        if (!_.isEmpty(props.candidates) && !_.isEqual(props.candidates, _candidates)) {
            setCandidates(props.candidates);
            fetchAll('/api/v2/maintenance.json', {
                ids: props.candidates,
            }).then((d) => {
                if (d) {
                    const events = {};
                    for (const event of d) {
                        events[event.id] = event;
                    }
                    setEvents(events);
                }
            });
        }
    }, [props.candidates]);

    const redirect = (event_id) => {
        const isPlanning = window.location.hash.includes('planning');
        let url = '/operational/#/';
        if (isPlanning) {
            url = `${url}planning/asset/${props.asset_id}/events/${event_id}/view`;
        } else {
            url = `${url}asset/${props.asset_id}/events/${event_id}/view`;
        }
        window.open(url, '_blank', 'noreferrer');
    };
    const confirm = (event_id) => {
        postAll('/api/v2/maintenance/status_duplicate/', {
            event_1: props.event_1,
            event_2: event_id,
            status: 'confirmed_duplicate',
        }).then((d) => {
            if (d) {
                props.onChangeStatus();
            }
        });
    };
    const deny = (event_id) => {
        postAll('/api/v2/maintenance/status_duplicate/', {
            event_1: props.event_1,
            event_2: event_id,
            status: 'confirmed_non_duplicate',
        }).then((d) => {
            if (d) {
                props.onChangeStatus();
            }
        });
    };

    const SimilarCandidate = ({ event_id }) => (
        <div
            className="similar-opened-issue"
            key={event_id}
        >
            <div className="similar-opened-issue-header">
                <div>{i18n.t('Similar opened issue')}</div>
                <div
                    className="outside-link"
                    onClick={() => redirect(event_id)}
                >
                    <div>{`M${event_id}`}</div>
                    <img src={Outside} alt="outside link" />
                </div>
                <div>{`(${i18n.t('on')} ${moment(_events[event_id]?.date_incident).format('DD/MM/YYYY, HH:mm')}):`}</div>
            </div>
            <div className="similar-opened-issue-body">
                <div className="similar-opened-issue-description">
                    { _events[event_id]?.description}
                </div>
                <div className="similar-opened-issue-actions">
                    <Button
                        label={i18n.t('Link as duplicate')}
                        onClick={() => confirm(event_id)}
                        icon={Confirm}
                        primary
                    />
                    <Button
                        label={i18n.t('This is not a duplicate')}
                        onClick={() => deny(event_id)}
                        icon={Deny}
                        primary
                    />
                </div>
            </div>
        </div>
    );

    const SimilarOpenedIssues = () => {
        const elements = props.candidates.map((candidate) => {
            if (parseInt(candidate, 10) === parseInt(props.event_1, 10)) return null;
            return (
                <SimilarCandidate
                    event_id={candidate}
                />
            );
        });
        return (
            <div className="similar-opened-issues">
                {elements}
            </div>
        );
    };

    if (_.isEmpty(props.candidates)) return null;

    if (_.isEmpty(_events)) {
        return (
            <Loader />
        );
    }

    return (
        <div className="duplicate-summary-holder">
            <div className="duplicate-summary">
                <div className="duplicate-summary-header">
                    <img src={Warning} alt="warning" />
                    <div>
                        <div>
                            { i18n.t('One or several already opened issue(s) is/are looking similar.') }
                        </div>
                        <div>
                            { i18n.t('Possible duplicate(s) detected. Please review these events and check if they are similar:') }
                        </div>
                    </div>
                </div>
                <SimilarOpenedIssues />
            </div>
        </div>
    );
};

export default DuplicateSummary;
