import React from 'react';
import PropTypes from 'prop-types';

import '../CommonButton.scss';
import './AddItemButton.scss';

const AddItemButton = (props) => (
    <button
        className={`sscustom-button ${props.primary ? 'primary' : 'secondary'} ${props.large ? 'large' : ''} ${props.disabled ? 'disabled' : ''}`}
        onClick={!props.disabled ? props.onClick : null}
    >
        <span className="glyphicon glyphicon-plus" />
        <span className="label">{props.label}</span>
    </button>
);

AddItemButton.defaultProps = {
    primary: false,
    large: false,
    label: 'my-button',
    disabled: false,
};

AddItemButton.propTypes = {
    onClick: PropTypes.func,
    primary: PropTypes.bool,
    label: PropTypes.string,
    large: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default AddItemButton;
