/**
 * Created by lpostula on 10/05/17.
 */
const testing = process.env.NODE_ENV === 'test';
const developing = process.env.NODE_ENV === 'development';
const API_ROOT = `${testing ? 'http://localhost' : ''}/api/v2/`;
const URL_ROOT = `${developing ? 'http://developer.railnova.net:8000' : 'https://one.railnova.eu'}`;
const WS_ROOT = testing ? 'ws://localhost/ws/' : `${window.settings ? window.settings.WS_PROTOCOL : 'ws://'}${window.location.host}/ws/`;
const WS_CONFIG = {
    maxReconnectAttempts: 3,
    timeoutInterval: testing ? 200 : 2000,
    reconnectInterval: testing ? 100 : 1000,
    reconnectDecay: 1.5,
    maxReconnectInterval: testing ? 1000 : 30000,
    pollingInterval: 5000,
    hiddenPollingInterval: 30000,
    maxPolling: 12,
};

export default {};
export {
    API_ROOT,
    URL_ROOT,
    WS_ROOT,
    WS_CONFIG,
};
