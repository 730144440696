import HotlineFilters from './filters/hotline/HotlineFilters';
import InterventionDetail from './details/interventionDetail/InterventionDetail';
import InterventionWorkflow from './forms/interventionWorkflow/InterventionWorkflow';
import EventsPart from './lists/eventsPart/EventsPart';
import InterventionForm from './forms/interventionForm/InterventionForm';
import CorrectiveEventForm from './forms/correctiveEventForm/correctiveEventForm';
import CorrectiveEventDetail from './details/correctiveEventDetail/CorrectiveEventDetail';
import RestrictionList from './lists/restrictionList/RestrictionList';
import RestrictionForm from './forms/restrictionForm/RestrictionForm';
import OperationalStatusList from './lists/operationalStatusList/OperationalStatusList';
import OperationalStatusForm from './forms/operationalStatusForm/OperationalStatusForm';
import CloseEventForm from './forms/closeEventForm/CloseEventForm';
import PreventiveEventForm from './forms/preventiveEventForm/PreventiveEventForm';
import PreventiveEventDetail from './details/preventiveEventDetail/PreventiveEventDetail';
import CampaignEventDetail from './details/campaignEventDetail/CampaignEventDetail';
import CampaignEventForm from './forms/campaignEventForm/CampaignEventForm';
import StandaloneEventForm from './forms/standaloneEventForm/StandaloneEventForm';
import EventSelected from './lists/eventsSelected/EventsSelected';
import RestrictionCardList from './lists/restrictionCardList/RestrictionCardList';
import EventsList from './lists/eventsList/EventsList';
import DriverLogbook from './pages/driverLogbook/DriverLogbook';

export default {
    filters: {
        HotlineFilters,
    },
    details: {
        InterventionDetail,
        CorrectiveEventDetail,
        PreventiveEventDetail,
        CampaignEventDetail,
    },
    forms: {
        InterventionWorkflow,
        InterventionForm,
        CorrectiveEventForm,
        PreventiveEventForm,
        RestrictionForm,
        OperationalStatusForm,
        CloseEventForm,
        CampaignEventForm,
        StandaloneEventForm,
    },
    lists: {
        EventsPart,
        RestrictionList,
        OperationalStatusList,
        EventSelected,
        RestrictionCardList,
        EventsList,
    },
    pages: {
        DriverLogbook,
    },
};
