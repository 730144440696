import React from 'react';

import './PlusTextAction.scss';

const PlusTextAction = (props) => (
    <div className={`plus-action ${props.disabled ? 'disabled' : ''}`}>
        <span className="glyphicon glyphicon-plus" />
        <span className="plus-action-text" onClick={props.disabled ? () => null : props.onClick}>{props.text}</span>
    </div>
);

export default PlusTextAction;
