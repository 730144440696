import React from 'react';
import './errors.scss';

const MainError = () => (
    <div className="error_body">
        <div className="background">
            <div className="bocktext">
                <div className="blocktext-center">
                    <h2>Oops! Something went wrong.</h2>
                    <h3>Please try again, or contact support if the problem persists.</h3>
                    <hr />
                    <a href="mailto:support@railnova.eu">
                        <button
                            className="btn btn-primary btn-lg"
                            type="button"
                        >
                            Contact support
                        </button>
                    </a>
                    <a href="/">
                        <button
                            className="btn btn-primary btn-lg"
                            type="button"
                        >
                            Go back to the homepage
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </div>
);

export default MainError;
