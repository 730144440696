import { useState, useEffect } from 'react';

const useCurrentPage = (pages) => {
    const [_page, setPage] = useState('Home');
    useEffect(() => {
        const routing = {
            base_name: window.location.pathname,
            path: window.location.hash,
        };
        if (!pages || routing.base_name === '/') {
            setPage('Home');
            return;
        }
        let { base_name } = routing;
        const base_path = routing.path.split('/')[1];
        if (base_name === '/operational/' && base_path !== 'dashboard') {
            base_name = base_path;
        }
        for (const product of pages) {
            if (!['railfleet', 'railgenius', 'railster'].includes(product.id)) {
                continue;
            }
            for (const page of product.pages) {
                if (page.routing === base_name || page.url === base_name) {
                    setPage(page.title);
                    return;
                }
            }
            for (const page of product.pages) {
                if (
                    page.routing === base_name
                    || page.url.includes(base_name)
                    || base_name.includes(page.url)
                ) {
                    setPage(page.title);
                    return;
                }
            }
        }
        setPage('Home');
    }, [window.location.href]);
    return _page;
};

export default useCurrentPage;
