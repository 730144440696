import React, { useContext, useEffect, useState } from 'react';
import components from 'components';
import i18n from 'i18next';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { companiesSettingsSelectors } from 'railfleet_state/ducks/companies_settings';
import { maintenanceEventsOperations, maintenanceEventsSelectors } from 'railfleet_state/ducks/maintenance_events';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { usersSelectors } from 'railfleet_state/ducks/users';
import { documentsSelectors, documentsOperations } from 'railfleet_state/ducks/documents';
import { restrictionsSelectors } from 'railfleet_state/ducks/restrictions';
import HomologationContext from 'compounds/pages/assetView/HomologationContext/homologationContext';

import scrollToTop from '../../../utils/scrollToTop';
import './correctiveEventForm.scss';

const {
    FormManager,
    FormDateTime,
    FormTextInput,
    FormCustomField,
    FormLocationDropdown,
    CutForm,
    FormCheckbox,
    InputsWrapper,
    FormFile,
    FormHotlineCall,
    FormRestrictions,
    FormFaultCategory,
    FormLabelCategory,
    FormCaptcha,
} = components.forms;
const { TextDisplay } = components.display;
const { usePerm } = components.hooks;

const { THEMES } = components.selectors;
const { MultiDeviceLayout, WithLoginless } = components.app;

const asset_operational_status_class_getter = (status) => {
    let class_name = 'operational_status ';
    switch (status.key) {
    case 'ok':
        class_name += 'success';
        break;
    case 'degraded':
        class_name += 'warning';
        break;
    case 'immobilized':
        class_name += 'danger';
        break;
    default:
        class_name += 'default';
        break;
    }
    return class_name;
};

const CorrectiveEventFormComponent = (props) => {
    const context = useContext(HomologationContext);
    const refreshData = context?.methods?.refreshData;
    const can_aggravate = context?.methods?.can_aggravate(props.asset_id);
    const [_sent, setSent] = useState(false);
    const [_selectedFrom, setSelectedFrom] = useState(null);
    const [_delayed, setDelayed] = useState(false);
    const [_hotline_called, setHotlineCalled] = useState(false);
    const [_errorApi, setErrorApi] = useState(null);
    const hasPerm = usePerm(props.asset_id || null);

    useEffect(() => {
        window.pausePolling(['all_assets_live_data']);
        if (window.pause_timeline_polling) {
            window.pause_timeline_polling();
        }
        return () => {
            window.unpausePolling(['all_assets_live_data']);
            if (window.unpause_timeline_polling) {
                window.unpause_timeline_polling();
            }
        };
    }, []);

    useEffect(() => {
        if (props.event.id) {
            props.fetchDocumentsForEvent(props.event.id);
        }
    }, [props.event.id]);

    const defaultValues = props.event_id ? (props.event) : {
        ...props.defaultCustomFields,
        reported_by: props.user.full_name,
        poi: props.poi,
    };

    const format_data = (data) => {
        const formated_data = { ...data };
        if (props.event_id) {
            formated_data.id = props.event.id;

            if (props.event.state === 'closed') {
                formated_data.date_closed = data.date_closed.toISOString();
                formated_data.date_started = data.date_started.toISOString();
            }
        }
        formated_data.date_incident = data.date_incident.toISOString();
        formated_data.asset = props.asset_id;
        formated_data.maintenance_type = props.maintenance_type || 'corrective';
        formated_data.hotline_called = formated_data.hotline_called === true || formated_data.hotline_called === 'true';
        formated_data.private = formated_data.private === true || formated_data.private === 'true';
        return formated_data;
    };

    const onSubmit = (data) => {
        if (_sent) return;
        const formated_data = format_data(data);
        setSent(true);
        const method = props.event.id ? props.updateEvent : props.createEvent;
        method(formated_data).then((d) => {
            setSent(false);
            if (d.payload && !d.error) {
                setErrorApi(null);
                if (refreshData) refreshData();
                if (props.callback) props.callback(d.payload);
            }
            if (d.error) {
                setErrorApi(d.payload.response);
                scrollToTop();
            }
        });
    };

    const onChangeFrom = (arg) => {
        if (arg) setSelectedFrom(arg);
        else setSelectedFrom(null);
    };

    const onChangeImpactServiceStatus = (arg) => {
        for (const key in props.impact_service_status_type) {
            const isst = props.impact_service_status_type[key];
            if (arg === isst.id) {
                if (isst.key === 'train_delayed') setDelayed(true);
                else setDelayed(false);
                break;
            }
        }
    };

    if (props.maintenance_type === 'servicing') {
        return (
            <div className={`form-main ${props.device} corrective-form`}>
                <FormManager
                    onSubmit={onSubmit}
                    textSubmitButton={i18n.t('Create event')}
                    disabled={_sent}
                    onCancel={props.cancelCallback}
                    apiErrors={_errorApi}
                >
                    {
                        props.loginless
                            && (
                                <FormCaptcha
                                    id="captcha"
                                />
                            )
                    }
                    <InputsWrapper
                        id="general"
                        title={i18n.t('General')}
                    >
                        <CutForm
                            device={props.device}
                        >
                            {
                                !props.standalone
                                    && (
                                        <TextDisplay
                                            label={i18n.t('Asset')}
                                            data={props.asset_name}
                                        />
                                    )
                            }
                            {
                                !props.standalone
                                    && (
                                        <TextDisplay
                                            label={i18n.t('Maintenance Type')}
                                            data={i18n.t('servicing')}
                                        />
                                    )
                            }
                            <FormDateTime
                                id="date_incident"
                                defaultValue={
                                    (defaultValues
                                    && defaultValues.date_incident
                                    && moment(defaultValues.date_incident))
                                    || moment()
                                }
                                label={i18n.t('Incident date')}
                                className="trs-datepicker-container"
                                fillFlex
                                required
                                onChange={onChangeFrom}
                            />
                            <FormLocationDropdown
                                id="poi"
                                defaultValue={defaultValues && defaultValues.poi}
                                label={i18n.t('Location of incident')}
                                id_getter={(item) => item.value}
                                labelizer={(item) => item.label}
                                clearable
                                required
                                theme={THEMES.FORM_LIGHT}
                                asset_id={props.asset_id}
                                date={_selectedFrom}
                                autoFilled
                            />
                            <FormTextInput
                                id="description"
                                defaultValue={defaultValues && defaultValues.description}
                                label={i18n.t('Description')}
                                large
                                required
                                lockSize
                            />
                            <div className="event-form-full-line">
                                <FormFile
                                    id="documents"
                                    label={i18n.t('Documents')}
                                    defaultValue={props.event.documents}
                                    documents={props.event.id && props.documents_for_event}
                                    url="/api/document.json"
                                    multi
                                    deletable
                                    deleteAction={(file) => props.deleteDocument(file)}
                                />
                            </div>
                        </CutForm>
                    </InputsWrapper>
                </FormManager>
            </div>
        );
    }
    if (!hasPerm('create_corrective_event') && !props.loginless) return (<div>YOU DO NOT HAVE THE PERM: create_corrective_event</div>);
    const withHomologation = props?.user_company?.has_homologated_assets && can_aggravate;
    return (
        <div className={`form-main ${props.device} corrective-form`}>
            <FormManager
                onSubmit={onSubmit}
                textSubmitButton={props.event_id ? i18n.t('Update event') : i18n.t('Create event')}
                disabled={_sent}
                onCancel={(!props.standalone && props.cancelCallback) || (props.driverLogbook && props.cancelCallback)}
                apiErrors={_errorApi}
                hasPerm={hasPerm('create_corrective_event') || props.loginless}
            >
                {
                    props.loginless
                        && (
                            <FormCaptcha
                                id="captcha"
                            />
                        )
                }
                <InputsWrapper
                    id="general"
                    title={i18n.t('General')}
                >
                    <CutForm
                        device={props.device}
                    >
                        {
                            !props.standalone
                                && (
                                    <TextDisplay
                                        label={i18n.t('Asset')}
                                        data={props.asset_name}
                                    />
                                )
                        }
                        {
                            !props.standalone
                                && (
                                    <TextDisplay
                                        label={i18n.t('Maintenance Type')}
                                        data={i18n.t('corrective')}
                                    />
                                )
                        }
                        <FormDateTime
                            id="date_incident"
                            defaultValue={
                                (defaultValues
                                && defaultValues.date_incident
                                && moment(defaultValues.date_incident))
                                || moment()
                            }
                            label={i18n.t('Incident date')}
                            className="trs-datepicker-container"
                            fillFlex
                            required
                            onChange={onChangeFrom}
                        />
                        <FormLocationDropdown
                            id="poi"
                            defaultValue={defaultValues && defaultValues.poi}
                            label={i18n.t('Location of incident')}
                            id_getter={(item) => item.value}
                            labelizer={(item) => item.label}
                            clearable
                            required
                            theme={THEMES.FORM_LIGHT}
                            asset_id={props.asset_id}
                            date={_selectedFrom}
                            autoFilled
                        />
                    </CutForm>
                    <FormCustomField
                        id="public_fields"
                        asset={props.asset_id}
                        defaultValue={defaultValues}
                        public
                        content_type="maintenanceevent"
                        content_options={props.content_options}
                        asset_class={props.asset_class}
                        {...props.loginless ? props : {}}
                    />
                    <CutForm
                        device={props.device}
                    >
                        <FormTextInput
                            id="description"
                            defaultValue={defaultValues && defaultValues.description}
                            label={i18n.t('Description')}
                            large
                            required
                            lockSize
                        />
                        <div className="event-form-full-line">
                            <FormFile
                                id="documents"
                                label={i18n.t('Documents')}
                                defaultValue={props.event.documents}
                                documents={props.event.id && props.documents_for_event}
                                url="/api/document.json"
                                multi
                                deletable
                                deleteAction={(file) => props.deleteDocument(file)}
                            />
                        </div>
                    </CutForm>
                    <CutForm
                        device={props.device}
                    >
                        <FormTextInput
                            id="reported_by"
                            defaultValue={defaultValues && defaultValues.reported_by}
                            label={i18n.t('Reported by')}
                            required
                        />
                        <FormCheckbox
                            id="hotline_called"
                            defaultValue={(defaultValues && defaultValues.hotline_called) ? 'true' : 'false'}
                            label={i18n.t('Hotline called')}
                            values={[
                                { id: 'true', label: i18n.t('yes') },
                                { id: 'false', label: i18n.t('no') },
                            ]}
                            labelizer={(item) => item.label}
                            onChange={(arg) => {
                                if (arg === 'true') setHotlineCalled(true);
                                else setHotlineCalled(false);
                            }}
                        />
                        {hasPerm('create_private_event')
                            && (
                                <FormCheckbox
                                    id="private"
                                    defaultValue="false"
                                    label={i18n.t('Privacy')}
                                    values={[
                                        { id: 'true', label: i18n.t('Private') },
                                        { id: 'false', label: i18n.t('Public') },
                                    ]}
                                    labelizer={(item) => item.label}
                                    required
                                />
                            )}
                    </CutForm>
                    <CutForm
                        device={props.device}
                    >
                        <div className="event-form-full-line">
                            {
                                _hotline_called
                                    && (
                                        <FormHotlineCall
                                            id="hotline_assign"
                                            label={i18n.t('Attach hotline calls')}
                                            asset_id={props.asset_id}
                                            event_id={props.event && props.event.id}
                                            date={_selectedFrom}
                                        />
                                    )
                            }
                        </div>
                    </CutForm>
                </InputsWrapper>
                <InputsWrapper
                    id="Impact"
                    title={i18n.t('impact')}
                >
                    <div className="event-form-full-line">
                        <FormCheckbox
                            id="impact_service_status"
                            defaultValue={defaultValues && defaultValues.impact_service_status}
                            label={i18n.t('Impact service')}
                            values={props.impact_service_status_type}
                            labelizer={(item) => item.name}
                            required
                            onChange={onChangeImpactServiceStatus}
                        />
                        {
                            _delayed
                                && (
                                    <FormTextInput
                                        id="impact_service_delay"
                                        defaultValue={
                                            defaultValues
                                        && defaultValues.impact_service_delay
                                        }
                                        label={i18n.t('delay_in_minutes')}
                                        required
                                    />
                                )
                        }
                    </div>
                    <FormCheckbox
                        id="impact_status"
                        defaultValue={defaultValues && defaultValues.impact_status}
                        label={i18n.t('Impact status')}
                        values={props.operational_status_type}
                        labelizer={(item) => item.name}
                        custom_class_getter={asset_operational_status_class_getter}
                        required
                    />
                    <FormLabelCategory
                        id="labels"
                        asset_id={props.asset_id}
                        defaultValue={defaultValues && defaultValues.labels}
                    />
                </InputsWrapper>
                {
                    props.private_fields && props.private_fields.length > 0
                        && (
                            <InputsWrapper
                                id="private"
                                title={i18n.t('Private')}
                                private
                            >
                                <FormCustomField
                                    id="private_fields"
                                    defaultValue={defaultValues}
                                    private
                                    content_type="maintenanceevent"
                                    content_options={props.content_options}
                                    asset_class={props.asset_class}
                                />
                            </InputsWrapper>
                        )
                }
                {
                    props.event_id && props.event.state !== 'open'
                        && (
                            <InputsWrapper
                                id="maintenance_report"
                                title={i18n.t('Maintenance report')}
                            >
                                <CutForm
                                    device={props.device}
                                >
                                    <FormTextInput
                                        id="rex"
                                        defaultValue={defaultValues && defaultValues.rex}
                                        label={i18n.t('Observations')}
                                        large
                                        lockSize
                                    />
                                    <FormDateTime
                                        id="date_started"
                                        defaultValue={
                                            (defaultValues
                                        && defaultValues.date_started
                                        && moment(defaultValues.date_started))
                                        || moment()
                                        }
                                        label={i18n.t('Date started')}
                                        className="trs-datepicker-container"
                                        fillFlex
                                    />
                                    <FormDateTime
                                        id="date_closed"
                                        defaultValue={
                                            (defaultValues
                                        && defaultValues.date_closed
                                        && moment(defaultValues.date_closed))
                                        || moment()
                                        }
                                        label={i18n.t('Date closed')}
                                        className="trs-datepicker-container"
                                        fillFlex
                                    />
                                    <FormFaultCategory
                                        id="fault_category"
                                        label={i18n.t('Fault Category')}
                                        defaultValue={
                                            defaultValues.fault_category
                                        }
                                        event_id={props.event_id}
                                        asset_id={props.asset_id}
                                    />
                                </CutForm>
                                <CutForm
                                    device={props.device}
                                >
                                    <FormCustomField
                                        id="public_fields"
                                        asset={props.asset_id}
                                        defaultValue={defaultValues}
                                        public
                                        content_type="maintenanceevent"
                                        content_options={props.content_options_close}
                                        asset_class={props.asset_class}
                                    />
                                </CutForm>
                                {
                                    props.private_fields_close
                                && props.private_fields_close.length > 0
                                    && (
                                        <InputsWrapper
                                            id="private"
                                            title={i18n.t('Private')}
                                            private
                                        >
                                            <FormCustomField
                                                id="private_fields"
                                                defaultValue={defaultValues}
                                                private
                                                content_type="maintenanceevent"
                                                content_options={props.content_options_close}
                                                asset_class={props.asset_class}
                                            />
                                        </InputsWrapper>
                                    )
                                }
                            </InputsWrapper>
                        )
                }
                {
                    ((props.event && props.event.state === 'closed' && props.restrictions.length > 0)
                    || (!props.event || props.event.state !== 'closed')) && hasPerm('create_restriction')
                        && (
                            <InputsWrapper
                                id="restrictions"
                                title={i18n.t('Restrictions')}
                            >
                                <div className="event-form-full-line">
                                    <FormRestrictions
                                        id="restrictions"
                                        asset_id={props.asset_id}
                                        restrictions={props.restrictions}
                                        lock={props.event && props.event.state === 'closed'}
                                        homologation={withHomologation}
                                    />
                                </div>
                            </InputsWrapper>
                        )
                }
                {
                    props.loginless
                        && (
                            <div className="google-policy">
                                <span>
                                    {'This site is protected by reCAPTCHA and the '}
                                    <a href="https://policies.google.com/privacy">Google Privacy Policy</a>
                                    {' and '}
                                    <a href="https://policies.google.com/terms">Terms of Service</a>
                                    {' '}
                                    apply.
                                </span>
                            </div>
                        )
                }
            </FormManager>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    if (props.loginless) return props.mapStateToProps(state, props);
    const { event_id } = props;
    const { asset_id } = props;
    const event = event_id && maintenanceEventsSelectors.getMaintenanceEvent(state, event_id);
    const maintenance_type = props.maintenance_type || (event && event.maintenance_type);
    const asset = asset_id && assetsSelectors.getAssetInfos(state, asset_id);
    const asset_class = asset && asset.asset_class;
    const asset_name = asset && asset.name;
    const user = state.users.me;
    const company_id = user && user.company;
    const fields = companiesSettingsSelectors.getPrivateFieldsForUserWithContentType('maintenanceevent')(state);
    const documents_for_event = event_id
        && documentsSelectors.getDocumentsForEvent(state, event_id);
    const restrictions = event_id && restrictionsSelectors.getRestrictionsByEvent(state, event_id);
    const content_type = 'maintenanceevent';
    const content_options = ['corrective', 'open'];
    const content_options_close = ['corrective', 'close'];
    const private_fields = content_type
        && companiesSettingsSelectors.getPrivateFieldsForUserWithContentTypeAndOptions(
            content_type, content_options,
        )(state);
    const private_fields_close = content_type
        && companiesSettingsSelectors.getPrivateFieldsForUserWithContentTypeAndOptions(
            content_type, content_options_close,
        )(state);
    const assets = assetsSelectors.getAssetsInfos(state);
    return ({
        users: state.users.all,
        user,
        assets,
        asset_class,
        content_options,
        content_options_close,
        private_fields,
        private_fields_close,
        impact_service_status_type: state.maintenance_settings
            && state.maintenance_settings[company_id]
            ? state.maintenance_settings[company_id].impact_service_status_type
            : null,
        operational_status_type: state.maintenance_settings
            && state.maintenance_settings[company_id]
            ? state.maintenance_settings[company_id].operational_status_type
            : null,
        user_perm_check: usersSelectors.userHasPerm(state),
        display_private: fields && fields.length > 0,
        event: event || {},
        asset_name,
        maintenance_type,
        documents_for_event: documents_for_event || {},
        restrictions: restrictions || [],
        user_company: usersSelectors.getCurrentUserCompany(state),
    });
};

const mapDispatchToProps = (dispatch, props) => {
    if (props.loginless) return props.mapDispatchToProps(dispatch, props);
    return ({
        createEvent: (payload) => dispatch(maintenanceEventsOperations.createEvent(payload)),
        updateEvent: (payload) => dispatch(
            maintenanceEventsOperations.updateMaintenanceEvent(payload),
        ),
        fetchDocumentsForEvent: (event_id) => (
            dispatch(documentsOperations.fetchForEvent(event_id))
        ),
        deleteDocument: (file) => dispatch(documentsOperations.deleteDocument(file)),
    });
};

const CorrectiveEventForm = (props) => (
    <MultiDeviceLayout>
        <CorrectiveEventFormComponent
            {...props}
        />
    </MultiDeviceLayout>
);

CorrectiveEventForm.defaultProps = {
    defaultCustomFields: {},
};

export default WithLoginless(connect(mapStateToProps, mapDispatchToProps)(CorrectiveEventForm));
