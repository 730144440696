import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './SwitchFilter.scss';

const SwitchFilter = (props) => {
    const [_selected, setSelected] = useState(props.defaultSelected);

    useEffect(() => {
        if (props.onChange) props.onChange(_selected);
    }, [_selected]);

    useEffect(() => {
        if (props.options && props.options.length > 0 && !props.defaultSelected) {
            if (!_selected) setSelected(props.options[0].id);
        }
    }, [props.options]);

    const renderOptions = () => props.options && props.options.map((option) => (
        <div
            className={`switch-filters-elm-box${_selected === option.id ? ' selected' : ''}`}
            onClick={() => {
                setSelected(option.id);
            }}
            key={option.id}
        >
            <div className="switch-filters-elm">
                {option.label}
            </div>
        </div>
    ));

    return (
        <div className="switch-filters-holder">
            {
                props.options && props.options.length > 0 && props.img
                    && <img src={props.img} alt="filter team logo" />
            }
            <div className="switch-filters-bar">
                { renderOptions() }
            </div>
        </div>
    );
};

SwitchFilter.propTypes = {
    options: PropTypes.array,
    defaultSelected: PropTypes.number,
    onChange: PropTypes.func,
};

export default SwitchFilter;
