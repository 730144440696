/**
 * Created by lpostula on 03/07/17.
 */

const FETCH_DETAIL_REQUEST = 'counters/FETCH_DETAIL_REQUEST';
const FETCH_DETAIL_COMPLETED = 'counters/FETCH_DETAIL_COMPLETED';
const FETCH_DETAIL_FAILED = 'counters/FETCH_DETAIL_FAILED';

const FETCH_LIST_REQUEST = 'counters/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'counters/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'counters/FETCH_LIST_FAILED';

const UPDATE_DONE_USAGES_REQUEST = 'counters/UPDATE_DONE_USAGES_REQUEST';
const UPDATE_DONE_USAGES_COMPLETED = 'counters/UPDATE_DONE_USAGES_COMPLETED';
const UPDATE_DONE_USAGES_FAILED = 'counters/UPDATE_DONE_USAGES_FAILED';

export default {
    FETCH_DETAIL_REQUEST,
    FETCH_DETAIL_COMPLETED,
    FETCH_DETAIL_FAILED,

    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,

    UPDATE_DONE_USAGES_REQUEST,
    UPDATE_DONE_USAGES_COMPLETED,
    UPDATE_DONE_USAGES_FAILED,
};
