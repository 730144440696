import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import components from 'components';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { usersSelectors } from 'railfleet_state/ducks/users';
import {
    assetOperationalStatusOperations,
    assetOperationalStatusSelectors,
} from 'railfleet_state/ducks/asset_operational_status';
import './OperationalStatusForm.scss';
import RtoIcon from './rtoIcon.svg';
import useScreenshot from '../../../components/hooks/useScreenshot';

const { Checkbox } = components.buttons;
const {
    FormManager,
    FormTextInput,
    FormDropdown,
    CutForm,
    FormDateTime,
    InputsWrapper,
} = components.forms;
const { THEMES } = components.selectors;
const { MultiDeviceLayout, AuthorizedComponent } = components.app;
const { usePerm } = components.hooks;

const sort_by_date = (a, b) => moment(b.date_started) - moment(a.date_started);

const OperationalStatusFormComponent = (props) => {
    const [_displayForm, setDisplayForm] = useState(false);
    const hasPerm = usePerm(props.asset_id);
    if (
        !hasPerm('edit_status')
        && !hasPerm('aggravate_status')
        && !hasPerm('alleviate_status')
    ) {
        return (
            <div>YOU DO NOT HAVE THE PERM: edit_status or aggravate_status or alleviate_status</div>
        );
    }
    const noStatus = {
        status_key: null,
        status_name: null,
        detailed_status_name: null,
        date_started: null,
        user_name: null,
    };
    const [
        _current_operational_status,
        setCurrentOperationalStatus,
    ] = useState(noStatus);

    useEffect(() => {
        if (
            props.asset_statuses
            && props.asset_statuses.length > 0
            && props.maintenance_settings.operational_status_type
        ) {
            const s = props.asset_statuses.sort(sort_by_date)[0];
            const user = props.users[s.user_started];
            const status = props.maintenance_settings.operational_status_type[s.status];
            const detailed_status = props.maintenance_settings
                .detailed_operational_status_type[s.detailed_status];
            setCurrentOperationalStatus({
                status_key: status.key,
                id: status.id,
                status_name: status.name,
                detailed_status_name: detailed_status && detailed_status.name,
                date_started: s.date_started,
                user_name: user && user.full_name,
            });
        } else {
            setCurrentOperationalStatus(noStatus);
        }
    }, [
        props.asset_statuses,
        props.maintenance_settings.operational_status_type,
    ]);

    const onChange = (arg) => {
        setDisplayForm(arg);
        if (props.onChange) {
            props.onChange(arg);
        }
    };

    useEffect(() => {
        if (props.asset_id) {
            props.fetchStatusForAsset(props.asset_id);
        }
    }, [props.asset_id]);

    useEffect(() => {
        if (props.onChange) {
            props.onChange(_displayForm);
        }
    }, []);

    return (
        <div className={`operational-status-form ${props.overrideDevice || props.device} ${props.noHeader ? 'noHeader' : ''}`}>
            {
                (!props.noHeader && props.impact_status_id)
                    && (
                        <div className={`operational-status-header${props.impact_status_id ? ' separator' : ''}`}>
                            <div className="current-status">
                                <span>{`${i18n.t('Asset')}:`}</span>
                                <span>{props.asset_name}</span>
                            </div>
                            <div className="current-status">
                                <span>{i18n.t('Current operational status:')}</span>
                                <span className={_current_operational_status.status_key}>
                                    {_current_operational_status.status_name}
                                </span>
                            </div>
                            {
                                _current_operational_status.detailed_status_name
                                && (
                                    <div>
                                        <span>{i18n.t('Current detailled status:')}</span>
                                        <span>
                                            {_current_operational_status.detailed_status_name}
                                        </span>
                                    </div>
                                )
                            }
                            <div>
                                <span>{i18n.t('on_date')}</span>
                                <span>{moment(_current_operational_status.date_started).format('DD/MM/YYYY HH:mm')}</span>
                            </div>
                            <div>
                                <span>{i18n.t('by')}</span>
                                <span>{_current_operational_status.user_name}</span>
                            </div>
                        </div>
                    )
            }
            <div className="vertical separator" />
            <div className="operational-status-body">
                {
                    (props.choice && !props.impact_status_id)
                        && (
                            <div className="choice-change">
                                {
                                    !props.noLabel
                                        && <span>{i18n.t('Do you want to change operational status ?')}</span>
                                }
                                <div className="checkboxes">
                                    <div>
                                        <Checkbox
                                            value={_displayForm}
                                            onChange={() => onChange(true)}
                                        />
                                        <span>{i18n.t('Yes')}</span>
                                    </div>
                                    <div>
                                        <Checkbox
                                            value={!_displayForm}
                                            onChange={() => onChange(false)}
                                        />
                                        <span>{i18n.t('No')}</span>
                                    </div>
                                </div>
                            </div>
                        )
                }
                {
                    (_displayForm || !props.choice)
                    && (
                        <StatusForm
                            {...props}
                            current_operational_status={_current_operational_status}
                            hasPerm={hasPerm}
                        />
                    )
                }
            </div>
        </div>
    );
};

const StatusForm = (props) => {
    const [_detailled_status, setDetailledStatus] = useState({});
    const [_locked, setLocked] = useState(false);
    const [_latestScreenshot, takeScreenshot] = useScreenshot();

    const [
        _asset_operational_status,
        setAssetOperationalStatus,
    ] = useState(props.asset_operational_status);

    useEffect(() => {
        if (_asset_operational_status
            && props.maintenance_settings.operational_status_type
        ) {
            const s_t = props.maintenance_settings.operational_status_type;
            const status = s_t[_asset_operational_status];
            let values = {};
            if (status && status.detailed_status && (status.detailed_status.length > 0)) {
                const d_t = props.maintenance_settings.detailed_operational_status_type;
                values = status.detailed_status.map((d_s_id) => d_t[d_s_id]);
            }
            setDetailledStatus(values);
        }
    }, [
        _asset_operational_status,
        props.maintenance_settings,
    ]);

    const onChangeOperationalStatus = (arg) => {
        setAssetOperationalStatus(arg);
    };

    const uploadImage = async (img) => {
        const payload = new FormData();
        payload.append('file', img, 'screenshot.png');
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
            },
            body: payload,
        };
        const url = '/api/asset_operational_status_document.json';
        return fetch(url, settings);
    };

    const onSubmit = (arg) => {
        setLocked(true);
        if (props.noScreenshot) {
            const payload = {
                asset: props.asset_id,
                comment: arg.comment,
                date_action: props.date_action && props.date_action.toISOString(),
                date_started: (arg.date_started && arg.date_started.toISOString()) || moment(),
                detailed_status: arg.detailed_status,
                status: arg.status,
            };
            props.createStatus(payload).then((d) => {
                if (d.payload && !d.error && props.operationalStatusCallback) {
                    if (props.autoRefresh) setLocked(false);
                    props.operationalStatusCallback({
                        date: props.date_action,
                        intervention: props.intervention,
                    }).then(() => {
                        setLocked(false);
                    });
                }
                if (d.error) {
                    setLocked(false);
                }
            });
        } else {
            takeScreenshot().then((canvas) => {
                canvas.toBlob((blob) => {
                    const file = new File([blob], 'screenshot', { type: 'image/png' });
                    uploadImage(file).then((response) => {
                        response.json().then((data) => {
                            const payload = {
                                asset: props.asset_id,
                                comment: arg.comment,
                                date_action: props.date_action && props.date_action.toISOString(),
                                date_started: (arg.date_started && arg.date_started.toISOString()) || moment(),
                                detailed_status: arg.detailed_status,
                                status: arg.status,
                                documents: [data.id],
                            };
                            props.createStatus(payload).then((d) => {
                                if (d.payload && !d.error && props.operationalStatusCallback) {
                                    if (props.autoRefresh) setLocked(false);
                                    props.operationalStatusCallback({
                                        date: props.date_action,
                                        intervention: props.intervention,
                                    }).then(() => {
                                        setLocked(false);
                                    });
                                }
                                if (d.error) {
                                    setLocked(false);
                                }
                            });
                        });
                    });
                });
            });
        }
    };
    const onCancel = () => {
        if (props.onCancel) props.onCancel();
    };

    const operational_status_type = {};
    const keys = props.maintenance_settings?.operational_status_type
        && Object.keys(props.maintenance_settings.operational_status_type).map((elm) => parseInt(elm, 10));
    const allowed_keys = [];
    const current_operational_status = (props.current_operational_status && props.current_operational_status.id) ? props.current_operational_status : { id: 0 };
    if (keys && props.hasPerm('aggravate_status')) {
        _.forEach(keys, (key) => {
            if (key >= current_operational_status.id && !allowed_keys.includes(key)) allowed_keys.push(key);
        });
    }
    if (keys && props.hasPerm('alleviate_status')) {
        _.forEach(keys, (key) => {
            if (key <= current_operational_status.id && !allowed_keys.includes(key)) allowed_keys.push(key);
        });
    }
    if (!props.hasPerm('aggravate_status') && !props.hasPerm('alleviate_status')) {
        allowed_keys.push(current_operational_status.id);
    }
    _.each(allowed_keys, (key) => {
        operational_status_type[key] = { ...props.maintenance_settings.operational_status_type[key] };
    });

    const actual_status = props.asset_operational_status;
    const target_status = _asset_operational_status;

    const from = operational_status_type[actual_status] && operational_status_type[actual_status].name;
    const to = target_status && operational_status_type[target_status] && operational_status_type[target_status].name;
    const rtoMessage = `${i18n.t('Return to Operation - You’ve changed the status from {{from}} to {{to}}? Confirming this action will result in a Return to Operation of the  asset.', {
        from,
        to,
    })}`;
    const rto = target_status < actual_status && actual_status === 3;

    return (
        <div className="data-form">
            <AuthorizedComponent
                asset_id={props.asset_id}
                permission_name={[
                    'aggravate_status',
                    'alleviate_status',
                    'edit_status',
                    'edit_detailed_status',
                ]}
                permission_text={i18n.t('manage status')}
                permission_operator="or"
            >
                <FormManager
                    onSubmit={onSubmit}
                    textSubmitButton={rto ? props.textSubmitRTO : (props.impact_status_id ? i18n.t('Confirm change of operational status') : props.textSubmit)}
                    onCancel={props.onCancel && onCancel}
                    disabled={_locked || props.disabled}
                    primary
                    resetOnSubmit={props.autoRefresh ? {
                        status: null,
                        detailed_status: null,
                        comment: null,
                        date_started: moment(),
                    } : null}
                >
                    <InputsWrapper
                        id="general"
                        title={!props.impact_status_id && (props.title || i18n.t('Operational status informations'))}
                        standalone={!props.choice && !props.impact_status_id}
                    >
                        <CutForm
                            device={props.overrideDevice || props.device}
                        >
                            <FormDropdown
                                id="status"
                                label={i18n.t('Operational status')}
                                value={props.impact_status_id}
                                values={operational_status_type}
                                id_getter={(a) => a.id}
                                labelizer={(a) => a.name}
                                theme={THEMES.FORM_LIGHT}
                                required
                                onChange={onChangeOperationalStatus}
                                disabled={!props.hasPerm('edit_status') && !props.hasPerm('aggravate_status') && !props.hasPerm('alleviate_status')}
                            />
                            {
                                _detailled_status
                                && _detailled_status.length > 0
                                    && (
                                        <FormDropdown
                                            id="detailed_status"
                                            label={i18n.t('Detailed status')}
                                            values={_detailled_status}
                                            id_getter={(a) => a.id}
                                            labelizer={(a) => a.name}
                                            theme={THEMES.FORM_LIGHT}
                                            dependOn={_asset_operational_status}
                                            disabled={!props.hasPerm('edit_status') || !props.hasPerm('edit_detailed_status')}
                                        />
                                    )
                            }
                            <FormDateTime
                                id="date_started"
                                label={i18n.t('Date')}
                                defaultValue={moment()}
                                theme={THEMES.FORM_LIGHT}
                                maxDate={moment().add(10, 'minutes')}
                                fillFlex
                                required
                                disabled={!props.hasPerm('edit_status')}
                            />
                            {
                                rto
                                && (
                                    <div className="rto-text">
                                        <img src={RtoIcon} alt="rto-icon" />
                                        <span>
                                            {rtoMessage}
                                        </span>
                                    </div>
                                )
                            }
                            <FormTextInput
                                id="comment"
                                label={i18n.t('Comment')}
                                theme={THEMES.FORM_LIGHT}
                                large
                                disabled={!props.hasPerm('edit_status')}
                            />
                        </CutForm>
                    </InputsWrapper>
                </FormManager>
            </AuthorizedComponent>
        </div>
    );
};

const OperationalStatusForm = (props) => (
    <MultiDeviceLayout>
        <OperationalStatusFormComponent
            {...props}
        />
    </MultiDeviceLayout>
);

const mapStateToProps = (state, props) => {
    if (props.loginless) return props.mapStateToProps(state, props);
    const { asset_id } = props;
    const asset = asset_id && assetsSelectors.getAssetInfos(state, asset_id);
    const asset_name = asset && asset.name;

    const getMaintenanceSettings = assetsSelectors.makeMaintenanceSettingsForAsset();
    const maintenance_settings = asset_id
        && getMaintenanceSettings(state, asset_id);

    const status_selector = assetOperationalStatusSelectors.makeStatusListForAsset();
    const asset_statuses = status_selector && asset_id
        && status_selector(state, asset_id);

    const asset_operationals = asset_id
        && assetsSelectors.getAssetsOperationals(state)[asset_id];

    const asset_operational_status = asset_operationals
        && asset_operationals.operational_status;
    const asset_operational_detailed_status = asset_operationals
        && asset_operationals.operational_detailed_status;
    const users = state.users.all;
    return {
        maintenance_settings: maintenance_settings || {},
        asset_name,
        asset_operational_status,
        asset_operational_detailed_status,
        asset_statuses,
        users,
        user_perm_check: usersSelectors.userHasPerm(state),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    if (props.loginless) return props.mapDispatchToProps(dispatch, props);
    return ({
        fetchStatusForAsset: (asset_id) => dispatch(
            assetOperationalStatusOperations.fetchForAsset(asset_id),
        ),
        createStatus: (values) => dispatch(
            assetOperationalStatusOperations.createStatus(values),
        ),
    });
};

OperationalStatusForm.defaultProps = {
    choice: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(OperationalStatusForm);
