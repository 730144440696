import React from 'react';
import RenderText from './RenderText';

const RenderData = ({ label, data, large }) => (
    <div className="element-holder">
        <div className="element-label">{label}</div>
        {
            large ? (
                <div className="element large">
                    <RenderText>{data}</RenderText>
                </div>
            ) : (
                <div className="element">
                    <span>{data}</span>
                </div>
            )
        }
    </div>
);

export default RenderData;
