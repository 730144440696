import React, { useState, useContext, useEffect } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import './LocoPreparationDetail.scss';
import moment from 'moment-timezone';
import DetailFrame from '../../../components/frames/detailFrame/DetailFrame';
import CutForm from '../../../components/forms/RailForms/cutForm/CutForm';
import TextDisplay from '../../../components/display/textDisplay/TextDisplay';
import Checklist from '../../../components/display/checklist/Checklist';
import DetailTitle from '../../../components/headers/detailTitle/DetailTitle';
import MultiDeviceLayout from '../../../components/app/MultiDeviceLayout';
import NumberedTitle from '../../../components/headers/numberedTitle/NumberedTitle';
import { LoaderContext } from '../../../components/containers/Modals/Modal';
import TextAction from '../../../components/buttons/textAction/TextAction';

const LocoPreparationDetailComponent = (props) => {
    const { registerDataToLoad } = useContext(LoaderContext);
    const [_conformityLabels, setConformityLabels] = useState(null);
    const [_levelCheckTitles, setLevelCheckTitles] = useState(null);
    const [_stepLabels, setStepLabels] = useState(null);
    const [_displayCheks, setDisplayChecks] = useState({});
    const [_orderedSteps, setOrderedSteps] = useState([]);

    useEffect(() => {
        if (registerDataToLoad) {
            registerDataToLoad({
                id: 'preparation_detail_type_locoPreparationDetail',
                loaded: props.preparation_detail && props.preparation_detail.type,
            });
            registerDataToLoad({
                id: 'steps_locoPreparationDetail',
                loaded: props.steps && props.steps.length > 0,
            });
            registerDataToLoad({
                id: 'conformity_labels_locoPreparationDetail',
                loaded: !!_conformityLabels,
            });
            registerDataToLoad({
                id: 'step_labels_locoPreparationDetail',
                loaded: !!_stepLabels,
            });
            registerDataToLoad({
                id: '_orderedSteps_locoPreparationDetail',
                loaded: !!_orderedSteps && _orderedSteps.length === props.steps.length,
            });
        }
    }, [props.preparation_detail, props.steps, _conformityLabels, _stepLabels]);

    useEffect(() => {
        const orderedSteps = [];
        if (props.steps && props.steps.length > 0) {
            const unordered_keys = Object.keys(props.steps).map((key) => [key, props.steps[key].order]);
            const ordered_keys = unordered_keys.sort((a, b) => a[1] - b[1]);
            for (const elm of ordered_keys) {
                const step = props.steps[elm[0]];
                orderedSteps.push(step.key);
            }
        }
        setOrderedSteps(orderedSteps);
    }, [props.steps]);

    useEffect(() => {
        const conformityLabels = {};
        const levelCheckTitles = {};
        const stepLabels = {};
        if (props.steps && props.steps.length > 0) {
            _.map(props.steps, (step) => {
                stepLabels[step.key] = {
                    label: step.label,
                    order: step.order,
                };
                conformityLabels[step.key] = {};
                _.map(step.conformity_checks, (elm) => {
                    conformityLabels[step.key][elm.key] = elm.label;
                });
                levelCheckTitles[step.key] = {};
                _.map(step.level_checks, (elm) => {
                    levelCheckTitles[step.key][elm.key] = {
                        label: elm.label,
                    };
                    const entries = {};
                    const options = {};
                    _.map(elm.entries, (entry) => {
                        entries[entry.key] = entry.label;
                    });
                    _.map(elm.options, (option) => {
                        options[option.key] = option.label;
                    });
                    levelCheckTitles[step.key][elm.key].entries = entries;
                    levelCheckTitles[step.key][elm.key].options = options;
                });
            });
        }
        setConformityLabels(_.isEmpty(conformityLabels) ? null : conformityLabels);
        setStepLabels(_.isEmpty(stepLabels) ? null : stepLabels);
        setLevelCheckTitles(_.isEmpty(levelCheckTitles) ? null : levelCheckTitles);
    }, [props.steps]);

    const generateFields = (preparation) => {
        const fields = _orderedSteps.map((key, index) => {
            const step = preparation.steps[key];
            const field = (
                <DetailFrame
                    key={key}
                    titleRenderer={() => (
                        <NumberedTitle
                            number={index + 1}
                            title={_stepLabels[key].label}
                        />
                    )}
                >
                    {
                        step?.conformity_checks && !_.isEmpty(step.conformity_checks)
                        && (
                            <div>
                                <Checklist
                                    validText={i18n.t('Conform, nothing to report')}
                                    invalidText={i18n.t('Not conform')}
                                    data={Object.keys(step.conformity_checks).map((cKey) => {
                                        const valid = step.conformity_checks[cKey];
                                        return ({
                                            text: _conformityLabels[key][cKey],
                                            valid,
                                        });
                                    })}
                                />
                                <TextDisplay
                                    label={i18n.t('Comment')}
                                    data={step.conformity_checks_comment}
                                    large
                                />
                            </div>
                        )
                    }
                    <div className={`level_checks ${_displayCheks[key] ? 'visible' : ''}`}>
                        {
                            step?.level_checks && Object.keys(step.level_checks).map((lKey) => {
                                const level_check = step.level_checks[lKey];
                                return (
                                    <div
                                        key={lKey}
                                        className="level-checks-detail"
                                    >
                                        <div
                                            className="level-check-title"
                                        >
                                            {_levelCheckTitles[key][lKey].label}
                                        </div>
                                        <CutForm
                                            device={props.device}
                                        >
                                            {
                                                Object.keys(_levelCheckTitles[key][lKey].entries).map((entryKey) => {
                                                    const entry = _levelCheckTitles[key][lKey].entries[entryKey];
                                                    const option = _levelCheckTitles[key][lKey].options[level_check[entryKey]];
                                                    return (
                                                        <TextDisplay
                                                            key={entryKey}
                                                            label={entry}
                                                            data={option}
                                                        />
                                                    );
                                                })
                                            }
                                        </CutForm>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <TextAction
                        text={_displayCheks[key] ? i18n.t('Hide level checks') : i18n.t('Display level checks')}
                        onClick={() => {
                            setDisplayChecks((oldDisplayChecks) => {
                                const newState = { ...oldDisplayChecks };
                                newState[key] = !oldDisplayChecks[key];
                                return newState;
                            });
                        }}
                        disabled={!step?.level_checks || Object.keys(step?.level_checks).length === 0}
                    />
                </DetailFrame>
            );
            return field;
        });
        return fields;
    };

    if (!props.preparation_detail || !_stepLabels || !_orderedSteps) return null;
    const fields = generateFields(props.preparation_detail);

    return (
        <div className={`loco-preparation form-detail-view ${props.device}`}>
            <DetailTitle title={i18n.t('Last execution information')} />
            <CutForm
                device={props.device}
            >
                <TextDisplay
                    label={i18n.t('Date')}
                    data={props.timestamp && moment(props.timestamp).format('DD/MM/YYYY HH:mm')}
                />
            </CutForm>
            {fields.map((field) => field)}
        </div>
    );
};

const LocoPreparationDetail = (props) => (
    <MultiDeviceLayout>
        <LocoPreparationDetailComponent {...props} />
    </MultiDeviceLayout>
);

const mapStateToProps = (state, props) => {
    const { preparation_detail } = props;
    const timestamp = preparation_detail && preparation_detail.timestamp;
    return {
        timestamp,
    };
};

export default connect(mapStateToProps, null)(LocoPreparationDetail);
