import React from 'react';

class MapLayer extends React.PureComponent {
    constructor(props) {
        super(props);

        // this.leafletElement = null;
        this.leafletElement = this.createLeafletElement(this.props);
    }

    createLeafletElement() {
        throw new Error('Must implement createLeafletElement()');
    }

    // componentWillMount() {
    //     this.leafletElement = this.createLeafletElement(this.props);
    // }

    componentDidMount() {
        this.context.map.addLayer(this.leafletElement);
    }

    componentWillUnmount() {
        this.leafletElement.remove();
    }

    render() {
        return null;
    }
}

export default MapLayer;
