import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { usersSelectors } from 'railfleet_state/ducks/users';

const UnAuthorizedComponent = (props) => {
    const perm_text = props.perm_text || i18n.t('perform this action');
    // do not refactor this line
    const err_text = props.asset_denied ? i18n.t('no_permission_for_asset', { perm_text }) : i18n.t('no_permission', { perm_text });
    return (
        <div className="alert alert-warning">
            {err_text}
        </div>
    );
};

const AuthorizedComponent = (props) => {
    if (props.user_perm && props.asset_perm) {
        return props.children;
    }
    let perm_text;
    if (props.refused_permissions) {
        perm_text = props.refused_permissions.join(` ${props.perm_operator} `);
    } else {
        perm_text = props.permission_text;
    }

    if (!props.no_message) {
        return (
            <UnAuthorizedComponent
                perm_text={perm_text}
                asset_denied={props.user_perm && !props.asset_perm}
            />
        );
    }
    return null;
};

AuthorizedComponent.defaultProps = {
    asset_id: PropTypes.number,
    permission_name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
};

const mapStateToProps = (state, props) => {
    let user_perm = true;
    let asset_perm = true;
    const refused_permissions = [];
    const perm_operator = props.permission_operator || 'or';
    // if there is no perm to check, return early
    if (!props.permission_name) {
        return {
            user_perm,
            asset_perm,
            refused_permissions,
            perm_operator,
        };
    }

    const user_perm_check = usersSelectors.userHasPerm(state);

    // permission_name can be string or array
    let permission_list = props.permission_name;
    if (typeof permission_list === 'string') {
        permission_list = [permission_list];
    }

    // Initial state for AND should be true: true AND cond AND cond
    // Initial state for OR should be false: false OR cond OR cond
    if (perm_operator === 'or') {
        user_perm = false;
        asset_perm = false;
    }

    // combine all required permissions
    for (const perm_name of permission_list) {
        const tmp_user_perm = user_perm_check(perm_name);
        const tmp_asset_perm = user_perm_check(perm_name, props.asset_id);

        // keep track of the failing permissons
        if (!tmp_user_perm || !tmp_asset_perm) {
            // NOTE: we keep track of the perm label so it can be easily displayed later
            refused_permissions.push(perm_name);
        }

        if (perm_operator === 'or') {
            user_perm |= tmp_user_perm;
            asset_perm |= tmp_asset_perm;
        } else {
            user_perm &= tmp_user_perm;
            asset_perm &= tmp_asset_perm;
        }
    }

    return {
        user_perm,
        asset_perm,
        refused_permissions,
        perm_operator,
    };
};

export default connect(mapStateToProps)(AuthorizedComponent);
