/**
 * Created by gknus on 02/09/19.
 */
import types from './types';
import { createReducer, apiHelpers } from '../../utils';

const initialState = {};

const listReducer = createReducer(initialState)({
    [types.FETCH_LIST_COMPLETED]: apiHelpers.fetch_list_complete,
});

export default listReducer;
