import types from './types';
import { createReducer, apiHelpers } from '../../utils';

const initialState = {};

const hotlineReducer = createReducer(initialState)({
    [types.FETCH_LIST_COMPLETED]: apiHelpers.fetch_list_complete,
    [types.FETCH_DETAILS_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.UPDATE_DETAILS_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.CREATE_HOTLINE_COMPLETED]: apiHelpers.fetch_details_complete,
});

export default hotlineReducer;
