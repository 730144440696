import React from 'react';
import PropTypes from 'prop-types';
import { Map as LeafletMap } from 'leaflet';

import './Map.scss';

export default class Map extends React.Component {
    constructor(props) {
        super(props);

        this.leafletElement = null;
        this.container = null;
    }

    getChildContext() {
        return {
            map: this.leafletElement,
            original: this.leafletElement,
        };
    }

    createLeafletElement() {
        // return new RailMap(new LeafletMap(this.container, null));
        return new LeafletMap(this.container, this.props);
    }

    componentDidUpdate(prevProps) {
        if (this.props.bounds && this.props.bounds.isValid()) {
            if (!L.latLng(this.props.center).equals(L.latLng(prevProps.center))) {
                this.leafletElement.setView(this.props.center, this.leafletElement.getZoom());
            }
            if (this.props.zoom !== prevProps.zoom) {
                this.leafletElement.setZoom(this.props.zoom);
            }
            if (this.props.bounds !== prevProps.bounds) {
                this.leafletElement.fitBounds(this.props.bounds, { maxZoom: 15 });
            }
        }
    }

    componentDidMount() {
        this.leafletElement = this.createLeafletElement();
        // this.leafletElement.setView(this.props.center, this.props.zoom);
        this.leafletElement.zoomControl.setPosition(this.props.position);
    }

    render() {
        return (
            <div
                id="assetmap"
                ref={(container) => { this.container = container; }}
            >
                {this.leafletElement && this.props.children}
            </div>
        );
    }
}
Map.defaultProps = {
    zoom: 5,
    center: [51.505, -0.09],
    position: 'topright',
    maxZoom: 18,
    minZoom: 3,
    bounds: null,
    // fadeAnimation: false,
    // zoomAnimation: false
};
Map.propTypes = {
    zoom: PropTypes.number,
    center: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
        PropTypes.shape({ lat: PropTypes.number, lon: PropTypes.number }),
    ]),
    position: PropTypes.oneOf(['topleft', 'topright', 'bottomleft', 'bottomright']),
    maxZoom: PropTypes.number,
    bounds: PropTypes.object,
};
Map.childContextTypes = {
    map: PropTypes.object,
    original: PropTypes.object,
};
