import React from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { companiesSettingsSelectors } from 'railfleet_state/ducks/companies_settings';

import './CustomFieldDisplay.scss';
import TextDisplay from '../textDisplay/TextDisplay';
import CutForm from '../../forms/RailForms/cutForm/CutForm';
import NoDataDisplay from '../noDataDisplay/NoDataDisplay';
import MultiDeviceLayout from '../../app/MultiDeviceLayout';
import MultiTextDisplay from '../multiTextDisplay/MultiTextDisplay';

const CustomFieldDisplayComponent = (props) => {
    if (props.public) {
        return (
            <GenericCustomFieldDisplay
                {...props}
                fields={props.fields}
                className="public custom-fields"
            />
        );
    }
    return (
        <GenericCustomFieldDisplay
            {...props}
            fields={props.fields}
            className="private custom-fields"
        />
    );
};

const GenericCustomFieldDisplay = (props) => {
    if (!props.fields || props.fields.length === 0) return null;
    const fields = props.fields.map((custom_field) => {
        const { key } = custom_field;
        const { label } = custom_field;
        const data_id = props.data[key];
        let data;
        if (custom_field.data_type === 'enum') {
            if (Array.isArray(data_id)) data = [];
            for (const value of custom_field.values) {
                if (Array.isArray(data_id)) {
                    for (const id of data_id) {
                        if (value.value === id) data.push(value.label);
                    }
                }
                if (value.value === data_id) data = value.label;
            }
            if (!data && custom_field.default_value) {
                for (const value of custom_field.values) {
                    if (value.value === custom_field.default_value) data = value.label;
                }
            }
        } else if (custom_field.data_type === 'bool') {
            data = data_id ? i18n.t('Yes') : i18n.t('No');
            if (!data) {
                data = custom_field.default_value;
            }
        } else if (custom_field.data_type === 'date') {
            data = data_id && moment(data_id).format('DD/MM/YYYY HH:mm');
            if (!data && custom_field.default_value) {
                data = moment(custom_field.default_value).format('DD/MM/YYYY HH:mm');
            }
        } else {
            data = data_id;
        }
        if (!data) {
            return (
                <NoDataDisplay
                    key={custom_field.id}
                    label={label}
                />
            );
        }
        if (Array.isArray(data)) {
            return (
                <MultiTextDisplay
                    key={custom_field.id}
                    id={custom_field.id}
                    label={label}
                    data={data}
                    large={!custom_field.small}
                />
            );
        }
        return (
            <TextDisplay
                key={custom_field.id}
                label={label}
                data={data}
                large={!custom_field.small}
            />
        );
    });
    return (
        <div className={`${props.className} ${props.device}`}>
            <CutForm
                device={props.device}
            >
                { fields }
            </CutForm>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    if (props.loginless) return props.mapStateToProps(state, props);
    const { asset_id } = props;
    const asset = assetsSelectors.getAssetInfos(state, asset_id);

    let content_options = null;
    if (props.content_options) {
        if (Array.isArray(props.content_options)) {
            content_options = props.content_options;
        } else {
            content_options = [props.content_options];
        }
    }
    let fields;
    if (props.public) {
        if (asset_id) {
            let publicFieldConfigSelector;
            if (props.content_type) {
                if (!content_options) {
                    // eslint-disable-next-line max-len
                    publicFieldConfigSelector = assetsSelectors.makePublicFieldsForAssetWithContentType(
                        props.content_type,
                    );
                } else {
                    // eslint-disable-next-line max-len
                    publicFieldConfigSelector = assetsSelectors.makePublicFieldsForAssetWithContentTypeAndOptions(
                        props.content_type,
                        content_options,
                    );
                }
            } else {
                publicFieldConfigSelector = assetsSelectors.makePublicFieldsForAsset();
            }
            fields = publicFieldConfigSelector(state, asset_id);
        }
    } else if (props.content_type) {
        if (!props.content_options) {
            fields = companiesSettingsSelectors.getPrivateFieldsForUserWithContentType(
                props.content_type,
            )(state);
        } else {
            fields = companiesSettingsSelectors.getPrivateFieldsForUserWithContentTypeAndOptions(
                props.content_type,
                content_options,
            )(state);
        }
    } else {
        fields = companiesSettingsSelectors.getPrivateFieldsForUser(state);
    }
    return {
        asset,
        fields,
    };
};

const CustomFieldDisplay = (props) => (
    <MultiDeviceLayout>
        <CustomFieldDisplayComponent {...props} />
    </MultiDeviceLayout>
);

export default connect(mapStateToProps)(CustomFieldDisplay);
