import React from 'react';
import { LoginlessContext } from './loginlessComponent';

const WithLoginless = (Component) => (props) => (
    <LoginlessContext.Consumer>
        { (context) => <Component {...{ ...props, ...context }} /> }
    </LoginlessContext.Consumer>
);

export default WithLoginless;
