/**
 * Created by lpostula on 12/05/17.
 */
import { createReducer } from '../../utils';
import config from './config';
import maintenanceEventsTypes from '../maintenance_events/types';

// If you want to track more than:
// - normal_polling
// - long_polling
// You can specified that here, the following state will be merged
// with the generated state

const overideInitialState = {
    maintenance_events_loading: {
        scheduling: {},
    },
};

// Method to construct the state, for each key defined in
// the `generate` var (which will be by default feed from the `loading` var in
// the config file) , it will add it as a key in the state, associated with
// an object composed from the normal/long polling counter and any value defined
// in the `override` var which by default will be the overideInitioalState.
const constructInitialState = (generate = {}, overide = {}) => {
    const initial_state = {};
    for (const state_key in generate) {
        initial_state[state_key] = {
            normal_polling: 0,
            long_polling: 0,
            ...overide[state_key],
        };
    }
    return initial_state;
};

// helper method that handle the update of the state for a particuliar loader
// it handle the long / normal polling based on the action.
const updatePolling = (state, action, key, value) => {
    const long_polling = action.meta && action.meta.long_polling;
    const sub_state = {
        ...state[key],
        normal_polling: state[key].normal_polling + (long_polling ? 0 : value),
        long_polling: state[key].long_polling + (long_polling ? value : 0),
    };
    return { ...state, [key]: sub_state };
};

// this is the core of the reducer, for each key in `loading` et will map
// the request action and the complete action and call the `updatePolling`
// helper accordingly
const generateReducerMap = (generate = {}) => {
    const map = {};
    for (const state_key in generate) {
        const type_module = generate[state_key];
        let request;
        let complete;
        if (Array.isArray(type_module)) {
            // the users provided a list we dont need to auto fetch the type
            if (type_module.length !== 2) {
                throw new Error(`${state_key} dont have the required number of keys`);
            }
            request = type_module[0];
            complete = type_module[1];
        } else {
            request = type_module.FETCH_LIST_REQUEST;
            complete = type_module.FETCH_LIST_COMPLETED;
            if (!request || !complete) {
                throw new Error(`FETCH_LIST_REQUEST, FETCH_LIST_COMPLETED not found in type module ${state_key}`);
            }
        }
        map[[request]] = (state, action) => (
            updatePolling(state, action, state_key, 1)
        );
        map[[complete]] = (state, action) => (
            updatePolling(state, action, state_key, -1)
        );
    }
    return map;
};

// here you can define your custom reducing logic, for example, if you've
// specified some key in the `overrideInitialState`
const reducerMap = () => Object.assign(
    generateReducerMap(config.loading),
    {
        [maintenanceEventsTypes.RUN_SCHEDULER_REQUEST]: (state, action) => {
            const change = {};
            change[action.meta.asset] = 1; // 1 is loading
            return {

                ...state,
                maintenance_events_loading: {

                    ...state.maintenance_events_loading,
                    scheduling: {

                        ...state.maintenance_events_loading.scheduling,
                        ...change,
                    },
                },
            };
        },
        [maintenanceEventsTypes.RUN_SCHEDULER_FAILED]: (state, action) => {
            const change = {};
            change[action.meta.asset] = -1; // -1 is failed
            return {

                ...state,
                maintenance_events_loading: {

                    ...state.maintenance_events_loading,
                    scheduling: {

                        ...state.maintenance_events_loading.scheduling,
                        ...change,
                    },
                },
            };
        },
        [maintenanceEventsTypes.RUN_SCHEDULER_COMPLETED]: (state, action) => {
            const change = {};
            change[action.meta.asset] = 0; // 0 is not loading
            return {

                ...state,
                maintenance_events_loading: {

                    ...state.maintenance_events_loading,
                    scheduling: {

                        ...state.maintenance_events_loading.scheduling,
                        ...change,
                    },
                },
            };
        },
    },
);

export const testExports = {
    constructInitialState,
    updatePolling,
    generateReducerMap,
    reducerMap,
};
export default createReducer(
    constructInitialState(config.loading, overideInitialState),
)(reducerMap());
