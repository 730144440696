import React, { useContext } from 'react';
import './FocusableFrame.scss';
import DetailTitle from '../../headers/detailTitle/DetailTitle';
import { ContextDevice, LAYOUT, withMultiDeviceLayout } from '../../app/MultiDeviceLayout';

const IconChevronRight = (props) => (
    <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.65161 4.62496L1.99996 7.27661" stroke={props.color} strokeWidth="2" strokeLinecap="square" />
        <path d="M2.02661 1.99999L4.4131 4.38647" stroke={props.color} strokeWidth="2" strokeLinecap="square" />
    </svg>
);

const VerticalSeparator = (props) => (
    <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="24" stroke={props.color} />
    </svg>
);

const TitleRenderer = ({ left_part, right_part }) => {
    if (left_part.length === 0) {
        return (
            <div className="detail-title">
                <div className="title-right-part">
                    { `${right_part.join(' - ')}` }
                </div>
            </div>
        );
    }
    return (
        <div className="detail-title">
            <div className="title-left-part">
                {
                    left_part.map((elm, index) => (
                        <div className="element-left-part" key={elm}>
                            { elm }
                            {
                                index !== left_part.length - 1
                                    && <IconChevronRight color="#717B8C" />
                            }
                        </div>
                    ))
                }
            </div>
            <VerticalSeparator color="#717B8C" />
            <div className="title-right-part">
                { `${right_part.join(' - ')}` }
            </div>
        </div>
    );
};

const FocusableFrame = (props) => {
    const { device } = useContext(ContextDevice);
    const _device = (props.noTabletDisplay && device === LAYOUT.TABLET)
        ? LAYOUT.MOBILE
        : device;
    return (
        <div className={`focusable-frame ${_device}${props.focus ? ' focus' : ''}${props.light ? ' light' : ''} ${props.className}`}>
            {
                props.title
                    && (
                        <div className="focusable-frame-title">
                            <DetailTitle
                                title={props.title}
                            />
                        </div>
                    )
            }
            {
                !props.title && props.titleComponent
                    && (
                        <div className="focusable-frame-title">
                            <TitleRenderer
                                left_part={props.titleComponent.left_part}
                                right_part={props.titleComponent.right_part}
                            />
                        </div>
                    )

            }
            <div className="focusable-frame-content">
                {props.children}
            </div>
        </div>
    );
};

export default withMultiDeviceLayout(FocusableFrame);
