import React, { useState, useEffect, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactSelectWrapper from '../ReactSelectWrapper';
import { StepContext } from '../step/Step';
import './FormCheckbox.scss';
import HintIcon from './hint.svg';
import FormLabel from '../formLabel/FormLabel';

export const CHECKBOX_THEMES = {
    BIG_BOX: 'big_box',
};

const Checkboxes = (props) => {
    const [_selection, setSelection] = useState({});

    // This is to be used with the Step Manager.
    // It allow to do Forms with multiple steps.
    // We have to register each field in the Step Manager to validate fields on each step instead of
    // validating each fields at the end of the form with the submit button.
    const stepContext = useContext(StepContext);
    useEffect(() => {
        if (stepContext && stepContext.addKey) stepContext.addKey(stepContext.step, props.id);
    }, []);

    useEffect(() => {
        if (props.defaultValue && props.defaultValue.length > 0) {
            const newSelection = { ..._selection };
            for (const val of props.defaultValue) {
                newSelection[val] = true;
            }
            setSelection(newSelection);
        }
    }, []);

    useEffect(() => {
        const keys = [];
        for (const key of Object.keys(_selection)) {
            keys.push(parseInt(key, 10));
        }
        props.onChange(keys);
    }, [_selection]);

    const onChange = (id_elm) => {
        if (props.disabled) return;
        const newSelection = { ..._selection };
        if (newSelection[id_elm]) {
            delete newSelection[id_elm];
        } else {
            newSelection[id_elm] = true;
        }
        setSelection(newSelection);
    };

    return _.map(props.values, (elm) => {
        const elm_is_selected = _selection[props.id_getter(elm)];
        const custom_class_getter = (props.custom_class_getter && props.custom_class_getter(elm)) || '';
        return (
            <div className="form_checkbox_elm" key={props.id_getter(elm)}>
                <input
                    type="checkbox"
                    name={props.id_getter(elm)}
                    checked={!!elm_is_selected}
                    onChange={() => onChange(props.id_getter(elm))}
                    disabled={props.disabled}
                />
                <div
                    className={`form_checkbox_elm_label ${custom_class_getter}`}
                    onClick={() => onChange(props.id_getter(elm))}
                >
                    <span>{props.labelizer(elm)}</span>
                </div>
            </div>
        );
    });
};

const Radio = (props) => {
    const [_selected, setSelected] = useState(null);

    useEffect(() => {
        if (props.defaultValue !== undefined) {
            setSelected(props.defaultValue);
        }
    }, []);

    useEffect(() => {
        if (props.selectedValue !== undefined) {
            setSelected(props.selectedValue);
        }
    }, [props.selectedValue]);

    useEffect(() => {
        props.onChange(_selected);
    }, [_selected]);

    const onChange = (id_elm) => {
        if (props.disabled) return;
        setSelected(id_elm);
    };

    return _.map(props.values, (elm) => {
        const elm_is_selected = props.id_getter(elm) === _selected;
        const custom_class_getter = (props.custom_class_getter && props.custom_class_getter(elm)) || '';
        return (
            <div key={props.id_getter(elm)}>
                <div
                    className={`form_checkbox_elm ${props.custom_checkbox ? 'custom' : ''} ${props.theme ? props.theme : ''} ${elm_is_selected ? 'checked' : ''}`}
                    onClick={() => !props.disabled && !elm.disabled && onChange(props.id_getter(elm))}
                >
                    <input
                        type="radio"
                        name={`${props.id}_${props.id_getter(elm)}`}
                        value={props.id_getter(elm)}
                        checked={elm_is_selected}
                        onChange={() => onChange(props.id_getter(elm))}
                        disabled={props.disabled || elm.disabled}
                    />
                    {
                        props.custom_checkbox && elm_is_selected
                            && (
                                <div
                                    className="after-radio"
                                >
                                    <div
                                        className="inner-after-radio"
                                    />
                                    <img src={props.custom_checkbox} alt="radio checked" />
                                </div>
                            )
                    }
                    <div
                        className={`form_checkbox_elm_label ${custom_class_getter}`}
                        onClick={() => !props.disabled && !elm.disabled && onChange(props.id_getter(elm))}
                    >
                        <span>{props.labelizer(elm)}</span>
                    </div>
                </div>
                {
                    elm.description
                    && (
                        <div className="checkbox-description-holder">
                            <img className="hint-icon" src={HintIcon} alt="hint icon" />
                            <div className="checkbox-description">{elm.description}</div>
                        </div>
                    )
                }
            </div>
        );
    });
};

const FormCheckbox = (props) => {
    const {
        errors, watch, setValue, clearErrors,
    } = useFormContext();
    const valueError = errors && errors[props.id];

    const validate = (data) => {
        if (!data || (Array.isArray(data) && data.length === 0)) return null;
        return data;
    };

    useEffect(() => {
        if (props.selectedValue !== undefined) {
            if (props.selectedValue !== watch(props.id)) {
                setValue(props.id, props.selectedValue);
                clearErrors();
            }
        }
    }, [props.selectedValue]);

    return (
        <div className={`form form_checkbox ${props.className ? props.className : ''} ${props.id} ${!props.label ? 'no-label' : ''}`} key={props.id}>
            <FormLabel {...props} />
            <div className="form_checkbox_holder">
                <ReactSelectWrapper
                    classname="checkbox"
                    // eslint-disable-next-line react/no-children-prop
                    children={props.multi ? Checkboxes : Radio}
                    name={props.id}
                    validate={validate}
                    {..._.omit(props, 'children')}
                />
                { props.children }
            </div>
            {valueError && (
                <div className="form-validation-error">
                    <div className="form-validation-error-arrow" />
                    <div className="form-error-message">
                        {
                            (valueError.message)
                                ? <div>{valueError.message}</div>
                                : <div>Please enter a value</div>
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

FormCheckbox.defaultProps = {
    id_getter: (val) => val.id,
    labelizer: (val) => val.label,
    selectedValue: undefined,
};

export default FormCheckbox;
