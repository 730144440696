import React from 'react';

const RenderText = (props) => (
    <textarea
        defaultValue={props.children}
        cols={30}
        rows={5}
        disabled
    />
);

export default RenderText;
