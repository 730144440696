import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import moment from 'moment-timezone';
import components from 'components';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { assetOperationalStatusOperations, assetOperationalStatusSelectors } from 'railfleet_state/ducks/asset_operational_status';
import { usersSelectors } from 'railfleet_state/ducks/users';
import './OperationalStatusList.scss';

const { Table } = components.tables;
const { MultiDeviceLayout, AuthorizedComponent } = components.app;

const sort_by_date = (a, b) => moment(b.date_started) - moment(a.date_started);

const OperationalStatusListComponent = (props) => {
    const [_data, setData] = useState([]);
    useEffect(() => {
        if (props.asset_id) {
            props.fetchStatusForAsset(props.asset_id);
        }
    }, []);

    useEffect(() => {
        if (props.asset_statuses) {
            const statuses = props.asset_statuses.sort(sort_by_date).slice(0, 10);
            setData(statuses);
        } else {
            setData([]);
        }
    }, [props.asset_statuses]);

    const OperationalStatusRenderer = (cellData) => {
        const status = props.operational_status_type[cellData]
            && props.operational_status_type[cellData].name;
        return (
            <div className="operational-status-renderer">
                <span>{status || '-'}</span>
            </div>
        );
    };

    const DetailedStatusRenderer = (cellData) => {
        const status = props.detailed_operational_status_type[cellData]
            && props.detailed_operational_status_type[cellData].name;
        return (
            <div className="detailed-status-renderer">
                <span>{status || '-'}</span>
            </div>
        );
    };

    const DateRenderer = (cellData) => (
        <span className="renderer-date">
            {moment(cellData).format('DD/MM/YYYY HH:mm')}
        </span>
    );

    const UserRenderer = (cellData) => (
        <span className="user-formater">
            {`${cellData && props.users[cellData] ? props.users[cellData].full_name : '-'}`}
        </span>
    );

    const CommentRenderer = (cellData) => (
        <div className="comment-renderer">
            <span>{cellData || '-'}</span>
        </div>
    );

    const columns = [
        {
            label: i18n.t('Operational status'), key: 'status', renderer: OperationalStatusRenderer, sortable: false,
        },
        {
            label: i18n.t('Detailed status'), key: 'detailed_status', renderer: DetailedStatusRenderer, sortable: false,
        },
        {
            label: i18n.t('Date started'), key: 'date_started', renderer: DateRenderer, sortable: false,
        },
        {
            label: i18n.t('Created by'), key: 'user_started', renderer: UserRenderer, sortable: false,
        },
        {
            label: i18n.t('Comment'), key: 'comment', renderer: CommentRenderer, sortable: false,
        },
    ];

    const mobile_arrangement = {
        primary_shorts: [
            { key: 'status' },
            { key: 'detailed_status' },
        ],
        secondary_shorts: [],
        secondary_longs: [
            { key: 'user_started' },
            { key: 'date_started' },
        ],
        primary_long: { key: 'comment' },
    };

    return (
        <div className="operational-status-list-holder">
            <div className="operational-status-list">
                <AuthorizedComponent
                    asset_id={props.asset_id}
                    permission_name="view_historic_status"
                >
                    <Table
                        data={_data}
                        data_unique_id="id"
                        columns={columns}
                        noTabletDisplay
                        mobile_arrangement={mobile_arrangement}
                        smallPlaceholder={props.smallPlaceholder}
                    />
                </AuthorizedComponent>
            </div>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const { asset_id } = props;
    const asset = asset_id && assetsSelectors.getAssetInfos(state, asset_id);
    const status_selector = assetOperationalStatusSelectors.makeStatusListForAsset();
    const asset_statuses = asset_id && status_selector(state, asset_id);
    const users = usersSelectors.getUsers(state);
    const getMaintenanceSettings = assetsSelectors.makeMaintenanceSettingsForAsset();
    const maintenance_settings = asset_id && getMaintenanceSettings(state, asset_id);
    const operational_status_type = maintenance_settings
        && maintenance_settings.operational_status_type;
    const detailed_operational_status_type = maintenance_settings
        && maintenance_settings.detailed_operational_status_type;
    return {
        asset,
        users,
        maintenance_settings,
        asset_statuses: asset_statuses || [],
        operational_status_type: operational_status_type || {},
        detailed_operational_status_type: detailed_operational_status_type || {},
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchStatusForAsset: (asset_id) => dispatch(assetOperationalStatusOperations.fetchForAsset(asset_id)),
});

const OperationalStatusList = (props) => (
    <MultiDeviceLayout>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <OperationalStatusListComponent
            {...props}
        />
    </MultiDeviceLayout>
);

OperationalStatusList.defaultProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(OperationalStatusList);
