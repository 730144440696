/**
 * Created by lpostula on 17/05/17.
 */
import { createSelector } from 'reselect';

// eslint-disable-next-line no-unused-vars
const getRestrictions = (state) => state.restrictions;

const getRestrictionsForAsset = (state, asset_id) => _.filter(
    state.restrictions,
    ((s) => s.asset === asset_id),
);

const getRestrictionsByEvent = (state, event_id) => _.filter(
    state.restrictions,
    ((s) => s.event === event_id),
);

const getRestrictionsById = (state, restriction_id) => state.restrictions[restriction_id];

const getRestrictionsByAsset = (state) => {
    const asset_dict = {};

    for (const id in state.restrictions) {
        const r = state.restrictions[id];
        // I keep only open restriction but this can be discussed
        // if the scope of this function get bigger
        if (r.state === 'closed') {
            continue;
        }
        if (!asset_dict[r.asset]) {
            asset_dict[r.asset] = [];
        }
        asset_dict[r.asset].push(r);
    }
    return asset_dict;
};

export const makeRestrictionsForAsset = () => createSelector(
    [getRestrictionsForAsset],
    (restrictions) => restrictions,
);

export const makeRestrictionsListForAsset = () => {
    const restrictions_for_asset = makeRestrictionsForAsset();
    return createSelector(
        [restrictions_for_asset],
        (restrictions) => _.values(restrictions),
    );
};

export default {
    makeRestrictionsForAsset,
    makeRestrictionsListForAsset,
    getRestrictionsByAsset,
    getRestrictionsByEvent,
    getRestrictionsById,
};
