import L from 'leaflet';
import 'leaflet-control-geocoder';

import MapControl from './MapControl';
import './Geocoder.css';

window.ZoneGeocoder = L.Class.extend({
    geocode(query, cb, context) {
        $.getJSON('/api/place/', {
            search: query,
            format: 'json',
        }, (data) => {
            const results = [];
            if (data.count > 0) {
                for (let i = data.results.length - 1; i >= 0; i -= 1) {
                    const result = data.results[i];
                    const { bbox } = result;
                    let categs = '';
                    if (result.label_set.length > 0) {
                        categs = ` [${result.label_set.join(', ')}]`;
                    }
                    const html = `<b>${result.name}</b><br/><span class="leaflet-control-geocoder-address-detail">${result.company}${categs}</span>`;
                    results[i] = {
                        name: result.name,
                        // add some buffer
                        bbox: L.latLngBounds([
                            bbox[0] - 0.01,
                            bbox[1] - 0.01,
                        ], [bbox[2] + 0.01,
                            bbox[3] + 0.01,
                        ]),
                        // center: L.latLng(result.point),
                        points: result.points,
                        html,
                    };
                }
            }
            cb.call(context, results);
        }, this, 'jsonp');
    },

    reverse(location, scale, cb, context) {
        // Not implemented we don't need it
        cb.call(context, []);
    },
});

window.markGeocodeZone = function markGeocodeZone(result) {
    this._map.fitBounds(result.geocode.bbox);

    if (this._geocodeMarker) {
        this._map.removeLayer(this._geocodeMarker);
    }

    if (result.geocode.center !== null && result.geocode.center !== undefined) {
        // default logic from leaflet-control-geocode to only show a point
        this._geocodeMarker = new L.Marker(result.geocode.center)
            .bindPopup(result.geocode.html || result.geocode.name)
            .addTo(this._map)
            .openPopup();
    } else {
        // logic to show a zone (polygon)
        this._geocodeMarker = new L.Polygon(result.geocode.points)
            .bindPopup(result.geocode.html || result.geocode.name)
            .addTo(this._map)
            .openPopup();
    }
    return this;
};

window.MultiGeocoder = L.Class.extend({
    initialize() {
        this.zone_geocoder = new window.ZoneGeocoder();
        this.nominatim_geocoder = new L.Control.Geocoder.Nominatim();
    },
    geocode(query, cb, context) {
        let zone_result = null;
        let nominatim_result = null;

        function checkComplete() {
            // check we have both response and launch callback
            if (zone_result !== null && nominatim_result !== null) {
                const results = zone_result.concat(nominatim_result);
                cb.call(context, results);
            }
        }

        function zone_results_cb(results) {
            zone_result = results;
            checkComplete();
        }

        function nominatim_results_cb(results) {
            nominatim_result = results;
            checkComplete();
        }
        this.nominatim_geocoder.geocode(query, nominatim_results_cb, context);
        this.zone_geocoder.geocode(query, zone_results_cb, context);
    },

    reverse(location, scale, cb, context) {
        // Not implemented we don't need it
        cb.call(context, []);
    },
});

class Geocoder extends MapControl {
    createLeafletElement() {
        // TODO not set position to 'topright' everytime but
        // get it from the props.
        const geocoder = L.Control.geocoder({
            position: 'topright',
            geocoder: new window.MultiGeocoder(),
        });

        // overide function to display Zone instead of marker
        geocoder.markGeocode = window.markGeocodeZone;

        return geocoder;
    }
}

export default Geocoder;
