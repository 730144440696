/**
 * Created by lpostula on 10/05/17.
 */
import { default as actions } from './actions';

export const { fetchMe } = actions;
export const { fetchList } = actions;
export const { setLanguage } = actions;
export const { setTimezone } = actions;

export default {
    fetchMe,
    fetchList,
    setLanguage,
    setTimezone,
};
