import moment from 'moment-timezone';

const strToMoment = (str, dateFormat, timezone) => {
    const temp_str = str.trim();
    const temp_date = moment.tz(temp_str, dateFormat, true, timezone);
    if (temp_date.isValid()) {
        return temp_date;
    }
    return null;
};

const concatDateTimeFormats = (dateFormat, timeFormat) => {
    if (timeFormat && dateFormat) {
        return `${dateFormat} ${timeFormat}`;
    }
    return dateFormat || timeFormat;
};

export { concatDateTimeFormats, strToMoment };
