import React from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { usersOperations } from 'railfleet_state/ducks/users';
import Selector from '../../selectors/selector/Selector';

import './user_menu.css';

const available_languages = [
    { id: 'en', name: 'English' },
    { id: 'fr', name: 'Français' },
    { id: 'nl', name: 'Nederlands' },
    { id: 'de', name: 'Deutsch' },
    { id: 'it', name: 'Italiano' },
    { id: 'sv', name: 'Svenska' },
];
const available_timezones = moment.tz.names().map((label) => ({ id: label, label }));

const UserMenu = (props) => {
    const onChangeLanguage = (arg) => {
        const value = arg[0];
        window.localStorage.setItem('user_language', value);
        props.onChangeLanguage(props.user_id, value).then(() => {
            location.reload();
        });
    };
    const onChangeTimezone = (arg) => {
        const value = arg[0];
        window.localStorage.setItem('user_language', value);
        props.onChangeTimezone(props.user_id, value).then(() => {
            location.reload();
        });
    };
    return (
        <div className="user-menu">
            { props.user_is_staff
                && <a href="/admin/">{i18n.t('Go to administration')}</a>}
            <div className="user-language-selector">
                <label
                    htmlFor="user_language_chooser"
                    className="control-label"
                >
                    {i18n.t('Language')}
                </label>
                <Selector
                    id="user_language_chooser"
                    name="user_language_chooser"
                    values={available_languages}
                    selected={props.user_language}
                    labelizer={(elm) => elm.name}
                    id_getter={(elm) => elm.id}
                    onChange={onChangeLanguage}
                    clearable={false}
                />
            </div>

            <div className="user-timezone-selector">
                <label
                    htmlFor="user_timezone_chooser"
                    className="control-label"
                >
                    {i18n.t('Timezone')}
                </label>
                <Selector
                    id="user_timezone_chooser"
                    name="user_timezone_chooser"
                    values={available_timezones}
                    selected={props.user_timezone}
                    onChange={onChangeTimezone}
                    clearable={false}
                />
            </div>

            <a href={props.logout_url}>{i18n.t('Logout')}</a>
        </div>
    );
};

const mapNavbarUserProfileStateToProps = (state) => ({
    user_id: state.users.me.id,
    user_is_staff: state.users.me.is_staff,
    user_language: state.users.me.language,
    user_timezone: state.users.me.timezone,
});

const mapNavbarUserProfileDispatchToProps = (dispatch) => ({
    onChangeLanguage(user_id, lng) {
        return dispatch(usersOperations.setLanguage(user_id, lng));
    },
    onChangeTimezone(user_id, timezone) {
        return dispatch(usersOperations.setTimezone(user_id, timezone));
    },
});

export default connect(mapNavbarUserProfileStateToProps,
    mapNavbarUserProfileDispatchToProps)(UserMenu);
