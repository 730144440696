/**
 * Created by lpostula on 28/09/17.
 */
import { default as actions } from './actions';

export const { fetchList } = actions;
export const { fetchForLog } = actions;
export const { updateDocument } = actions;
export const { createDocument } = actions;
export const { deleteDocument } = actions;
export const { setDocumentInStore } = actions;

export const incomingWS = (data) => (dispatch) => {
    switch (data.type) {
    case 'update':
        dispatch(actions.setDocumentInStore(data.payload));
        break;
    default:
        break;
    }
};

export default {
    fetchList,
    fetchForLog,
    updateDocument,
    createDocument,
    deleteDocument,
    setDocumentInStore,
    incomingWS,
};
