/**
 * Created by lpostula on 10/05/17.
 */
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import * as reducers from './ducks';
import filteringReducer from './ducks/filtering/reducers';
import filteringTypes from './ducks/filtering/types';
import { createSnackBar } from './ducks/ui/operations';
import { reloadPage } from './ducks/ui/actions';
import types from './ducks/telematics/types';

let _store = null;
let _persistor = null;

const whitelist_duck = [];

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'company', 'maintenance_settings', 'companies_settings',
        'asset_classes', 'filtering', 'label_category', 'delegations',
        'ux',
        // 'assets', 'users',
    ],
};

const httpErrorCatcherMiddleware = (store) => (next) => (action) => {
    if (action.error === true) {
        if (action.meta && action.meta.noToast) {
            return next(action);
        }
        if (action.payload && action.payload.constructor.name === 'ApiError') {
            console.error('ApiError', action.payload);

            switch (action.payload.status) {
            case 401: // the user is logged out
                // Show the reload modal
                store.dispatch(reloadPage({
                    supernova_version: null,
                    message: { [window.settings.lng]: 'You are logged out, reload the page to login again.' },
                }));
                break;
            case 500: // server error
                // show a small alert
                store.dispatch(createSnackBar({
                    id: 'error-500',
                    duration: 10000, // ms
                    message: 'Our server answered with an error, retry in a few minutes.',
                    className: 'warning',
                    dismissible: true,
                }));
                break;
            case 503: // request timeout
                // show a small alert
                store.dispatch(createSnackBar({
                    id: 'error-503',
                    duration: 10000, // ms
                    message: 'Your request timeouted, retry in a few minutes.',
                    className: 'warning',
                    dismissible: true,
                }));
                break;
            default:
                // show a small alert
                if (!action.type === types.FETCH_LIST_FAILED) {
                    store.dispatch(createSnackBar({
                        id: 'error-default',
                        duration: 10000, // ms
                        message: `Unexpected error: ${action.payload.status}.`,
                        className: 'warning',
                        dismissible: true,
                    }));
                }
            }
        }
    }
    return next(action);
};

export default function configureStore(cb, initialState) {
    if (!_store || !_persistor) {
        let middleware = [thunk, apiMiddleware];
        if (process.env.NODE_ENV === 'development') {
            const loggerMiddleware = createLogger({
                logger: console,
                level: {
                    prevState: 'debug',
                    nextState: 'debug',
                    action: 'info',
                    error: 'error',
                },
                collapsed: (getState, action, logEntry) => !logEntry.error,
                predicate: (getState, action) => whitelist_duck.some((duck) => duck.test(action.type)),
            });
            middleware = [...middleware, loggerMiddleware, httpErrorCatcherMiddleware];
        }

        // here we go for the reducing
        // we need to use a custom logic for the filter reducer, because
        // this reducer need access to all of the state, but the other don't.
        // first come the combinedReducer, this is the normal use cases.
        // your ducks will use that
        const combinedReducer = combineReducers(reducers);
        // this is a reducing function that will pass the all state.
        const reducerWithAllPassedState = (state, action) => filteringReducer(state, action);
        // let new_state = filteringReducer(state, action);
        // // if you ever need some new reducer to have the all state,
        // // add them here
        // return new_state;

        // here is the rootReducer, that first call the combined reducer,
        // then apply the reducer that need the all state to it.
        const rootReducer = (state, action) => {
            const intermediateState = combinedReducer(state, action);
            return reducerWithAllPassedState(intermediateState, action);
        };

        const persistedReducer = persistReducer(persistConfig, rootReducer);
        _store = createStore(
            persistedReducer,
            initialState,
            applyMiddleware(...middleware),
        );

        _persistor = persistStore(
            _store,
            null,
            () => {
                _store.dispatch({ type: filteringTypes.INIT_FILTER });
                cb(_store);
            },
        );
    }
    return { store: _store, persistor: _persistor };
}
