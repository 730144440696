import React from 'react';

import '../plusTextAction/PlusTextAction.scss';

const TextAction = (props) => (
    <div
        className={`plus-action ${props.disabled ? 'disabled' : ''}${props.selected ? ' selected' : ''}`}
        onClick={props.disabled ? () => null : props.onClick}
    >
        <span className="plus-action-text">{props.text || props.label}</span>
    </div>
);

export default TextAction;
