/**
 * Created by lpostula on 15/05/17.
 */
import { RSAA } from 'redux-api-middleware';
import types from './types';
import { API_ROOT } from '../../config';

const API_RESOURCE_NAME = 'asset_operational_status';

export const fetchForAsset = (asset_id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}.json?asset=${asset_id}`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_LIST_REQUEST,
            types.FETCH_LIST_COMPLETED,
            types.FETCH_LIST_FAILED,
        ],
    },
});

export const updateStatus = (values) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${values.id}.json`,
        method: 'PATCH',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(values),
        credentials: 'same-origin',
        types: [
            types.UPDATE_DETAILS_REQUEST,
            types.UPDATE_DETAILS_COMPLETED,
            types.UPDATE_DETAILS_FAILED,
        ],
    },
});

export const createStatus = (values) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(values),
        credentials: 'same-origin',
        types: [
            types.CREATE_EVENT_REQUEST,
            types.CREATE_EVENT_COMPLETED,
            types.CREATE_EVENT_FAILED,
        ],
    },
});

export const updateOperationalStatusInStore = (data) => ({
    type: types.FETCH_DETAIL_COMPLETED,
    payload: data,
});

export const removeOperationalStatusInStore = (group_id) => ({
    type: types.DELETE_COMPLETED,
    meta: {
        id: group_id,
    },
});

export default {
    fetchForAsset,
    updateStatus,
    createStatus,
    updateOperationalStatusInStore,
    removeOperationalStatusInStore,
};
