import React from 'react';
import './LateIcon.scss';
import Important from './Important.svg';

const LateIcon = (props) => (
    <div>
        <div className="late-icon-holder">
            <img className="late-icon" src={Important} alt="important" />
            <span className="late-text">{props.text}</span>
        </div>
    </div>
);

export default LateIcon;
