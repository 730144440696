/**
 * Created by lpostula on 11/10/17.
 */
import React from 'react';
import PropTypes from 'prop-types';

import EditLogo from './editLogo.svg';

import './PrimaryModal.scss';

// todo use a redux action to modify the body class
// instead of this jquery selector
class _PrimaryModal extends React.Component {
    constructor(props) {
        super(props);
        this.close = this.close.bind(this);
        this.action = this.action.bind(this);
    }

    close() {
        if (this.props.paths) {
            this.props.router.push(this.props.paths.close);
        } else {
            this.props.actions.close();
        }
    }

    action() {
        if (this.props.paths) {
            this.props.router.push(this.props.paths.action);
        } else {
            this.props.actions.action();
        }
    }

    componentDidMount() {
        if (document.body.classList) {
            document.body.classList.add('modal-open');
        }
    }

    componentWillUnmount() {
        if (document.body.classList) {
            document.body.classList.remove('modal-open');
        }
    }

    render() {
        return (
            <div className="modal" id={this.props.id || ''}>
                <div className="modal-dialog">
                    <div className={`modal-content ${this.props.className}`}>
                        <div className="modal-header">
                            <div
                                className="pull-right"
                                style={{
                                    marginRight: 10,
                                }}
                            >
                                <div className="extra-action">
                                    { this.props.extraActions }
                                </div>
                                {
                                    this.props.actionText
                                        && (
                                            <a className="btn btn-borderless btn-icon" onClick={this.action}>
                                                <img src={EditLogo} alt="edit logo" />
                                                <div className="label edit">{this.props.actionText}</div>
                                            </a>
                                        )
                                }
                            </div>
                            <h2>{this.props.title}</h2>
                            <a className="btn-close" onClick={this.close}>
                                <span className="glyphicon glyphicon-remove" />
                            </a>
                        </div>
                        <div className="modal-body">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

_PrimaryModal.defaultProps = {
    actionText: '',
    className: '',
};

_PrimaryModal.propTypes = {
    paths: PropTypes.shape({
        close: PropTypes.string,
        action: PropTypes.string,
    }),
    actionText: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
};

const PrimaryModal = _PrimaryModal;

export default PrimaryModal;
