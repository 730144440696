import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import moment from 'moment-timezone';
import components from 'components';
import { interventionsOperations, interventionsSelectors } from 'railfleet_state/ducks/interventions';
import { maintenanceEventsOperations, maintenanceEventsSelectors } from 'railfleet_state/ducks/maintenance_events';
import { uxSelectors, uxOperations } from 'railfleet_state/ducks/ux';
import { usersSelectors } from 'railfleet_state/ducks/users';
import './EventsPart.scss';
import useFetch from 'components/hooks/useFetch';
import SmallDocumentHolder from '../../../../../dashboard/static/event/smalDocumentHolder';
import CloseEventForm from '../../forms/closeEventForm/CloseEventForm';
import KeepOpenForm from '../../forms/keepOpenForm/KeepOpenForm';
import AddItemButton from '../../../components/buttons/addItemButton/AddItemButton';
import Tooltip from '../../../components/frames/tooltip/Tooltip';
import { ContextDevice, withMultiDeviceLayout } from '../../../components/app/MultiDeviceLayout';
import FocusableFrame from '../../../components/frames/focusableFrame/FocusableFrame';

const { LAYOUT } = components.app;
const { useArrayToDict } = components.hooks;
const { Selector } = components.selectors;
const { Table, TABLE_THEMES } = components.tables;
const {
    TextAction, TrashButton, PlusTextAction, EditButton,
} = components.buttons;
const { usePerm } = components.hooks;

const ActionSelected = ({ text, className }) => (
    <div className={`iea-result ${className}`}>
        <div className="v-circle"><span className="glyphicon glyphicon-ok" /></div>
        <div className="result-text">{ text }</div>
    </div>
);

const InterventionResultTranslation = {
    keep_open: i18n.t('Kept open'),
    closed: i18n.t('Closed'),
    managed_elsewhere: i18n.t('Closed'),
    cancelled: i18n.t('Cancelled'),
};

const new_event_labelizer = (e) => {
    let date = e.date_incident;
    if (e.maintenance_type === 'preventive') {
        date = e.dated_state.date;
    }
    return `M${e.id} ${e.maintenance_type} ${moment(date).format('DD/MM/YYYY HH:mm')} ${e.description}`;
};

const new_event_id_getter = (e) => e.id;

const NewEventFrame = (props) => {
    const [_displayForm, setDisplayForm] = useState(false);
    const [_allowSave, setAllowSave] = useState(true);
    const [_addableEvents, setAddableEvents] = useState([]);
    const [_selectedEvent, setSelectedEvent] = useState(null);
    const _data = useArrayToDict(props.data, 'id');
    const hasPerm = usePerm(props.asset_id || null);

    const onClickNewEvent = () => {
        setDisplayForm(true);
    };
    const closeForm = () => {
        setDisplayForm(false);
    };
    const changeEvent = (arg) => {
        if (arg && arg.length > 0) {
            setSelectedEvent(arg[0]);
        } else {
            setSelectedEvent(null);
        }
    };
    const toCreateEvent = () => {
        const local_from = props.nFrom || `/operational/#${props.router?.location?.pathname}`;
        const push_infos = {
            from: local_from,
            extras: {
                id: props.intervention_id,
                asset: props.asset_id,
                location_id: props.location_id,
            },
        };

        props.setPushInfos(push_infos);
        // props.router.push(`dashboard/asset/${props.asset_id}/events/new/type/corrective`);
        window.location.href = props.nToCreateEvent || `/operational/#/asset/${props.asset_id}/events/new/type/corrective`;
    };
    const submitForm = () => {
        if (_selectedEvent && _allowSave) {
            setAllowSave(false);
            props.addEvents([_selectedEvent]).then(() => {
                setAllowSave(true);
                setDisplayForm(false);
                setSelectedEvent(null);
            });
        }
    };

    useEffect(() => {
        const addable_events = {};
        for (const e in props.maintenance_events_for_asset) {
            if (!_data[e]) {
                addable_events[e] = props.maintenance_events_for_asset[e];
            }
        }
        setAddableEvents(addable_events);
    }, [props.maintenance_events_for_asset, _data]);

    if (!props.canAddNewEvent) return null;

    const sort_func = (a, b) => {
        const sa = a.maintenance_type === 'preventive' ? a.dated_state.date : a.date_incident;
        const sb = b.maintenance_type === 'preventive' ? b.dated_state.date : b.date_incident;
        return -(sa > sb) || -(sa !== sb);
    };

    return (
        <div className="frame-new-event">
            <PlusTextAction
                text={i18n.t('Add a new event')}
                onClick={onClickNewEvent}
                disabled={!hasPerm('edit_intervention_events') || _displayForm}
            />
            {
                _displayForm
                    && (
                        <div className="framed">
                            <div className="add-event-top">
                                <div className="add-event-title">
                                    {i18n.t('Add new event')}
                                </div>
                                <div className="add-event-close-button">
                                    <span
                                        className="glyphicon glyphicon-remove"
                                        onClick={closeForm}
                                    />
                                </div>
                            </div>
                            <div className="add-event-bottom">
                                <div className="add-event-selector">
                                    <Selector
                                        labelizer={new_event_labelizer}
                                        id_getter={new_event_id_getter}
                                        values={_addableEvents}
                                        selected={_selectedEvent}
                                        onChange={changeEvent}
                                        label={i18n.t('Select an existing event')}
                                        sort_func={sort_func}
                                        style={{
                                            placeholder: {
                                                opacity: '0.7',
                                                fontSize: '13px',
                                                fontWeight: '500',
                                                color: '#3e454e',
                                            },
                                            control: {
                                                borderRadius: '4px',
                                                border: 'solid 2px rgba(0, 0, 0, 0.3)',
                                            },
                                        }}
                                    />
                                </div>
                                <div className="add-event-action">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        disabled={!_selectedEvent || !_allowSave}
                                        onClick={submitForm}
                                        id="blue-fill"
                                    >
                                        {i18n.t('Add this event')}
                                    </button>
                                    <div className="separator-or">
                                        <div className="mini-separator" />
                                        <span>{i18n.t('OR')}</span>
                                        <div className="mini-separator" />
                                    </div>
                                    <div className="create-event-action">
                                        <AddItemButton
                                            onClick={toCreateEvent}
                                            label={i18n.t('Create a new event')}
                                            disabled={!hasPerm('edit_intervention_events')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
            }
        </div>
    );
};

const EventsPart = (props) => {
    const { device } = useContext(ContextDevice);
    const [_actionRowSelected, setActionRowSelected] = useState(null);
    const [_idRowSelected, setIdRowSelected] = useState(null);
    const [_editMode, setEditMode] = useState(null);
    const [_locked, setLocked] = useState(false);
    const [_allClosed, setAllClosed] = useState(false);
    const hasPerm = usePerm(props.asset_id || null);
    const { fetchAll, data } = useFetch();

    const fetchEventsByIds = (ids) => (
        fetchAll('/api/maintenance.json', {
            page_size: ids.length,
            ids,
        })
    );

    useEffect(() => {
        if (props.intervention) {
            const event_ids = _.pluck(props.intervention.interventioneventaction, 'event');
            if (event_ids && event_ids.length > 0) {
                fetchEventsByIds(event_ids);
            }
        }
    }, [props.intervention]);

    useEffect(() => {
        if (props.eventPartCallback) props.eventPartCallback(_allClosed);
    }, [_allClosed]);

    const getIEA = (event_id) => {
        if (!props.intervention.interventioneventaction) return null;
        for (const iea of props.intervention.interventioneventaction) {
            if (iea.event === event_id) return iea;
        }
        return null;
    };

    useEffect(() => {
        if (!data) return;
        for (const event of data) {
            const iea = getIEA(event.id);
            if (!iea?.result) {
                setAllClosed(false);
                return;
            }
        }
        setAllClosed(true);
    }, [data]);

    const EventNumberRenderer = (cellData) => (
        <div className="description-renderer">
            <a
                className="event-number-renderer"
                onClick={(evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    const from = props.nFrom || (`/operational/#${props.router?.location?.pathname}`);
                    const push_infos = {
                        from,
                    };
                    props.setPushInfos(push_infos);
                    window.location.href = props.nToViewEvent(cellData) || `/operational/#/asset/${props.asset_id}/events/${cellData}/view`;
                    // props.router.push(`dashboard/asset/${props.asset_id}
                    // /events/${cellData}/view`);
                }}
            >
                {`M${cellData}`}
            </a>
        </div>
    );

    const DescriptionRenderer = (cellData) => (
        <div className="description-renderer">
            {cellData || '-'}
        </div>
    );

    const RexRenderer = (cellData, rowData) => {
        let _data = null;
        let result = null;
        if (rowData.interventioneventaction) {
            for (const iea of rowData.interventioneventaction) {
                if (iea.event === rowData.id && iea.intervention === parseInt(props.intervention_id, 10)) {
                    _data = iea.comment;
                    result = iea.result;
                }
            }
        }
        if (!_data && rowData.rex && _.isEqual(result, 'closed')) _data = rowData.rex;
        return (
            <div className="description-renderer">
                {_data || '-'}
            </div>
        );
    };

    const RemainingRenderer = (cellData, rowData, _device) => {
        let preText = '';
        if (device === LAYOUT.MOBILE) {
            preText = `${i18n.t('remaining usage')} `;
        }
        return (
            <span className="remaining-usage-renderer">
                {preText}
                {' '}
                {cellData || '-'}
            </span>
        );
    };

    const smallDocumentRenderer = (cellData, rowData) => (
        <SmallDocumentHolder
            event_id={rowData.id}
            count={(cellData != null ? cellData.length : null) || 0}
            noRedux
        />
    );

    const onClickClose = (rowData, id, setExpandedRow) => {
        setActionRowSelected('close');
        setIdRowSelected(id);
        setExpandedRow({ ...rowData, _expanded: 'close', _textSubmit: i18n.t('Close event') });
    };

    const onClickKeepOpen = (rowData, id, setExpandedRow) => {
        setActionRowSelected('keep_open');
        setIdRowSelected(id);
        setExpandedRow({ ...rowData, _expanded: 'keep_open', _textSubmit: i18n.t('Keep open') });
    };

    const onClickTrash = (rowData, id, setExpandedRow, event_id) => {
        if (_locked) return;
        setLocked(true);
        const { intervention } = props;
        const res = _.find(
            intervention.interventioneventaction,
            (a) => a.event === event_id,
        );
        if (res && res.result === 'closed') {
            alert(i18n.t('err_remove_closed_event_from_intervention'));
            setLocked(false);
            return;
        }
        if (Object.keys(intervention.interventioneventaction).length <= 1) {
            alert('You cannot remove the last event of an intervention');
            setLocked(false);
            return;
        }
        props.removeEvent(intervention, [event_id]).then(() => {
            setLocked(false);
        }).catch(() => {
            setLocked(false);
        });
    };

    const trashActionRenderer = (cellData, rowData, _device, setExpandedRow) => {
        const iea = getIEA(rowData.id);
        let perm_name = '';
        if (rowData.maintenance_type === 'preventive') perm_name = 'close_preventive_event';
        else if (rowData.maintenance_type === 'campaign') perm_name = 'close_campaign_event';
        else perm_name = 'close_corrective_event';
        const isDisabled = !hasPerm(perm_name);
        return (
            <div className="result-action-renderer mobile">
                <TrashButton
                    onClick={() => onClickTrash(rowData, rowData.id, setExpandedRow, iea?.event)}
                    disabled={!hasPerm('edit_intervention_events') || isDisabled}
                />
            </div>
        );
    };

    const derogationRenderer = (cellData, rowData) => {
        let rts_question_derogation = cellData;
        let rts_question_derogation_comment = null;
        if (!rts_question_derogation) {
            if (rowData.interventioneventaction) {
                for (const iea of rowData.interventioneventaction) {
                    if (iea.event === rowData.id) {
                        rts_question_derogation = iea.rts_question_derogation;
                        rts_question_derogation_comment = iea.rts_question_derogation_comment;
                    }
                }
            }
        }
        return (
            <div className="derogation-renderer">
                { rts_question_derogation ? i18n.t('Yes') : i18n.t('No') }
                {
                    rts_question_derogation_comment
                    && (
                        <Tooltip
                            title={i18n.t('Derogation comment')}
                            text={rts_question_derogation_comment}
                        />
                    )
                }
            </div>
        );
    };

    const resultActionRenderer = (cellData, rowData, _device, setExpandedRow) => {
        const current_device = _device === LAYOUT.DESKTOP ? LAYOUT.DESKTOP : LAYOUT.MOBILE;
        const iea = getIEA(rowData.id);
        let perm_name = '';
        if (rowData.maintenance_type === 'preventive') perm_name = 'close_preventive_event';
        else if (rowData.maintenance_type === 'campaign') perm_name = 'close_campaign_event';
        else perm_name = 'close_corrective_event';
        const isDisabled = !hasPerm(perm_name);
        if (
            iea
            && ['keep_open', 'closed', 'cancelled', 'managed_elsewhere'].includes(iea?.result)
            && _editMode !== iea?.event
        ) {
            return (
                <div className={`result-action-renderer ${current_device}`}>
                    <ActionSelected
                        text={InterventionResultTranslation[iea?.result]}
                    />
                    {
                        !props.readOnly
                            && (
                                <EditButton
                                    text={null}
                                    onClick={(evt) => {
                                        evt.stopPropagation();
                                        setEditMode(iea?.event);
                                        if (iea?.result === 'keep_open') {
                                            onClickKeepOpen(rowData, rowData.id, setExpandedRow);
                                        } else {
                                            onClickClose(rowData, rowData.id, setExpandedRow);
                                        }
                                    }}
                                    disabled={isDisabled}
                                />
                            )
                    }
                </div>
            );
        }
        return (
            <div className={`result-action-renderer ${device}`}>
                <TextAction
                    text={i18n.t('keep open')}
                    onClick={(evt) => {
                        evt.stopPropagation();
                        onClickKeepOpen(rowData, rowData.id, setExpandedRow);
                    }}
                    selected={_idRowSelected === rowData.id && _actionRowSelected === 'keep_open'}
                    disabled={isDisabled}
                />
                {
                    _idRowSelected !== rowData.id
                        && <span className="separator vertical" />
                }
                <TextAction
                    text={i18n.t('close')}
                    onClick={(evt) => {
                        evt.stopPropagation();
                        onClickClose(rowData, rowData.id, setExpandedRow);
                    }}
                    selected={_idRowSelected === rowData.id && _actionRowSelected === 'close'}
                    disabled={isDisabled}
                />
            </div>
        );
    };

    const expandRowRenderer = (id, rowData, _device, setExpandedRow) => {
        if (rowData) {
            if (rowData._expanded === 'close') {
                return (
                    <CloseEventForm
                        event_id={rowData.id}
                        intervention_id={props.intervention_id}
                        textSubmit={rowData._textSubmit}
                        setLocked={setLocked}
                        locked={_locked}
                        date_started={
                            props.intervention.date_start_actual
                            && moment(props.intervention.date_start_actual)
                        }
                        framed
                        onCancel={() => {
                            setExpandedRow({ _expanded: null, _textSubmit: null });
                            setIdRowSelected(null);
                            setActionRowSelected(null);
                            setEditMode(null);
                        }}
                        callback={() => {
                            setExpandedRow({ _expanded: null, _textSubmit: null });
                            setIdRowSelected(null);
                            setActionRowSelected(null);
                            setEditMode(null);
                            props.fetchMaintenanceEventsForAsset(props.asset_id);
                        }}
                        noTabletDisplay
                        standalone={false}
                        fromIntervention={props.fromIntervention}
                    />
                );
            }
            return (
                <KeepOpenForm
                    event_id={rowData.id}
                    intervention_id={props.intervention_id}
                    textSubmit={rowData._textSubmit}
                    setLocked={setLocked}
                    locked={_locked}
                    date_started={
                        props.intervention.date_start_actual
                        && moment(props.intervention.date_start_actual)
                    }
                    framed
                    onCancel={() => {
                        setExpandedRow({ _expanded: null, _textSubmit: null });
                        setIdRowSelected(null);
                        setActionRowSelected(null);
                        setEditMode(null);
                    }}
                    callback={() => {
                        setExpandedRow({ _expanded: null, _textSubmit: null });
                        setIdRowSelected(null);
                        setActionRowSelected(null);
                        setEditMode(null);
                        props.fetchMaintenanceEventsForAsset(props.asset_id);
                    }}
                    noTabletDisplay
                    fromIntervention={props.fromIntervention}
                />
            );
        }
        return null;
    };

    const render = () => (
        <div className="events-parts">
            <div className="events-part-opened">
                <Table
                    data={data || []}
                    data_unique_id="id"
                    columns={[
                        { label: i18n.t('Event number'), key: 'id', renderer: EventNumberRenderer },
                        { label: i18n.t('Type'), key: 'maintenance_type', renderer: DescriptionRenderer },
                        { label: i18n.t('Event description'), key: 'description', renderer: DescriptionRenderer },
                        { label: i18n.t('Remaining usage'), key: 'remaining_usage', renderer: RemainingRenderer },
                        { label: i18n.t('Documents'), key: 'documents', renderer: smallDocumentRenderer },
                        { label: i18n.t('Comment'), key: 'comment', renderer: RexRenderer },
                        { label: i18n.t('Derogation'), key: 'rts_question_derogation', renderer: derogationRenderer },
                        { label: i18n.t('Result'), renderer: resultActionRenderer },
                        { renderer: trashActionRenderer },
                    ]}
                    theme={TABLE_THEMES.INLINE}
                    expandRowRenderer={expandRowRenderer}
                    noTabletDisplay
                    mobile_arrangement={{
                        primary_shorts: [
                            { key: 'id' },
                            { key: 'maintenance_type' },
                        ],
                        secondary_shorts: [
                            { key: 'documents' },
                            { key: 'remaining_usage' },
                            { key: 'rex' },
                        ],
                        primary_long: { key: 'description' },
                        actions: [
                            { id: 'trash', renderer: trashActionRenderer },
                            { id: 'action', renderer: resultActionRenderer },
                        ],
                    }}
                />
            </div>
            {
                props.intervention && !['done', 'cancelled'].includes(props.intervention.state)
                    && (
                        <NewEventFrame
                            {...props}
                            data={data}
                            location_id={props.location_id}
                        />
                    )
            }
        </div>
    );

    if (!props.framed) {
        return (
            <div className={`events-part ${device}`}>
                {
                    render()
                }
            </div>
        );
    }

    return (
        <div className={`events-part ${device}`}>
            <FocusableFrame
                title={i18n.t('Events part of this intervention')}
            >
                {
                    render()
                }
            </FocusableFrame>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const { intervention_id } = props;
    const intervention = intervention_id
        ? interventionsSelectors.getInterventionById(state, intervention_id)
        : null;
    const asset_id = intervention && intervention.asset;
    const events_selector = maintenanceEventsSelectors.makeMaintenanceEventsForIntervention();
    let maintenance_events = {};
    if (intervention) {
        maintenance_events = events_selector(state, intervention.id);
    }
    const getEventForAsset = maintenanceEventsSelectors.makeOpenMaintenanceEventsForAsset();
    const maintenance_events_for_asset = asset_id && getEventForAsset(state, asset_id);
    return {
        intervention: intervention || {},
        asset_id,
        users: state.users.all,
        maintenance_events,
        maintenance_events_for_asset,
        user_perm_check: usersSelectors.userHasPerm(state),
        push_infos: uxSelectors.getPushInfos(state),
    };
};

let controller;
const mapDispatchToProps = (dispatch, props) => ({
    fetchInterventionDetail: (intervention_id) => dispatch(
        interventionsOperations.fetchDetails(intervention_id),
    ),
    fetchEvents: (event_ids) => {
        if (controller) {
            controller.abort();
        }
        controller = new AbortController();
        return dispatch(
            maintenanceEventsOperations.fetchByIds(event_ids, controller),
        );
    },
    fetchMaintenanceEventsForAsset: (asset_id) => {
        if (controller) {
            controller.abort();
        }
        controller = new AbortController();
        return (
            dispatch(maintenanceEventsOperations.fetchForAsset(asset_id, controller))
        );
    },
    setPushInfos: (push_infos, from = null) => dispatch(uxOperations.setPushInfos(push_infos, from)),
    addEvents: (ids) => dispatch(
        interventionsOperations.addEventsInIntervention(props.intervention_id, ids),
    ),
    removeEvent: (intervention, ids) => dispatch(
        interventionsOperations.removeEventsFromIntervention(intervention, ids, true),
    ),
});

EventsPart.propTypes = {
    framed: PropTypes.bool,
    canAddNewEvent: PropTypes.bool,
    nFrom: PropTypes.string,
    nToCreateEvent: PropTypes.string,
    nToViewEvent: PropTypes.func,
};

EventsPart.defaultProps = {
    framed: false,
    canAddNewEvent: true,
    nFrom: null,
    nToCreateEvent: null,
    nToViewEvent: null,
};

export {
    ActionSelected,
};
export default withMultiDeviceLayout(connect(mapStateToProps, mapDispatchToProps)(EventsPart));
