import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import i18n from 'i18next';
import { assetsSelectors } from 'railfleet_state/ducks/assets';

import './hotlineSuggestion.scss';
import Table, { THEMES as TABLE_THEMES } from '../../../tables/table/Table';
import MultiDeviceLayout, { LAYOUT } from '../../../app/MultiDeviceLayout';
import useArrayToDict from '../../../hooks/useArrayToDict';
import PlusTextAction from '../../../buttons/plusTextAction/PlusTextAction';
import TextAction from '../../../buttons/textAction/TextAction';

const getHotlineSuggestion = async (asset_id, date) => {
    const settings = {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    };
    const url = `/api/hotline_call/suggested.json?asset=${asset_id}&date=${moment(date).toISOString()}`;
    const r = await fetch(url, settings);
    const d = await r.json();
    return d;
};

const GetAttachedCall = async (event_id) => {
    const settings = {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    };
    const url = `/api/hotline_call/attached.json?event_id=${event_id}`;
    const r = await fetch(url, settings);
    const d = await r.json();
    return d;
};

const _HotlineSuggestionRO = (props) => {
    const [_hotline_data, setHotlineData] = useState([]);
    const [_hotline_suggestion, setHotlineSuggestion] = useState([]);
    const [_selected, setSelected] = useState({});
    const [_merged, setMerged] = useState([]);
    const [_source, setSource] = useState(null);
    const [_addTab, setAddTab] = useState(false);

    useEffect(() => {
        if (props.event) {
            const attached_calls = GetAttachedCall(props.event);
            attached_calls.then((d) => {
                if (d) {
                    setHotlineData(d);
                }
            });
        }
    }, [props.event]);

    useEffect(() => {
        const merged = [];
        if (_hotline_data && _hotline_data.length > 0) {
            for (const data of _hotline_data) {
                merged.push(data.id);
            }
        }
        for (const selected in _selected) {
            merged.push(parseInt(selected, 10));
        }
        setMerged(merged);
    }, [_selected, _hotline_data]);

    useEffect(() => {
        if (_merged && _merged.length === 0) props.onChange(null);
        else props.onChange(_merged);
    }, [_merged]);

    useEffect(() => {
        if (props.addOnly && props.asset_id && props.date) {
            const hotline_suggestion = getHotlineSuggestion(props.asset_id, props.date);
            hotline_suggestion.then((d) => {
                if (d) {
                    const filtered = _.filter(d, (elm) => elm.id !== props.current);
                    setHotlineSuggestion(filtered);
                }
            });
        }
    }, [props.asset_id, props.date, props.addOnly]);

    useEffect(() => {
        for (const data of _hotline_data) {
            if (data.is_source_call_for_event) {
                setSource(data);
            }
        }
    }, [_hotline_data]);

    const RenderLineMobile = ({ elm }) => (
        <div className="hotline-call-summary-bar-infos">
            <div className="hotline-suggested-source">
                <span className={`source-holder ${!elm.is_source_call_for_event ? 'collapsed' : ''}`}>{i18n.t('SOURCE')}</span>
            </div>
            <div className="asset">
                <span>{i18n.t('Description')}</span>
                <div>
                    <span>{elm.issue_description}</span>
                </div>
            </div>
            <div className="caller">
                <span>{i18n.t('Caller')}</span>
                <div>
                    <span>{elm.caller_name}</span>
                    <span>{elm.caller_phone_number}</span>
                </div>
            </div>
            <div className="date">
                <span>{i18n.t('Date')}</span>
                <div>
                    <span>{moment(elm.date_start).format('DD/MM/YYYY HH:mm')}</span>
                </div>
            </div>
        </div>
    );

    const RenderLine = ({ elm }) => (
        <div className="hotline-suggested-line">
            <div className="hotline-suggested-source">
                <span className={`source-holder ${!elm.is_source_call_for_event ? 'collapsed' : ''}`}>{i18n.t('SOURCE')}</span>
            </div>
            <div className="hotline-suggested-description">
                <span>{elm.issue_description}</span>
            </div>
            <div className="hotline-suggested-caller">
                <span>
                    {elm.caller && props.users ? props.users[elm.caller]?.full_name : elm.caller_name}
                    {' '}
                    -
                    {' '}
                    {elm.caller_phone_number}
                </span>
            </div>
            <div className="hotline-suggested-date"><span>{moment(elm.date_start).format('DD/MM/YYYY HH:mm')}</span></div>
        </div>
    );

    const RenderLineAdd = ({ elm, elm_is_selected, onChange }) => (
        <div className="hotline-suggested-line">
            <div className="hotline-suggested-checkbox">
                <input
                    type="checkbox"
                    checked={!!elm_is_selected}
                    onChange={() => onChange(elm.id)}
                />
            </div>
            <div className="hotline-suggested-description"><span>{elm.issue_description}</span></div>
            <div className="hotline-suggested-caller">
                <span>
                    {elm.caller && props.users ? props.users[elm.caller]?.full_name : elm.caller_name}
                    {' '}
                    -
                    {' '}
                    {elm.caller_phone_number}
                </span>
            </div>
            <div className="hotline-suggested-date"><span>{moment(elm.date_start).format('DD/MM/YYYY HH:mm')}</span></div>
        </div>
    );

    const RenderLineAddMobile = ({
        elm, elm_is_selected, onChange, className,
    }) => (
        <div className={`hotline-suggested-line ${className}`}>
            <div className="hotline-suggested-line-left">
                <div className="hotline-suggested-description">
                    <span>{i18n.t('Description')}</span>
                    <div>
                        <span>{elm.issue_description}</span>
                    </div>
                </div>
                <div className="hotline-suggested-caller">
                    <span>{i18n.t('Caller')}</span>
                    <div>
                        <span>
                            {elm.caller_name}
                            {elm.caller_name}
                        </span>
                        <span>{elm.caller_phone_number}</span>
                    </div>
                </div>
                <div className="hotline-suggested-date">
                    <span>{i18n.t('Date')}</span>
                    <div>
                        <span>{moment(elm.date_start).format('DD/MM/YYYY HH:mm')}</span>
                    </div>
                </div>
            </div>
            <div className="hotline-suggested-line-right">
                <div className="hotline-suggested-checkbox">
                    <input
                        type="checkbox"
                        checked={!!elm_is_selected}
                        onChange={() => onChange(elm.id)}
                    />
                </div>
            </div>
        </div>
    );

    const onChangeSelected = (id_call) => {
        const newSelected = { ..._selected };
        if (id_call === -1) {
            for (const elm of _hotline_suggestion) {
                newSelected[elm.id] = true;
            }
        } else if (newSelected[id_call]) {
            delete newSelected[id_call];
        } else {
            newSelected[id_call] = true;
        }
        setSelected(newSelected);
    };

    const RenderSource = () => {
        if (_source) {
            if (props.device === LAYOUT.MOBILE) {
                return (
                    <div>
                        <RenderLineMobile
                            elm={_source}
                        />
                        <div className="separator horizontal" />
                    </div>
                );
            }
            return (
                <div>
                    <RenderLine
                        elm={_source}
                    />
                    <div className="separator horizontal" />
                </div>
            );
        }
        return null;
    };

    const RenderCalls = () => _hotline_data.map((elm) => {
        if (_source && elm.id === _source.id) return null;
        if (props.device === LAYOUT.MOBILE) {
            return (
                <div key={elm.id}>
                    <RenderLineMobile
                        elm={elm}
                    />
                </div>
            );
        }
        return (
            <div key={elm.id}>
                <RenderLine
                    elm={elm}
                />
                <div className="separator horizontal" />
            </div>
        );
    });

    const RenderAddOnly = () => _hotline_suggestion.map((elm) => {
        if (props.device === LAYOUT.MOBILE) {
            return (
                <div key={elm.id}>
                    <RenderLineAddMobile
                        elm={elm}
                        elm_is_selected={_selected[elm.id]}
                        onChange={onChangeSelected}
                    />
                    <div className="separator horizontal" />
                </div>
            );
        }
        return (
            <div key={elm.id}>
                <RenderLineAdd
                    elm={elm}
                    elm_is_selected={_selected[elm.id]}
                    onChange={onChangeSelected}
                />
                <div className="separator horizontal" />
            </div>
        );
    });

    const attachMore = () => {
        if (!_addTab) {
            setSelected({});
        }
        setAddTab(!_addTab);
    };

    const RenderHeader = () => {
        if (props.device === LAYOUT.MOBILE) return null;
        return (
            <div className="hotline-suggested-line titles">
                <div className="hotline-suggested-source">Source</div>
                <div className="hotline-suggested-description"><span>{i18n.t('Asset dysfunction')}</span></div>
                <div className="hotline-suggested-caller"><span>{i18n.t('Caller')}</span></div>
                <div className="hotline-suggested-date"><span>{i18n.t('Date start')}</span></div>
            </div>
        );
    };

    if (_hotline_data.length === 0) {
        return (
            <div className={`hotline-suggestion-holder ${props.device}`}>
                <div className="hotline-suggestion-header">
                    <div className="hotline-suggestion-title">There is no call attached to this event</div>
                </div>
            </div>
        );
    }

    return (
        <div className="hotline-suggestion-addonly">
            <div className={`hotline-suggestion-holder readonly ${props.device}`}>
                <div className={`hotline-suggestion-body ${props.device}`}>
                    <div className="hotline-suggested-lines">
                        <RenderHeader />
                        <RenderSource />
                        <RenderCalls />
                    </div>
                </div>
                {
                    props.addOnly && !_addTab
                        && (
                            <PlusTextAction
                                onClick={attachMore}
                                text={i18n.t('Attach more hotline calls')}
                                disabled={!_hotline_suggestion || _hotline_suggestion.length === 0}
                            />
                        )
                }
            </div>
            {
                props.addOnly && _addTab
                    && (
                        <div className={`hotline-suggestion-holder ${props.device}`}>
                            <div className="hotline-suggestion-header">
                                <div className="hotline-suggestion-title">{i18n.t('Attach other hotline calls')}</div>
                                <div className="separator horizontal" />
                            </div>
                            <div className="hotline-suggestion-body">
                                <div className="hotline-suggested-lines">
                                    <RenderAddOnly />
                                </div>
                            </div>
                            <TextAction
                                onClick={attachMore}
                                text={i18n.t('Cancel')}
                            />
                        </div>
                    )
            }
        </div>
    );
};

const _HotlineAssignation = (props) => {
    const [_selected, setSelected] = useState({});
    const [_source, setSource] = useState(null);
    const [_merged, setMerged] = useState([]);
    const suggestions = useArrayToDict(props.suggestions, 'id');
    const [_sourceTab, setSourceTab] = useState(true);

    useEffect(() => {
        const merged = [];
        if (_source) merged.push(_source);
        for (const selected in _selected) {
            merged.push(parseInt(selected, 10));
        }
        setMerged(merged);
    }, [_selected, _source]);

    useEffect(() => {
        if (_merged && _merged.length === 0) props.onChange(null);
        else props.onChange(_merged);
    }, [_merged]);

    const RenderLine = ({
        elm, elm_is_selected, onChange, className = '',
    }) => (
        <div className={`hotline-suggested-line ${className}`}>
            <div className="hotline-suggested-checkbox">
                <input
                    type={_sourceTab || elm.id === _source ? 'radio' : 'checkbox'}
                    checked={!!elm_is_selected}
                    onChange={() => onChange(elm.id)}
                />
            </div>
            <div className="hotline-suggested-description"><span>{elm.issue_description}</span></div>
            <div className="hotline-suggested-caller">
                <span>
                    {elm.caller && props.users ? props.users[elm.caller]?.full_name : elm.caller_name}
                    {' '}
                    -
                    {' '}
                    {elm.caller_phone_number}
                </span>
            </div>
            <div className="hotline-suggested-date"><span>{moment(elm.date_start).format('DD/MM/YYYY HH:mm')}</span></div>
        </div>
    );

    const RenderLineMobile = ({
        elm, elm_is_selected, onChange, className = '',
    }) => (
        <div className={`hotline-suggested-line ${className}`}>
            <div className="hotline-suggested-line-left">
                <div className="hotline-suggested-description">
                    <span>{i18n.t('Description')}</span>
                    <div>
                        <span>{elm.issue_description}</span>
                    </div>
                </div>
                <div className="hotline-suggested-caller">
                    <span>{i18n.t('Caller')}</span>
                    <div>
                        <span>
                            {elm.caller_name}
                            {elm.caller_name}
                        </span>
                        <span>{elm.caller_phone_number}</span>
                    </div>
                </div>
                <div className="hotline-suggested-date">
                    <span>{i18n.t('Date')}</span>
                    <div>
                        <span>{moment(elm.date_start).format('DD/MM/YYYY HH:mm')}</span>
                    </div>
                </div>
            </div>
            <div className="hotline-suggested-line-right">
                <div className="hotline-suggested-checkbox">
                    <input
                        type={_sourceTab || elm.id === _source ? 'radio' : 'checkbox'}
                        checked={!!elm_is_selected}
                        onChange={() => onChange(elm.id)}
                    />
                </div>
            </div>
        </div>
    );

    const onChangeSelected = (id_call) => {
        const newSelected = { ..._selected };
        if (id_call === -1) {
            for (const elm of props.suggestions) {
                newSelected[elm.id] = true;
            }
        } else if (newSelected[id_call]) {
            delete newSelected[id_call];
        } else {
            newSelected[id_call] = true;
        }
        setSelected(newSelected);
    };

    const onChangeSource = (id) => {
        if (_source !== id) {
            setSource(id);
        }
    };

    const attachMore = () => {
        if (!_sourceTab) {
            setSelected({});
        }
        setSourceTab(!_sourceTab);
    };

    const RenderHeader = () => {
        if (props.device === LAYOUT.MOBILE) return null;
        return (
            <div className="hotline-suggested-line titles">
                <div className="hotline-suggested-checkbox" />
                <div className="hotline-suggested-description"><span>{i18n.t('Asset dysfunction')}</span></div>
                <div className="hotline-suggested-caller"><span>{i18n.t('Caller')}</span></div>
                <div className="hotline-suggested-date"><span>{i18n.t('Date start')}</span></div>
            </div>
        );
    };

    const RenderCalls = () => props.suggestions.map((elm) => {
        if (
            (!_sourceTab && elm.id === _source)
            || (_sourceTab && elm.solved)
        ) {
            return null;
        }

        return (
            <div key={elm.id}>
                {
                    props.device === LAYOUT.MOBILE ? (
                        <RenderLineMobile
                            elm={elm}
                            elm_is_selected={
                                _sourceTab
                                    ? _source === elm.id
                                    : _selected[elm.id]
                            }
                            className={
                                _sourceTab
                                    ? 'sourceTab'
                                    : 'otherTab'
                            }
                            onChange={
                                _sourceTab
                                    ? onChangeSource
                                    : onChangeSelected
                            }
                        />
                    ) : (
                        <RenderLine
                            elm={elm}
                            elm_is_selected={
                                _sourceTab
                                    ? _source === elm.id
                                    : _selected[elm.id]
                            }
                            className={
                                _sourceTab
                                    ? 'sourceTab'
                                    : 'otherTab'
                            }
                            onChange={
                                _sourceTab
                                    ? onChangeSource
                                    : onChangeSelected
                            }
                        />
                    )
                }
                <div className="separator horizontal" />
            </div>
        );
    });

    const RenderSelectSource = () => {
        if (
            !_sourceTab
            && _source
            && suggestions
            && Object.keys(suggestions).length > 0 && suggestions[_source]
        ) {
            return (
                <div className="hotline-suggestion-source">
                    {
                        props.device === LAYOUT.MOBILE ? (
                            <RenderLineMobile
                                elm={suggestions[_source]}
                                elm_is_selected
                                onChange={() => null}
                            />
                        ) : (
                            <RenderLine
                                elm={suggestions[_source]}
                                elm_is_selected
                                onChange={() => null}
                            />
                        )
                    }
                    <div className="separator horizontal" />
                    <div className="hotline-suggested-separator">
                        {
                            !_sourceTab && props.device !== LAYOUT.MOBILE
                                && <div className="hotline-suggested-select-all" onClick={() => onChangeSelected(-1)}>ALL</div>
                        }
                        <div className="hotline-suggestion-title">{i18n.t('Attach other hotline calls')}</div>
                        {
                            !_sourceTab && props.device === LAYOUT.MOBILE
                                && <div className="hotline-suggested-select-all" onClick={() => onChangeSelected(-1)}>ALL</div>
                        }
                    </div>
                </div>
            );
        }
        return null;
    };

    if (!props.calls_exists) {
        return (
            <div className={`hotline-suggestion-holder ${props.device}`}>
                <div className="hotline-suggestion-header">
                    <div className="hotline-suggestion-title">There is no call to attach to this event</div>
                </div>
            </div>
        );
    }

    return (
        <div className={`hotline-suggestion-holder ${props.device}`}>
            <div className="hotline-suggestion-header">
                <div className="hotline-suggestion-title">{i18n.t('Select hotline calls')}</div>
                <div className="hotline-suggestion-description">{i18n.t('Select the source hotline call of the corrective event')}</div>
            </div>
            <div className="hotline-suggestion-body">
                <div className="hotline-suggested-lines">
                    <RenderHeader />
                    <RenderSelectSource />
                    <RenderCalls />
                    {
                        _sourceTab ? (
                            <PlusTextAction
                                onClick={attachMore}
                                text={i18n.t('Attach more hotline calls')}
                                disabled={!_source}
                            />
                        ) : (
                            <TextAction
                                onClick={attachMore}
                                text={i18n.t('Cancel')}
                                disabled={!_source}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
};

const _HotlineSuggestion = (props) => {
    const [_hotline_suggestion, setHotlineSuggestion] = useState([]);
    const [_selected, setSelected] = useState({});

    useEffect(() => {
        if (props.asset_id && props.date) {
            const hotline_suggestion = getHotlineSuggestion(props.asset_id, props.date);
            hotline_suggestion.then((d) => {
                if (d) {
                    const filtered = _.filter(d, (elm) => elm.id !== props.current);
                    setHotlineSuggestion(filtered);
                }
            });
        }
    }, [props.asset_id, props.date]);

    useEffect(() => {
        props.onChange(_selected);
    }, [_selected]);

    const RenderLine = ({ elm, elm_is_selected, onChange }) => (
        <div className="hotline-suggested-line">
            <div className="hotline-suggested-checkbox">
                <input
                    type="checkbox"
                    checked={!!elm_is_selected}
                    onChange={() => onChange(elm.id)}
                />
            </div>
            <div className="hotline-suggested-description"><span>{elm.issue_description}</span></div>
            <div className="hotline-suggested-caller">
                <span>
                    {elm.caller && props.users ? props.users[elm.caller]?.full_name : elm.caller_name}
                    {' '}
                    -
                    {' '}
                    {elm.caller_phone_number}
                </span>
            </div>
            <div className="hotline-suggested-date"><span>{moment(elm.date_start).format('DD/MM/YYYY HH:mm')}</span></div>
        </div>
    );

    const onChangeSelected = (id_call) => {
        const newSelected = { ..._selected };
        if (id_call === -1) {
            for (const elm of _hotline_suggestion) {
                newSelected[elm.id] = true;
            }
        } else if (newSelected[id_call]) {
            delete newSelected[id_call];
        } else {
            newSelected[id_call] = true;
        }
        setSelected(newSelected);
    };

    const DateColumnFormatter = (d) => (
        <span>
            { moment(d).format('DD/MM/YYYY HH:mm:ss') }
        </span>
    );

    const CallerFormatter = (d, row) => (
        <span>{ `${d ? props.users[d].email : row.caller_name} (${row.caller_phone_number})`}</span>
    );
    if (props.device === LAYOUT.MOBILE) {
        return (
            <Table
                is_fetching={_hotline_suggestion.length === 0}
                data={_hotline_suggestion}
                data_unique_id="id"
                columns={[
                    { label: 'Description', key: 'issue_description' },
                    { label: 'Caller', key: 'caller', renderer: CallerFormatter },
                    { label: 'Start', key: 'date_start', renderer: DateColumnFormatter },
                ]}
                bulk_actions={{
                    subtitle: i18n.t('Please select other hotline calls to attach to the corrective event'),
                    actions: [
                        { label: i18n.t('Attach more calls'), onClick: props.action },
                    ],
                }}
                actions={[]}
                onSelect={props.onChange}
                mobile_arrangement={{
                    primary_shorts: [
                        {
                            key: 'date_start',
                        },
                    ],
                    primary_long: { key: 'issue_description' },
                    secondary_shorts: [
                        {
                            key: 'caller',
                        },
                    ],
                }}
                theme={TABLE_THEMES.MODAL}
            />
        );
    }

    return (
        <div className={`hotline-suggestion-holder ${props.device}`}>
            <div className="hotline-suggestion-header">
                <div className="hotline-suggestion-title">{i18n.t('Recent suggestion')}</div>
                <div className="hotline-suggestion-description">{i18n.t('Select other hotline call to attach to the corrective event')}</div>
            </div>
            <div className="hotline-suggestion-body">
                <div className="hotline-suggested-lines">
                    <div className="hotline-suggested-line titles">
                        <div className="hotline-suggested-select-all" onClick={() => onChangeSelected(-1)}>ALL</div>
                        <div className="hotline-suggested-checkbox" />
                        <div className="hotline-suggested-description"><span>{i18n.t('Asset dysfunction')}</span></div>
                        <div className="hotline-suggested-caller"><span>{i18n.t('Caller')}</span></div>
                        <div className="hotline-suggested-date"><span>{i18n.t('Date start')}</span></div>
                    </div>
                    {
                        _hotline_suggestion.map((elm) => (
                            <div key={elm.id}>
                                <RenderLine
                                    elm={elm}
                                    elm_is_selected={_selected[elm.id]}
                                    onChange={onChangeSelected}
                                />
                                <div className="separator horizontal" />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    assets: assetsSelectors.getAssetsInfos(state),
    users: state.users.all,
});

const Comp = (props) => (
    <MultiDeviceLayout>
        {
            props.readOnly ? (
                /* eslint-disable-next-line react/jsx-pascal-case */
                <_HotlineSuggestionRO
                    {...props}
                />
            ) : (
                props.has_source || props.current ? (
                    /* eslint-disable-next-line react/jsx-pascal-case */
                    <_HotlineSuggestion
                        {...props}
                    />
                ) : (
                    /* eslint-disable-next-line react/jsx-pascal-case */
                    <_HotlineAssignation
                        {...props}
                    />
                )
            )
        }
    </MultiDeviceLayout>
);

const HotlineSuggestion = connect(
    mapStateToProps,
    null,
)(Comp);

export default HotlineSuggestion;
