import React, { useState, useEffect } from 'react';
import './StateTimeline.scss';
import TimelineBullet from '../timelineBullet/TimelineBullet';
import TextDisplay from '../textDisplay/TextDisplay';
import MultiDeviceLayout, { LAYOUT } from '../../app/MultiDeviceLayout';
import usePerm from '../../hooks/usePerm';

const TimelineBarHorizontal = (props) => (
    <div className="state-timeline-bar horizontal">
        <div className="state-timeline-sub-bar-holder">
            {
                props.steps && props.steps.map((step, index) => {
                    if (index === Object.keys(props.steps).length - 1) return null;
                    return (
                        <div
                            key={`state-timeline-sub-bar-${step.label}`}
                            className={`state-timeline-sub-bar${!props.selected || index >= props.position ? ' disabled' : ''}${props.late ? ' late' : ''}`}
                        />
                    );
                })
            }
        </div>
    </div>
);
const TimelineBarVertical = (props) => (
    <div className="state-timeline-bar vertical">
        <div className="state-timeline-sub-bar-holder">
            {
                props.steps && props.steps.map((step, index) => {
                    if (index === Object.keys(props.steps).length - 1) return null;
                    if (index >= props.position) return null;
                    return (
                        <div
                            key={`state-timeline-sub-bar-${step.label}`}
                            className={`state-timeline-sub-bar${!props.selected || index >= props.position ? ' disabled' : ''} ${index === props.position - 1 ? 'last' : ''}${props.late ? ' late' : ''}`}
                        />
                    );
                })
            }
        </div>
    </div>
);

const StateTimelineComponent = (props) => {
    const [_position, setPosition] = useState(0);
    const hasPerm = usePerm(props.asset_id || null);

    useEffect(() => {
        if (props.steps && props.selected) {
            props.steps.map((step, index) => {
                if (step.state === props.selected) {
                    setPosition(index);
                }
                return null;
            });
        }
        if (props.steps && !props.selected) {
            setPosition(999);
        }
    }, [props.steps, props.selected]);

    const hasPast = _position > 0 && props.data && Object.keys(props.data).length > 0;
    return (
        <div className={`state-timeline ${props.device === LAYOUT.TABLET ? LAYOUT.DESKTOP : props.device}`}>
            {
                props.title
                    && <div className="state-timeline-title">{props.title}</div>
            }
            <div className={`state-timeline-holder${hasPast ? ' hasPast' : ''}`}>
                <TimelineBarHorizontal
                    steps={props.steps}
                    selected={props.selected}
                    position={_position}
                    late={props.late}
                />
                <TimelineBarVertical
                    steps={props.steps}
                    selected={props.selected}
                    position={_position}
                    late={props.late}
                />
                <div>
                    {
                        props.steps && props.steps.map((step, index) => {
                            if (index > _position && props.device === LAYOUT.MOBILE) return null;
                            return (
                                <TimelineBullet
                                    key={`timeline-bullet_${step.state}`}
                                    label={step.label}
                                    state={step.state}
                                    selected={step.state === props.selected}
                                    past={index < _position}
                                    opened={props.opened || index !== _position}
                                    buttonText={props.buttonText}
                                    onClick={props.onClick}
                                    cancelled={props.cancelled}
                                    late={props.late}
                                    done={props.done}
                                    hasPerm={hasPerm('manage_intervention_date')}
                                >
                                    <TextDisplay
                                        label={props.data[step.state] ? props.data[step.state].label : '-'}
                                        data={props.data[step.state] ? props.data[step.state].value : '-'}
                                    />
                                </TimelineBullet>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

StateTimelineComponent.defaultProps = {
    steps: [],
    selected: null,
    data: {},
    opened: true,
};

const StateTimeline = (props) => (
    <MultiDeviceLayout>
        <StateTimelineComponent {...props} />
    </MultiDeviceLayout>
);

export default StateTimeline;
