import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { connect } from 'react-redux';
import { components } from 'react-select';
import memoize from 'memoize-one';
import { faultCategoriesSelectors } from 'railfleet_state/ducks/fault_categories';
import './FaultCategorySelector.scss';
import Selector from '../selector/Selector';
import atomeLogo from './atomeLogo.svg';

const ValueContainer = ({ children, ...props }) => {
    const [_topn, setTopn] = useState({});

    useEffect(() => {
        const topn = {};
        for (const option of props.options[0].options) {
            topn[option.value] = option.percent;
        }
        setTopn(topn);
    }, [props.options]);

    let fault_category_id = null;
    if (props.hasValue && props.getValue()[0]) {
        fault_category_id = props.id_getter(props.getValue()[0]);
    }
    return (
        <components.ValueContainer {...props}>
            <div className="value-container-holder">
                {
                    props.hasValue && fault_category_id
                    && _topn && _topn.hasOwnProperty(fault_category_id)
                        ? (
                            <>
                                <div className="left-content">
                                    <img src={atomeLogo} alt="atome logo" />
                                    <div className="suggested-text">Suggested</div>
                                </div>
                                <div className="children">{children}</div>
                                <div className="percent">
                                    <div className="percent-text">{`${_topn[fault_category_id]}%`}</div>
                                </div>
                            </>
                        ) : (
                            <div className="children">{children}</div>
                        )
                }
            </div>
        </components.ValueContainer>
    );
};

const OptionContainer = ({ children, ...props }) => {
    let selected = false;
    if (props.data) {
        if (props.data.percent) {
            if (props.hasValue) {
                const selected_id = props.id_getter(props.getValue()[0]);
                const current_id = props.id_getter(props.data);
                selected = selected_id === current_id;
            }
            return (
                <components.Option {...props}>
                    <div className="value-container-holder">
                        <div className="children">{children}</div>
                        <div className={`percent ${selected ? 'selected' : ''}`}>
                            <div className="percent-text">{`${props.data.percent}%`}</div>
                        </div>
                    </div>
                </components.Option>
            );
        }
    }
    return (
        <components.Option {...props}>
            {children}
        </components.Option>
    );
};

const GroupHeadingContainer = ({ children, ...props }) => {
    const suggested = children === 'Suggested';
    return (
        <components.GroupHeading {...props}>
            <div className="group-heading-container-holder">
                <div className="left-content">
                    {
                        suggested
                            && <img src={atomeLogo} alt="intercom logo" />
                    }
                    <div className={`suggested-text ${!suggested ? 'all' : ''}`}>{children}</div>
                </div>
            </div>
            <div className="suggested-separator" />
        </components.GroupHeading>
    );
};

const fn_array_to_dict = (array) => {
    const ret = {};
    for (const index in array) {
        const elm = array[index];
        if (elm?.id) ret[elm.id] = elm;
    }
    return ret;
};

const fn_raw_topn_to_topn = (rawTopn) => {
    const ret = {};
    for (const index in rawTopn) {
        const elm = rawTopn[index];
        const { event_id } = elm;
        const fault_categories = elm.fault_category_id;
        ret[event_id] = {};
        for (const index_fault_category in fault_categories) {
            const fault_category = fault_categories[index_fault_category];
            ret[event_id][fault_category.id] = fault_category.score;
        }
    }
    return ret;
};

const fn_make_selected_data = (topns, maintenanceEvents) => {
    const ret = {};
    for (const key in topns) {
        const event_id = key;
        const topn = topns[key];
        const event = maintenanceEvents[event_id];
        if (event && event.fault_category) {
            ret[event_id] = event.fault_category;
        } else if (Object.keys(topn).length > 0) {
            const max = { id: null, value: -1 };
            for (const id in topn) {
                const percent = topn[id];
                if (percent >= max.value) {
                    max.id = parseInt(id, 10);
                    max.value = percent;
                }
            }
            ret[event_id] = max.id;
        }
    }
    // TODO Added after a long time, not 100% sure if no side effect.
    for (const event_id of Object.keys(maintenanceEvents)) {
        const event = maintenanceEvents[event_id];
        if (event.fault_category) {
            ret[event.id] = event.fault_category;
        }
    }
    return ret;
};

const FaultCategorySelector = (props) => {
    const array_to_dict = memoize(fn_array_to_dict);
    const raw_topn_to_topn = memoize(fn_raw_topn_to_topn);
    const make_selected_data = memoize(fn_make_selected_data);
    const [topn, setTopn] = useState({});
    const [selectedData, setSelectedData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [top3, setTop3] = useState([]);
    const [_prev_events, setPrevEvents] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        const _selected_data = {};
        for (const event of props.maintenance_events) {
            if (event.fault_category) {
                _selected_data[event.id] = event.fault_category;
            }
        }
        setSelectedData(_selected_data);
    }, []);

    const [maintenanceEvents, setMaintenanceEvents] = useState(
        array_to_dict(props.maintenance_events),
    );
    useEffect(() => {
        setIsLoading(true);
        setMaintenanceEvents(array_to_dict(props.maintenance_events));
    }, [props.maintenance_events]);

    useEffect(() => {
        if (props.raw_topn && Object.keys(props.raw_topn).length > 0) {
            setTopn(raw_topn_to_topn(props.raw_topn));
            setIsLoading(false);
        }
        if (props.raw_topn && Object.keys(props.raw_topn).length === 0) {
            setIsLoading(false);
        }
    }, [props.raw_topn]);

    useEffect(() => {
        if (
            topn && maintenanceEvents
            && Object.keys(topn).length > 0 && Object.keys(maintenanceEvents).length > 0
            && !_.isEqual(_prev_events, maintenanceEvents)
        ) {
            const selected_data = make_selected_data(topn, maintenanceEvents);
            setSelectedData(selected_data);
            setPrevEvents(maintenanceEvents);
            if (props.event_id && selected_data && selected_data[props.event_id]) {
                props.onChange(selected_data[props.event_id]);
            }
        }
        if (props.displayTop3 && topn && topn[props.event_id]) {
            const _top3 = [];
            const rows = topn[props.event_id];
            for (const fault_category_id in rows) {
                const percent = rows[fault_category_id];
                const fault_category = props.fault_categories[fault_category_id];
                _top3.push({
                    label: fault_category.name,
                    value: fault_category.id,
                    percent,
                });
            }
            _top3.sort((a, b) => (a.percent > b.percent ? -1 : 1));
            setTop3(_top3);
        }
    }, [topn, maintenanceEvents]);

    useEffect(() => {
        if (props.callbackSelectedData && Object.keys(selectedData).length > 0) {
            props.callbackSelectedData(selectedData);
        }
    }, [selectedData]);

    // const getisLoading = () => {
    //     const events = maintenanceEvents;
    //     if (!events || Object.keys(events).length === 0) {
    //         return false;
    //     }
    //     const event = events[props.event_id];
    //     if (event && event.fault_category) {
    //         return false;
    //     } else if (!selectedData) {
    //         return true;
    //     }
    //     return false;
    // };

    // useEffect(() => {
    //     const ret = getisLoading();
    //     setIsLoading(ret);
    // }, [selectedData, maintenanceEvents]);

    const onChange = (args) => {
        const fault_category_id = args[0];
        const cp_selectedData = Object.assign([], selectedData);
        cp_selectedData[props.event_id] = fault_category_id;
        setSelectedData(cp_selectedData);
        const val = {
            event_id: props.event_id,
            fault_category_id,
        };
        if (!props.onChangeArgStruct) {
            props.onChange(fault_category_id);
        } else {
            const ret = [];
            for (const key of props.onChangeArgStruct) {
                if (val.hasOwnProperty(key)) {
                    ret.push(val[key]);
                }
            }
            props.onChange(ret);
        }
    };
    const event = maintenanceEvents[props.event_id];
    let fault_category = null;
    // if (props.selected) {
    //     fault_category = props.fault_categories[props.selected];
    // } else
    if (event && (
        props.editing[props.event_id] === false
        || (
            !props.editing[props.event_id]
            && event.fault_category
            && props.fault_categories
        ))
    ) {
        if (props.editing[props.event_id] === false) {
            const fault_category_id = selectedData[event.id];
            fault_category = props.fault_categories[fault_category_id];
        } else {
            fault_category = props.fault_categories[event.fault_category];
        }
    }
    if (
        (props.downtimeAnalysis && fault_category)
        || (!props.downtimeAnalysis && fault_category && props.readOnly)
    ) {
        return (
            <div className="fault_category_selected">
                <div className="fault_category_label">
                    {fault_category.name}
                </div>
                {
                    topn[props.event_id] && topn[props.event_id][fault_category.id]
                    && (
                        <div className="fault_category_percent">
                            <div className="fault_category_percent_text">
                                {`${topn[props.event_id][fault_category.id]}%`}
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }

    if (
        (props.downtimeAnalysis && props.readOnly)
        || (!props.downtimeAnalysis && !fault_category && props.readOnly)
    ) {
        return (
            <div className="fault_category_selected">
                <div className="fault_category_label">
                    -
                </div>
            </div>
        );
    }

    const custom_components = {
        ValueContainer: (_props) => (
            <ValueContainer {...({ ..._props, id_getter: (elm) => (props.displayTop3 ? elm.value : elm.id) })} />
        ),
    };

    let options = null;
    let grouped = false;
    if (props.displayTop3 && top3) {
        grouped = true;
        const option = [];
        for (const index in props.fault_categories) {
            const fc = props.fault_categories[index];
            option.push({
                label: fc.name,
                value: fc.id,
            });
        }
        options = [];
        options.push({
            label: 'Suggested',
            options: top3,
        }, {
            label: i18n.t('All categories'),
            options: option && _.sortBy(option, (elm) => elm.label),
        });
        custom_components.Option = (_props) => (
            <OptionContainer {...({ ..._props, id_getter: (elm) => elm.value })} />
        );
        custom_components.GroupHeading = (_props) => (
            <GroupHeadingContainer {..._props} />
        );
    }
    const width = props.width || (props.large ? '100%' : '380px');
    return (
        <div
            style={{
                width,
            }}
            onClick={props.onClick}
            className="fault-category-selector"
        >
            <Selector
                name="fault_category"
                values={options || props.fault_categories}
                id_getter={(item) => item.id}
                labelizer={(item) => item.name}
                onChange={onChange}
                components={custom_components}
                selected={selectedData && selectedData[props.event_id]}
                isLoading={isLoading}
                grouped={grouped}
                style={props.theme || {
                    placeholder: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        transform: 'none',
                        top: 'auto',
                        position: 'static',
                        alignSelf: 'center',
                        flex: '1',
                    },
                    singleValue: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        transform: 'none',
                        top: 'auto',
                        alignSelf: 'center',
                        flex: '1',
                        maxWidth: 'initial',
                        position: 'initial',
                        paddingLeft: '4px',
                    },
                    option: {
                        whiteSpace: 'normal',
                        // '&:hover': {
                        //     backgroundColor: 'red',
                        // }
                    },
                    clearIndicator: {
                        paddingLeft: '0',
                    },
                }}
            />
        </div>
    );
};

FaultCategorySelector.defaultProps = {
    onChangeArgStruct: null,
    editing: {},
    lockSelection: true,
    displayTop3: true,
    readOnly: false,
};

FaultCategorySelector.propTypes = {
    event_id: PropTypes.number,
    maintenance_events: PropTypes.array,
    raw_topn: PropTypes.array,
};

const mapStateToProps = (state, props) => {
    const fault_categories = props.asset_id
        ? faultCategoriesSelectors.getFaultCategoriesForCurrentAsset(state, props.asset_id)
        : faultCategoriesSelectors.getFaultCategoriesForCurrentUser(state);
    const fault_categories_label = props.asset_id
        ? faultCategoriesSelectors.getFaultCategoryGroupLabelForCurrentAsset(state, props.asset_id)
        : faultCategoriesSelectors.getFaultCategoryGroupLabelForCurrentUser(state);
    return {
        fault_categories,
        fault_categories_label,
    };
};

export default connect(mapStateToProps, null)(FaultCategorySelector);
