/**
 * Created by lpostula on 26/10/17.
 */
import { combineReducers } from 'redux';
import types from './types';
import { createReducer } from '../../utils';

const assetStatusFormInitialState = {
    show: false,
    impacting_status: undefined,
    close_action: undefined,
    submit_callback: undefined,
};

const assetStatusFormReducer = createReducer(assetStatusFormInitialState)({
    [types.DISPLAY_ASSET_STATUS_FORM]: (state, action) => ({
        ...state, ...action.payload, show: true,
    }),
    [types.HIDE_ASSET_STATUS_FORM]: () => ({
        ...assetStatusFormInitialState,
    }),
});

const filteringUIInitialState = {
    show: true,
};

const filteringReducer = createReducer(filteringUIInitialState)({
    [types.TOGGLE_FILTER]: (state) => ({
        ...state, show: !state.show,
    }),
});

const SnackbarInitialState = {
    messages: [],
    queue: [],
};

const SnackbarReducer = createReducer(SnackbarInitialState)({
    [types.SB_CREATE]: (state, action) => {
        const messages = state.messages.concat(action.payload);
        return { ...state, messages };
    },
    [types.SB_REMOVE]: (state, action) => {
        const messages = state.messages.filter(
            (message) => message.id !== action.payload.id,
        );
        return { ...state, messages };
    },
    [types.SB_QUEUE]: (state, action) => {
        const queue = state.queue.concat(action.payload);
        return { ...state, queue };
    },
    [types.SB_QUEUE_REMOVE]: (state, action) => {
        const queue = state.queue.filter(
            (message) => message.id !== action.payload.id,
        );
        return { ...state, queue };
    },
});

const ProfilePageInitialState = {
    selected_asset: null,
};

const ProfilePageReducer = createReducer(ProfilePageInitialState)({
    [types.SELECT_ASSET_FOR_PERM_CHECK]: (state, action) => ({
        ...state, selected_asset: action.payload,
    }),
});

const ReloadPageInitialState = {
    force: false,
    message: null,
    active: false,
};

const ReloadPageReducer = createReducer(ReloadPageInitialState)({
    [types.RELOAD_PAGE]: (state, action) => {
        const target_version = action.payload.supernova_version;
        const current_version = window.settings.release;
        return {
            force: action.payload.force,
            message: action.payload.message,
            active: !target_version || target_version !== current_version,
        };
    },
    [types.DISMISS_RELOAD]: () => ({ ...ReloadPageInitialState }),
});

const counterReadingInitialState = {
    selected_asset: null,
};

const counterReadingReducer = createReducer(counterReadingInitialState)({
    [types.SELECT_COUNTER_READING_ASSET]: (state, action) => ({
        ...state, selected_asset: action.payload,
    }),
});

const UIReducer = combineReducers({
    asset_status_form: assetStatusFormReducer,
    filtering: filteringReducer,
    snackbar: SnackbarReducer,
    profile_page: ProfilePageReducer,
    reload_page: ReloadPageReducer,
    counter_reading: counterReadingReducer,
});

export default UIReducer;
