const FETCH_LIST_REQUEST = 'logs/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'logs/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'logs/FETCH_LIST_FAILED';
const FETCH_DETAIL_REQUEST = 'logs/FETCH_DETAIL_REQUEST';
const FETCH_DETAIL_COMPLETED = 'logs/FETCH_DETAIL_COMPLETED';
const FETCH_DETAIL_FAILED = 'logs/FETCH_DETAIL_FAILED';
const CREATE_LOG_REQUEST = 'logs/CREATE_LOG_REQUEST';
const CREATE_LOG_COMPLETED = 'logs/CREATE_LOG_COMPLETED';
const CREATE_LOG_FAILED = 'logs/CREATE_LOG_FAILED';
const EDIT_LOG_REQUEST = 'logs/EDIT_LOG_REQUEST';
const EDIT_LOG_COMPLETED = 'logs/EDIT_LOG_COMPLETED';
const EDIT_LOG_FAILED = 'logs/EDIT_LOG_FAILED';
const ARCHIVE_LOG_REQUEST = 'logs/ARCHIVE_LOG_REQUEST';
const ARCHIVE_LOG_COMPLETED = 'logs/ARCHIVE_LOG_COMPLETED';
const ARCHIVE_LOG_FAILED = 'logs/ARCHIVE_LOG_FAILED';
const UNARCHIVE_LOG_REQUEST = 'logs/UNARCHIVE_LOG_REQUEST';
const UNARCHIVE_LOG_COMPLETED = 'logs/UNARCHIVE_LOG_COMPLETED';
const UNARCHIVE_LOG_FAILED = 'logs/UNARCHIVE_LOG_FAILED';

export default {
    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,
    FETCH_DETAIL_REQUEST,
    FETCH_DETAIL_COMPLETED,
    FETCH_DETAIL_FAILED,
    CREATE_LOG_REQUEST,
    CREATE_LOG_COMPLETED,
    CREATE_LOG_FAILED,
    EDIT_LOG_REQUEST,
    EDIT_LOG_COMPLETED,
    EDIT_LOG_FAILED,
    ARCHIVE_LOG_REQUEST,
    ARCHIVE_LOG_COMPLETED,
    ARCHIVE_LOG_FAILED,
    UNARCHIVE_LOG_REQUEST,
    UNARCHIVE_LOG_COMPLETED,
    UNARCHIVE_LOG_FAILED,
};
