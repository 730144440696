/**
 * Created by lpostula on 10/05/17.
 */
const FILTER_ERROR = 'filtering/FILTER_ERROR';
const TOGGLE_FITLER_VISIBILITY = 'filtering/TOGGLE_FITLER_VISIBILITY';
const SET_SEARCH_TEXT = 'filtering/SET_SEARCH_TEXT';
const SET_SORT_BY = 'filtering/SET_SORT_BY';
const SET_ACTION_MODE = 'filtering/SET_ACTION_MODE';

const INIT_FILTER = 'filtering/INIT_FILTER';
const SET_FILTER_KEY = 'filtering/SET_FILTER_KEY';
const UPDATED_ASSET_INFOS = 'filtering/UPDATED_ASSET_INFOS';

const SET_SELECTED_LABELS = 'filtering/SET_SELECTED_LABELS';
const SET_SELECTED_STATUS = 'filtering/SET_SELECTED_STATUS';
const SET_SELECTED_TECH_STATUS = 'filtering/SET_SELECTED_TECH_STATUS';

const SET_MASK_SEARCH = 'filtering/SET_MASK_SEARCH';
const SET_MASK_FILTER = 'filtering/SET_MASK_FILTER';
const REFRESH_RESULT = 'filtering/REFRESH_RESULT';

export default {
    FILTER_ERROR,
    TOGGLE_FITLER_VISIBILITY,
    SET_SEARCH_TEXT,
    SET_SORT_BY,
    SET_ACTION_MODE,

    INIT_FILTER,
    SET_FILTER_KEY,
    UPDATED_ASSET_INFOS,

    SET_SELECTED_LABELS,
    SET_SELECTED_STATUS,
    SET_SELECTED_TECH_STATUS,

    SET_MASK_SEARCH,
    SET_MASK_FILTER,
    REFRESH_RESULT,
};
