import React, { useState } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { usersSelectors } from 'railfleet_state/ducks/users';
import RestrictionWarning from '../icons/restriction_warning.svg';
import Marker from './components/Marker';
import { TelematicField } from '../../../../railfleet/railfleet/lib/components/display/TelematicValue';
import './AssetMarker.scss';
import { deltaTimeFromNow, prettyDateFromNow } from '../../../../railfleet/railfleet/lib/utils/prettyDate';
import WithPopper from '../../../../railfleet/railfleet/lib/components/app/WithPopper';
import warning_old_tech_status from '../../../../railfleet/railfleet/lib/components/tables/warning.svg';
import info_dark from '../../../../railfleet/railfleet/lib/components/tables/info-dark.svg';
import info_on_hover from '../../../../railfleet/railfleet/lib/components/filters/verticalMultiOptionFilter/info-on-hover.svg';
import linkSign from '../../../../railfleet/railfleet/lib/components/tables/link-sign.svg';

const ASSET_STATUS_TO_LABEL = {
    no_gps: i18n.t('No gps'),
    vehicle: i18n.t('Vehicle'),
    parking: i18n.t('Parking'),
    traction: i18n.t('Traction'),
    poweron: i18n.t('Power on'),
    standby: i18n.t('Idle'),
    unknown: i18n.t('Unknown'),
};

const ASSET_STATUS_TO_ZINDEX = {
    no_gps: 1000,
    unknown: 1000,
    vehicle: 2000,
    parking: 4000,
    traction: 5000,
    poweron: 3000,
    standby: 1000,
};

const OPERATIONAL_STATUS_TO_CLASS = {
    1: 'operational_status_ok',
    2: 'operational_status_degraded',
    3: 'operational_status_immobilized',
};

/* eslint-disable max-len */
class MapIcon extends L.DivIcon {
    static initClass() {
        this.prototype.options = {
            iconSize: [28, 28],
            className: 'map-icon',
            label: 'loco-name',
            restriction: false,
            html: '',
            popupAnchor: [0, -33],
            status: 'no_gps',
        };
    }

    initialize(options) {
        this.options = L.setOptions(this, options);

        let restriction = '';
        let restriction_div = '';
        if (this.options.restriction) {
            restriction = 'restriction';
            restriction_div = `<div class="restriction_icon"><img src="${RestrictionWarning}" /></div>`;
        }

        this.options.html += `<i class='inner ${this.options.status}'></i>`;

        let cls;
        if (options.operational_status) {
            cls = OPERATIONAL_STATUS_TO_CLASS[options.operational_status];
        } else {
            cls = 'operational_status_ok';
        }
        this.options.html += `<div class="label ${cls} ${restriction}"><div class="name">${this.options.label}</div>${restriction_div}</div>`;

        if ('directionAngle' in this.options) {
            const transform = `rotate(${this.options.directionAngle}deg) translateY(-20px)`;
            let compat = `transform: ${transform};`;
            compat += `-moz-transform: ${transform};`;
            compat += `-webkit-transform: ${transform};`;
            this.options.html += `<span class='glyphicon glyphicon-triangle-top direction' style='${compat}' ></span>`;
        }
    }
}
MapIcon.initClass();

const Row = ({ title, value }) => (
    <tr>
        <td>
            <b>{title}</b>
        </td>
        <td>
            {value}
        </td>
    </tr>
);

const GreyRowStatus = ({ title, value, dateStatusLastChange }) => {
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const warningInfoPopper = (
        <WithPopper
            visible={isPopperOpen}
            modifiers={[
                {
                    name: 'offset',
                    options: {
                        offset: ({ placement, reference, popper }) => {
                            if (placement === 'bottom-end' || placement === 'bottom' || placement === 'bottom-start') {
                                return [0, 15];
                            }
                            return [0, -2];
                        },
                    },
                }]}
            extraClass="asset-marker-popper-warning-info"
            usePortal
        >
            <div />
            <div className="info-content">
                <div>
                    {i18n.t('The locomotive is in "{{asset_status}}" status and we didn’t receive update since {{dateStatusLastChange}}.', { asset_status: value, dateStatusLastChange })}
                    <br />
                    <a href="https://help.railnova.eu/en/articles/581328-asset-states" target="_blank" rel="noreferrer">
                        <button className="button-go-intercom-article">
                            <span>{i18n.t('More info in help guide')}</span>
                            {' '}
                            <img
                                src={linkSign}
                                alt="link sign"
                            />
                        </button>
                    </a>
                </div>
            </div>
        </WithPopper>
    );
    return (
        <tr>
            <td>
                <b>{title}</b>
            </td>
            <td>
                <div className="old-tech-status-assetMarker">
                    <img src={warning_old_tech_status} alt="old-tech-status" />
                    <div className="old-value-assetMarker">{value}</div>
                    &nbsp;
                    <img
                        src={isPopperOpen ? info_on_hover : info_dark}
                        alt="optionInfo"
                        onMouseEnter={() => setIsPopperOpen(true)}
                        onMouseLeave={() => setIsPopperOpen(false)}
                    />
                    <div
                        className="warning-info-popper"
                        onMouseEnter={() => setIsPopperOpen(true)}
                        onMouseLeave={() => setIsPopperOpen(false)}
                    >
                        {warningInfoPopper}
                    </div>
                </div>
            </td>
        </tr>
    );
};

const GreyRowStatusDuration = ({ title, value }) => (
    <tr>
        <td>
            <b>{title}</b>
        </td>
        <td>
            <div className="old-tech-status-assetMarker">
                {value}
            </div>
        </td>
    </tr>
);

const TooltipContent = (props) => {
    const {
        id,
        name,
        assign_serial,
    } = props.asset.info;
    const { live_data } = props.asset;
    const asset_status = (live_data['telematic.status'] && live_data['telematic.status'][0]) || 'no_gps';
    const status_last_change = (live_data['telematic.status_last_change'] && live_data['telematic.status_last_change'][0]) || '2018-01-01';
    const last_update = (live_data['telematic.status_last_change'] && live_data['telematic.status_last_change'][1]) || '2018-01-01';
    const location = (live_data['position.location'] && live_data['position.location'][0]) || null;
    const speed = (live_data['position.speed'] && live_data['position.speed'][0]) || 0;
    let latitude = (live_data['position.latitude'] && live_data['position.latitude'][0]) || 0;
    let longitude = (live_data['position.longitude'] && live_data['position.longitude'][0]) || 0;
    latitude /= 1000000;
    longitude /= 1000000;

    // default message for rug1 is telematic
    // and merged for rug2
    const msg_type = (assign_serial && assign_serial < 2000) ? 'telematic' : 'merged';

    const now = moment().toISOString();
    const before = moment().subtract(1, 'hours').toISOString();
    let rowStatus = <Row title={i18n.t('Status')} value={ASSET_STATUS_TO_LABEL[asset_status]} />;
    let rowStatusDuration = <Row title={i18n.t('Status duration')} value={prettyDateFromNow(status_last_change)} />;
    const deltaTimeFromNowOfLastUpdate = deltaTimeFromNow(last_update);
    if ((20 * 60 * 1000) < deltaTimeFromNowOfLastUpdate && asset_status !== 'no_gps' && asset_status !== 'parking') {
        rowStatus = <GreyRowStatus title={i18n.t('Status')} value={ASSET_STATUS_TO_LABEL[asset_status]} dateStatusLastChange={prettyDateFromNow(status_last_change)} />;
        rowStatusDuration = <GreyRowStatusDuration title={i18n.t('Status duration')} value={prettyDateFromNow(status_last_change)} />;
    }

    if (props.fixed) {
        let grey = false;
        if ((20 * 60 * 1000) < deltaTimeFromNowOfLastUpdate && asset_status !== 'no_gps' && asset_status !== 'parking') {
            grey = true;
        }
        const [isPopperOpen, setIsPopperOpen] = useState(false);
        const value = ASSET_STATUS_TO_LABEL[asset_status];
        const dateStatusLastChange = prettyDateFromNow(status_last_change);
        const warningInfoPopper = (
            <WithPopper
                visible={isPopperOpen}
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: ({ placement, reference, popper }) => {
                                if (placement === 'bottom-end' || placement === 'bottom' || placement === 'bottom-start') {
                                    return [0, -2];
                                }
                                return [0, -2];
                            },
                        },
                    }]}
                extraClass="asset-marker-popper-warning-info"
                usePortal
            >
                <div />
                <div className="info-content">
                    <div>
                        {i18n.t('The locomotive is in "{{asset_status}}" status and we didn’t receive update since {{dateStatusLastChange}}.', { asset_status: value, dateStatusLastChange })}
                        <br />
                        <a href="https://help.railnova.eu/en/articles/581328-asset-states" target="_blank" rel="noreferrer">
                            <button className="button-go-intercom-article">
                                <span>{i18n.t('More info in help guide')}</span>
                                {' '}
                                <img
                                    src={linkSign}
                                    alt="link sign"
                                />
                            </button>
                        </a>
                    </div>
                </div>
            </WithPopper>
        );
        return (
            <div className="map_info_window fixed">
                <div className="display-top">
                    <div className="location">
                        <div className="title">{i18n.t('Location')}</div>
                        <div className="value">{location || null}</div>
                    </div>
                </div>
                <div className="display-bottom">
                    <div className={`data${grey ? ' old-tech-status-assetMarker' : ''}`}>
                        <div className="title">{i18n.t('Status')}</div>
                        {
                            grey && (
                                <img src={warning_old_tech_status} alt="old-tech-status" />
                            )
                        }
                        <div className={`value${grey ? ' old-value-assetMarker' : ''}`}>
                            {ASSET_STATUS_TO_LABEL[asset_status]}
                            {
                                grey && (
                                    <>
                                        <img
                                            src={isPopperOpen ? info_on_hover : info_dark}
                                            alt="optionInfo"
                                            onMouseEnter={() => setIsPopperOpen(true)}
                                            onMouseLeave={() => setIsPopperOpen(false)}
                                        />
                                        <div
                                            className="warning-info-popper"
                                            onMouseEnter={() => setIsPopperOpen(true)}
                                            onMouseLeave={() => setIsPopperOpen(false)}
                                        >
                                            {warningInfoPopper}
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <div className="data">
                        <div className="title">{i18n.t('Speed')}</div>
                        <div className="value">{speed ? `${speed} km/h` : null}</div>
                    </div>
                    <div className="data">
                        <div className="title">{i18n.t('Status duration')}</div>
                        <div className="value">{prettyDateFromNow(status_last_change)}</div>
                    </div>
                    <div className="actions">
                        <a href={`https://maps.google.com/maps/dir/Current+Location/${latitude},${longitude}`} target="_blank" rel="noopener noreferrer">
                            {i18n.t('Driving directions')}
                        </a>
                        {props.check_user_perms('page_gps_history')
                            && props.check_user_perms('view_gps_history_data', id)
                            && (
                                <a
                                    href={`/history/#/?asset_id=${id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {i18n.t('GPS history')}
                                </a>
                            )}
                        {props.check_user_perms('page_telematic_data')
                            && props.check_user_perms('view_telematic_history_data', id)
                            && (
                                <a
                                    href={`/telematics/#/?asset=${id}&type=${msg_type}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {i18n.t('Telematics history')}
                                </a>
                            )}
                        {props.check_user_perms('page_data_inspector', id)
                            && props.check_user_perms('view_telematic_history_data', id)
                            && (
                                <a href={`/inspect/#/?asset_id=${id}&begin=${before}&end=${now}&fields=${msg_type}.speed`} target="_blank" rel="noopener noreferrer">
                                    {i18n.t('Data Inspector')}
                                </a>
                            )}
                    </div>
                </div>
            </div>
        );
    }
    let restrictions = '';
    if (props.restrictions) {
        // join all restriction text together
        restrictions = _.pluck(props.restrictions, 'comment').join(';');
    }

    return (
        <table id="map_info_window">
            <tbody>
                <Row title={i18n.t('Asset')} value={name} />
                {rowStatus}
                {rowStatusDuration}
                {(speed !== null) ? <Row title={i18n.t('Speed')} value={`${speed} km/h`} /> : null}
                {location ? <Row title={i18n.t('Location')} value={location} /> : null}
                {props.getConfigurableFields()}

                {restrictions ? <Row title={i18n.t('Restrictions')} value={restrictions} /> : null}

                <tr>
                    <td colSpan="2">
                        <a href={`https://maps.google.com/maps/dir/Current+Location/${latitude},${longitude}`} target="_blank" rel="noopener noreferrer">
                            {i18n.t('Driving directions')}
                        </a>
                    </td>
                </tr>
                {props.check_user_perms('page_gps_history')
                    && props.check_user_perms('view_gps_history_data', id)
                    && (
                        <tr>
                            <td colSpan="2">
                                <a href={`/history/#/?asset_id=${id}`} target="_blank" rel="noopener noreferrer">
                                    {i18n.t('GPS history')}
                                </a>
                            </td>
                        </tr>
                    )}
                {props.check_user_perms('page_telematic_data')
                    && props.check_user_perms('view_telematic_history_data', id)
                    && (
                        <tr>
                            <td colSpan="2">
                                <a href={`/telematics/#/?asset=${id}&type=${msg_type}`} target="_blank" rel="noopener noreferrer">
                                    {i18n.t('Telematics history')}
                                </a>
                            </td>
                        </tr>
                    )}
                {props.check_user_perms('page_data_inspector', id)
                    && props.check_user_perms('view_telematic_history_data', id)
                    && (
                        <tr>
                            <td colSpan="2">
                                <a href={`/inspect/#/?asset_id=${id}&begin=${before}&end=${now}&fields=${msg_type}.speed`} target="_blank" rel="noopener noreferrer">
                                    {i18n.t('Data Inspector')}
                                </a>
                            </td>
                        </tr>
                    )}
            </tbody>
        </table>
    );
};

class AssetMarker extends React.PureComponent {
    constructor(props) {
        super(props);
        this.marker = null;
        this.getConfigurableFields = this.getConfigurableFields.bind(this);
    }

    getConfigurableFields() {
        const cfg = this.props.data_config;
        const { live_data } = this.props.asset;

        if (!cfg || !live_data) {
            return [];
        }

        const ret = [];
        for (const field_config of cfg.fields) {
            // only show related columns
            if (field_config.show_operational === false) {
                continue;
            }

            const data = live_data[field_config.id];
            if (!data) {
                continue;
            }

            const [value, date] = data;

            if (value === null || value === undefined) {
                continue;
            }

            ret.push(
                <tr key={field_config.id}>
                    <td>
                        <b>{field_config.name}</b>
                    </td>
                    <td>
                        <TelematicField
                            config={field_config}
                            value={value}
                            date={date}
                            language={this.props.user.language}
                        />
                    </td>
                </tr>,
            );
        }
        return ret;
    }

    getIconForAsset(asset, asset_status, course, speed) {
        // This is called when assets are updated
        const cfg = {
            label: asset.info.name,
            status: asset_status,
            operational_status: asset.operational.operational_status,
            restriction: false,
        };

        if (this.props.restrictions) {
            cfg.restriction = true;
        }

        if (speed > 1) {
            cfg.directionAngle = course;
        }

        return new MapIcon(cfg);
    }

    render() {
        const {
            id,
            name,
        } = this.props.asset.info;

        const { live_data } = this.props.asset;
        let latitude = (live_data['position.latitude'] && live_data['position.latitude'][0]) || 0;
        let longitude = (live_data['position.longitude'] && live_data['position.longitude'][0]) || 0;
        const asset_status = (live_data['telematic.status'] && live_data['telematic.status'][0]) || 'no_gps';
        const course = (live_data['position.course'] && live_data['position.course'][0]) || 0;
        const speed = (live_data['position.speed'] && live_data['position.speed'][0]) || 0;

        latitude /= 1000000;
        longitude /= 1000000;

        if (this.props.fixed) {
            return (
                <TooltipContent
                    live_data={live_data}
                    getConfigurableFields={this.getConfigurableFields}
                    {...this.props}
                />
            );
        }

        return (
            <Marker
                {...this.props}
                ref={(x) => { this.marker = x; }}
                title={name}
                zIndexOffset={ASSET_STATUS_TO_ZINDEX[asset_status]}
                position={[
                    latitude,
                    longitude,
                ]}
                icon={this.getIconForAsset(this.props.asset, asset_status, course, speed)}
                onClick={() => this.props.onClick(this.props.asset)}
            >
                {
                    !this.props.hideTooltip ? (
                        <TooltipContent
                            live_data={live_data}
                            getConfigurableFields={this.getConfigurableFields}
                            {...this.props}
                        />
                    ) : (null)
                }
            </Marker>
        );
    }
}
AssetMarker.defaultProps = {
    onClick: () => { },
};
AssetMarker.propTypes = {
    onClick: PropTypes.func,
    opened: PropTypes.bool,
    asset: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    check_user_perms: usersSelectors.userHasPerm(state),
});
export default connect(mapStateToProps, null)(AssetMarker);
