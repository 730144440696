const FETCH_DETAIL_REQUEST = 'counters_readings/FETCH_DETAIL_REQUEST';
const FETCH_DETAIL_COMPLETED = 'counters_readings/FETCH_DETAIL_COMPLETED';
const FETCH_DETAIL_FAILED = 'counters_readings/FETCH_DETAIL_FAILED';

const FETCH_LIST_REQUEST = 'counters_readings/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'counters_readings/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'counters_readings/FETCH_LIST_FAILED';

const CREATE_REQUEST = 'counters_readings/CREATE_REQUEST';
const CREATE_COMPLETED = 'counters_readings/CREATE_COMPLETED';
const CREATE_FAILED = 'counters_readings/CREATE_FAILED';

export default {
    FETCH_DETAIL_REQUEST,
    FETCH_DETAIL_COMPLETED,
    FETCH_DETAIL_FAILED,

    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,

    CREATE_REQUEST,
    CREATE_COMPLETED,
    CREATE_FAILED,
};
