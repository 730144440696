/**
 * Created by lpostula on 12/05/17.
 */
import { createSelector } from 'reselect';

const getStatus = (state) => state.asset_operational_status;

const getStatusForAsset = (state, asset_id) => _.filter(
    state.asset_operational_status,
    ((s) => s.asset === asset_id),
);

export const makeStatusForAsset = () => createSelector(
    [getStatusForAsset],
    (status) => status,
);

export const makeStatusListForAsset = () => {
    const status_for_asset = makeStatusForAsset();
    return createSelector(
        [status_for_asset],
        (status) => _.values(status),
    );
};

export default {
    getStatus,
    makeStatusForAsset,
    makeStatusListForAsset,
};
