import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import components from 'components';
import { uxSelectors, uxOperations } from 'railfleet_state/ducks/ux';
import { interventionsOperations } from 'railfleet_state/ducks/interventions';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import './create_corrective_event.scss';
import CorrectiveEventForm from '../../../../railfleet/lib/compounds/forms/correctiveEventForm/correctiveEventForm';

const { MultiDeviceLayout } = components.app;
const { HeaderModal } = components.headers;
const { usePerm } = components.hooks;

const CreateCorrectiveEventComponent = (props) => {
    const [_pushInfos, setPushInfos] = useState(null);
    const hasPerm = usePerm(props.asset_id);

    useEffect(() => {
        if (props.push_infos && props.push_infos.extras && props.push_infos.extras.target === 'status_change') return;
        setPushInfos(props.push_infos);
        props.setPushInfos(null, 'create corrective');
    }, []);

    const createCallback = (event) => {
        if (event.impact_status > props.asset?.operational_status) {
            if (hasPerm('aggravate_status')) {
                let from = `/operational/#/asset/${props.asset_id}/events/${event.id}/view`;
                if (_pushInfos && _pushInfos.from) {
                    const intervention_id = _pushInfos.extras.id;
                    props.addEvents(intervention_id, [event.id]);
                    from = _pushInfos.from;
                }
                const push_infos = {
                    from,
                    extras: {
                        impact_status_id: event.impact_status,
                    },
                };

                props.setPushInfos(push_infos);
                window.location.href = `/operational/#/asset/${props.asset_id}`;
            } else {
                props.router.push(
                    `/asset/${props.asset_id}/events/${event.id}/view`,
                );
            }
        } else if (_pushInfos && (_pushInfos.extras && !_pushInfos.extras.impact_status_id)) {
            const intervention_id = _pushInfos.extras.id;
            props.addEvents(intervention_id, [event.id]);
            window.location.href = _pushInfos.from;
        } else {
            const escapeRE = new RegExp(/^\/(\w+)\//g);
            const rootPath = props.router.location.pathname.match(escapeRE)[0];
            props.router.push(
                `${rootPath}asset/${props.asset_id}/events/${event.id}/view`,
            );
        }
    };

    const onClickPrevious = () => {
        if (_pushInfos && (!_pushInfos.extras || !_pushInfos.extras.impact_status_id)) {
            window.location.href = _pushInfos.from;
        } else {
            props.router.push(
                `/asset/${props.asset_id}/events/${props.event_id}/view`,
            );
        }
    };

    return (
        <HeaderModal
            title={i18n.t('Corrective event')}
            subtitle={props.event_id ? i18n.t('Edit') : i18n.t('Create')}
            onCloseModal={props.onCloseModal}
            previous_link={props.onClickPrevious || onClickPrevious}
        >
            <CorrectiveEventForm
                asset_id={props.asset_id}
                event_id={props.event_id}
                poi={_pushInfos && _pushInfos.extras && _pushInfos.extras.location_id}
                cancelCallback={props.cancelCallback || props.onCloseModal}
                callback={props.createCallback || createCallback}
                maintenance_type={props.maintenance_type}
            />
        </HeaderModal>
    );
};

const CreateCorrectiveEvent = (props) => (
    <MultiDeviceLayout>
        <CreateCorrectiveEventComponent {...props} />
    </MultiDeviceLayout>
);
const mapStateToProps = (state, props) => ({
    push_infos: uxSelectors.getPushInfos(state),
    asset: assetsSelectors.getAssetOperationals(state, props.asset_id),
});

const mapDispatchToProps = (dispatch) => ({
    setPushInfos: (push_infos, from = null) => dispatch(uxOperations.setPushInfos(push_infos, from)),
    addEvents: (intervention_id, ids) => dispatch(
        interventionsOperations.addEventsInIntervention(intervention_id, ids),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCorrectiveEvent);
