import React, { createContext, useState } from 'react';

export const StepContext = createContext({});
const Step = (props) => {
    const [_display, setDisplay] = useState(false);
    const addKey = (arg, arg2) => {
        setDisplay(true);
        if (props.addKey) props.addKey(arg, arg2);
    };

    const deleteKey = (arg, arg2) => {
        setDisplay(true);
        if (props.deleteKey) props.deleteKey(arg, arg2);
    };

    const context = {
        step: props.id,
        addKey,
        deleteKey,
    };

    if (!_display) {
        return (
            <div className="hide">
                <StepContext.Provider value={context}>
                    { props.children }
                </StepContext.Provider>
            </div>
        );
    }

    return (
        <StepContext.Provider value={context}>
            { props.children }
        </StepContext.Provider>
    );
};
export default Step;
