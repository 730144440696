const FETCH_LIST_REQUEST = 'hotlines/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'hotlines/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'hotlines/FETCH_LIST_FAILED';

const FETCH_DETAILS_REQUEST = 'hotlines/FETCH_DETAILS_REQUEST';
const FETCH_DETAILS_COMPLETED = 'hotlines/FETCH_DETAILS_COMPLETED';
const FETCH_DETAILS_FAILED = 'hotlines/FETCH_DETAILS_FAILED';

const UPDATE_DETAILS_REQUEST = 'hotlines/UPDATE_DETAILS_REQUEST';
const UPDATE_DETAILS_COMPLETED = 'hotlines/UPDATE_DETAILS_COMPLETED';
const UPDATE_DETAILS_FAILED = 'hotlines/UPDATE_DETAILS_FAILED';

const CREATE_HOTLINE_REQUEST = 'hotlines/CREATE_HOTLINE_REQUEST';
const CREATE_HOTLINE_COMPLETED = 'hotlines/CREATE_HOTLINE_COMPLETED';
const CREATE_HOTLINE_FAILED = 'hotlines/CREATE_HOTLINE_FAILED';

export default {
    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,
    FETCH_DETAILS_REQUEST,
    FETCH_DETAILS_COMPLETED,
    FETCH_DETAILS_FAILED,
    UPDATE_DETAILS_REQUEST,
    UPDATE_DETAILS_COMPLETED,
    UPDATE_DETAILS_FAILED,
    CREATE_HOTLINE_REQUEST,
    CREATE_HOTLINE_COMPLETED,
    CREATE_HOTLINE_FAILED,
};
