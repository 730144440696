import { createSelector } from 'reselect';
import collections from './collections';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

export const getCollectionFilter = (state, collection) => {
    const filters_state = state.filtering_collections;
    const collection_state = filters_state[collection];
    if (collection_state) {
        return collection_state;
    }
    return EMPTY_OBJECT;
};

export const getAllCollection = (state, collection_key) => {
    const collection = collections[collection_key];
    if (!collection) return EMPTY_ARRAY;
    return collection.selector(state);
};

export const isCollectionFetching = createSelector(
    [getCollectionFilter],
    (f) => f.fetching,
);

export const isCollectionPristine = createSelector(
    [getCollectionFilter],
    (f) => f.pristine,
);

export const isCollectionFailed = createSelector(
    [getCollectionFilter],
    (f) => f.failed,
);

export const getCollectionController = createSelector(
    [getCollectionFilter],
    (f) => f.controller,
);

export const getCollectionFields = createSelector(
    [getCollectionFilter],
    (f) => f.fields,
);

export const getCollectionPaginationInfo = createSelector(
    [getCollectionFilter],
    (f) => f.pagination,
);

export const getCollectionPaginationLinks = createSelector(
    [getCollectionPaginationInfo],
    (i) => i.links,
);

export const getCollectionPaginationCurrentPage = createSelector(
    [getCollectionPaginationInfo],
    (i) => i.current_page || 0,
);

export const getCollectionPaginationTotalPage = createSelector(
    [getCollectionPaginationInfo],
    (i) => {
        if (i.links && i.links.last) {
            return i.links.last.page;
        }
        return i.current_page || 0;
    },
);

export const getCollectionForCurrentPage = createSelector(
    [getCollectionPaginationInfo, getAllCollection],
    (p, c) => {
        const { current_page } = p;
        const page_info = p.pages[current_page];
        if (!page_info) return EMPTY_ARRAY;
        const out = [];
        for (const id of page_info.ids) {
            if (c.hasOwnProperty(id) && c[id]) {
                out.push(c[id]);
            }
        }
        return out;
    },
);

export default {
    isCollectionFetching,
    isCollectionPristine,
    isCollectionFailed,
    getCollectionController,
    getCollectionFields,
    getCollectionPaginationInfo,
    getCollectionPaginationLinks,
    getCollectionPaginationCurrentPage,
    getCollectionPaginationTotalPage,
    getCollectionForCurrentPage,
};
