import React from 'react';
import './simpleTimeline.scss';

const SimpleTimeline = (props) => (
    <div className="timeline step-manager-header">
        <div className="step-manager-holder">
            <div className="step-manager-bar" />
            <div>
                {
                    props.values?.map((elm) => (
                        <div className={`step-manager-elm ${elm.selected ? 'active' : ''}`} key={elm.text}>
                            <div className="step-manager-bullet" />
                            <div>
                                {elm.text}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    </div>
);

export default SimpleTimeline;
