/**
 * Created by lpostula on 05/12/17.
 */

// todo that
// import i18n from 'i18next';;

const NOT_SUPPORTED = 'WebSocket is not supported by your browser';
const TOO_MANY_RETRY = 'too many reconnection attempts';
const UNMATCH_RESOURCE = 'resource not match';

export default {
    NOT_SUPPORTED,
    TOO_MANY_RETRY,
    UNMATCH_RESOURCE,
};
