import React from 'react';
import './TelematicDataOverlay.scss';
import errorTelematicData from './errorTelematicData.svg';
import nodataTelematic from './noDataTelematic.svg';

const TelematicDataOverlay = (props) => {
    let imgSrc = null;
    let colorText = null;
    if (props.error) {
        imgSrc = errorTelematicData;
        colorText = '#ff3e62';
    }
    if (props.nodata) {
        imgSrc = nodataTelematic;
        colorText = '#848ea0';
    }
    return (
        <div className="telematic-data-overlay">
            <div className="grid-container">
                <div />
                <div>
                    <div className="telematic-data-image">
                        <img src={imgSrc} alt="telematicData" />
                    </div>
                    <div className="telematic-title" style={{ color: colorText }}>{props.title}</div>
                    <div className="telematic-subtitle" style={{ color: colorText }}>{props.subtitle}</div>
                </div>
            </div>
        </div>
    );
};

export default TelematicDataOverlay;
