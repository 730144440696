import React from 'react';
import MenuProduct from './menu_product';
import RailfleetLogo from './img/railfleet_logo.svg';
import RailgeniusLogo from './img/railgenius_logo.svg';
import RailsterLogo from './img/railster_logo.svg';
import UserMenu from './user_menu';
import './main_menu.css';

const LOGOS = {
    railfleet: RailfleetLogo,
    railgenius: RailgeniusLogo,
    railster: RailsterLogo,
};

const MainMenu = ({ user, logout_url, onClick }) => (
    <div className="main-menu">
        {user && user.is_free_plan && (
            <span className="glyphicon glyphicon-star free-trial">
                <span>Free Plan</span>
            </span>
        )}
        {/* Display every app besides quicklinks */}
        <div className="menu-product-list">
            {user && user.pages && user.pages.map((category) => (
                (category.id !== 'top-nav')
                    && (
                        <MenuProduct
                            key={`menu_${category.id}`}
                            category={category}
                            logo={LOGOS[category.id]}
                            onClick={onClick}
                        />
                    )

            ))}
        </div>
        <UserMenu
            logout_url={logout_url}
        />
    </div>
);
export default MainMenu;
