/**
 * Created by lpostula on 26/10/17.
 */
import { createSelector } from 'reselect';

const getWSState = (state) => state.ws;

const getPollingID = createSelector(
    [getWSState],
    (s) => s.polling_id,
);

export default {
    getWSState,
    getPollingID,
};
