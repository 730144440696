/**
 * Created by lpostula on 11/05/17.
 */
// import { createSelector } from 'reselect';

export const getDataConfigs = (state) => state.data_configs;

export const getDataConfig = (state, id) => state.data_configs[id];

export default {
    getDataConfigs,
    getDataConfig,
};
