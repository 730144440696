import React, { useState, useEffect, useRef } from 'react';
import { usePopper } from 'react-popper-2';
import usePrevious from '../hooks/usePrevious';

import Portal from './portal';

const isInViewport = function (elem) {
    const bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0
        && bounding.left >= 0
        && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

const WithPopper = (props) => {
    const [visible, setVisibility] = useState(props.visible || false);
    const [locked, setLocked] = useState(false);
    // const [firstRender, setFirstRender] = useState(true);

    const prevProps = usePrevious(props);
    const prevVisible = usePrevious(visible);
    const prevLocked = usePrevious(locked);

    const baseRef = useRef(null);
    const popperRef = useRef(null);

    const {
        styles, attributes, update, state,
    } = usePopper(
        baseRef.current,
        popperRef.current,
        {
            placement: props.placement || 'bottom',
            strategy: props.strategy || 'fixed',
            modifiers: props.modifiers ? [...props.modifiers] : [],
        },
    );

    const updatePosition = async () => update();

    const handleDocumentClick = (event) => {
        if (
            (popperRef && popperRef.current
            && (popperRef.current.contains(event.target) || baseRef.current.contains(event.target)))
            || props.noEvent
        ) {
            return;
        }
        setVisibility(false);
        setLocked(false);
    };

    const handleReferenceClick = () => {
        if (!props.dontOpenOnClick && !props.disabled) {
            setVisibility(!visible || !locked);
            setLocked(!locked);
            updatePosition();
        }
    };

    const handleReferenceEnter = async () => {
        if (!props.dontOpenOnHover && !props.disabled) {
            setVisibility(true);
            updatePosition();
        }
    };

    const handleReferenceOut = async () => {
        if (!props.dontOpenOnHover && !locked && !props.disabled) {
            setVisibility(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleDocumentClick);
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, []);

    useEffect(() => {
        if (props.openCallback) {
            props.openCallback(visible);
            if (update) updatePosition();
        }
    }, [visible]);

    useEffect(() => {
        if (props.visible !== null && props.visible !== visible) {
            setVisibility(props.visible);
            if (update) updatePosition();
        }
    }, [props.visible]);

    useEffect(() => {
        if (state && props.popperOverflow) {
            // somehow this is the condition that matches when Popper as
            // correctly position the popper element
            if (prevProps.visible === props.visible
                && prevVisible === visible && prevLocked === locked) {
                const overflows = (!isInViewport(state.elements.popper)
                    && isInViewport(state.elements.reference));
                props.popperOverflow(overflows);
            }
        }
    });

    if (props.children.length !== 2) return null;
    const spanBaseRef = (
        <span
            ref={baseRef}
            onClick={handleReferenceClick}
            onMouseEnter={handleReferenceEnter}
            onMouseLeave={handleReferenceOut}
        >
            {props.children[0]}
        </span>
    );
    const divPopperRef = (
        <div
            className={props.extraClass || ''}
            ref={popperRef}
            style={{ ...styles.popper, zIndex: 10000 }}
            {...attributes.popper}
        >
            {
                visible ? props.children[1] : null
            }
        </div>
    );
    let withPopper = (
        <>
            {spanBaseRef}
            {divPopperRef}
        </>
    );
    if (props.usePortal) {
        withPopper = (
            <>
                {spanBaseRef}
                <Portal>
                    {divPopperRef}
                </Portal>
            </>
        );
    }
    return withPopper;
};
WithPopper.defaultProps = {
    dontOpenOnHover: false,
    dontOpenOnClick: false,
    extraClass: '',
    openCallback: null,
    disabled: false,
    visible: null,
    usePortal: false,
};

export default WithPopper;
