import React from 'react';
import moment from 'moment-timezone';
import './DuplicateTreated.scss';
import i18n from 'i18next';
import usePost from 'components/hooks/usePost';
import Revert from './revert.svg';
import Link from './link.svg';

const DuplicateTreated = (props) => {
    const redirect = (event_id) => {
        const isPlanning = window.location.hash.includes('planning');
        let url = '/operational/#/';
        if (isPlanning) {
            url = `${url}planning/asset/${props.asset_id}/events/${event_id}/view`;
        } else {
            url = `${url}asset/${props.asset_id}/events/${event_id}/view`;
        }
        window.open(url, '_blank', 'noreferrer');
    };

    const { postAll, hasError, isLoading } = usePost();
    const revert = (event_id) => {
        postAll('/api/v2/maintenance/status_duplicate/', {
            event_1: props.event_1,
            event_2: event_id,
            status: 'candidate_duplicate',
        }).then((d) => {
            if (d) {
                props.onChangeStatus();
            }
        });
    };

    const SimilarIssue = ({
        data, title, duplicate,
    }) => (
        <div className="similar-issue">
            <div className="similar-issue-title">{title}</div>
            <div className="similar-issue-data">
                {data.map((elm) => {
                    let duplicat = props.duplicat && _.filter(props.duplicat[props.event_1], (element) => element.event_1 === elm);
                    if (_.isEmpty(duplicat)) {
                        duplicat = props.duplicat && _.filter(props.duplicat[props.event_1], (element) => element.event_2 === elm);
                    }
                    duplicat = duplicat && duplicat[0];
                    return (
                        <div className="similar-issue-row" key={elm}>
                            <div>
                                <div
                                    className="similar-issue-link"
                                    onClick={() => redirect(elm)}
                                >
                                    <div>{`M${elm}`}</div>
                                    <img src={Link} alt="link outside" />
                                </div>
                                <div className="similar-issue-bold">
                                    {duplicate ? i18n.t('is a duplicate') : i18n.t('is not a duplicate')}
                                </div>
                                <div className="similar-issue-text">{i18n.t('of this corrective event')}</div>
                                <div className="similar-issue-author">{`(${duplicat?.user_status} ${i18n.t('on')} ${moment(duplicat?.date_status).format('DD/MM/YYYY, HH:mm')})`}</div>
                            </div>
                            <div
                                className="similar-issue-actions"
                                onClick={() => revert(elm)}
                            >
                                <img src={Revert} alt="revert" />
                                <div>{i18n.t('revert')}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );

    const confirmed = _.filter(props.confirmed, (elm) => parseInt(elm, 10) !== parseInt(props.event_1, 10));
    const denied = _.filter(props.denied, (elm) => parseInt(elm, 10) !== parseInt(props.event_1, 10));

    if (_.isEmpty(confirmed) && _.isEmpty(denied)) return null;

    return (
        <div className="similar-issue-found-holder">
            <div className="similar-issue-found">
                <div className="similar-issue-header">
                    <div>{i18n.t('Similar Issues Found')}</div>
                    <div>{i18n.t('Similar opened issues were found over time, you can find below a list of user(s) decisions taken on each of them (you can choose to revert these decisions)')}</div>
                </div>
                <div className="similar-issue-body">
                    {
                        !_.isEmpty(props.confirmed) && (
                            <SimilarIssue
                                title={i18n.t('Confirmed as a duplicate')}
                                duplicate
                                data={confirmed}
                            />
                        )
                    }
                    {
                        !_.isEmpty(props.denied) && (
                            <SimilarIssue
                                title={i18n.t('Marked as not a duplicate')}
                                data={denied}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default DuplicateTreated;
