import React, { Component, Fragment } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { assetClassesSelectors } from 'railfleet_state/ducks/asset_classes';
import { labelsSelectors } from 'railfleet_state/ducks/labels';
import { labelCategoriesSelectors } from 'railfleet_state/ducks/label_categories';
import { maintenanceSettingsSelectors } from 'railfleet_state/ducks/maintenance_settings';
import { filteringOperations, filteringSelectors } from 'railfleet_state/ducks/filtering';
import { operationsSelectors } from 'railfleet_state/ducks/operations';
import { uiSelectors } from 'railfleet_state/ducks/ui';
import { usersSelectors } from 'railfleet_state/ducks/users';

import Selector from '../selectors/selector/Selector';

import './AssetFilter.scss';
import './AssetFilter.css';

const GPS_STATUS_TO_GLYPH = {
    no_gps: 'question-sign',
    vehicle: 'link',
    parking: 'off',
    traction: 'play-circle',
    poweron: 'flash',
    standby: 'hourglass',
};

/** Filter the options displayed by label only (and not by value, ie: the asset id) */
const _filterOption = (option, input) => option.label.includes(input);

class _AssetFilter extends Component {
    static sort_options(a, b) {
        let sa;
        if (a.label) {
            sa = a.label.toLowerCase();
        }

        let sb;
        if (b.label) {
            sb = b.label.toLowerCase();
        }

        return -(sa < sb) || +(sa !== sb);
    }

    static techOptionRenderer(option) {
        return (
            <span>
                <span
                    className={`map-icon status_${option.value}`}
                    style={{ marginLeft: 4, marginRight: 4 }}
                >
                    <span
                        className={`inner glyphicon glyphicon-${GPS_STATUS_TO_GLYPH[option.value]}`}
                    />
                </span>
                {option.label}
            </span>
        );
    }

    static getTechStatusOptions() {
        const options = [
            { value: 'no_gps', label: i18n.t('No gps') },
            { value: 'vehicle', label: i18n.t('Vehicle') },
            { value: 'parking', label: i18n.t('Parking') },
            { value: 'traction', label: i18n.t('Traction') },
            { value: 'poweron', label: i18n.t('Power on') },
            { value: 'standby', label: i18n.t('Idle') },
        ];

        options.sort(_AssetFilter.sort_options);
        return options;
    }

    constructor(props) {
        super(props);
        this.onActionChange = this.onActionChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onTechStatusChange = this.onTechStatusChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onLabelChange = this.onLabelChange.bind(this);
        this.onAssetChange = this.onAssetChange.bind(this);
        this.onOperationChange = this.onOperationChange.bind(this);
    }

    componentDidMount() {
        this.props.refreshResult();
    }

    onSearchChange(evt) {
        return this.props.setSearchText(evt.target.value);
    }

    onSortChange(evt) {
        return this.props.setSortBy(evt[0]);
    }

    onActionChange(evt) {
        return this.props.setActionMode(evt[0]);
    }

    onAssetChange(assets) {
        return this.props.setSelectedAssets(assets);
    }

    onLabelChange(labels) {
        return this.props.setSelectedLabels(labels);
    }

    onStatusChange(status) {
        return this.props.setSelectedStatus(status);
    }

    onTechStatusChange(status) {
        return this.props.setSelectedTechStatus(status);
    }

    onOperationChange(operations) {
        return this.props.setSelectedOperations(operations);
    }

    sort_func(a, b) {
        let sa = String(a.label).toLowerCase();
        let sb = String(b.label).toLowerCase();
        if (a.value < 0 || b.value < 0) {
            sa = a.value;
            sb = b.value;
        }
        return -(sa < sb) || +(sa !== sb);
    }

    renderAssetFilter() {
        return (
            <div className="input-group filter" id="asset_filter">
                <span className="input-group-addon filter">
                    {i18n.t('Assets')}
                </span>
                <Selector
                    name="asset_filter"
                    // Filter by label only
                    filterOption={_filterOption}
                    values={this.props.asset_classes.concat(this.props.assets)}
                    id_getter={(item) => item.value}
                    labelizer={(item) => item.label}
                    selected={this.props.selectedAssetClasses.concat(this.props.selectedAssets)}
                    multi
                    autosize={false}
                    clearable
                    onChange={this.onAssetChange}
                    label={i18n.t('Filter by asset or asset class')}
                    disabled={this.props.disabled}
                    sort_func={this.sort_func}
                    virtual
                />
            </div>
        );
    }

    renderLabelFilter() {
        return (
            <div className="input-group filter">
                <span className="input-group-addon filter">
                    {i18n.t('Labels')}
                </span>
                <Selector
                    name="label_filter"
                    values={this.props.labels}
                    selected={this.props.selectedLabels}
                    id_getter={(item) => item.value}
                    labelizer={(item) => item.label}
                    multi
                    autosize={false}
                    clearable
                    onChange={this.onLabelChange}
                    label={i18n.t('Filter by label')}
                    disabled={this.props.disabled}
                />
            </div>
        );
    }

    renderStatusFilter() {
        return (
            <div className="input-group filter">
                <span className="input-group-addon filter">
                    {i18n.t('Asset status')}
                </span>
                <Selector
                    name="status_filter"
                    values={this.props.operational_status}
                    selected={this.props.selectedStatus}
                    id_getter={(item) => item.value}
                    labelizer={(item) => item.label}
                    multi
                    autosize={false}
                    clearable
                    onChange={this.onStatusChange}
                    label={i18n.t('Filter by status')}
                    disabled={this.props.disabled}
                />
            </div>
        );
    }

    renderTechStatusFilter() {
        return (
            <div className="input-group filter">
                <span className="input-group-addon filter">
                    {i18n.t('Tech status')}
                </span>
                <Selector
                    name="tech_status_filter"
                    values={_AssetFilter.getTechStatusOptions()}
                    selected={this.props.selectedTechStatus}
                    id_getter={(item) => item.value}
                    labelizer={(item) => item.label}
                    multi
                    autosize={false}
                    clearable
                    onChange={this.onTechStatusChange}
                    label={i18n.t('Filter by tech status')}
                    optionRenderer={_AssetFilter.techOptionRenderer}
                    valueRenderer={_AssetFilter.techOptionRenderer}
                    disabled={this.props.disabled}
                />
            </div>
        );
    }

    renderOperationFilter() {
        return (
            <div className="input-group filter" id="operation_filter">
                <span className="input-group-addon filter">
                    {i18n.t('Filter by operation')}
                </span>
                <Selector
                    name="filter_by_operation"
                    values={this.props.operations}
                    selected={this.props.selectedOperations}
                    id_getter={(item) => item.value}
                    labelizer={(item) => item.label}
                    multi
                    autosize={false}
                    clearable
                    onChange={this.onOperationChange}
                    label={i18n.t('Filter by operation')}
                    disabled={this.props.disabled}
                />
            </div>
        );
    }

    renderSorting() {
        const options = [
            {
                options: [
                    { label: i18n.t('Asset name'), value: 'asset_name' },
                    { label: i18n.t('Asset class'), value: 'asset_class' },
                    { label: i18n.t('next_immobilization_date'), value: 'next_immobilization_date' },
                    { label: i18n.t('Tech status'), value: 'tech_status' },
                    { label: i18n.t('Tech status duration'), value: 'tech_status_duration' },
                    { label: i18n.t('Operational status'), value: 'operational_status' },
                    { label: i18n.t('Alert count'), value: 'alert_count' },
                    { label: i18n.t('Event count'), value: 'event_count' },
                    { label: i18n.t('Intervention count'), value: 'intervention_count' },
                ],
            },
        ];

        if (this.props.label_categories.length > 0) {
            const option = [
                {
                    label: '------',
                    options: [],
                },
            ];
            for (const category of this.props.label_categories) {
                option[0].options.push(
                    { label: category.name, value: `label_category__${category.id}` },
                );
            }
            options.push(option[0]);
        }
        return (
            <div className="input-group filter">
                <span className="input-group-addon filter">
                    {i18n.t('Sort by')}
                </span>
                <Selector
                    className="form-control"
                    name="asset_sort"
                    onChange={this.onSortChange}
                    selected={this.props.sortBy}
                    disabled={this.props.disabled}
                    values={options}
                    id_getter={(item) => item.value}
                    labelizer={(item) => item.label}
                    clearable={false}
                    grouped
                />
            </div>
        );
    }

    renderActionChange() {
        let option;
        const options = [
            {
                options: [
                    { label: i18n.t('All'), value: 'all' },
                ],
            },
            {
                label: '------',
                options: [
                    { label: i18n.t('Alerts Open'), value: 'alert_open' },
                ],
            },
        ];
        if (this.props.company_list) {
            option = [
                {
                    label: '------',
                    options: [],
                },
            ];
            for (const company of this.props.company_list) {
                option[0].options.push(
                    {
                        label: `${i18n.t('Alerts open by')} ${company.name}`,
                        value: `alert_open_by__${company.id}`,
                    },
                );
            }
            options.push(option[0]);
        }

        option = [
            {
                label: '------',
                options: [
                    { label: i18n.t('Preventive planning -- event due within 4 weeks'), value: 'event_preventive_due_within_4_weeks' },
                    { label: i18n.t('Preventive planning - all events'), value: 'event_preventive_due' },
                    { label: i18n.t('Events to be planned'), value: 'event_unplanned' },
                    { label: i18n.t('Events currently planned'), value: 'event_planned' },
                    { label: i18n.t('All Events Open (planned + unplanned)'), value: 'event_open' },
                ],
            },
        ];
        options.push(option[0]);

        option = [
            {
                label: '------',
                options: [
                    { label: i18n.t('Intervention in progress'), value: 'intervention_in_progress' },
                    { label: i18n.t('Intervention starting or ending today'), value: 'intervention_today' },
                    { label: i18n.t('Intervention planned after today'), value: 'intervention_after_today' },
                ],
            },
        ];
        options.push(option[0]);

        return (
            <div className="input-group filter">
                <span className="input-group-addon filter">
                    {i18n.t('Action')}
                </span>
                <Selector
                    className="form-control"
                    name="action_filter"
                    onChange={this.onActionChange}
                    selected={this.props.actionMode}
                    disabled={this.props.disabled}
                    values={options}
                    id_getter={(item) => item.value}
                    labelizer={(item) => item.label}
                    clearable={false}
                    grouped
                />
            </div>
        );
    }

    render() {
        let { search_fields } = this.props;
        if (![
            'event_preventive_due',
            'event_preventive_due_within_4_weeks',
        ].includes(this.props.actionMode)) {
            search_fields = _.filter(this.props.search_fields, (o) => o !== 'renderOperationFilter');
        }
        const style = this.props.own_style ? { margin: 'auto', maxWidth: 1600, width: '100%' } : {};

        return (
            <>
                <div
                    className="form-horizontal row float-row asset-filter-inner filter_column_container"
                    id="filter_form"
                    style={style}
                >
                    {_.map(search_fields, (field) => (
                        <div key={`${field}`} className="filter_column">
                            {this[field]()}
                        </div>
                    ))}
                    {_.map(this.props.filter_fields, (field) => (
                        <div key={`${field}`} className="filter_column">
                            {this[field]()}
                        </div>
                    ))}
                </div>
                {this.props.children}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    asset_classes: assetClassesSelectors.getAssetClassesOptionsNegId(state),
    assets: assetsSelectors.getUnfilteredAssetOptions(state),
    labels: labelsSelectors.getAllOperationalLabelsOptions(state),
    label_categories: labelCategoriesSelectors.getOperationalLabelCategoriesForCurrentUserList(
        state,
    ),
    operations: operationsSelectors.getAllOperationsOptions(state),
    operational_status: maintenanceSettingsSelectors.getOperationalStatusOptions(state),
    users: usersSelectors.getUsersSortedListOptions(state),

    sortBy: filteringSelectors.getSortBy(state),
    actionMode: filteringSelectors.getActionMode(state),
    selectedAssets: filteringSelectors.getSelectedAssets(state),
    selectedAssetClasses: filteringSelectors.getSelectedAssetClassesNegId(state),
    selectedLabels: filteringSelectors.getSelectedLabels(state),
    selectedStatus: filteringSelectors.getSelectedStatus(state),
    selectedTechStatus: filteringSelectors.getSelectedTechStatus(state),
    selectedOperations: filteringSelectors.getSelectedOperations(state),
});

let delayTimer;
const mapDispatchToProps = (dispatch, props) => {
    const setMaskSearch = (search_fields) => dispatch(filteringOperations.setMaskSearch(search_fields));

    const setMaskFilter = (filter_fields) => dispatch(filteringOperations.setMaskFilter(filter_fields));

    const applyFilter = (f) => {
        setMaskSearch(props.search_fields || _AssetFilter.defaultProps.search_fields);
        setMaskFilter(props.filter_fields || _AssetFilter.defaultProps.filter_fields);
        f();
        if (props.applyFilter) {
            props.applyFilter();
        }
    };
    const setAndTimeApply = (
        f,
        delay = 500,
    ) => {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => applyFilter(f), delay);
    };
    return {
        setSearchText: (text) => setAndTimeApply(() => dispatch(filteringOperations.setSearchText(text))),
        setSortBy: (text) => applyFilter(() => dispatch(filteringOperations.setSortBy(text))),
        setActionMode: (text) => applyFilter(() => dispatch(filteringOperations.setActionMode(text))),
        setSelectedAssets: (values) => applyFilter(() => dispatch(filteringOperations.setSelectedAssetsAndClasses(values))),
        setSelectedLabels: (values) => applyFilter(() => dispatch(filteringOperations.setSelectedLabels(values))),
        setSelectedStatus: (values) => applyFilter(() => dispatch(filteringOperations.setSelectedStatus(values))),
        setSelectedTechStatus: (values) => applyFilter(() => dispatch(filteringOperations.setSelectedTechStatus(values))),
        setSelectedOperations: (values) => applyFilter(() => dispatch(filteringOperations.setSelectedOperations(values))),
        clearAllFilters: () => applyFilter(() => dispatch(filteringOperations.clearAllFilters(dispatch))),
        refreshResult: () => applyFilter(() => dispatch(filteringOperations.refreshResult(dispatch))),
    };
};

_AssetFilter.defaultProps = {
    search_fields: ['renderAssetFilter', 'renderLabelFilter', 'renderStatusFilter', 'renderTechStatusFilter', 'renderOperationFilter'],
    filter_fields: ['renderSorting', 'renderActionChange'],
    own_style: true,
};

_AssetFilter.propTypes = {
    setSearchText: PropTypes.func.isRequired,
    setSortBy: PropTypes.func.isRequired,
    setActionMode: PropTypes.func.isRequired,
    setSelectedAssets: PropTypes.func.isRequired,
    setSelectedLabels: PropTypes.func.isRequired,
    setSelectedStatus: PropTypes.func.isRequired,
    setSelectedTechStatus: PropTypes.func.isRequired,
    is_fetching: PropTypes.bool,
    disabled: PropTypes.bool,
    own_style: PropTypes.bool,
};

const AssetFilter = connect(mapStateToProps, mapDispatchToProps)(_AssetFilter);

export default AssetFilter;
