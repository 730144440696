/**
 * Created by lpostula on 14/05/19.
 */
const FETCH_LIST_REQUEST = 'monitoring_rules/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'monitoring_rules/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'monitoring_rules/FETCH_LIST_FAILED';

export default {
    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,
};
