/**
 * Created by lpostula on 14/05/19.
 */
const FETCH_LIST_REQUEST = 'flat_alerts/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'flat_alerts/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'flat_alerts/FETCH_LIST_FAILED';

const FETCH_DETAIL_COMPLETED = 'flat_alerts/FETCH_DETAIL_COMPLETED';

export default {
    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,
    FETCH_DETAIL_COMPLETED,
};
