import React from 'react';
import './DetailTitle.scss';

const DetailTitle = (props) => (
    <div className="detail-title">
        {props.title}
    </div>
);

export default DetailTitle;
