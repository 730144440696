import React, { useState } from 'react';
import i18n from 'i18next';
import { useForm, FormProvider } from 'react-hook-form';
import './FormManager.scss';
import './FormLayout.scss';
import TextAction from '../../buttons/textAction/TextAction';
import Button from '../../buttons/button/Button';
import CsrfMiddlewareToken from '../../../utils/CsrfToken';

const FormManager = (props) => {
    if (props.legacy) {
        return (
            <form
                method={props.method || 'POST'}
                action={props.action || ''}
            >
                {props.children}
                <CsrfMiddlewareToken />
            </form>
        );
    }

    const methods = useForm();
    const [_displayCreate, setDisplayCreate] = useState(true);
    const [_preSubmits, setPreSubmits] = useState([]);

    const registerPreSubmit = (m) => {
        setPreSubmits((p) => {
            p.push(m);
            return p;
        });
    };

    const errorAppending = {};
    if (props.apiErrors) {
        for (const key of Object.keys(props.apiErrors)) {
            const error = props.apiErrors[key];
            errorAppending[key] = {
                type: 'api',
                message: error,
            };
        }
    }
    const updatedMethods = {
        ...methods,
        errors: { ...methods.errors, ...errorAppending },
        setDisplayCreate,
        registerPreSubmit,
    };

    const onSubmit = (arg) => {
        let new_arg = { ...arg };
        for (const preSubmit of _preSubmits) {
            new_arg = preSubmit(new_arg);
        }
        if (props.resetOnSubmit) {
            methods.reset(props.resetOnSubmit);
        }
        return props.onSubmit(new_arg);
    };

    return (
        <FormProvider {...updatedMethods}>
            <form
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                {props.children}
                {_displayCreate && props.textSubmitButton
                    && (
                        <div className="desktop-actions-buttons">
                            {
                                props.onCancel
                                && (
                                    <TextAction
                                        onClick={props.onCancel}
                                        text={i18n.t('Cancel')}
                                    />
                                )
                            }
                            <Button
                                submitButton
                                label={props.textSubmitButton}
                                disabled={props.disabled || !props.hasPerm}
                                primary={props.primary}
                            />
                        </div>
                    )}
            </form>
        </FormProvider>
    );
};

FormManager.defaultProps = {
    hasPerm: true,
};

export default FormManager;
