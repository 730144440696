const isIndex = (v) => {
    const type = typeof v;
    if (type === 'number') return true;
    return false;
};

const toKey = (value) => {
    if (typeof value === 'string') {
        return value;
    }
    return `${value}`;
};

function set(object, path, value) {
    const length = path.length;
    const lastIndex = length - 1;
    let index = -1;
    let nested = object;
    // eslint-disable-next-line no-plusplus
    while (nested != null && ++index < length) {
        const key = toKey(path[index]);
        let newValue = value;
        if (index !== lastIndex) {
            const objValue = nested[key];
            newValue = _.isObject(objValue)
                ? objValue
                : (isIndex(path[index + 1]) ? [] : {});
        }
        nested[key] = newValue;
        nested = nested[key];
    }
    return object;
}
const formatStrToDict = (base) => {
    const out = {};
    Object.keys(base).map((key) => {
        const splitted_key = key.split('__');
        set(out, splitted_key, base[key]);
        return out;
    });
    return out;
};

export default formatStrToDict;
