import React from 'react';

const ContextProvider = ({
    Component,
    ContextClass,
    contextValues,
    ...props
}) => (
    <ContextClass.Provider value={contextValues}>
        <Component {...props} />
    </ContextClass.Provider>
);

const ContextConsumer = ({
    Component,
    arg,
    ContextClass,
}) => (
    <ContextClass.Consumer>
        {() => <Component {...{ ...arg, ...{ contextClass: ContextClass } }} />}
    </ContextClass.Consumer>
);

const withContext = (ContextManager, provider) => (Component) => (props) => {
    if (provider) {
        return (
            <ContextManager.Renderer
                Provider={ContextProvider}
                Component={Component}
                ContextClass={ContextManager.ContextClass}
                {...props}
            />
        );
    }
    return (
        <ContextConsumer
            Component={Component}
            arg={props}
            ContextClass={ContextManager.ContextClass}
        />
    );
};

export default withContext;
