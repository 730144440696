export const split = (str, separator, limit) => {
    const splitted = str.split(separator);

    if (splitted.length > limit) {
        const ret = splitted.splice(0, limit);
        ret.push(splitted.join(separator));

        return ret;
    }

    return splitted;
};

export function getFaultMetadata(faults_cache, rnfcid) {
    const default_fc = {
        id: rnfcid,
        source: '',
        code: '',
        name: '',
        description: '',
        placeholder: true,
    };
    return faults_cache[rnfcid] || default_fc;
}

export function getRuleMetadata(rules_cache, rule) {
    const default_rule = {
        rules: rule,
        source: '',
        code: '',
        name: '',
        description: '',
        placeholder: true,
    };
    return rules_cache[rule] || default_rule;
}

/** Map fault data to a data serie suitable for a step graph */
export const mapFaultData = (data) => {
    const result = [];
    // const L = data.length - 1;
    data.forEach((currentValue, index, array) => {
        const previous = (index > 0) ? array[index - 1] : null;
        // const next = (index < L) ? array[index + 1] : null;
        if (currentValue[1]) {
            // fault opens
            if ((previous === null) || !previous[1]) {
                // first data point or previously closed
                result.push([currentValue[0], 0]);
            }
            result.push([currentValue[0], 1]);
        } else {
            // fault closes
            if ((previous === null) || previous[1]) {
                // first data point or previously opened
                result.push([currentValue[0], 1]);
            }
            result.push([currentValue[0], 0]);
        }
    });
    return result;
};

/** Map rule data to a data serie suitable for a step graph */
export const mapRuleData = (data) => {
    const result = [];
    // const L = data.length - 1;
    data.forEach((currentValue, index, array) => {
        const previous = (index > 0) ? array[index - 1] : null;
        // const next = (index < L) ? array[index + 1] : null;
        if (currentValue[1]) {
            // rule opens
            if ((previous === null) || !previous[1]) {
                // first data point or previously closed
                result.push([currentValue[0], 0]);
            }
            result.push([currentValue[0], 1]);
        } else {
            // rule closes
            if ((previous === null) || previous[1]) {
                // first data point or previously opened
                result.push([currentValue[0], 1]);
            }
            result.push([currentValue[0], 0]);
        }
    });
    return result;
};

export const getParamsFromHash = (hash) => {
    const params = {};
    hash.slice(hash.indexOf('?') + 1).split('&').forEach((param) => {
        const pair = param.split('=');
        if (pair.length === 2) {
            params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
        }
    });
    return params;
};

export const paramsToUrlHash = (params) => {
    const encoded = Object.keys(params).map(
        ((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`),
    );
    return `#/?${encoded.join('&')}`;
};
