let _numberFormat1000 = null;
let _numberFormat100 = null;
let _numberFormat10 = null;
let _numberFormat1 = null;

function formatNumber(value) {
    if (!_numberFormat1000) {
        const lng = window.user_info.language;
        _numberFormat1000 = new Intl.NumberFormat(lng, { maximumFractionDigits: 0 });
        _numberFormat100 = new Intl.NumberFormat(lng, { maximumFractionDigits: 1 });
        _numberFormat10 = new Intl.NumberFormat(lng, { maximumFractionDigits: 2 });
        _numberFormat1 = new Intl.NumberFormat(lng, { maximumFractionDigits: 3 });
    }
    const abs = Math.abs(value);
    if (abs > 1000) {
        return _numberFormat1000.format(value);
    }
    if (abs > 100) {
        return _numberFormat100.format(value);
    }
    if (abs > 10) {
        return _numberFormat10.format(value);
    }
    return _numberFormat1.format(value);
}

export default formatNumber;
