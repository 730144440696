/**
 * Created by lpostula on 11/05/17.
 */
import types from './types';
import m_e_types from '../maintenance_events/types';
import { createReducer, apiHelpers } from '../../utils';

/* State shape
{
    {intervention_id: intervention},
}
 */

const updateInterventionIEAforEvent = (state, action) => {
    const ieas = action.payload.interventioneventaction;
    if (!ieas.length) return state;
    if (typeof ieas[0] !== 'object') return state;
    const new_interventions = {};
    for (const iea of ieas) {
        if (!state.hasOwnProperty(iea.intervention)) continue;
        const intervention = state[iea.intervention];
        // we need to find the iea
        const iea_index = state[iea.intervention].interventioneventaction.findIndex(
            (cand) => typeof cand === 'object' && cand.id === iea.id,
        );
        const iea_array = intervention.interventioneventaction.slice(0);
        iea_array[iea_index] = iea;
        new_interventions[intervention.id] = {

            ...intervention,
            interventioneventaction: iea_array,
        };
    }
    return { ...state, ...new_interventions };
};

const initialState = {};

const listReducer = createReducer(initialState)({
    [types.FETCH_LIST_COMPLETED]: (state, action) => {
        const new_state = { ...state };

        const items = 'results' in action.payload ? action.payload.results : action.payload;
        if (!items) return new_state;
        for (const item of items) {
            new_state[item.id] = Object.assign(new_state[item.id] ? new_state[item.id] : {}, item);
        }
        return new_state;
    },
    [types.FETCH_DETAILS_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.UPDATE_DETAILS_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.CREATE_INTERVENTION_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.CANCEL_INTERVENTION_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.ADD_EVENTS_IN_INTERVENTION_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.REMOVE_EVENTS_FROM_INTERVENTION_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.REMOVE_INTERVENTION]: apiHelpers.delete_completed,
    [m_e_types.PROCESS_EVENT_COMPLETED]: updateInterventionIEAforEvent,
    [m_e_types.CLOSE_EVENT_COMPLETED]: updateInterventionIEAforEvent,
});

export default listReducer;
