/**
 * Created by lpostula on 11/05/17.
 */
import { userHasPerm } from '../users/selectors';
import { default as actions } from './actions';

export const fetchForAsset = (asset_id) => (dispatch, getState) => {
    // we need to check if the user has the permission
    const state = getState();
    if (!userHasPerm(state)('view_historic_status', asset_id)) {
        return Promise.resolve();
    }
    return dispatch(actions.fetchForAsset(asset_id));
};

export const { updateStatus } = actions;
export const { createStatus } = actions;
export const { updateOperationalStatusInStore } = actions;

export const incomingWS = (data) => (dispatch) => {
    switch (data.type) {
    case 'update':
        dispatch(actions.updateOperationalStatusInStore(data.payload));
        break;
    case 'delete':
        dispatch(actions.removeOperationalStatusInStore(data.payload));
        break;
    default:
        break;
    }
};

export default {
    fetchForAsset,
    updateStatus,
    createStatus,
    incomingWS,
    updateOperationalStatusInStore,
};
