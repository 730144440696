import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { countersSelectors } from 'railfleet_state/ducks/counters';
import TextDisplay from '../../../display/textDisplay/TextDisplay';
import formatNumber from '../../../../utils/formatNumber';
import CutForm from '../cutForm/CutForm';

const RenderCounterDoneUsage = (props) => {
    const renderElm = (reading, index) => {
        if (!reading) return null;
        const counter = props.counters[reading.counter];
        let label = '';
        if (counter) {
            const cname = counter.counter_type_name;
            const { serial } = counter;
            if (cname || serial) {
                label += '(';
            }

            label += cname;
            if (serial) {
                label += ' - ';
                label += serial;
            }

            label += ')';
        }
        return (
            <TextDisplay
                key={index}
                label={`${i18n.t('done usage')} ${label}`}
                data={`${formatNumber(reading.value)} ${counter?.unit}`}
            />
        );
    };

    const renderFields = () => {
        const ret = [];
        if (props.source_set.length > 0) {
            for (const index of Object.keys(props.source_set)) {
                const elm = props.source_set[index];
                ret.push(renderElm(elm, index));
            }
        } else {
            ret.push(renderElm(props.reading, 0));
        }
        return (
            <CutForm
                device={props.device}
            >
                { ret }
            </CutForm>
        );
    };

    const fields = renderFields();
    if (!fields || fields.length === 0) return null;
    return fields;
};

const mapStateToProps = (state, props) => {
    const { event_id } = props;
    const { asset_id } = props;
    const maintenance_event = event_id ? state.maintenance_events[event_id] : null;
    const reading = maintenance_event && maintenance_event.counter_reading;
    const source_set = maintenance_event && maintenance_event.counterreading_source_set;
    const counters = asset_id && countersSelectors.getCountersForAsset(state, asset_id);
    return {
        maintenance_event,
        reading,
        source_set,
        counters,
    };
};

export default connect(mapStateToProps, null)(RenderCounterDoneUsage);
