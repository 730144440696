import React from 'react';
import './Tooltip.scss';
import TextIcon from './TextIcon.svg';
import TextIconClosed from './TextIconClosed.svg';
import HintIcon from '../../display/hintIcon/HintIcon';

const Tooltip = (props) => (
    <div
        className={`tooltip-holder ${props.className}`}
    >
        <div className="tooltip-frame">
            <HintIcon
                placement={props.placement}
                opened={TextIcon}
                closed={TextIconClosed}
                visible={props.visible}
                setVisible={props.setVisible}
                noIcon={props.noIcon}
                noEvent={props.noEvent}
            >
                <div className="tooltip-content">
                    {
                        !props.noTitle
                        && (
                            <div className="tooltip-title">
                                <div className="tooltip-icon">
                                    {
                                        props.icon
                                        && (
                                            <img src={props.icon} alt="icon" />
                                        )
                                    }
                                </div>
                                {props.title}
                            </div>
                        )
                    }
                    <div className="tooltip-text">
                        {props.text || props.children}
                    </div>
                </div>
            </HintIcon>
        </div>
    </div>
);

export default Tooltip;
