/**
 * Created by lpostula on 11/05/17.
 */
import {
    createSelector,
} from 'reselect';
import {
    getCompanies,
} from '../companies/selectors';
import {
    getDelegations,
} from '../delegations/selectors';

export const getUsers = (state) => state.users.all;
export const getUser = (state) => state.users.me;

// this is a duplicate from the asset selectors, it is duplicated as it doesn't
// use any  reselect capability. And it will remove the dependency
// from the ducks `users` to the ducks `assets`
const getAssetInfos = (state, asset_id) => state.assets.assets_infos[asset_id];

export const userHasPerm = (state) => (permission, asset_id) => {
    const user = state.users.me;
    if (!user) return false;
    // legacy system (django perm)
    if (permission.indexOf('.') > -1) {
        console.log('Warning: legacy permission used:', permission);
        return false;
    }

    // test this is a proper permission
    if (!user.hasOwnProperty(permission)) {
        console.log('Warning: unknown permission:', permission);
        return false;
    }

    // check if the user has the permission
    if (!user[permission]) {
        return false;
    }

    // check if the user can bypass delegation
    if (user.email.endsWith('railnova.eu')) {
        // TODO change this with a railnova permission
        return true;
    }

    // check if the asset has the permission
    if (asset_id) {
        const asset = getAssetInfos(state, asset_id);
        // the asset is not in the store, so the user does not have the perm
        if (!asset) return false;
        if (!asset.has_access_now) return false;

        // console.log('TODO: the check admin_id === user.company, needs update');
        if (asset.admin_id === user.company) return true;

        const delegation_id = asset.delegation;
        const delegations = getDelegations(state);
        const delegation = delegations[delegation_id];
        if (!delegation) return false;
        if (delegation.hasOwnProperty(permission)) {
            return delegation[permission];
        }
        // console.log('Permission does not exists on the delegation:', permission);
        // delegation.permissions.includes(permission);
    }

    return true;
};

const sort_func = (a, b) => {
    const sa = a.email.toLowerCase();
    const sb = b.email.toLowerCase();
    return -(sa < sb) || +(sa !== sb);
};

export const getUsersSortedList = createSelector(
    [getUsers],
    (users) => _.values(users).sort(sort_func),
);

const getUserById = (state, user_id) => _.find(state.users.all, (user) => user.id === user_id);

export const getUsersSortedListOptions = createSelector(
    [getUsersSortedList],
    (users) => _.map(users, (u) => ({ value: u.id, label: u.full_name })),
);

export const getCurrentUserCompanyId = createSelector(
    [getUser],
    (user) => ((user !== null && user !== undefined) ? user.company : null),
);

export const getCurrentUserCompany = createSelector(
    [getCurrentUserCompanyId, getCompanies],
    (company_id, companies) => (company_id ? companies[company_id] : null),
);

export const getCurrentUserPage = createSelector(
    [getUser],
    (user) => ((user !== null && user !== undefined) ? user.pages : null),
);

export default {
    getUser,
    userHasPerm,
    // getPermission,
    getUsers,
    getUsersSortedList,
    getUsersSortedListOptions,
    getCurrentUserCompanyId,
    getCurrentUserCompany,
    getCurrentUserPage,
    getUserById,
};
