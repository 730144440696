/**
 * Created by lpostula on 11/05/17.
 */
import { default as actions } from './actions';

export const { fetchForEvent } = actions;
export const { fetchForIntervention } = actions;
export const { fetchList } = actions;
export const { fetchListFiltered } = actions;
export const { fetchDetails } = actions;
export const { fetchLocationByAssetByDate } = actions;
export const { searchByName } = actions;
export const { searchByNameSmartLocation } = actions;

export default {
    fetchForEvent,
    fetchForIntervention,
    fetchDetails,
    fetchList,
    fetchLocationByAssetByDate,
    searchByName,
    searchByNameSmartLocation,
    fetchListFiltered,
};
