import React from 'react';

const RenderNoData = ({ label }) => (
    <div className="element-holder">
        <div className="element-label disabled">{label}</div>
        <div className="element">
            <span>---</span>
        </div>
    </div>
);

export default RenderNoData;
