/**
 * Created by gknus on 02/09/19.
 */
const FETCH_LIST_REQUEST = 'intervention_types/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'intervention_types/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'intervention_types/FETCH_LIST_FAILED';

export default {
    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,
};
