/**
 * Created by lpostula on 12/10/17.
 */
import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { locationsOperations, locationsSelectors } from 'railfleet_state/ducks/locations/';
import moment from 'moment-timezone';
import Selector, { getStyle } from '../selector/Selector';

export const LocationSelectorClass = class LocationSelectorClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            manual: false,
            poi_type: props.poi_type || '',
            options_selected: props.options && props.options.selected,
        };
        this.handleInput = this.handleInput.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    handleInput(inputValue) {
        // we want to also relaunch a search with an empty list for intervention filter
        // as it act more as a selector with a short list
        const should_search = inputValue.length > 0 || this.props.autoSearch;
        this.setState({
            manual: should_search,
        });
        if (should_search) {
            return this.doSearch(inputValue);
        }
        return null;
    }

    doSearch(search_text) {
        if (this.props.asset_class) {
            if (this.props.assign_to) {
                return this.props.searchByNameSmartLocation && this.props.searchByNameSmartLocation(
                    search_text,
                    this.props.asset_class,
                    this.props.assign_to,
                ).then(() => this.props.search_results);
            }
        }
        return this.props.searchByName && this.props.searchByName(
            search_text,
            this.state.poi_type,
            this.props.with_intervention,
        ).then(() => this.props.search_results);
    }

    loadLocationFromAsset() {
        if (this.state.options_selected || this.state.manual || !this.props.autofill) {
            return;
        }
        let { date } = this.props;
        if (date && typeof date === 'string') {
            date = moment(date);
        }
        if (!date || !date.isValid()) {
            return;
        }
        this.props.fetchLocationByAssetByDate(
            this.props.asset,
            date.toISOString(),
            this.props.onChange,
        );
    }

    loadLocation(location_id) {
        let locations = location_id;
        if (!this.props.multi) {
            locations = [locations];
        }
        for (const l of locations) {
            if (typeof l === 'number' && !this.props.locations[l]) {
                this.props.fetchLocation(l);
            }
        }
    }

    componentDidMount() {
        // load from id if not loaded
        this.loadLocation(this.props.selected_location);

        // load from asset and date
        if (this.props.autofill) {
            this.loadLocationFromAsset();
        }

        // preload search list
        // if (this.props.autoSearch && this.props.asset_class && this.props.assign_to) {
        if (this.props.autoSearch) {
            this.doSearch();
        }
    }

    componentDidUpdate(prevProps) {
        // check if we need to load a location
        // if has changed
        if (prevProps.selected_location !== this.props.selected_location) {
            this.loadLocation(this.props.selected_location);
        }

        // load from asset and date
        if (this.props.autofill && this.props.asset) {
            // if has changed
            if (prevProps.asset !== this.props.asset || prevProps.date !== this.props.date) {
                this.loadLocationFromAsset();
            }
        }

        // preload search list
        if (this.props.autoSearch && this.props.asset_class && this.props.assign_to) {
            // if has changed
            if (prevProps.asset_class !== this.props.asset_class
                || prevProps.assign_to !== this.props.assign_to) {
                this.doSearch();
            }
        }
    }

    onChange(select) {
        if (this.props.multi) {
            this.props.onChange(_.map([].concat(select), (s) => s && s.value));
        } else {
            this.props.onChange(select && select.value);
        }
        if (this.props.onChangeCallback) {
            this.props.onChangeCallback();
        }
    }

    render() {
        const componentStyles = getStyle(this.props);
        let value = this.props.selected_location;
        if (typeof value === 'number') {
            const loc = this.props.locations[this.props.selected_location];
            if (loc) {
                value = {
                    value,
                    label: loc.name,
                };
            }
        } else if (this.props.multi) {
            value = value.map((v) => {
                let p = v;
                if (typeof p === 'number') {
                    p = this.props.locations[p];
                }
                return {
                    value: p.id,
                    label: p.name,
                };
            });
        } else if (value) {
            value = {
                value: this.props.id_getter(value),
                label: this.props.labelizer(value),
            };
        }
        return (
            <Selector
                id_getter={this.props.id_getter}
                labelizer={this.props.labelizer}
                name={this.props.id || 'location_selection'}
                isLoading={this.props.loading}
                search={this.handleInput}
                multi={this.props.multi}
                selected={value}
                label={`${i18n.t('Select')} ${this.props.item_name}`}
                isClearable={this.props.clearable}
                onChange={this.onChange}
                styles={componentStyles}
                filterOption={(elm) => elm}
                defaultOptions
            />
        );
    }
};

const LocationSelector = (props) => {
    let { id_getter } = props;
    let { labelizer } = props;

    let { loading } = props;
    let { search_results } = props;
    let { locations } = props;
    let { selected_location } = props;

    let { fetchLocation } = props;
    let { fetchLocationByAssetByDate } = props;
    let { searchByName } = props;
    let { searchByNameSmartLocation } = props;

    if (props.options) {
        id_getter = props.options.id_getter
            ? props.options.id_getter : id_getter;
        labelizer = props.options.labelizer
            ? props.options.labelizer : labelizer;

        loading = props.options.loading
            ? props.options.loading : loading;
        search_results = props.options.search_results
            ? props.options.search_results : search_results;
        locations = props.options.locations
            ? props.options.locations : locations;
        selected_location = props.options.selected_location
            ? props.options.selected_location : selected_location;

        fetchLocation = props.options.fetchLocation
            ? props.options.fetchLocation : fetchLocation;
        fetchLocationByAssetByDate = props.options.fetchLocationByAssetByDate
            ? props.options.fetchLocationByAssetByDate : fetchLocationByAssetByDate;
        searchByName = props.options.searchByName
            ? props.options.searchByName : searchByName;
        searchByNameSmartLocation = props.options.searchByNameSmartLocation
            ? props.options.searchByNameSmartLocation : searchByNameSmartLocation;
    }

    return (
        <LocationSelectorClass
            // DefaultProps
            asset={props.asset}
            date={props.date}
            label={props.label}
            multi={props.multi}
            item_name={props.item_name}
            autofill={props.autofill}
            autoSearch={props.autoSearch}
            with_intervention={props.with_intervention}
            asset_class={props.asset_class}
            assign_to={props.assign_to}
            style={props.style}
            clearable={props.clearable}
            onChange={props.onChange}
            labelizer={labelizer}
            id_getter={id_getter}
            selected_location={selected_location}
            // Redux Selectors
            locations={locations}
            search_results={search_results}
            loading={loading}
            // Redux Operations
            fetchLocation={fetchLocation}
            fetchLocationByAssetByDate={fetchLocationByAssetByDate}
            searchByName={searchByName}
            searchByNameSmartLocation={searchByNameSmartLocation}
            options={props.options}
        />
    );
};

const mapStateToProps = (state) => ({
    locations: locationsSelectors.getLocations(state),
    search_results: locationsSelectors.getSearchResultsOptions(state),
    loading: state.locations.search_query > 0,
});

const mapDispatchToProps = (dispatch) => ({
    fetchLocation: (id) => dispatch(
        locationsOperations.fetchDetails(id),
    ),
    fetchLocationByAssetByDate: (asset_id, date, callback) => dispatch(
        locationsOperations.fetchLocationByAssetByDate(asset_id, date, callback),
    ),
    searchByName: (name, poi_type, with_intervention) => dispatch(
        locationsOperations.searchByName(name, poi_type, with_intervention),
    ),
    searchByNameSmartLocation: (name, asset_class, assign_to) => dispatch(
        locationsOperations.searchByNameSmartLocation(name, asset_class, assign_to),
    ),
});

LocationSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
};

LocationSelector.defaultProps = {
    asset: null,
    date: null,
    label: i18n.t('Location'),
    multi: false,
    item_name: 'item',
    labelizer: (elm) => elm.label,
    id_getter: (elm) => elm.id,
    autofill: false, // auto select the first value of the search list on init
    autoSearch: false, // init search list when search text is empty
    with_intervention: null, // restrict search list to location associated with an intervention
    asset_class: null,
    assign_to: null,
    style: {},
    clearable: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelector);
