/**
 * Created by lpostula on 11/05/17.
 */
import { userHasPerm } from '../users/selectors';
import { default as actions } from './actions';

const is_permitted = (perm, action, payload, dispatch, state) => {
    const has_perm = userHasPerm(state)(perm, payload.asset);
    if (!has_perm) {
        throw new Error('You dont have the permission');
    }
    return dispatch(action(payload));
};

export const { fetchForAsset } = actions;
export const { fetchList } = actions;
export const { updateRestriction } = actions;

const closeRestriction = (restriction) => (dispatch, getState) => (
    is_permitted(
        'close_restriction',
        actions.closeRestriction,
        restriction,
        dispatch,
        getState(),
    )
);

export const createRestriction = (data) => (dispatch, getState) => (
    is_permitted(
        'create_restriction',
        actions.createRestriction,
        data,
        dispatch,
        getState(),
    )
);

export const incomingWS = (data) => (dispatch) => {
    switch (data.type) {
    case 'update':
        dispatch(actions.updateRestrictionInStore(data.payload));
        break;
    default:
        break;
    }
};

export default {
    fetchForAsset,
    fetchList,
    updateRestriction,
    createRestriction,
    closeRestriction,
    incomingWS,
};
