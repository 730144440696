import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Portal from '../../app/portal';
import './AsyncConfirmationDialog.scss';
import ExclamationLogo from './exclamation.svg';
import LogoOk from './logo_ok.svg';
import Loader from '../../animations/loader/Loader';

const ConfirmationDialogComp = (props) => {
    const [_pending, setPending] = useState(false);
    const [_done, setDone] = useState(false);
    const dialogRef = useRef(null);
    const handleClickOutside = (event) => {
        if (dialogRef.current
            && dialogRef.current.contains(event.target)
        ) {
            return;
        }
        props.onClickReject();
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleTimeout = () => {
        props.onClickResolve();
    };

    useEffect(() => {
        if (_done) setTimeout(handleTimeout, 5000);
    }, [_done]);

    if (_pending) {
        return (
            <Portal>
                <div className="confirmation-message-holder" ref={dialogRef}>
                    <div className={`confirmation-message-frame ${props.variant}`}>
                        <div className={`confirmation-message-title ${props.variant}`} />
                        <div className="confirmation-message-description">
                            { props.in_progress_message}
                        </div>
                        <Loader />
                    </div>
                </div>
            </Portal>
        );
    }

    if (_done) {
        return (
            <Portal>
                <div className="confirmation-message-holder" ref={dialogRef}>
                    <div className={`confirmation-message-frame ${props.variant}`}>
                        <div className="confirmation-message-logo">
                            <img src={LogoOk} alt="ok logo" />
                        </div>
                        <div className={`confirmation-message-title ${props.variant}`}>
                            {props.done_message}
                        </div>
                        <div className="confirmation-message-description" />
                    </div>
                </div>
            </Portal>
        );
    }

    return (
        <Portal>
            <div className="confirmation-message-holder" ref={dialogRef}>
                <div className={`confirmation-message-frame ${props.variant}`}>
                    <div className="confirmation-message-logo">
                        {
                            props.variant === 'danger' ? (
                                <img src={ExclamationLogo} alt="exclamation logo" />
                            ) : (
                                <img src={LogoOk} alt="ok logo" />
                            )
                        }
                    </div>
                    <div className={`confirmation-message-title ${props.variant}`}>
                        {props.confirmation_message}
                    </div>
                    <div className="confirmation-message-description">
                        {props.description_message}
                    </div>
                    <div className="confirmation-message-action">
                        {
                            _pending ? (
                                <Loader />
                            ) : (
                                props.variant === 'danger' ? (
                                    <>
                                        <button
                                            className="btn btn-default"
                                            onClick={props.onClickReject}
                                        >
                                            {(props.buttons_text && props.buttons_text[0]) || 'Cancel'}
                                        </button>
                                        <button
                                            className="btn btn-default"
                                            id="blue-fill"
                                            onClick={() => {
                                                setPending(true);
                                                document.removeEventListener('mousedown', handleClickOutside);
                                                return props.actionOnYes().then(() => {
                                                    document.addEventListener('mousedown', handleClickOutside);
                                                    setDone(true);
                                                    setPending(false);
                                                });
                                            }}
                                        >
                                            {(props.buttons_text && props.buttons_text[1]) || 'OK'}
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button
                                            className="btn btn-default"
                                            onClick={() => {
                                                setPending(true);
                                                document.removeEventListener('mousedown', handleClickOutside);
                                                return props.actionOnYes().then(() => {
                                                    document.addEventListener('mousedown', handleClickOutside);
                                                    setDone(true);
                                                    setPending(false);
                                                });
                                            }}
                                        >
                                            {(props.buttons_text && props.buttons_text[0]) || 'Ok'}
                                        </button>
                                    </>
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </Portal>
    );
};

const AsyncConfirmationDialog = (
    {
        variant, confirmation_message, description_message,
        buttons_text, actionOnYes, done_message, in_progress_message,
        timed,
    },
) => new Promise((resolve, reject) => {
    const modal = document.createElement('div');
    document.body.appendChild(modal);
    ReactDOM.render(<ConfirmationDialogComp
        variant={variant}
        confirmation_message={confirmation_message}
        description_message={description_message}
        done_message={done_message}
        in_progress_message={in_progress_message}
        buttons_text={buttons_text}
        actionOnYes={actionOnYes}
        onClickResolve={() => {
            ReactDOM.render(null, modal);
            resolve();
        }}
        onClickReject={() => {
            ReactDOM.render(null, modal);
            if (variant === 'danger') {
                return reject();
            }
            return resolve();
        }}
        timed={timed}
    />, modal);
});

export default AsyncConfirmationDialog;
