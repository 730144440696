/**
 * Created by lpostula on 11/05/17.
 */
const FETCH_DETAIL_INFOS_REQUEST = 'assets/FETCH_DETAIL_INFOS_REQUEST';
const FETCH_DETAIL_INFOS_COMPLETED = 'assets/FETCH_DETAIL_INFOS_COMPLETED';
const FETCH_DETAIL_INFOS_FAILED = 'assets/FETCH_DETAIL_INFOS_FAILED';

const FETCH_LIST_INFOS_REQUEST = 'assets/FETCH_LIST_INFOS_REQUEST';
const FETCH_LIST_INFOS_COMPLETED = 'assets/FETCH_LIST_INFOS_COMPLETED';
const FETCH_LIST_INFOS_FAILED = 'assets/FETCH_LIST_INFOS_FAILED';

// new live data related to asset shadow
const FETCH_DETAIL_LIVE_DATA_REQUEST = 'assets/FETCH_DETAIL_LIVE_DATA_REQUEST';
const FETCH_DETAIL_LIVE_DATA_COMPLETED = 'assets/FETCH_DETAIL_LIVE_DATA_COMPLETED';
const FETCH_DETAIL_LIVE_DATA_FAILED = 'assets/FETCH_DETAIL_LIVE_DATA_FAILED';

const FETCH_LIST_LIVE_DATA_REQUEST = 'assets/FETCH_LIST_LIVE_DATA_REQUEST';
const FETCH_LIST_LIVE_DATA_COMPLETED = 'assets/FETCH_LIST_LIVE_DATA_COMPLETED';
const FETCH_LIST_LIVE_DATA_FAILED = 'assets/FETCH_LIST_LIVE_DATA_FAILED';
const LIVE_DATA_PARTIAL_UPDATE = 'assets/LIVE_DATA_PARTIAL_UPDATE';

const FETCH_LIST_ALERTS_REQUEST = 'assets/FETCH_LIST_ALERTS_REQUEST';
const FETCH_LIST_ALERTS_COMPLETED = 'assets/FETCH_LIST_ALERTS_COMPLETED';
const FETCH_LIST_ALERTS_FAILED = 'assets/FETCH_LIST_ALERTS_FAILED';

const FETCH_DETAIL_OPERATIONALS_REQUEST = 'assets/FETCH_DETAIL_OPERATIONALS_REQUEST';
const FETCH_DETAIL_OPERATIONALS_COMPLETED = 'assets/FETCH_DETAIL_OPERATIONALS_COMPLETED';
const FETCH_DETAIL_OPERATIONALS_FAILED = 'assets/FETCH_DETAIL_OPERATIONALS_FAILED';

const FETCH_LIST_OPERATIONALS_REQUEST = 'assets/FETCH_LIST_OPERATIONALS_REQUEST';
const FETCH_LIST_OPERATIONALS_COMPLETED = 'assets/FETCH_LIST_OPERATIONALS_COMPLETED';
const FETCH_LIST_OPERATIONALS_FAILED = 'assets/FETCH_LIST_OPERATIONALS_FAILED';

const FETCH_DETAIL_LABELS_REQUEST = 'assets/FETCH_DETAIL_LABELS_REQUEST';
const FETCH_DETAIL_LABELS_COMPLETED = 'assets/FETCH_DETAIL_LABELS_COMPLETED';
const FETCH_DETAIL_LABELS_FAILED = 'assets/FETCH_DETAIL_LABELS_FAILED';

const FETCH_LIST_LABELS_REQUEST = 'assets/FETCH_LIST_LABELS_REQUEST';
const FETCH_LIST_LABELS_COMPLETED = 'assets/FETCH_LIST_LABELS_COMPLETED';
const FETCH_LIST_LABELS_FAILED = 'assets/FETCH_LIST_LABELS_FAILED';

const FETCH_DETAIL_ALL_REQUEST = 'assets/FETCH_DETAIL_ALL_REQUEST';
const FETCH_DETAIL_ALL_COMPLETED = 'assets/FETCH_DETAIL_ALL_COMPLETED';
const FETCH_DETAIL_ALL_FAILED = 'assets/FETCH_DETAIL_ALL_FAILED';

const FETCH_LIST_ALL_REQUEST = 'assets/FETCH_LIST_ALL_REQUEST';
const FETCH_LIST_ALL_COMPLETED = 'assets/FETCH_LIST_ALL_COMPLETED';
const FETCH_LIST_ALL_FAILED = 'assets/FETCH_LIST_ALL_FAILED';

const SET_LABELS_REQUEST = 'assets/SET_LABELS_REQUEST';
const SET_LABELS_COMPLETED = 'assets/SET_LABELS_COMPLETED';
const SET_LABELS_FAILED = 'assets/SET_LABELS_FAILED';

const FETCH_ASSIGN_TO_REQUEST = 'assets/FETCH_ASSIGN_TO_REQUEST';
const FETCH_ASSIGN_TO_COMPLETED = 'assets/FETCH_ASSIGN_TO_COMPLETED';
const FETCH_ASSIGN_TO_FAILED = 'assets/FETCH_ASSIGN_TO_FAILED';

const OPERATIONAL_PARTIAL_UPDATE = 'assets/OPERATIONAL_PARTIAL_UPDATE';

export default {
    FETCH_DETAIL_INFOS_REQUEST,
    FETCH_DETAIL_INFOS_COMPLETED,
    FETCH_DETAIL_INFOS_FAILED,

    FETCH_LIST_INFOS_REQUEST,
    FETCH_LIST_INFOS_COMPLETED,
    FETCH_LIST_INFOS_FAILED,

    FETCH_LIST_LIVE_DATA_REQUEST,
    FETCH_LIST_LIVE_DATA_COMPLETED,
    FETCH_LIST_LIVE_DATA_FAILED,
    LIVE_DATA_PARTIAL_UPDATE,

    FETCH_LIST_ALERTS_REQUEST,
    FETCH_LIST_ALERTS_COMPLETED,
    FETCH_LIST_ALERTS_FAILED,

    FETCH_DETAIL_LIVE_DATA_REQUEST,
    FETCH_DETAIL_LIVE_DATA_COMPLETED,
    FETCH_DETAIL_LIVE_DATA_FAILED,

    FETCH_DETAIL_OPERATIONALS_REQUEST,
    FETCH_DETAIL_OPERATIONALS_COMPLETED,
    FETCH_DETAIL_OPERATIONALS_FAILED,

    FETCH_LIST_OPERATIONALS_REQUEST,
    FETCH_LIST_OPERATIONALS_COMPLETED,
    FETCH_LIST_OPERATIONALS_FAILED,

    FETCH_DETAIL_LABELS_REQUEST,
    FETCH_DETAIL_LABELS_COMPLETED,
    FETCH_DETAIL_LABELS_FAILED,

    FETCH_LIST_LABELS_REQUEST,
    FETCH_LIST_LABELS_COMPLETED,
    FETCH_LIST_LABELS_FAILED,

    FETCH_DETAIL_ALL_REQUEST,
    FETCH_DETAIL_ALL_COMPLETED,
    FETCH_DETAIL_ALL_FAILED,

    FETCH_LIST_ALL_REQUEST,
    FETCH_LIST_ALL_COMPLETED,
    FETCH_LIST_ALL_FAILED,

    SET_LABELS_REQUEST,
    SET_LABELS_COMPLETED,
    SET_LABELS_FAILED,

    FETCH_ASSIGN_TO_REQUEST,
    FETCH_ASSIGN_TO_COMPLETED,
    FETCH_ASSIGN_TO_FAILED,

    OPERATIONAL_PARTIAL_UPDATE,
};
