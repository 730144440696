/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import { LAYOUT } from '../../../app/MultiDeviceLayout';
import './CutForm.scss';

const CutForm = (props) => {
    const className = `adjusted-line ${props.className ? props.className : ''}`;

    if (props.hide) return null;
    let { size } = props;
    if (props.device && !props.size) {
        if (props.device === LAYOUT.TABLET) size = 2;
        else if (props.device === LAYOUT.MOBILE) size = 1;
        else size = 3;
    }
    let elms = [];
    const ret = [];
    let cpt = 1;
    let cptG = 0;
    React.Children.map(props.children, (elm) => {
        if (!elm) return null;
        if (elm.props && elm.props.large) {
            ret.push(<div
                className={className}
                key={cptG}
            >
                { elms }
            </div>);
            elms = [];
            cpt = 0;
            cptG += 1;
            ret.push(<div
                className={`${className} large`}
                key={cptG}
            >
                { [elm] }
            </div>);
        } else {
            elms.push(elm);
            if (cpt % size === 0) {
                ret.push(<div
                    className={className}
                    key={cptG}
                >
                    { elms }
                </div>);
                elms = [];
                cpt = 0;
            }
        }
        cpt += 1;
        cptG += 1;
        return null;
    });
    if (elms.length > 0) {
        ret.push(<div
            className={className}
            key={cptG}
        >
            { elms }
        </div>);
    }
    return ret;
};

export default CutForm;
