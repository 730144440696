/**
 * Created by lpostula on 12/10/17.
 */
import React from 'react';

const AutoRefreshWrapper = (refresh_url, refresh_interval, to_render) => {
    class AutoRefreshWrapped extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                data: {},
            };
        }

        componentDidMount() {
            this.trigger_refresh();
        }

        trigger_refresh() {
            // Refresh data or wait depending on cookie values.
            if (!(Cookies.get('no_refresh')) || (Cookies.get('no_refresh') === '0')) {
                this.refresh_data();
            } else {
                setTimeout(this.trigger_refresh.bind(this), refresh_interval);
            }
        }

        refresh_data() {
            // Refresh state from URL `@state.refresh_data_url`
            window.clearTimeout(this.refresh_timeout_object);
            $.getJSON(refresh_url, (data) => {
                this.setState({ data });
                this.refresh_timeout_object = setTimeout(
                    this.trigger_refresh.bind(this),
                    refresh_interval,
                );
            }).fail(() => {
                this.refresh_timeout_object = setTimeout(
                    this.trigger_refresh.bind(this),
                    refresh_interval,
                );
            });
        }

        render() {
            return React.createElement(
                to_render,
                {
                    data: this.state.data,
                    ...this.props,
                },
            );
        }
    }
    return AutoRefreshWrapped;
};

export default AutoRefreshWrapper;
