import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import DateSelector from '../dateSelector/DateSelector';
import Selector from '../selector/Selector';

import './TimeRangeSelector.scss';

const SECONDS = 1;
const MINUTES = 60 * SECONDS;
const HOURS = 60 * MINUTES;
const DAYS = 24 * HOURS;
const MONTHS = 30 * DAYS;
// const YEARS = 365 * DAYS;

const refresh_options = [
    { id: 0, label: 'off' },
    { id: 5 * SECONDS, label: '5s' },
    { id: 10 * SECONDS, label: '10s' },
    { id: 30 * SECONDS, label: '30s' },
    { id: MINUTES, label: '1m' },
    { id: 5 * MINUTES, label: '5m' },
    { id: 15 * MINUTES, label: '15m' },
    { id: 30 * MINUTES, label: '30m' },
    { id: HOURS, label: '1h' },
    { id: 2 * HOURS, label: '2h' },
    { id: DAYS, label: '1d' },
];

const getRefreshLabel = (refresh) => {
    for (const op of refresh_options) {
        if (op.id === refresh) return op.label;
    }
    return '';
};

const get_last = ({ interval }) => {
    const to = moment();
    const from = moment().subtract(interval, 'seconds');
    return { from, to, allow_refresh: true };
};

const get_previous = ({ interval, unit, so_far }) => {
    const date = moment().subtract(interval, unit);
    const from = moment(date).startOf(unit);
    const to = so_far ? moment() : moment(date).endOf(unit);
    return { from, to, allow_refresh: so_far };
};

const quick_ranges = [
    [
        {
            id: 'Last 2 days', label: 'Last 2 days', getter: get_last, params: { interval: 2 * DAYS },
        },
        {
            id: 'Last 7 days', label: 'Last 7 days', getter: get_last, params: { interval: 7 * DAYS },
        },
        {
            id: 'Last 30 days', label: 'Last 30 days', getter: get_last, params: { interval: MONTHS },
        },
    ], [
        {
            id: 'Yesterday', label: 'Yesterday', getter: get_previous, params: { interval: 1, unit: 'days' },
        },
        {
            id: 'Day before yesterday', label: 'Day before yesterday', getter: get_previous, params: { interval: 2, unit: 'days' },
        },
        {
            id: 'This day last week', label: 'This day last week', getter: get_previous, params: { interval: 7, unit: 'days' },
        },
        {
            id: 'Previous week', label: 'Previous week', getter: get_previous, params: { interval: 1, unit: 'weeks' },
        },
        {
            id: 'Previous month', label: 'Previous month', getter: get_previous, params: { interval: 1, unit: 'months' },
        },
    ], [
        {
            id: 'Today', label: 'Today', getter: get_previous, params: { interval: 0, unit: 'days' },
        },
        {
            id: 'Today so far', label: 'Today so far', getter: get_previous, params: { interval: 0, unit: 'days', so_far: true },
        },
        {
            id: 'This week', label: 'This week', getter: get_previous, params: { interval: 0, unit: 'weeks' },
        },
        {
            id: 'This week so far', label: 'This week so far', getter: get_previous, params: { interval: 0, unit: 'weeks', so_far: true },
        },
        {
            id: 'This month', label: 'This month', getter: get_previous, params: { interval: 0, unit: 'months' },
        },
        {
            id: 'This month so far', label: 'This month so far', getter: get_previous, params: { interval: 0, unit: 'months', so_far: true },
        },
    ], [
        {
            id: 'Last 5 minutes', label: 'Last 5 minutes', getter: get_last, params: { interval: 5 * MINUTES },
        },
        {
            id: 'Last 15 minutes', label: 'Last 15 minutes', getter: get_last, params: { interval: 15 * MINUTES },
        },
        {
            id: 'Last 30 minutes', label: 'Last 30 minutes', getter: get_last, params: { interval: 30 * MINUTES },
        },
        {
            id: 'Last 1 hour', label: 'Last 1 hour', getter: get_last, params: { interval: HOURS },
        },
        {
            id: 'Last 3 hours', label: 'Last 3 hours', getter: get_last, params: { interval: 3 * HOURS },
        },
        {
            id: 'Last 6 hours', label: 'Last 6 hours', getter: get_last, params: { interval: 6 * HOURS },
        },
        {
            id: 'Last 12 hours', label: 'Last 12 hours', getter: get_last, params: { interval: 12 * HOURS },
        },
        {
            id: 'Last 24 hours', label: 'Last 24 hours', getter: get_last, params: { interval: 24 * HOURS },
        },
    ],
];

class TimeRangeSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display_form: false,
            refresh: null,
            temp_refresh: null,
            quick_range: null,
        };
        this.toggleForm = this.toggleForm.bind(this);
        this.setDates = this.setDates.bind(this);
        this.setFromDate = this.setFromDate.bind(this);
        this.setToDate = this.setToDate.bind(this);
        this.setRefresh = this.setRefresh.bind(this);
        this.applyRefresh = this.applyRefresh.bind(this);
        this.triggerRefresh = this.triggerRefresh.bind(this);
        this.setQuickRange = this.setQuickRange.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.refresh_timeout = null;

        this.form_container = React.createRef();
        this.button_container = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(evt) {
        if (!this.state.display_form || !this.form_container.current) return;
        let outside = true;
        let i = 0;
        const l = evt.path.length - 1;
        while (outside && i < l) {
            const e = evt.path[i];
            i += 1;
            if (
                this.form_container.current.contains(e)
                || this.button_container.current.contains(e)
            ) {
                outside = false;
            }
        }
        if (outside) {
            this.setState({ display_form: false });
        }
    }

    toggleForm() {
        this.setState({ display_form: !this.state.display_form });
    }

    setDates(from, to) {
        this.props.onChange({ from, to });
    }

    setFromDate(date) {
        this.setDates(date, this.props.to);
        this.setState({ quick_range: null });
    }

    setToDate(date) {
        this.setDates(this.props.from, date);
        this.setState({ quick_range: null });
    }

    setRefresh([temp_refresh]) {
        this.setState({ temp_refresh });
    }

    triggerRefresh() {
        clearTimeout(this.refresh_timeout);
        const { refresh } = this.state;
        if (!refresh) return;
        let { from, to } = this.props;
        from = moment(from).add(refresh, 'seconds');
        to = moment(to).add(refresh, 'seconds');
        this.setDates(from, to);
        this.refresh_timeout = setTimeout(this.triggerRefresh, refresh * 1000);
    }

    applyRefresh() {
        const { temp_refresh } = this.state;
        if (temp_refresh) {
            this.setState(
                { refresh: temp_refresh, temp_refresh: null },
                () => this.triggerRefresh(),
            );
        }
    }

    setQuickRange(evt, range) {
        evt.preventDefault();
        this.setState({ quick_range: range.id });
        const { from, to, allow_refresh } = range.getter(range.params);
        this.setDates(from, to);
        if (!allow_refresh) {
            clearTimeout(this.refresh_timeout);
        } else {
            this.applyRefresh();
        }
    }

    getTimeRangeLabel() {
        const { quick_range } = this.state;
        if (quick_range) {
            return <span>{quick_range}</span>;
        }
        const { from, to } = this.props;
        return [
            <span key="from-out">{from.format('DD/MM/YYYY HH:mm:SS')}</span>,
            <span key="range-glyph" className="glyphicons glyphicons-arrow-right" />,
            <span key="to-out">{to.format('DD/MM/YYYY HH:mm:SS')}</span>,
        ];
    }

    renderQuickRange() {
        const { quick_range } = this.state;
        return (
            quick_ranges.map((group, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ul className="quick_range_group" key={index}>
                    {
                        group.map((range_option) => (
                            <li key={range_option.id}>
                                <a
                                    href="#"
                                    onClick={(e) => (
                                        this.setQuickRange(e, range_option)
                                    )}
                                    className={quick_range === range_option.id ? 'active' : ''}
                                >
                                    {range_option.label}
                                </a>
                            </li>
                        ))
                    }
                </ul>
            ))
        );
    }

    render() {
        const { from, to } = this.props;
        const { display_form, refresh, temp_refresh } = this.state;
        // console.log(refresh, temp_refresh);
        return (
            <>
                <div
                    className="timerange_display_holder"
                    onClick={this.toggleForm}
                    ref={this.button_container}
                >
                    <div className="value">
                        <button type="button" className="btn btn-default">
                            <span className="glyphicons glyphicons-clock" />
                            {this.getTimeRangeLabel()}
                            {
                                refresh ? (
                                    <div className="refresh">
                                        Refresh every
                                        {' '}
                                        {getRefreshLabel(refresh)}
                                    </div>
                                ) : null
                            }
                        </button>
                    </div>
                </div>
                {
                    display_form ? (
                        <div className="timerange_form_holder" ref={this.form_container}>
                            <div className="custom_range">
                                <h4>Custom Range</h4>
                                <div>
                                    <DateSelector
                                        id="From"
                                        label="From"
                                        onChange={this.setFromDate}
                                        value={from}
                                    />
                                    <DateSelector
                                        id="To"
                                        label="To"
                                        onChange={this.setToDate}
                                        value={to}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Refreshing every: </label>
                                    <div className="refresh-selector">
                                        <Selector
                                            onChange={this.setRefresh}
                                            selected={temp_refresh || refresh}
                                            values={refresh_options}
                                            sort={false}
                                        />
                                        <button
                                            className="btn btn-primary apply-refresh"
                                            type="button"
                                            onClick={this.applyRefresh}
                                        >
                                            Apply
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="quick_range">
                                <h4>Quick Ranges</h4>
                                <div className="range_options">
                                    {this.renderQuickRange()}
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </>
        );
    }
}

TimeRangeSelector.propTypes = {
    from: PropTypes.instanceOf(moment),
    to: PropTypes.instanceOf(moment),
    timezone: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

TimeRangeSelector.defaultProps = {
    from: moment().subtract(1, 'hours'),
    to: moment(),
    timezone: moment.tz.guess(true),
};

export default TimeRangeSelector;
