/**
 * Created by gknuts on 29/03/19.
 */
import {
    createSelector,
} from 'reselect';

const getAssetInfos = (state, asset_id) => state.assets.assets_infos[asset_id];

export const getFaultCategories = (state) => state.fault_categories;
export const getFaultCategory = (state, fc_id) => state.fault_categories[fc_id];

const getUserCompany = (state) => {
    // not null nor undefined
    if (state.users.me) {
        return state.users.me.company;
    }
    return null;
};
const getUserlanguage = (state) => {
    // not null nor undefined
    if (state.users.me) {
        return state.users.me.language;
    }
    return null;
};

export const getFaultCategoriesForCurrentUser = createSelector(
    [getFaultCategories, getUserCompany],
    (fault_categories, user_company) => _.pick(
        fault_categories, (category) => category.group.company === user_company,
    ),
);

export const getFaultCategoriesForCurrentAsset = createSelector(

    [getFaultCategories, getAssetInfos],
    (fault_categories, asset_info) => (asset_info ? _.pick(
        fault_categories, (category) => category.group.company === asset_info.ecm_id,
    ) : []),
);

export const getFaultCategoryGroupLabelForCurrentUser = createSelector(
    [getFaultCategories, getUserCompany, getUserlanguage],
    (fault_categories, user_company, user_language) => {
        const fc = _.pick(
            fault_categories, (category) => category.group.company === user_company,
        );
        try {
            const first_key = Object.keys(fc)[0];
            return fc[first_key].group.label[user_language];
        } catch (err) {
            return null;
        }
    },
);

export const getFaultCategoryGroupLabelForCurrentAsset = createSelector(
    [getFaultCategories, getAssetInfos, getUserlanguage],
    (fault_categories, asset_info, user_language) => {
        const fc = asset_info ? _.pick(
            fault_categories, (category) => category.group.company === asset_info.admin_id,
        ) : [];
        try {
            const first_key = Object.keys(fc)[0];
            return fc[first_key].group.label[user_language];
        } catch (err) {
            return null;
        }
    },
);

export default {
    getFaultCategories,
    getFaultCategory,
    getFaultCategoriesForCurrentUser,
    getFaultCategoriesForCurrentAsset,
    getFaultCategoryGroupLabelForCurrentUser,
    getFaultCategoryGroupLabelForCurrentAsset,
};
