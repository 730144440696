import { RSAA } from 'redux-api-middleware';
import types from './types';
import { API_ROOT } from '../../config';

const API_RESOURCE_NAME = 'hotline_call';

export const fetchDetails = (item_id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${item_id}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_DETAILS_REQUEST,
            types.FETCH_DETAILS_COMPLETED,
            types.FETCH_DETAILS_FAILED,
        ],
    },
});

export const fetchList = (long_polling = false) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            {
                type: types.FETCH_LIST_REQUEST,
                meta: { long_polling },
            },
            {
                type: types.FETCH_LIST_COMPLETED,
                meta: { long_polling },
            },
            types.FETCH_LIST_FAILED,
        ],
    },
});

export const updateHotline = (values) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${values.id}.json/`,
        method: 'PATCH',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(values),
        credentials: 'same-origin',
        types: [
            types.UPDATE_DETAILS_REQUEST,
            types.UPDATE_DETAILS_COMPLETED,
            types.UPDATE_DETAILS_FAILED,
        ],
    },
});

export const createHotline = (values) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(values),
        credentials: 'same-origin',
        types: [
            types.CREATE_HOTLINE_REQUEST,
            types.CREATE_HOTLINE_COMPLETED,
            types.CREATE_HOTLINE_FAILED,
        ],
    },
});

export const assignHotline = (values) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/assign.json`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(values),
        credentials: 'same-origin',
        types: [
            types.CREATE_HOTLINE_REQUEST,
            types.CREATE_HOTLINE_COMPLETED,
            types.CREATE_HOTLINE_FAILED,
        ],
    },
});

export const updateHotlineInStore = (payload, create = false) => ({
    type: types.FETCH_DETAILS_COMPLETED,
    payload,
    meta: { create },
});

export default {
    fetchDetails,
    fetchList,
    updateHotline,
    createHotline,
    assignHotline,
    updateHotlineInStore,
};
