import React from 'react';
import i18n from 'i18next';
import moment from 'moment-timezone';
import WithPopper from '../app/WithPopper';

const ALERT_PRIORITY_COLORS = {
    1: { name: 'Red', id: 1 },
    2: { name: 'Orange', id: 2 },
    3: { name: 'Purple', id: 3 },
    4: { name: 'Blue', id: 4 },
    5: { name: 'Green', id: 5 },
};

const AssetRenderer = (asset_id, assets) => {
    const asset = assets[asset_id];
    if (!asset) return null;
    return (
        <span>{`${asset.name} [${asset.class_name}]`}</span>
    );
};

const ShortAssetRenderer = (asset_id, assets) => {
    const asset = assets[asset_id];
    if (!asset) return null;
    return (
        <span>{asset.name}</span>
    );
};

const OverFlowRenderer = (cellData) => (
    <span
        style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        }}
        title={cellData}
    >
        {cellData}
    </span>
);

const RuleRenderer = (rule_id, rules) => {
    const rule = rules[rule_id];
    if (!rule) return null;
    return OverFlowRenderer(rule.name);
};

const AlertPriorityRenderer = (priority) => (
    <div
        style={{
            width: 10,
            height: 10,
            backgroundColor: ALERT_PRIORITY_COLORS[priority].name,
        }}
    />
);

const DateRenderer = (cellData) => {
    if (cellData) {
        // force interpretation as UTC format and localize it
        const { timezone } = window.user_info;
        const date = moment(cellData).tz(timezone);
        // force output as local time
        const output_date = cellData && date.isValid() ? date.format('DD/MM/YYYY HH:mm:ss') : cellData;
        return <span>{ output_date }</span>;
    }
    return cellData;
};

const TRUE_CLASS = 'glyphicon glyphicon-ok-circle text-success';
const FALSE_CLASS = 'glyphicon glyphicon-remove-circle text-danger';
const BooleanCellRenderer = (cellData) => {
    if (cellData !== null && cellData !== undefined) {
        return (
            <span className={cellData ? TRUE_CLASS : FALSE_CLASS} style={{ fontSize: '1.5em', lineHeight: 0.8 }} />
        );
    }
    return cellData;
};

const OnOffCellRenderer = (cellData) => {
    if (cellData !== null && cellData !== undefined) {
        return cellData ? 'on' : 'off';
    }
    return cellData;
};

const SlaveMasterCellRenderer = (cellData) => {
    if (cellData !== null && cellData !== undefined) {
        return cellData ? 'Slave' : 'Master';
    }
    return cellData;
};

const FloatCellRenderer = (cellData) => {
    if (typeof (cellData) === 'number') {
        // simple implementation of NumberFormat.format({maximumFractionDigits:2})
        // without the international part
        return Math.round(cellData * 100) / 100;
    }
    return cellData;
};

const JsonifyRenderer = (cellData) => {
    if (cellData) {
        return (
            <code>
                { [JSON.stringify(cellData)] }
            </code>
        );
    }
    return '';
};

const bytes_units = ['B', 'kiB', 'MiB', 'GiB', 'TiB', 'PiB'];

export const BytesCountCellRenderer = (cellData) => {
    let size = parseFloat(cellData);
    if (!isNaN(size)) {
        let unit;

        /* ros symbols are int32. For sizes, which are unsigned,
         * take 2's complement to get an uint32 representation */
        if (size < 0) {
            size = (2 ** 32) + size;
        }

        for (let i = 0; i < bytes_units.length; i += 1) {
            unit = bytes_units[i];
            if (size < 1024) {
                break;
            }
            size /= 1024;
        }
        size = Math.round(size * 100) / 100;
        return `${size} ${unit}`;
    }
    return '';
};

const InspectorRenderer = (cellData, rowData) => {
    const start = moment(rowData.timestamp);
    const stop = moment(rowData.close_time || rowData.timestamp);

    start.subtract(1, 'hours');
    stop.add(1, 'hours');

    if (rowData.asset_id != null) {
        return (
            <a href={`/inspect/#/?asset_id=${rowData.asset_id}&begin=${start.toISOString()}&end=${stop.toISOString()}`}>
                <span className="glyphicon glyphicon-signal" title={i18n.t('Show this timeframe in the data inspector')} />
            </a>
        );
    }
    return <span />;
};

/**
 * A component that displays an hexadecimal bitfield, and shows a tooltip
 * that list the active bits when hovered.
 * Required props:
 *  bits: an array (see rug2pm_status_bits for an example)
 *  value: an hexadecimal number
 */
const BitFieldTooltip = (props) => {
    const word = parseInt(props.value, 16);
    if (isNaN(word)) {
        return null;
    }
    const bitlist = props.bits
        .filter((bit) => ((word >> bit.pos) & 1) === 1)
        .map((bit) => `<dt>${bit.name}</dt><dd>${bit.description}<dd/>`);
    const repr = (bitlist.length > 0)
        ? `<dl>${bitlist.join('')}</dl>`
        : 'No bit set';
    return (
        <WithPopper
            placement="right"
        >
            <a>{props.value}</a>
            <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: repr,
                }}
            />
        </WithPopper>
    );
};

const rug2pm_status_bits = [
    { pos: 0, name: 'PM_ALARM', description: 'Something really bad happened, OS halt required' },
    { pos: 1, name: 'PM_VIN_DET', description: 'External voltage detected' },
    { pos: 2, name: 'PM_BP_DET', description: 'Batpack detected' },
    { pos: 3, name: 'PM_PWR_OK', description: 'External or battery power available' },
    { pos: 4, name: 'PM_EXT_PG', description: 'Power rail EXT OK' },
    { pos: 5, name: 'PM_EXT_EN', description: 'Power rail EXT enabled' },
    { pos: 6, name: 'PM_VCCO_PG', description: 'Power rail VCCO OK' },
    { pos: 7, name: 'PM_VCCO_EN', description: 'Power rail VCCO enabled' },
    { pos: 8, name: 'PM_5V_PG', description: 'Power rail 5V OK' },
    { pos: 9, name: 'PM_5V_EN', description: 'Power rail 5V enabled' },
    { pos: 10, name: 'PM_3V8_PG', description: 'Power rail 3V8 OK' },
    { pos: 11, name: 'PM_3V8_EN', description: 'Power rail 3V8 enabled' },
    { pos: 12, name: 'PM_VUSB_DET', description: 'USB power detected' },
    { pos: 14, name: 'PM_ADXL_ERR', description: 'Accelerometer read error' },
    { pos: 15, name: 'PM_MEMS_EN', description: 'Vibration wakeup enabled' },
    { pos: 16, name: 'PM_PER_EN', description: 'Periodic wakeup enabled' },
    { pos: 17, name: 'PM_PER_WKUP', description: 'Periodic wakeup' },
    { pos: 18, name: 'PM_MEMS_WKUP', description: 'Vibration wakeup' },
    { pos: 19, name: 'PM_SWD_NRST', description: 'Railster OS watchdog triggered' },
    { pos: 20, name: 'PM_LTC_ERR', description: 'Supercap manager read error' },
    { pos: 21, name: 'PM_BQ28_ERR', description: 'Batpack manager read error' },
    { pos: 22, name: 'PM_BQ24_ERR', description: 'Battery charger read error' },
    { pos: 23, name: 'PM_INA_ERR', description: 'Power measurement read error' },
    { pos: 24, name: 'PM_IWDG_NRST', description: 'Platform manager watchdog triggered' },
    { pos: 27, name: 'PM_LOG_EN', description: 'Hardware logging service enabled' },
    { pos: 28, name: 'PM_MON_EN', description: 'Board monitoring service enabled' },
    { pos: 29, name: 'PM_CHARGER_EN', description: 'Battery charger enabled' },
    { pos: 30, name: 'PM_ROS_EN', description: 'Railster OS running' },
    { pos: 31, name: 'PM_SWD_EN', description: 'Railster OS watchdog enabled' },
];

const rug2pm_bat_status_bits = [
    { pos: 0, name: 'PM_BAT_ALARM', description: 'Something really bad happened' },
    { pos: 1, name: 'PM_BAT_ALERT', description: 'An imminent error is about to occur (see bq_safety_alert)' },
    { pos: 4, name: 'PM_BAT_DSGFET', description: 'Discharge FET opened (no current flow)' },
    { pos: 5, name: 'PM_BAT_CHGFET', description: 'Charge FET opened (no current flow)' },
    { pos: 8, name: 'PM_BAT_FD', description: 'Battery is fully discharged' },
    { pos: 9, name: 'PM_BAT_FC', description: 'Battery is fully charged' },
    { pos: 10, name: 'PM_BAT_TD', description: 'Battery should terminate discharging (0% SoC)' },
    { pos: 11, name: 'PM_BAT_TC', description: 'Battery should terminate charging (100% SoC)' },
];

const rug2pm_bq_bat_status_bits = [
    { pos: 0, name: 'EC0', description: 'See BQ28 docs (or Alex)' },
    { pos: 1, name: 'EC1', description: 'See BQ28 docs (or Alex)' },
    { pos: 2, name: 'EC2', description: 'See BQ28 docs (or Alex)' },
    { pos: 3, name: 'EC3', description: 'See BQ28 docs (or Alex)' },
    { pos: 4, name: 'FD', description: 'Fully Discharged' },
    { pos: 5, name: 'FC', description: 'Fully Charged' },
    { pos: 6, name: 'DSG', description: 'DiScharGe/relax state' },
    { pos: 7, name: 'INIT', description: 'See BQ28 docs (or Alex)' },
    { pos: 8, name: 'RTA', description: 'See BQ28 docs (or Alex)' },
    { pos: 9, name: 'RCA', description: 'See BQ28 docs (or Alex)' },
    { pos: 11, name: 'TDA', description: 'Terminate Discharge Alarm' },
    { pos: 12, name: 'OTA', description: 'OverTemperature Alarm' },
    { pos: 14, name: 'TCA', description: 'Terminate Charge Alarm' },
    { pos: 15, name: 'OCA', description: 'OverCharge Alarm' },
];

const rug2pm_bq_mac_ctl_bits = [
    { pos: 0, name: 'QMAX', description: 'See BQ28 docs (or Alex)' },
    { pos: 1, name: 'VOK', description: 'See BQ28 docs (or Alex)' },
    { pos: 2, name: 'R_DIS', description: 'See BQ28 docs (or Alex)' },
    { pos: 3, name: 'LDMD', description: 'See BQ28 docs (or Alex)' },
    { pos: 7, name: 'BTP_INT', description: 'See BQ28 docs (or Alex)' },
    { pos: 9, name: 'CHECKSUMVALID', description: 'See BQ28 docs (or Alex)' },
    { pos: 12, name: 'AUTHCALM', description: 'See BQ28 docs (or Alex)' },
    { pos: 13, name: 'SEC0', description: 'See BQ28 docs (or Alex)' },
    { pos: 14, name: 'SEC1', description: 'See BQ28 docs (or Alex)' },
];

const rug2pm_bq_op_status_bits = [
    { pos: 1, name: 'DSG', description: 'DSG FET status' },
    { pos: 2, name: 'CHG', description: 'CHG FET status' },
    { pos: 7, name: 'BTP_ENT', description: 'See BQ28 docs (or Alex)' },
    { pos: 8, name: 'SEC0', description: 'See BQ28 docs (or Alex)' },
    { pos: 9, name: 'SEC1', description: 'See BQ28 docs (or Alex)' },
    { pos: 10, name: 'SDV', description: 'See BQ28 docs (or Alex)' },
    { pos: 11, name: 'SS', description: 'See BQ28 docs (or Alex)' },
    { pos: 12, name: 'PF', description: 'See BQ28 docs (or Alex)' },
    { pos: 15, name: 'SLEEP', description: 'See BQ28 docs (or Alex)' },
    { pos: 16, name: 'SDM', description: 'See BQ28 docs (or Alex)' },
    { pos: 18, name: 'AUTH', description: 'See BQ28 docs (or Alex)' },
    { pos: 19, name: 'AUTOCALM', description: 'See BQ28 docs (or Alex)' },
    { pos: 20, name: 'CAL', description: 'See BQ28 docs (or Alex)' },
    { pos: 21, name: 'CALOFFSET', description: 'See BQ28 docs (or Alex)' },
    { pos: 22, name: 'XL', description: 'See BQ28 docs (or Alex)' },
    { pos: 23, name: 'SLEEPM', description: 'See BQ28 docs (or Alex)' },
    { pos: 24, name: 'INIT', description: 'See BQ28 docs (or Alex)' },
    { pos: 25, name: 'SMBLCAL', description: 'See BQ28 docs (or Alex)' },
    { pos: 26, name: 'SLPAD', description: 'See BQ28 docs (or Alex)' },
    { pos: 27, name: 'SLPCC', description: 'See BQ28 docs (or Alex)' },
    { pos: 28, name: 'CB', description: 'Cell Balancing active' },
];

const rug2pm_bq_chg_status_bits = [
    { pos: 0, name: 'UT', description: 'Under Temperature' },
    { pos: 1, name: 'LT', description: 'Low Temperature' },
    { pos: 2, name: 'SLT', description: 'Standard Low Temperature' },
    { pos: 3, name: 'RT', description: 'Recommended Temperature' },
    { pos: 4, name: 'STH', description: 'Standard Temperature High' },
    { pos: 5, name: 'HT', description: 'High Temperature' },
    { pos: 6, name: 'OT', description: 'Over Temperature' },
    { pos: 8, name: 'PV', description: 'Precharge Voltage region' },
    { pos: 9, name: 'LV', description: 'Low Voltage region' },
    { pos: 10, name: 'MV', description: 'Mid Voltage region' },
    { pos: 11, name: 'HV', description: 'High Voltage region' },
    { pos: 12, name: 'IN', description: 'charge INhibit' },
    { pos: 13, name: 'SU', description: 'charge SUspend' },
    { pos: 14, name: 'MCHG', description: 'Maintenance CHarGe' },
    { pos: 15, name: 'VCT', description: 'Valid Charge Termination' },
];

const rug2pm_bq_gauging_status_bits = [
    { pos: 0, name: 'FD', description: 'Fully Discharged' },
    { pos: 1, name: 'FC', description: 'Fully Charged' },
    { pos: 2, name: 'TD', description: 'Terminate Discharge' },
    { pos: 3, name: 'TC', description: 'Terminate Charge' },
    { pos: 4, name: 'BAL_EN', description: 'BALancing ENabled' },
    { pos: 5, name: 'EDV', description: 'End of Discharge termination Voltage' },
    { pos: 6, name: 'DSG', description: 'DiScharGe/relax state' },
    { pos: 7, name: 'CF', description: 'See BQ28 docs (or Alex)' },
    { pos: 8, name: 'REST', description: 'See BQ28 docs (or Alex)' },
    { pos: 10, name: 'RDIS', description: 'See BQ28 docs (or Alex)' },
    { pos: 11, name: 'VOK', description: 'See BQ28 docs (or Alex)' },
    { pos: 12, name: 'QEN', description: 'See BQ28 docs (or Alex)' },
    { pos: 13, name: 'SLPQMAX', description: 'See BQ28 docs (or Alex)' },
    { pos: 15, name: 'NSFM', description: 'See BQ28 docs (or Alex)' },
    { pos: 16, name: 'VDQ', description: 'See BQ28 docs (or Alex)' },
    { pos: 17, name: 'QMAX', description: 'See BQ28 docs (or Alex)' },
    { pos: 18, name: 'RX', description: 'See BQ28 docs (or Alex)' },
    { pos: 19, name: 'LDMD', description: 'See BQ28 docs (or Alex)' },
    { pos: 20, name: 'OCVFR', description: 'See BQ28 docs (or Alex)' },
];

const rug2pm_bq_safety_alert_bits = [
    { pos: 0, name: 'CUV', description: 'Cell Under Voltage' },
    { pos: 1, name: 'COV', description: 'Cell Over Voltage' },
    { pos: 2, name: 'OCC', description: 'Over Current during Charge' },
    { pos: 4, name: 'OCD', description: 'Over Current during Discharge' },
    { pos: 6, name: 'AOLD', description: 'OverLoad during Discharge' },
    { pos: 8, name: 'ASCC', description: 'Short-Circuit during Charge' },
    { pos: 10, name: 'ASCD', description: 'Short-Circuit during Discharge' },
    { pos: 12, name: 'OTC', description: 'Over-Temperature during Charge' },
    { pos: 13, name: 'OTD', description: 'Over-Temperature during Discharge' },
    { pos: 19, name: 'PTOS', description: 'Precharge TimeOut Suspend' },
    { pos: 21, name: 'CTOS', description: 'Charge TimeOut Suspend' },
    { pos: 26, name: 'UTC', description: 'Under-Temperature during Charge' },
    { pos: 27, name: 'UTD', description: 'Under-Temperature during Discharge' },
];

const rug2pm_bq_safety_status_bits = [
    { pos: 0, name: 'CUV', description: 'Cell Under Voltage' },
    { pos: 1, name: 'COV', description: 'Cell Over Voltage' },
    { pos: 2, name: 'OCC', description: 'Over Current during Charge' },
    { pos: 4, name: 'OCD', description: 'Over Current during Discharge' },
    { pos: 6, name: 'AOLD', description: 'Overload During Discharge' },
    { pos: 8, name: 'ASCC', description: 'Short-Circuit during Charge' },
    { pos: 10, name: 'ASCD', description: 'Short-Circuit during Discharge' },
    { pos: 12, name: 'OTC', description: 'Over-Temperature during Charge' },
    { pos: 13, name: 'OTD', description: 'Over-Temperature during Discharge' },
    { pos: 19, name: 'PTOS', description: 'Precharge TimeOut Suspend' },
    { pos: 21, name: 'CTOS', description: 'Charge TimeOut Suspend' },
    { pos: 26, name: 'UTC', description: 'Under-Temperature during Charge' },
    { pos: 27, name: 'UTD', description: 'Under-Temperature during Discharge' },
];

const rug2pmRendererFor = (columnName) => {
    let bits;
    switch (columnName) {
    case 'pm_status':
    case 'pmstat': bits = rug2pm_status_bits; break;
    case 'bat_status':
    case 'bpstat': bits = rug2pm_bat_status_bits; break;
    case 'bq_bat_status': bits = rug2pm_bq_bat_status_bits; break;
    case 'bq_chg_status': bits = rug2pm_bq_chg_status_bits; break;
    case 'bq_gauging_status': bits = rug2pm_bq_gauging_status_bits; break;
    case 'bq_mac_ctl': bits = rug2pm_bq_mac_ctl_bits; break;
    case 'bq_op_status': bits = rug2pm_bq_op_status_bits; break;
    case 'bq_safety_stat': bits = rug2pm_bq_safety_status_bits; break;
    case 'bq_safety_alert': bits = rug2pm_bq_safety_alert_bits; break;
    default: return null;
    }

    return (cellData) => <BitFieldTooltip value={cellData} bits={bits} />;
};

/**
 * A component that displays an integer number, and shows a tooltip
 * that list the active bits when hovered.
 * Required props:
 *  bits: an array (see rug2pm_status_bits for an example)
 *  value: an integer number
 */
const BitFieldIntegerTooltip = (props) => {
    const word = props.value;
    if (isNaN(word)) {
        return null;
    }
    const bitlist = props.bits
        .filter((bit) => ((word >> bit.pos) & 1) === 1)
        .map((bit) => `<dt>${bit.name}</dt><dd>${bit.description}<dd/>`);
    const repr = (bitlist.length > 0)
        ? `<dl>${bitlist.join('')}</dl>`
        : 'No bit set';
    return (
        <WithPopper
            placement="right"
        >
            <a>{props.value}</a>
            <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: repr,
                }}
            />
        </WithPopper>
    );
};

const uip_mux_sr_status_bits = [
    { pos: 0, name: 'RX', description: 'Receiving' },
    { pos: 1, name: 'RUNNING', description: 'Running' },
    { pos: 2, name: 'PGOOD', description: 'Power Good' },
    { pos: 3, name: 'OVERLOAD', description: 'Overload' },
    { pos: 4, name: 'SS_FAIL', description: 'Soft Start Fail' },
    { pos: 5, name: 'FB', description: 'Value of feedback' },
    { pos: 6, name: 'OC', description: 'Value of OverCurrent' },
];

const uipcsrRendererFor = (columnName) => {
    let bits;
    switch (columnName) {
    case 'uip_mux.uip1_sr':
    case 'uip_mux.uip2_sr':
    case 'uip_mux.uip3_sr':
    case 'uip_mux.uip4_sr':
    case 'uip_mux.uip5_sr': bits = uip_mux_sr_status_bits; break;
    default: return null;
    }

    return (cellData) => <BitFieldIntegerTooltip value={cellData} bits={bits} />;
};

export default {
    AlertPriorityRenderer,
    AssetRenderer,
    ShortAssetRenderer,
    RuleRenderer,
    OverFlowRenderer,
    DateRenderer,
    BooleanCellRenderer,
    OnOffCellRenderer,
    SlaveMasterCellRenderer,
    FloatCellRenderer,
    JsonifyRenderer,
    BytesCountCellRenderer,
    InspectorRenderer,
    rug2pmRendererFor,
    uipcsrRendererFor,
    ALERT_PRIORITY_COLORS,
};
