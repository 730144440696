import React, { useEffect, useState } from 'react';
import './ComponentDisplay.scss';
import NoDataDisplay from '../noDataDisplay/NoDataDisplay';
import useFetch from '../../hooks/useFetch';
import useArrayToDict from '../../hooks/useArrayToDict';

const ComponentDisplay = (props) => {
    const [_components, setComponents] = useState(null);
    const components = useArrayToDict(_components);
    const { fetchAll } = useFetch();
    const [_text, setText] = useState('loading...');

    useEffect(() => {
        if (!props.asset_id) return;
        fetchAll(`/api/asset/${props.asset_id}/components`).then((d) => {
            if (d) {
                setComponents(d);
            } else {
                setComponents(null);
            }
        });
    }, [props.asset_id]);

    useEffect(() => {
        if (props.asset_id || !props.component_id) return;
        fetchAll(`/api/component/${props.component_id}/details`).then((d) => {
            if (d) {
                setComponents(d);
            } else {
                setComponents(null);
            }
        });
    }, [props.asset_id, props.component_id]);

    useEffect(() => {
        if (!props.component_id || _.isEmpty(components)) return;
        const component = components[props.component_id];
        if (component) {
            setText(component.label);
        }
    }, [props.component_id, components]);

    if (!props.component_id) {
        return (<NoDataDisplay {...props} />);
    }
    return (
        <div className={`component-display element-holder ${props.className ? props.className : ''}`}>
            <div className="top-label-late">
                {
                    props.label
                    && <div className="element-label">{props.label}</div>
                }
            </div>
            <div className="element">
                <span>{`${_text}`}</span>
            </div>
        </div>
    );
};

export default ComponentDisplay;
