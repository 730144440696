import React from 'react';
import components from 'components';
import './preventive_detail.scss';
import PreventiveEventDetail
    from '../../../../railfleet/lib/compounds/details/preventiveEventDetail/PreventiveEventDetail';

const { MultiDeviceLayout } = components.app;

const PreventiveDetailComponent = (props) => (
    <PreventiveEventDetail
        asset_id={props.asset_id}
        component_id={props.component_id}
        event_id={props.event_id}
        onCloseModal={props.onCloseModal}
        onClickEdit={props.onClickEdit}
        onClickClose={props.onClickClose}
        readOnly={props.readOnly}
        interventionIdRedirect={props.interventionIdRedirect}
        push_infos={props.push_infos}
    />
);

const PreventiveDetail = (props) => (
    <MultiDeviceLayout>
        <PreventiveDetailComponent {...props} />
    </MultiDeviceLayout>
);

export default PreventiveDetail;
