/* eslint-disable func-names */
import d3 from 'd3';
import d3Tip from 'd3-tip';
import i18n from 'i18next';
import moment from 'moment-timezone';
import './timeline.scss';

d3.tip = d3Tip;

function __guardMethod__(obj, methodName, transform) {
    if (typeof obj !== 'undefined' && obj !== null && typeof obj[methodName] === 'function') {
        return transform(obj, methodName);
    }
    return null;
}

function pointRenderer(d) {
    return `<div> \
            <h4> \
                ${(d3.select(this.parentNode)[0][0].__data__.value_mapping[d.value] != null ? d3.select(this.parentNode)[0][0].__data__.value_mapping[d.value].label : undefined)} \
            </h4> \
        </div> \
        <div> \
            ${moment(d.at).format('DD/MM/YYYY HH:mm:ss')} \
        </div> \
        ${d.extra ? `<div><i>${d.extra}</i></div>` : ''}`;
}

const intervalRenderer = function (d) {
    let duration = moment.duration((d.to ? moment(d.to) : moment()).diff(moment(d.from)));
    if (duration.years()) {
        duration = `${duration.years()} years `;
    } else if (duration.months()) {
        duration = `${duration.months()} months `;
    } else if (duration.days()) {
        duration = `${duration.days()} days `;
    } else if (duration.hours()) {
        duration = `${duration.hours()} hours `;
    } else if (duration.minutes()) {
        duration = `${duration.minutes()} minutes`;
    } else if (duration.seconds()) {
        duration = `${duration.seconds()} seconds`;
    }
    //
    return (
        `<div> \
            <h4> \
                ${(d3.select(this.parentNode)[0][0].__data__.value_mapping[d.value] != null ? d3.select(this.parentNode)[0][0].__data__.value_mapping[d.value].label : undefined)} \
            </h4> \
        </div> \
        <div> \
            <label> ${d.fromLabel ? d.fromLabel : i18n.t('Start')}: </label> \
            ${moment(d.from).format('DD/MM/YYYY HH:mm:ss')} \
        </div> \
        <div> \
            <label> ${d.toLabel ? d.toLabel : i18n.t('End')}: </label> \
            ${d.to ? moment(d.to).format('DD/MM/YYYY HH:mm:ss') : '-'} \
        </div> \
        <div> \
            <label> ${i18n.t('Duration')}: </label> \
            <span> ${duration} </span> \
        </div> \
        ${d.extra ? `<div><i>${d.extra}</i></div>` : ''}`
    );
};

const defaultConfig = {
    tickFormat: [
        ['.%L', (d) => d.getMilliseconds()],
        [':%S', (d) => d.getSeconds()],
        ['%I:%M', (d) => d.getMinutes()],
        ['%I %p', (d) => d.getHours()],
        ['%a %d', (d) => d.getDay() && (d.getDate() !== 1)],
        ['%b %d', (d) => d.getDate() !== 1],
        ['%B', (d) => d.getMonth()],
        ['%Y', () => true],
    ],
    margin: {
        top: 22,
        right: 2,
        bottom: 2,
        left: 0,
    },
    showLiveMarker: true,
    labelWidth: 0.10,
    groupHeight: 18,
    groupSpacing: 11,
    dateFormat: d3.time.format('%d/%m/%Y'),
    showExtremum: true,
    extremumHeight: 10,
    backgroundBorderSize: 2,
    zoomChangeCallback: () => {},
    textTruncateThreshold: 30,
    interveralToolTipRenderer: intervalRenderer,
    pointToolTipRenderer: pointRenderer,
};

class TimeLine {
    getPointMinDt(p) {
        if (p.type === 'POINT') {
            return p.at;
        }
        return p.from;
    }

    getPointMaxDt(p) {
        if (p.type === 'POINT') {
            return p.at;
        }
        return p.to || moment.utc().toISOString();
    }

    zoomed(translateX) {
        let x_diff = 0;
        if (translateX) {
            x_diff = Math.abs(Math.abs(this.translateX) - Math.abs(translateX));
        }
        const now = new Date();
        const scale = this.xScale;
        const nowX = scale(now);
        if (x_diff > 100) {
            this.translateX = translateX;
            this.config.zoomChangeCallback(this.xScale.domain()[0], this.xScale.domain()[1]);
        }

        /* NOW MARKER  */
        this.now.attr('transform', `translate(${nowX - 17}, -16)`);

        /* EXTREMUM */
        if (this.config.showExtremum) {
            this.manageExtremum(this.config.dateFormat);
        }

        /* POINTS */
        this.svg.selectAll('circle.dot').attr('cx', (d) => this.xScale(new Date(d.at)));
        this.svg.selectAll('rect.dot').attr('x', (d) => this.xScale(new Date(d.at)) - 3);
        this.svg.selectAll('text.dot').attr('x', (d) => this.xScale(new Date(d.at)));
        this.svg.selectAll('line.dot').attr('transform', (d) => `translate(${this.xScale(new Date(d.at))}, 0)`);

        /* INTERVALS */
        this.svg.selectAll('rect.interval')
            .attr('x', (d) => scale(new Date(d.from)))
            .attr('width', (d) => Math.max(
                8,
                scale(d.to ? new Date(d.to) : now) - scale(new Date(d.from)),
            ));

        const treshold = this.config.textTruncateThreshold;
        this.svg.selectAll('.interval-text')
            .attr('x', function (d) {
                let label = d3.select(this.parentNode)[0][0].__data__.value_mapping[d.value] != null
                    ? d3.select(this.parentNode)[0][0].__data__.value_mapping[d.value].label : null;

                if (!label) { label = 'undefined'; }

                const from = scale(new Date(d.from)) + 10;
                const to = scale(d.to ? new Date(d.to) : new Date()) - 10;
                if ((to - 10) < ((label.length * 4) + 25)) {
                    return to;
                } if ((from < 25) && (to > 25)) {
                    return 35;
                }
                return from;
            })
            .attr('text-anchor', function (d) {
                let label = d3.select(this.parentNode)[0][0].__data__.value_mapping[d.value] != null
                    ? d3.select(this.parentNode)[0][0].__data__.value_mapping[d.value].label : null;
                if (!label) { label = 'undefined'; }
                const to = scale(d.to ? new Date(d.to) : new Date()) - 10;
                if ((to - 10) < ((label.length * 4) + 25)) {
                    return 'end';
                }
                return 'start';
            })
            .text(function (d) {
                let label = d3.select(this.parentNode)[0][0].__data__.value_mapping[d.value] != null
                    ? d3.select(this.parentNode)[0][0].__data__.value_mapping[d.value].label : null;
                if (!label) { label = 'undefined'; }
                const from = scale(new Date(d.from)) + 10;
                const to = scale(d.to ? new Date(d.to) : new Date()) - 10;
                const width = to - from - 20;
                const percent = (width - treshold) / (label.length * 4);
                if (percent < 1) {
                    if (width > treshold) {
                        return `${label.substr(0, Math.floor(label.length * percent))}...`;
                    }
                    return '';
                }
                return label;
            });
        this.refreshAxis();
    }

    getConfig(props) {
        return _.mapObject(defaultConfig, (val, key) => {
            if (props[key] != null) {
                return props[key];
            }
            return val;
        });
    }

    refreshAxis() {
        this.svg.select('.x.axis').call(this.xAxis);
    }

    manageExtremum(dateFormat) {
        const extremum = this.svg.select('.extremum');
        extremum.selectAll('.minimum').remove();
        extremum.selectAll('.maximum').remove();

        const domain = this.xScale.domain();
        extremum.append('text')
            .classed('minimum', true)
            .text(dateFormat(domain[0]))
            .attr('transform', 'translate(35, -8)');
        extremum.append('text')
            .classed('maximum', true)
            .text(dateFormat(domain[1]))
            .attr('transform', `translate(${this.xScale.range()[1] - (0.05 * this.xScale.range()[1])}, -8)`);
    }

    constructTip(width, labelWidth, groupHeight) {
        return d3.tip()
            .attr('class', 'd3-tip')
            .offset(function () {
                const box = this.getBBox();
                const to = box.width + box.x;
                const chartWidth = width * (1 - labelWidth);
                let offset = null;
                if ((to > chartWidth) && (box.x < 0)) {
                    offset = chartWidth / 2;
                } else if (to > chartWidth) {
                    offset = (chartWidth + box.x) / 2;
                } else if (box.x < 0) {
                    offset = (box.width + box.x) / 2;
                }
                return [
                    2 * groupHeight,
                    (offset != null) ? ((-box.width / 2) - box.x) + offset : 0,
                ];
            })
            .direction('s')
            .html(function (d) {
                if (d.type === 'INTERVAL') {
                    intervalRenderer.call(this, d);
                } else if (d.type === 'POINT') {
                    pointRenderer.call(this, d);
                }
            });
    }

    populateIntervals(data) {
        const now = new Date();
        this.chartContainer.selectAll('*').remove();

        if (!data || (data.length === 0)) {
            return;
        }

        const safe_label_mapping = function (dd) {
            if (dd.title) {
                return dd.title;
            }

            if (d3.select(this.parentNode)[0][0].__data__.value_mapping[dd.value]) {
                return d3.select(this.parentNode)[0][0].__data__.value_mapping[dd.value].label;
            }

            return `unknown:${dd.value}`;
        };

        this.chartContainer
            .append('rect')
            .attr('class', 'chart-bounds')
            .attr('x', 0)
            .attr('y', 0)
            .attr('height', this.config.height - this.config.backgroundBorderSize)
            .attr('width', this.config.width * (1 - this.config.labelWidth));

        const groupIntervalItems = this.chartContainer.selectAll('.group-interval-item')
            .data(data)
            .enter()
            .append('g')
            .attr('clip-path', 'url(#chart-content)')
            .attr('class', 'item')
            .attr('transform', (d, i) => `translate(0, ${((i + 1) * this.config.groupSpacing) + (i * this.config.groupHeight)})`)
            .selectAll('.dot')
            .data((dd) => _.filter(dd.data, (ddd) => ddd.type === 'INTERVAL'))
            .enter();

        groupIntervalItems
            .append('rect')
            .attr('class', function (d) {
                return `interval time-line-event-${(d3.select(this.parentNode)[0][0].__data__.value_mapping[d.value] != null ? d3.select(this.parentNode)[0][0].__data__.value_mapping[d.value].color : null)}`;
            })
            .attr('width', (d) => Math.max(8, this.xScale(d.to ? new Date(d.to) : now) - this.xScale(new Date(d.from))))
            .attr('height', this.config.groupHeight)
            .attr('y', 0)
            .attr('x', (d) => this.xScale(new Date(d.from)))
            .style('fill', function (d) {
                if ((d3.select(this.parentNode)[0][0].__data__.value_mapping[d.value] != null
                    ? d3.select(this.parentNode)[0][0].__data__.value_mapping[d.value].raw : null)) {
                    return (d3.select(this.parentNode)[0][0].__data__.value_mapping[d.value] != null
                        ? d3.select(this.parentNode)[0][0].__data__.value_mapping[d.value].color
                        : null);
                }
                return null;
            })
            .on('mouseover', this.tip.show)
            .on('mouseout', this.tip.hide);

        groupIntervalItems
            .append('text')
            .text(safe_label_mapping)
            .classed('interval-text', true)
            .attr('y', this.config.groupHeight / 2)
            .attr('x', (d) => this.xScale(new Date(d.from)));

        const groupDotItems = this.chartContainer.selectAll('.group-dot-item')
            .data(data)
            .enter()
            .append('g')
            .attr('clip-path', 'url(#chart-content)')
            .attr('class', 'item')
            .attr('transform', (dd, i) => `translate(0, ${((i) * this.config.groupSpacing) + (i * this.config.groupHeight)})`)
            .selectAll('.dot')
            .data((dd) => _.filter(dd.data, (ddd) => ddd.type === 'POINT'))
            .enter();

        groupDotItems
            .append('rect')
            .classed('dot', true)
            .attr('width', 6)
            .attr('height', this.config.groupHeight)
            .attr('y', this.config.groupSpacing)
            .on('mouseover', this.tip.show)
            .on('mouseout', this.tip.hide);

        groupDotItems
            .append('circle')
            .classed('dot', true)
            .attr('cx', (d) => this.xScale(new Date(d.at)))
            .attr('cy', 3 + this.config.groupSpacing)
            .attr('r', 3);

        groupDotItems
            .append('line')
            .classed('dot', true)
            .attr('x1', 0)
            .attr('x2', 0)
            .attr('y1', 6 + this.config.groupSpacing)
            .attr('y2', this.config.groupHeight + this.config.groupSpacing);

        const groupDotItemsText = this.chartContainer.selectAll('.group-dot-item')
            .data(data)
            .enter()
            .append('g')
            .attr('clip-path', 'url(#chart-content)')
            .attr('class', 'item')
            .attr('transform', (d, i) => `translate(0, ${((i) * this.config.groupSpacing) + (i * this.config.groupHeight)})`)
            .selectAll('.dot')
            .data((dd) => _.filter(dd.data, (ddd) => ddd.type === 'POINT'))
            .enter();

        groupDotItemsText
            .append('text')
            .text(safe_label_mapping)
            .classed('dot', true)
            .attr('y', 0)
            .on('click', function (d) {
                return __guardMethod__(d3.select(this.parentNode)[0][0].__data__.value_mapping[d.value], 'onclick', (o) => o.onclick(d));
            })
            .on('mouseover', this.tip.show)
            .on('mouseout', this.tip.hide);
    }

    create(el, props, data, id) {
        this.config = this.getConfig(props);
        this.translateX = 0;
        const { margin } = this.config;

        const lines = _.keys(data).length;
        let height = (
            ((lines * (this.config.groupHeight)) + ((lines + 2) * this.config.groupSpacing))
            - (2 * this.config.backgroundBorderSize))
            + 11 + (this.config.showExtremum ? this.config.extremumHeight : 0);
        if (height < 0) {
            height = 0;
        }
        el.classList.add('timeline-chart');

        const elementWidth = props.width || el.clientWidth;

        const width = elementWidth - margin.left - margin.right;
        this.config.height = height;
        this.config.width = width;

        const now = new Date();
        const min = (new Date()).setHours(now.getHours() - 2);
        const max = (new Date()).setHours(now.getHours() + 7); // default window is [now-8h, now+1h]
        // we first scale it to a 8 hour span
        const xscale = d3.time.scale()
            .domain([min, max])
            .range([0, (1 - this.config.labelWidth) * width]);
        this.xScale = xscale;
        let tick_format = _.map(this.config.tickFormat, (f) => f.slice(0));
        tick_format = d3.time.format.multi(tick_format);

        this.xAxis = d3.svg.axis()
            .scale(this.xScale)
            .orient('top')
            .tickSize(-height + this.config.backgroundBorderSize, 0)
            .tickFormat(tick_format);

        this.zoom = d3.behavior.zoom()
            .x(this.xScale)
            .on('zoom', () => {
                let zoom = true;
                if ((d3.event.sourceEvent != null ? d3.event.sourceEvent.type : null) === 'wheel') {
                    if (d3.event.sourceEvent.deltaX !== 0) {
                        zoom = false;
                        this.navigateTime((d3.event.sourceEvent.deltaX > 0));
                    }
                }
                if (zoom) {
                    this.zoomed(d3.event.translate[0]);
                }
            });

        const svg_base = d3.select(el).append('svg')
            .attr('id', id)
            .attr('class', 'd3_timeline')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.bottom + margin.top);

        svg_base.append('rect')
            .classed('background', true)
            .attr('width', '100%')
            .attr('height', height + margin.bottom + margin.top)
            .attr('y', 0)
            .attr('rx', 3)
            .attr('ry', 3);

        this.svg = svg_base
            .append('g')
            .attr('transform', `translate(${margin.left + 2},${margin.top + 2})`)
            .call(this.zoom);

        this.svg.append('defs')
            .append('clipPath')
            .attr('id', 'chart-content')
            .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('height', height - this.config.extremumHeight)
            .attr('width', width * (1 - this.config.labelWidth));

        /* tip management */

        this.tip = this.constructTip(width, this.config.labelWidth, 0);
        this.svg.call(this.tip);

        /* chart management */
        this.chartContainer = this.svg
            .append('g')
            // .attr('height', height - @config.extremumHeight)
            .attr('y', 2)
            .attr('x', 2)
            .attr('class', 'chart-bounds')
            .attr('transform', `translate(0, ${this.config.extremumHeight})`);

        this.chartOuterContainer = this.svg
            .append('g')
            .attr('with', width)
            .attr('y', 2)
            .attr('x', 2)
            .attr('class', 'chart-bounds-outer');

        this.populateIntervals(data);

        const axesContainer = this.chartOuterContainer.append('g')
            .classed('axes', true);

        axesContainer.append('g')
            .attr('class', 'x axis')
            .attr('transform', `translate(0, ${this.config.extremumHeight})`)
            .call(this.xAxis);

        if (this.config.showLiveMarker) {
            // show a line/marker at current time
            this.now = this.chartOuterContainer.append('g')
                .classed('live_marker', true)
                .attr('transform', 'translate(0, -16)');

            this.now.append('line')
                .attr('clip-path', 'url(#chart-content)')
                .attr('class', 'vertical-marker now')
                .attr('x1', 0)
                .attr('x2', 0)
                .attr('y1', 0)
                .attr('y2', height - this.config.extremumHeight)
                .attr('transform', `translate(17, ${this.config.extremumHeight + 16})`);

            this.now.append('rect')
                .classed('vertical-marker now-rect', true)
                .attr('x', 0)
                .attr('y', 0)
                .attr('rx', 5)
                .attr('ry', 5)
                .attr('width', '34px')
                .attr('height', '14px')
                .attr('transform', `translate(0, ${this.config.extremumHeight + 2})`);

            this.now.append('text')
                .classed('vertical-marker now-text', true)
                .style('text-anchor', 'middle')
                .attr('x', 17)
                .attr('y', 8)
                .attr('dominant-baseline', 'middle')
                .attr('transform', `translate(0, ${this.config.extremumHeight})`)
                .text('now');
        }

        /* extremum management */
        if (this.config.showExtremum) {
            // eslint-disable-next-line
            const extremum = this.chartOuterContainer.append('g')
                .classed('extremum', true);
        }

        /* label management */
        const labelContainer = this.svg
            .append('g')
            .classed('labels', true)
            .attr('height', height)
            .attr('transform', `translate(${width * (1 - this.config.labelWidth)}, ${this.config.extremumHeight})`);

        // to hide everything under
        labelContainer.append('rect')
            .attr('class', 'label-bounds')
            .attr('x', -2)
            .attr('y', -(this.config.extremumHeight + 4))
            .attr('height', (height - this.config.extremumHeight) + 13)
            .attr('width', (width * this.config.labelWidth) + 1);

        labelContainer.selectAll('.label')
            .data(data)
            .enter()
            .append('text')
            .attr('class', 'group-label')
            .attr('text-anchor', 'end')
            .attr('x', (width * this.config.labelWidth) - 5)
            .attr('y', (d, i) => (
                ((i + 1) * this.config.groupSpacing)
                + (i * this.config.groupHeight)
                + (this.config.groupHeight / 2)
            ))
            .attr('dominant-baseline', 'middle')
            .text((d) => d.label);

        labelContainer.selectAll('.label')
            .data(data)
            .enter()
            .append('line')
            .classed('group-label-line', true)
            .attr('x1', 0)
            .attr('x2', width * this.config.labelWidth)
            .attr('y1', (d, i) => (
                ((i + 1) * this.config.groupSpacing) + (i * this.config.groupHeight)
            ))
            .attr('y2', (d, i) => (
                ((i + 1) * this.config.groupSpacing) + (i * this.config.groupHeight)
            ));

        labelContainer.selectAll('.label')
            .data(data)
            .enter()
            .append('line')
            .classed('group-label-line', true)
            .attr('x1', 0)
            .attr('x2', width * this.config.labelWidth)
            .attr('y1', (d, i) => (
                ((i + 1) * this.config.groupSpacing) + ((i + 1) * this.config.groupHeight)
            ))
            .attr('y2', (d, i) => (
                ((i + 1) * this.config.groupSpacing) + ((i + 1) * this.config.groupHeight)
            ));
    }

    update(data) {
        this.populateIntervals(data);
        this.zoomed();
    }

    destroy(id) {
        this.svg.selectAll('*').remove();
        d3.select('.d3-tip').remove();
        $(`#${id}`).empty();
    }

    pZoom(change) {
        const domain = this.xScale.domain();
        domain[0] = new Date(new Date(domain[0]).getTime() + change);
        domain[1] = new Date(new Date(domain[1]).getTime() + change);
        // d3.transition()
        // .duration(change)
        // .call(@zoom.x(@xScale.domain(domain)).event)
        this.svg.call(this.zoom.x(this.xScale.domain(domain)).event);
    }

    zoomIn() {
        const domain = this.xScale.domain();
        const date0 = new Date(domain[0]).getTime();
        const date1 = new Date(domain[1]).getTime();
        const delta = 0.2 * (date1 - date0);
        domain[0] = new Date(date0 + delta);
        domain[1] = new Date(date1 - delta);
        this.svg.call(this.zoom.x(this.xScale.domain(domain)).event);
    }

    zoomOut() {
        const domain = this.xScale.domain();
        const date0 = new Date(domain[0]).getTime();
        const date1 = new Date(domain[1]).getTime();
        const delta = 0.2 * (date1 - date0);
        domain[0] = new Date(date0 - delta);
        domain[1] = new Date(date1 + delta);
        this.svg.call(this.zoom.x(this.xScale.domain(domain)).event);
    }

    navigateTime(forward) {
        const ticks = this.xScale.ticks();
        const change = ticks[1] - ticks[0];
        this.pZoom(forward ? change : -change);
    }

    navigateTo(from, to) {
        const domain = [from, to];
        this.svg.call(this.zoom.x(this.xScale.domain(domain)).event);
    }

    resetTime() {
        const ticks = this.xScale.ticks();
        const now = new Date().getTime();
        const max = ticks[2];
        const change = now - max;
        this.pZoom(change);
    }
}

export default TimeLine;
