import React from 'react';
import './TimelineBullet.scss';
import MultiDeviceLayout, { LAYOUT } from '../../app/MultiDeviceLayout';
import Button from '../../buttons/button/Button';

const ButtonOpened = (props) => {
    if (
        props.opened
        || props.past
        || props.cancelled
        || !props.hasPerm) return null;
    return (
        <div className="timeline-bullet-button">
            <Button
                primary
                label={props.buttonText}
                onClick={props.onClick}
            />
        </div>
    );
};

const Bullet = () => (
    <div className="timeline-bullet-icon">
        <div className="timeline-bullet-icon-inner" />
        <div className="timeline-bullet-pointer" />
    </div>
);

const TimelineBulletComponent = (props) => (
    <div className={`timeline-bullet${props.selected ? ' selected' : ''}${props.past ? ' past' : ''} ${props.device === LAYOUT.TABLET ? LAYOUT.DESKTOP : props.device}${props.late ? ' late' : ''}${props.done ? ' done' : ''}`}>
        <div className="timeline-part">
            <Bullet />
            <div className="timeline-bullet-label">{props.label}</div>
        </div>
        {
            props.past
            && <div className="timeline-bullet-content">{props.children}</div>
        }
        <Bullet />
        <ButtonOpened {...props} />
    </div>
);

const TimelineBullet = (props) => (
    <MultiDeviceLayout>
        <TimelineBulletComponent {...props} />
    </MultiDeviceLayout>
);

TimelineBulletComponent.defaultProps = {
    opened: true,
    buttonText: 'ok',
};

export default TimelineBullet;
