import React, { Fragment } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';

import Dropzone from 'react-dropzone-component';
import excel_image from '../../img/excel_image.png';
import word_image from '../../img/word_image.png';
import pdf_image from '../../img/pdf_image.png';
import default_image from '../../img/default_image.png';
import WithLoginless from '../../../app/withLoginless';
import './FileInput.scss';

export class ROFileInput extends React.PureComponent {
    render() {
        const {
            name, value,
            multi, deleteAction,
            deletable, onChange, custom_class,
        } = this.props;
        const files = multi ? value : [value];
        const file_type_mapping = [
            {
                extensions: ['xls', 'xlsx', 'csv'],
                image: excel_image,
            },
            {
                extensions: ['doc', 'docx', 'txt'],
                image: word_image,
            },
            {
                extensions: ['pdf'],
                image: pdf_image,
            },
            {
                extensions: ['png', 'jpg', 'jpeg', 'gif'],
            },
        ];
        return (
            <div>
                {
                    _.map(files, (file_id) => {
                        const file = this.props.values && this.props.values[file_id];
                        if (file !== undefined && file !== null && file.file_url !== null) {
                            let file_img = default_image;
                            const file_extension = file.file_url.split('.')
                                .slice(-1)[0].split('?')[0];
                            for (const cand of file_type_mapping) {
                                if (file_extension !== undefined
                                    && file_extension !== null
                                    && _.includes(cand.extensions, file_extension.toLowerCase())
                                ) {
                                    if (cand.image) {
                                        file_img = cand.image;
                                    } else {
                                        file_img = file.file_url;
                                    }
                                    break;
                                }
                            }
                            /* eslint-disable */
                            return (
                                <div
                                    key={`${name}_file_${file_id}`}
                                    className={
                                        (custom_class !== null && custom_class !== undefined) ?
                                            custom_class : 'col-sm-3'
                                    }
                                    onClick={evt => evt.stopPropagation()}
                                >
                                    <div className="thumbnail">
                                        {/* <img src={file.file_url}/> */}
                                        <a
                                            href={file.file_url}
                                            download={file.file_name}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={file_img} alt="" />
                                        </a>
                                        <div className="caption row">
                                            <div className="col-xs-12 small text-muted text-center">
                                                {file.file_name}
                                                {' ● '}
                                                <a
                                                    className="caption-link"
                                                    href={file.file_url}
                                                    download={file.file_name}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {i18n.t('Download')}
                                                </a>
                                                {
                                                    deletable ? (
                                                        <Fragment>
                                                            {' ● '}
                                                            <a
                                                                className="caption-link"
                                                                onClick={
                                                                    (evt) => {
                                                                        evt.preventDefault();
                                                                        if (window.confirm('Do you want to delete this file?')) {
                                                                            deleteAction(file);
                                                                            if (typeof onChange === 'function') {
                                                                                const vv = _.filter(this.props.value, v => v !== file_id);
                                                                                onChange(vv);
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            >
                                                                {i18n.t('Delete')}
                                                            </a>
                                                        </Fragment>
                                                    ) : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                            /* eslint-enable */
                        }
                        return null;
                    })
                }
            </div>
        );
    }
}

class FileInput extends React.PureComponent {
    constructor(props) {
        super(props);
        this._dz = null;
    }

    render() {
        if (this.props.readonly) return <ROFileInput {...this.props} />;
        const { onChange, url, hide_ro } = this.props;
        const djsConfig = {
            addRemoveLinks: true,
            maxFilesize: 10, // MB
            uploadMultiple: false,
            dictDefaultMessage: i18n.t('Click or drop file here to add them'),
            headers: {
                'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
                'SUPERNOVA-LOGINLESS-ASSET-ID': this.props.asset_id,
                'SUPERNOVA-LOGINLESS-SECRET': this.props.secret,
            },
        };
        if (this.props.unique) {
            djsConfig.maxFiles = 1;
        }
        return (
            <div>
                {
                    hide_ro ? null : (
                        <div className="row">
                            <ROFileInput {...this.props} />
                        </div>
                    )
                }
                <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                    <Dropzone
                        config={{
                            postUrl: url,
                            iconFileTypes: ['.jpg', '.png', '.pdf', '.gif', '.doc', '.docx'],
                            showFileTypeIcon: true,
                        }}
                        djsConfig={djsConfig}
                        eventHandlers={{
                            init: (dz) => (this._dz = dz),
                            success: (() => {
                                const files_id = _.keys(this.props.values);
                                for (const file of this._dz.getAcceptedFiles()) {
                                    if (file.status === 'success') {
                                        files_id.push(JSON.parse(file.xhr.responseText).id);
                                    }
                                }
                                onChange(files_id);
                            }),
                            reset: (() => {
                                onChange(null);
                            }),
                            error: ((file, message) => {
                                alert(message);
                                this._dz.removeFile(file);
                            }),
                        }}
                    >
                        {this.props.children}
                    </Dropzone>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state, props) => {
    if (props.loginless) return props.mapStateToProps(state, props);
    return {};
};

const mapDispatchToProps = (dispatch, props) => {
    if (props.loginless) return props.mapDispatchToProps(dispatch, props);
    return ({});
};

export default WithLoginless(connect(mapStateToProps, mapDispatchToProps)(FileInput));
