/**
 * Created by lpostula on 12/10/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import DatetimePicker from 'react-datetime';

import 'react-datetime/css/react-datetime.css';

class DateSelector extends React.Component {
    constructor(props) {
        super(props);
        const value_date = moment(props.value).tz(this.props.timezone);
        this.state = {
            valid: value_date && value_date.isValid(),
            value: value_date,
        };
        this.isValidDate = this.isValidDate.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    isValidDate(currentDate) {
        let valid = true;
        // both condition should be satisfied
        valid = valid && (!this.props.minDate || currentDate.isSameOrAfter(moment(this.props.minDate), 'day'));
        valid = valid && (!this.props.maxDate || currentDate.isSameOrBefore(moment(this.props.maxDate), 'day'));
        return valid;
    }

    onChange(value) {
        let date = value;
        let isValid = !this.props.required;

        if (date) {
            if (typeof date === 'string') {
                date = value.trim();
            }
            date = moment.tz(date, this.props.labelFormat, true, this.props.timezone);
        } else {
            date = null;
        }

        if (date && date.isValid()) {
            isValid = true;
        }

        this.setState({
            valid: isValid,
            value: date,
        });

        this.props.onChange(date, isValid);
    }

    shouldComponentUpdate(np, ns) {
        const p = this.props;
        if (p.value !== np.value
            || p.dateFormat !== np.dateFormat
            || p.timeFormat !== np.timeFormat
            || p.labelFormat !== np.labelFormat
            || p.name !== np.name
            || p.minDate !== np.minDate
            || p.maxDate !== np.maxDate
            || p.open !== np.open
            || p.required !== np.required
            || p.noLabel !== np.noLabel
            || p.prepend !== np.prepend
            || p.disabled !== np.disabled
        ) {
            return true;
        }

        if (this.state.valid !== ns.valid || this.state.value !== ns.value) {
            return true;
        }

        return false;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.value) {
            const value = moment(props.value).tz(props.timezone);
            if (value && value.isValid()) {
                return { value };
            }
        }
        return null;
    }

    renderSelector() {
        const prependStyle = this.props.prepend ? { borderRadius: '0' } : {};
        return (
            <div className={`input-group ${this.state.valid ? '' : 'has-error'}`} style={{ width: '100%' }}>
                {
                    this.props.prepend ? (
                        <span className="input-group-addon">
                            {this.props.prepend}
                        </span>
                    ) : null
                }
                <DatetimePicker
                    dateFormat={this.props.dateFormat}
                    timeFormat={this.props.timeFormat}
                    inputProps={{
                        id: this.props.id || `id_${this.props.name.replace(' ', '_')}`,
                        className: `form-control${this.state.valid ? '' : ' has-error'}${this.props.prepend ? ' has-left-addon' : ''}`,
                        disabled: this.props.disabled,
                        style: { ...this.props.style, ...prependStyle },
                    }}
                    isValidDate={this.isValidDate}
                    onChange={this.onChange}
                    initialValue={this.state.value}
                />
                <label
                    htmlFor={this.props.id || `id_${this.props.name.replace(' ', '_')}`}
                    className="input-group-addon"
                >
                    <span className="glyphicon glyphicon-calendar" />
                </label>
            </div>
        );
    }

    render() {
        if (this.props.noLabel) {
            return this.renderSelector();
        }
        return (
            <div className="form-group label-floating">
                <label
                    htmlFor={this.props.id || `id_${this.props.label.replace(' ', '_')}`}
                    className="control-label"
                >
                    {this.props.label}
                </label>
                {this.renderSelector()}
            </div>
        );
    }
}

DateSelector.defaultProps = {
    value: null,
    dateFormat: 'DD/MM/YYYY',
    timeFormat: 'HH:mm',
    labelFormat: 'DD/MM/YYYY HH:mm',
    timezone: (window.user_info && window.user_info.timezone) || 'Europe/Brussels',
    name: 'Date',
    minDate: null,
    maxDate: null,
    required: true,
    noLabel: false,
    prepend: null,
    disabled: false,
    style: {},
};

DateSelector.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    labelFormat: PropTypes.string,
    name: PropTypes.string,
    minDate: PropTypes.instanceOf(moment),
    maxDate: PropTypes.instanceOf(moment),
    required: PropTypes.bool,
    noLabel: PropTypes.bool,
    prepend: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    style: PropTypes.object,
};

export default DateSelector;
