import {
    createSelector,
} from 'reselect';

export const getFleetAvailabilityDataState = (state) => (state.fleet_availability);

export const getFleetAvailabilityData = createSelector(
    [getFleetAvailabilityDataState],
    (fleet_availability) => fleet_availability.data,
);

export default {
    getFleetAvailabilityData,
};
