/**
 * Created by gknuts on 27/09/18.
 */
import { combineReducers } from 'redux';
import types from './types';
import { createReducer } from '../../utils';

const initialState = {};

const listReducer = createReducer(initialState)({
    [types.FETCH_LIST_COMPLETED]: (state, action) => ('results' in action.payload ? action.payload.results : action.payload),
    [types.FETCH_LIST_FAILED]: (state, action) => (action.payload.response !== undefined ? action.payload.response : action.payload),
});
const advancedKeyReducer = createReducer(initialState)({
    [types.SEND_QUERY_SUCCESS]: (state, action) => {
        if (action.payload) {
            if ('results' in action.payload) {
                return action.payload.results;
            }
            return action.payload;
        }
        return state;
    },
});

const telematicsReducer = combineReducers({
    telematics: listReducer,
    advanced_key: advancedKeyReducer,
});

export default telematicsReducer;
