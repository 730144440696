/**
 * Created by lpostula on 23/05/17.
 */
import React from 'react';
import i18n from 'i18next';
import './MainLoader.scss';

export default (props) => (
    <div className="main_loader_wrapper" id="main_loader">
        <div className="main_loader_spinner">
            <div className="main_loader_spinner_holder">
                <div className="spinner" />
                <div className="center-text">
                    <span className="loader_step">
                        {props.not_loaded}
                    </span>
                    <span className="step_count">
                        /
                        {props.to_load}
                    </span>
                </div>
            </div>
            <div className="bottom-text">
                {
                    _.map(props.loading_steps, (loading_step, id) => (
                        <div key={`loading_step_text_for_${id}`} className="show">
                            <span className={loading_step.loading ? 'wait' : 'done'}>
                                <span className={`glyphicon glyphicon-${loading_step.loading ? 'refresh' : 'ok'}`} />
                            </span>
                            {`${props.main_loading_text || i18n.t('Loading')} ${loading_step.label || ''}` }
                        </div>
                    ))
                }
            </div>
        </div>
    </div>
);
