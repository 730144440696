/**
 * Created by lpostula on 03/07/17.
 */
import { RSAA } from 'redux-api-middleware';
import types from './types';
import { API_ROOT } from '../../config';

const API_RESOURCE_NAME = 'counters';

export const fetchDetails = (item_id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${item_id}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_DETAILS_REQUEST,
            types.FETCH_DETAILS_COMPLETED,
            types.FETCH_DETAILS_FAILED,
        ],
    },
});

export const fetchList = (long_polling = false) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            {
                type: types.FETCH_LIST_REQUEST,
                meta: { long_polling },
            },
            {
                type: types.FETCH_LIST_COMPLETED,
                meta: { long_polling },
            },
            types.FETCH_LIST_FAILED,
        ],
    },
});

export const fetchForAsset = (asset_id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}.json?asset=${asset_id}`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_LIST_REQUEST,
            {
                type: types.FETCH_LIST_COMPLETED,
                meta: { filter_out: { key: 'asset', value: asset_id } },
            },
            types.FETCH_LIST_FAILED,
        ],
    },
});

export const updateCounterInStore = (data) => ({
    type: types.FETCH_DETAIL_COMPLETED,
    payload: data,
});

export const updateCounterReadingSet = (id_counter, counter_reading) => (dispatch) => dispatch({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${id_counter}/readings/${counter_reading.id}.json`,
        method: 'PATCH',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(counter_reading.value),
        credentials: 'same-origin',
        types: [
            types.UPDATE_DONE_USAGES_REQUEST,
            types.UPDATE_DONE_USAGES_COMPLETED,
            types.UPDATE_DONE_USAGES_FAILED,
        ],
    },
});

export default {
    fetchList,
    fetchDetails,
    fetchForAsset,
    updateCounterInStore,
    updateCounterReadingSet,
};
