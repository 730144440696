import React from 'react';
import './HeaderModal.scss';
import MultiDeviceLayout, { LAYOUT } from '../../app/MultiDeviceLayout';
import BackArrow from './Vector.svg';
import Cross from './Union.svg';
import BlackCross from './BlackUnion.svg';

const ModalHeaderComponent = (props) => {
    const ButtonClose = () => (
        <div className={`close-button${props.hideCloseButton ? ' fill' : ''}`}>
            <div
                className="button_close"
                onClick={props.onCloseModal}
            >
                <img src={props.device === LAYOUT.MOBILE ? BlackCross : Cross} alt="arrow left" />
            </div>
        </div>
    );

    const ButtonPrevious = () => {
        if (!props.previous_link) return null;
        return (
            <div className="previous-button">
                <div
                    className="button_previous"
                    onClick={props.previous_link}
                >
                    <img src={BackArrow} alt="arrow left" />
                </div>
            </div>
        );
    };

    const actions = props.actionsRenderer && props.actionsRenderer();
    const detail = props.detailRenderer && props.detailRenderer();

    return (
        <div className="content-with-header">
            <div className={`header-modal ${props.device} ${props.subtitle ? 'sub' : ''}`}>
                <div className="header-modal-left">
                    <div className="header-modal-title-holder">
                        <div className={`header-modal-title ${props.subtitle ? 'sub' : ''}`}>
                            <ButtonPrevious />
                            <span>{ props.title }</span>
                            {
                                props.subtitle
                                    && (
                                        <div className="header-modal-subtitle">
                                            <span className="glyphicon glyphicon-chevron-right" />
                                            <span>{ props.subtitle }</span>
                                        </div>
                                    )
                            }
                        </div>
                        <ButtonClose />
                    </div>
                    {
                        detail
                            && (
                                <>
                                    <div className="separator vertical" />
                                    { detail }
                                </>
                            )
                    }
                </div>
                <div className="header-modal-right">
                    { actions }
                    <ButtonClose />
                </div>
            </div>
            {
                props.children
            }
        </div>
    );
};

const HeaderModal = (props) => (
    <MultiDeviceLayout>
        <ModalHeaderComponent {...props} />
    </MultiDeviceLayout>
);

export default HeaderModal;
