import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import components from 'components';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { usersSelectors } from 'railfleet_state/ducks/users';
import { restrictionsOperations, restrictionsSelectors } from 'railfleet_state/ducks/restrictions';
import { uxOperations } from 'railfleet_state/ducks/ux';
import './RestrictionCardList.scss';

const { MultiDeviceLayout } = components.app;
const { RestrictionCard } = components.display;

const RestrictionCardListComponent = (props) => {
    const [_data, setData] = useState([]);
    useEffect(() => {
        if (props.asset_id) {
            props.fetchRestrictionsForAsset(props.asset_id);
        }
    }, []);

    useEffect(() => {
        const all_restrictions = props.restrictions || props.all_restrictions;
        const restrictions = [];
        if (all_restrictions) {
            for (const restriction of all_restrictions) {
                if (restriction.state === props.restriction_state) restrictions.push(restriction);
            }
        }
        setData(restrictions);
    }, [props.restrictions, props.all_restrictions]);

    const ret = _data.map((elm) => {
        const restriction_type_id = elm.restriction_type;
        const restriction_type = restriction_type_id && props.restriction_type && props.restriction_type[restriction_type_id];
        const restriction_type_name = restriction_type && restriction_type.name;
        const date_started = moment(elm.date_started).format('DD/MM/YYYY HH:mm');
        return (
            <RestrictionCard
                {...props}
                key={elm.id}
                type={restriction_type_name || '-'}
                date={date_started}
                content={elm.comment}
            />
        );
    });

    return (
        <div className="restriction-card-list">
            { ret }
        </div>
    );
};

const mapStateToProps = (state, props) => {
    if (props.loginless) return props.mapStateToProps(state, props);
    const { asset_id } = props;
    const asset = asset_id && assetsSelectors.getAssetInfos(state, asset_id);
    const restrictions_selector = restrictionsSelectors.makeRestrictionsListForAsset();
    const restrictions = restrictions_selector(state, asset_id);
    const users = usersSelectors.getUsers(state);
    const getMaintenanceSettings = assetsSelectors.makeMaintenanceSettingsForAsset();
    const maintenance_settings = getMaintenanceSettings(state, asset_id);
    const restriction_type = maintenance_settings && maintenance_settings.restriction_type;
    return {
        asset,
        restrictions,
        users,
        restriction_type,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    if (props.loginless) return props.mapDispatchToProps(dispatch, props);
    return ({
        fetchRestrictionsForAsset: (asset_id) => (
            dispatch(restrictionsOperations.fetchForAsset(asset_id))
        ),
        setPushInfos: (push_infos, from = null) => dispatch(uxOperations.setPushInfos(push_infos, from)),
    });
};

const RestrictionCardList = (props) => (
    <MultiDeviceLayout>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <RestrictionCardListComponent
            {...props}
        />
    </MultiDeviceLayout>
);

RestrictionCardList.defaultProps = {
    restriction_state: 'open',
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionCardList);
