import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { uiSelectors } from 'railfleet_state/ducks/ui';

import './navbar.css';
import intercomLogo from './img/intercom_logo.svg';
import UserMenuButton from './user_menu_button';
import MainMenuButton from './main_menu_button';
import BrandingBar from './branding';
import useCurrentPage from '../../hooks/useCurrentPage';
import { usersSelectors } from '../../../../state/ducks/users';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

const NavBar = ({
    logout_url, /* home_url, railnova_logo, */ user, product, pages,
}) => {
    const [notification_count, setNotification_count] = useState(false);
    const page = useCurrentPage(pages);

    const initializeMoment = () => {
        // as the navbar is a component that is always loaded, on every page,
        // intialisation code come here. The goal is to have it in an HOC
        // or in the redux initialisation.
        if (user && user.is_authenticated) {
            const { language, timezone } = user;
            moment.locale(language);
            moment.tz.setDefault(timezone);
        }
    };

    useEffect(() => {
        initializeMoment();
        if (window.Intercom !== undefined) {
            window.Intercom('onUnreadCountChange', (count) => setNotification_count(count));
        }
    });
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const isMobile = windowDimensions.width <= 767.98;
    return (
        <div className="rn-navbar ">
            <MainMenuButton
                user={user}
                product={product}
                page={page}
                logout_url={logout_url}
                isMobile={isMobile}
            />
            <div className="spacer" />
            <a className="help-center" href="http://help.railnova.eu" alt="help.railnova.eu">{i18n.t('Help center')}</a>
            <a className="intercom_messenger_toggle" href="#">
                <div className="logo_intercom">
                    <div className="circle">
                        <img src={intercomLogo} alt="intercom logo" />
                        {
                            notification_count > 0
                            && <span className="notification_count">{notification_count}</span>
                        }
                    </div>
                </div>
                <span className="text_intercom" id="underline">{i18n.t('Contact us')}</span>
            </a>
            <UserMenuButton
                user={user}
                logout_url={logout_url}
            />
            {!isMobile && <BrandingBar />}
        </div>
    );
};

NavBar.defaultProps = {
    home_url: '/home/',
    logout_url: '/accounts/logout/',
};

const mapNavbarStateToProps = (state) => ({
    user: state.users.me,
    product: uiSelectors.getCurrentProduct(state),
    pages: usersSelectors.getCurrentUserPage(state),
});

export default connect(mapNavbarStateToProps)(NavBar);
