import React, { useState, useEffect, useContext } from 'react';
import i18n from 'i18next';
import './RtsForm.scss';
import MultiDeviceLayout, { ContextDevice, withMultiDeviceLayout } from '../../../components/app/MultiDeviceLayout';
import FormManager from '../../../components/forms/RailForms/FormManager';
import FormTextInput from '../../../components/forms/RailForms/formTextInput/FormTextInput';
import FormCheckbox from '../../../components/forms/RailForms/formCheckbox/FormCheckbox';
import CutForm from '../../../components/forms/RailForms/cutForm/CutForm';
import RestrictionList from '../../lists/restrictionList/RestrictionList';
import { THEMES } from '../../../components/tables/table/Table';

const RtsFormComponent = (props) => {
    const { device } = useContext(ContextDevice);
    const [_locked, setLocked] = useState(false);

    const formatData = (data) => {
        const formated_data = {
            rts_question_derogation: data.rts_question_derogation === 'true',
            rts_question_return: data.rts_question_return === 'true',
            rts_question_derogation_comment: data.rts_question_derogation_comment,
            rts_question_return_comment: data.rts_question_return_comment,
        };
        return formated_data;
    };

    const onSubmit = (arg) => {
        const data = formatData(arg);
        if (props.rtsCallback) {
            props.rtsCallback({
                intervention: props.intervention,
                date: props.date_action,
                rts: data,
            });
        }
    };

    const defaultValues = {
        rts_question_derogation: false,
        rts_question_derogation_comment: null,
        rts_question_return: true,
        rts_question_return_comment: null,
    };

    return (
        <div className={`rts form-view ${device}`}>
            <div className="body-rts form-main">
                <FormManager
                    onSubmit={onSubmit}
                    textSubmitButton={props.textSubmit}
                    onCancel={props.onCancel}
                    disabled={_locked || props.disabled}
                >
                    <div className="rts-part">
                        <CutForm
                            device={device}
                        >
                            <FormCheckbox
                                id="rts_question_return"
                                defaultValue={(defaultValues && defaultValues.rts_question_return ? 'true' : 'false') || 'false'}
                                label={i18n.t('Is your opinion in favour of returning asset into operation?')}
                                values={[
                                    {
                                        id: 'true',
                                        name: i18n.t('Yes'),
                                    },
                                    {
                                        id: 'false',
                                        name: i18n.t('No'),
                                    },
                                ]}
                                trigger="false"
                                labelizer={(item) => item.name}
                                required
                            />

                            <FormTextInput
                                id="rts_question_return_comment"
                                defaultValue={defaultValues && defaultValues.rts_question_return_comment}
                                label={i18n.t('Comment')}
                                large
                                lockSize
                            />
                        </CutForm>
                        <div className="restrictions-list-opened">
                            <span>{i18n.t('Open restrictions')}</span>
                            <RestrictionList
                                asset_id={props.asset_id}
                                restriction_state="open"
                                fields={[
                                    'date_started',
                                    'restriction_type',
                                    'comment',
                                ]}
                                theme={THEMES.INLINE}
                                noPlaceholder
                            />
                        </div>
                    </div>
                </FormManager>
            </div>
        </div>
    );
};

const RtsForm = (props) => (
    <MultiDeviceLayout>
        <RtsFormComponent
            {...props}
        />
    </MultiDeviceLayout>
);

export default withMultiDeviceLayout(RtsForm);
