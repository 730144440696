/**
 * Created by lpostula on 11/05/17.
 */
import types from '../companies_settings/types';
import { createReducer } from '../../utils';

/* State shape
{
    {company_id: maintenance_settings},
}
 */

const initialState = {};

const listReducer = createReducer(initialState)({
    [types.FETCH_LIST_COMPLETED]: (state, action) => {
        const new_state = { ...state };
        if (!action?.payload) return new_state;
        for (const company of action.payload) {
            const new_settings_raw = company.maintenance_settings;
            const new_settings = {};
            for (const key of Object.keys(new_settings_raw)) {
                const setting = {};
                for (const setting_raw of new_settings_raw[key]) {
                    setting[setting_raw.id] = setting_raw;
                }
                new_settings[key] = setting;
            }
            new_state[company.id] = new_settings;
        }
        return new_state;
    },
});

export default listReducer;
