import React, { useState } from 'react';
import i18n from 'i18next';
import './VerticalMultiOptionFilter.scss';
import PropTypes from 'prop-types';
import WithPopper from '../../app/WithPopper';
import info_dark from './info-dark.svg';
import info_on_hover from './info-on-hover.svg';
import no_description_option_dark from './no-description-option-dark.svg';
import no_description_option_on_hover from './no-description-option-on-hover.svg';
import { getParamsFromHash } from '../../../../../../railgenius/data_inspector/static/utils';

export default function VerticalOptionFilter(props) {
    const f = props.onOptionChange;
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    let unit_of_measure = null;
    if (props.option.unit_of_measure && props.option.unit_of_measure !== 'no_unit') {
        unit_of_measure = (
            <div className="unit-measure">
                {`${i18n.t('Unit')}: ${props.option.unit_of_measure}`}
            </div>
        );
    }
    const assetInfoPopper = (
        <WithPopper
            visible={isPopperOpen}
            placement="right-start"
        >
            <div />
            <div className="info-content">
                <div>
                    {i18n.t(props.option.description)}
                    <br />
                    {unit_of_measure}
                </div>
            </div>
        </WithPopper>
    );
    let descriptionOption = null;
    if (props.requestDescription) {
        const assetAddInfoPopper = (
            <WithPopper
                visible={isPopperOpen}
                placement="right-start"
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [-60, 2],
                        },
                    }]}
            >
                <div />
                <div
                    className="no-description-popper"
                    onMouseEnter={() => setIsPopperOpen(true)}
                    onMouseLeave={() => setIsPopperOpen(false)}
                >
                    {i18n.t('No description for this symbol yet.')}
                    <br />
                    <br />
                    <div
                        className="request-description"
                        onClick={
                            () => {
                                const symbolTitle = `${props.option.key_name} (asset:${props.asset_title})`;
                                const request_documentation_text = i18n.t('Your request for description of {{symbolTitle}} has been sent to our support team. We will respond to you with a description and update the documentation.', { symbolTitle });
                                window.intercomSettings.request_documentation_text = request_documentation_text;
                                Intercom('update');
                            }
                        }
                    >
                        {i18n.t('Ask us for a description')}
                    </div>
                </div>
            </WithPopper>
        );
        descriptionOption = (
            <div className="grid-child option-info">
                <img
                    src={isPopperOpen ? no_description_option_on_hover : no_description_option_dark}
                    alt="optionInfo"
                    onMouseEnter={() => setIsPopperOpen(true)}
                    onMouseLeave={() => setIsPopperOpen(false)}
                />
                <div className="option-info-popper">
                    &nbsp;
                    &nbsp;
                    {assetAddInfoPopper}
                </div>
            </div>
        );
    }
    if (props.showDescription && props.option.description) {
        descriptionOption = (
            <div className="grid-child option-info">
                <img
                    src={isPopperOpen ? info_on_hover : info_dark}
                    alt="optionInfo"
                    onMouseEnter={() => setIsPopperOpen(true)}
                    onMouseLeave={() => setIsPopperOpen(false)}
                />
                <div className="option-info-popper">
                    &nbsp;
                    &nbsp;
                    {assetInfoPopper}
                </div>
            </div>
        );
    }
    if (props.option.label === '---') {
        descriptionOption = null;
    }
    return (
        <div className="grid-element-vertical-option" key={props.option.key}>
            <div className="grid-child option">
                <div className="option-checkbox-wrapper">
                    <input
                        type="checkbox"
                        checked={props.isOptionSelected(props.option.key)}
                        onChange={(e) => f(props.option, e.target.checked)}
                        className="option-checkbox"
                        id={`vmof-${props.option}`}
                    />
                    <label
                        htmlFor={`vmof-${props.option}`}
                        style={props.optioncolor ? { color: props.option.color } : {}}
                        className="option-label"
                    >
                        {props.option.label}
                    </label>
                </div>
            </div>
            {
                descriptionOption
            }
        </div>
    );
}

VerticalOptionFilter.propTypes = {
    onOptionChange: PropTypes.func,
    isOptionSelected: PropTypes.func,
};
