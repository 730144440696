import React, { useState } from 'react';
import i18n from 'i18next';
import './RestrictionCard.scss';
import AutoTranslatedDisplay from '../autoTranslatedDisplay/AutoTranslatedDisplay';
import MultiDeviceLayout from '../../app/MultiDeviceLayout';

const Bubble = () => (
    <div className="auto-translate-bubble">
        <span>A</span>
        <div className="auto-translate-bubble-arrow" />
    </div>
);

const RestrictionCardComponent = (props) => {
    const [_displayTranslation, setDisplayTranslation] = useState(false);
    return (
        <div className={`restriction-card-root ${props.device}`}>
            <div className="restriction-card">
                <div className="restriction-card-content">
                    <div className="restriction-card-left">
                        <div className="restriction-card-type">
                            <div>{`${i18n.t('Restriction')}`}</div>
                            <div>{`${props.type.toUpperCase()}`}</div>
                        </div>
                        <div className="restriction-card-date">{props.date}</div>
                    </div>
                    <div className="restriction-card-right">
                        <div className="restriction-card-right-sub-left">
                            <div className="restriction-card-content">{props.content}</div>
                        </div>
                        <div className="restriction-card-right-sub-right">
                            <div
                                className="restriction-card-auto-translate"
                                onClick={() => setDisplayTranslation(!_displayTranslation)}
                            >
                                <Bubble />
                                <span className="auto-translated-to">{i18n.t('Auto Translate')}</span>
                                <span className={`glyphicon ${_displayTranslation ? 'glyphicon-chevron-up' : 'glyphicon-chevron-down'}`} />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    _displayTranslation
                        && (
                            <AutoTranslatedDisplay
                                {...props}
                                data={props.content}
                                readOnly
                                error={props.error}
                            />
                        )
                }
            </div>
        </div>
    );
};

const RestrictionCard = (props) => (
    <MultiDeviceLayout>
        <RestrictionCardComponent {...props} />
    </MultiDeviceLayout>
);

export default RestrictionCard;
