import React, { useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { useFormContext } from 'react-hook-form';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { usersSelectors } from 'railfleet_state/ducks/users';
import { restrictionsOperations } from 'railfleet_state/ducks/restrictions';
import './FormRestrictions.scss';
import HomologationForm from 'compounds/forms/homologationForm/HomologationForm';
import FormCheckbox from 'components/forms/RailForms/formCheckbox/FormCheckbox';
import InputsWrapper from 'components/forms/RailForms/InputsWrapper';
import MultiDeviceLayout, { LAYOUT } from '../../../app/MultiDeviceLayout';
import FormTextInput from '../formTextInput/FormTextInput';
import { THEMES } from '../../../filters/selectFilter/SelectFilter';
import CutForm from '../cutForm/CutForm';
import PlusTextAction from '../../../buttons/plusTextAction/PlusTextAction';
import TrashButton from '../../../buttons/trashButton/TrashButton';
import ReactSelectWrapper from '../ReactSelectWrapper';
import { StepContext } from '../step/Step';
import TextDisplay from '../../../display/textDisplay/TextDisplay';
import FormDropdown from '../formDropdown/FormDropdown';
import WithLoginless from '../../../app/withLoginless';

const RenderHomologations = (props) => (
    <InputsWrapper
        id="homologation"
        className="homologation"
    >
        <FormCheckbox
            id={`impact_${props.index}`}
            defaultValue={(props.impact ? 'true' : 'false') || 'true'}
            label={i18n.t('Does this restriction has an impact on the current aptitudes ?')}
            values={[
                {
                    id: 'true',
                    name: i18n.t('Yes'),
                },
                {
                    id: 'false',
                    name: i18n.t('No'),
                },
            ]}
            trigger="false"
            labelizer={(item) => item.name}
            required
            onChange={() => {
                props.setImpact((prevState) => {
                    const newState = { ...prevState };
                    newState[props.index] = !prevState[props.index];
                    return newState;
                });
            }}
        />
        {
            props.impact && props.impact[props.index] && (
                <HomologationForm
                    index={props.index}
                    asset_id={props.asset_id}
                    titleCountry={i18n.t('Countries (aptitudes that may be impacted)')}
                    titleOther={i18n.t('Others (aptitudes that may be impacted)')}
                    standalone
                    onChange={props.setHomologations}
                    withFlags
                    noBorder
                    impact
                    id_getter={(elm) => elm.target}
                    isReadOnly={() => false}
                />
            )
        }
    </InputsWrapper>
);

const FormRestrictionsManager = (props) => {
    const [_impact, setImpact] = useState({});
    const [_restrictions, setRestrictions] = useState([]);
    useEffect(() => {
        setRestrictions(props.restrictions);
    }, [props.restrictions]);

    useEffect(() => {
        const restriction = [];
        for (const index of Object.keys(_restrictions)) {
            const elm = _restrictions[index];
            if (elm.hide || !elm.new) continue;
            if (!elm.restriction_type && !elm.comment) continue;
            const r = {
                restriction_type: elm.restriction_type || null,
                comment: elm.comment || null,
            };
            if (!_.isEmpty(elm.homologations)) {
                if (!_.isEmpty(elm.homologations.countries)) {
                    r.impacts = Object.keys(elm.homologations.countries).map((key) => parseInt(key, 10));
                }
                if (!_.isEmpty(elm.homologations.others)) {
                    r.impacts_other = Object.keys(elm.homologations.others).map((key) => parseInt(key, 10));
                }
            }
            restriction.push(r);
        }
        props.onChange(restriction);
    }, [_restrictions]);

    const removeElm = (index) => {
        const oldRestrictions = [..._restrictions];
        oldRestrictions[index].hide = true;
        setRestrictions(oldRestrictions);
    };

    const onChangeRestrictiontype = (arg, index) => {
        if (_restrictions[index].restriction_type !== arg) {
            const oldRestrictions = [..._restrictions];
            oldRestrictions[index].restriction_type = arg;
            setRestrictions(oldRestrictions);
        }
    };
    const onChangeComment = (arg, index) => {
        if (_restrictions[index].comment !== arg) {
            const oldRestrictions = [..._restrictions];
            oldRestrictions[index].comment = arg;
            setRestrictions(oldRestrictions);
        }
    };

    const onChangeHomologation = (arg, index) => {
        if (_.isEmpty(arg) || (_.isEmpty(arg.countries) && _.isEmpty(arg.others)) || (_.isEqual(_restrictions[index].homologations, arg))) return;
        if (_restrictions[index].homologations !== arg) {
            const oldRestrictions = [..._restrictions];
            oldRestrictions[index].homologations = arg;
            setRestrictions(oldRestrictions);
        }
    };

    const renderElm = (restriction, index) => {
        const TextWidget = restriction.new ? FormTextInput : TextDisplay;
        return (
            <React.Fragment key={index}>
                {
                    restriction.new
                    && props.device === LAYOUT.MOBILE
                        && (
                            <div className="trash-container">
                                <TrashButton
                                    onClick={() => removeElm(index)}
                                />
                            </div>
                        )
                }
                <CutForm
                    device={props.device}
                    className={`restriction-elm${!restriction.new ? ' readOnly' : ''}`}
                >
                    {
                        props.restriction_type
                        && Object.keys(props.restriction_type).length > 0
                            && (
                                <FormDropdown
                                    id={`restriction_type_${index}`}
                                    defaultValue={
                                        restriction
                                    && restriction.restriction_type
                                    }
                                    label={i18n.t('Restriction type')}
                                    values={props.restriction_type}
                                    id_getter={(elm) => elm.id}
                                    labelizer={(elm) => elm.name}
                                    clearable
                                    theme={THEMES.FORM_LIGHT}
                                    onChange={(arg) => onChangeRestrictiontype(arg, index)}
                                    readOnly={!restriction.new}
                                    required
                                />
                            )
                    }
                    <TextWidget
                        id={`comment_${index}`}
                        className="large"
                        defaultValue={
                            restriction
                            && restriction.comment
                        }
                        data={
                            restriction
                            && restriction.comment
                        }
                        label={i18n.t('Restriction comment')}
                        theme={THEMES.FORM_LIGHT}
                        onChange={(arg) => onChangeComment(arg, index)}
                        required
                        large
                    >
                        {
                            restriction.new
                            && props.device !== LAYOUT.MOBILE
                                && (
                                    <TrashButton
                                        onClick={() => removeElm(index)}
                                    />
                                )
                        }
                    </TextWidget>
                </CutForm>
                {
                    props.homologation && (
                        <RenderHomologations
                            index={index}
                            impact={_impact}
                            setImpact={setImpact}
                            setHomologations={(arg) => onChangeHomologation(arg, index)}
                            {...props}
                        />
                    )
                }
            </React.Fragment>
        );
    };

    const renderFields = () => {
        const ret = [];

        for (const index of Object.keys(_restrictions)) {
            const elm = _restrictions[index];
            if (elm.hide) continue;
            ret.push(renderElm(elm, index));
        }
        return ret;
    };

    const onClickNew = () => {
        const oldRestrictions = [..._restrictions];
        oldRestrictions.push({ new: true });
        setRestrictions(oldRestrictions);
    };

    const fields = renderFields();
    return (
        <div>
            { fields }
            {
                !props.readOnly && !props.lock
                    && (
                        <PlusTextAction
                            onClick={onClickNew}
                            text={i18n.t('Add a new restriction')}
                        />
                    )
            }
        </div>
    );
};

const FormRestrictionsComponent = (props) => {
    const stepContext = useContext(StepContext);
    const { registerPreSubmit } = useFormContext();

    const preSubmit = (arg) => {
        if (!arg) return arg;
        const ret = { ...arg };
        for (const key of Object.keys(arg)) {
            if (key.indexOf('restriction_type_') !== -1) {
                delete ret[key];
            }
        }
        return ret;
    };

    useEffect(() => {
        if (stepContext && stepContext.addKey) stepContext.addKey(stepContext.step, props.id);
        props.fetchRestrictionsForAsset(props.asset_id);
        registerPreSubmit(preSubmit);
    }, []);

    const validate = (data) => {
        if (data.length === 0) return null;
        return data;
    };

    return (
        <div
            className={`form form_restrictions ${props.className ? props.className : ''} ${props.id} ${props.device}`}
            key={props.id}
        >
            <ReactSelectWrapper
                classname="restrictions"
                // eslint-disable-next-line react/no-children-prop
                children={FormRestrictionsManager}
                name={props.id}
                validate={validate}
                preSubmit={preSubmit}
                {..._.omit(props, 'children')}
            />
        </div>
    );
};

const FormRestrictions = (props) => (
    <MultiDeviceLayout>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <FormRestrictionsComponent
            {...props}
        />
    </MultiDeviceLayout>
);

const mapStateToProps = (state, props) => {
    if (props.loginless) return props.mapStateToProps(state, props);
    const { asset_id } = props;
    const asset = asset_id && assetsSelectors.getAssetInfos(state, asset_id);
    const getMaintenanceSettings = assetsSelectors.makeMaintenanceSettingsForAsset();
    const can_close = asset && usersSelectors.userHasPerm(state)('close_restriction', asset_id);
    const can_add = asset && usersSelectors.userHasPerm(state)('create_restriction', asset_id);
    const can_update = asset && usersSelectors.userHasPerm(state)('edit_restriction', asset_id);
    const can_view = asset && usersSelectors.userHasPerm(state)('view_restrictions', asset_id);

    const maintenance_settings = getMaintenanceSettings(state, asset_id);

    const restriction_type = maintenance_settings && maintenance_settings.restriction_type;

    return {
        can_close,
        can_add,
        can_update,
        can_view,
        maintenance_settings: maintenance_settings || {},
        users: state.users.all,
        user: state.users.me,
        asset: asset || {},
        restriction_type: restriction_type || {},
    };
};

const mapDispatchToProps = (dispatch, props) => {
    if (props.loginless) return props.mapDispatchToProps(dispatch, props);
    return ({
        fetchRestrictionsForAsset: (asset_id) => (
            dispatch(restrictionsOperations.fetchForAsset(asset_id))
        ),
    });
};

export default WithLoginless(connect(mapStateToProps, mapDispatchToProps)(FormRestrictions));
