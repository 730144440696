/**
 * Created by lpostula on 15/05/17.
 */
export const fetch_list_complete = (state, action) => {
    let new_state = {};
    if (!action.meta || !action.meta.long_polling) {
        // if there is a filtering in the meta of the action
        // this means that we need to discard all matching items
        // this will allow us to remove stale content like old
        // component_counters linked in the past to an asset,...
        if (action.meta && action.meta.filter_out) {
            const filter_key = action.meta.filter_out.key;
            const filter_value = action.meta.filter_out.value;
            for (const cand in state) {
                if (state[cand][filter_key] !== filter_value) {
                    // only update if it does not match the filter
                    new_state[cand] = state[cand];
                }
            }
        } else {
            new_state = { ...state };
        }
    }

    if (!action.payload) return new_state;
    const items = 'results' in action.payload ? action.payload?.results : action.payload;
    if (!items) return new_state;
    for (const item of items) {
        new_state[item.id] = item;
    }
    return new_state;
};

export const fetch_details_complete = (state, action) => {
    const { id } = action.payload;
    if (id) {
        const new_data = {};
        new_data[id] = action.payload;
        return { ...state, ...new_data };
    }
    return state;
};

// merge a recent partial update with the a previous content
export const fetch_details_partial = (state, action) => {
    const { id } = action.payload;
    if (id) {
        const old_item = state[id] || {};
        const new_data = {};
        new_data[id] = { ...old_item, ...action.payload };
        return { ...state, ...new_data };
    }
    return state;
};

export const delete_completed = (state, action) => {
    const new_state = { ...state };
    delete new_state[action.meta.id];
    return new_state;
};

export default {
    fetch_list_complete,
    fetch_details_complete,
    fetch_details_partial,
    delete_completed,
};
