import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { uxSelectors, uxOperations } from 'railfleet_state/ducks/ux';
import components from 'components';
import './edit_campaign_event.scss';
import CampaignEventForm from '../../../../railfleet/lib/compounds/forms/campaignEventForm/CampaignEventForm';

const { MultiDeviceLayout } = components.app;
const { HeaderModal } = components.headers;

const EditCampaignEventComponent = (props) => {
    const [_pushInfos, setPushInfos] = useState(null);

    useEffect(() => {
        setPushInfos(props.push_infos);
        props.setPushInfos(null, 'edit campaign');
    }, []);

    const createCallback = (event_id) => props.router.push(
        `/asset/${props.asset_id}/events/${event_id}/view`,
    );

    const onClickPrevious = () => {
        if (_pushInfos && _pushInfos.from) {
            window.location.href = _pushInfos.from;
        } else if (props.event_id) {
            props.router.push(
                `/asset/${props.asset_id}/events/${props.event_id}/view`,
            );
        } else {
            props.router.push(
                `/asset/${props.asset_id}/events/`,
            );
        }
    };

    return (
        <HeaderModal
            title={i18n.t('Campaign event')}
            subtitle={i18n.t('Edit')}
            onCloseModal={props.onCloseModal}
            previous_link={onClickPrevious}
        >
            <CampaignEventForm
                asset_id={props.asset_id}
                event_id={props.event_id}
                cancelCallback={props.cancelCallback || props.onCloseModal}
                callback={props.createCallback || createCallback}
            />
        </HeaderModal>
    );
};

const EditCampaignEvent = (props) => (
    <MultiDeviceLayout>
        <EditCampaignEventComponent {...props} />
    </MultiDeviceLayout>
);

const mapStateToProps = (state) => ({
    push_infos: uxSelectors.getPushInfos(state),
});

const mapDispatchToProps = (dispatch) => ({
    setPushInfos: (push_infos, from = null) => dispatch(uxOperations.setPushInfos(push_infos, from)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCampaignEvent);
