/**
 * Created by lpostula on 12/05/17.
 */
import {
    createSelector,
} from 'reselect';

const sort_options = function (a, b) {
    const sa = a.label.toLowerCase();
    const sb = b.label.toLowerCase();
    return -(sa < sb) || +(sa !== sb);
};

export const getMaintenanceSettings = (state) => state.maintenance_settings;
const getCurrentUserCompanyId = (state) => {
    // not null nor undefined
    if (state.users.me) {
        return state.users.me.company;
    }
    return null;
};

export const getOperationalStatusOptions = createSelector(
    [getMaintenanceSettings, getCurrentUserCompanyId],
    (maintenance_settings, user_company_id) => {
        const settings = maintenance_settings[user_company_id];
        if (!settings) {
            return [];
        }
        const options = [];
        const detailed_options = [];
        for (const k in settings.operational_status_type) {
            const s = settings.operational_status_type[k];
            options.push({
                value: s.id,
                label: s.name,
            });
            for (const dsid of s.detailed_status) {
                const ds = settings.detailed_operational_status_type[dsid];
                detailed_options.push({
                    value: ds.id,
                    label: `${s.name}: ${ds.name}`,
                });
            }
        }
        options.sort(sort_options);
        detailed_options.sort(sort_options);
        return options.concat(detailed_options);
    },
);

export const getMaintenanceSettingsForUser = createSelector(
    [getMaintenanceSettings, getCurrentUserCompanyId],
    (maintenance_settings, company_id) => maintenance_settings[company_id] || {},
);

export default {
    getMaintenanceSettings,
    getMaintenanceSettingsForUser,
    getOperationalStatusOptions,
};
