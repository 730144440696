import React from 'react';
import PropTypes from 'prop-types';

/* MapControl is not meant to be used as a Component but more as an
 * abstract datatype.
 */
class MapControl extends React.Component {
    constructor(props) {
        super(props);

        this.leafletElement = null;
    }

    componentDidMount() {
        this.leafletElement = this.createLeafletElement(this.props);
        this.context.map.addControl(this.leafletElement);
    }

    shouldComponentUpdate() {
        return false;
    }

    componentWillUnmount() {
        this.leafletElement.remove();
    }

    render() {
        return null;
    }
}
MapControl.contextTypes = {
    map: PropTypes.object,
};

export default MapControl;
