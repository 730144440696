import React, { useState, useEffect, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactSelectWrapper from '../ReactSelectWrapper';
import './FormQcm.scss';
import '../formCheckbox/FormCheckbox.scss';
import FormLabel from '../formLabel/FormLabel';
import Invalid from './Invalid.svg';
import Valid from './Valid.svg';
import { StepContext } from '../step/Step';
import TextAction from '../../../buttons/textAction/TextAction';

export const TypeRadio = {
    VALID: {
        color: '#00B380',
        img: Valid,
    },
    INVALID: {
        color: '#FF3E62',
        img: Invalid,
    },
    DEFAULT: {
        color: '#027AFF',
        img: Valid,
    },
};

const InputRenderer = (props) => (
    <>
        <input
            type="radio"
            name={props.name}
            value={props.value}
            checked={props.checked}
            onChange={props.onChange}
            disabled={props.disabled}
        />
        {
            props.typeRadio
                && (
                    <div
                        className="after-radio"
                    >
                        <div
                            className="inner-after-radio"
                            style={{
                                backgroundColor: props.typeRadio.color,
                            }}
                        />
                        <img src={props.typeRadio.img} alt="radio checked" />
                    </div>
                )
        }
    </>
);

const RowCheckboxGridRenderer = (props) => {
    const [_selected, setSelected] = useState(props.defaultValue);
    // This is to be used with the Step Manager.
    // It allow to do Forms with multiple steps.
    // We have to register each field in the Step Manager to validate fields on each step instead of
    // validating each fields at the end of the form with the submit button.
    const stepContext = useContext(StepContext);
    useEffect(() => {
        if (stepContext && stepContext.addKey) stepContext.addKey(stepContext.step, props.id);
    }, []);

    useEffect(() => {
        if (props.defaultValue) {
            setSelected(props.defaultValue);
        }
    }, []);

    useEffect(() => {
        if (props.selectedValue !== undefined) {
            setSelected(props.selectedValue);
        }
    }, [props.selectedValue]);

    useEffect(() => {
        if (props.boolean !== undefined && props.boolean !== null) {
            const ret = {};
            for (const key in _selected) {
                const elm = _selected[key];
                ret[key] = elm === props.boolean;
            }
            props.onChange(ret);
        } else {
            props.onChange(_selected);
        }
    }, [_selected]);

    const onChange = (id_data, id_elm) => {
        if (props.disabled) return;
        setSelected((prevValues) => {
            const newValues = { ...prevValues };
            newValues[id_data] = id_elm;
            return newValues;
        });
    };

    const selectAll = () => {
        const valueCompliant = props.id_getter(props.options[0]);
        const newValues = {};
        _.map(props.data, (elm) => {
            newValues[props.id_getter(elm)] = valueCompliant;
        });
        setSelected(newValues);
    };

    return (
        <table>
            <thead>
                {
                    props.selectAllText
                    && (
                        <tr>
                            {
                                _.map(props.options, (elm, index) => (
                                    <th key={`th_${index}`} />
                                ))
                            }
                            <th className="select-all-button">
                                <TextAction
                                    text={props.selectAllText}
                                    onClick={selectAll}
                                />
                            </th>
                        </tr>
                    )
                }
                <tr>
                    <th><FormLabel {...props} /></th>
                    {
                        _.map(props.options, (elm) => (
                            <th key={props.id_getter(elm)}>{props.labelizer(elm)}</th>
                        ))
                    }
                </tr>
            </thead>
            <tbody>
                {
                    _.map(props.data, (data_elm) => (
                        <tr key={props.id_getter(data_elm)}>
                            <td>
                                <div>{props.labelizer(data_elm)}</div>
                            </td>
                            {
                                _.map(props.options, (elm) => {
                                    const elm_is_selected = `${_selected && _selected[props.id_getter(data_elm)]}` === `${props.id_getter(elm)}`;
                                    return (
                                        <td key={`${props.id_getter(data_elm)}_${props.id_getter(elm)}`}>
                                            <div className="form_checkbox_elm">
                                                <InputRenderer
                                                    type="radio"
                                                    name={`${props.id}_${props.id_getter(data_elm)}`}
                                                    value={props.id_getter(elm)}
                                                    checked={elm_is_selected}
                                                    onChange={() => onChange(
                                                        props.id_getter(data_elm),
                                                        props.id_getter(elm),
                                                    )}
                                                    disabled={props.disabled}
                                                    typeRadio={elm.type || TypeRadio.DEFAULT}
                                                />
                                            </div>
                                        </td>
                                    );
                                })
                            }
                        </tr>
                    ))
                }
            </tbody>
        </table>
    );
};

const FormQcmManager = (props) => (
    <div className="qcm_holder">
        <RowCheckboxGridRenderer
            id={props.id}
            options={props.options}
            data={props.data}
            labelizer={props.labelizer}
            id_getter={props.id_getter}
            onChange={props.onChange}
            defaultValue={props.defaultValue}
            {...props}
        />
    </div>
);

const FormQcm = (props) => {
    const methods = useFormContext();
    const valueError = methods.errors && methods.errors[props.id];
    const validate = (data) => {
        if (!data || _.isEmpty(data)) return null;
        if (props.validate) {
            return props.validate(data);
        }
        return data;
    };

    return (
        <div
            className={`form form_qcm ${props.className ? props.className : ''} ${props.id} ${!props.label ? 'no-label' : ''}`}
            key={props.id}
        >
            <ReactSelectWrapper
                classname="qcm"
                // eslint-disable-next-line react/no-children-prop
                children={FormQcmManager}
                name={props.id}
                validate={validate}
                {..._.omit(props, 'children')}
            />
            {valueError && (
                <div className="form-validation-error">
                    <div className="form-validation-error-arrow" />
                    <div className="form-error-message">
                        {
                            (valueError.message)
                                ? <div>{valueError.message}</div>
                                : <div>Please enter a value</div>
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

FormQcm.defaultProps = {
    color: '#027AFF',
};

export default FormQcm;
