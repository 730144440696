/**
 * Created by lpostula on 03/07/17.
 */
import { default as actions } from './actions';

export const { fetchList } = actions;
export const { fetchDetails } = actions;
export const { fetchForAsset } = actions;

export const incomingWS = (data) => (dispatch) => {
    switch (data.type) {
    case 'update':
        dispatch(actions.updateCounterInStore(data.payload));
        break;
    default:
        break;
    }
};

export default {
    fetchList,
    fetchDetails,
    fetchForAsset,
    incomingWS,
};
