import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import components from 'components';
import { uxSelectors, uxOperations } from 'railfleet_state/ducks/ux';
import { interventionsOperations } from 'railfleet_state/ducks/interventions';
import './create_preventive_event.scss';
import PreventiveEventForm from '../../../../railfleet/lib/compounds/forms/preventiveEventForm/PreventiveEventForm';

const { MultiDeviceLayout } = components.app;
const { HeaderModal } = components.headers;

const CreatePreventiveEventComponent = (props) => {
    const [_pushInfos, setPushInfos] = useState(null);

    useEffect(() => {
        setPushInfos(props.push_infos);
        props.setPushInfos(null, 'create prventive');
    }, []);

    const createCallback = (event_id) => {
        if (_pushInfos) {
            if (_pushInfos.extras) {
                const intervention_id = _pushInfos.extras.id;
                props.addEvents(intervention_id, [event_id]);
            }
            window.location.href = _pushInfos.from;
        } else {
            props.router.push(
                `/asset/${props.asset_id}/events/${event_id}/view`,
            );
        }
    };

    const onClickPrevious = () => {
        if (_pushInfos && _pushInfos.from) {
            window.location.href = _pushInfos.from;
        } else if (props.event_id) {
            props.router.push(
                `/asset/${props.asset_id}/events/${props.event_id}/view`,
            );
        } else {
            props.router.push(
                `/asset/${props.asset_id}/events/`,
            );
        }
    };

    return (
        <HeaderModal
            title={i18n.t('Preventive event')}
            subtitle={props.event_id ? i18n.t('Edit') : i18n.t('Create')}
            onCloseModal={props.onCloseModal}
            previous_link={props.onClickPrevious || onClickPrevious}
        >
            <PreventiveEventForm
                asset_id={props.asset_id}
                event_id={props.event_id}
                poi={_pushInfos && _pushInfos.extras && _pushInfos.extras.location_id}
                cancelCallback={props.cancelCallback || props.onCloseModal}
                callback={props.createCallback || createCallback}
            />
        </HeaderModal>
    );
};

const CreatePreventiveEvent = (props) => (
    <MultiDeviceLayout>
        <CreatePreventiveEventComponent {...props} />
    </MultiDeviceLayout>
);
const mapStateToProps = (state) => ({
    push_infos: uxSelectors.getPushInfos(state),
});

const mapDispatchToProps = (dispatch) => ({
    setPushInfos: (push_infos, from = null) => dispatch(uxOperations.setPushInfos(push_infos, from)),
    addEvents: (intervention_id, ids) => dispatch(
        interventionsOperations.addEventsInIntervention(intervention_id, ids),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePreventiveEvent);
