import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import DatetimePicker from 'react-datetime';
import PropTypes from 'prop-types';
import 'react-datetime/css/react-datetime.css';
import { strToMoment } from '../utils';

import './DateInput.scss';

const DateInput = (props) => {
    const [_value, setValue] = useState(props.value);

    const validDateRange = (currentDate) => {
        let valid = true;
        valid = valid && (!props.minDate || currentDate.isSameOrAfter(moment(props.minDate), 'day'));
        valid = valid && (!props.maxDate || currentDate.isSameOrBefore(moment(props.maxDate), 'day'));
        return valid;
    };

    const renderInput = (input_props, openCalendar) => (
        <div className="ds-input-holder">
            <input
                {...input_props}
                onChange={(e) => input_props.onChange(e.target.value)}
            />
            <div className="ds-icon" onClick={openCalendar}>
                <span className="glyphicon glyphicon-triangle-bottom" />
            </div>
        </div>
    );

    const onChange = (value) => {
        // we always update the state so the input field can be refreshed
        setValue(value);

        let date = value;
        let date_and_time = false;

        if (date && typeof date === 'string') {
            // check if string in a partial format
            date = strToMoment(value, props.format, props.timezone);

            if (!date) {
                date = strToMoment(value, props.datetimeFormat, props.timezone);
                if (date) date_and_time = true;
            }
        }
        if (date) {
            if (date_and_time && props.onChangeSpread) {
                props.onChangeSpread(date);
            } else if (props.onChange) {
                props.onChange(date);
            }
        } else {
            props.onChange(null);
        }
    };

    useEffect(() => {
        if (props.value) setValue(props.value);
    }, [props.value]);

    return (
        <DatetimePicker
            dateFormat={props.format}
            renderInput={renderInput}
            inputProps={{
                disabled: props.disabled,
                size: 1,
                style: { width: `${props.format.length}ch` },
                className: 'not-form-control',
                onChange,
            }}
            isValidDate={validDateRange}
            value={_value}
            onChange={onChange}
            timeFormat={false}
            closeOnSelect
            className={`ds-main ${!props.valid ? 'has-error' : ''}`}
        />
    );
};

DateInput.defaultProps = {
    value: null,
    format: 'DD/MM/YYYY',
    datetimeFormat: 'DD/MM/YYYY HH:mm',
    timezone: (window.user_info && window.user_info.timezone) || 'Europe/Brussels',
};

DateInput.propTypes = {
    value: PropTypes.instanceOf(moment),
    format: PropTypes.string,
    datetimeFormat: PropTypes.string,
    timezone: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onChangeSpread: PropTypes.func,
};

export default DateInput;
