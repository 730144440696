import React, { useEffect, useState } from 'react';
import useFetch from 'components/hooks/useFetch';
import useArrayToDict from 'components/hooks/useArrayToDict';
import HomologationContext from 'compounds/pages/assetView/HomologationContext/homologationContext';
import usePerm from 'components/hooks/usePerm';

const HomologationContextManager = (props) => {
    if (props.loginless) return props.children;
    const { fetchAll } = useFetch();
    const [_countryList, setCountryList] = useState(null);
    const [_otherList, setOtherList] = useState(null);
    const [_homologations, setHomologations] = useState(null);
    const countries = useArrayToDict(_countryList, 'id');
    const others = useArrayToDict(_otherList, 'id');
    const homologations = useArrayToDict(_homologations, 'id');
    const hasPerm = usePerm();

    const can_allievate = (asset_id) => hasPerm('homologation_aptitude_set_true', asset_id);
    const can_aggravate = (asset_id) => hasPerm('homologation_aptitude_set_false', asset_id);

    const fetchData = () => {
        fetchAll('/api/homologation/country/').then((d) => {
            if (d) {
                setCountryList(d);
            } else {
                setCountryList(null);
            }
        });
        fetchAll('/api/homologation/other/').then((d) => {
            if (d) {
                setOtherList(d);
            } else {
                setOtherList(null);
            }
        });
        fetchAll('/api/homologation/asset/').then((d) => {
            if (d) {
                setHomologations(d);
            } else {
                setHomologations(null);
            }
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const contextValues = {
        data: {
            countries,
            others,
            homologations,
        },
        methods: {
            setCountryList,
            setOtherList,
            setHomologations,
            refreshData: fetchData,
            can_allievate,
            can_aggravate,
        },
    };
    return (
        <HomologationContext.Provider value={contextValues}>
            {props.children}
        </HomologationContext.Provider>
    );
};

export default HomologationContextManager;
