import React from 'react';
import PropTypes from 'prop-types';
import './VerticalMultiOptionFilter.scss';
import VerticalOptionFilter from './VerticalOptionFilter';

export default function VerticalMultiOptionFilter(props) {
    const options = props.options.map((option) => <VerticalOptionFilter {...props} option={option} />);
    return (
        <div
            className={`vertical-multi-option-select ${props.disabled ? 'disabled' : ''} ${props.isDataRetentionReadable ? '' : 'data-retention-not-readable'}`}
        >
            {options}
        </div>
    );
}

VerticalMultiOptionFilter.defaultProps = {
    disabled: false,
};

VerticalMultiOptionFilter.propTypes = {
    options: PropTypes.array,
    disabled: PropTypes.bool,
};
