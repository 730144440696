import usersTypes from '../users/types';
import companiesTypes from '../companies/types';
import companiesSettingsTypes from '../companies_settings/types';
import labelsTypes from '../labels/types';
import labelCategoriesTypes from '../label_categories/types';
import assetClassesTypes from '../asset_classes/types';
import assetsTypes from '../assets/types';
import logCategoriesTypes from '../log_categories/types';
import logEntriesTypes from '../logs/types';
import maintenanceEventsTypes from '../maintenance_events/types';
import delegationsTypes from '../delegations/types';
import operationsTypes from '../operations/types';
import operationGroupsTypes from '../operation_groups/types';
import countersTypes from '../counters/types';
import maintenancePlansTypes from '../maintenance_plans/types';
import dataConfigTypes from '../data_configs/types';
import monitoringRulesTypes from '../monitoring_rules/types';
import interventionTypesTypes from '../intervention_types/types';
import faultCategoriesTypes from '../fault_categories/types';
import componentStructuresTypes from '../component_structures/types';

// The loading reducer helps tracks which action are pending.
// By default it fetch list fetching wether it is with a normal or a long polling
// The expected behaviour when you need to track the loading of an action
// is to provide in the `loading` object below a key and the types module for it.
// So let's say that you want to fetch the ducks `foobar`, you will add to the
// `loading` object `foobar: foobarTypes`.
//
// This presuppose that in foobarTypes we will find these two actions:
// - foobarTypes.FETCH_LIST_REQUEST
// - foobarTypes.FETCH_LIST_COMPLETE
//
// If you don't want to track these two default action, you can instead of
// a type module object, provide an array with TWO actions (the request and the
// complete of your choice) (see assets_infos_loading)

const loading = {
    users_loading: usersTypes,
    companies_loading: companiesTypes,
    companies_settings_loading: companiesSettingsTypes,
    labels_loading: labelsTypes,
    label_categories_loading: labelCategoriesTypes,
    asset_classes_loading: assetClassesTypes,
    assets_infos_loading: [
        assetsTypes.FETCH_LIST_INFOS_REQUEST, assetsTypes.FETCH_LIST_INFOS_COMPLETED,
    ],
    assets_live_data_loading: [
        assetsTypes.FETCH_LIST_LIVE_DATA_REQUEST, assetsTypes.FETCH_LIST_LIVE_DATA_COMPLETED,
    ],
    assets_alerts_loading: [
        assetsTypes.FETCH_LIST_ALERTS_REQUEST, assetsTypes.FETCH_LIST_ALERTS_COMPLETED,
    ],
    assets_operationals_loading: [
        assetsTypes.FETCH_LIST_OPERATIONALS_REQUEST, assetsTypes.FETCH_LIST_OPERATIONALS_COMPLETED,
    ],
    assets_labels_loading: [
        assetsTypes.FETCH_LIST_LABELS_REQUEST, assetsTypes.FETCH_LIST_LABELS_COMPLETED,
    ],

    log_categories_loading: logCategoriesTypes,
    log_entries_loading: logEntriesTypes,
    maintenance_events_loading: maintenanceEventsTypes,
    delegations_loading: delegationsTypes,
    operations_loading: operationsTypes,
    operation_groups_loading: operationGroupsTypes,
    counters_loading: countersTypes,
    maintenance_plans_loading: maintenancePlansTypes,
    data_configs_loading: dataConfigTypes,
    monitoring_rules_loading: monitoringRulesTypes,
    intervention_types_loading: interventionTypesTypes,
    fault_categories_loading: faultCategoriesTypes,
    component_structures_loading: componentStructuresTypes,
};

export default {
    loading,
};
