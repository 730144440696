import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { locationsOperations, locationsSelectors } from 'railfleet_state/ducks/locations/';
import ReactSelectWrapper from '../ReactSelectWrapper';
import SelectLocationFilter from '../../../filters/selectLocationFilter/SelectLocationFilter';
import { StepContext } from '../step/Step';
import FormLabel from '../formLabel/FormLabel';
import WithLoginless from '../../../app/withLoginless';

const FormLocationDropdown = (props) => {
    const [_locations, setLocation] = useState([]);
    const [_suggested, setSuggested] = useState(null);
    const { errors } = useFormContext();
    const valueError = errors && errors[props.id];

    // This is to be used with the Step Manager.
    // It allow to do Forms with multiple steps.
    // We have to register each field in the Step Manager to validate fields on each step instead of
    // validating each fields at the end of the form with the submit button.
    const stepContext = useContext(StepContext);
    useEffect(() => {
        if (stepContext && stepContext.addKey) stepContext.addKey(stepContext.step, props.id);
    }, []);

    const validate = (data) => {
        if (!data || (Array.isArray(data) && data.length === 0)) return null;
        return data;
    };

    useEffect(() => {
        if (props.defaultValue) {
            if (props.multi) {
                for (const poi of props.defaultValue) {
                    props.fetchLocation(poi);
                }
            } else {
                props.fetchLocation(props.defaultValue);
            }
        }
    }, [props.defaultValue]);

    useEffect(() => {
        let mounted = true;
        if (props.asset_id && props.date && props.autoFilled && !props.defaultValue) {
            let date;
            if (props.date instanceof moment) {
                date = props.date.toISOString();
            } else {
                date = moment(props.date).toISOString();
            }
            props.fetchLocationByAssetByDate(props.asset_id, date, (arg) => {
                if (mounted && arg) {
                    props.fetchLocation(arg).then((d) => {
                        if (mounted && d && d.payload) {
                            const suggested_poi = {
                                value: d.payload.id,
                                label: d.payload.name,
                                poi: d.payload,
                            };
                            setSuggested(suggested_poi);
                        }
                    });
                }
            });
        }
        // eslint-disable-next-line no-return-assign
        return () => mounted = false;
    }, [props.asset_id, props.date, props.autoFilled]);

    useEffect(() => {
        const locations = [];
        if (props.locations) {
            for (const poi of props.locations) {
                locations.push({
                    value: poi.id,
                    label: poi.name,
                    poi,
                });
            }
        }
        setLocation(locations);
    }, [props.locations]);

    return (
        <div className={`form form_location_dropdown ${props.className} ${props.id} ${!props.label ? 'no-label' : ''}`} key={props.id}>
            <FormLabel {...props} />
            <ReactSelectWrapper
                // eslint-disable-next-line react/no-children-prop
                children={SelectLocationFilter}
                name={props.id}
                validate={validate}
                defaultLocation={_locations}
                suggested={_suggested}
                {...props}
            />
            {valueError && (
                <div className="form-validation-error">
                    <div className="form-validation-error-arrow" />
                    <div className="form-error-message">
                        {
                            (valueError.message)
                                ? <div>{valueError.message}</div>
                                : <div>Please enter a value</div>
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state, props) => {
    if (props.loginless) return props.mapStateToProps(state, props);
    const locations = locationsSelectors.getLocationsByIds(state, props.multi
        ? props.defaultValue
        : [props.defaultValue]);
    return {
        locations,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    if (props.loginless) return props.mapDispatchToProps(dispatch, props);
    return ({
        fetchLocation: (id) => dispatch(
            locationsOperations.fetchDetails(id),
        ),
        fetchLocationByAssetByDate: (asset, date, callback = null) => dispatch(
            locationsOperations.fetchLocationByAssetByDate(asset, date, callback),
        ),
    });
};

export default WithLoginless(connect(mapStateToProps, mapDispatchToProps)(FormLocationDropdown));
