import React, { Component } from 'react';
import PropTypes from 'prop-types';

class VisibilityToggleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: props.force_open,
        };
        this.toggleDisplay = this.toggleDisplay.bind(this);
    }

    toggleDisplay() {
        const new_toggle_state = !this.state.display;
        this.setState({ display: new_toggle_state });
        this.props.toggle_callback(new_toggle_state);
    }

    render() {
        if (!this.state.display) {
            return (
                <div className="form-actions text-right">
                    <button
                        className={`btn btn-${this.props.toggle_color}`}
                        onClick={this.toggleDisplay}
                    >
                        {this.props.toggle_text}
                    </button>
                </div>
            );
        }
        return this.props.children;
    }
}

VisibilityToggleComponent.propTypes = {
    force_open: PropTypes.bool,
    toggle_color: PropTypes.string,
    toggle_text: PropTypes.string.isRequired,
    toggle_callback: PropTypes.func,
};

VisibilityToggleComponent.defaultProps = {
    force_open: false,
    toggle_color: 'danger',
    toggle_text: '',
    toggle_callback: () => {},
};

export default VisibilityToggleComponent;
