import { useState, useEffect } from 'react';
import memoize from 'memoize-one';
import fn_array_to_dict from '../../utils/array_of_objects_to_object';

const useArrayToDict = (array, key = 'id') => {
    const array_to_dict = memoize(fn_array_to_dict);
    const [dict, setDict] = useState({});

    useEffect(() => {
        if (array && array.length > 0) {
            setDict(array_to_dict(array, key));
        } else {
            setDict({});
        }
    }, [array]);
    return dict;
};

export default useArrayToDict;
