/**
 * Created by gknuts on 28/08/18.
 */
import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import './BubbleListSelector.scss';

const BubbleListSelector = (props) => (
    <div className="bubble-wrapper">
        <div className={`select-control ${props.active ? 'active' : ''} ${props.disabled ? 'disabled' : ''}`}>
            <div className="bubble-left">
                <div className="bubble-label">{props.inline_label}</div>
                <div className="select-multi-value-wrapper">
                    {props.values.length > 0
                        ? props.values.map((elm) => (
                            <div className="select-value" key={props.id_getter(elm)}>
                                <span className="select-value-icon" onClick={(e) => { props.onChange(props.id_getter(elm)); e.stopPropagation(); }}>x</span>
                                <span className="select-value-label">{props.labelizer(elm)}</span>
                            </div>
                        )) : (
                            <span className="select-placeholder">
                                {i18n.t('Add a filter')}
                                ...
                            </span>
                        )}
                </div>
            </div>
            <div className="separator" />
            <div className="indicator">
                <span className="glyphicon glyphicon-filter" />
            </div>
        </div>
    </div>
);

BubbleListSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
};

BubbleListSelector.defaultProps = {
    values: [],
    labelizer(item) { return item.label; },
    id_getter(item) { return item.id; },
};

export default BubbleListSelector;
