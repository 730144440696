import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import './d3.scss';
import TimeLine from './timeline';

const DAYTIME = 60 * 60 * 24 * 1000;

class D3TSRenderer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            future: false,
        };
        this.loop = this.loop.bind(this);
        this.zoomChangeCallback = this.zoomChangeCallback.bind(this);
        this.chart = React.createRef();
    }

    componentWillUnmount() {
        this.props.chart.destroy();
    }

    componentDidMount() {
        const el = this.chart.current;
        this.props.chart.create(
            el,
            { zoomChangeCallback: this.zoomChangeCallback, ...this.props.custom_props },
            this.props.data,
            this.props.id,
        );
        if (this.props.data) {
            this.props.chart.update(el, this.props.data, this.props.id);
        }
        window.clearTimeout(this.timeoutID);
        this.timeoutID = setTimeout(this.loop, 500);
    }

    loop() {
        // @props.chart.pZoom(500)
        this.timeoutID = setTimeout(this.loop, 500);
    }

    zoomChangeCallback(min_date, max_date) {
        const future = new Date(max_date).getTime() < new Date().getTime();
        if (future !== this.state.future) {
            this.setState({ future });
        }
        this.props.zoomChangeCallBack(min_date, max_date);
    }

    shouldComponentUpdate(props) {
        return props.data !== this.props.data;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((this.props.data != null ? this.props.data.length : null)
            !== (nextProps.data != null ? nextProps.data.length : null)) {
            const el = this.chart.current;
            nextProps.chart.destroy(this.props.id);
            nextProps.chart.create(
                el,
                {
                    zoomChangeCallback: this.zoomChangeCallback,
                    ...nextProps.custom_props,
                },
                nextProps.data,
                nextProps.id,
            );
        }
    }

    componentDidUpdate() {
        this.props.chart.update(this.props.data);
    }

    render() {
        return (
            <div className="timeline">
                <div className="shortcut-buttons">
                    <button className="zoom-in" onClick={() => this.props.chart.zoomIn()}>
                        <span className="glyphicon glyphicon-plus" />
                    </button>
                    <button className="zoom-out" onClick={() => this.props.chart.zoomOut()}>
                        <span className="glyphicon glyphicon-minus" />
                    </button>
                    <button
                        className="center-24h"
                        onClick={() => this.props.chart.navigateTo(
                            new Date().getTime() - DAYTIME,
                            new Date().getTime() + DAYTIME,
                        )}
                    >
                        <span>
                            { `${i18n.t('Now')} +/- 24${i18n.t('hour_short_notation')}` }
                        </span>
                    </button>
                    <button
                        className="move-24h"
                        onClick={() => {
                            this.props.chart.navigateTo(
                                new Date().getTime(),
                                new Date().getTime() + DAYTIME,
                            );
                            this.props.chart.navigateTime(false);
                        }}
                    >
                        <span>
                            { `${i18n.t('Now')} +24${i18n.t('hour_short_notation')}` }
                        </span>
                    </button>
                    <button
                        className="move-7d"
                        onClick={() => {
                            this.props.chart.navigateTo(
                                new Date().getTime(),
                                new Date().getTime() + (DAYTIME * 7),
                            );
                            this.props.chart.navigateTime(false);
                        }}
                    >
                        <span>
                            { `${i18n.t('Now')} +7${i18n.t('day_short_notation')}` }
                        </span>
                    </button>
                </div>
                <div className="scroll-buttons">
                    <button
                        className="scroll-earlier"
                        onClick={() => this.props.chart.navigateTime(false)}
                    >
                        <span className="glyphicon glyphicon-chevron-left" />
                    </button>
                    <button
                        className="scroll-forward"
                        onClick={() => this.props.chart.navigateTime(true)}
                    >
                        <span className="glyphicon glyphicon-chevron-right" />
                    </button>
                    <button className="scroll-now" onClick={() => this.props.chart.resetTime()}>
                        <span className={`glyphicon glyphicon-step-${this.state.future ? 'backward' : 'forward'}`} />
                    </button>
                </div>
                <div
                    id={this.props.id}
                    className={`chart d3_holder_${this.props.id}`}
                    ref={this.chart}
                />
            </div>
        );
    }
}

D3TSRenderer.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value_mapping: PropTypes.objectOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired,
        })).isRequired,
        data: PropTypes.arrayOf(PropTypes.shape({
            from: PropTypes.string, // ISO String
            to: PropTypes.string, // ISO String
            at: PropTypes.string, // ISO String
            type: PropTypes.oneOf(['POINT', 'INTERVAL']).isRequired,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            extra: PropTypes.string,
            title: PropTypes.string,
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        })).isRequired,
    })),
    chart: PropTypes.any,
    zoomChangeCallBack: PropTypes.func,
};

D3TSRenderer.defaultProps = {
    data: {},
    width: 400,
    height: 170,
    id: 'd3_renderer',
    chart: new TimeLine(),
    custom_props: {},
    zoomChangeCallBack: () => {},
};

export default {
    D3TSRenderer,
};
