import React from 'react';
import PropTypes from 'prop-types';

import 'leaflet.gridlayer.googlemutant';
import 'mapbox-gl-leaflet';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import './TileLayers.css';

/* A wrapper for the different TileLayer you can find on a map.
 */

const RailnovaLayers = L.Control.Layers.extend({
    _addLayer(layer, name, overlay) {
        if (this._map) {
            layer.on('add remove', this._onLayerChange, this);
        }

        this._layers.push({
            layer: layer.layer,
            traffic: layer.traffic,
            name,
            overlay,
        });
        if (layer.traffic) {
            this._layers.push({
                layer: layer.traffic,
                overlay: false,
                traffic: false,
                hide: true,
                name,
            });
        }

        if (this.options.sortLayers) {
            this._layers.sort(
                L.Util.bind(
                    (a, b) => this.options.sortFunction(a.layer, b.layer, a.name, b.name),
                    this,
                ),
            );
        }

        if (this.options.autoZIndex && layer.setZIndex) {
            this._lastZIndex += 1;
            layer.setZIndex(this._lastZIndex);
        }

        this._expandIfNotCollapsed();
    },

    _addItem(obj) {
        if (obj.hide) return null;
        const label = document.createElement('div');
        label.className = 'layer-label-row';
        const traffic_checked = obj.traffic && this._map.hasLayer(obj.traffic);
        const checked = this._map.hasLayer(obj.layer) | traffic_checked;
        let input;
        let traffic_input;

        if (obj.overlay) {
            input = document.createElement('input');
            input.type = 'checkbox';
            input.className = 'leaflet-control-layers-selector';
            input.defaultChecked = checked;
        } else {
            input = this._createRadioElement(`leaflet-base-layers_${L.Util.stamp(this)}`, checked);
        }

        this._layerControlInputs.push(input);
        input.layerId = L.Util.stamp(obj.layer);

        L.DomEvent.on(input, 'click', this._onInputClick, this);

        let name = document.createElement('span');
        name.innerHTML = ` ${obj.name}`;

        // Helps from preventing layer control flicker when checkboxes are disabled
        // https://github.com/Leaflet/Leaflet/issues/2771
        let holder = document.createElement('div');

        label.appendChild(holder);
        holder.appendChild(input);
        holder.appendChild(name);

        if (obj.traffic) {
            traffic_input = document.createElement('input');
            traffic_input.type = 'checkbox';
            traffic_input.className = 'leaflet-control-layers-selector';
            traffic_input.defaultChecked = traffic_checked;
            traffic_input.force_disabled = !checked;

            this._layerControlInputs.push(traffic_input);
            traffic_input.layerId = L.Util.stamp(obj.traffic);

            L.DomEvent.on(traffic_input, 'click', this._onInputClick, this);

            name = document.createElement('span');
            name.innerHTML = ' Traffic';

            holder = document.createElement('div');
            holder.className = 'traffic';

            label.appendChild(holder);
            holder.appendChild(traffic_input);
            holder.appendChild(name);
        }

        const container = obj.overlay ? this._overlaysList : this._baseLayersList;
        container.appendChild(label);

        this._checkDisabledLayers();
        return label;
    },

    _getInput(id) {
        for (const input of this._layerControlInputs) {
            if (input && input.layerId === id) {
                return input;
            }
        }
        return null;
    },

    _onInputClick() {
        let layer;
        let addedLayers = [];
        const removedLayers = [];
        this._handlingClick = true;

        for (const input of this._layerControlInputs.reverse()) {
            let traffic_input;
            layer = this._getLayer(input.layerId);
            if (layer.traffic) {
                traffic_input = this._getInput(L.Util.stamp(layer.traffic));
            }
            if (input.checked) {
                addedLayers.push(layer.layer);
                if (traffic_input) {
                    traffic_input.disabled = false;
                }
            } else if (!input.checked) {
                removedLayers.push(layer.layer);
                if (traffic_input) {
                    traffic_input.checked = false;
                    traffic_input.disabled = true;
                    removedLayers.push(layer.traffic);
                }
            }
        }

        addedLayers = addedLayers.filter((e) => !removedLayers.includes(e));

        for (const l of removedLayers) {
            if (this._map.hasLayer(l)) {
                this._map.removeLayer(l);
            }
        }
        for (const l of addedLayers) {
            if (!this._map.hasLayer(l)) {
                this._map.addLayer(l);
            }
        }

        this._handlingClick = false;
        this._refocusOnMap();
    },

    _checkDisabledLayers() {
        const zoom = this._map.getZoom();
        for (const input of this._layerControlInputs.reverse()) {
            const { layer } = this._getLayer(input.layerId);
            input.disabled = (
                (layer.options.minZoom !== undefined && zoom < layer.options.minZoom)
                || (layer.options.maxZoom !== undefined && zoom > layer.options.maxZoom));
            input.disabled = input.disabled || input.force_disabled;
        }
    },
});

class TileLayers extends React.Component {
    constructor(props) {
        super(props);

        this.leafletElement = null;
    }

    componentDidMount() {
        const GoogleRoad = L.gridLayer.googleMutant({
            type: 'roadmap',
            googlekey: this.context.googleAPIKey,
        });
        const GoogleSatellite = L.gridLayer.googleMutant({
            type: 'hybrid',
            googlekey: this.context.googleAPIKey,
        });
        const GoogleRoadTraffic = L.gridLayer.googleMutant({
            type: 'roadmap',
            googlekey: this.context.googleAPIKey,
        });
        const GoogleHybridTraffic = L.gridLayer.googleMutant({
            type: 'hybrid',
            googlekey: this.context.googleAPIKey,
        });
        GoogleRoadTraffic.addGoogleLayer('TrafficLayer');
        GoogleHybridTraffic.addGoogleLayer('TrafficLayer');

        L.mapboxAccessToken = 'pk.eyJ1IjoicmFpbG5vdmEiLCJhIjoiY2tkN2Zta3J1MDVhNTJybXM2cWJ4NWdhYiJ9.s0XtzZA08KHLg2l-UkoOnQ';

        const Base = mapboxgl.supported() ? L.mapboxGL({
            accessToken: L.mapboxAccessToken,
            style: 'mapbox://styles/railnova/ckc4uqsn312kj1ip6s8jjqbd9',
        }) : new L.tileLayer(
            `https://api.mapbox.com/styles/v1/railnova/ckc4uqsn312kj1ip6s8jjqbd9/tiles/{z}/{x}/{y}?access_token=${L.mapboxAccessToken}`, {
                tileSize: 512,
                zoomOffset: -1,
                attribution: '© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            },
        );

        const layers = new RailnovaLayers({
            'Railways by Railnova': { layer: Base },
            'Google Road': { layer: GoogleRoad, traffic: GoogleRoadTraffic },
            'Google Satellite': { layer: GoogleSatellite, traffic: GoogleHybridTraffic },
        });
        this.leafletElement = layers.addTo(this.context.map);

        this.leafletElement.setPosition(this.props.position);

        this.context.map.addLayer(Base);
    }

    shouldComponentUpdate() {
        return false;
    }

    // componentWillUnmount() {
    //     this.leafletElement.remove();
    // }

    render() {
        return null;
    }
}

TileLayers.defaultProps = {
    position: 'topright',
};
TileLayers.propTypes = {
    position: PropTypes.string,
};
TileLayers.contextTypes = {
    googleAPIKey: PropTypes.string,
    map: PropTypes.object,
};

export default TileLayers;
