/**
 * Created by lpostula on 12 10/17.
 */
import React from 'react';

/**
 * This React component renders and <input> field that contains the Django
 * CSRF token, for use in React-rendered forms such as login or assign.
 */

const CsrfMiddlewareToken = () => (
    <input
        type="hidden"
        name="csrfmiddlewaretoken"
        value={Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME)}
    />
);

export default CsrfMiddlewareToken;
