import moment from 'moment-timezone';

const rel_rx = new RegExp('rel="(.*)"');
const url_rx = new RegExp('<*(.*)>');
const url_info_rx = new RegExp('.*page=([0-9]*).*page_size=([0-9a-zA-Z]*)*');
export const parseLinkHeader = (headers) => {
    // a single header looks like this
    // <http://testing.com?page=2&page_size=5>;rel="prev"
    if (!headers) return {};
    const out = {};
    const splitted_headers = headers.split(/,\s*</);
    for (const header of splitted_headers) {
        const splitted_header = header.split(';');
        if (splitted_header.length !== 2) {
            console.error(`malformed header: ${headers}`);
            return {};
        }
        const url_part = splitted_header[0];
        const rel_part = splitted_header[1];
        if (!rel_rx.test(rel_part)) {
            console.error(`malformed header: ${headers}`);
            return {};
        }
        const rel = rel_rx.exec(rel_part)[1];
        if (!url_rx.test(url_part)) {
            console.error(`malformed header: ${header}`);
            return {};
        }
        const url = url_rx.exec(url_part)[1];
        if (!url_info_rx.test(url_part)) {
            console.error(`malformed header: ${header}`);
            return {};
        }
        const url_part_rx = url_info_rx.exec(url_part);
        const page = Number(url_part_rx[1]);
        let page_size = url_part_rx[2];
        if (page_size.match(/[0-9]+/)) {
            // we are in the case of a simple link header
            page_size = Number(page_size);
        }
        out[[rel]] = {
            page,
            page_size,
            url,
            rel,
        };
    }
    return out;
};

const map_time_interval_to_moment_duration = {
    month: moment.duration(1, 'months'),
    week: moment.duration(7, 'days'),
    day: moment.duration(1, 'days'),
    hour: moment.duration(1, 'hours'),
};

export const parseTimedLinkHeader = (headers) => {
    const parsed = parseLinkHeader(headers);
    const time_info = {};
    // first we need to find the curent page
    let curr_page = 1;
    let page_size = 'week';
    if (parsed.prev) {
        curr_page = parsed.prev.page + 1;
        page_size = parsed.prev.page_size;
    } else if (parsed.next) {
        curr_page = parsed.next.page - 1;
        page_size = parsed.next.page_size;
    }
    // the interval is:
    // - from: now - curr_page * page_size
    // - to: now - (curr_page - 1) * page_size
    const now = moment();
    const dt = map_time_interval_to_moment_duration[page_size];
    const from = moment(now).subtract(curr_page * dt);
    const to = moment(now).subtract((curr_page - 1) * dt);
    time_info.from = from;
    time_info.to = to;
    return {
        time_info,
        ...parsed,
    };
};

export default {
    parseLinkHeader,
    parseTimedLinkHeader,
};
