import React from 'react';
import './ErrorMessageDisplay.scss';

const ErrorMessageDisplay = (props) => {
    if (props.display) {
        if (typeof props.message === 'string') {
            return (
                <div className="error-message-frame">
                    <div className="error-message-holder">
                        <span>{props.message}</span>
                    </div>
                </div>
            );
        }
        return (
            <div className="error-message-frame">
                <div className="error-message-holder">
                    {
                        Object.keys(props.message).map((key) => (
                            <span>{`${key} : ${props.message[key][0]}`}</span>
                        ))
                    }
                </div>
            </div>
        );
    }
    return null;
};

export default ErrorMessageDisplay;
