import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import components from 'components';
import moment from 'moment-timezone';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { locationsSelectors, locationsOperations } from 'railfleet_state/ducks/locations/';
import { maintenanceEventsOperations } from 'railfleet_state/ducks/maintenance_events';
import { documentsSelectors, documentsOperations } from 'railfleet_state/ducks/documents';
import { companiesSettingsSelectors } from 'railfleet_state/ducks/companies_settings';
import { restrictionsSelectors } from 'railfleet_state/ducks/restrictions';
import { uxSelectors, uxOperations } from 'railfleet_state/ducks/ux';

import './CorrectiveEventDetail.scss';
import TextAction from 'components/buttons/textAction/TextAction';
import DuplicateSummary from 'compounds/duplicateTicketDetection/duplicateSummary/DuplicateSummary';
import DuplicateTreated from 'compounds/duplicateTicketDetection/duplicateTreated/DuplicateTreated';
import useFetch from 'components/hooks/useFetch';
import InterventionHistory from '../../lists/interventionHistory/InterventionHistory';

const { DetailFrame } = components.frames;
const { TextDisplay, CustomFieldDisplay, AutoTranslatedDisplay } = components.display;
const { CutForm } = components.forms;
const { MultiDeviceLayout } = components.app;
const { HeaderModal } = components.headers;
const {
    RenderDocuments,
    RenderRestrictions,
    RenderFaultCategory,
    RenderLabelCategory,
} = components.forms;
const { EditButton, CancelButton } = components.buttons;
const { usePerm } = components.hooks;

const CorrectiveEventDetailComponent = (props) => {
    const [_pushInfos, setPushInfos] = useState(null);
    const hasPerm = usePerm(props.asset_id || null);
    const [_duplicat, setDuplicat] = useState([]);
    const { fetchAll } = useFetch();

    const all_duplicat = _duplicat && _duplicat[props.maintenance_event.id];
    const candidate = _.filter(all_duplicat, (element) => element.status === 'candidate_duplicate');
    const confirmed_duplicat = _.filter(all_duplicat, (element) => element.status === 'confirmed_duplicate');
    const denied_duplicat = _.filter(all_duplicat, (element) => element.status === 'confirmed_non_duplicate');

    const treatRawDuplicat = (rawDuplicat) => {
        const duplicat = {};
        if (!rawDuplicat) return duplicat;
        for (const element of rawDuplicat) {
            if (!duplicat[element.event_1]) duplicat[element.event_1] = [];
            if (!duplicat[element.event_2]) duplicat[element.event_2] = [];
            duplicat[element.event_1].push(element);
            duplicat[element.event_2].push(element);
        }
        return duplicat;
    };

    const fetchDuplicate = () => {
        fetchAll('/api/v2/maintenance/get_duplicate.json', {
            event_1: props.maintenance_event.id,
        }).then((d) => {
            const duplicat = treatRawDuplicat(d);
            setDuplicat(duplicat);
        });
    };

    useEffect(() => {
        if (!props.maintenance_event) return;
        fetchDuplicate();
    }, [props.maintenance_event]);

    useEffect(() => {
        if (props.push_infos && props.push_infos.extras && props.push_infos.extras.target === 'status_change') return;
        setPushInfos(props.push_infos);
        props.setPushInfos(null, 'corrective event detail');
    }, []);

    useEffect(() => {
        if (props.event_id) props.fetchEventDetails(props.event_id);
    }, [props.event_id]);

    useEffect(() => {
        if (props.maintenance_event && props.maintenance_event.poi && !props.poi.id) {
            props.fetchLocation(props.maintenance_event.poi);
        }
        if (props.maintenance_event && props.maintenance_event.id) {
            props.fetchDocumentsForEvent(props.maintenance_event.id);
        }
    }, [props.maintenance_event]);

    const CancelButtonManager = () => {
        if (props.maintenance_event.next_planned_intervention) return null;
        if (props.maintenance_event.state === 'closed') return null;
        return (
            <CancelButton
                text={i18n.t('Close event')}
                onClick={props.onClickClose}
                disabled={!hasPerm('close_corrective_event')}
            />
        );
    };

    const detailRenderer = () => (
        <div className="corrective-event-refs">
            <div className="corrective-event-ref">
                <span>{i18n.t('Réf')}</span>
                <span>{`M${props.event_id}`}</span>
                {
                    props.is_railnovian_impersonating && (
                        <TextAction
                            onClick={() => {
                                const link = `/admin/maintenance/maintenanceevent/?q=${props.event_id}`;
                                window.open(
                                    link,
                                    '_blank',
                                );
                            }}
                            text={i18n.t('View in admin')}
                        />
                    )
                }
            </div>
        </div>
    );

    const actionsRenderer = () => (
        <div className="corrective-event-header-actions">
            <CancelButtonManager
                text={i18n.t('Close event')}
                onClick={props.onClickClose}
            />
            <EditButton
                text={i18n.t('Edit event')}
                onClick={props.onClickEdit}
                disabled={!hasPerm('edit_corrective_event')}
            />
        </div>
    );

    const onClickPrevious = () => {
        window.location.href = _pushInfos.from;
    };

    if (!hasPerm('view_corrective_events') && !props.loginless) {
        return (
            <div className={`corrective-event-detail ${props.device}`}>
                <HeaderModal
                    title={i18n.t('Event details')}
                    onCloseModal={props.onCloseModal}
                />
                <div>YOU DO NOT HAVE THE PERM: view_corrective_events</div>
            </div>
        );
    }
    return (
        <div className={`corrective-event-detail ${props.device}`}>
            <HeaderModal
                title={i18n.t('Event details')}
                detailRenderer={detailRenderer}
                actionsRenderer={!props.readOnly && actionsRenderer}
                onCloseModal={() => {
                    fetchDuplicate();
                    props.onCloseModal();
                }}
                previous_link={_pushInfos && onClickPrevious}
            />
            <DetailFrame
                title={i18n.t('General informations')}
            >
                <CutForm
                    device={props.device}
                >
                    <TextDisplay
                        label={i18n.t('Asset')}
                        data={props.asset.name}
                    />
                    <TextDisplay
                        label={i18n.t('Maintenance type')}
                        data={props.maintenance_type}
                    />
                    <TextDisplay
                        label={i18n.t('Incident date')}
                        data={moment(props.maintenance_event.date_incident).format('DD/MM/YYYY HH:mm')}
                    />
                    <TextDisplay
                        label={i18n.t('Location of incident')}
                        data={props.poi.name}
                    />
                    <AutoTranslatedDisplay
                        {...props}
                        label={i18n.t('Description')}
                        data={props.maintenance_event.description}
                        large
                    />
                    <DuplicateSummary
                        asset_id={props.asset_id}
                        event_1={props.event_id}
                        onChangeStatus={fetchDuplicate}
                        candidates={[..._.pluck(candidate, 'event_2'), ..._.pluck(candidate, 'event_1')]}
                        large
                    />
                    <DuplicateTreated
                        asset_id={props.asset_id}
                        event_1={props.event_id}
                        duplicat={_duplicat}
                        confirmed={[..._.pluck(confirmed_duplicat, 'event_2'), ..._.pluck(confirmed_duplicat, 'event_1')]}
                        denied={[..._.pluck(denied_duplicat, 'event_2'), ..._.pluck(denied_duplicat, 'event_1')]}
                        onChangeStatus={fetchDuplicate}
                        large
                    />
                    <TextDisplay
                        label={i18n.t('Impact status')}
                        data={props.impact_status.name}
                        className={props.impact_status.key}
                    />
                    <TextDisplay
                        label={i18n.t('Impact service')}
                        data={props.impact_service_status.name}
                    />
                    {
                        props.impact_service_status.key === 'train_delayed'
                        && (
                            <TextDisplay
                                label={i18n.t('delay_in_minutes')}
                                data={props.maintenance_event.impact_service_delay}
                            />
                        )
                    }
                    <TextDisplay
                        label={i18n.t('Reported by')}
                        data={props.maintenance_event.reported_by}
                    />
                    <TextDisplay
                        label={i18n.t('Hotline called')}
                        data={props.hotline_called}
                    />
                    {hasPerm('create_private_event')
                        && (
                            <TextDisplay
                                label={i18n.t('Privacy')}
                                data={props.is_private}
                            />
                        )}
                </CutForm>
                <RenderLabelCategory
                    event_id={props.event_id}
                />
            </DetailFrame>
            {
                props.public_fields && props.public_fields.length > 0
                && (
                    <DetailFrame
                        title={i18n.t('Public')}
                    >
                        <CustomFieldDisplay
                            asset_id={props.asset_id}
                            content_type="maintenanceevent"
                            content_options={props.content_options}
                            public
                            data={props.maintenance_event}
                            fields={props.loginless ? props.public_fields : null}
                            {...props}
                        />
                    </DetailFrame>
                )
            }
            {
                props.private_fields && props.private_fields.length > 0
                && (
                    <DetailFrame
                        title={i18n.t('Private')}
                        private
                    >
                        <CustomFieldDisplay
                            asset_id={props.asset_id}
                            content_type="maintenanceevent"
                            content_options={props.content_options}
                            private
                            data={props.maintenance_event}
                            fields={props.loginless ? props.private_fields : null}
                            {...props}
                        />
                    </DetailFrame>
                )
            }
            {
                props.maintenance_event.state !== 'open'
                    && (
                        <DetailFrame
                            title={i18n.t('Maintenance report')}
                        >
                            {
                                !_.isEmpty(props.public_fields_close)
                                && (
                                    <CustomFieldDisplay
                                        asset_id={props.asset_id}
                                        content_type="maintenanceevent"
                                        content_options={props.content_options_close}
                                        public
                                        data={props.maintenance_event}
                                        fields={props.loginless ? props.public_fields : null}
                                        {..._.omit(props, 'content_options')}
                                    />
                                )
                            }
                            {
                                !_.isEmpty(props.private_fields_close)
                                && (
                                    <CustomFieldDisplay
                                        asset_id={props.asset_id}
                                        content_type="maintenanceevent"
                                        content_options={props.content_options_close}
                                        private
                                        data={props.maintenance_event}
                                        fields={props.loginless ? props.private_fields : null}
                                        {..._.omit(props, 'content_options')}
                                    />
                                )
                            }
                            <CutForm
                                device={props.device}
                            >
                                <TextDisplay
                                    label={i18n.t('Date started')}
                                    data={props.date_started && moment(props.date_started).format('DD/MM/YYYY HH:mm')}
                                />
                                <TextDisplay
                                    label={i18n.t('Date closed')}
                                    data={props.date_closed && moment(props.date_closed).format('DD/MM/YYYY HH:mm')}
                                />
                                <TextDisplay
                                    label={i18n.t('Closed by')}
                                    data={props.users && props.users[props.maintenance_event.user_closed]?.email}
                                />
                                <TextDisplay
                                    label={i18n.t('Observations')}
                                    data={props.rex}
                                    large
                                />
                                <RenderFaultCategory
                                    fault_category_id={props.fault_category}
                                    label={i18n.t('Fault Category')}
                                    {...props}
                                />
                            </CutForm>
                        </DetailFrame>
                    )
            }
            {
                props.restrictions && props.restrictions.length > 0
                && (
                    <DetailFrame
                        title={i18n.t('Restrictions')}
                    >
                        <RenderRestrictions
                            id="restrictions"
                            asset_id={props.asset_id}
                            restrictions={props.restrictions}
                            device={props.device}
                            {...props}
                        />
                    </DetailFrame>
                )
            }
            <DetailFrame
                title={i18n.t('Documents')}
            >
                <RenderDocuments
                    label={i18n.t('Documents')}
                    data={props.maintenance_event.documents}
                    documents_for_event={props.documents_for_event}
                    device={props.device}
                />
            </DetailFrame>
            <InterventionHistory
                event_id={props.event_id}
                noLink={props.readOnly}
                interventionIdRedirect={props.interventionIdRedirect}
                ecm_id={props.asset.ecm_id}
                event={props.loginless ? props.maintenance_event : null}
                {...props}
            />
        </div>
    );
};

const CorrectiveEventDetail = (props) => (
    <MultiDeviceLayout>
        <CorrectiveEventDetailComponent {...props} />
    </MultiDeviceLayout>
);

const mapStateToProps = (state, props) => {
    if (props.loginless) return props.mapStateToProps(state, props);
    const { event_id } = props;
    const { asset_id } = props;
    const content_type = 'maintenanceevent';
    const is_railnovian_impersonating = (state.users.me != null && state.users.me.actual_user != null ? state.users.me.actual_user.email.endsWith('railnova.eu') : false);
    const maintenance_event = event_id ? state.maintenance_events[event_id] : null;
    const rex = maintenance_event && maintenance_event.rex;
    const fault_category = maintenance_event && maintenance_event.fault_category;
    const date_closed = maintenance_event && maintenance_event.date_closed;
    const date_started = maintenance_event && maintenance_event.date_started;
    const content_options = ['corrective', 'open'];
    const content_options_close = ['corrective', 'close'];
    const maintenance_type = maintenance_event && maintenance_event.maintenance_type;
    const asset = asset_id && assetsSelectors.getAssetInfos(state, asset_id);
    const publicFieldConfigSelector = asset && content_type
        && assetsSelectors.makePublicFieldsForAssetWithContentTypeAndOptions(
            content_type, content_options,
        );
    const publicFieldCloseConfigSelector = asset && content_type
        && assetsSelectors.makePublicFieldsForAssetWithContentTypeAndOptions(
            content_type, content_options_close,
        );
    const public_fields = asset_id && asset && publicFieldConfigSelector(state, asset_id);
    const public_fields_close = asset_id && asset && publicFieldCloseConfigSelector(state, asset_id);
    const private_fields = content_type
        && companiesSettingsSelectors.getPrivateFieldsForUserWithContentTypeAndOptions(
            content_type, content_options_close,
        )(state);
    const private_fields_close = content_type
        && companiesSettingsSelectors.getPrivateFieldsForUserWithContentTypeAndOptions(
            content_type, content_options_close,
        )(state);
    const poi = maintenance_event
        && locationsSelectors.getLocationsById(state, maintenance_event.poi);
    const getMaintenanceSettings = assetsSelectors.makeMaintenanceSettingsForAsset();
    const maintenance_settings = getMaintenanceSettings(state, asset_id);
    const impact_service_status_type = maintenance_settings
        && maintenance_settings.impact_service_status_type;
    const operational_status_type = maintenance_settings
        && maintenance_settings.operational_status_type;
    const impact_service_status = impact_service_status_type
        && maintenance_event
        && maintenance_event.impact_service_status
        && impact_service_status_type[maintenance_event.impact_service_status];
    const impact_status = operational_status_type
        && maintenance_event
        && maintenance_event.impact_status
        && operational_status_type[maintenance_event.impact_status];
    const hotline_called = maintenance_event && maintenance_event.hotline_called ? i18n.t('yes') : i18n.t('no');
    const is_private = maintenance_event && maintenance_event.private ? i18n.t('Private') : i18n.t('Public');
    const documents_for_event = event_id
        && documentsSelectors.getDocumentsForEvent(state, event_id);
    const restrictions = restrictionsSelectors.getRestrictionsByEvent(state, event_id);
    const push_infos = props.push_infos || uxSelectors.getPushInfos(state);
    return {
        maintenance_event: maintenance_event || {},
        maintenance_type,
        is_railnovian_impersonating,
        rex,
        fault_category,
        date_closed,
        date_started,
        public_fields,
        public_fields_close,
        private_fields,
        private_fields_close,
        content_options,
        content_options_close,
        push_infos,
        asset: asset || {},
        poi: poi || {},
        impact_service_status: impact_service_status || {},
        impact_status: impact_status || {},
        hotline_called,
        is_private,
        documents_for_event: documents_for_event || {},
        restrictions: restrictions || {},
        users: state.users.all,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    if (props.loginless) return props.mapDispatchToProps(dispatch, props);
    return ({
        fetchEventDetails: (event_id) => dispatch(
            maintenanceEventsOperations.fetchDetails(event_id),
        ),
        fetchLocation: (id) => dispatch(locationsOperations.fetchDetails(id)),
        fetchDocumentsForEvent: (event_id) => (
            dispatch(documentsOperations.fetchForEvent(event_id))
        ),
        setPushInfos: (push_infos, from = null) => dispatch(uxOperations.setPushInfos(push_infos, from)),
    });
};

export default connect(mapStateToProps, mapDispatchToProps)(CorrectiveEventDetail);
