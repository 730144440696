/**
 * A component that displays itself only if a `/brand_logo` image is found.
 */

import React from 'react';

const showContainer = (event) => {
    /* eslint-disable no-param-reassign */
    const backgroundImageHeight = 28;
    const backgroundImageWidth = 80;
    let paddingTop = 4;
    if (backgroundImageHeight >= event.target.height) {
        paddingTop = 8;
    }
    if (backgroundImageWidth <= event.target.width) {
        paddingTop = 6;
    }
    event.target.style.paddingTop = `${paddingTop}px`;
    event.target.style.maxHeight = `${backgroundImageHeight - paddingTop}px`;
    event.target.parentElement.style.display = 'block';
};

const BrandingBar = () => (
    <div
        className="branding-bar"
        style={{ display: 'none' }}
    >
        <img
            className="brandingLogo"
            src="/brand_logo"
            alt="brandingLogo"
            onLoad={showContainer}
        />
    </div>
);

export default BrandingBar;
