import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SelectFilter from '../selectFilter/SelectFilter';
import WithLoginless from '../../app/withLoginless';

const wrapPoiForSelect = (element) => ({
    value: element.id,
    label: element.name,
    poi: element,
});

const SelectLocationFilter = (props) => {
    const [_selected, setSelected] = useState(props.multi ? [] : null);
    const [_defaultSetted, setDefaultSetted] = useState(false);

    const onChange = (select) => {
        if (!select) {
            if (props.multi) {
                setSelected([]);
                props.onChange([]);
            } else {
                setSelected(null);
                props.onChange(null);
            }
            return;
        }
        if (props.multi) {
            setSelected(select);
            props.onChange(_.map([].concat(select), (s) => s.value));
        } else {
            setSelected(select);
            props.onChange(select.value);
        }
    };

    useEffect(() => {
        if (!_defaultSetted && props.defaultLocation && props.defaultLocation.length > 0) {
            if (props.multi) {
                onChange(props.defaultLocation);
            } else {
                onChange(props.defaultLocation[0]);
            }
            setDefaultSetted(true);
        }
    }, [props.defaultLocation]);

    const search = async (input) => {
        if (props.loginless
            && props.asset_class
            && props.assign_to
        ) return props.searchByNameSmartLocation(input);
        if (props.loginless) return props.search(input);
        let url = '/api/v2/place/';
        const args = { search: input };
        args.ordering = 'trigram';
        if (props.asset_class && props.assign_to) {
            url = '/api/v2/place/prediction_for_intervention.json';
            args.limit = 20;
            args.asset_class = props.asset_class;
            args.assign_to = props.assign_to;
        } else {
            args.page_size = 20;
        }
        const raw_options = await $.getJSON(url, args);
        const options = [];
        for (const element of raw_options) {
            options.push(wrapPoiForSelect(element));
        }
        return options;
    };

    useEffect(() => {
        if (props.suggested) {
            onChange(props.suggested);
        }
    }, [props.suggested]);

    return (
        <SelectFilter
            {...props}
            search={search}
            autoload={props.autoload}
            filterOption={() => true} // filtering is done by endpoint
            onChange={onChange}
            onClear={() => onChange(null)}
            selected={_selected}
        />
    );
};

SelectLocationFilter.propTypes = {};

SelectLocationFilter.defaultProps = {
    autoload: true,
};
const mapStateToProps = (state, props) => {
    if (props.loginless) return props.mapStateToProps(state, props);
    return {};
};

const mapDispatchToProps = (dispatch, props) => {
    if (props.loginless) return props.mapDispatchToProps(dispatch, props);
    return ({});
};

export default WithLoginless(connect(mapStateToProps, mapDispatchToProps)(SelectLocationFilter));
