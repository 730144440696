import types from './types';
import { createReducer, apiHelpers } from '../../utils';

/* State shape
{
    {: },
}
 */

const initialState = {};

const listReducer = createReducer(initialState)({
    [types.FETCH_LIST_COMPLETED]: apiHelpers.fetch_list_complete,
});

export default listReducer;
