import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import './AutoTranslatedDisplay.scss';
import { API_ROOT } from 'railfleet_state/config';
import NoDataDisplay from '../noDataDisplay/NoDataDisplay';
import DetailFrame from '../../frames/detailFrame/DetailFrame';
import supportedLanguages from './supportedLanguages';
import FormDropdown from '../../forms/RailForms/formDropdown/FormDropdown';
import FormManager from '../../forms/RailForms/FormManager';
import useDictToArray from '../../hooks/useDictToArray';
import { THEMES } from '../../filters/selectFilter/SelectFilter';
import useMe from '../../hooks/useMe';
import MultiDeviceLayout from '../../app/MultiDeviceLayout';
import Loader from '../../animations/loader/Loader';
import usePost from '../../hooks/usePost';
import ExclamationImg from './exclamation.svg';

const Bubble = () => (
    <div className="auto-translate-bubble">
        <span>A</span>
        <div className="auto-translate-bubble-arrow" />
    </div>
);

const user_languages = {
    en: 'en-gb',
    pt: 'pt-pt',
};

const AutoTranslatedDisplayComponent = (props) => {
    const me = props.loginless ? { language: props.language } : useMe();
    const languages = useDictToArray(supportedLanguages);
    const [_selectedLanguage, setSelectedLanguage] = useState(user_languages[me.language] || me.language);
    const [_text, setText] = useState(props.data);
    const { postAll, isLoading, hasError } = usePost();
    const [_translation, setTranslation] = useState(null);
    const [_sourceLanguage, setSourceLanguage] = useState(null);

    useEffect(() => {
        if (_sourceLanguage && _sourceLanguage === me.language) {
            setSelectedLanguage(null);
        }
    }, [_sourceLanguage]);

    const getTranslationLoginless = async (data) => {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
                'SUPERNOVA-LOGINLESS-ASSET-ID': props.asset.info.id,
                'SUPERNOVA-LOGINLESS-SECRET': props.secret,
            },
            body: JSON.stringify(data),
        };
        const url = `${API_ROOT}translate/`;
        await fetch(url, settings).then((r) => r.json().then((d) => {
            if (!d.error) {
                setTranslation(d.translation);
                setSourceLanguage(d.source);
            }
        }));
    };

    useEffect(() => {
        if (_selectedLanguage && _text) {
            const data = {
                text: _text,
                target: _selectedLanguage,
            };
            if (props.loginless) {
                getTranslationLoginless(data);
            } else {
                postAll('/api/v2/translate/', data).then((d) => {
                    if (!d.error) {
                        setTranslation(d.translation);
                        setSourceLanguage(d.source);
                    }
                });
            }
        }
    }, [_selectedLanguage]);

    if (!props.data) {
        return (<NoDataDisplay {...props} />);
    }

    const warning = () => (
        <div className="auto-translated-warning">
            <div>
                <span>!</span>
                <span>{i18n.t('Automaticaly translated*')}</span>
            </div>
        </div>
    );

    const disclaimer = () => (
        <div className="auto-translated-disclaimer">
            <span>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                {
                    i18n.t(`* DISCLAIMER: "Auto Translate" feature uses an automated translation service.
                    Please be aware that since translations are done by machine, they may not
                    always be perfect. Railnova is not responsible for the translations provided by
                    the translation service. We do not make any promises, assurances, or guarantees
                    as to the accuracy, reliability, or timeliness of the translations provided.`)
                }
            </span>
        </div>
    );

    const Widget = !props.readOnly ? DetailFrame : (arg) => (
        <div className="auto-translated-holder">
            { arg.children }
        </div>
    );

    return (
        <div className={`auto-translated element-holder ${props.overrideDevice || props.device} ${props.className ? props.className : ''} ${props.readOnly ? ' readOnly' : ''}`}>
            <Widget>
                <div>
                    {
                        !props.readOnly
                            && (
                                <div className="auto-translated-top">
                                    <div className="auto-translated-language">
                                        <div className="auto-translated-element-label">{props.label}</div>
                                    </div>
                                    <div className="auto-translated-element origin large">
                                        <textarea
                                            cols={30}
                                            rows={5}
                                            defaultValue={props.data}
                                            onInput={(arg) => {
                                                if (arg && arg.target.value) {
                                                    setText(arg.target.value);
                                                } else {
                                                    setText(null);
                                                    setTranslation(null);
                                                    setSelectedLanguage(null);
                                                }
                                                setSelectedLanguage(null);
                                            }}
                                            disabled
                                        />
                                    </div>
                                </div>
                            )
                    }
                    { isLoading ? (
                        <div className="auto-translated-bottom">
                            <Loader />
                        </div>
                    ) : (
                        <div className="auto-translated-bottom">
                            {
                                _translation
                                    && (
                                        <>
                                            <div className="auto-translated-language-selection">
                                                <Bubble />
                                                <span className="auto-translated-to">{i18n.t('Auto Translate to')}</span>
                                                <FormManager>
                                                    <FormDropdown
                                                        id="languages"
                                                        placeholder={i18n.t('Supported languages')}
                                                        noLabel
                                                        values={languages || ''}
                                                        value={_selectedLanguage}
                                                        id_getter={(a) => a.id}
                                                        labelizer={(a) => a.data}
                                                        theme={THEMES.FORM_LIGHT}
                                                        onChange={(d) => setSelectedLanguage(d)}
                                                    />
                                                </FormManager>
                                                { warning() }
                                            </div>
                                            {
                                                _selectedLanguage
                                            && _translation
                                                && (
                                                    <div className="auto-translated-element translation large">
                                                        <textarea
                                                            cols={30}
                                                            rows={5}
                                                            value={_translation}
                                                            disabled
                                                        />
                                                    </div>
                                                )
                                            }
                                        </>
                                    )
                            }
                        </div>
                    )}
                </div>
                {
                    hasError
                    && (
                        <div className="translation-error">
                            <img src={ExclamationImg} alt="exclamation icon" />
                            <div>{hasError}</div>
                        </div>
                    )
                }
                { disclaimer() }
            </Widget>
        </div>
    );
};

const AutoTranslatedDisplay = (props) => (
    <MultiDeviceLayout>
        <AutoTranslatedDisplayComponent
            {...props}
        />
    </MultiDeviceLayout>
);

export default AutoTranslatedDisplay;
