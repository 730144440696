/**
 * Created by lpostula on 17/05/18.
 */
import { RSAA } from 'redux-api-middleware';

import types from './types';
import { API_ROOT } from '../../config';

const API_RESOURCE_NAME = 'campaign';

export const fetchDetails = (item_id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${item_id}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_DETAILS_REQUEST,
            types.FETCH_DETAILS_COMPLETED,
            types.FETCH_DETAILS_FAILED,
        ],
    },
});

export const fetchList = (long_polling = false) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            {
                type: types.FETCH_LIST_REQUEST,
                meta: { long_polling },
            },
            {
                type: types.FETCH_LIST_COMPLETED,
                meta: { long_polling },
            },
            types.FETCH_LIST_FAILED,
        ],
    },
});

export const createCampaign = (values) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(values),
        credentials: 'same-origin',
        types: [
            types.CREATE_REQUEST,
            types.CREATE_COMPLETED,
            types.CREATE_FAILED,
        ],
    },
});

export const updateCampaign = (values) => (dispatch) => dispatch({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${values.id}.json`,
        method: 'PATCH',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(values),
        credentials: 'same-origin',
        types: [
            types.UPDATE_REQUEST,
            types.UPDATE_COMPLETED,
            types.UPDATE_FAILED,
        ],
    },
});

export const cancelCampaign = (id) => (dispatch) => dispatch({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${id}/cancel.json`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        credentials: 'same-origin',
        types: [
            types.CANCEL_REQUEST,
            types.CANCEL_COMPLETED,
            types.CANCEL_FAILED,
        ],
    },
});

export const duplicateCampaign = (id) => (dispatch) => dispatch({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${id}/duplicate.json`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        credentials: 'same-origin',
        types: [
            types.DUPLICATE_REQUEST,
            types.DUPLICATE_COMPLETED,
            types.DUPLICATE_FAILED,
        ],
    },
});

export const approveCampaign = (id) => (dispatch) => dispatch({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${id}/approve.json`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        credentials: 'same-origin',
        types: [
            types.APPROVE_REQUEST,
            types.APPROVE_COMPLETED,
            types.APPROVE_FAILED,
        ],
    },
});

export const assignAssetsToCampaign = (id, asset_ids) => (dispatch) => dispatch({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${id}/assign.json`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify({ assets: asset_ids }),
        credentials: 'same-origin',
        types: [
            types.ASSIGN_REQUEST,
            types.ASSIGN_COMPLETED,
            types.ASSIGN_FAILED,
        ],
    },
});

export const deAssignAssetsToCampaign = (id, asset_ids) => (dispatch) => dispatch({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${id}/deassign.json`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify({ assets: asset_ids }),
        credentials: 'same-origin',
        types: [
            types.DEASSIGN_REQUEST,
            types.DEASSIGN_COMPLETED,
            types.DEASSIGN_FAILED,
        ],
    },
});

export const updateInStore = (data) => ({
    type: types.CREATE_COMPLETED,
    payload: data,
});

export default {
    fetchDetails,
    fetchList,
    updateCampaign,
    createCampaign,
    cancelCampaign,
    duplicateCampaign,
    approveCampaign,
    assignAssetsToCampaign,
    deAssignAssetsToCampaign,
    updateInStore,
};
