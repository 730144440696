/**
 * Created by lpostula on 11/05/17.
 */
import {
    createSelector,
} from 'reselect';
import {
    getOperationalLabelCategoriesForCurrentUser,
    getLabelCategoriesForCurrentUser,
} from '../label_categories/selectors';

export const getLabels = (state) => state.labels;

export const getLabelsForCurrentUser = createSelector(
    [getLabelCategoriesForCurrentUser],
    (label_categories) => _.sortBy(_.flatten(_.pluck(
        label_categories, 'label_set',
    )), 'rank'),
);

function sort_options(a, b) {
    const sa = a.label.toLowerCase();
    const sb = b.label.toLowerCase();
    return -(sa < sb) || +(sa !== sb);
}

export const getAllOperationalLabelsOptions = createSelector(
    [getOperationalLabelCategoriesForCurrentUser],
    (label_categories) => {
        const options = [];
        for (const category_id in label_categories) {
            const category = label_categories[category_id];
            if (category) {
                for (const label of category.label_set) {
                    options.push({
                        value: label.id,
                        label: `${category.name}: ${label.name}`,
                    });
                }
            }
        }
        options.sort(sort_options);
        return options;
    },
);

export default {
    getLabels,
    getAllOperationalLabelsOptions,
    getLabelsForCurrentUser,
};
