/**
 * Created by gknus on 02/09/19.
 */

import {
    createSelector,
} from 'reselect';

export const getInterventionTypes = (state) => state.intervention_types;
export const getInterventionTypeById = (state, intervention_type_id) => state.intervention_types[intervention_type_id];

const getUserCompany = (state) => {
    // not null nor undefined
    if (state.users.me) {
        return state.users.me.company;
    }
    return null;
};

export const getInterventionTypesForCurrentUser = createSelector(
    [getInterventionTypes, getUserCompany],
    (intervention_types, user_company) => _.pick(
        intervention_types, (intervention_type) => intervention_type.company === user_company,
    ),
);

export default {
    getInterventionTypes,
    getInterventionTypeById,
    getInterventionTypesForCurrentUser,
};
