import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import i18n from 'i18next';
import 'react-datetime/css/react-datetime.css';

import { concatDateTimeFormats } from '../utils';
import DateInput from '../dateInput/DateInput';
import TimeInput from '../timeInput/TimeInput';

import './DateTimeSelectorR.scss';
import Button from '../../buttons/button/Button';
import { LAYOUT } from '../../app/MultiDeviceLayout';
import DropdownMenuButton from '../../buttons/dropdownMenuButton/DropdownMenuButton';
import LateIcon from '../../display/lateIcon/LateIcon';

const DateTimeSelector = (props) => {
    const validDateTimeRange = (currentDate) => {
        let valid = true;
        valid = valid && (!props.minDate || currentDate.isSameOrAfter(moment(props.minDate)));
        valid = valid && (!props.maxDate || currentDate.isSameOrBefore(moment(props.maxDate)));
        return valid;
    };

    const isValid = (value) => {
        if (!value) {
            return !props.required;
        }
        return !!(value.isValid() && validDateTimeRange(value));
    };

    const [_value, setValue] = useState(props.selected);
    const [_value_date, setValueDate] = useState(
        (props.timeFormat || !props.selected) ? props.selected : props.selected.startOf('day'),
    );
    const [_value_time, setValueTime] = useState(
        props.timeFormat && (props.selected || moment()
            .startOf('day')),
    );
    const [_valid, setValid] = useState(isValid(props.selected));
    const [_dateTimeFormat, setDateTimeFormat] = useState(
        concatDateTimeFormats(props.dateFormat, props.timeFormat),
    );

    const concatNewValue = (date, time) => {
        if (date && time) {
            return moment(
                `${date.format(props.dateFormat)} ${time.format(props.timeFormat)}`,
                _dateTimeFormat,
                true,
            );
        }
        const value = (date || time).format(_dateTimeFormat);
        return moment(
            value,
            _dateTimeFormat,
            true,
        );
    };

    const onChangeDate = (value) => {
        setValueDate(value);
        if (value && (_value_time || !props.timeFormat)) {
            const new_value = concatNewValue(value, _value_time);
            if (new_value.isValid()) {
                setValue(new_value);
                setValid(isValid(new_value));
            } else {
                setValue(null);
                setValid(isValid(null));
            }
        } else {
            setValue(null);
            setValid(isValid(null));
        }
    };

    const onChangeTime = (value) => {
        setValueTime(value);
        if (value && _value_date) {
            const new_value = concatNewValue(_value_date, value);
            if (new_value.isValid()) {
                setValue(new_value);
                const test = isValid(new_value);
                setValid(test);
            } else {
                setValue(null);
                setValid(isValid(null));
            }
        } else {
            setValue(null);
            setValid(isValid(null));
        }
    };

    const onChangeDateTime = (value) => {
        if (value === _value) return;
        if (value && _value && value.format('DD/MM/YYYY HH:mm:ss') === _value.format('DD/MM/YYYY HH:mm:ss')) return;
        if (!value) {
            setValueDate(null);
            setValueTime(moment()
                .startOf('day'));
        } else {
            setValueDate(value);
            setValueTime(value);
        }
        setValue(value);
        setValid(isValid(value));
    };

    useEffect(
        () => setDateTimeFormat(concatDateTimeFormats(props.dateFormat, props.timeFormat)),
        [props.dateFormat, props.timeFormat],
    );
    useEffect(() => {
        setValid(isValid(_value));
    }, [props.minDate, props.maxDate]);
    useEffect(() => {
        props.onChange({ value: _value, valid: _valid });
    }, [_value, _valid]);

    useEffect(() => {
        onChangeDateTime(props.selected);
    }, [props.selected]);

    const onClickNow = (evt) => {
        evt.preventDefault();
        onChangeDateTime(moment());
    };

    const onClickInterval = (interval) => {
        onChangeDateTime(moment(props.basedOn).add('m', interval));
    };

    return (
        <div className={`dts-main ${props.className} ${!_valid ? 'has-error' : ''} ${props.device}`}>
            {
                props.label
                    ? (
                        <div className="dsr-holder">
                            <div className={`dsr-label${props.required ? ' mandatory' : ''}`}>{props.label}</div>
                            {
                                props.now
                                    ? (
                                        <div
                                            className="now_button"
                                            onClick={onClickNow}
                                        >
                                            now
                                        </div>
                                    ) : null
                            }
                            {
                                props.late
                                && (
                                    <LateIcon
                                        text={i18n.t('Late')}
                                    />
                                )
                            }
                            {
                                props.interval
                                && (
                                    <div className="interval_buttons">
                                        <div
                                            className="interval_button"
                                            onClick={() => onClickInterval(5)}
                                        >
                                            ~5min
                                        </div>
                                        <div className="interval_separator" />
                                        <div
                                            className="interval_button"
                                            onClick={() => onClickInterval(10)}
                                        >
                                            10min
                                        </div>
                                        <div className="interval_separator" />
                                        <div
                                            className="interval_button"
                                            onClick={() => onClickInterval(20)}
                                        >
                                            20min
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    ) : null
            }
            <div className={`dsr-container-holder ${props.fillFlex ? 'fillFlex' : ''}`}>
                <div className="dsr-container">
                    <DateInput
                        format={props.dateFormat}
                        datetimeFormat={_dateTimeFormat}
                        disabled={props.disabled}
                        onChange={onChangeDate}
                        onChangeSpread={onChangeDateTime}
                        minDate={props.minDate}
                        maxDate={props.maxDate}
                        value={_value_date}
                        valid={_valid}
                    />
                    {
                        props.timeFormat
                        && (
                            <TimeInput
                                format={props.timeFormat}
                                datetimeFormat={_dateTimeFormat}
                                disabled={props.disabled}
                                onChange={onChangeTime}
                                onChangeSpread={onChangeDateTime}
                                value={_value_time}
                                valid={_valid}
                            />
                        )
                    }
                </div>
                {
                    props.now
                        && (
                            <div className="dsr-actions">
                                <Button
                                    label="now"
                                    onClick={onClickNow}
                                />
                            </div>
                        )
                }
                {
                    props.interval
                        && (
                            <div className="dsr-actions">
                                {
                                    props.device === LAYOUT.TABLET ? (
                                        <>
                                            <Button
                                                label="~5min"
                                                onClick={(evt) => {
                                                    evt.preventDefault();
                                                    onClickInterval(5);
                                                }}
                                            />
                                            <Button
                                                label="10min"
                                                onClick={(evt) => {
                                                    evt.preventDefault();
                                                    onClickInterval(10);
                                                }}
                                            />
                                            <Button
                                                label="20min"
                                                onClick={(evt) => {
                                                    evt.preventDefault();
                                                    onClickInterval(20);
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <DropdownMenuButton
                                            primary
                                            label="~5min"
                                            actions={[
                                                {
                                                    label: '~5min',
                                                    onClick: (evt) => {
                                                        evt.preventDefault();
                                                        onClickInterval(5);
                                                    },
                                                },
                                                {
                                                    label: '10min',
                                                    onClick: (evt) => {
                                                        evt.preventDefault();
                                                        onClickInterval(10);
                                                    },
                                                },
                                                {
                                                    label: '20min',
                                                    onClick: (evt) => {
                                                        evt.preventDefault();
                                                        onClickInterval(20);
                                                    },
                                                },
                                            ]}
                                        />
                                    )
                                }
                            </div>
                        )
                }
            </div>
        </div>
    );
};

DateTimeSelector.defaultProps = {
    selected: null,
    dateFormat: 'DD/MM/YYYY',
    timeFormat: 'HH:mm',
    timezone: (window.user_info && window.user_info.timezone) || 'Europe/Brussels',
    name: 'Date',
    minDate: null,
    maxDate: null,
    required: true,
    noLabel: false,
    prepend: null,
    disabled: false,
    style: {},
    now: false,
    basedOn: moment(),
    device: LAYOUT.DESKTOP,
};

DateTimeSelector.propTypes = {
    id: PropTypes.string.isRequired,
    selected: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    dateFormat: PropTypes.string.isRequired,
    timeFormat: PropTypes.string,
    name: PropTypes.string,
    minDate: PropTypes.instanceOf(moment),
    maxDate: PropTypes.instanceOf(moment),
    required: PropTypes.bool,
    noLabel: PropTypes.bool,
    prepend: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    now: PropTypes.bool,
};

export default DateTimeSelector;
