import React from 'react';
import RenderText from './RenderText';

const RenderStatus = ({
    label, data, large, className,
}) => {
    if (!data) return null;
    return (
        <div className="element-holder">
            <div className="element-label">{label}</div>
            {
                large ? (
                    <div className="element large">
                        <RenderText>{data}</RenderText>
                    </div>
                ) : (
                    <div className="element">
                        <span
                            className={className}
                        >
                            {data}
                        </span>
                    </div>
                )
            }
        </div>
    );
};

export default RenderStatus;
