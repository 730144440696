import { default as actions } from './actions';

export const { fetchDetails } = actions;
export const { fetchList } = actions;
export const { updateHotline } = actions;
export const { createHotline } = actions;
export const { assignHotline } = actions;
export const incomingWS = (data) => (dispatch) => {
    switch (data.type) {
    case 'create':
        dispatch(actions.updateHotlineInStore(data.payload, true));
        break;
    case 'update':
        dispatch(actions.updateHotlineInStore(data.payload));
        break;
    default:
        break;
    }
};

export default {
    fetchDetails,
    fetchList,
    updateHotline,
    createHotline,
    assignHotline,
    incomingWS,
};
