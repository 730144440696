/**
 * Created by lpostula on 14/05/19.
 */
import { default as actions } from './actions';

export const { fetchList } = actions;
export const { updateAlertInPlace } = actions;

export default {
    fetchList,
    updateAlertInPlace,
};
