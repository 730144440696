/**
 * Created by lpostula on 18/05/17.
 */
const FETCH_DETAIL_REQUEST = 'documents/FETCH_DETAIL_REQUEST';
const FETCH_DETAIL_COMPLETED = 'documents/FETCH_DETAIL_COMPLETED';
const FETCH_DETAIL_FAILED = 'documents/FETCH_DETAIL_FAILED';

const FETCH_LIST_REQUEST = 'documents/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'documents/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'documents/FETCH_LIST_FAILED';

const UPDATE_DETAILS_REQUEST = 'documents/UPDATE_DETAILS_REQUEST';
const UPDATE_DETAILS_COMPLETED = 'documents/UPDATE_DETAILS_COMPLETED';
const UPDATE_DETAILS_FAILED = 'documents/UPDATE_DETAILS_FAILED';

const CREATE_DOCUMENT_REQUEST = 'documents/CREATE_DOCUMENT_REQUEST';
const CREATE_DOCUMENT_COMPLETED = 'documents/CREATE_DOCUMENT_COMPLETED';
const CREATE_DOCUMENT_FAILED = 'documents/CREATE_DOCUMENT_FAILED';

const DELETE_DOCUMENT_REQUEST = 'documents/DELETE_DOCUMENT_REQUEST';
const DELETE_DOCUMENT_COMPLETED = 'documents/DELETE_DOCUMENT_COMPLETED';
const DELETE_DOCUMENT_FAILED = 'documents/DELETE_DOCUMENT_FAILED';

export default {
    FETCH_DETAIL_REQUEST,
    FETCH_DETAIL_COMPLETED,
    FETCH_DETAIL_FAILED,
    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,
    UPDATE_DETAILS_REQUEST,
    UPDATE_DETAILS_COMPLETED,
    UPDATE_DETAILS_FAILED,
    CREATE_DOCUMENT_REQUEST,
    CREATE_DOCUMENT_COMPLETED,
    CREATE_DOCUMENT_FAILED,
    DELETE_DOCUMENT_REQUEST,
    DELETE_DOCUMENT_COMPLETED,
    DELETE_DOCUMENT_FAILED,
};
