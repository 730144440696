import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import Selector from '../selectors/selector/Selector';
import './CollectionFilter.scss';

// const SMALL_FILTER_COLUMN_CLASS = 'col-lg-3 col-md-4 col-sm-6 col-xs-12';
// const BIG_FILTER_COLUMN_CLASS = 'col-lg-6 col-md-8 col-sm-12 col-xs-12';
const SMALL_FILTER_COLUMN_CLASS = 'col-xs-3';
const BIG_FILTER_COLUMN_CLASS = 'col-xs-6';

class CollectionFilter extends Component {
    constructor(props) {
        super(props);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.onChangeSortBy = this.onChangeSortBy.bind(this);
        this.onChangeUpdate = this.onChangeUpdate.bind(this);
        this.clearAndStartTimeout = this.clearAndStartTimeout.bind(this);
    }

    doSearch() {
        if (this.props.applyFilter) {
            this.props.applyFilter();
        }
    }

    onSearchChange(evt) {
        if (this.props.setSearchText) {
            this.props.setSearchText(evt.target.value);
            this.clearAndStartTimeout(1000);
        }
    }

    onChangeUpdate() {
        this.doSearch();
    }

    clearAndStartTimeout(delay = 500) {
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(this.doSearch, delay);
    }

    onChangeSortBy(evt) {
        this.props.setSortBy(evt);
        this.doSearch();
    }

    static renderFilterField(name, label, field, big) {
        const filter_class = big
            ? BIG_FILTER_COLUMN_CLASS
            : SMALL_FILTER_COLUMN_CLASS;
        return (
            <div key={name} className={filter_class}>
                {
                    label ? (
                        <div className="input-group filter">
                            {label}
                            {field}
                        </div>
                    ) : (
                        <div className="filter">{field}</div>
                    )
                }
            </div>
        );
    }

    static renderFilterLabelField(label) {
        return (
            <span className="input-group-addon filter">
                {label}
            </span>
        );
    }

    renderFilterInputField(option) {
        let Comp = Selector;
        if (option.custom_component) {
            Comp = option.custom_component;
        }
        return (
            <Comp
                className="form-control"
                onChangeCallback={this.onChangeUpdate}
                {...option}
            />
        );
    }

    renderSearchText() {
        if (!this.props.setSearchText) {
            return null;
        }
        const label = (
            <span className="input-group-addon filter">
                <span className="glyphicon glyphicon-search" />
            </span>
        );
        const field = (
            <input
                value={this.props.search_text}
                type="text"
                onChange={this.onSearchChange}
                className="form-control"
                placeholder={this.props.search_placeholder}
                disabled={false}
            />
        );
        return CollectionFilter.renderFilterField('search_text', label, field);
    }

    renderSortby() {
        if (!this.props.setSortBy) {
            return null;
        }
        const label = CollectionFilter.renderFilterLabelField(i18n.t('Sort by'));
        const field = this.renderFilterInputField({
            name: 'sort_by',
            values: this.props.ordering,
            selected: this.props.selected_ordering,
            onChange: this.onChangeSortBy,
        });
        return CollectionFilter.renderFilterField('sortby', label, field);
    }

    renderFilterActions() {
        if (!this.props.resetFilter) {
            return null;
        }
        return (
            <div key="filter_actions" className="col-xs-12">
                <div className="btn-group filter" role="group">
                    <button
                        className="btn btn-danger"
                        type="reset"
                        onClick={this.props.resetFilter}
                    >
                        {i18n.t('reset')}
                    </button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="collection-filter-container">
                    {
                        this.props.filters.map((f) => {
                            let label;
                            if (f.label) {
                                label = CollectionFilter.renderFilterLabelField(f.label);
                            }
                            const field = this.renderFilterInputField(f);
                            return CollectionFilter.renderFilterField(f.name, label, field, f.big);
                        })
                    }
                    {this.renderSortby()}
                    {this.renderSearchText()}
                </div>
                {this.renderFilterActions()}
            </>
        );
    }
}

CollectionFilter.defaultProps = {
    pristine: true,
    search_placeholder: 'Search...',
    filters: [],
};

CollectionFilter.propTypes = {
    ordering: PropTypes.array,
    selected_ordering: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    search_text: PropTypes.string,
    setSearchText: PropTypes.func,
    setSortBy: PropTypes.func,
    resetFilter: PropTypes.func,
    applyFilter: PropTypes.func,
    is_fetching: PropTypes.bool,
    filters: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string,
            values: PropTypes.oneOfType([
                PropTypes.object,
                PropTypes.array,
            ]),
            selected: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.object,
                PropTypes.array,
            ]),
            onChange: PropTypes.func.isRequired,
            placeholder: PropTypes.string,
            id_getter: PropTypes.func,
            labelizer: PropTypes.func,
            custom_component: PropTypes.func,
            disabled: PropTypes.bool,
            big: PropTypes.bool,
        }),
    ),
};

export default CollectionFilter;
