import React from 'react';
import PropTypes from 'prop-types';

const InputsWrapper = (props) => {
    if (props.disabled) return props.children;
    return (
        <div className={`inputs-wrapper ${props.private ? 'private' : ''} ${props.standalone ? 'standalone' : ''} ${props.noTitle ? 'noTitle' : ''} ${props.className ? props.className : ''}`}>
            {
                props.private
                    && (
                        <div className="lock-icon">
                            <div className="shape circle">
                                <span className="glyphicon glyphicon-eye-close" />
                            </div>
                        </div>
                    )
            }
            {
                props.standalone && !props.noTitle && !props.hidden
                    && <div className="inputs-wrapper-title">{props.title}</div>
            }
            {
                !props.hidden && (
                    <div className="inputs-wrapper-holder">
                        {props.children}
                    </div>
                )
            }
        </div>
    );
};

InputsWrapper.propTypes = {
    id: PropTypes.string.isRequired,
};

InputsWrapper.defaultProps = {
    // When standalone is true, it means it should have a blue background and a title
    standalone: true,
    noTitle: false,
};

export default InputsWrapper;
