import { RSAA } from 'redux-api-middleware';

import types from './types';
import { API_ROOT } from '../../config';

const API_RESOURCE_NAME = 'log';

export const fetchLogs = (long_polling = false) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            {
                type: types.FETCH_LIST_REQUEST,
                meta: { long_polling },
            },
            {
                type: types.FETCH_LIST_COMPLETED,
                meta: { long_polling },
            },
            types.FETCH_LIST_FAILED,
        ],
    },
});

export const fetchDetails = (item_id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${item_id}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_DETAIL_REQUEST,
            types.FETCH_DETAIL_COMPLETED,
            types.FETCH_DETAIL_FAILED,
        ],
    },
});

export const createLog = (values) => ({
    [RSAA]: {
        endpoint: `${API_ROOT}${API_RESOURCE_NAME}/`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(values),
        credentials: 'same-origin',
        types: [
            types.CREATE_LOG_REQUEST,
            types.CREATE_LOG_COMPLETED,
            types.CREATE_LOG_FAILED,
        ],
    },
});

export const editLog = (values) => ({
    [RSAA]: {
        endpoint: `${API_ROOT}${API_RESOURCE_NAME}/${values.id}/`,
        method: 'PATCH',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(values),
        credentials: 'same-origin',
        types: [
            types.EDIT_LOG_REQUEST,
            types.EDIT_LOG_COMPLETED,
            types.EDIT_LOG_FAILED,
        ],
    },
});

export const archiveLog = (id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT}${API_RESOURCE_NAME}/${id}/archive/`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        credentials: 'same-origin',
        types: [
            types.ARCHIVE_LOG_REQUEST,
            types.ARCHIVE_LOG_COMPLETED,
            types.ARCHIVE_LOG_FAILED,
        ],
    },
});

export const unarchiveLog = (id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT}${API_RESOURCE_NAME}/${id}/unarchive/`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        credentials: 'same-origin',
        types: [
            types.UNARCHIVE_LOG_REQUEST,
            types.UNARCHIVE_LOG_COMPLETED,
            types.UNARCHIVE_LOG_FAILED,
        ],
    },
});

export const updateLogInStore = (payload, create = false) => ({
    type: types.FETCH_DETAIL_COMPLETED,
    payload,
    meta: { create },
});

export default {
    fetchLogs,
    fetchDetails,
    createLog,
    editLog,
    archiveLog,
    unarchiveLog,
    updateLogInStore,
};
