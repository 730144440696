import React from 'react';
import { connect } from 'react-redux';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import './AssetTrackOnMap.scss';
import MultiDeviceLayout from '../../app/MultiDeviceLayout';
import AssetSmallMap from '../../../../../dashboard/static/asset/small_map';

const AssetTrackOnMapComponent = (props) => {
    const redirectToMap = (evt) => {
        evt.preventDefault();
        const { id } = props.asset.info;
        const path = `/map/#/${id}`;
        window.location.href = path;
    };

    return (
        <div className={`asset-track-map-root ${props.device}`}>
            <div className="asset-track-map">
                <div className="too-small-map">
                    <div className="link_to_full">
                        <p>Open the map</p>
                        <button className="btn btn-default" onClick={redirectToMap}>
                            <span className="glyphicon glyphicon-resize-full" />
                        </button>
                    </div>
                </div>
                <AssetSmallMap
                    asset={props.asset}
                    fixed
                />
            </div>
        </div>
    );
};

const AssetTrackOnMap = (props) => (
    <MultiDeviceLayout>
        <AssetTrackOnMapComponent
            {...props}
        />
    </MultiDeviceLayout>
);

const mapStateToProps = (state, props) => {
    if (props.loginless) return props.mapStateToProps(state, props);
    const { asset_id } = props;
    const getAllAssetData = assetsSelectors.makeAllAssetData();
    const asset = asset_id && getAllAssetData(state, asset_id);
    return {
        asset,
    };
};

export default connect(mapStateToProps, null)(AssetTrackOnMap);
