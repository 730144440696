/**
 * Created by lpostula on 15/05/17.
 */
import { RSAA } from 'redux-api-middleware';
import types from './types';
import { API_ROOT } from '../../config';

const API_RESOURCE_NAME = 'alert';

export const processAlert = (alert_id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${alert_id}/process.json`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        credentials: 'same-origin',
        types: [
            types.PROCESS_REQUEST,
            types.PROCESS_COMPLETED,
            types.PROCESS_FAILED,
        ],
    },
});

export function changeRating(alert_id, rating) {
    return (dispatch) => {
        if (!alert_id) {
            return Promise.resolve();
        }

        dispatch({
            [RSAA]: {
                endpoint: `${API_ROOT + API_RESOURCE_NAME}/${alert_id}/rate/?rating=${rating}`,
                method: 'GET',
                headers: {
                    'Cache-Control': 'no-cache',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
                },
                credentials: 'same-origin',
                types: [
                    types.PROCESS_REQUEST,
                    types.FETCH_DETAIL_COMPLETED,
                    types.PROCESS_FAILED,
                ],
            },
        });
        return Promise.resolve();
    };
}

export const updateAlertInStore = (data) => ({
    type: types.FETCH_DETAIL_COMPLETED,
    payload: data,
});

export const fetchForAsset = (asset_id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}.json?asset=${asset_id}&page_size=15`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_LIST_REQUEST,
            types.FETCH_LIST_COMPLETED,
            types.FETCH_LIST_FAILED,
        ],
    },
});

export const fetchList = (long_polling = false) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            {
                type: types.FETCH_LIST_REQUEST,
                meta: { long_polling },
            },
            {
                type: types.FETCH_LIST_COMPLETED,
                meta: { long_polling },
            },
            types.FETCH_LIST_FAILED,
        ],
    },
});

export const fetchByIds = (alerts_id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}.json?ids=${alerts_id}`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_LIST_REQUEST,
            types.FETCH_LIST_COMPLETED,
            types.FETCH_LIST_FAILED,
        ],
    },
});

export default {
    processAlert,
    fetchForAsset,
    fetchList,
    fetchByIds,
    updateAlertInStore,
    changeRating,
};
