import React, { useEffect, useState } from 'react';
import './TablePagination.scss';

const SEPARATOR = '...';
const THEMES = {
    DARK: 'dark-theme',
    LIGHT: 'light-theme', // default
};

const DISPLAY_TYPE = {
    TABLET_AND_MOBILE: 'tablet-mobile',
    DESKTOP: 'desktop',
};

const PaginationChevron = (props) => {
    const theme_color = props.theme === THEMES.LIGHT && props.display === DISPLAY_TYPE.DESKTOP
        ? '#027AFF' : '#ffffff';
    const color = props.active ? theme_color : '#C1C8D3';
    const width = props.display === DISPLAY_TYPE.DESKTOP ? 6 : 8;
    const height = props.display === DISPLAY_TYPE.DESKTOP ? 8 : 12;
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.17333 8.5L0.5 4.50363L4.17333 0.5L5.5 1.93869L3.14667 4.50363L5.5 7.06857L4.17333 8.5Z"
                fill={color}
                transform={
                    `${width === 8 ? 'scale(1.5) ' : ''}${props.next ? 'rotate(180 2.5 4.5)' : ''}`
                }
            />
        </svg>
    );
};

const createPagination = (links, current_page) => {
    if (!links || !current_page) return null;
    const constructed_pagination = [];
    let temp = [];
    for (const [key, value] of Object.entries(links)) {
        temp.push({ ...value, rel: key });
    }
    temp.push({ page: current_page, url: null, rel: 'current' });
    temp = temp.sort((a, b) => a.page - b.page);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < temp.length; i++) {
        const element = temp[i];
        if (i - 1 >= 0) {
            if (element.page > temp[i - 1].page + 1) constructed_pagination.push(SEPARATOR);
        }
        constructed_pagination.push(element);
    }
    return constructed_pagination;
};

// TODO missing support for no last page if necessary
const TablePagination = (props) => {
    const [_pagination, setPagination] = useState(
        createPagination(props.links, props.current_page),
    );

    const theme = props.display === DISPLAY_TYPE.DESKTOP ? props.theme : THEMES.LIGHT;

    useEffect(() => {
        setPagination(createPagination(props.links, props.current_page));
    }, [props.current_page, props.total_page]);

    if (!_pagination) return null;
    return (
        <div className={`table-pagination ${theme} ${props.display}`}>
            {props.display === DISPLAY_TYPE.DESKTOP
            && (
                <a
                    href="#"
                    onClick={(evt) => {
                        if (props.links?.prev) {
                            props.navigate(evt, 'prev');
                        }
                    }}
                    className="arrow"
                >
                    <PaginationChevron
                        active={!!props.links?.prev}
                        theme={theme}
                        display={props.display}
                    />
                </a>
            )}
            {
                _pagination.map((v) => {
                    if (v !== SEPARATOR) {
                        if (v.rel === 'current') {
                            return <a className="current-page" key={v.page}>{v.page}</a>;
                        }
                        return (
                            <a
                                className="page"
                                onClick={(evt) => props.navigate(evt, v.rel)}
                                key={v.page}
                            >
                                {v.page}
                            </a>
                        );
                    }
                    return <div className="separator" key="separator">{v}</div>;
                })
            }
            {props.display !== DISPLAY_TYPE.DESKTOP
            && (
                <>
                    <a
                        href="#"
                        onClick={(evt) => {
                            if (props.links?.prev) {
                                props.navigate(evt, 'prev');
                            }
                        }}
                        className="arrow"
                    >
                        <PaginationChevron
                            active={!!props.links?.prev}
                            theme={theme}
                            display={props.display}
                        />
                    </a>
                    <div className="vertical-separator-container">
                        <div className="vertical-separator" />
                    </div>
                </>
            )}
            <a
                href="#"
                onClick={(evt) => {
                    if (props.links?.next) {
                        props.navigate(evt, 'next');
                    }
                }}
                className="arrow"
            >
                <PaginationChevron
                    active={!!props.links?.next}
                    theme={theme}
                    next
                    display={props.display}
                />
            </a>
        </div>
    );
};

TablePagination.defaultProps = {
    theme: THEMES.LIGHT,
    display: DISPLAY_TYPE.DESKTOP,
};

export default TablePagination;
export {
    THEMES,
    DISPLAY_TYPE,
};
