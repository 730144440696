/**
 * Created by gknuts on 29/04/2022.
 */
import { default as actions } from './actions';

export const { fetchList } = actions;
export const incomingWS = (data) => (dispatch) => {
    switch (data.type) {
    case 'update':
        dispatch(actions.updateInStore(data.payload));
        break;
    case 'delete':
        dispatch(actions.removeInStore(data.payload));
        break;
    default:
        break;
    }
};

export default {
    incomingWS,
    fetchList,
};
