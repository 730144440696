import React from 'react';
import PropTypes from 'prop-types';

class AlertDismissable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alertVisible: true,
        };
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
        this.handleAlertShow = this.handleAlertShow.bind(this);
    }

    render() {
        if (this.state.alertVisible) {
            return (
                <div className={`alert alert-${this.props.bsStyle} alert-dismissable`} role="alert">
                    <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                    >
                        <span className="glyphicon glyphicon-remove" />
                    </button>
                </div>
            );
            // @props.children
        } if (this.props.showButton) {
            return (
                <button
                    type="button"
                    className={`btn btn-${this.props.bsStyle}`}
                    onClick={this.handleAlertShow}
                >
                    Signalement en enregistré
                </button>
            );
        }
        return <span />;
    }

    handleAlertDismiss() {
        this.setState({ alertVisible: false });
    }

    handleAlertShow() {
        this.setState({ alertVisible: true });
    }
}

AlertDismissable.propTypes = {
    bsStyle: PropTypes.string,
    showButton: PropTypes.bool,
};

export default AlertDismissable;
