import React, { useContext, useEffect, useState } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import components from 'components';
import moment from 'moment-timezone';
import { interventionsOperations, interventionsSelectors } from 'railfleet_state/ducks/interventions';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { companiesSelectors } from 'railfleet_state/ducks/companies';
import { companiesSettingsSelectors } from 'railfleet_state/ducks/companies_settings';
import { usersSelectors } from 'railfleet_state/ducks/users';

import './InterventionDetail.scss';
import { LoaderContext } from '../../../components/containers/Modals/Modal';
import useFetch from '../../../components/hooks/useFetch';
import useArrayToDict from '../../../components/hooks/useArrayToDict';
import { locationsOperations, locationsSelectors } from '../../../../state/ducks/locations';

const { DetailFrame } = components.frames;
const { TextDisplay, CustomFieldDisplay } = components.display;
const { CutForm } = components.forms;
const { MultiDeviceLayout } = components.app;

const InterventionDetailComponent = (props) => {
    const { registerDataToLoad } = useContext(LoaderContext);
    const { fetchAll } = useFetch();
    const [_workshops, setWorkshops] = useState(null);
    const workshops = useArrayToDict(_workshops, 'id');

    useEffect(() => {
        if (registerDataToLoad) {
            registerDataToLoad({
                id: 'location_name_interventionDetail',
                loaded: props.location_name,
            });
            registerDataToLoad({
                id: 'workshops_interventionDetail',
                loaded: !_.isEmpty(_workshops),
            });
        }
    }, [props.location_name, _workshops]);

    useEffect(() => {
        if (props.ecm_id) {
            fetchAll('/api/workshop.json', {
                company: props.ecm_id,
            }).then((d) => {
                if (d) {
                    setWorkshops(d);
                } else {
                    setWorkshops(null);
                }
            });
        } else {
            setWorkshops(null);
        }
    }, [props.ecm_id]);

    useEffect(() => {
        if (props.intervention
            && props.intervention.poi
            && _.isEmpty(props.locations)
            && !props.locations[props.intervention.poi]
        ) {
            props.fetchLocation(props.intervention.poi);
        }
    }, [props.intervention, props.locations]);

    return (
        <div className="intervention-detail">
            <DetailFrame
                title={i18n.t('General informations')}
            >
                <CutForm
                    device={props.device}
                >
                    <TextDisplay
                        label={i18n.t('Asset')}
                        data={props.asset_name}
                    />
                    <TextDisplay
                        label={i18n.t('Intervention type')}
                        data={props.intervention_type_name}
                    />
                    <TextDisplay
                        label={i18n.t('Assigned to')}
                        data={
                            workshops
                            && !_.isEmpty(workshops)
                            && workshops[props.workshop_in_charge_id]
                            && workshops[props.workshop_in_charge_id].name
                        }
                    />
                    <TextDisplay
                        label={i18n.t('Intervention location')}
                        data={props.location_name}
                    />
                    <TextDisplay
                        label={i18n.t('Planned Start Date')}
                        data={props.date_start_planned}
                    />
                    <TextDisplay
                        label={i18n.t('Planned End Date')}
                        data={props.date_done_planned}
                    />
                    <TextDisplay
                        label={i18n.t('Created by')}
                        data={props.created_by}
                    />
                    <TextDisplay
                        label={i18n.t('Comment')}
                        data={props.comment}
                        large
                    />
                </CutForm>
                <CustomFieldDisplay
                    asset_id={props.asset_id}
                    content_type="intervention"
                    public
                    data={props.intervention}
                />
            </DetailFrame>
            {
                (props.intervention.state === 'done')
                && (
                    <DetailFrame
                        title={i18n.t('Release to service (RTS)')}
                    >
                        <CutForm
                            device={props.device}
                        >
                            <TextDisplay
                                label={i18n.t('Is your opinion in favour of returning asset into operation?')}
                                data={props.rts_question_return ? i18n.t('Yes') : (props.rts_question_return === null ? null : i18n.t('No'))}
                            />
                            <TextDisplay
                                label={i18n.t('Comment')}
                                data={props.rts_question_return_comment}
                                large={!_.isEmpty(props.rts_question_return_comment)}
                            />
                        </CutForm>
                    </DetailFrame>
                )
            }
            {
                props.display_private
                    && (
                        <DetailFrame
                            title={i18n.t('Private')}
                            private
                        >
                            <CustomFieldDisplay
                                asset_id={props.asset_id}
                                content_type="intervention"
                                private
                                data={props.intervention}
                            />
                        </DetailFrame>
                    )
            }
        </div>
    );
};

const InterventionDetail = (props) => (
    <MultiDeviceLayout>
        <InterventionDetailComponent {...props} />
    </MultiDeviceLayout>
);

const mapStateToProps = (state, props) => {
    const intervention_id = props.intervention_id;
    const asset_id = props.asset_id;
    const intervention = (intervention_id && !props.intervention)
        ? interventionsSelectors.getInterventionById(state, intervention_id)
        : props.intervention;
    const date_start_planned = intervention && intervention.date_start_planned && moment(intervention.date_start_planned).format('DD/MM/YYYY HH:mm');
    const date_done_planned = intervention && intervention.date_done_planned && moment(intervention.date_done_planned).format('DD/MM/YYYY HH:mm');
    const company = intervention && intervention.assign_to;
    const location_id = intervention && intervention.poi;
    const created_by_id = intervention && intervention.created_by;
    const comment = intervention && intervention.comment;
    const rts_question_derogation = intervention && intervention.rts_question_derogation;
    const rts_question_derogation_comment = intervention && intervention.rts_question_derogation_comment;
    const rts_question_return = intervention && intervention.rts_question_return;
    const rts_question_return_comment = intervention && intervention.rts_question_return_comment;

    const asset = asset_id && assetsSelectors.getAssetInfos(state, asset_id);
    const asset_name = asset && asset.name;
    const ecm_id = asset && asset.ecm_id;
    const getMaintenanceSettings = assetsSelectors.makeMaintenanceSettingsForAsset();
    const maintenance_settings = asset_id && getMaintenanceSettings(state, asset_id);

    const intervention_types = maintenance_settings && maintenance_settings.intervention_type;
    const intervention_type_id = intervention && intervention.intervention_type;

    const intervention_type_name = intervention_types && intervention_type_id
        && intervention_types[intervention_type_id] && intervention_types[intervention_type_id].name;

    const companies = companiesSelectors.getCompanies(state);
    const assign_to = companies && company && companies[company] && companies[company].name;
    const workshop_in_charge_id = intervention && intervention.workshop_in_charge;
    const locations = locationsSelectors.getLocations(state);

    const location_name = locations && location_id
        && locations[location_id] && locations[location_id].name;
    const users = usersSelectors.getUsers(state);
    const created_by = created_by_id && users
        && users[created_by_id] && users[created_by_id].full_name;
    const private_fields = companiesSettingsSelectors.getPrivateFieldsForUserWithContentType('intervention')(state);
    return {
        intervention: intervention || {},
        asset_name,
        ecm_id,
        locations,
        date_start_planned,
        date_done_planned,
        intervention_type_name,
        assign_to,
        workshop_in_charge_id,
        location_name,
        created_by,
        comment,
        display_private: private_fields && private_fields.length > 0,
        rts_question_derogation,
        rts_question_derogation_comment,
        rts_question_return,
        rts_question_return_comment,
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchInterventionDetail: (intervention_id) => dispatch(
        interventionsOperations.fetchDetails(intervention_id),
    ),
    fetchLocation: (id) => dispatch(locationsOperations.fetchDetails(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InterventionDetail);
