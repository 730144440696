import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import L from 'leaflet';

export default class Marker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    createLeafletElement() {
        return L.marker(
            this.props.position,
            this.props,
        ).on(
            'click',
            this.props.onClick,
        ).on(
            'popupopen',
            this.onPopupOpen.bind(this),
        ).on(
            'popupclose',
            this.onPopupClose.bind(this),
        );
    }

    onPopupOpen(evt) {
        this.setState({ open: true });
    }

    onPopupClose(evt) {
        this.setState({ open: false });
    }

    renderPopup(forceOpen = false) {
        if (React.Children.count(this.props.children) !== 1) {
            return;
        }
        const popup = this.leafletElement.getPopup();
        const wasOpened = popup ? popup.isOpen() : false;
        ReactDOM.render(React.Children.only(this.props.children), this.popup_div, () => {
            if (wasOpened || forceOpen) {
                if (this.context.clusterable) {
                    this.context.map.zoomToShowLayer(this.leafletElement, () => {
                        this.leafletElement.openPopup();
                    });
                } else {
                    this.leafletElement.openPopup();
                }

                this.context.original.setView(
                    this.props.position,
                    this.context.original.getZoom(),
                );
            }
        });
    }

    componentDidMount() {
        this.leafletElement = this.createLeafletElement();
        this.popup_div = document.createElement('div');
        this.leafletElement.bindPopup(this.popup_div);
        if (this.props.opened) {
            this.renderPopup(this.props.opened);
        }

        this.context.map.addLayer(this.leafletElement);
    }

    componentWillUnmount() {
        this.context.map.removeLayer(this.leafletElement);
    }

    componentDidUpdate(prevProps) {
        // very ugly design
        if (this.props.opened || this.state.open) {
            this.renderPopup();
        }
        if (!L.latLng(this.props.position).equals(L.latLng(prevProps.position))) {
            this.leafletElement.setLatLng(this.props.position);
        }
        if (this.props.icon !== prevProps.icon) {
            this.leafletElement.setIcon(this.props.icon);
        }
        if (this.props.zIndexOffset !== prevProps.zIndexOffset) {
            this.leafletElement.setZIndexOffset(this.props.zIndexOffset);
        }
        if (this.props.opacity !== prevProps.opacity) {
            this.leafletElement.setOpacity(this.props.opacity);
        }
        if (this.props.opened !== prevProps.opened) {
            if (this.props.opened) {
                this.context.map.zoomToShowLayer(this.leafletElement, () => {
                    this.leafletElement.openPopup();
                });
            } else {
                this.leafletElement.closePopup();
            }
        }
    }

    render() {
        return null;
    }
}
Marker.contextTypes = {
    clusterable: PropTypes.bool,
    map: PropTypes.object,
    original: PropTypes.object,
};
Marker.defaultProps = {
    onClick: (e) => e.stopPropagation(),
    draggable: false,
    icon: L.Icon.Default,
    keyboard: true,
    title: '',
    alt: '',
    zIndexOffset: 0,
    opacity: 1.0,
    riseOnHover: false,
    riseOffset: 250,
    pane: 'markerPane',
    bubblingMouseEvents: false,
    opened: false,
};
Marker.propTypes = {
    onClick: PropTypes.func,
    draggable: PropTypes.bool,
    // icon: PropTypes.object,
    keyboard: PropTypes.bool,
    title: PropTypes.string,
    alt: PropTypes.string,
    zIndexOffset: PropTypes.number,
    opacity: PropTypes.number,
    riseOnHover: PropTypes.bool,
    riseOffset: PropTypes.number,
    pane: PropTypes.string,
    bubblingMouseEvents: PropTypes.bool,
    position: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
        PropTypes.shape({ lat: PropTypes.number, lon: PropTypes.number }),
    ]).isRequired,
    opened: PropTypes.bool,
};
