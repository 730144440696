/**
 * Created by lpostula on 11/05/17.
 */
import { default as actions } from './actions';

export const { fetchForAsset } = actions;
export const { fetchList } = actions;
export const { fetchByIds } = actions;
export const { changeRating } = actions;

export const processAlerts = (ids) => (dispatch) => {
    for (const id of ids) {
        dispatch(actions.processAlert(id));
    }
    return Promise.resolve();
};

export const incomingWS = (data) => (dispatch) => {
    switch (data.type) {
    case 'update':
        dispatch(actions.updateAlertInStore(data.payload));
        break;
    default:
        break;
    }
};
export default {
    fetchForAsset,
    fetchList,
    fetchByIds,
    processAlerts,
    incomingWS,
    changeRating,
};
