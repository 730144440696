import moment from 'moment-timezone';
import i18n from 'i18next';

const SECONDS = 1;
const MINUTES = 60 * SECONDS;
const HOURS = 60 * MINUTES;
const DAYS = 24 * HOURS;
const MONTHS = 30 * DAYS;
// const YEARS = 365 * DAYS;

const get_last = ({ interval }) => {
    const to = moment();
    const from = moment()
        .subtract(interval, 'seconds');
    return { from, to };
};

const get_previous = ({ interval, unit, so_far }) => {
    const date = moment()
        .subtract(interval, unit);
    const from = moment(date)
        .startOf(unit);
    const to = so_far ? moment() : moment(date)
        .endOf(unit);
    return { from, to };
};

const get_next_interval = ({ interval }) => {
    const from = moment();
    const to = moment()
        .add(interval, 'seconds');
    return { from, to };
};

const get_next = ({ interval, unit, so_far }) => {
    const date = moment()
        .add(interval, unit);
    const from = moment(date)
        .startOf(unit);
    const to = so_far ? moment() : moment(date)
        .endOf(unit);
    return { from, to };
};

const LAST_2_DAYS = { key: 'last 2 days', label: i18n.t('Last 2 days') };
const LAST_3_DAYS = { key: 'last 3 days', label: i18n.t('Last 3 days') };
const LAST_7_DAYS = { key: 'last 7 days', label: i18n.t('Last 7 days') };
const LAST_30_DAYS = { key: 'last 30 days', label: i18n.t('Last 30 days') };

const NEXT_2_DAYS = { key: 'next 2 days', label: i18n.t('Next 2 days') };
const NEXT_7_DAYS = { key: 'next 7 days', label: i18n.t('Next 7 days') };
const NEXT_14_DAYS = { key: 'next 14 days', label: i18n.t('Next 14 days') };
const NEXT_30_DAYS = { key: 'next 30 days', label: i18n.t('Next 30 days') };

const YESTERDAY = { key: 'yesterday', label: i18n.t('Yesterday') };
const DAY_BEFORE = { key: 'day before yesterday', label: i18n.t('Day before yesterday') };
const DAY_BEFORE_2 = { key: 'two days ago', label: i18n.t('Two days ago') };
const THIS_DAY_LAST_WEEK = { key: 'this day last week', label: i18n.t('This day last week') };
const PREVIOUS_WEEK = { key: 'previous week', label: i18n.t('Previous week') };
const PREVIOUS_MONTH = { key: 'previous month', label: i18n.t('Previous month') };

const TODAY = { key: 'today', label: i18n.t('Today') };
const TODAY_SO_FAR = { key: 'today so far', label: i18n.t('Today so far') };
const THIS_WEEK = { key: 'this week', label: i18n.t('This week') };
const THIS_WEEK_SO_FAR = { key: 'this week so far', label: i18n.t('This week so far') };
const THIS_MONTH = { key: 'this month', label: i18n.t('This month') };
const THIS_MONTH_SO_FAR = { key: 'this month so far', label: i18n.t('This month so far') };

const TOMORROW = { key: 'tomorrow', label: i18n.t('Tomorrow') };
const DAY_AFTER = { key: 'day after tomorrow', label: i18n.t('Day after tomorrow') };
const THIS_DAY_NEXT_WEEK = { key: 'this day next week', label: i18n.t('This day next week') };
const NEXT_WEEK = { key: 'next week', label: i18n.t('Next week') };
const NEXT_MONTH = { key: 'next month', label: i18n.t('Next month') };

const LAST_5_MINUTES = { key: 'last 5 minutes', label: i18n.t('Last 5 minutes') };
const LAST_15_MINUTES = { key: 'last 15 minutes', label: i18n.t('Last 15 minutes') };
const LAST_30_MINUTES = { key: 'last 30 minutes', label: i18n.t('Last 30 minutes') };
const LAST_1_HOURS = { key: 'last 1 hour', label: i18n.t('Last 1 hour') };
const LAST_2_HOURS = { key: 'last 2 hours', label: i18n.t('Last 2 hours') };
const LAST_3_HOURS = { key: 'last 3 hours', label: i18n.t('Last 3 hours') };
const LAST_6_HOURS = { key: 'last 6 hours', label: i18n.t('Last 6 hours') };
const LAST_8_HOURS = { key: 'last 8 hours', label: i18n.t('Last 8 hours') };
const LAST_12_HOURS = { key: 'last 12 hours', label: i18n.t('Last 12 hours') };
const LAST_24_HOURS = { key: 'last 24 hours', label: i18n.t('Last 24 hours') };

const NEXT_12_HOURS = { key: 'next 12 hours', label: i18n.t('Next 12 hours') };
const NEXT_24_HOURS = { key: 'next 24 hours', label: i18n.t('Next 24 hours') };

const QUICK_RANGES = {
    LAST_2_DAYS: LAST_2_DAYS.key,
    LAST_3_DAYS: LAST_3_DAYS.key,
    LAST_7_DAYS: LAST_7_DAYS.key,
    LAST_30_DAYS: LAST_30_DAYS.key,
    YESTERDAY: YESTERDAY.key,
    DAY_BEFORE: DAY_BEFORE.key,
    DAY_BEFORE_2: DAY_BEFORE_2.key,
    THIS_DAY_LAST_WEEK: THIS_DAY_LAST_WEEK.key,
    PREVIOUS_WEEK: PREVIOUS_WEEK.key,
    PREVIOUS_MONTH: PREVIOUS_MONTH.key,
    TODAY: TODAY.key,
    TODAY_SO_FAR: TODAY_SO_FAR.key,
    THIS_WEEK: THIS_WEEK.key,
    THIS_WEEK_SO_FAR: THIS_WEEK_SO_FAR.key,
    THIS_MONTH: THIS_MONTH.key,
    THIS_MONTH_SO_FAR: THIS_MONTH_SO_FAR.key,
    LAST_5_MINUTES: LAST_5_MINUTES.key,
    LAST_15_MINUTES: LAST_15_MINUTES.key,
    LAST_30_MINUTES: LAST_30_MINUTES.key,
    LAST_1_HOURS: LAST_1_HOURS.key,
    LAST_2_HOURS: LAST_2_HOURS.key,
    LAST_3_HOURS: LAST_3_HOURS.key,
    LAST_6_HOURS: LAST_6_HOURS.key,
    LAST_8_HOURS: LAST_8_HOURS.key,
    LAST_12_HOURS: LAST_12_HOURS.key,
    LAST_24_HOURS: LAST_24_HOURS.key,
    NEXT_2_DAYS: NEXT_2_DAYS.key,
    NEXT_7_DAYS: NEXT_7_DAYS.key,
    NEXT_14_DAYS: NEXT_14_DAYS.key,
    NEXT_30_DAYS: NEXT_30_DAYS.key,
    TOMORROW: TOMORROW.key,
    DAY_AFTER: DAY_AFTER.key,
    THIS_DAY_NEXT_WEEK: THIS_DAY_NEXT_WEEK.key,
    NEXT_WEEK: NEXT_WEEK.key,
    NEXT_MONTH: NEXT_MONTH.key,
    NEXT_12_HOURS: NEXT_12_HOURS.key,
    NEXT_24_HOURS: NEXT_24_HOURS.key,
};

const QUICK_RANGES_DETAILS = [
    [
        {
            id: LAST_2_DAYS.key,
            label: LAST_2_DAYS.label,
            getter: get_last,
            params: { interval: 2 * DAYS },
        },
        {
            id: LAST_3_DAYS.key,
            label: LAST_3_DAYS.label,
            getter: get_last,
            params: { interval: 2 * DAYS },
        },
        {
            id: LAST_7_DAYS.key,
            label: LAST_7_DAYS.label,
            getter: get_last,
            params: { interval: 7 * DAYS },
        },
        {
            id: LAST_30_DAYS.key,
            label: LAST_30_DAYS.label,
            getter: get_last,
            params: { interval: MONTHS },
        },
    ], [
        {
            id: YESTERDAY.key,
            label: YESTERDAY.label,
            getter: get_previous,
            params: { interval: 1, unit: 'days' },
        },
        {
            id: DAY_BEFORE.key,
            label: DAY_BEFORE.label,
            getter: get_previous,
            params: { interval: 2, unit: 'days' },
        },
        {
            id: DAY_BEFORE_2.key,
            label: DAY_BEFORE_2.label,
            getter: get_previous,
            params: { interval: 3, unit: 'days' },
        },
        {
            id: THIS_DAY_LAST_WEEK.key,
            label: THIS_DAY_LAST_WEEK.label,
            getter: get_previous,
            params: { interval: 7, unit: 'days' },
        },
        {
            id: PREVIOUS_WEEK.key,
            label: PREVIOUS_WEEK.label,
            getter: get_previous,
            params: { interval: 1, unit: 'isoWeek' },
        },
        {
            id: PREVIOUS_MONTH.key,
            label: PREVIOUS_MONTH.label,
            getter: get_previous,
            params: { interval: 1, unit: 'months' },
        },
    ], [
        {
            id: TODAY.key,
            label: TODAY.label,
            getter: get_previous,
            params: { interval: 0, unit: 'days' },
        },
        {
            id: TODAY_SO_FAR.key,
            label: TODAY_SO_FAR.label,
            getter: get_previous,
            params: { interval: 0, unit: 'days', so_far: true },
        },
        {
            id: THIS_WEEK.key,
            label: THIS_WEEK.label,
            getter: get_previous,
            params: { interval: 0, unit: 'isoWeek' },
        },
        {
            id: THIS_WEEK_SO_FAR.key,
            label: THIS_WEEK_SO_FAR.label,
            getter: get_previous,
            params: { interval: 0, unit: 'isoWeek', so_far: true },
        },
        {
            id: THIS_MONTH.key,
            label: THIS_MONTH.label,
            getter: get_previous,
            params: { interval: 0, unit: 'months' },
        },
        {
            id: THIS_MONTH_SO_FAR.key,
            label: THIS_MONTH_SO_FAR.label,
            getter: get_previous,
            params: { interval: 0, unit: 'months', so_far: true },
        },
    ], [
        {
            id: LAST_5_MINUTES.key,
            label: LAST_5_MINUTES.label,
            getter: get_last,
            params: { interval: 5 * MINUTES },
        },
        {
            id: LAST_15_MINUTES.key,
            label: LAST_15_MINUTES.label,
            getter: get_last,
            params: { interval: 15 * MINUTES },
        },
        {
            id: LAST_30_MINUTES.key,
            label: LAST_30_MINUTES.label,
            getter: get_last,
            params: { interval: 30 * MINUTES },
        },
        {
            id: LAST_1_HOURS.key,
            label: LAST_1_HOURS.label,
            getter: get_last,
            params: { interval: HOURS },
        },
        {
            id: LAST_2_HOURS.key,
            label: LAST_2_HOURS.label,
            getter: get_last,
            params: { interval: 2 * HOURS },
        },
        {
            id: LAST_3_HOURS.key,
            label: LAST_3_HOURS.label,
            getter: get_last,
            params: { interval: 3 * HOURS },
        },
        {
            id: LAST_6_HOURS.key,
            label: LAST_6_HOURS.label,
            getter: get_last,
            params: { interval: 6 * HOURS },
        },
        {
            id: LAST_8_HOURS.key,
            label: LAST_8_HOURS.label,
            getter: get_last,
            params: { interval: 8 * HOURS },
        },
        {
            id: LAST_12_HOURS.key,
            label: LAST_12_HOURS.label,
            getter: get_last,
            params: { interval: 12 * HOURS },
        },
        {
            id: LAST_24_HOURS.key,
            label: LAST_24_HOURS.label,
            getter: get_last,
            params: { interval: 24 * HOURS },
        },
    ], [
        {
            id: NEXT_2_DAYS.key,
            label: NEXT_2_DAYS.label,
            getter: get_next_interval,
            params: { interval: 2 * DAYS },
        },
        {
            id: NEXT_7_DAYS.key,
            label: NEXT_7_DAYS.label,
            getter: get_next_interval,
            params: { interval: 7 * DAYS },
        },
        {
            id: NEXT_14_DAYS.key,
            label: NEXT_14_DAYS.label,
            getter: get_next_interval,
            params: { interval: 14 * DAYS },
        },
        {
            id: NEXT_30_DAYS.key,
            label: NEXT_30_DAYS.label,
            getter: get_next_interval,
            params: { interval: 30 * DAYS },
        },
    ], [
        {
            id: TOMORROW.key,
            label: TOMORROW.label,
            getter: get_next,
            params: { interval: 1, unit: 'days' },
        },
        {
            id: DAY_AFTER.key,
            label: DAY_AFTER.label,
            getter: get_next,
            params: { interval: 2, unit: 'days' },
        },
        {
            id: THIS_DAY_NEXT_WEEK.key,
            label: THIS_DAY_NEXT_WEEK.label,
            getter: get_next,
            params: { interval: 7, unit: 'days' },
        },
        {
            id: NEXT_WEEK.key,
            label: NEXT_WEEK.label,
            getter: get_next,
            params: { interval: 1, unit: 'isoWeek' },
        },
        {
            id: NEXT_MONTH.key,
            label: NEXT_MONTH.label,
            getter: get_next,
            params: { interval: 1, unit: 'months' },
        },
    ], [
        {
            id: NEXT_12_HOURS.key,
            label: NEXT_12_HOURS.label,
            getter: get_next_interval,
            params: { interval: 12 * HOURS },
        },
        {
            id: NEXT_24_HOURS.key,
            label: NEXT_24_HOURS.label,
            getter: get_next_interval,
            params: { interval: 24 * HOURS },
        },
    ],
];

export {
    QUICK_RANGES,
    QUICK_RANGES_DETAILS,
};
