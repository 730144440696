/**
 * Created by lpostula on 12/05/17.
 */
import types from './types';
import assetsTypes from '../assets/types';
import { createReducer } from '../../utils';
import {
    filterBySelectedAssets,
    filterBySelectedAssetClasses,
    filterBySelectedLabels,
    filterBySelectedStatus,
    filterBySelectedTechStatus,
    filterBySelectedOperations,
    compute_alert_count,
    compute_event_count,
    compute_intervention_count,
} from './utils';
import {
    getLabelCategoriesWithLabels,
} from '../label_categories/selectors';

/**
 *  FILTERING REDUCER
 *  Be very careful when modifying it, this is not the same reducer
 *  as for the other ducks. This reducer take the all state as state parameter.
 *  So we need to be very careful when modifying something here.
 *  Always use this boilerplate:
 *  const filter_state = Object.assign({}, state.filtering);
 *  ...do your magic on filter_state, as you would on state in other ducks
 *  return Object.assign({}, state, {filtering: filter_state});
 */

const initialState = {
    filtering: {
        init: false,
        unordered_result: [],
        ordered_result: [],
        sort_by: 'asset_name',
        mode: 'all',
        filters: {
            mask_search: [],
            mask_filter: [],
            assets: {
                active: false,
                query: [],
                result: [],
            },
            asset_classes: {
                active: false,
                query: [],
                result: [],
            },
            labels: {
                active: false,
                query: [],
                result: [],
            },
            statuses: {
                active: false,
                query: [],
                result: [],
            },
            tech_statuses: {
                active: false,
                query: [],
                result: [],
            },
            operations: {
                active: false,
                query: [],
                result: [],
            },
            countries: {
                active: false,
                query: [],
                result: [],
            },
            others: {
                active: false,
                query: [],
                result: [],
            },
        },
    },
};

function sort_by_asset_name(a, b) {
    const sa = a.value.toUpperCase();
    const sb = b.value.toUpperCase();
    return -(sa < sb) || +(sa !== sb);
}

function sort_by_asset_class_name(a, b) {
    let sa = a.class_name.toUpperCase();
    let sb = b.class_name.toUpperCase();
    // sort by name inside the class
    if (sa === sb) {
        sa = a.asset_name.toUpperCase();
        sb = b.asset_name.toUpperCase();
    }
    return -(sa < sb) || +(sa !== sb);
}

function sort_by_category(a, b, categ_label_set) {
    // compute best rank for a
    let a_rank = Number.MAX_SAFE_INTEGER;
    for (const label_id of a.labels) {
        for (const categ_label of categ_label_set) {
            if ((label_id === categ_label.id) && (categ_label.rank < a_rank)) {
                a_rank = categ_label.rank;
            }
        }
    }

    let b_rank = Number.MAX_SAFE_INTEGER;
    for (const label_id of b.labels) {
        for (const categ_label of categ_label_set) {
            if ((label_id === categ_label.id) && (categ_label.rank < b_rank)) {
                b_rank = categ_label.rank;
            }
        }
    }
    return a_rank - b_rank;
}

function sort_by_operational_status(a, b) {
    let sa = a.op_status || Number.MAX_SAFE_INTEGER;
    let sb = b.op_status || Number.MAX_SAFE_INTEGER;

    // sort by detailed status inside the main status
    if (sa === sb) {
        sa = a.detailed_status;
        sb = b.detailed_status;
    }
    return -(sa < sb) || +(sa !== sb);
}

// define the tech status priority (higher is better)
const TECH_STATUS_TO_VALUE = {
    unknown: 0,
    no_gps: 1,
    parking: 2,
    vehicle: 3,
    poweron: 4,
    standby: 5,
    traction: 6,
};

function sort_by_asc_value(a, b) {
    return b.value - a.value;
}

function sort_by_tech_status_duration(b, a) {
    // b, a arguments are inverted because it uses decreasing sort order
    return -(a.value < b.value) || +(a.value !== b.value);
}

function sort_by_min_date(a, b) {
    if (!a.value) return 1;
    if (!b.value) return -1;
    return -(a.value < b.value) || +(a.value !== b.value);
}

const get_ordered_result = (array_in, sort_key, state) => {
    // let's make a copy of the array_in;
    const array_out = [].concat(array_in);
    if (!sort_key) return array_out;
    switch (sort_key) {
    case 'asset_name': {
        // for this we need the assets infos
        const array_in_with_value = [];
        for (const asset_id of array_out) {
            if (state.assets.assets_infos[asset_id]) {
                const value = state.assets.assets_infos[asset_id].name;
                array_in_with_value.push({ id: asset_id, value });
            }
        }
        // let's sort it and return the list of id
        return array_in_with_value.sort(
            sort_by_asset_name,
        ).map((c) => c.id);
    }
    case 'asset_class': {
        // for this we need the assets infos and the class info
        const array_in_with_value = [];
        for (const asset_id of array_out) {
            const asset_info = state.assets.assets_infos[asset_id];
            const asset_name = asset_info && asset_info.name;
            const class_id = asset_info && asset_info.asset_class;
            const class_name = state.asset_classes[class_id].name;
            array_in_with_value.push({
                id: asset_id,
                class_name,
                asset_name,
            });
        }
        // let's sort it and return the list of id
        return array_in_with_value.sort(
            sort_by_asset_class_name,
        ).map((c) => c.id);
    }
    case 'operational_status': {
        // for this we need the asset op status and the detailed status
        // maintenance settings for this asset
        const array_in_with_value = [];
        for (const asset_id of array_out) {
            const asset_operationals = state.assets.assets_operationals[asset_id];
            const op_status = asset_operationals.operational_status;
            let detailed_status;
            if (asset_operationals.detailed_operational_status) {
                const user_company_id = state.users.me.company;
                const settings = state.maintenance_settings[user_company_id];
                const d_settings = settings.detailed_operational_status_type;
                if (d_settings[asset_operationals.detailed_operational_status]) {
                    detailed_status = d_settings[asset_operationals.detailed_operational_status];
                }
            }
            array_in_with_value.push({
                id: asset_id,
                op_status,
                detailed_status,
            });
        }

        array_in_with_value.sort(
            sort_by_operational_status,
        );
        return array_in_with_value.map((c) => c.id);
    }
    case 'tech_status': {
        // for this we need the asset gps_value
        const array_in_with_value = [];
        for (const asset_id of array_out) {
            const live_data = state.assets.assets_live_data[asset_id];
            const status_ar = live_data['telematic.status'];
            const asset_status = (status_ar ? status_ar[0] : null) || 'no_gps';
            const value = TECH_STATUS_TO_VALUE[asset_status];

            array_in_with_value.push({
                id: asset_id,
                value,
                status: asset_status,
            });
        }

        array_in_with_value.sort(
            sort_by_asc_value,
        );
        return array_in_with_value.map((c) => c.id);
    }
    case 'tech_status_duration': {
        // for this we need the asset status duration
        const array_in_with_value = [];
        for (const asset_id of array_out) {
            const live_data = state.assets.assets_live_data[asset_id];
            const status_ar = live_data['telematic.status_last_change'];
            const status_last_change = (status_ar ? status_ar[0] : null) || '1970-01-01';

            array_in_with_value.push({
                id: asset_id,
                value: status_last_change,
            });
        }
        return array_in_with_value.sort(
            sort_by_tech_status_duration,
        ).map((c) => c.id);
    }
    case 'alert_count': {
        // for this we need the asset operational
        const { mode } = state.filtering;
        const array_in_with_value = [];
        for (const asset_id of array_out) {
            const asset_op = state.assets.assets_operationals[asset_id];
            const value = compute_alert_count(asset_op, mode);
            array_in_with_value.push({
                id: asset_id,
                value,
            });
        }
        return array_in_with_value.sort(
            sort_by_asc_value,
        ).map((c) => c.id);
    }
    case 'event_count': {
        // for this we need the asset operational
        const { mode } = state.filtering;
        const array_in_with_value = [];
        for (const asset_id of array_out) {
            const asset_op = state.assets.assets_operationals[asset_id];
            const value = compute_event_count(asset_op, mode);
            array_in_with_value.push({
                id: asset_id,
                value,
            });
        }
        return array_in_with_value.sort(
            sort_by_asc_value,
        ).map((c) => c.id);
    }
    case 'next_immobilization_date': {
        // for this we need the asset next_pm_date
        const array_in_with_value = [];
        for (const asset_id of array_out) {
            const asset_op = state.assets.assets_operationals[asset_id];
            const pmd = asset_op.next_pm_date;
            const ind = asset_op.next_intervention_date;
            // take min of both date excluding null
            const min_date = (pmd && ind) ? ((pmd < ind) ? pmd : ind) : (pmd || ind);
            array_in_with_value.push({
                id: asset_id,
                value: min_date,
            });
        }
        return array_in_with_value.sort(
            sort_by_min_date,
        ).map((c) => c.id);
    }
    case 'intervention_count': {
        // for this we need the asset operational
        const { mode } = state.filtering;
        const array_in_with_value = [];
        for (const asset_id of array_out) {
            const asset_op = state.assets.assets_operationals[asset_id];
            const value = compute_intervention_count(asset_op, mode);
            array_in_with_value.push({
                id: asset_id,
                value,
            });
        }
        return array_in_with_value.sort(
            sort_by_asc_value,
        ).map((c) => c.id);
    }
    default: {
        // we'll assume that we do a filter by label_category.
        // this comes in the form "label_category__<cat_id>"
        const splitted_key = sort_key.split('__');
        if (splitted_key.length !== 2) return array_out;
        const categ_id = splitted_key[1];
        if (!categ_id) return array_out;
        const labels_categories = getLabelCategoriesWithLabels(state);
        const { label_set } = labels_categories[categ_id];
        // for this we need the assets labels
        const array_in_with_value = [];
        for (const asset_id of array_out) {
            const asset_label = state.assets.assets_labels[asset_id];
            array_in_with_value.push({
                id: asset_id,
                labels: asset_label.label_set,
            });
        }
        return array_in_with_value.sort(
            (a, b) => sort_by_category(a, b, label_set),
        ).map((c) => c.id);
    }
    }
};

const get_unordered_result = (filters, mode, state) => {
    // first we get the list of asset id
    const assets = Object.keys(state.assets.assets_infos).map((c) => Number(c));
    let result = [].concat(assets);
    // we will start with the action mode
    if (mode.indexOf('alert') !== -1) {
        // we need to filter by alert_open_by...
        const new_result = [];
        for (const asset_id of result) {
            const asset_op = state.assets.assets_operationals[asset_id];
            const alert_count = compute_alert_count(asset_op, mode);
            if (alert_count > 0) {
                new_result.push(asset_id);
            }
        }
        result = new_result;
    } else if (mode.indexOf('event') !== -1) {
        // we need to filter by event_count...
        const new_result = [];
        for (const asset_id of result) {
            const asset_op = state.assets.assets_operationals[asset_id];
            const event_count = compute_event_count(asset_op, mode);
            if (event_count > 0) {
                new_result.push(asset_id);
            }
        }
        result = new_result;
    } else if (mode.indexOf('intervention') !== -1) {
        // we need to filter by intervention_count...
        const new_result = [];
        for (const asset_id of result) {
            const asset_op = state.assets.assets_operationals[asset_id];
            const intervention_count = compute_intervention_count(asset_op, mode);
            if (intervention_count > 0) {
                new_result.push(asset_id);
            }
        }
        result = new_result;
    }
    // we will start with the asset and asset class filter,
    // as those two are a little bit peculiar, because, between them,
    // we do an OR filter, all the others will be AND filter.
    if (filters.assets.active || filters.asset_classes.active) {
        let asset_cand = [];
        if (filters.assets.active) {
            asset_cand = _.intersection(result, filters.assets.result);
        }
        let class_cand = [];
        if (filters.asset_classes.active) {
            class_cand = _.intersection(result, filters.asset_classes.result);
        }
        result = _.union(asset_cand, class_cand);
    }
    // then we do an AND with the labels filter
    if (filters.labels.active) {
        result = _.intersection(result, filters.labels.result);
    }
    // and an AND with the status filter
    if (filters.statuses.active) {
        result = _.intersection(result, filters.statuses.result);
    }
    // and an AND with the tech status filter
    if (filters.tech_statuses.active) {
        result = _.intersection(result, filters.tech_statuses.result);
    }
    if (filters.countries?.active) {
        result = _.intersection(result, filters.countries?.result);
    }
    if (filters.others?.active) {
        result = _.intersection(result, filters.others?.result);
    }
    // and an AND with the operations filter
    if (filters.operations.active) {
        // for this one, the action mode must be preventive relative
        if ([
            'event_preventive_due',
            'event_preventive_due_within_4_weeks',
        ].includes(mode)) {
            result = _.intersection(result, filters.operations.result);
        }
    }
    return result;
};

const isDeepEqual = (arrayA, arrayB) => {
    if (arrayA === arrayB) return true;
    if (arrayA.length !== arrayB.length) return false;
    for (let i = 0, l = arrayA.length; i < l; i += 1) {
        if (arrayA[i] !== arrayB[i]) return false;
    }
    return true;
};

const small_filter_update = (
    cands, filters_to_check, test_sort,
    test_mode, state, replace = false,
) => {
    let should_sort = false;
    let should_update = false;
    let updated = false;
    let { filters } = state.filtering;
    const orig_unordered_result = state.filtering.unordered_result;
    const orig_ordered_result = state.filtering.ordered_result;
    let unordered_result = orig_unordered_result;
    let ordered_result = orig_ordered_result;
    const new_filters = {};
    for (const filter_to_check of filters_to_check) {
        const filter_key = filter_to_check.key;
        const filter_func = filter_to_check.func;
        const filter = filters[filter_key];
        if (!filter.active) continue;
        // first we check the cands against the filter query.
        const labels_categories = getLabelCategoriesWithLabels(state);
        const result = filter_func(filter.query, cands, labels_categories);
        // if it's not match, we go to the next
        let new_result = [];
        for (const cand of cands) {
            if (!result.includes(cand.id)) continue;
            if (!filter.result.includes(cand.id)) {
                // we need to tell that we've update;
                updated = true;
            }
            new_result.push(cand.id);
        }
        if (!replace) {
            new_result = _.union(filter.result, new_result);
        }
        new_filters[filter_key] = {

            ...filter,
            result: new_result,
        };
    }
    // now that we've compute the specifics filter, we need to update the main result
    should_update = replace || updated || test_mode(state.filtering.mode);
    if (should_update) {
        filters = {

            ...filters,
            ...new_filters,
        };
        unordered_result = get_unordered_result(filters, state.filtering.mode, state);
        should_sort = updated;
    }
    // now we sort the results
    should_sort = should_sort || test_sort(state.filtering.sort_by);
    if (should_sort) {
        ordered_result = get_ordered_result(unordered_result, state.filtering.sort_by, state);
    }
    if (isDeepEqual(orig_unordered_result, unordered_result)) {
        unordered_result = orig_unordered_result;
    }
    if (isDeepEqual(orig_ordered_result, ordered_result)) {
        ordered_result = orig_ordered_result;
    }
    return {

        ...state,
        filtering: {

            ...state.filtering,
            filters,
            unordered_result,
            ordered_result,
        },
    };
};

const filteringReducer = createReducer(initialState)({
    [types.SET_SEARCH_TEXT]: (state, action) => {
        const filter_state = {

            ...state.filtering,
            searchText: action.payload,
        };
        return { ...state, filtering: filter_state };
    },
    [types.INIT_FILTER]: (state) => {
        let filtering_state = {
            ...initialState.filtering, ...state.filtering,
        };
        if (!filtering_state) {
            filtering_state = {};
        }
        filtering_state.filters.mask_search = [];
        filtering_state.filters.mask_filter = [];
        if (filtering_state.init) return state;
        const unordered_result = get_unordered_result(
            filtering_state.filters,
            filtering_state.mode,
            state,
        );
        const ordered_result = get_ordered_result(
            unordered_result,
            filtering_state.sort_by,
            state,
        );
        filtering_state = {

            ...filtering_state,
            init: true,
            unordered_result,
            ordered_result,
        };
        return { ...state, filtering: filtering_state };
    },
    [types.SET_SORT_BY]: (state, action) => {
        const filter_state = {

            ...state.filtering,
            // store the new sort
            sort_by: action.payload,
            // compute the sort
            ordered_result: get_ordered_result(
                state.filtering.unordered_result,
                action.payload,
                state,
            ),
        };
        return { ...state, filtering: filter_state };
    },
    [types.SET_ACTION_MODE]: (state, action) => {
        const unordered_result = get_unordered_result(
            state.filtering.filters,
            action.payload,
            state,
        );
        const ordered_result = get_ordered_result(
            unordered_result,
            state.filtering.sort_by,
            state,
        );
        const filter_state = {

            ...state.filtering,
            mode: action.payload,
            unordered_result,
            ordered_result,
        };
        return { ...state, filtering: filter_state };
    },
    [types.SET_FILTER_KEY]: (state, action) => {
        if (!Array.isArray(action.payload)) {
            return state;
        }
        let filter_state = { ...state.filtering };
        // we update the base filters
        const filters = { ...filter_state.filters };
        if (action?.payload) {
            for (const a of action.payload) {
                filters[[a.key]] = {
                    query: a.query,
                    result: a.result,
                    active: a.active,
                };
            }
        }
        // we now update the merged result
        const unordered_result = get_unordered_result(filters, state.filtering.mode, state);
        // we now sort the merged result
        const ordered_result = get_ordered_result(unordered_result, filter_state.sort_by, state);
        filter_state = {

            ...filter_state,
            filters,
            unordered_result,
            ordered_result,
        };
        return { ...state, filtering: filter_state };
    },
    [types.REFRESH_RESULT]: (state) => {
        let filter_state = { ...state.filtering };
        // we update the base filters
        const filters = { ...filter_state.filters };
        // we now update the merged result
        const unordered_result = get_unordered_result(filters, state.filtering.mode, state);
        // we now sort the merged result
        const ordered_result = get_ordered_result(unordered_result, filter_state.sort_by, state);
        filter_state = {

            ...filter_state,
            filters,
            unordered_result,
            ordered_result,
        };
        return { ...state, filtering: filter_state };
    },
    [types.SET_MASK_SEARCH]: (state, action) => {
        let filter_state = { ...state.filtering };
        // we update the base filters
        const filters = { ...filter_state.filters };
        filters.mask_search = action.payload;
        // we now update the merged result
        const unordered_result = get_unordered_result(filters, state.filtering.mode, state);
        // we now sort the merged result
        const ordered_result = get_ordered_result(unordered_result, filter_state.sort_by, state);
        filter_state = {

            ...filter_state,
            filters,
            unordered_result,
            ordered_result,
        };
        return { ...state, filtering: filter_state };
    },
    [types.SET_MASK_FILTER]: (state, action) => {
        let filter_state = { ...state.filtering };
        // we update the base filters
        const filters = { ...filter_state.filters };
        filters.mask_filter = action.payload;
        // we now update the merged result
        const unordered_result = get_unordered_result(filters, state.filtering.mode, state);
        // we now sort the merged result
        const ordered_result = get_ordered_result(unordered_result, filter_state.sort_by, state);
        filter_state = {

            ...filter_state,
            filters,
            unordered_result,
            ordered_result,
        };
        return { ...state, filtering: filter_state };
    },
    [assetsTypes.FETCH_DETAIL_INFOS_COMPLETED]: (state, action) => (
        /**
         * assets infos information are useful for:
         * filtering:
        /*      - asset
        /*      - asset_class
        /*    sorting:
        /*      - asset
        /*      - asset_class
        /*      - tech_status (if the asset become assigned)
        /*      - tech_status_duration (if the asset become assigned)
         */
        small_filter_update(
            [action.payload],
            [
                { key: 'assets', func: filterBySelectedAssets },
                { key: 'asset_classes', func: filterBySelectedAssetClasses },
            ],
            (key) => [
                'asset_class', 'asset_name',
                'tech_status', 'tech_status_duration',
            ].includes(key),
            () => false,
            state,
        )
    ),
    [assetsTypes.FETCH_LIST_INFOS_COMPLETED]: (state, action) => (
        /**
         * assets infos information are useful for:
         * filtering:
        /*      - asset
        /*      - asset_class
        /*    sorting:
        /*      - asset
        /*      - asset_class
        /*      - tech_status (if the asset become assigned)
        /*      - tech_status_duration (if the asset become assigned)
         */
        small_filter_update(
            action.payload,
            [
                { key: 'assets', func: filterBySelectedAssets },
                { key: 'asset_classes', func: filterBySelectedAssetClasses },
            ],
            (key) => [
                'asset_class', 'asset_name',
                'tech_status', 'tech_status_duration',
            ].includes(key),
            () => false,
            state,
            action.meta && action.meta.long_polling,
        )
    ),
    [assetsTypes.FETCH_DETAIL_LABELS_COMPLETED]: (state, action) => (
        /**
         * assets labels information are useful for:
         * filtering:
        /*      - labels
        /*    sorting:
        /*      - labels
         */
        small_filter_update(
            [action.payload],
            [{ key: 'labels', func: filterBySelectedLabels }],
            (key) => key.startsWith('label_category'),
            () => false,
            state,
        )
    ),
    [assetsTypes.FETCH_LIST_LABELS_COMPLETED]: (state, action) => (
        /**
         * assets labels information are useful for:
         * filtering:
        /*      - labels
        /*    sorting:
        /*      - labels
         */
        small_filter_update(
            action.payload,
            [{ key: 'labels', func: filterBySelectedLabels }],
            (key) => key.startsWith('label_category'),
            () => false,
            state,
            action.meta && action.meta.long_polling,
        )
    ),
    [assetsTypes.FETCH_DETAIL_OPERATIONALS_COMPLETED]: (state, action) => (
        /**
         * assets operationals information are useful for:
         *    filtering:
        /*      - status
        /*      - operations
        /*    sorting:
        /*      - status
        /*      - alert_count
        /*      - event_count
        /*      - intervention_count
        /*    action_mode:
        /*      - alert_count_by_<company>
        /*      - event_unplanned
        /*      - event_planned
        /*      - event_open
        /*      - intervention_in_progress
        /*      - intervention_today
        /*      - intervention_start_after_today
        /*      - intervention_open
         */
        small_filter_update(
            [action.payload],
            [
                { key: 'statuses', func: filterBySelectedStatus },
                { key: 'operations', func: filterBySelectedOperations },
            ],
            (key) => [
                'operational_status', 'alert_count',
                'event_count', 'intervention_count',
            ].includes(key),
            (mode) => {
                if (mode.startsWith('alert_open_by__')) {
                    return true;
                }
                if (mode.startsWith('event')) {
                    return true;
                }
                if (mode.startsWith('intervention')) {
                    return true;
                }
                return false;
            },
            state,
        )
    ),
    [assetsTypes.FETCH_LIST_OPERATIONALS_COMPLETED]: (state, action) => (
        /**
         * assets operationals information are useful for:
         *    filtering:
        /*      - status
        /*      - operations
        /*    sorting:
        /*      - status
        /*      - alert_count
        /*      - event_count
        /*      - intervention_count
         */
        small_filter_update(
            action.payload,
            [
                { key: 'statuses', func: filterBySelectedStatus },
                { key: 'operations', func: filterBySelectedOperations },
            ],
            (key) => [
                'operational_status', 'alert_count',
                'event_count', 'intervention_count',
            ].includes(key),
            (mode) => {
                if (mode.startsWith('alert_open_by__')) {
                    return true;
                }
                return false;
            },
            state,
            action.meta && action.meta.long_polling,
        )
    ),
    [assetsTypes.FETCH_DETAIL_LIVE_DATA_COMPLETED]: (state, action) => (
        /**
         * assets live_data information are useful for:
         * filtering:
        /*      - tech_status
        /*    sorting:
        /*      - tech_status
        /*      - tech_status_duration
         */
        small_filter_update(
            [action.payload],
            [{ key: 'tech_statuses', func: filterBySelectedTechStatus }],
            (key) => [
                'tech_status', 'tech_status_duration',
            ].includes(key),
            () => false,
            state,
        )
    ),
    [assetsTypes.FETCH_LIST_LIVE_DATA_COMPLETED]: (state, action) => (
        /**
         * assets live_data information are useful for:
         *    filtering:
        /*      - tech_status
        /*    sorting:
        /*      - tech_status
        /*      - tech_status_duration
         */
        small_filter_update(
            action.payload,
            [{ key: 'tech_statuses', func: filterBySelectedTechStatus }],
            (key) => [
                'tech_status', 'tech_status_duration',
            ].includes(key),
            () => false,
            state,
            action.meta && action.meta.long_polling,
        )
    ),
});

export default filteringReducer;
