/**
 * Created by lpostula on 27/02/18.
 */
import types from './types';
import { createReducer, apiHelpers } from '../../utils';

/* State shape
{
    {company_id: companies_settings},
}
 */

const initialState = {};

const listReducer = createReducer(initialState)({
    [types.FETCH_LIST_COMPLETED]: apiHelpers.fetch_list_complete,
});

export default listReducer;
