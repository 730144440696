/**
 * Created by lpostula on 11/05/17.
 */
const FETCH_LIST_REQUEST = 'asset_classes/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'asset_classes/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'asset_classes/FETCH_LIST_FAILED';

export default {
    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,
};
