/**
 * Created by gknuts on 27/09/18.
 */
const FETCH_LIST_REQUEST = 'telematics/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'telematics/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'telematics/FETCH_LIST_FAILED';

const SEND_QUERY_REQUEST = 'telematics/SEND_QUERY_REQUEST';
const SEND_QUERY_SUCCESS = 'telematics/SEND_QUERY_SUCCESS';
const SEND_QUERY_FAILED = 'telematics/SEND_QUERY_FAILED';
const SEND_QUERY_CANCEL = 'telematics/SEND_QUERY_CANCEL';

export default {
    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,

    SEND_QUERY_REQUEST,
    SEND_QUERY_SUCCESS,
    SEND_QUERY_FAILED,

    SEND_QUERY_CANCEL,
};
