/**
 * Created by lpostula on 11/10/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import formatNumber from 'railfleet/utils/formatNumber';
import { prettyDate } from 'railfleet/utils/prettyDate';

import './TelematicValue.scss';

class LabelValue extends React.PureComponent {
    render() {
        const {
            value, color, unit, date, link,
        } = this.props;

        let valueOut = value;
        if (typeof value === 'number') {
            valueOut = formatNumber(value);
        }

        return (
            <div
                className={`telematic-value-indicator ${link ? 'linked' : ''}`}
                title={prettyDate(date) || ''}
                onClick={link ? () => window.open(link, '_blank') : null}
            >
                <span className={`label label-${color}`}>
                    {`${valueOut} ${unit}`}
                </span>
            </div>
        );
    }
}

class TextValue extends React.PureComponent {
    render() {
        const {
            value, color, unit, date, link,
        } = this.props;

        let valueOut = value;
        if (typeof value === 'number') {
            valueOut = formatNumber(value);
        }

        return (
            <div
                className={`telematic-value text-${color} ${link ? 'linked' : ''}`}
                title={prettyDate(date) || ''}
                onClick={link ? () => window.open(link, '_blank') : null}
            >
                {`${valueOut} ${unit}`}
            </div>
        );
    }
}

class GaugeValue extends React.PureComponent {
    render() {
        const {
            value, color, date, link,
        } = this.props;

        let outer_text;
        let inner_text;
        if (value <= 50) {
            outer_text = (
                <span className={`progress-bar-text text-${color}`}>
                    {`${Math.round(value)}%`}
                </span>
            );
        } else {
            inner_text = `${Math.round(value)}%`;
        }
        return (
            <div
                className={`telematic-gauge-indicator ${link ? 'linked' : ''}`}
                title={prettyDate(date) || ''}
                onClick={link ? () => window.open(link, '_blank') : null}
            >
                <div className={`progress ${color}`}>
                    <div
                        className={`progress-bar progress-bar-${color}`}
                        role="progressbar"
                        style={{
                            width: `${value}%`, ariaValuemin: 0, ariaValuemax: 100, ariaValuenow: value,
                        }}
                    >
                        {inner_text}
                    </div>
                    {outer_text}
                </div>
            </div>
        );
    }
}

class TelematicField extends React.Component {
    shouldComponentUpdate(nextProps) {
        const { props } = this;
        if (props.value !== nextProps.value
            || props.date !== nextProps.date) {
            return true;
        }

        return false;
    }

    render() {
        const { props } = this;

        let { value } = props;

        const { date } = props;
        // ref date is date of reference to see if a value is "old"
        // if ref_date is undefined it will use "now"
        const ref_date = moment.isMoment(props.ref_date) ? props.ref_date : moment(props.ref_date);
        const cfg = props.config;

        if (value === null || value === undefined) {
            return null;
        }
        let color = 'primary';

        const mDate = moment.isMoment(date) ? date : moment(date);

        const dt = ref_date - mDate;

        const expiration_delay = cfg.expiration_delay * 60 * 1000;
        if (dt > expiration_delay) {
            color = 'old';
        } else if (cfg.low_threshold !== null && cfg.high_threshold !== null) {
            // do we have a threshold config ?
            // since we user <= it works for equality with low and mid threshold
            if (value <= cfg.low_threshold) {
                color = cfg.low_color;
            } else if (value <= cfg.high_threshold) {
                color = cfg.mid_color;
            } else {
                color = cfg.high_color;
            }
        }

        let { unit } = cfg;
        let Comp = (unit === '%') ? GaugeValue : LabelValue;

        if (props.textOnly) {
            Comp = TextValue;
        }

        if (unit === 'bool' || unit === 'is_master') {
            unit = '';
            if (color !== 'old') {
                color = value ? cfg.low_color : cfg.high_color;
            }
            value = value ? 'on' : 'off';
        } else if (unit === 'str' || unit === 'text') {
            // force cast to string to avoid user locale number formatting
            unit = '';
            value = String(value);
        } else if (unit === 'train_number') {
            unit = '';
        }

        return (
            <Comp
                value={value}
                color={color}
                date={date}
                unit={unit}
                link={props.link}
            />
        );
    }
}

TelematicField.propTypes = {
    config: PropTypes.object,
    value: PropTypes.any,
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

// *********************************************************

export {
    TelematicField,
    LabelValue,
    GaugeValue,
};
