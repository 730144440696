import React from 'react';
import './CallHotlineButton.scss';
import CallIcon from './CallIcon.svg';

const CallHotlineButton = (props) => (
    <button
        className={`call-hotline-button ${props.disabled ? 'disabled' : ''}`}
        onClick={!props.disabled ? props.onClick : null}
    >
        <img className="call-icon" src={CallIcon} alt="call icon" />
    </button>
);

export default CallHotlineButton;
