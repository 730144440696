import React from 'react';
import './CollectionPagination.css';
// blindMode mean if you don't know how many page
export default (props) => (
    <ul className="pagination">
        {
            props.links.first ? (
                <li>
                    <a href="#" onClick={(evt) => props.navigate(evt, 'first')}>
                        <span className="glyphicon glyphicon-chevron-left" />
                    </a>
                </li>
            ) : null
        }
        <li
            className={props.links.prev ? '' : 'disabled'}
        >
            <a href="#" onClick={(evt) => props.navigate(evt, 'prev')}>
                <span className="glyphicon glyphicon-chevron-left" />
            </a>
        </li>
        <li
            className="active"
        >
            <a href="#" onClick={(evt) => evt.preventDefault()}>
                {`${props.current_page} ${props.total_page ? `/ ${props.total_page}` : ''} `}
            </a>
        </li>
        <li
            className={props.links.next ? '' : 'disabled'}
        >
            <a
                href="#"
                onClick={(evt) => props.navigate(evt, 'next')}
            >
                <span className="glyphicon glyphicon-chevron-right" />
            </a>
        </li>
        {
            props.links.last ? (
                <li>
                    <a href="#" onClick={(evt) => props.navigate(evt, 'last')}>
                        <span className="glyphicon glyphicon-chevron-right" />
                    </a>
                </li>
            ) : null
        }
    </ul>
);
