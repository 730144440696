import i18n from 'i18next';

const intervention_steps = [
    {
        state: 'planned',
        field: 'date_start_actual',
        user_field: 'start_actual_by',
        label: i18n.t('Date Started'),
        nextStepLabel: i18n.t('Start Intervention'),
    },
    {
        state: 'started',
        field: 'date_done_actual',
        user_field: 'done_actual_by',
        label: i18n.t('Date Done'),
        nextStepLabel: i18n.t('Close Intervention'),
    },
];

const intervention_cancelled_steps = [
    {
        state: 'cancelled',
        field: 'date_cancelled',
        user_field: 'cancelled_by',
        label: i18n.t('Date cancelled'),
    },
];

const intervention_handover_steps = [
    {
        state: 'planned',
        field: 'date_transfer_in',
        user_field: 'transfer_in_by',
        label: i18n.t('Date Transfer in'),
        nextStepLabel: i18n.t('Start Transfer In'),
    },
    {
        state: 'transfer_in',
        field: 'date_wait_in',
        user_field: 'wait_in_by',
        label: i18n.t('Date Wait in'),
        nextStepLabel: i18n.t('Start Wait in'),
    },
    {
        state: 'wait_in',
        field: 'date_start_actual',
        user_field: 'start_actual_by',
        label: i18n.t('Date Started'),
        nextStepLabel: i18n.t('Start Intervention'),
    },
    {
        state: 'started',
        field: 'date_done_actual',
        user_field: 'done_actual_by',
        label: i18n.t('Date Out Workshop'),
        nextStepLabel: i18n.t('Finish Intervention'),
    },
    {
        state: 'wait_out',
        field: 'date_transfer_out',
        user_field: 'transfer_out_by',
        label: i18n.t('Date Transfer out'),
        nextStepLabel: i18n.t('Start Transfer Out'),
    },
    {
        state: 'transfer_out',
        field: 'date_transfer_out_finished',
        user_field: 'transfer_out_finished_by',
        label: i18n.t('Date Transfer finished'),
        nextStepLabel: i18n.t('Finish Transfer and Close Intervention'),
    },
];

const getInterventionSteps = (intervention) => {
    if (!intervention) return null;
    if (!intervention.interventioneventaction) return null;
    let steps = intervention_steps;
    if (intervention.state === 'cancelled') {
        steps = intervention_cancelled_steps;
    } else if (intervention.has_handover_process) {
        steps = intervention_handover_steps;
    }
    return steps;
};

export const getCurrentStep = (intervention, steps) => {
    let s = steps;
    if (!s) s = getInterventionSteps(intervention);
    let step;
    switch (intervention.state) {
    case 'planned':
        step = s[0];
        break;
    case 'in_progress': {
        let find = false;
        let i = 0;
        while (!find && i < s.length) {
            if (!intervention[s[i].field]) {
                find = true;
                step = s[i];
            } else {
                i += 1;
            }
        }
        break;
    }
    case 'cancelled':
        step = s[0];
        break;
    default:
        step = null;
        break;
    }
    return step;
};

// Utilisé par la liste des intervention sur la page intervention
export default getInterventionSteps;
