import React from 'react';
import moment from 'moment-timezone';
import CutForm from '../cutForm/CutForm';
import RenderData from './RenderData';
import RenderNoData from './RenderNoData';

const RenderCustomField = (props) => {
    if (!props.custom_fields) return null;
    const fields = props.custom_fields.map((custom_field) => {
        const { key } = custom_field;
        const { label } = custom_field;
        const data_id = props.data[key];
        let data;
        if (custom_field.data_type === 'enum') {
            for (const value of custom_field.values) {
                if (value.value === data_id) data = value.label;
            }
            if (!data && custom_field.default_value) {
                for (const value of custom_field.values) {
                    if (value.value === custom_field.default_value) data = value.label;
                }
            }
        } else if (custom_field.data_type === 'bool') {
            data = data_id ? i18n.t('Yes') : i18n.t('No');
            if (!data) {
                data = custom_field.default_value;
            }
        } else if (custom_field.data_type === 'date') {
            data = data_id && moment(data_id).format('DD/MM/YYYY HH:mm');
            if (!data && custom_field.default_value) {
                data = moment(custom_field.default_value).format('DD/MM/YYYY HH:mm');
            }
        } else {
            data = data_id;
        }
        if (!data) {
            return (
                <RenderNoData
                    key={custom_field.id}
                    label={label}
                />
            );
        }
        return (
            <RenderData
                key={custom_field.id}
                label={label}
                data={data}
                large={!custom_field.small}
            />
        );
    });
    return (
        <CutForm
            device={props.device}
        >
            { fields }
        </CutForm>
    );
};

export default RenderCustomField;
