import types from './types';
import { createReducer, apiHelpers } from '../../utils';

const initialState = {};

const listReducer = createReducer(initialState)({
    [types.FETCH_LIST_COMPLETED]: (state, action) => {
        let new_state = { ...state };
        if (!action?.payload) return new_state;
        for (const reading of action.payload) {
            const { id } = reading;
            if (id) {
                const old_item = new_state[reading.counter] || {};
                const new_data = {};
                new_data[reading.counter] = old_item;
                new_data[reading.counter][id] = { ...old_item[id], ...reading };
                new_state = { ...new_state, ...new_data };
            }
        }
        return new_state;
    },
    [types.FETCH_DETAIL_COMPLETED]: apiHelpers.fetch_details_partial,
});

export default listReducer;
