import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import components from 'components';
import i18n from 'i18next';
import { assetsOperations, assetsSelectors } from 'railfleet_state/ducks/assets';

import CorrectiveEventForm from '../correctiveEventForm/correctiveEventForm';
import './StandaloneEventForm.scss';
import usePerm from '../../../components/hooks/usePerm';
import { LAYOUT } from '../../../components/app/MultiDeviceLayout';
import Modal from '../../../components/containers/Modals/Modal';
import MODAL_CONSTANTS from '../../../components/containers/Modals/Constants';
import OperationalStatusForm from '../operationalStatusForm/OperationalStatusForm';
import ConfirmationDialog from '../../../components/containers/ConfirmationDialog/ConfirmationDialog';
import HeaderModal from '../../../components/headers/headerModal/HeaderModal';
import HomologationContextManager from '../../../../../dashboard/static/dashboard/homologationContextManager';

const { MultiDeviceLayout, WithLoginless } = components.app;
const {
    FormManager,
    InputsWrapper,
    FormDropdown,
    FormCheckbox,
} = components.forms;
const { THEMES } = components.selectors;
const { Toast } = components.frames;

const StandaloneEventFormComponent = (props) => {
    const [_assetId, setAssetId] = useState(null);
    const [_mType, setMType] = useState(null);
    const [_createdEvent, setCreatedEvent] = useState(null);
    const hasPerm = usePerm(_assetId || null);
    const [_statusForm, setStatusForm] = useState(null);

    useEffect(() => {
        if (props.asset_id) setAssetId(props.asset_id);
        if (props.maintenance_type) setMType(props.maintenance_type);
        props.fetchOperationalsList();
    }, []);

    const finalCallback = () => {
        setMType(null);
        setAssetId(null);
        if (props.callback) props.callback(_createdEvent);
        if (props.createCallback) props.createCallback(_createdEvent);
        if (!props.callback && !props.createCallback) {
            props.router.push('/event/create/asset/');
        }
    };

    const callback = (event) => {
        let status_to_be_changed = false;
        if (!props.loginless && props.asset) {
            if (event.impact_status > props.asset.operational_status) {
                if (hasPerm('aggravate_status')) {
                    setStatusForm(event.impact_status);
                    status_to_be_changed = true;
                }
            }
        }
        setCreatedEvent(event);
        if (!status_to_be_changed) {
            finalCallback();
        }
    };

    const getCorrectiveHelpText = (
        <div>
            <p>{i18n.t('corrective_form_corrective_help_text')}</p>
        </div>
    );

    const getServicingHelpText = (
        <div>
            <p>{i18n.t('corrective_form_servicing_help_text')}</p>
            <p>{i18n.t('corrective_form_servicing_help_text2')}</p>
            <ul>
                <li>{i18n.t('corrective_form_servicing_sample1')}</li>
                <li>{i18n.t('corrective_form_servicing_sample2')}</li>
                <li>{i18n.t('corrective_form_servicing_sample3')}</li>
                <li>{i18n.t('corrective_form_servicing_sample4')}</li>
                <li>...</li>
            </ul>
        </div>
    );

    const onCloseStatusForm = () => {
        setStatusForm(null);
        finalCallback();
    };

    const operationalStatusCallback = (data) => {
        onCloseStatusForm();
        finalCallback();
        return ConfirmationDialog({
            confirmation_message: i18n.t('The operational status has been successfully Changed.'),
            buttons_text: ['Ok'],
        });
    };

    return (
        <div className={`standalone-holder${props.driverLogbook ? ' logbook' : ''}`}>
            {
                _createdEvent
                    && (
                        <Toast
                            text={`${i18n.t('Your event has been successfully created')}: M${_createdEvent.id}`}
                            onClick={(evt) => {
                                if (evt) {
                                    evt.preventDefault();
                                    evt.stopPropagation();
                                }
                                setCreatedEvent(null);
                            }}
                        />
                    )
            }
            <div className={`form-main  standalone-form ${props.device}`}>
                {
                    (!props.loginless)
                        && (
                            <InputsWrapper
                                id="create_new"
                                title={i18n.t('Create a new defect report')}
                            >
                                <FormManager>
                                    <div className="standalone-fields-holder">
                                        <FormDropdown
                                            id="asset"
                                            label={i18n.t('Asset')}
                                            defaultValue={
                                                props.params
                                            && props.params.asset_id
                                            && parseInt(props.params.asset_id, 10)
                                            }
                                            is_asset_SelectFilter
                                            values={props.assets}
                                            value={_assetId}
                                            id_getter={(a) => a.id}
                                            labelizer={(a) => `${a.name} [${a.class_name}]`}
                                            virtual
                                            clearable={false}
                                            theme={THEMES.FORM_LIGHT}
                                            disabled={!!props.driverLogbook}
                                            onChange={(arg) => {
                                                if (arg !== null && arg !== 'null') {
                                                    setAssetId(arg);
                                                    props.router.push(`/event/create/asset/${arg}/${_mType || ''}`);
                                                    props.setParams({
                                                        asset_id: arg,
                                                    });
                                                }
                                            }}
                                        />
                                        <FormCheckbox
                                            id="maintenance_type"
                                            label={i18n.t('Maintenance Type')}
                                            defaultValue={
                                                props.params
                                            && props.params.maintenance_type
                                            }
                                            values={[
                                                {
                                                    id: 'corrective',
                                                    label: i18n.t('corrective_form_corrective_report'),
                                                    description: getCorrectiveHelpText,
                                                },
                                                {
                                                    id: 'servicing',
                                                    label: i18n.t('corrective_form_servicing_report'),
                                                    description: getServicingHelpText,
                                                },
                                            ]}
                                            /* eslint-disable-next-line max-len */
                                            // This is a temporary fix until we can "reset" 2 form manager used in the same page
                                            /* eslint-disable-next-line max-len */
                                            // because otherwise, fields in corrective form stay registered for the servicing form
                                            // preventing from validating the form.
                                            disabled={props.driverLogbook && !!_mType}
                                            selectedValue={_mType}
                                            onChange={(arg) => {
                                                setMType(arg);
                                                if (_assetId !== null && _assetId !== 'null' && arg !== null && arg !== 'null') {
                                                    props.router.push(`/event/create/asset/${_assetId}/${arg}`);
                                                }
                                                if (props.setParams) {
                                                    props.setParams({
                                                        maintenance_type: arg,
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                </FormManager>
                            </InputsWrapper>
                        )
                }
                {
                    _assetId && _mType
                        && (
                            <CorrectiveEventForm
                                asset_id={_assetId}
                                maintenance_type={_mType}
                                callback={callback}
                                standalone={!props.loginless}
                                cancelCallback={props.cancelCallback}
                                driverLogbook={props.driverLogbook}
                                defaultCustomFields={props.defaultCustomFields}
                                {...props.loginless ? props : {}}
                            />
                        )
                }
                {
                    _statusForm
                    && (
                        <Modal
                            layout={
                                props.device === LAYOUT.DESKTOP
                                    ? MODAL_CONSTANTS.LAYOUT.NONE
                                    : MODAL_CONSTANTS.LAYOUT.FULL
                            }
                            position={MODAL_CONSTANTS.POSITION.RIGHT}
                            onClose={onCloseStatusForm}
                        >
                            <div className="operational-status-modal">
                                <HeaderModal
                                    title={i18n.t('Change current asset operational status')}
                                    onCloseModal={onCloseStatusForm}
                                />
                                <OperationalStatusForm
                                    asset_id={_assetId}
                                    title={i18n.t('Change current asset operational status')}
                                    textSubmitRTO={i18n.t('Change operational status and Return to operation')}
                                    textSubmit={i18n.t('Confirm change of operational status')}
                                    operationalStatusCallback={operationalStatusCallback}
                                    impact_status_id={_statusForm}
                                    choice={false}
                                    overrideDevice={LAYOUT.MOBILE}
                                    autoRefresh
                                    noPermMessage
                                    onCancel={onCloseStatusForm}
                                    handleCloseInside
                                    noScreenshot
                                />
                            </div>
                        </Modal>
                    )
                }
            </div>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    if (props.loginless) return props.mapStateToProps(state, props);
    const assets = assetsSelectors.getAssetsInfos(state);
    const asset_id = props.params && props.params.asset_id ? parseInt(props.params.asset_id, 10) : null;
    return ({
        assets,
        asset: asset_id && assetsSelectors.getAssetOperationals(state, asset_id),
    });
};
const StandaloneEventForm = (props) => (
    <MultiDeviceLayout>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <HomologationContextManager
            {...props}
        >
            <StandaloneEventFormComponent
                {...props}
            />
        </HomologationContextManager>
    </MultiDeviceLayout>
);

const mapDispatchToProps = (dispatch, props) => {
    if (props.loginless) return props.mapDispatchToProps(dispatch, props);
    return ({
        fetchOperationalsList: () => dispatch(assetsOperations.fetchOperationalsList()),
    });
};

export default WithLoginless(
    connect(mapStateToProps, mapDispatchToProps)(StandaloneEventForm),
);
