/**
 * Created by lpostula on 03/07/17.
 */
import types from './types';
import { createReducer, apiHelpers } from '../../utils';

/* State shape
{
    {counter_id: counter},
}
 */

const initialState = {};

const listReducer = createReducer(initialState)({
    [types.FETCH_LIST_COMPLETED]: apiHelpers.fetch_list_complete,
    [types.FETCH_DETAIL_COMPLETED]: apiHelpers.fetch_details_partial,
    // [types.FETCH_DETAIL_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.UPDATE_DONE_USAGES_COMPLETED]: apiHelpers.fetch_details_complete,
});

export default listReducer;
