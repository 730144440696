import React from 'react';
import './NumberedTitle.scss';

const NumberedTitle = (props) => (
    <div className="numbered-title">
        <span className="number shape circle filled black">{props.number}</span>
        <span className="title">{props.title}</span>
    </div>
);

export default NumberedTitle;
