import React from 'react';
import i18n from 'i18next';

import './MergeController.scss';
import icon from './merge.svg';
import icon_white from './merge-white.svg';
import reset_zoom from './reset-zoom.svg';

//

const MergeController = (props) => {
    const zoomSpan = (
        props.zoomedIn
            ? (
                <span>
                    <button onClick={props.zoomOut} className="cancel">
                        <img src={reset_zoom} alt="" />
                &nbsp;
                        {i18n.t('Reset zoom')}
                    </button>
                </span>
            )
            : (<span></span>)
    );
    if (props.enabled) {
        const enough = props.selectCount >= 2;
        return (
            <div className="merge-controller">
                {zoomSpan}
                <button onClick={props.disable} className="cancel">{i18n.t('Cancel')}</button>
                {!enough && <span>{i18n.t('Please select min. 2 symbols to visually group')}</span>}
                <button disabled={!enough} className="right-button merge" onClick={props.onMerge}>
                    <img src={icon_white} alt="" />
                    {i18n.t('Group the following symbols')}
                    <span className="symbol-count">{props.selectCount}</span>
                </button>
            </div>
        );
    }
    return (
        <div className="merge-controller">
            {zoomSpan}
            {i18n.t('Visually group multiple symbols on the same graph:')}
            <button onClick={props.enable} className="right-button enable">
                <img src={icon} alt="" />
                {i18n.t('Group symbols')}
            </button>
        </div>
    );
};

MergeController.defaultProps = {
    enabled: false,
    selectCount: 0,
};

export default MergeController;
