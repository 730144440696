import React from 'react';

const DataWrapper = (props) => (
    <div className={`data-wrapper ${props.private ? 'private' : ''}`}>
        <div className="data-wrapper-title">{props.title}</div>
        <div className="data-wrapper-holder">
            <div className="pretty-separator" />
            <div className="data-wrapper-content">
                {props.children}
            </div>
        </div>
    </div>
);

export default DataWrapper;
