/**
 * Created by lpostula on 10/05/17.
 */
import { RSAA } from 'redux-api-middleware';

import types from './types';
import { API_ROOT } from '../../config';

const API_RESOURCE_NAME = 'user';

export const fetchMe = () => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/me.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_ME_REQUEST,
            types.FETCH_ME_COMPLETED,
            types.FETCH_ME_FAILED,
        ],
    },
});

export const fetchList = (long_polling = false) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            {
                type: types.FETCH_LIST_REQUEST,
                meta: { long_polling },
            },
            {
                type: types.FETCH_LIST_COMPLETED,
                meta: { long_polling },
            },
            types.FETCH_LIST_FAILED,
        ],
    },
});

const setLanguage = (user_id, language) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${user_id}.json`,
        method: 'PATCH',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify({ language }),
        credentials: 'same-origin',
        types: [
            types.SET_LANGUAGE_REQUEST,
            types.SET_LANGUAGE_COMPLETED,
            types.SET_LANGUAGE_FAILED,
        ],
    },
});

const setTimezone = (user_id, timezone) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${user_id}.json`,
        method: 'PATCH',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify({ timezone }),
        credentials: 'same-origin',
        types: [
            types.SET_TIMEZONE_REQUEST,
            types.SET_TIMEZONE_COMPLETED,
            types.SET_TIMEZONE_FAILED,
        ],
    },
});

export default {
    fetchMe,
    fetchList,
    setLanguage,
    setTimezone,
};
