import React from 'react';

// output a object in a primary panel with a search box
class InfoGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterText: '',
        };
        this.onFilterTextChange = this.onFilterTextChange.bind(this);
    }

    onFilterTextChange(val) {
        this.setState({ filterText: val });
    }

    sortOnKeys(dict) {
        const sorted = [];
        for (const k in dict) {
            sorted[sorted.length] = k;
        }

        sorted.sort();

        const tempDict = {};
        for (let i = 0; i < sorted.length; i += 1) {
            tempDict[sorted[i]] = dict[sorted[i]];
        }
        return tempDict;
    }

    render() {
        const filter = (
            <div className="input-group">
                <span className="input-group-addon">
                    <span className="glyphicon glyphicon-search" />
                </span>
                <input
                    type="text"
                    className="form-control"
                    value={this.state.filterText}
                    onChange={(e) => this.onFilterTextChange(e.target.value)}
                />
            </div>
        );
        const sorted = this.sortOnKeys(this.props.data);

        return (
            <div className="panel panel-primary">
                <div className="panel-heading">
                    <div className="form-group">
                        <label className="control-label">
                            { this.props.title }
                        </label>
                        { filter }
                    </div>
                </div>
                <ul
                    className="list-group"
                    style={{
                        maxHeight: 400,
                        overflowY: 'auto',
                        overflowX: 'none',
                    }}
                >
                    {
                        Object.keys(sorted).map((k) => {
                            const v = sorted[k];
                            if ((this.state.filterText.trim() === '') || (k.toString().toLowerCase().indexOf(this.state.filterText.toLowerCase()) !== -1)) {
                                return (
                                    <li className="list-group-item" key={k}>
                                        <dt className="text-capitalize">
                                            { k }
                                        </dt>
                                        <dd>
                                            { JSON.stringify(v) }
                                        </dd>
                                    </li>
                                );
                            }
                            return null;
                        })
                    }
                </ul>
            </div>
        );
    }
}

export default InfoGroup;
