import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { labelCategoriesSelectors } from 'railfleet_state/ducks/label_categories';

import './FormLabelCategory.scss';
import WithLoginless from 'components/app/withLoginless';
import ReactSelectWrapper from '../ReactSelectWrapper';
import { StepContext } from '../step/Step';
import CutForm from '../cutForm/CutForm';
import FormDropdown from '../formDropdown/FormDropdown';
import { THEMES } from '../../../filters/selectFilter/SelectFilter';
import MultiDeviceLayout from '../../../app/MultiDeviceLayout';

const FormLabelCategoryManager = (props) => {
    const renderFields = () => {
        // we will now add all the labels events related for the user company
        const ret = [];
        // we need to regroup all the labels in one fields
        for (const cat_id in props.user_label_categories) {
            const cat = props.user_label_categories[cat_id];
            if (cat.is_event_related) {
                const label_set = {};
                for (const label of cat.label_set) {
                    label_set[label.id] = label;
                }
                let dv = [];
                if (props.defaultValue) {
                    if (Array.isArray(props.defaultValue)) {
                        for (const defaultValue of props.defaultValue) {
                            if (
                                Object.keys(label_set).includes(defaultValue)
                                || Object.keys(label_set).includes(String(defaultValue))
                            ) {
                                dv.push(defaultValue);
                            }
                        }
                    } else {
                        dv.push(props.defaultValue);
                    }
                }
                if (dv.length === 0) dv = null;
                const id = cat.name.replace(' ', '_').toLowerCase();
                ret.push(<FormDropdown
                    key={cat.name}
                    id={id}
                    label={cat.name}
                    defaultValue={dv}
                    values={label_set}
                    id_getter={(elm) => elm.id}
                    labelizer={(elm) => elm.name}
                    clearable
                    theme={THEMES.FORM_LIGHT}
                    required
                />);
            }
        }
        return ret;
    };

    const fields = renderFields();
    if (!fields || fields.length === 0) return null;
    return (
        <CutForm
            device={props.device}
        >
            { fields }
        </CutForm>
    );
};

const _FormLabelCategory = (props) => {
    const stepContext = useContext(StepContext);
    const { registerPreSubmit } = useFormContext();

    const preSubmit = (arg) => {
        if (!arg) return arg;
        const ret = { ...arg };
        let labels = [];
        for (const cat_id in props.user_label_categories) {
            const cat = props.user_label_categories[cat_id];
            const id = cat.name.replace(' ', '_').toLowerCase();
            if (ret[id]) {
                if (Array.isArray(ret[id])) {
                    labels = labels.concat(ret[id]);
                } else {
                    labels.push(ret[id]);
                }
                delete ret[id];
            }
        }
        ret.labels = labels;
        return ret;
    };

    useEffect(() => {
        if (stepContext && stepContext.addKey) stepContext.addKey(stepContext.step, props.id);
        registerPreSubmit(preSubmit);
    }, []);

    const validate = (data) => {
        if (data.length === 0) return null;
        return data;
    };

    return (
        <div
            className={`form_labels ${props.className ? props.className : ''} ${props.device}`}
            key={props.id}
        >
            <ReactSelectWrapper
                classname="labels"
                // eslint-disable-next-line react/no-children-prop
                children={FormLabelCategoryManager}
                name={props.id}
                validate={validate}
                preSubmit={preSubmit}
                {..._.omit(props, 'children')}
            />
        </div>
    );
};

const mapStateToProps = (state, props) => {
    if (props.loginless) return props.mapStateToProps(state, props);
    const user_label_categories = labelCategoriesSelectors.getLabelCategoriesForCurrentUser(
        state, props,
    );
    const { asset_id } = props;
    const asset_selector = assetsSelectors.makeAllAssetData();
    const asset = asset_id && asset_selector(state, asset_id);
    const labels = asset && asset.labels;
    const label_set = labels && labels.label_set;
    let defaultValue = props.defaultValue || [];
    if (label_set && !props.defaultValue) defaultValue = defaultValue.concat(label_set);
    return ({
        user_label_categories,
        defaultValue,
    });
};

const FormLabelCategory = (props) => (
    <MultiDeviceLayout>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <_FormLabelCategory
            {...props}
        />
    </MultiDeviceLayout>
);

export default WithLoginless(connect(mapStateToProps, null)(FormLabelCategory));
