/**
 * Created by lpostula on 11/05/17.
 */
const FETCH_LIST_REQUEST = 'alerts/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'alerts/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'alerts/FETCH_LIST_FAILED';

const FETCH_DETAIL_COMPLETED = 'alerts/FETCH_DETAIL_COMPLETED';

const PROCESS_REQUEST = 'alerts/PROCESS_REQUEST';
const PROCESS_COMPLETED = 'alerts/PROCESS_COMPLETED';
const PROCESS_FAILED = 'alerts/PROCESS_FAILED';

export default {
    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,
    PROCESS_REQUEST,
    PROCESS_COMPLETED,
    PROCESS_FAILED,
    FETCH_DETAIL_COMPLETED,
};
