import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import CrossButton from './Union.svg';
import './Toast.scss';
import Info from './Info.svg';

const Toast = (props) => {
    const [_display, setDisplay] = useState(true);
    if (props.hidden) return null;

    const autonomousOnClick = () => {
        setDisplay(false);
    };

    const onClick = props.autonomous ? autonomousOnClick : props.onClick;
    const handleTimeout = () => {
        if (onClick) onClick();
    };

    useEffect(() => {
        if (!props.noTimeout) setTimeout(handleTimeout, 5000);
    }, []);

    if (!_display) return null;

    const getIcon = () => {
        if (props.informative) {
            return (
                <img
                    className="info-icon"
                    src={Info}
                    alt="info"
                />
            );
        }
        return (
            <span className="glyphicon glyphicon-ok" />
        );
    };

    return (
        <div className={`toast-frame ${props.className}${props.informative ? ' informative' : ''}`}>
            <div className="toast-frame-content">
                { props.validated && (
                    <span className="icon-toast-frame">{getIcon()}</span>
                )}
                <div className="text-holder">
                    <span className="toast-text">{props.text}</span>
                    {
                        props.action
                        && (
                            <span
                                className="toast-action"
                                onClick={props.action}
                            >
                                {props.actionText || i18n.t('Click here to do this action')}
                            </span>
                        )
                    }
                </div>
                <div className="toast-children-holder">{props.children}</div>
                {
                    props.closable
                    && (
                        <img
                            className="close-toast-button"
                            src={CrossButton}
                            alt="close toast"
                            onClick={onClick}
                        />
                    )
                }
            </div>
        </div>
    );
};

Toast.defaultProps = {
    validated: true,
    closable: true,
};

export default Toast;
