/**
 * Created by lpostula on 03/10/19.
 */
import React from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';

import { uiOperations, uiSelectors } from 'railfleet_state/ducks/ui';
import './ReloadPage.scss';

const RELOAD_TIMEOUT = 15;

const reload = () => window.location.reload();

class ReloadPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            remaining: RELOAD_TIMEOUT,
        };
        this.timeout = null;
        this.handleTimeout = this.handleTimeout.bind(this);
    }

    componentDidMount() {
        if (this.props.forced) {
            // no need for anything
            reload();
            return;
        }
        // we need to set a timeout
        this.timeout = setTimeout(this.handleTimeout, 1000);
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    handleTimeout() {
        if (this.state.remaining === 0) {
            reload();
            return;
        }
        this.setState({ remaining: this.state.remaining - 1 });
        this.timeout = setTimeout(this.handleTimeout, 1000);
    }

    render() {
        // no need to display anything if that's the case
        if (this.props.forced) return null;
        const { lng } = window.settings;
        let message = this.props.message[lng];
        if (!message) {
            message = this.props.message.en; // default to english
        }
        const dismiss_text = i18n.t('dismiss');
        const reload_text = i18n.t('reload');
        return (
            <div className="reload_page">
                {message}
                <div className="actions">
                    <button className="btn btn-default" onClick={this.props.dismiss} onTouchEnd={this.props.dismiss}>
                        {dismiss_text.charAt(0).toUpperCase() + dismiss_text.slice(1)}
                    </button>
                    <button className="btn btn-primary" onClick={reload} onTouchEnd={reload}>
                        {`${reload_text.charAt(0).toUpperCase() + reload_text.slice(1)} (${this.state.remaining}s)`}
                    </button>

                </div>
            </div>
        );
    }
}

// As this is the component responsible to reload the page
// And this component is only shown in that event
// We can assume than when an error happened in it, we can reload the page

class ReloadOnError extends React.Component {
    componentDidCatch() {
        reload();
    }

    render() {
        return this.props.children;
    }
}

const ReloadPageHolder = (props) => (
    <div className="reload_page_holder">
        {
            props.active ? (
                <ReloadOnError>
                    <ReloadPage
                        dismiss={props.dismissReloadPage}
                        forced={props.forced}
                        message={props.message}
                    />
                </ReloadOnError>
            ) : null
        }
    </div>
);

const mapStateToProps = (state) => ({
    active: uiSelectors.isReloadPageActive(state),
    forced: uiSelectors.isReloadPageForced(state),
    message: uiSelectors.getReloadPageMessage(state),
});

const mapDispatchToProps = (dispatch) => ({
    dismissReloadPage: () => dispatch(uiOperations.dismissReloadPage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReloadPageHolder);
