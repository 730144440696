import React, { useState, useEffect, createContext } from 'react';

export const LAYOUT = {
    MOBILE: 'mobile',
    TABLET: 'tablet',
    DESKTOP: 'desktop',
};
const ContextDevice = createContext(null);
const MultiDeviceLayout = (props) => {
    const [_layout, setLayout] = useState(LAYOUT.DESKTOP);

    const updateLayout = () => {
        const width = window.innerWidth;
        if (width <= 768) {
            setLayout(LAYOUT.MOBILE);
        } else if (width <= 1280) {
            setLayout(LAYOUT.TABLET);
        } else {
            setLayout(LAYOUT.DESKTOP);
        }
    };

    useEffect(() => {
        updateLayout();
        window.addEventListener('resize', updateLayout);
        return () => {
            window.removeEventListener('resize', updateLayout);
        };
    }, []);

    if (props.noClone) {
        return (
            <ContextDevice.Provider
                value={{
                    device: _layout,
                }}
            >
                { props.children }
            </ContextDevice.Provider>
        );
    }

    return (
        React.cloneElement(
            props.children,
            { device: _layout },
        )
    );
};

const withMultiDeviceLayout = (Component) => (props) => (
    <MultiDeviceLayout noClone>
        {React.createElement(Component, { ...props })}
    </MultiDeviceLayout>
);

export default MultiDeviceLayout;
export {
    ContextDevice,
    withMultiDeviceLayout,
};
