import React from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import { usersSelectors } from 'railfleet_state/ducks/users';

/*
This is an HOC that provide permission checkin for railfleet route.

It will render the inner component if the logged user has all the permissions
in the permissions parameter, or if the permissions parameter is empty or null

 */

const AuthorizedRoute = (Comp, permissions) => {
    const map_state_to_props = (state) => ({
        user: state.users.me,
        user_perm_check: usersSelectors.userHasPerm(state),
    });
    class WithAuthorization extends React.Component {
        isAuthorized() {
            if (!permissions) return true;
            for (const permission of permissions) {
                if (!this.props.user_perm_check(permission)) {
                    return false;
                }
            }
            return true;
        }

        render() {
            if (!this.isAuthorized()) {
                return (
                    <div>
                        {i18n.t('Permission denied')}
                    </div>
                );
            }
            // remove user prop from the props
            const _props = { ...this.props };
            delete _props.user;
            return <Comp {..._props} />;
        }
    }

    return connect(map_state_to_props, null)(WithAuthorization);
};

export default AuthorizedRoute;
