import React from 'react';
import i18n from 'i18next';
import './SuggestedBubble.scss';
import Suggested_logo from './suggested_logo.svg';
import { COUNTER_UNIT_SHORT_TRANSLATION } from '../../../../../dashboard/static/constants';
import formatNumber from '../../../utils/formatNumber';

const SuggestedBubble = (props) => (
    <div className={`suggested-bubble direction-${props.direction ? props.direction : ''}`}>
        <div className="suggested-bubble-value-arrow-holder">
            <div className="suggested-bubble-value-arrow">
                <div className="suggested-bubble-value-arrow-inner" />
            </div>
        </div>
        <div className="suggested-value-holder">
            <div>
                <img className="sugessted-logo" src={Suggested_logo} alt="suggested logo" />
            </div>
            <div>
                <span className="suggested-value">
                    <span>{i18n.t('Suggested')}</span>
                    <span>
                        {formatNumber(props.value) || '-'}
                        {' '}
                        {props.unit && COUNTER_UNIT_SHORT_TRANSLATION[props.unit]}
                    </span>
                </span>
                {
                    props.clickable
                        && (
                            <span
                                className="suggested-action"
                                onClick={props.onClick}
                            >
                                {i18n.t('Use this value')}
                            </span>
                        )
                }
            </div>
        </div>
    </div>
);

SuggestedBubble.defaultProps = {
    direction: 'top',
};

export default SuggestedBubble;
