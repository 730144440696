import React, { useState, useEffect } from 'react';
import Portal from '../../app/portal';
import CrossButton from './Union.svg';

import './Dialog.scss';

const Dialog = (props) => {
    const [_opened, setOpened] = useState(false);

    useEffect(() => {
        if (_opened) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }
    }, [_opened]);

    if (!props.children) {
        if (_opened) setOpened(false);
        return null;
    }
    if (!_opened) setOpened(true);

    return (
        <Portal>
            <div className={`dialog-container ${props.className}`}>
                <div className="content">
                    <div className="close-actions">
                        <div
                            className="button_close"
                            onClick={props.onClose}
                        >
                            <img
                                className="close-toast-button"
                                src={CrossButton}
                                alt="close toast"
                                onClick={props.onClickClose}
                            />
                        </div>
                    </div>
                    { props.children }
                </div>
            </div>
        </Portal>
    );
};

export default Dialog;
