import React from 'react';
import i18n from 'i18next';
import './TextDisplay.scss';
import NoDataDisplay from '../noDataDisplay/NoDataDisplay';
import LateIcon from '../lateIcon/LateIcon';
import TextAction from '../../buttons/textAction/TextAction';

const TextDisplay = (props) => {
    if (!props.data) {
        return (<NoDataDisplay {...props} />);
    }
    return (
        <div className={`text-display element-holder ${props.className ? props.className : ''}`}>
            {
                (props.label || props.late)
                    && (
                        <div className="top-label-late">
                            {
                                props.label
                                && <div className="element-label">{props.label}</div>
                            }
                            {
                                props.late
                                && (
                                    <LateIcon
                                        text={i18n.t('Late')}
                                    />
                                )
                            }
                        </div>
                    )
            }
            {
                props.large ? (
                    <div className="element large">
                        <textarea
                            cols={30}
                            rows={5}
                            value={props.data}
                            onChange={() => null}
                        />
                    </div>
                ) : (
                    <div className="element">
                        <div>{props.data}</div>
                        {
                            props.action && (
                                <div
                                    className="action-label"
                                    onClick={props.action.onClick}
                                >
                                    {props.action.label}
                                </div>
                            )
                        }
                        {
                            props.onEdit
                            && (
                                <TextAction
                                    text={i18n.t('Edit')}
                                    onClick={props.onEdit}
                                />
                            )
                        }
                    </div>
                )
            }
            { props.children }
        </div>
    );
};

export default TextDisplay;
