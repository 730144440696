import React, { useState, useEffect, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactSelectWrapper from '../ReactSelectWrapper';
import { StepContext } from '../step/Step';
import './FormBoolean.scss';

const BooleanField = (props) => {
    const methods = useFormContext();
    const [_selected, setSelected] = useState(false);

    // This is to be used with the Step Manager.
    // It allow to do Forms with multiple steps.
    // We have to register each field in the Step Manager to validate fields on each step instead of
    // validating each fields at the end of the form with the submit button.
    const stepContext = useContext(StepContext);
    useEffect(() => {
        if (stepContext && stepContext.addKey) stepContext.addKey(stepContext.step, props.id);

        return (() => {
            methods.unregister(props.id);
            if (stepContext && stepContext.deleteKey) {
                stepContext.deleteKey(stepContext.step, props.id);
            }
        });
    }, []);

    useEffect(() => {
        if (props.defaultValue !== undefined) {
            setSelected(props.defaultValue);
        }
    }, []);

    useEffect(() => {
        props.onChange(_selected);
    }, [_selected]);

    const onChange = () => {
        setSelected(!_selected);
    };
    const custom_class_getter = (props.custom_class_getter && props.custom_class_getter(props.id)) || '';
    return (
        <div className="form_boolean_elm">
            <input
                type="checkbox"
                checked={_selected}
                onChange={onChange}
                disabled={props.disabled}
            />
            <div
                className={`form_boolean_elm_label ${custom_class_getter}`}
                onClick={props.disabled ? () => null : onChange}
            >
                <span>{props.label}</span>
            </div>
        </div>
    );
};

const FormBoolean = (props) => {
    const { errors } = useFormContext();
    const valueError = errors && errors[props.id];

    const validate = (data) => {
        if (data) return 'true';
        return 'false';
    };
    return (
        <div className={`form form_boolean ${props.className ? props.className : ''} ${props.id} no-label`} key={props.id}>
            <div className="form_boolean_holder">
                <ReactSelectWrapper
                    classname="boolean"
                    // eslint-disable-next-line react/no-children-prop
                    children={BooleanField}
                    name={props.id}
                    validate={validate}
                    {..._.omit(props, 'children')}
                />
            </div>
            {valueError && (
                <div className="form-validation-error">
                    <div className="form-validation-error-arrow" />
                    <div className="form-error-message">
                        {
                            (valueError.message)
                                ? <div>{valueError.message}</div>
                                : <div>Please enter a value</div>
                        }
                    </div>
                </div>
            )}
        </div>

    );
};

export default FormBoolean;
