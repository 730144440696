import React, {
    useState, useEffect, cloneElement, useRef,
} from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';

import { useResponsiveSize, findBreakPoint } from '../../hooks/useResponsiveBreakpoints';
import linkSign from './link-sign.svg';
import warningDataRetention from './warning-data-retention.svg';
import './Filters.scss';
import HideFiltersIcon from './hide-icon.svg';
import HideFiltersIconVertical from './hide-icon-vertical.svg';
import SwitchFilter from '../../filters/switchFilter/SwitchFilter';

const HORIZONTAL = 'horizontal';
const VERTICAL = 'vertical';
const OVERLAY = 'overlay';

// WARNING THESE ARE SET IN JS AND MUST BE ADAPTED IF CHANGES ARE MADE
const HORIZONTAL_M_BETWEEN_FIELDS = 10;
const ACTION_MIN_WIDTH = 100;
const SEARCH_FILLER_WIDTH = 20;

const getComputedWidthOfElement = (el) => (
    parseInt(window.getComputedStyle(el)
        .getPropertyValue('width'), 10) + 1
);

// Wrapper component to report min-width of a filter element
// This is used to compute breakpoints of the filter component
const MinWidthSizer = (props) => {
    const [_initialization, setInitialization] = useState(true);

    const ref = useRef(null);

    useEffect(() => {
        if (ref && ref.current && _initialization) {
            const computed = getComputedWidthOfElement(ref.current);
            if (props.onSetMinWidth) props.onSetMinWidth(computed);
            setInitialization(false);
        }
    });

    return (
        <div ref={ref} className={`min-width-sizer ${_initialization ? 'init' : ''}`}>
            {props.children}
        </div>
    );
};

const Block = (props) => {
    const [_min_widths, setMinWidth] = useState(Array(props.children.length)
        .fill(null));

    useEffect(() => {
        // sum of all elements min-width
        const raw_fbp = _.reduce(_min_widths, (memo, min_width) => memo + min_width, 0);
        // max of elements min-width
        const raw_lbp = Math.max(..._min_widths);
        if (props.onMinSizeChange) {
            props.onMinSizeChange(
                raw_fbp + (HORIZONTAL_M_BETWEEN_FIELDS * props.children.length) + 1,
                raw_lbp + HORIZONTAL_M_BETWEEN_FIELDS + SEARCH_FILLER_WIDTH + ACTION_MIN_WIDTH + 1,
                raw_lbp + 1,
            );
        }
    }, [props.children, _min_widths]);

    const onSetMinWidth = (index, value) => {
        setMinWidth((prevState) => {
            const new_min_widths = [...prevState];
            new_min_widths[index] = value;
            return new_min_widths;
        });
    };

    return (
        <div className={`search-logical-block flex-grow-${props.grow}`}>
            {
                React.Children.map(
                    props.children,
                    (child, index) => {
                        if (!child) return null;
                        return (
                            <div
                                className={`search-element ${(child.props.grow !== undefined) ? `flex-grow-${child.props.grow}` : ''}`}
                                key={child.key}
                                style={{ maxWidth: child.props.customMaxWidth ? child.props.customMaxWidth : '700px' }}
                            >
                                <MinWidthSizer onSetMinWidth={(value) => onSetMinWidth(index, value)}>
                                    {cloneElement(child, { reduced: props.reduced })}
                                </MinWidthSizer>
                            </div>
                        );
                    },
                )
            }
        </div>
    );
};

Block.propTypes = {
    grow: PropTypes.number,
    onMinSizeChange: PropTypes.func,
};

Block.defaultProps = {
    grow: 1,
    onMinSizeChange: null,
};

const Filters = (props) => {
    const [_blocks_first_breakp, setBlocksFirstBreakpoints] = useState({});
    const [_blocks_medium_breakp, setBlocksMediumBreakpoints] = useState({});
    const [_blocks_last_breakp, setBlocksLastBreakpoints] = useState({});
    const [_se_breakpoints, setSEBreakPoints] = useState(null);
    const [_bt_breakpoints, setBTBreakPoints] = useState(null);
    const [_hide_filters, setHideFilters] = useState(false);
    const [_orientClass, setOrientClass] = useState('');

    const onMinSizeChange = (index, fbp, mbp, lbp) => {
        setBlocksFirstBreakpoints((prevState) => ({ ...prevState, [index]: fbp }));
        setBlocksMediumBreakpoints((prevState) => ({ ...prevState, [index]: mbp }));
        setBlocksLastBreakpoints((prevState) => ({ ...prevState, [index]: lbp }));
    };

    const search_elementsRef = useRef(null);
    const bottomRef = useRef(null);

    const { current_width: selms_width } = useResponsiveSize(search_elementsRef);

    const { current_width: bottom_width } = useResponsiveSize(bottomRef);

    useEffect(() => {
        // maximum of (
        //     sum of elements min-width + spacing for each block
        // )
        const first_breakpoint = Math.max(...Object.values(_blocks_first_breakp));
        // maximum of (
        //     maximum of (element min-width + action min-width + spacing)
        // )
        const medium_breakpoint = Math.max(...Object.values(_blocks_medium_breakp));
        // maximum of (
        //     maximum of (element min-width)
        // )
        const last_breakpoint = Math.max(...Object.values(_blocks_last_breakp));
        // search-elements will brake at first_breakpoint (see medium style in css for change)
        setSEBreakPoints([
            { classname: 'medium', min: 0, max: first_breakpoint },
            { classname: 'large', min: first_breakpoint },
        ]);
        // bottom filters will brake at medium_breakpoint and last_breakpoint
        // (see medium style in css for change)
        setBTBreakPoints([
            { classname: 'small', min: 0, max: last_breakpoint },
            { classname: 'medium', min: last_breakpoint, max: medium_breakpoint },
            { classname: 'large', min: medium_breakpoint },
        ]);
    }, [_blocks_first_breakp, _blocks_medium_breakp, _blocks_last_breakp]);

    useState(() => {
        if (props.orient === VERTICAL) setOrientClass('vertical');
        else if (props.orient === OVERLAY) setOrientClass('overlay');
        else setOrientClass('');
    });
    let retention_data_message = null;
    if (props.retention_data != null) {
        retention_data_message = (
            <div className="warning-data-retention">
                <div className="icon-warning">
                    <img style={{ width: '14px', height: '14px' }} src={warningDataRetention} alt="warning Data Retention" />
                </div>
                <div className="text-data-retention">
                    <div
                        dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
                            __html: i18n.t('<span style="color: #FF9505">Data before\n{{retention_data}}\nfor the selected asset is not directly available in the GPS history.</span><br /><br /><span style="color: white">For any request before this limit, please make an export of the data.</span>', { retention_data: props.retention_data }, { interpolation: { escapeValue: false } }),
                        }}
                    />
                    <span style={{ padding: '10px', display: 'block' }} />
                    <a href="/data_export">
                        <button className="button-go-data-export">
                            <span>{i18n.t('Go to Data Export')}</span>
                            {' '}
                            <img
                                src={linkSign}
                                alt="link sign"
                            />
                        </button>
                    </a>
                    <div className="line" />
                    <div className="sub-message">
                        {i18n.t('or')}
                        {' '}
                        <button onClick={() => props.setStartDate()} className="button-gps-history-data">
                            <span style={{ color: '#FFFFFF' }}>
                                {i18n.t('Display data from')}
                                {' '}
                                {props.retention_data}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className={`filter-bar ${_orientClass} ${_hide_filters ? 'no-show' : ''}`}>
            <div className={`hide-filters ${_hide_filters ? 'as-show-filters' : ''}`}>
                <div
                    className="hide-filters-button"
                    onClick={() => {
                        setHideFilters(!_hide_filters);
                        if (props.onChange !== undefined) {
                            props.onChange(_hide_filters);
                        }
                    }}
                >
                    <img
                        src={(props.orient === HORIZONTAL)
                            ? HideFiltersIcon : HideFiltersIconVertical}
                        alt="hide filters"
                        className={`${_hide_filters ? 'as-show-filters' : ''}`}
                    />
                </div>
            </div>
            <div className="filters">
                <div className="top">
                    <div className="call-to-action">{props.call_to_action}</div>
                    {
                        props.switch_options
                        && (
                            <SwitchFilter
                                options={props.switch_options.options}
                                defaultSelected={props.switch_options.defaultSelected}
                                onChange={props.switch_options.onChange}
                                img={props.switch_options.img}
                            />
                        )
                    }
                </div>
                <div
                    className={`bottom ${findBreakPoint(_bt_breakpoints, bottom_width)} ${bottom_width}`}
                    ref={bottomRef}
                >
                    <div
                        className={`search-elements ${findBreakPoint(_se_breakpoints, selms_width)} ${selms_width}`}
                        ref={search_elementsRef}
                    >
                        {
                            React.Children.map(props.children, (child, index) => (
                                cloneElement(child, {
                                    onMinSizeChange: (first, medium, last) => {
                                        onMinSizeChange(index, first, medium, last);
                                    },
                                    // eslint-disable-next-line react/no-array-index-key
                                    key: index,
                                    ...findBreakPoint(_bt_breakpoints, bottom_width) === 'small' && { reduced: true },
                                })
                            ))
                        }
                    </div>
                    <div className="search-filler"></div>
                    {props.showActions && (
                        <div className="actions">
                            {
                                !props.is_fetching
                                    ? (
                                        <div
                                            className={`apply-button ${(props.action_disabled) ? 'disabled' : ''}`}
                                            onClick={!props.action_disabled ? props.applyFilter : null}
                                        >
                                            {
                                                props.is_pristine
                                                    ? (
                                                        <span
                                                            className={`glyphicon glyphicon-refresh refresh-label ${props.is_fetching ? 'glyphicon-refresh-animate' : ''}`}
                                                        />
                                                    )
                                                    : <span className="apply-label">{i18n.t('Apply filters')}</span>
                                            }
                                        </div>
                                    )
                                    : (
                                        <div className="cancel-button" onClick={props.cancel}>
                                            <span
                                                className={`glyphicon glyphicon-refresh refresh-label ${props.is_fetching ? 'glyphicon-refresh-animate' : ''}`}
                                            />
                                            <span className="apply-label">{i18n.t('Cancel')}</span>
                                        </div>
                                    )
                            }
                        </div>
                    )}
                </div>
                {retention_data_message}
            </div>

        </div>
    );
};

Filters.propTypes = {
    call_to_action: PropTypes.string,
    applyFilter: PropTypes.func,
    action_disabled: PropTypes.bool,
    showActions: PropTypes.bool,
    orient: PropTypes.string,
};

Filters.defaultProps = {
    call_to_action: null,
    applyFilter: () => null,
    showActions: true,
    action_disabled: false,
    orient: HORIZONTAL,
};

export {
    Filters,
    Block,
    HORIZONTAL,
    VERTICAL,
    OVERLAY,
};
