/**
 * Created by lpostula on 11/10/17.
 */
import React from 'react';
import PropTypes from 'prop-types';

const Alert = (props) => (
    <div
        className={`alert alert-${props.type}${props.dismissable ? ' alert-dismissable' : ''}`}
        role="alert"
    >
        {
            props.dismissable ? (
                <button className="close" data-dismiss="alert" aria-label="Close" type="button">
                    <span className="glyphicon glyphicon-remove" />
                </button>
            ) : null
        }
        {props.children}
    </div>
);

Alert.defaultProps = {
    type: 'default',
    dismissable: false,
};

Alert.propTypes = {
    type: PropTypes.string,
    dismissable: PropTypes.bool,
};

export default Alert;
