import * as React from 'react';
import i18n from 'i18next';
import memoize from 'memoize-one';
import { FixedSizeList } from 'react-window';

import './ColumnChooser.css';

import WithPopper from '../app/WithPopper';

class PresetsDropDown extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { visible: false };
    }

    render() {
        const self = this;
        const handleClick = (event) => {
            self.setState({ visible: false });
            const value = event.target.getAttribute('data');
            const selected = self.props.options.find((o) => String(o.value) === value);
            self.props.onClick(selected || { value });
        };
        const onPopperVisibilityChange = (visible) => {
            self.setState({ visible });
        };
        const options = (self.props.options.length > 0 ? self.props.options.map((
            (a) => (<div key={a.value}><a data={a.value} onClick={handleClick}>{a.label}</a></div>)
        )) : (
            <div>
                <p>{i18n.t('No preset defined yet.')}</p>
                <p>{i18n.t('A preset saves your current selection of table columns.')}</p>
            </div>
        ));
        const actions = self.props.onCreatePreset ? (
            <div>
                <button
                    type="button"
                    className="btn save-as-new-preset"
                    onClick={() => {
                        self.setState({ visible: false });
                        self.props.onCreatePreset();
                    }}
                >
                    <span className="glyphicon glyphicon-plus"></span>
&nbsp;
                    {i18n.t('Save columns as new preset')}
                </button>
            </div>
        ) : (<div></div>);
        return (
            <WithPopper
                placement="bottom-start"
                dontOpenOnHover
                visible={self.state.visible}
                openCallback={onPopperVisibilityChange}
            >
                <button
                    type="button"
                    className="dg-presets-btn"
                >
                    {self.props.label}
                    <span className="caret"></span>
                </button>
                <div className="dg-presets-menu">
                    {options}
                    {actions}
                </div>
            </WithPopper>
        );
    }
}

PresetsDropDown.defaultProps = {
    options: [],
    label: i18n.t('Presets'),
    onClick: (event) => {
        console.log(event.target);
    },
};

/** Size the element selected by the given `selector` in the given `html`. */
const sizeHtmlInHiddenContainer = (html, selector) => {
    // TODO: move to an appropriate source file to be reused by other components ?
    const container = document.createElement('div');
    container.visibility = 'hidden';
    container.innerHTML = html;
    document.body.appendChild(container);
    const element = container.querySelector(selector);
    const computedStyle = window.getComputedStyle(element, null);
    const result = {
        offsetHeight: element.offsetHeight,
        offsetWidth: element.offsetWidth,
        scrollHeight: element.scrollHeight,
        scrollWidth: element.scrollWidth,
        paddingLeft: parseFloat(computedStyle.getPropertyValue('padding-left')),
        paddingRight: parseFloat(computedStyle.getPropertyValue('padding-right')),
        paddingTop: parseFloat(computedStyle.getPropertyValue('padding-top')),
        paddingBottom: parseFloat(computedStyle.getPropertyValue('padding-bottom')),
        // TODO: add more sizes, for instance margins ?
    };
    document.body.removeChild(container);
    return result;
};

/** Find the actual width of the longest item in the columns chooser list. */
const getColumnChooserWidth = (columns) => {
    // find the longest text for the ColumnChooser's list of labels.
    const longestLabelText = columns.reduce((accumulator, column) => {
        const text = (column.label || column.dataKey);
        return text.length > accumulator.length ? text : accumulator;
    }, '');
    // create a column chooser's list item with that longest text as label.
    const div = document.createElement('div');
    div.className = 'checkbox control-label';
    div.innerHTML = (
        '<label htmlFor="dummy-columnchooser-label"><input type="checkbox" id="dummy-columnchooser-label"/></label>'
    );
    div.querySelector('label').appendChild(document.createTextNode(longestLabelText));
    // measure it's label element in a hidden container ...
    const sizes = sizeHtmlInHiddenContainer(div.outerHTML, 'label');
    //  ... and return its width, including padding.
    return sizes.scrollWidth + sizes.paddingLeft + sizes.paddingRight;
};

class ColumnsDropDown extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            filterText: '',
            visible: false,
            visibilityMask: {},
        };
        this.getColumnChooserWidth = memoize(getColumnChooserWidth);
        this.filterColumns = memoize(this.filterColumns);
    }

    filterColumns(filterText, columns) {
        const out = [];
        const txt = filterText.trim().toLowerCase();
        for (const column of columns) {
            if (column.alwaysVisible === true) {
                continue;
            }
            const key = column.dataKey.toString().toLowerCase();
            const label = (column.label || column.dataKey).toString().toLowerCase();
            if (txt.length === 0 || key.indexOf(txt) >= 0 || label.indexOf(txt) >= 0) {
                out.push(column);
            }
        }
        // sort columns
        out.sort((col_a, col_b) => {
            const la = (col_a.label && col_a.label !== '') ? col_a.label : col_a.dataKey;
            const lb = (col_b.label && col_b.label !== '') ? col_b.label : col_b.dataKey;
            return la.toLowerCase().localeCompare(lb.toLowerCase());
        });
        return out;
    }

    render() {
        const self = this;
        const columns = this.filterColumns(this.state.filterText, this.props.columns);
        const height = window.innerHeight - 200;
        const width = 250;
        const listHeight = Math.min(columns.length * 30, height - 100);
        const listWidth = Math.max(width + 30, this.getColumnChooserWidth(columns));
        const onPopperVisibilityChange = (visible) => {
            self.setState({
                columnConfigVisible: visible,
                visibilityMask: self.props.getDataGrid().getVisibilityMask(),
            });
        };
        const showAllColumns = () => {
            self.setState({ visibilityMask: self.props.getDataGrid().showAllColumns() });
        };
        const hideAllColumns = () => {
            self.setState({ visibilityMask: self.props.getDataGrid().hideAllColumns() });
        };
        const columnItem = ({ index, style }) => {
            const column = columns[index];
            const dg = self.props.getDataGrid();
            return (
                <div
                    key={`checkbox-${column.dataKey}`}
                    className="dg-columns-dropdown-item"
                    style={style}
                >
                    <div className="checkbox control-label">
                        <label htmlFor={`checkbox-${column.dataKey}-input`}>
                            <input
                                type="checkbox"
                                id={`checkbox-${column.dataKey}-input`}
                                checked={
                                    self.state.visibilityMask[column.dataKey] !== false
                                }
                                onChange={(e, dataKey = column.dataKey) => {
                                    self.setState({ visibilityMask: dg.toggleColumn(dataKey) });
                                }}
                                disabled={column.alwaysVisible}
                            />
                            { column.label || column.dataKey }
                        </label>
                    </div>
                </div>
            );
        };
        return (
            <WithPopper
                placement="bottom-start"
                dontOpenOnHover
                visible={this.state.columnConfigVisible}
                openCallback={onPopperVisibilityChange}
            >
                <button
                    type="button"
                    className="dg-columns-dropdown-btn"
                >
                    {i18n.t('Columns')}
                    <span className="caret"></span>
                </button>
                <div
                    className="dg-columns-dropdown-menu"
                    style={{
                        minWidth: 300,
                        maxWidth: listWidth + 20,
                        maxHeight: height,
                    }}
                >
                    <span className="dg-columns-dropdown-title">{this.props.label}</span>
                    <div className="dg-columns-dropdown-control">
                        <div className="input-group dg-column-filter">
                            <input
                                type="text"
                                placeholder="Search"
                                className="form-control"
                                value={this.state.filterText}
                                onChange={(event) => (
                                    this.setState({ filterText: event.target.value })
                                )}
                            />
                        </div>
                    </div>
                    <div className="dg-columns-dropdown-list">
                        <div>
                            <a onClick={showAllColumns}>{i18n.t('All')}</a>
                            &nbsp;-&nbsp;
                            <a onClick={hideAllColumns}>{i18n.t('None')}</a>
                        </div>
                        <FixedSizeList
                            itemSize={30}
                            height={listHeight}
                            width={listWidth}
                            itemCount={columns.length}
                            itemKey={(index) => columns[index].dataKey.toString().toLowerCase()}
                        >
                            { columnItem }
                        </FixedSizeList>
                    </div>
                </div>
            </WithPopper>
        );
    }
}

ColumnsDropDown.defaultProps = {
    label: i18n.t('Choose columns to display'),
};

const ColumnChooser = (props) => {
    const displayPresets = (props.getColumnSetOptions != null);
    const changePreset = (selected) => {
        props.getDataGrid().onColumnSetChange(selected);
    };
    return (
        <div className="dg-columnchooser-container">
            <div className="dg-columnchooser">
                <div style={{ marginTop: 'auto' }}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.3636 0H0.636354C0.284932 0 0 0.284932 0 0.636354V13.3636C0 13.7151 0.284932 14 0.636354 14H13.3636C13.7151 14 14 13.7151 14 13.3636V0.636354C14 0.284932 13.7151 0 13.3636 0ZM4.13638 12.7273H1.27274V1.27274H4.13638V12.7273ZM8.59091 12.7273H5.40908V1.27274H8.59091V12.7273ZM12.7273 12.7273H9.86365V1.27274H12.7273V12.7273Z" fill="#626C7C" />
                    </svg>
                </div>
                <ColumnsDropDown
                    columns={props.columns}
                    getDataGrid={props.getDataGrid}
                />
                {displayPresets ? (
                    <PresetsDropDown
                        options={props.getColumnSetOptions() || []}
                        onClick={changePreset}
                        onCreatePreset={props.onCreatePreset}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default ColumnChooser;
