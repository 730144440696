/**
 * Created by lpostula on 28/09/17.
 */
const getDocuments = (state) => state.log_documents;

export const getDocumentsForLog = (state, log_id) => {
    if (log_id === undefined || log_id === null) {
        return [];
    }
    const log = state.logs[log_id];
    if (log) {
        return _.pick(state.log_documents, log.documents);
    }
    return {};
};

export default {
    getDocuments,
    getDocumentsForLog,
};
