/**
 * Created by gknus on 02/09/19.
 */
import { default as actions } from './actions';

export const { fetchList } = actions;

export default {
    fetchList,
};
