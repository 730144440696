import React from 'react';
import './ModalTitle.scss';

const ModalTitle = (props) => (
    <div className="modal-title">
        {props.title}
    </div>
);

export default ModalTitle;
