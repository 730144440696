/**
 * Created by gknuts on 27/09/18.
 */
import { default as actions } from './actions';
import { getCollectionController, getCollectionFilter } from '../filtering_collections/selectors';

export const { fetchList } = actions;

const cancelGetAdvancedKey = (collection_key) => (dispatch, getState) => {
    dispatch(actions.cancelSendQuery(collection_key));
    const controller = getCollectionController(getState(), collection_key);
    if (!controller) {
        return null;
    }
    return controller.abort();
};

const get_advanced_key = (collection_key, onSuccessCallback) => (dispatch, getState) => {
    dispatch(cancelGetAdvancedKey(collection_key));
    const state = getState();
    const filters = getCollectionFilter(state, collection_key);
    const advanced_filters = _.pick(filters.fields, 'advanced_search');
    return dispatch(actions.get_advanced_key(advanced_filters))
        .then((d) => dispatch(() => onSuccessCallback(
            (d.payload) ? d.payload.advanced_search_id : null,
        )));
};

export default {
    fetchList,
    cancelGetAdvancedKey,
    get_advanced_key,
};
