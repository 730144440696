/**
 * Created by lpostula on 12/05/17.
 */
import { default as actions } from './actions';
import {
    getAssetsInfos,
    getAssetsLabels,
    getAssetsOperationals,
    getAssetsLiveData,
} from '../assets/selectors';
import { getLabelCategoriesWithLabels } from '../label_categories/selectors';

import {
    validate_list_of_ids,
    filterBySelectedAssets,
    filterBySelectedAssetClasses,
    filterBySelectedLabels,
    filterBySelectedStatus,
    filterBySelectedTechStatus,
    filterBySelectedOperations,
} from './utils';

export const { setSearchText } = actions;
export const { setSortBy } = actions;
export const { setActionMode } = actions;
export const { updatedAssetInfos } = actions;
export const { setMaskSearch } = actions;
export const { setMaskFilter } = actions;
export const { refreshResult } = actions;

/**
 * ASSET FILTERING
 * =====================
 */

export const _setSelectedAssets = (assets) => {
    // this is the bare action creator for the set selected asset.
    // we use a _ function in order to reuse the behaviour for the
    // setSelectedAssetsAndClasses method
    let result = [];
    const active = assets.length > 0;
    if (active) {
        result = filterBySelectedAssets(assets);
    }
    return {
        key: 'assets',
        query: assets,
        active,
        result,
    };
};

export const setSelectedAssets = (assets_query) => (dispatch) => {
    if (!validate_list_of_ids(assets_query)) {
        return dispatch(actions.filterError('invalid parameter for asset filtering'));
    }
    const action = _setSelectedAssets(assets_query);
    // be careful setFilterKey expect a list
    dispatch(actions.setFilterKey([action]));
    return Promise.resolve();
};

/**
 * ASSET CLASS FILTERING
 * =====================
 */

export const _setSelectedAssetClasses = (asset_classes, assets) => {
    // this is the bare action creator for the set selected asset.
    // we use a _ function in order to reuse the behaviour for the
    // setSelectedAssetsAndClasses method
    let result = [];
    const active = asset_classes.length > 0;
    if (active) {
        result = filterBySelectedAssetClasses(asset_classes, assets);
    }
    return {
        key: 'asset_classes',
        query: asset_classes,
        active,
        result,
    };
};

export const setSelectedAssetClasses = (asset_classes) => (dispatch, getState) => {
    if (!validate_list_of_ids(asset_classes)) {
        return dispatch(actions.filterError('invalid parameter for asset class filtering'));
    }
    const state = getState();
    const assets = getAssetsInfos(state);
    const action = _setSelectedAssetClasses(asset_classes, assets);
    // be careful setFilterKey expect a list
    dispatch(actions.setFilterKey([action]));
    return Promise.resolve();
};

/**
 * ASSET AND ASSET CLASS FILTERING
 * =====================
 */

export const setSelectedAssetsAndClasses = (assets_and_classes) => (dispatch, getState) => {
    if (!validate_list_of_ids(assets_and_classes)) {
        return dispatch(actions.filterError('invalid parameter for asset filtering'));
    }
    // we need to split the asset and the classes
    const assets_query = [];
    const asset_classes_query = [];
    for (const c of assets_and_classes) {
        if (c < 0) {
            // then it's an asset class
            asset_classes_query.push(-1 * c);
        } else {
            assets_query.push(c);
        }
    }
    const state = getState();
    const assets = getAssetsInfos(state);
    const asset_action = _setSelectedAssets(assets_query);
    const asset_class_action = _setSelectedAssetClasses(asset_classes_query, assets);
    dispatch(actions.setFilterKey([asset_action, asset_class_action]));
    return Promise.resolve();
};
/**
 * LABELS FILTERING
 * =====================
 */

export const setSelectedLabels = (labels) => (dispatch, getState) => {
    if (!validate_list_of_ids(labels)) {
        return dispatch(actions.filterError('invalid parameter for label filtering'));
    }
    const state = getState();
    const assets = getAssetsLabels(state);
    const labels_categories = getLabelCategoriesWithLabels(state);
    let result = [];
    const active = labels.length > 0;
    if (active) {
        result = filterBySelectedLabels(labels, assets, labels_categories);
    }
    dispatch(actions.setFilterKey([{
        key: 'labels',
        query: labels,
        active,
        result,
    }]));
    return Promise.resolve();
};

/**
 * STATUS FILTERING
 * ================
 */

export const setSelectedStatus = (statuses) => (dispatch, getState) => {
    if (!validate_list_of_ids(statuses)) {
        return dispatch(actions.filterError('invalid parameter for status filtering'));
    }
    const state = getState();
    const assets = getAssetsOperationals(state);
    let result = [];
    const active = statuses.length > 0;
    if (active) {
        result = filterBySelectedStatus(statuses, assets);
    }
    dispatch(actions.setFilterKey([{
        key: 'statuses',
        query: statuses,
        active,
        result,
    }]));
    return Promise.resolve();
};

/**
 * TECH STATUS FILTERING
 * =====================
 */

export const setSelectedTechStatus = (statuses) => (dispatch, getState) => {
    if (!Array.isArray(statuses)) {
        return dispatch(actions.filterError('invalid parameter for tech status filtering'));
    }
    const state = getState();
    const assets = getAssetsLiveData(state);
    let result = [];
    const active = statuses.length > 0;
    if (active) {
        result = filterBySelectedTechStatus(statuses, assets);
    }
    dispatch(actions.setFilterKey([{
        key: 'tech_statuses',
        query: statuses,
        active,
        result,
    }]));
    return Promise.resolve();
};

const checker = (arr, target) => target.every((v) => arr.includes(v));

export const setSelectedCountries = (country_links, values) => (dispatch, getState) => {
    const result = [];
    const truth = {};
    for (const key in country_links) {
        const link = country_links[key];
        if (link.aptitude) {
            if (!truth[link.asset]) truth[link.asset] = [];
            truth[link.asset].push(link.target);
        }
    }
    for (const raw_asset_id in truth) {
        const asset_id = parseInt(raw_asset_id, 10);
        const elm = truth[asset_id];
        if (checker(elm, values) && !result.includes(asset_id)) {
            result.push(asset_id);
        }
    }
    const active = values.length > 0;
    dispatch(actions.setFilterKey([{
        key: 'countries',
        query: values,
        active,
        result,
    }]));
    return Promise.resolve();
};

export const setSelectedOthers = (other_links, values) => (dispatch, getState) => {
    const result = [];
    const ids = values.map((elm) => -elm);
    const truth = {};
    for (const key in other_links) {
        const link = other_links[key];
        if (link.aptitude) {
            if (!truth[link.asset]) truth[link.asset] = [];
            truth[link.asset].push(link.target);
        }
    }
    for (const raw_asset_id in truth) {
        const asset_id = parseInt(raw_asset_id, 10);
        const elm = truth[asset_id];
        if (checker(elm, ids) && !result.includes(asset_id)) {
            result.push(asset_id);
        }
    }

    const active = values.length > 0;
    dispatch(actions.setFilterKey([{
        key: 'others',
        query: ids,
        active,
        result,
    }]));
    return Promise.resolve();
};

export const setSelectedOperations = (operations) => (dispatch, getState) => {
    if (!validate_list_of_ids(operations)) {
        return dispatch(actions.filterError('invalid parameter for operations filtering'));
    }
    const state = getState();
    const assets = getAssetsOperationals(state);
    let result = [];
    const active = operations.length > 0;
    if (active) {
        result = filterBySelectedOperations(operations, assets);
    }
    dispatch(actions.setFilterKey([{
        key: 'operations',
        query: operations,
        active,
        result,
    }]));
    return Promise.resolve();
};

export const clearAllFilters = (dispatch) => {
    dispatch(setSearchText(''));
    dispatch(setSortBy('asset_name'));
    dispatch(setActionMode('all'));
    dispatch(setSelectedAssetsAndClasses([]));
    dispatch(setSelectedLabels([]));
    dispatch(setSelectedStatus([]));
    dispatch(setSelectedTechStatus([]));
};

export const clearAllFiltersSync = () => (dispatch) => {
    dispatch(setSearchText(''));
    dispatch(setSortBy('asset_name'));
    dispatch(setActionMode('all'));
    dispatch(setSelectedAssetsAndClasses([]));
    dispatch(setSelectedLabels([]));
    dispatch(setSelectedStatus([]));
    dispatch(setSelectedTechStatus([]));
};

export const clearAssetFilter = () => (dispatch) => {
    dispatch(setSelectedAssetsAndClasses([]));
};

export default {
    setSearchText,

    setActionMode,
    setSortBy,
    setSelectedAssets,
    setSelectedAssetClasses,
    setSelectedAssetsAndClasses,
    setSelectedLabels,
    setSelectedStatus,
    setSelectedTechStatus,
    setSelectedOperations,
    setSelectedCountries,
    setSelectedOthers,
    clearAllFilters,
    setMaskSearch,
    setMaskFilter,
    refreshResult,
    clearAllFiltersSync,
    clearAssetFilter,
};
