export const formatErrors = (errors) => ({
    error: true,
    payload: {
        response: errors,
    },
});

export default {
    formatErrors,
};
