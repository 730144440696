/**
 * Created by lpostula on 15/05/17.
 */
const FETCH_LIST_REQUEST = 'interventions/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'interventions/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'interventions/FETCH_LIST_FAILED';

const FETCH_DETAILS_REQUEST = 'interventions/FETCH_DETAILS_REQUEST';
const FETCH_DETAILS_COMPLETED = 'interventions/FETCH_DETAILS_COMPLETED';
const FETCH_DETAILS_FAILED = 'interventions/FETCH_DETAILS_FAILED';

const UPDATE_DETAILS_REQUEST = 'interventions/UPDATE_DETAILS_REQUEST';
const UPDATE_DETAILS_COMPLETED = 'interventions/UPDATE_DETAILS_COMPLETED';
const UPDATE_DETAILS_FAILED = 'interventions/UPDATE_DETAILS_FAILED';

const CREATE_INTERVENTION_REQUEST = 'interventions/CREATE_INTERVENTION_REQUEST';
const CREATE_INTERVENTION_COMPLETED = 'interventions/CREATE_INTERVENTION_COMPLETED';
const CREATE_INTERVENTION_FAILED = 'interventions/CREATE_INTERVENTION_FAILED';

const CANCEL_INTERVENTION_REQUEST = 'interventions/CANCEL_INTERVENTION_REQUEST';
const CANCEL_INTERVENTION_COMPLETED = 'interventions/CANCEL_INTERVENTION_COMPLETED';
const CANCEL_INTERVENTION_FAILED = 'interventions/CANCEL_INTERVENTION_FAILED';

const REMOVE_INTERVENTION = 'interventions/REMOVE_INTERVENTION';

const ADD_EVENTS_IN_INTERVENTION_REQUEST = 'interventions/ADD_EVENTS_IN_INTERVENTION_REQUEST';
const ADD_EVENTS_IN_INTERVENTION_COMPLETED = 'interventions/ADD_EVENTS_IN_INTERVENTION_COMPLETED';
const ADD_EVENTS_IN_INTERVENTION_FAILED = 'interventions/ADD_EVENTS_IN_INTERVENTION_FAILED';

const REMOVE_EVENTS_FROM_INTERVENTION_REQUEST = 'interventions/REMOVE_EVENTS_FROM_INTERVENTION_REQUEST';
const REMOVE_EVENTS_FROM_INTERVENTION_COMPLETED = 'interventions/REMOVE_EVENTS_FROM_INTERVENTION_COMPLETED';
const REMOVE_EVENTS_FROM_INTERVENTION_FAILED = 'interventions/REMOVE_EVENTS_FROM_INTERVENTION_FAILED';

export default {
    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,
    FETCH_DETAILS_REQUEST,
    FETCH_DETAILS_COMPLETED,
    FETCH_DETAILS_FAILED,
    UPDATE_DETAILS_REQUEST,
    UPDATE_DETAILS_COMPLETED,
    UPDATE_DETAILS_FAILED,
    CREATE_INTERVENTION_REQUEST,
    CREATE_INTERVENTION_COMPLETED,
    CREATE_INTERVENTION_FAILED,
    CANCEL_INTERVENTION_REQUEST,
    CANCEL_INTERVENTION_COMPLETED,
    CANCEL_INTERVENTION_FAILED,
    REMOVE_INTERVENTION,
    ADD_EVENTS_IN_INTERVENTION_REQUEST,
    ADD_EVENTS_IN_INTERVENTION_COMPLETED,
    ADD_EVENTS_IN_INTERVENTION_FAILED,
    REMOVE_EVENTS_FROM_INTERVENTION_REQUEST,
    REMOVE_EVENTS_FROM_INTERVENTION_COMPLETED,
    REMOVE_EVENTS_FROM_INTERVENTION_FAILED,
};
