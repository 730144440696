/**
 * Created by lpostula on 12/05/17.
 */
import {
    createSelector,
} from 'reselect';
import _ from 'underscore';

export const getCompaniesSettings = (state) => state.companies_settings;
export const getCompanySettings = (state, id) => state.companies_settings[id];
const getCurrentUserCompanyId = (state) => {
    // not null nor undefined
    if (state.users.me) {
        return state.users.me.company;
    }
    return null;
};

export const getCompaniesSettingsForUser = createSelector(
    [getCompaniesSettings, getCurrentUserCompanyId],
    (maintenance_settings, company_id) => maintenance_settings[company_id] || {},
);

export const getPrivateFieldsForUser = createSelector(
    [getCompaniesSettingsForUser],
    (settings) => (settings && settings.fields ? settings.fields.private : []),
);
export const getPrivateFieldsForUserWithContentType = (content_type) => createSelector(
    [getCompaniesSettingsForUser],
    (settings) => {
        if (!settings || !settings.fields || !settings.fields.private) return [];
        const filtered = _.filter(
            settings.fields.private,
            (elm) => elm.content_type === content_type,
        );
        return filtered;
    },
);

// eslint-disable-next-line max-len
export const getPrivateFieldsForUserWithContentTypeAndOptions = (content_type, content_options) => createSelector(
    [getCompaniesSettingsForUser],
    (settings) => {
        if (!settings || !settings.fields || !settings.fields.private) return [];
        if (!content_options) return null;
        let filtered = _.filter(
            settings.fields.private,
            (elm) => elm.content_type === content_type,
        );

        if (content_options && filtered) {
            filtered = _.filter(
                filtered,
                (elm) => {
                    if (!elm.content_options) return null;
                    let toCheck = elm;
                    if (toCheck && content_options.includes('corrective') && content_options.includes('preventive')) {
                        if (!(toCheck.content_options.includes('corrective') || toCheck.content_options.includes('preventive'))) toCheck = null;
                    } else {
                        if (toCheck && content_options.includes('corrective')) {
                            if (!toCheck.content_options.includes('corrective')) toCheck = null;
                        }
                        if (toCheck && content_options.includes('preventive')) {
                            if (!toCheck.content_options.includes('preventive')) toCheck = null;
                        }
                    }
                    if (toCheck && content_options.includes('open') && content_options.includes('close')) {
                        if (!(toCheck.content_options.includes('open') || toCheck.content_options.includes('close'))) toCheck = null;
                    } else {
                        if (toCheck && content_options.includes('open')) {
                            if (!toCheck.content_options.includes('open')) toCheck = null;
                        }
                        if (toCheck && content_options.includes('close')) {
                            if (!toCheck.content_options.includes('close')) toCheck = null;
                        }
                    }
                    return toCheck;
                },
            );
        }
        return filtered;
    },
);

export default {
    getCompaniesSettings,
    getCompanySettings,
    getCompaniesSettingsForUser,
    getPrivateFieldsForUser,
    getPrivateFieldsForUserWithContentType,
    getPrivateFieldsForUserWithContentTypeAndOptions,
};
