/**
 * Created by lpostula on 28/12/17.
 */
import i18n from 'i18next';
import { createSelector } from 'reselect';
import { getOperationGroups } from '../operation_groups/selectors';
import { getFilteredAssetList, isFilterActive } from '../filtering/selectors';
import { getAssetsInfos } from '../assets/selectors';
import { getComponentStructures } from '../component_structures/selectors';
import { getMaintenancePlans } from '../maintenance_plans/selectors';

export const getOperations = (state) => state.operations;

function sort_options(a, b) {
    const sa = a.label.toLowerCase();
    const sb = b.label.toLowerCase();
    return -(sa < sb) || +(sa !== sb);
}

export const getAllOperationsOptions = createSelector(
    [getOperations, getOperationGroups],
    (operations, operation_groups) => {
        const options = [];
        for (const group_id in operation_groups) {
            const group = operation_groups[group_id];
            for (const operation_id of group.operations) {
                const operation = operations[operation_id];
                if (operation) {
                    options.push({
                        value: operation.id,
                        label: `${group.name}: ${operation.name}`,
                    });
                }
            }
        }
        options.sort(sort_options);
        return options;
    },
);

export const getOperationsAndGroupsOptions = createSelector(
    [getOperations, getOperationGroups],
    (operations, operation_groups) => {
        const options = [];

        const groups_options = [];
        for (const group_id in operation_groups) {
            const group = operation_groups[group_id];
            for (const operation_id of group.operations) {
                const operation = operations[operation_id];
                if (operation) {
                    options.push({
                        value: operation.id,
                        label: `${group.name}: ${operation.name}`,
                    });
                }
            }
            groups_options.push({
                value: -group_id,
                label: `${i18n.t('Group')}: ${group.name}`,
            });
        }
        options.sort(sort_options);
        groups_options.sort(sort_options);
        return groups_options.concat(options);
    },
);

export const getOperationsAndGroupsOptionsByAssets = createSelector(
    [
        getOperations,
        getOperationGroups,
        getFilteredAssetList,
        isFilterActive,
        getAssetsInfos,
        getComponentStructures,
        getMaintenancePlans,
    ],
    (
        operations,
        operation_groups,
        filtered_assets,
        filter_active,
        assets,
        component_structures,
        maintenance_plans,
    ) => {
        // list the ids of either all assets or only the filtered ones
        const assets_ids = filter_active ? filtered_assets : _.pluck(assets, 'id');
        // gather the set of operation groups ids for that list of assets
        const operation_groups_ids_set = {};
        for (const asset_id of assets_ids) {
            const asset = assets[asset_id];
            const with_operation_groups = (asset.component_structure !== null)
                ? component_structures[asset.component_structure]
                : maintenance_plans[asset.maintenance_plan];
            if (!with_operation_groups) {
                continue;
            }
            for (const id_og of with_operation_groups.operation_groups) {
                operation_groups_ids_set[id_og] = true;
            }
        }
        const options = [];
        const groups_options = [];
        for (const id_og in operation_groups_ids_set) {
            const group = operation_groups[id_og];
            for (const id_operation of group.operations) {
                const operation = operations[id_operation];
                if (operation) {
                    options.push({
                        value: operation.id,
                        label: `${group.name}: ${operation.name}`,
                    });
                }
            }
            groups_options.push({
                value: -id_og,
                label: `${i18n.t('Group')}: ${group.name}`,
            });
        }
        options.sort(sort_options);
        groups_options.sort(sort_options);
        return _.uniq(groups_options.concat(options), (v) => v.value);
    },
);

export default {
    getOperations,
    getAllOperationsOptions,
    getOperationsAndGroupsOptions,
    getOperationsAndGroupsOptionsByAssets,
};
