import React, { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { StepContext } from '../step/Step';
import FormLabel from '../formLabel/FormLabel';
import './FormTextInput.scss';
import TextDisplay from '../../../display/textDisplay/TextDisplay';
import Eye from './eye.svg';
import EyeSlash from './eye-slash.svg';

const FormTextInput = (props) => {
    const [_showPassword, setShowPassword] = useState(false);
    const methods = useFormContext();
    const valueError = (methods && methods.errors && methods.errors[props.id]) || props.valueError;

    // This is to be used with the Step Manager.
    // It allow to do Forms with multiple steps.
    // We have to register each field in the Step Manager to validate fields on each step instead of
    // validating each fields at the end of the form with the submit button.
    const stepContext = useContext(StepContext);
    useEffect(() => {
        if (stepContext && stepContext.addKey && !props.orphan) {
            stepContext.addKey(stepContext.step, props.id);
        }

        return (() => {
            if (methods) {
                methods.unregister(props.id);
            }
            if (stepContext && stepContext.deleteKey) {
                stepContext.deleteKey(stepContext.step, props.id);
            }
        });
    }, []);

    useEffect(() => {
        if (props.showPassword !== undefined) {
            setShowPassword(props.showPassword);
        }
    }, [props.showPassword]);

    const ref = (arg) => {
        if (props.orphan) return null;
        return methods && methods.register({ required: props.required })(arg);
    };

    if (props.disabled) {
        return (
            <>
                <input type="hidden" name={props.id} value={props.defaultValue} />
                <TextDisplay
                    {...props}
                    data={props.defaultValue}
                    onEdit={props.onEdit}
                />
            </>
        );
    }

    return (
        <div className={`form form_text_input ${props.className ? props.className : ''} ${props.id} ${props.lockSize ? 'lock-size' : ''} ${!props.label ? 'no-label' : ''}${props.large ? ' large' : ''}${props.password ? ' password-field' : ''}`} key={props.id}>
            <FormLabel {...props} />
            {
                props.large ? (
                    <textarea
                        className={`${props.className ? props.className : ''} ${valueError ? 'validation-error' : ''}`}
                        name={props.id}
                        defaultValue={props.defaultValue}
                        placeholder={props.placeholder}
                        ref={ref}
                        rows={5}
                        onChange={(e) => props.onChange && props.onChange(e.target.value)}
                        disabled={props.disabled}
                    />
                ) : (
                    <>
                        <input
                            className={`${props.className ? props.className : ''} ${valueError ? 'validation-error' : ''}`}
                            name={props.id}
                            defaultValue={props.defaultValue}
                            value={props.value}
                            placeholder={props.placeholder}
                            ref={ref}
                            onChange={(e) => props.onChange && props.onChange(e.target.value)}
                            disabled={props.disabled}
                            type={props.password && !_showPassword ? 'password' : 'text'}
                        />
                        {
                            props.password
                            && (
                                <div className="show-password-field">
                                    <img
                                        src={_showPassword ? Eye : EyeSlash}
                                        alt="show-password-icon"
                                        onClick={() => {
                                            if (props.onClickShowPassword) {
                                                props.onClickShowPassword(!_showPassword);
                                            } else {
                                                setShowPassword(!_showPassword);
                                            }
                                        }}
                                    />
                                </div>
                            )
                        }
                    </>
                )
            }
            {valueError && (
                <div className="form-validation-error">
                    <div className="form-validation-error-arrow" />
                    <div className="form-error-message">
                        {
                            (valueError.message)
                                ? <div>{valueError.message}</div>
                                : <div>Please enter a value</div>
                        }
                    </div>
                </div>
            )}
            { props.children }
        </div>
    );
};

export default FormTextInput;
