import React, { useContext } from 'react';
import i18n from 'i18next';
import './NoAssetSelected.scss';
import noAssetSelectedImg from './no_asset_selected.svg';

const NoAssetSelected = (props) => (
    <div className="nothing-selected-holder">
        <img className="nothing-selected" src={noAssetSelectedImg} alt="no asset selected" />
        <div className="title">{props.title}</div>
        <div className="sub-title">{props.subtitle}</div>
    </div>
);

NoAssetSelected.defaultProps = {
    title: i18n.t('No asset selected'),
    subtitle: i18n.t('Please select an asset first'),
};

export default NoAssetSelected;
