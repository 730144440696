import React from 'react';

import './CancelButton.scss';

const CancelButton = (props) => (
    <div
        className={`cancel-button-action ${props.disabled ? 'disabled' : ''}`}
        onClick={props.disabled ? () => null : props.onClick}
    >
        <span className="glyphicon glyphicon-remove shape circle" />
        <span className="cancel-button-action-text">{props.text}</span>
    </div>
);

export default CancelButton;
