import React from 'react';
import components from 'components';
import './campaign_detail.scss';
import CampaignEventDetail from '../../../../railfleet/lib/compounds/details/campaignEventDetail/CampaignEventDetail';

const { MultiDeviceLayout } = components.app;

const CampaignDetailComponent = (props) => (
    <CampaignEventDetail
        asset_id={props.asset_id}
        event_id={props.event_id}
        onCloseModal={props.onCloseModal}
        onClickEdit={props.onClickEdit}
        onClickClose={props.onClickClose}
        readOnly={props.readOnly}
        interventionIdRedirect={props.interventionIdRedirect}
        push_infos={props.push_infos}
    />
);

const CampaignDetail = (props) => (
    <MultiDeviceLayout>
        <CampaignDetailComponent {...props} />
    </MultiDeviceLayout>
);

export default CampaignDetail;
