import {
    createSelector,
} from 'reselect';

export const getAssetPreparation = (state) => (state.asset_preparations);

export const getAssetPreparationList = createSelector(
    [getAssetPreparation],
    (_) => _.asset_preparations,
);

export default {
    getAssetPreparationList,
};
