/**
 * Created by lpostula on 11/05/17.
 */
const FETCH_LIST_REQUEST = 'labels/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'labels/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'labels/FETCH_LIST_FAILED';

const FETCH_DETAIL_REQUEST = 'labels/FETCH_DETAIL_REQUEST';
const FETCH_DETAIL_COMPLETED = 'labels/FETCH_DETAIL_COMPLETED';
const FETCH_DETAIL_FAILED = 'labels/FETCH_DETAIL_FAILED';

const DELETE_COMPLETED = 'labels/DELETE_COMPLETED';

export default {
    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,
    FETCH_DETAIL_REQUEST,
    FETCH_DETAIL_COMPLETED,
    FETCH_DETAIL_FAILED,
    DELETE_COMPLETED,
};
