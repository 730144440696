import React, { useEffect, useState } from 'react';
import components from 'components';
import i18n from 'i18next';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { maintenanceEventsOperations, maintenanceEventsSelectors } from 'railfleet_state/ducks/maintenance_events';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { documentsSelectors, documentsOperations } from 'railfleet_state/ducks/documents';
import './CampaignEventForm.scss';
import scrollToTop from '../../../utils/scrollToTop';
import ComponentDisplay from '../../../components/display/componentDisplay/ComponentDisplay';

const {
    FormManager,
    FormDateTime,
    FormTextInput,
    CutForm,
    InputsWrapper,
    FormFile,
    FormCounterDoneUsage,
} = components.forms;

const { MultiDeviceLayout } = components.app;
const { TextDisplay } = components.display;

const CampaignEventForm = (props) => {
    const [_sent, setSent] = useState(false);
    const [_errorApi, setErrorApi] = useState(null);
    const [_from, setFrom] = useState(null);

    useEffect(() => {
        window.pausePolling(['all_assets_live_data']);
        if (window.pause_timeline_polling) {
            window.pause_timeline_polling();
        }
        return () => {
            window.unpausePolling(['all_assets_live_data']);
            if (window.unpause_timeline_polling) {
                window.unpause_timeline_polling();
            }
        };
    }, []);

    useEffect(() => {
        if (props.event.id) {
            props.fetchDocumentsForEvent(props.event.id);
        }
    }, [props.event.id]);

    const defaultValues = props.event_id ? (props.event) : {
        reported_by: props.user.full_name,
    };

    if (props.event_id) {
        defaultValues.date_closed = (
            props.event.date_closed && moment(props.event.date_closed)
        ) || moment();
    }

    const format_data = (data) => {
        const formated_data = { ...data };
        formated_data.id = props.event.id;
        formated_data.date_closed = data.date_closed && data.date_closed.toISOString();
        return formated_data;
    };

    const onSubmit = (data) => {
        const formated_data = format_data(data);
        setSent(true);
        props.updateEvent(formated_data).then((d) => {
            setSent(false);
            if (d.payload && !d.error) {
                setErrorApi(null);
                if (props.callback) props.callback(d.payload.id);
            }
            if (d.error) {
                setErrorApi(d.payload.response);
                scrollToTop();
            }
        });
    };

    const onCancel = () => {
        if (props.cancelCallback) props.cancelCallback();
    };

    const onChangeFrom = (arg) => {
        if (arg) setFrom(arg);
        else setFrom(null);
    };

    const getCloseFields = () => {
        if (props.event.state !== 'closed') return null;
        if (!props.event.counterreading_source_set) return null;
        return (
            <InputsWrapper
                id="maintenance_report"
                title={i18n.t('Maintenance report')}
            >
                <CutForm
                    device={props.device}
                >
                    <FormDateTime
                        id="date_closed"
                        label={i18n.t('Date closed')}
                        defaultValue={
                            defaultValues.date_closed
                        }
                        fillFlex
                        required
                        onChange={onChangeFrom}
                    />
                    <FormTextInput
                        id="rex"
                        defaultValue={defaultValues && defaultValues.rex}
                        label={i18n.t('Observations')}
                        large
                        lockSize
                    />
                </CutForm>
                {
                    props.event.counterreading_source_set
                        && (
                            <FormCounterDoneUsage
                                event_id={props.event_id}
                                date={_from}
                                defaultValue={defaultValues}
                            />
                        )
                }
            </InputsWrapper>
        );
    };

    const closeFields = getCloseFields();

    return (
        <div className={`form-main ${props.device} preventive-form`}>
            <FormManager
                onSubmit={onSubmit}
                textSubmitButton={props.event_id ? i18n.t('Update event') : i18n.t('Create event')}
                disabled={_sent}
                onCancel={onCancel}
                apiErrors={_errorApi}
            >
                <InputsWrapper
                    id="general"
                    title={i18n.t('General')}
                >
                    <CutForm
                        device={props.device}
                    >
                        <TextDisplay
                            label={i18n.t('Asset')}
                            data={props.asset_name}
                        />
                        <TextDisplay
                            label={i18n.t('Maintenance Type')}
                            data="preventive"
                        />
                        <FormTextInput
                            id="description"
                            defaultValue={defaultValues && defaultValues.description}
                            label={i18n.t('Description')}
                            large
                            required
                            lockSize
                        />
                        <div className="event-form-full-line">
                            <FormFile
                                id="documents"
                                label={i18n.t('Documents')}
                                defaultValue={props.event.documents}
                                documents={props.event.id && props.documents_for_event}
                                url="/api/document.json"
                                multi
                                deletable
                                deleteAction={(file) => props.deleteDocument(file)}
                            />
                        </div>
                    </CutForm>
                </InputsWrapper>
                { closeFields }
            </FormManager>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const user = state.users.me;
    const { event_id } = props;
    const { asset_id } = props;
    const event = event_id && maintenanceEventsSelectors.getMaintenanceEvent(state, event_id);
    const maintenance_type = event && event.maintenance_type;
    const asset = asset_id && assetsSelectors.getAssetInfos(state, asset_id);
    const asset_name = asset && asset.name;
    const documents_for_event = event_id
        && documentsSelectors.getDocumentsForEvent(state, event_id);
    return {
        users: state.users.all,
        user,
        event: event || {},
        asset_name,
        maintenance_type,
        documents_for_event: documents_for_event || {},
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateEvent: (payload) => dispatch(maintenanceEventsOperations.updateMaintenanceEvent(payload)),
    fetchDocumentsForEvent: (event_id) => (
        dispatch(documentsOperations.fetchForEvent(event_id))
    ),
    deleteDocument: (file) => dispatch(documentsOperations.deleteDocument(file)),
});

const Comp = (props) => (
    <MultiDeviceLayout>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <CampaignEventForm
            {...props}
        />
    </MultiDeviceLayout>
);

export default connect(mapStateToProps, mapDispatchToProps)(Comp);
