import React, { useEffect, useState } from 'react';
import { flagToCountryMap } from 'components/hooks/countryMap';
import Flags from 'country-flag-icons/react/3x2';
import { hasFlag } from 'country-flag-icons';

const useUnicodeFlag = () => {
    const [_support, setSupport] = useState(false);
    const supportsFlagEmoji = () => {
        const canvas = document.createElement('canvas');
        canvas.height = 10;
        canvas.width = canvas.height * 2;
        const ctx = canvas.getContext('2d');
        ctx.font = `${canvas.height} px Arial`;
        ctx.fillText('🇬🇧', 0, canvas.height);
        const data = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
        let i = 0;
        while (i < data.length) {
            if (data[i] !== data[i + 1] || data[i] !== data[i + 2]) return true;
            i += 4;
        }
        return false;
    };

    useEffect(() => {
        setSupport(supportsFlagEmoji);
    }, []);

    const getFlag = (unicode_flag, force = false) => {
        if (_support && !force) return unicode_flag;
        const code = flagToCountryMap[unicode_flag];
        const exist = hasFlag(code);
        if (!exist) return unicode_flag;
        const Flag = Flags[code];
        return (
            <Flag
                title={code}
                style={{
                    display: 'inline-block',
                    height: '1em',
                    width: '1em',
                }}
            />
        );
    };

    return getFlag;
};

export default useUnicodeFlag;
