/**
 * Created by lpostula on 15/05/17.
 */
import { RSAA } from 'redux-api-middleware';
import types from './types';
import { API_ROOT } from '../../config';

const API_RESOURCE_NAME = 'place';

const LIMIT_SEARCH_RESULT = 20;

const fetchListFiltered = (filter_name, filter_value) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}.json?${filter_name}=${filter_value}`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_LIST_REQUEST,
            types.FETCH_LIST_COMPLETED,
            types.FETCH_LIST_FAILED,
        ],
    },
});

export const fetchForEvent = (event_id) => fetchListFiltered('event', event_id);
export const fetchForIntervention = (intervention_id) => fetchListFiltered('interventions', intervention_id);

export const fetchDetails = (item_id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${item_id}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_DETAILS_REQUEST,
            types.FETCH_DETAILS_COMPLETED,
            types.FETCH_DETAILS_FAILED,
        ],
    },
});

export const fetchList = (long_polling = false) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            {
                type: types.FETCH_LIST_REQUEST,
                meta: { long_polling },
            },
            {
                type: types.FETCH_LIST_COMPLETED,
                meta: { long_polling },
            },
            types.FETCH_LIST_FAILED,
        ],
    },
});

export const searchByName = function (text, poi_type, with_intervention) {
    let url = `${API_ROOT + API_RESOURCE_NAME}.json?limit=${LIMIT_SEARCH_RESULT}`;

    if (text) {
        url += `&search=${text}`;
    }

    if (poi_type) {
        url += `&poi_type=${poi_type}`;
    }

    if (with_intervention) {
        url += '&with_intervention=1';
    }

    return {
        // in a wonderful world, we should cancel previous query
        [RSAA]: {
            endpoint: url,
            method: 'GET',
            credentials: 'same-origin',
            types: [
                types.SEARCH_REQUEST,
                types.SEARCH_COMPLETED,
                types.SEARCH_FAILED,
            ],
        },
    };
};

export const fetchLocationByAssetByDate = (asset_id, date, callback) => (dispatch) => {
    dispatch({
        [RSAA]: {
            endpoint: `${API_ROOT}asset/${asset_id}/location/?date=${date}`, // todo this endpoint, should probably be on the location
            method: 'get',
            credentials: 'same-origin',
            types: [
                types.SEARCH_FOR_ASSET_BY_DATE_REQUEST,
                {
                    type: types.SEARCH_FOR_ASSET_BY_DATE_COMPLETED,
                    payload: (action, state, res) => res.json().then((json) => {
                        if (json.id !== null && json.id !== undefined) {
                            callback(json.id);// call the callback
                        }
                        return json;
                    }),
                },
                types.SEARCH_FOR_ASSET_BY_DATE_FAILED,
            ],
        },
    });
    return Promise.resolve();
};

export const searchByNameSmartLocation = function (text, asset_class_id, assign_to_id) {
    let url = `${API_ROOT + API_RESOURCE_NAME}/prediction_for_intervention.json?limit=${LIMIT_SEARCH_RESULT}&asset_class=${asset_class_id}&assign_to=${assign_to_id}`;

    if (text) {
        url += `&search=${text}`;
    }

    return {
        // in a wonderful world, we should cancel previous query
        [RSAA]: {
            endpoint: url,
            method: 'GET',
            credentials: 'same-origin',
            types: [
                types.SEARCH_REQUEST,
                types.SEARCH_COMPLETED,
                types.SEARCH_FAILED,
            ],
        },
    };
};

export default {
    fetchForEvent,
    fetchForIntervention,
    fetchDetails,
    fetchListFiltered,
    fetchList,
    fetchLocationByAssetByDate,
    searchByName,
    searchByNameSmartLocation,
};
