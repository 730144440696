/**
 * Created by lpostula on 26/10/17.
 */
import types from './types';

export const addSnackbar = (snack) => ({
    type: types.SB_CREATE,
    payload: snack,
});

export const removeSnackbar = (snack) => ({
    type: types.SB_REMOVE,
    payload: snack,
});

export const queueSnackbar = (snack) => ({
    type: types.SB_QUEUE,
    payload: snack,
});

export const unqueueSnackbar = (snack) => ({
    type: types.SB_QUEUE_REMOVE,
    payload: snack,
});

export const selectCounterReadingAsset = (asset_id) => ({
    type: types.SELECT_COUNTER_READING_ASSET,
    payload: asset_id,
});

export const reloadPage = (payload) => ({
    type: types.RELOAD_PAGE,
    payload,
});

export const dismissReloadPage = () => ({
    type: types.DISMISS_RELOAD,
});

export default {
    addSnackbar,
    removeSnackbar,
    queueSnackbar,
    unqueueSnackbar,
    selectCounterReadingAsset,
    reloadPage,
    dismissReloadPage,
};
