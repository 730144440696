/**
 * Created by lpostula on 17/05/18.
 */
import i18n from 'i18next';

import { default as actions } from './actions';
import { userHasPerm } from '../users/selectors';
import { createSnackBar } from '../ui/operations';

export const { fetchList } = actions;
export const { fetchDetails } = actions;
export const { updateCampaign } = actions;
export const { createCampaign } = actions;

export const cancelCampaign = (id) => (dispatch, getState) => {
    const state = getState();
    const user_has_perm = userHasPerm(state)('cancel_campaign');
    if (!user_has_perm) {
        return dispatch(createSnackBar({
            id: `perm_denied_cancel_campaign_${id}`,
            message: i18n.t('Permission denied'),
            className: 'danger',
        }));
    }
    if (!window.confirm(i18n.t('Are you sure you want to cancel this campaign?'))) {
        return Promise.resolve();
    }
    return dispatch(
        actions.cancelCampaign(id),
    ).then(
        (d) => {
            // forward errors if the creation failed
            if (d.error) {
                return dispatch(createSnackBar({
                    id: `error_cancel_campaign_${id}`,
                    message: d.error,
                    className: 'danger',
                }));
            }
            return dispatch(createSnackBar({
                id: `success_cancel_campaign_${id}`,
                message: i18n.t('Campaign cancelled'),
                className: 'success',
            }));
        },
    );
};

export const duplicateCampaign = (id, onSuccessCallback) => (dispatch) => {
    if (!window.confirm(i18n.t('Are you sure you want to duplicate this campaign?'))) {
        return Promise.resolve();
    }
    return dispatch(
        actions.duplicateCampaign(id),
    ).then(
        (d) => {
            // forward errors if the creation failed
            if (d.error) {
                return dispatch(createSnackBar({
                    id: `error_duplicate_campaign_${id}`,
                    message: d.error,
                    className: 'danger',
                }));
            }
            dispatch(createSnackBar({
                id: `success_duplicate_campaign_${id}`,
                message: i18n.t('Campaign duplicated'),
                className: 'success',
            }));
            return dispatch(() => onSuccessCallback(d.payload.id));
        },
    );
};

export const approveCampaign = (id) => (dispatch, getState) => {
    const state = getState();
    const user_has_perm = userHasPerm(state)('approve_campaign');
    if (!user_has_perm) {
        return dispatch(createSnackBar({
            id: `perm_denied_approve_campaign_${id}`,
            message: i18n.t('Permission denied'),
            className: 'danger',
        }));
    }
    if (!window.confirm(i18n.t('Are you sure you want to approve this campaign?'))) {
        return Promise.resolve();
    }
    return dispatch(
        actions.approveCampaign(id),
    ).then(
        (d) => {
            // forward errors if the creation failed
            if (d.error) {
                return dispatch(createSnackBar({
                    id: `error_approve_campaign_${id}`,
                    message: d.error,
                    className: 'danger',
                }));
            }
            return dispatch(createSnackBar({
                id: `success_cancel_campaign_${id}`,
                message: i18n.t('Campaign approved'),
                className: 'success',
            }));
        },
    );
};

export const assignAssetsToCampaign = (id, asset_ids) => (dispatch, getState) => {
    const state = getState();
    const user_has_perm = userHasPerm(state)('assign_campaign');
    if (!user_has_perm) {
        return dispatch(createSnackBar({
            id: `perm_denied_approve_campaign_${id}`,
            message: i18n.t('Permission denied'),
            className: 'danger',
        }));
    }
    return dispatch(
        actions.assignAssetsToCampaign(id, asset_ids),
    ).then(
        (d) => {
            // forward errors if the creation failed
            if (d.error) {
                return dispatch(createSnackBar({
                    id: `error_assign_campaign_${id}`,
                    message: d.error,
                    className: 'danger',
                }));
            }
            return dispatch(createSnackBar({
                id: `success_assign_campaign_${id}`,
                message: i18n.t('Asset successfully assign to campaign'),
                className: 'success',
            }));
        },
    );
};

export const deAssignAssetsToCampaign = (id, asset_ids) => (dispatch, getState) => {
    const state = getState();
    const user_has_perm = userHasPerm(state)('assign_campaign');
    if (!user_has_perm) {
        return dispatch(createSnackBar({
            id: `perm_denied_approve_campaign_${id}`,
            message: i18n.t('Permission denied'),
            className: 'danger',
        }));
    }
    if (!window.confirm(i18n.t('Are you sure you want to deassign this asset?'))) {
        return Promise.resolve();
    }
    return dispatch(
        actions.deAssignAssetsToCampaign(id, asset_ids),
    ).then(
        (d) => {
            // forward errors if the creation failed
            if (d.error) {
                return dispatch(createSnackBar({
                    id: `error_deassign_campaign_${id}`,
                    message: d.error,
                    className: 'danger',
                }));
            }
            return dispatch(createSnackBar({
                id: `success_deassign_campaign_${id}`,
                message: i18n.t('Asset successfully deassign from campaign'),
                className: 'success',
            }));
        },
    );
};

export const incomingWS = (data) => (dispatch) => {
    switch (data.type) {
    case 'update':
        dispatch(actions.updateInStore(data.payload));
        break;
    default:
        break;
    }
};

export default {
    fetchDetails,
    fetchList,
    createCampaign,
    updateCampaign,
    cancelCampaign,
    duplicateCampaign,
    approveCampaign,
    assignAssetsToCampaign,
    deAssignAssetsToCampaign,
    incomingWS,
};
