import React from 'react';

import './Checkbox.scss';

const Checkbox = (props) => (
    <label className="checkbox-container">
        <input
            type="checkbox"
            checked={props.value}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => e.stopPropagation()}
            disabled={props.disabled}
        />
        <span
            className="checkmark"
            onClick={(e) => {
                e.stopPropagation();
                props.onChange(!props.value);
            }}
        />
    </label>
);

Checkbox.default = {
    value: false,
};

export default Checkbox;
