import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import './ComboSelector.scss';
import Selector from '../selector/Selector';
import BubbleListSelector from '../bubbleListSelector/BubbleListSelector';
import WithPopper from '../../app/WithPopper';

class ComboSelector extends React.Component {
    constructor(props) {
        super(props);
        this.onChangeColumn = this.onChangeColumn.bind(this);
        this.onChangeSymbol = this.onChangeSymbol.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.getSymbolLabel = this.getSymbolLabel.bind(this);
        this.addValue = this.addValue.bind(this);
        this.removeValue = this.removeValue.bind(this);
        this.getSymbolId = this.getSymbolId.bind(this);
        this.onChangeSelector = this.onChangeSelector.bind(this);
        this.initSelected = this.initSelected.bind(this);
        this.alreadyExists = this.alreadyExists.bind(this);
        this.onClickValue = this.onClickValue.bind(this);
        this.onClickAdd = this.onClickAdd.bind(this);
        this.getIndexWithId = this.getIndexWithId.bind(this);
        this.popupCallback = this.popupCallback.bind(this);

        this.state = {
            column_selected: null,
            symbol_selected: 'eg',
            value_text: '',
            results: [],
            id_selected: null,
            left: null,
            showPopup: false,
        };
        this.symbols = [
            { id: 'eg', label: '==' },
            { id: 'gt', label: '>' },
            { id: 'gte', label: '>=' },
            { id: 'lt', label: '<' },
            { id: 'lte', label: '<=' },
            { id: 'neg', label: '!=' },
            { id: 'sw', label: i18n.t('startswith') },
            { id: 'ew', label: i18n.t('endswith') },
            { id: 'ctns', label: i18n.t('contains') },
            { id: 'nctns', label: i18n.t('not contains') },
            { id: 'ne', label: i18n.t('is not empty') },
            { id: 'isnull', label: i18n.t('is empty') },
        ];
    }

    componentDidMount() {
        this.initSelected();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.left !== this.props.left) {
            // eslint-disable-next-line
            this.setState({ left: this.props.left });
        }
        if (prevProps.selected !== this.props.selected) {
            this.initSelected();
        }
    }

    onChangeColumn(evt) {
        if (evt && evt.length > 0) {
            const left = this.state.left || [];
            if (!left.includes(evt[0])) {
                left.push(evt[0]);
            }
            this.setState({ column_selected: evt[0], left });
        } else {
            this.setState({ column_selected: null });
        }
    }

    onChangeSymbol(evt) {
        this.setState({ symbol_selected: evt[0] });
    }

    onChangeValue(evt) {
        const { value } = evt.target;
        this.setState({ value_text: value });
    }

    getSymbolLabel(id) {
        for (const index in this.symbols) {
            const symbol = this.symbols[index];
            if (id === symbol.id) {
                return symbol.label;
            }
        }
        return null;
    }

    getSymbolId(label) {
        for (const index in this.symbols) {
            const symbol = this.symbols[index];
            if (label === symbol.label) {
                return symbol.id;
            }
        }
        return null;
    }

    removeValue(id, results = null) {
        const ret = [];
        const data = results || this.state.results;
        for (const key in data) {
            const elm = data[key];
            if (elm.id !== id) {
                ret.push(elm);
            }
        }
        return ret;
    }

    initSelected() {
        const results = [];
        for (const index in this.props.selected) {
            const elm = this.props.selected[index].split('__');
            if (elm.length !== 3) continue;
            const temp = {};
            temp.column = elm[0];
            temp.symbolLabel = this.getSymbolLabel(elm[1]);
            temp.symbol = elm[1];
            temp.value = elm[2];
            temp.id = `${temp.column}_${temp.symbol}_${temp.value}`;
            results.push(temp);
        }
        this.setState({ results });
    }

    getIndexWithId(id) {
        for (const key in this.state.results) {
            const elm = this.state.results[key];
            if (elm.id === id) {
                return key;
            }
        }
        return null;
    }

    alreadyExists(id) {
        for (const key in this.state.results) {
            const elm = this.state.results[key];
            if (elm.id === id) {
                return true;
            }
        }
        return false;
    }

    popupCallback(isOpen) {
        this.setState({ showPopup: isOpen });
    }

    addValue(evt) {
        if (evt) {
            evt.preventDefault();
        }
        const column = this.state.column_selected;
        const symbol = this.state.symbol_selected
            ? this.getSymbolLabel(this.state.symbol_selected)
            : null;
        const value = this.state.value_text !== ''
            ? this.state.value_text
            : null;
        const { symbol_selected } = this.state;
        if (!column || !symbol || (!['ne', 'isnull'].includes(symbol_selected) && !value)) {
            return;
        }

        const temp = {};
        temp.column = column;
        temp.symbolLabel = symbol;
        temp.symbol = this.state.symbol_selected;
        temp.value = ['ne', 'isnull'].includes(symbol_selected) ? '' : value || '';
        temp.id = `${temp.column}_${temp.symbol}_${temp.value}`;
        if (this.alreadyExists(temp.id)) {
            this.setState({
                column_selected: null, symbol_selected: 'lte', value_text: '', id_selected: null,
            });
            return;
        }
        let results = Object.assign([], this.state.results);
        if (this.state.id_selected) {
            const index = this.getIndexWithId(this.state.id_selected);
            if (index) {
                results = this.removeValue(this.state.id_selected, results);
            }
        }
        results.push(temp);
        this.setState({
            results, column_selected: null, symbol_selected: 'eg', value_text: '', id_selected: null,
        });
        this.props.onChange(results);
    }

    onChangeSelector(id) {
        if (!this.props.disabled) {
            const results = this.removeValue(id);
            this.setState({ results });
            this.props.onChange(results);
        }
    }

    onClickValue(id) {
        if (!this.props.disabled) {
            const index = this.getIndexWithId(id);
            const elm = this.state.results[index];
            const column_selected = elm.column;
            const symbol_selected = elm.symbol;
            const value_text = elm.value;
            const id_selected = elm.id;
            this.setState({
                column_selected,
                symbol_selected,
                value_text,
                id_selected,
            });
            // this.props.onChange(this.state.results);
        }
    }

    onClickAdd(evt) {
        evt.preventDefault();
        if (!this.props.locked) {
            this.setState({
                column_selected: null, symbol_selected: 'eg', value_text: '', id_selected: null,
            });
            this.props.onChange(this.state.results);
        }
    }

    render() {
        return (
            <>
                <WithPopper
                    dontOpenOnHover
                    openCallback={this.popupCallback}
                    extraClass="combo-popup-root"
                >
                    <div className="advanced_search_main">
                        <BubbleListSelector
                            values={this.state.results}
                            id_getter={(item) => item.id}
                            labelizer={(item) => `${item.column} ${item.symbolLabel} ${item.value}`}
                            onChange={this.onChangeSelector}
                            onClickValue={this.onClickValue}
                            onClickAdd={this.onClickAdd}
                            id_selected={this.state.id_selected}
                            active={this.state.showPopup}
                            inline_label={i18n.t('Advanced filters')}
                            disabled={this.props.disabled}
                        />
                    </div>
                    <div className="advanced_search_popup">
                        <h3>{i18n.t('Add advanced filters')}</h3>
                        <div className="advanced_search_popup_fields">
                            <div className="adv-field">
                                <Selector
                                    onChange={this.onChangeColumn}
                                    values={this.state.left}
                                    id_getter={this.props.id_getter}
                                    labelizer={this.props.labelizer}
                                    selected={this.state.column_selected}
                                    disabled={this.props.disabled}
                                    label={`${i18n.t('Column')}...`}
                                    creatable
                                    virtual
                                />
                            </div>
                            <div className="adv-operator">
                                <Selector
                                    onChange={this.onChangeSymbol}
                                    values={this.symbols}
                                    id_getter={(item) => item.id}
                                    labelizer={(item) => item.label}
                                    selected={this.state.symbol_selected}
                                    disabled={this.props.disabled}
                                    clearable={false}
                                />
                            </div>
                            <div className="adv-value">
                                <div className="input-group filter">
                                    <input
                                        value={this.state.value_text}
                                        type="text"
                                        onChange={this.onChangeValue}
                                        className="form-control"
                                        placeholder={i18n.t('Value...')}
                                        disabled={
                                            this.props.disabled || ['ne', 'isnull'].includes(this.state.symbol_selected)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="adv-btn-group">
                                <button
                                    type="button"
                                    className="btn btn-default add"
                                    onClick={this.addValue}
                                    disabled={this.props.disabled}
                                >
                                    <span className="glyphicon glyphicon-plus" />
&nbsp;
                                    {i18n.t('Add')}
                                </button>
                            </div>
                        </div>
                    </div>
                </WithPopper>
            </>
        );
    }
}

ComboSelector.defaultProps = {
    left: [],
    labelizer: (item) => item.label,
    id_getter: (item) => item.id,
    selected: null,
    locked: false,
    disabled: false,
};

ComboSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
    labelizer: PropTypes.func,
    id_getter: PropTypes.func,
    selected: PropTypes.array,
    locked: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default ComboSelector;
