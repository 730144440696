import { combineReducers } from 'redux';
import types from './types';
import collections from './collections';
import { createReducer, parseLinkHeader } from '../../utils';

const collectionInitialState = (collection) => {
    const fields = {};
    for (const f in collections[collection].fields) {
        fields[f] = collections[collection].fields[f].default;
    }
    return ({
        fields,
        pagination: {
            current_page: null,
            pages: {},
            links: {},
        },
        fetching: false,
        failed: false,
        controller: null,
        pristine: true,
    });
};

const collectionReducer = (collection) => {
    let req_types;
    const { redux_types } = collections[collection];
    if (Array.isArray(redux_types) && redux_types.length === 3) {
        req_types = redux_types;
    } else {
        req_types = [
            redux_types.FETCH_LIST_REQUEST,
            redux_types.FETCH_LIST_COMPLETED,
            redux_types.FETCH_LIST_FAILED,
        ];
    }
    return createReducer(collectionInitialState(collection))({
        [types.FILTER_APPLY_CANCEL]: (state, action) => {
            if (action.metadata.collection !== collection) {
                // this does not matter for this collection
                return state;
            }
            return { ...state, fetching: false, failed: false };
        },
        [types.FILTER_APPLY]: (state, action) => {
            if (action.metadata.collection !== collection) {
                // this does not matter for this collection
                return state;
            }
            if (action.payload.exception_name) {
                return ({
                    ...state, fetching: false, failed: true,
                });
            }
            // first we need to parse the headers
            const link = action.metadata && action.metadata.link;
            const parsed_link = parseLinkHeader(link);
            const pagination_state = { ...state.pagination };
            let current_page = 1;
            if (parsed_link) {
                pagination_state.links = parsed_link;
                if (parsed_link.prev) {
                    current_page = parsed_link.prev.page + 1;
                } else if (parsed_link.next) {
                    current_page = parsed_link.next.page - 1;
                }
            }
            pagination_state.current_page = current_page;
            // now, we set which ids are present on this page
            const ids = action.payload.map((e) => e.id);
            pagination_state.pages = {

                ...pagination_state.pages,
                [current_page]: {
                    fetching: false,
                    ids,
                },
            };
            return {

                ...state,
                pagination: pagination_state,
                pristine: true,
            };
        },
        [types.FILTER_RESET]: (state, action) => {
            if (action.metadata.collection !== collection) {
                // this does not matter for this collection
                return state;
            }
            return {

                ...collectionInitialState(collection),
            };
        },
        [types.FILTER_SET_FIELD]: (state, action) => {
            if (action.metadata.collection !== collection) {
                // this does not matter for this collection
                return state;
            }
            const field = collections[action.metadata.collection].fields[action.metadata.field];
            if (!field) {
                // the field is not supported
                return state;
            }
            let value = action.payload;
            if (field.type === 'enum' && !field.multi && Array.isArray(value)) {
                if (value.length > 0) value = value[0];
                else value = null;
            }
            const fields_state = {

                ...state.fields,
                [action.metadata.field]: value,
            };
            return {

                ...state,
                fields: fields_state,
                pristine: false,
            };
        },
        [req_types[0]]: (state, action) => {
            const new_state = { fetching: true, failed: false };
            if (action.meta && !action.error) {
                new_state.controller = action.meta.controller;
            }
            return { ...state, ...new_state };
        },
        [req_types[1]]: (state) => ({
            ...state, fetching: false, controller: null,
        }),
        [req_types[2]]: (state, action) => {
            if (!action.payload.message.includes('abort')) {
                return ({
                    ...state, fetching: false, failed: true,
                });
            }
            return ({
                ...state, failed: true,
            });
        },
    });
};

const reducers = {};
for (const collection in collections) {
    reducers[collection] = collectionReducer(collection);
}

export default combineReducers(reducers);
