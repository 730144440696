import { combineReducers } from 'redux';
import types from './types';
import { createReducer, apiHelpers } from '../../utils';

const apiMessageLogsReducer = createReducer({})({
    [types.FETCH_LINEAS_GMAO_API_MESSAGE_LOGS_COMPLETED]: apiHelpers.fetch_list_complete,
});

const LineasGmaoReducer = combineReducers({
    api_message_logs: apiMessageLogsReducer,
});

export default LineasGmaoReducer;
