import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import moment from 'moment-timezone';
import DocumentIcon from './DocumentIcon.png';
import ClockIcon from './ClockIcon.svg';

import './DocumentsList.scss';
import useFetch from '../../../components/hooks/useFetch';

const DocumentsList = (props) => {
    const [_data, setData] = useState(null);
    const { fetchAll } = useFetch();

    useEffect(() => {
        if (!props.asset_id) return;
        fetchAll(`/driverlogbook/api/documents/${props.asset_id}/`).then((d) => {
            if (d) {
                setData([...d.type_documents, ...d.asset_documents]);
            } else {
                setData(null);
            }
        });
    }, [props.asset_id]);

    if (_.isEmpty(_data)) return null;

    return (
        <div className="documents-list-root">
            <div className="document-list-title">{props.title}</div>
            <div className="documents-list">
                {
                    _data.map((document) => (
                        <DocumentElm
                            {...document}
                        />
                    ))
                }
            </div>
        </div>
    );
};

const DocumentElm = (props) => (
    <a href={props.file_url} target="_blank" rel="noopener noreferrer" className="document-elm">
        <div className="document-elm-left">
            <img className="document-icon" src={DocumentIcon} alt="document icon" />
        </div>
        <div className="document-elm-right">
            <div className="document-elm-title">
                {props.name || props.file_name}
            </div>
            {props.type_name && <div className="document-elm-label">{props.type_name}</div>}
            <div className="document-elm-date">
                <img className="clock-icon" src={ClockIcon} alt="clock icon" />
                {`${i18n.t('Last upload')}: ${moment(props.created_at).format('DD/MM/YYYY HH:mm')}`}
            </div>
        </div>

    </a>
);

export default DocumentsList;
