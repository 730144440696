import React from 'react';
import PropTypes from 'prop-types';

const URL = 'https://maps.googleapis.com/maps/api/js';

export default class MapWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: !!document.getElementById('api_map'),
            key: window.settings.GOOGLE_MAP_KEY,
        };
        this.onLoad = this.onLoad.bind(this);
    }

    getChildContext() {
        return {
            googleAPIKey: this.state.key,
        };
    }

    onLoad() {
        this.setState({
            loaded: true,
        });
    }

    componentDidMount() {
        const prev_script = document.getElementById('api_map');
        // if the script is already loaded
        if (prev_script) {
            return;
        }
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'api_map';
        script.src = `${URL}?key=${this.state.key}`;
        script.onload = this.onLoad;
        document.body.appendChild(script);
    }

    render() {
        if (!this.state.loaded) {
            return null;
        }

        return this.props.children;
    }
}
MapWrapper.childContextTypes = {
    googleAPIKey: PropTypes.string,
};
