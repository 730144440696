import React from 'react';
import PropTypes from 'prop-types';
import './Stamp.scss';
import NotOk from './NotOk.svg';
import Ok from './Ok.svg';
import MultiDeviceLayout from '../../app/MultiDeviceLayout';

const StampComponent = (props) => (
    <div className={`stamp-holder ${props.valid ? 'valid' : ''} ${props.device}`}>
        {
            props.valid ? (
                <img className="ok-img" src={Ok} alt="ok" />
            ) : (
                <img className="not-ok-img" src={NotOk} alt="not ok" />
            )
        }
        {
            props.text
                && (
                    <div className="stamp-text-holder">
                        <div className="stamp-text">{props.text}</div>
                    </div>
                )
        }
    </div>
);

StampComponent.propTypes = {
    // if we display the valid or invalid stamp (red or green)
    valid: PropTypes.bool,
    // the text to display under the img
    text: PropTypes.string,
};

const Stamp = (props) => (
    <MultiDeviceLayout>
        <StampComponent {...props} />
    </MultiDeviceLayout>
);

export default Stamp;
