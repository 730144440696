import React from 'react';
import PropTypes from 'prop-types';

import '../CommonButton.scss';

// <button className="btn btn-default">
//     <img src={icon_excel} alt="icon_excel" />{ `${i18n.t('Export to Excel')}` }
// </button>
const Button = (props) => {
    if (props.submitButton && !props.disabled) {
        return (
            <button
                type="submit"
                className={`sscustom-button ${props.primary ? 'primary' : 'secondary'} ${props.black ? 'black' : ''} ${props.white ? 'white' : ''} ${props.large ? 'large' : ''} ${props.small ? 'small' : ''} ${props.disabled ? 'disabled' : ''} ${props.className}`}
                onClick={props.onClick ? props.onClick : () => null}
            >
                <span className="label">{props.label}</span>
            </button>
        );
    }
    const onClick = (evt) => {
        evt.preventDefault();
        if (!props.disabled) props.onClick(evt);
    };
    return (
        <button
            className={`sscustom-button ${props.primary ? 'primary' : 'secondary'} ${props.black ? 'black' : ''} ${props.white ? 'white' : ''} ${props.large ? 'large' : ''} ${props.small ? 'small' : ''} ${props.disabled ? 'disabled' : ''} ${props.className}`}
            onClick={onClick}
        >
            {
                props.icon
                    && <img src={props.icon} alt="icon_excel" />
            }
            {
                !_.isEmpty(props.children) ? props.children : (
                    <span className="label">{props.label}</span>
                )
            }
        </button>
    );
};

Button.defaultProps = {
    primary: false,
    black: false,
    white: false,
    large: false,
    small: false,
    label: 'my-button',
    disabled: false,
};

Button.propTypes = {
    onClick: PropTypes.func,
    primary: PropTypes.bool,
    black: PropTypes.bool,
    white: PropTypes.bool,
    label: PropTypes.string,
    large: PropTypes.bool,
    small: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default Button;
