import React, { useEffect, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import moment from 'moment-timezone';
import ReactSelectWrapper from '../ReactSelectWrapper';
import { StepContext } from '../step/Step';
import FormLabel from '../formLabel/FormLabel';
import './FormHotlineCall.scss';
import HotlineSuggestion from './hotlineSuggestion';
import WithLoginless from '../../../app/withLoginless';

const getHotlineSuggestion = async (asset_id, date, secret) => {
    const settings = {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
            'SUPERNOVA-LOGINLESS-ASSET-ID': asset_id,
            'SUPERNOVA-LOGINLESS-SECRET': secret,
        },
    };
    const url = `/api/hotline_call/suggested.json?asset=${asset_id}&date=${moment(date).toISOString()}`;
    const r = await fetch(url, settings);
    const d = await r.json();
    return d;
};

const GetAttachedCall = async (event_id) => {
    const settings = {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    };
    const url = `/api/hotline_call/attached.json?event_id=${event_id}`;
    const r = await fetch(url, settings);
    const d = await r.json();
    return d;
};

const FormHotlineCall = (props) => {
    const [_suggestions, setSuggestions] = useState([]);
    const [_calls_attached, setCallsAttached] = useState([]);
    const [_eligible_source, setEligibleSource] = useState([]);
    const [_has_source, setHasSource] = useState(false);
    const [_editMode, setEditMode] = useState(true);
    const [_calls_exists, setCallExists] = useState(false);

    const { errors } = useFormContext();
    const valueError = errors && errors[props.id];

    const stepContext = useContext(StepContext);
    useEffect(() => {
        if (stepContext && stepContext.addKey) stepContext.addKey(stepContext.step, props.id);
    }, []);

    useEffect(() => {
        setHasSource(_calls_attached.length > 0);
    }, [_calls_attached]);

    useEffect(() => {
        setCallExists(
            _eligible_source && _eligible_source.length > 0
            && (_suggestions.length > 0 || _calls_attached.length > 0),
        );
    }, [_suggestions, _calls_attached, _eligible_source]);

    useEffect(() => {
        if (props.readOnly) setEditMode(false);
        else setEditMode(true);
    }, [props.readOnly, _has_source, props.event_id]);

    useEffect(() => {
        if (props.asset_id && props.date) {
            const hotline_suggestion = getHotlineSuggestion(
                props.asset_id, props.date, props.secret,
            );
            hotline_suggestion.then((d) => {
                if (d) {
                    const filtered = _.filter(d, (elm) => elm.id !== props.current);
                    setSuggestions(filtered);
                }
            });
        }
    }, [props.asset_id, props.date, props.addOnly]);

    useEffect(() => {
        if (props.event_id) {
            const attached_calls = GetAttachedCall(props.event_id);
            attached_calls.then((d) => {
                if (d) {
                    setCallsAttached(d);
                }
            });
        }
    }, [props.event_id]);

    useEffect(() => {
        const eligible_source = [];
        if (_suggestions && _suggestions.length > 0) {
            for (const suggestion of _suggestions) {
                if (!suggestion.solved) {
                    eligible_source.push(suggestion.id);
                }
            }
        }
        setEligibleSource(eligible_source);
    }, [_suggestions]);

    return (
        <div className={`form form_hotline_call ${props.className ? props.className : ''} ${props.id} ${!props.label ? 'no-label' : ''}${props.large ? ' large' : ''}`} key={props.id}>
            <FormLabel {...props} />
            <ReactSelectWrapper
                classname="hotline_call"
                // eslint-disable-next-line react/no-children-prop
                children={HotlineSuggestion}
                name={props.id}
                validate={(data) => data}
                calls_exists={_calls_exists && _eligible_source.length > 0}
                readOnly={!_editMode || _has_source}
                addOnly={_editMode}
                onChange={() => null}
                event={props.event_id}
                has_source={_has_source}
                suggestions={_suggestions}
                attached_calls={_calls_attached}
                eligible_source={_eligible_source}
                {...props}
            />
            {valueError && (
                <div className="form-validation-error">
                    <div className="form-validation-error-arrow" />
                    <div className="form-error-message">
                        {
                            (valueError.message)
                                ? <div>{valueError.message}</div>
                                : <div>Please enter a value</div>
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state, props) => {
    if (props.loginless) return props.mapStateToProps(state, props);
    return {};
};

const mapDispatchToProps = (dispatch, props) => {
    if (props.loginless) return props.mapDispatchToProps(dispatch, props);
    return ({});
};

export default WithLoginless(connect(mapStateToProps, mapDispatchToProps)(FormHotlineCall));
