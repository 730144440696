import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import moment from 'moment-timezone';
import components from 'components';
import { countersSelectors } from 'railfleet_state/ducks/counters';
import './EventsList.scss';
import useFetch from '../../../components/hooks/useFetch';

const { Table } = components.tables;
const { MultiDeviceLayout } = components.app;

const MaintenanceStateTranslation = {
    open: i18n.t('open'),
    unplanned: i18n.t('unplanned'),
    planned: i18n.t('planned'),
    in_progress: i18n.t('in_progress'),
    closed: i18n.t('closed'),
    due: i18n.t('due'),
    overdue: i18n.t('overdue'),
    due_tl: i18n.t('due_tl'),
    overdue_tl: i18n.t('overdue_tl'),
    init: i18n.t('init'),
};

const EventsListComponent = (props) => {
    const [_ordering, setOrdering] = useState('-sorting_date');
    const { fetchAll, isLoading, data } = useFetch();
    const [_events, setEvents] = useState(null);

    useEffect(() => {
        if (props.loginless) {
            setEvents(props.events);
        }
    }, [props.loginless]);

    useEffect(() => {
        if (!props.loginless) {
            setEvents(data);
        }
    }, [props.loginless, data]);

    useEffect(() => {
        if (props.asset_id && !props.loginless) {
            fetchAll('/api/v2/maintenance.json', {
                asset_ids: props.asset_id,
                ordering: _ordering,
                page_size: 50,
                state: props.result,
                states: props.states,
                maintenance_type: props.maintenance_type || 'corrective',
            });
        }
    }, [props.asset_id, _ordering, props.refresh, props.loginless]);

    const eventIdRenderer = (cellData, rowData) => {
        if (rowData.private) {
            // code below is an event id with a vertically aligned icon
            // that doesn't break when the layout switch in vertical stack
            return (
                <div>
                    <span>
                        { `M${cellData}` }
                    </span>
                    <span
                        style={{ lineHeight: '8px' }}
                        className="glyphicons glyphicons-eye-close"
                    />
                </div>
            );
        }
        return `M${cellData}`;
    };

    const descriptionRenderer = (cellData, rowData, device) => {
        let event_name = '';
        if (device === 'mobile') {
            event_name = `M${rowData.id}`;
        }
        let inf = '';
        if (rowData.counter) {
            const cnt = props.counters[rowData.counter];
            if (cnt != null ? cnt.serial : null) {
                inf = ` (${cnt.serial})`;
            }
        }
        return (
            <div className="description-renderer">
                {
                    device === 'mobile'
                        && <span>{ event_name }</span>
                }
                {cellData + inf}
            </div>
        );
    };

    const maintenanceDateStateRenderer = (cellData) => {
        let { status } = cellData;
        const date = moment(cellData.date);

        const date_label = moment(cellData.date)
            .format('DD/MM/YYYY');
        let tolerance_days = null;

        // handle tolerance extra days, the hard way
        const splitted = status.split('_');
        // handle "+ XX" tolerance date in the status
        if ((splitted.length === 2) && !isNaN(splitted[1])) {
            status = splitted[0];
            tolerance_days = ` +${splitted[1]}${i18n.t('day_short_notation')}`;
        }

        const in14days = moment().endOf('day').add(14, 'days');
        const color = status.includes('overdue') ? 'overdue'
            : status.includes('unplanned') ? 'unplanned'
                : status.includes('due') && date.isSameOrBefore(in14days) ? 'unplanned'
                    : status.includes('due') ? 'due' : status;
        status = MaintenanceStateTranslation[status].toUpperCase();

        return (
            <div className="status-block">
                <div className={`status ${color}`}>{status}</div>
                <div className="date">
                    {date_label}
                    {(tolerance_days) && <span className="plus_days">{tolerance_days}</span>}
                </div>
            </div>
        );
    };

    const columns = [
        { key: 'id', label: i18n.t('Event Nr'), renderer: eventIdRenderer },
        { key: 'description', label: i18n.t('Event Description'), renderer: descriptionRenderer },
        {
            key: 'dated_state', sort_key: 'sorting_date', label: i18n.t('State'), renderer: maintenanceDateStateRenderer,
        },
    ];

    return (
        <div className={`event-list-root ${props.device}`}>
            <Table
                is_fetching={isLoading}
                data={_events || []}
                data_unique_id="id"
                columns={columns}
                setOrdering={(v) => {
                    setOrdering(v);
                }}
                ordering={_ordering}
                onRowClick={props.onRowClick}
                noMobileDisplay={props.noMobileDisplay}
                noTabletDisplay={props.noTabletDisplay}
                mobile_arrangement={{
                    primary_shorts: [
                        { key: 'dated_state' },
                    ],
                    primary_long:
                        { key: 'description' },
                }}
                deviceOverride={props.deviceOverride}
                loadingText={i18n.t('LOADING events')}
                theme={props.theme}
            />
        </div>
    );
};

const mapStateToProps = (state, props) => {
    if (props.loginless) return props.mapStateToProps(state, props);
    const { asset_id } = props;
    let counters = {};
    if (asset_id) {
        counters = countersSelectors.getCountersForAsset(state, props.asset_id);
    } else {
        counters = countersSelectors.getCounters(state);
    }
    return {
        counters,
    };
};

const EventsList = (props) => (
    <MultiDeviceLayout>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <EventsListComponent
            {...props}
        />
    </MultiDeviceLayout>
);

EventsList.defaultProps = {
    noTabletDisplay: true,
};

export default connect(mapStateToProps, null)(EventsList);
