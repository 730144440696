/**
 * Created by lpostula on 12/05/17.
 */
import {
    createSelector,
} from 'reselect';

export const getCompanies = (state) => state.companies;

export const getCompaniesNameByIds = createSelector(
    [getCompanies],
    (companies) => {
        const out = {};
        for (const company in companies) {
            if (companies.hasOwnProperty(company)) {
                out[company] = companies[company].name;
            }
        }
        return out;
    },
);

export const getCompaniesList = createSelector(
    [getCompanies],
    (companies) => _.values(companies),
);

export const getCompaniesListOptions = createSelector(
    [getCompaniesList],
    (companies) => _.map(companies, (c) => ({ value: c.id, label: c.name })),
);

export default {
    getCompanies,
    getCompaniesNameByIds,
    getCompaniesList,
    getCompaniesListOptions,
};
