/**
 * Created by lpostula on 11/05/17.
 */
import { createSelector } from 'reselect';
import i18n from 'i18next';

export const getAssetClasses = (state) => state.asset_classes;

export const getAssetClass = (state, class_id) => state.asset_classes[class_id];

export const getAssetClassesNameList = createSelector(
    [getAssetClasses],
    (asset_classes) => _.map(asset_classes, (c) => c.name),
);

const sort_options = function (a, b) {
    const sa = a.label.toLowerCase();
    const sb = b.label.toLowerCase();
    return -(sa < sb) || +(sa !== sb);
};

export const getAssetClassesOptions = createSelector(
    [getAssetClasses],
    (asset_classes) => {
        const c = [];
        for (const class_id in asset_classes) {
            if (!asset_classes.hasOwnProperty(class_id)) continue;
            const { name } = asset_classes[class_id];
            c.push({
                value: class_id,
                label: `${i18n.t('Class')}: ${name}`,
            });
        }
        c.sort(sort_options);
        return c;
    },
);

// we neg the id in order to display the asset class, side by side
// with the asset, the < 0 domain is reserved to asset class whereas
// the > 0 domain is reserved to the assets.
export const getAssetClassesOptionsNegId = createSelector(
    [getAssetClassesOptions],
    (asset_classes) => asset_classes.map(
        (c) => ({ value: c.value * -1, label: c.label }),
    ),
);

export default {
    getAssetClasses,
    getAssetClass,
    getAssetClassesNameList,
    getAssetClassesOptions,
    getAssetClassesOptionsNegId,
};
