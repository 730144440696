import { default as actions } from './actions';
import { fetchForAsset } from '../counters/operations';

export const { fetchCounterReadingForCounter } = actions;
// eslint-disable-next-line max-len
export const createReadings = (values) => (dispatch) => dispatch(actions.createReadings(values)).then((d) => {
    const error_type = d.payload.response ? 'error_type' in d.payload.response ? d.payload.response.error_type : null : null;
    if (d.error && error_type === 'suspicious_done_usage') {
        const counter_id = d.payload.response ? 'id' in d.payload.response ? parseInt(d.payload.response.id[0], 10) : null : null;
        const counter_type_name = d.payload.response ? 'counter_type_name' in d.payload.response ? d.payload.response.counter_type_name : null : null;
        if (!counter_id && !counter_type_name) {
            return Promise.resolve(d);
        }
        let old_key = null;
        const new_values = [];
        for (const key in values) {
            const value = values[key];
            if (window.confirm(`Value is unexpectedly high compared to daily usage for ${counter_type_name}, do you confirm ${value.value} as done usage ?`)) {
                old_key = key;
                new_values.push({ ...value, allow_suspicious_done_usage: true });
            } else {
                return Promise.resolve(d);
            }
        }
        if (!old_key) {
            return Promise.resolve(d);
        }
        return dispatch(createReadings(new_values));
    } if (d.error) {
        return Promise.resolve(d);
    }
    return Promise.resolve(d);
});

export const fetchCounterReadingForAsset = (asset_id) => (dispatch) => dispatch(
    fetchForAsset(asset_id),
).then((d) => {
    if (d.payload) {
        for (const counter of d.payload) {
            dispatch(fetchCounterReadingForCounter(counter.id));
        }
    }
});
export const incomingWS = (data) => (dispatch) => {
    switch (data.type) {
    case 'update':
        dispatch(actions.updateCounterReadingInStore(data.payload));
        break;
    default:
        break;
    }
};

export default {
    incomingWS,
    fetchCounterReadingForAsset,
    createReadings,
};
