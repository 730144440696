import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import components from 'components';
import {
    filteringCollectionsOperations, filteringCollectionsSelectors,
} from 'railfleet_state/ducks/filtering_collections';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { assetClassesSelectors } from 'railfleet_state/ducks/asset_classes';

const {
    SelectFilter,
    TimeRangeFilter,
    QUICK_RANGES,
    SearchFilter,
    MultiOptionFilter,
} = components.filters;

const {
    Filters,
    Block,
} = components.app;

const solved = [
    { key: 'solved', label: i18n.t('Solved') },
    { key: 'not_solved', label: i18n.t('Not Solved') },
    { key: 'not_solved_immediate_intervention', label: i18n.t('Immediate intervention required') },
];

const event = [
    { key: 'event_not_source', label: i18n.t('Attached (not source)') },
    { key: 'event_as_source', label: i18n.t('Source') },
    { key: 'no_event', label: i18n.t('Not attached') },
];

const HotlineFilter = (props) => {
    const [_selectedAssetClasses, setSelectedAssetClasses] = useState([]);

    const onChangeAsset = (arg) => {
        const asset_classes = [];
        const assets = [];
        for (const id of arg) {
            if (id < 0) {
                asset_classes.push(-1 * id);
            } else {
                assets.push(id);
            }
        }
        props.setAssets(assets);
        props.setAssetClasses(asset_classes);
    };

    const sort_func = (a, b) => {
        let sa = String(a.label)
            .toLowerCase();
        let sb = String(b.label)
            .toLowerCase();
        if (a.value < 0 || b.value < 0) {
            sa = a.value;
            sb = b.value;
        }
        return -(sa < sb) || +(sa !== sb);
    };

    const onTimeRangeChange = (from, to) => {
        if (from) props.setFromDateStart(from.toISOString());
        if (to) props.setToDateEnd(to.toISOString());
    };

    useEffect(() => {
        const selectedAssetClasses = [];
        for (const assetClass of props.selected_asset_classes || []) {
            selectedAssetClasses.push(-1 * assetClass);
        }
        setSelectedAssetClasses(selectedAssetClasses);
    }, [props.selected_asset_classes]);

    const getAssetLabelizer = (item) => {
        const { name } = item;
        const asset_class_id = item.asset_class;
        let asset_class;
        if (asset_class_id) {
            asset_class = props.all_asset_classes[asset_class_id]
                && props.all_asset_classes[asset_class_id].name;
        }
        if (!name && !asset_class) return item.label;
        if (asset_class) {
            return `${name} (${asset_class})`;
        }
        return name;
    };

    const applyFilter = (arg) => {
        props.applyFilter();
        if (props.callback) props.callback(arg);
    };

    return (
        <Filters
            call_to_action={i18n.t('Browse hotline calls')}
            applyFilter={applyFilter}
            is_fetching={props.is_fetching}
            is_pristine={props.is_pristine}
        >
            <Block>
                <SelectFilter
                    name="filter_asset"
                    values={props.assets.concat(props.asset_classes)}
                    onChange={onChangeAsset}
                    selected={_selectedAssetClasses.concat(props.selected_assets)}
                    id_getter={(item) => item.id || item.value}
                    labelizer={getAssetLabelizer}
                    inline_label={i18n.t('Asset')}
                    is_asset_SelectFilter
                    sort_func={sort_func}
                    onEnterKey={applyFilter}
                    virtual
                    clearable
                    multi
                />
                <SelectFilter
                    name="filter_hotliner"
                    values={props.users}
                    onChange={props.setHotliner}
                    selected={props.selected_hotliner}
                    id_getter={(item) => item.id || item.value}
                    labelizer={(a) => a.email}
                    inline_label={i18n.t('Hotliner')}
                    virtual
                    clearable
                />
            </Block>
            <Block>
                <TimeRangeFilter
                    name="filter_timerange"
                    from={props.selected_date_start_from}
                    to={props.selected_date_end_to}
                    timezone={props.timezone}
                    onChange={onTimeRangeChange}
                    label={i18n.t('Date')}
                    quickranges={[QUICK_RANGES.THIS_WEEK,
                        QUICK_RANGES.NEXT_WEEK,
                        QUICK_RANGES.NEXT_7_DAYS,
                        QUICK_RANGES.TOMORROW,
                        QUICK_RANGES.TODAY,
                        QUICK_RANGES.YESTERDAY,
                        QUICK_RANGES.NEXT_14_DAYS,
                    ]}
                />
            </Block>
            <Block>
                <SearchFilter
                    name="filter_search"
                    value={props.searchText}
                    onChange={props.setSearchText}
                    placeholder={i18n.t('Search content...')}
                    disabled={props.interfaceSelected === null}
                />
                <MultiOptionFilter
                    name="filter_solved"
                    options={solved}
                    onChange={props.setSolvedStatus}
                    values={props.selected_solved_status}
                    label={i18n.t('Solved')}
                />
                <MultiOptionFilter
                    name="filter_event"
                    options={event}
                    onChange={props.setAttachedEvent}
                    values={props.selected_attached_event}
                    label={i18n.t('Attached to event')}
                />
            </Block>
        </Filters>
    );
};

const mapStatetoProps = (state) => {
    const filter_fields = filteringCollectionsSelectors.getCollectionFields(state, 'hotline');
    return ({
        assets: assetsSelectors.getAssetsInfosArray(state),
        asset_classes: assetClassesSelectors.getAssetClassesOptionsNegId(state),
        all_asset_classes: assetClassesSelectors.getAssetClasses(state),
        selected_assets: filter_fields.assets,
        selected_hotliner: filter_fields.hotliner,
        selected_solved_status: filter_fields.solved_status,
        selected_immediate_intervention: filter_fields.immediate_intervention,
        selected_attached_event: filter_fields.attached_event,
        searchText: filter_fields.search,
        selected_asset_classes: filter_fields.asset_classes,
        selected_date_start_from: filter_fields.date_start_gte,
        selected_date_end_to: filter_fields.date_end_lte,
        is_fetching: filteringCollectionsSelectors.isCollectionFetching(state, 'hotline'),
        is_pristine: filteringCollectionsSelectors.isCollectionPristine(state, 'hotline'),
        users: state.users.all,
    });
};

const mapDispatchToProps = (dispatch) => ({
    setAssets: (p) => dispatch(filteringCollectionsOperations.setField('hotline', 'assets', p)),
    setHotliner: (p) => dispatch(filteringCollectionsOperations.setField('hotline', 'hotliner', p)),
    setSolvedStatus: (p) => dispatch(filteringCollectionsOperations.setField('hotline', 'solved_status', p)),
    setAttachedEvent: (p) => dispatch(filteringCollectionsOperations.setField('hotline', 'attached_event', p)),
    setSearchText: (p) => dispatch(filteringCollectionsOperations.setField('hotline', 'search', p)),
    setAssetClasses: (p) => dispatch(filteringCollectionsOperations.setField('hotline', 'asset_classes', p)),
    setFromDateStart: (p) => dispatch(filteringCollectionsOperations.setField('hotline', 'date_start_gte', p)),
    setToDateEnd: (p) => dispatch(filteringCollectionsOperations.setField('hotline', 'date_end_lte', p)),
    applyFilter: () => dispatch(filteringCollectionsOperations.applyFilter('hotline')),
});

export default connect(mapStatetoProps, mapDispatchToProps)(HotlineFilter);
