import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';

const ReactSelectWrapper = (props) => {
    const { field } = useController({
        name: props.id,
        rules: { required: !!props.required },
    });

    useEffect(() => {
        if (props.defaultValue && !_.isEqual(props.defaultValue, field.value)) {
            field.onChange(props.defaultValue, { shouldValidate: true });
            if (props.onChange) props.onChange(props.defaultValue);
        }
    }, []);

    const handleClear = () => {
        field.onChange(null);
        if (props.onChange) props.onChange(null);
        if (props.onClear) props.onClear();
    };

    const handleChange = (value) => {
        const isDate = props.name.includes('date');
        const validated_data = props.validate(value);
        if (validated_data) {
            field.onChange(validated_data);
            if (props.onChange) props.onChange(validated_data);
        } else if (!isDate) {
            handleClear();
        }
    };

    const element = React.createElement(props.children, {
        ...props,
        onClear: handleClear,
        onChange: handleChange,
        selected: field.value,
    });

    return element;
};

ReactSelectWrapper.defaultProps = {
    validate: (data) => data,
};

export default ReactSelectWrapper;
