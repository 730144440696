import React, { useState } from 'react';
import i18n from 'i18next';
import './FormTimeline.scss';
import MultiDeviceLayout from '../../../app/MultiDeviceLayout';
import TextDisplay from '../../../display/textDisplay/TextDisplay';
import FormDateTime from '../formDateTime/FormDateTime';
import { THEMES } from '../../../filters/selectFilter/SelectFilter';
import { strToMoment } from '../../../filters/utils';

const intervention_steps = [
    {
        state: 'planned',
        field: 'date_start_actual',
        user_field: 'start_actual_by',
        label: i18n.t('Date Started'),
        nextStepLabel: i18n.t('Start Intervention'),
    },
    {
        state: 'started',
        field: 'date_done_actual',
        user_field: 'done_actual_by',
        label: i18n.t('Date Done'),
        nextStepLabel: i18n.t('Close Intervention'),
    },
];

const intervention_handover_steps = [
    {
        state: 'planned',
        field: 'date_transfer_in',
        user_field: 'transfer_in_by',
        label: i18n.t('Date Transfer in'),
        nextStepLabel: i18n.t('Start Transfer In'),
    },
    {
        state: 'transfer_in',
        field: 'date_wait_in',
        user_field: 'wait_in_by',
        label: i18n.t('Date Wait in'),
        nextStepLabel: i18n.t('Start Wait in'),
    },
    {
        state: 'wait_in',
        field: 'date_start_actual',
        user_field: 'start_actual_by',
        label: i18n.t('Date Started'),
        nextStepLabel: i18n.t('Start Intervention'),
    },
    {
        state: 'started',
        field: 'date_done_actual',
        user_field: 'done_actual_by',
        label: i18n.t('Date Out Workshop'),
        nextStepLabel: i18n.t('Finish Intervention'),
    },
    {
        state: 'wait_out',
        field: 'date_transfer_out',
        user_field: 'transfer_out_by',
        label: i18n.t('Date Transfer out'),
        nextStepLabel: i18n.t('Start Transfer Out'),
    },
    {
        state: 'transfer_out',
        field: 'date_transfer_out_finished',
        user_field: 'transfer_out_finished_by',
        label: i18n.t('Date Transfer finished'),
        nextStepLabel: i18n.t('Finish Transfer and Close Intervention'),
    },
];

const FormTimelineComponent = (props) => {
    const [_dates, setDates] = useState({});
    const formatDate = (value) => {
        if (value && typeof value === 'string') {
            // check if string in a partial format
            return strToMoment(value, 'DD/MM/YYYY HH:mm');
        }
        return value;
    };

    const getStep = (id) => {
        if (props.steps) {
            for (const step of props.steps) {
                if (step.state === id) return step;
            }
        }
        return null;
    };

    const onChange = (key, arg) => {
        if (arg) {
            const oldDate = props.values[key].value;
            const newDate = arg.format('DD/MM/YYYY HH:mm');
            const step = getStep(key);
            const { field } = step;
            setDates((prev) => {
                const next = { ...prev };
                next[field] = arg;
                return next;
            });
            if (oldDate !== newDate && props.onChange) {
                props.onChange(field, arg);
            }
        }
    };

    const getInterventionStepIndex = (in_steps, step_state) => {
        for (const index in in_steps) {
            const in_step = in_steps[index];
            if (in_step.state === step_state) return index;
        }
        return null;
    };

    const getInterventionStepByIndex = (in_steps, lf_index) => {
        for (const index in in_steps) {
            const in_step = in_steps[index];
            if (parseInt(index, 10) === lf_index) return in_step;
        }
        return null;
    };

    const getMinDate = (step_state) => {
        let step;
        if (Object.keys(_dates).length === 2) {
            const index = getInterventionStepIndex(intervention_steps, step_state);
            step = getInterventionStepByIndex(intervention_steps, index - 1);
        } else {
            const index = getInterventionStepIndex(intervention_handover_steps, step_state);
            step = getInterventionStepByIndex(intervention_handover_steps, index - 1);
        }
        const minDate = (step && _dates[step.field]) || null;
        return minDate;
    };

    const renderFields = () => {
        if (!props.values) return null;

        return Object.keys(props.values).map((key) => {
            const elm = props.values[key];
            const step = getStep(key);
            if (props.cancelled) {
                return (
                    <div className="cancelled-state">
                        <span>{i18n.t('cancelled')}</span>
                        <TextDisplay
                            key={key}
                            data={elm.value}
                        />
                        <span className="date-label">{elm.label}</span>
                    </div>
                );
            }
            if (!props.editMode) {
                return (
                    <TextDisplay
                        key={key}
                        data={elm.value}
                        label={step.label}
                    >
                        <span className="date-label">{elm.label}</span>
                    </TextDisplay>
                );
            }
            const minDate = getMinDate(step.state);
            return (
                <FormDateTime
                    key={key}
                    id={step.field}
                    label={step.label}
                    defaultValue={
                        formatDate(elm.value)
                    }
                    theme={THEMES.FORM_LIGHT}
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    onChange={(arg) => onChange(key, arg)}
                    minDate={minDate}
                >
                    <span className="date-label">{elm.label}</span>
                </FormDateTime>
            );
        });
    };

    const fields = renderFields();
    if (!fields || fields.length === 0) return null;

    return (
        <div
            className={`form form_timeline ${props.className ? props.className : ''} ${props.id} ${props.device}`}
            key={props.id}
        >
            {fields}
        </div>
    );
};

const FormTimeline = (props) => (
    <MultiDeviceLayout>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <FormTimelineComponent
            {...props}
        />
    </MultiDeviceLayout>
);

export default FormTimeline;
