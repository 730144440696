import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import moment from 'moment-timezone';
import components from 'components';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { usersSelectors } from 'railfleet_state/ducks/users';
import { restrictionsOperations, restrictionsSelectors } from 'railfleet_state/ducks/restrictions';
import { uxOperations } from 'railfleet_state/ducks/ux';
import './RestrictionList.scss';
import HomologationList from 'compounds/lists/homologationList/HomologationList';
import useFetch from 'components/hooks/useFetch';
import useArrayToDict from 'components/hooks/useArrayToDict';
import Dialog from '../../../components/containers/dialog/Dialog';
import AutoTranslatedDisplay from '../../../components/display/autoTranslatedDisplay/AutoTranslatedDisplay';
import withNavigate from '../../../components/app/withNavigate';
import Table from '../../../components/tables/table/Table';

const { MultiDeviceLayout } = components.app;
const { EditButton, CancelButton } = components.buttons;
const { AsyncConfirmationDialog } = components.containers;
const { usePerm } = components.hooks;

const RestrictionListComponent = (props) => {
    const [_countryList, setCountryList] = useState(null);
    const [_otherList, setOtherList] = useState(null);
    const countries = useArrayToDict(_countryList, 'id');
    const others = useArrayToDict(_otherList, 'id');
    const [_data, setData] = useState([]);
    const hasPerm = usePerm(props.asset_id);
    const [_rowSelected, setRowSelected] = useState(null);
    const { fetchAll } = useFetch();

    useEffect(() => {
        fetchAll('/api/homologation/country/').then((d) => {
            if (d) {
                setCountryList(d);
            } else {
                setCountryList(null);
            }
        });
    }, []);

    useEffect(() => {
        fetchAll('/api/homologation/other/').then((d) => {
            if (d) {
                setOtherList(d);
            } else {
                setOtherList(null);
            }
        });
    }, []);

    useEffect(() => {
        if (props.asset_id) {
            props.fetchRestrictionsForAsset(props.asset_id);
        }
    }, []);

    useEffect(() => {
        const restrictions = [];
        if (props.restrictions) {
            for (const restriction of props.restrictions) {
                if (restriction.state === props.restriction_state) restrictions.push(restriction);
            }
        }
        restrictions.sort((a, b) => moment(b.created_at) - moment(a.created_at));
        setData(restrictions);
    }, [props.restrictions]);

    const DateRenderer = (cellData) => (
        <span className="renderer-date">
            {moment(cellData).format('DD/MM/YYYY HH:mm')}
        </span>
    );

    const UserRenderer = (cellData) => (
        <span className="user-formater">
            {`${cellData && props.users[cellData] ? props.users[cellData].full_name : '-'}`}
        </span>
    );

    const RestrictionTypeRenderer = (cellData) => (
        <span className="restriction-type-formater">
            {`${cellData && props.restriction_type[cellData] ? props.restriction_type[cellData].name : '-'}`}
        </span>
    );

    const Bubble = (rowData) => (
        <div
            className="auto-translate-bubble"
            onClick={() => setRowSelected(rowData)}
        >
            <span>A</span>
            <div className="auto-translate-bubble-arrow" />
        </div>
    );

    const CommentRenderer = (cellData, rowData) => (
        <div className="comment-renderer">
            <span>{cellData || '-'}</span>
            <Bubble
                {...rowData}
            />
        </div>
    );

    const homologationRenderer = (cellData, rowData) => {
        if (!_.isEmpty(rowData.impacts) || !_.isEmpty(rowData.impacts_other)) {
            const impacts = rowData.impacts?.map((elm) => countries[elm]?.country);
            const impacts_other = rowData.impacts_other?.map((elm) => others[elm]);
            return (
                <HomologationList
                    impact
                    impacts={impacts}
                    impacts_other={impacts_other}
                />
            );
        }
        return (
            <div>-</div>
        );
    };

    const EventRenderer = (cellData, rowData) => {
        if (cellData) {
            return (
                <a
                    className="event-number-renderer"
                    onClick={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        const from = `/operational/#${props.router.location.pathname}`;
                        const push_infos = {
                            from,
                        };
                        props.setPushInfos(push_infos);
                        if (props.eventIdRedirect) {
                            const url = props.eventIdRedirect(cellData);
                            window.location.href = url;
                        } else {
                            props.router.push(`/asset/${rowData.asset}/events/${cellData}/view`);
                        }
                    }}
                >
                    {`M${cellData}`}
                </a>
            );
        }
        return null;
    };

    const onClickClose = (restriction) => {
        let type;
        if (props.restriction_type) {
            type = props.restriction_type[restriction.restriction_type];
        }
        let confirm_text = i18n.t('Are you sure you want to close this restriction?');
        if (restriction.comment) {
            if (type) {
                confirm_text = `${confirm_text}\n${type.name} -- ${restriction.comment}`;
            } else {
                confirm_text = `${confirm_text}\n${restriction.comment}`;
            }
        }

        return AsyncConfirmationDialog({
            variant: 'danger',
            description_message: confirm_text,
            buttons_text: ['No', 'Yes'],
            actionOnYes: () => {
                const res = { ...restriction, asset_id: props.asset_id };
                console.log('res:', res);
                return props.closeRestriction(res);
            },
            in_progress_message: i18n.t('Closing restriction ...'),
            done_message: i18n.t('Restriction successfuly closed'),
            timed: true,
        });
    };

    const onClickEdit = (restriction) => {
        props.router.push(
            `/asset/${props.asset_id}/events/restrictions/${restriction.id}/edit`,
        );
    };

    const actionRenderer = (cellData, rowData) => (
        <div className="action-renderer">
            {
                props.restriction_state === 'open'
                && (
                    <CancelButton
                        text={i18n.t('Close')}
                        onClick={props.onClickClose ? () => props.onClickClose(rowData) : () => onClickClose(rowData)}
                        disabled={!hasPerm('close_restriction')}
                    />
                )
            }
            <EditButton
                text={null}
                onClick={props.onClickEdit ? () => props.onClickEdit(rowData) : () => onClickEdit(rowData)}
                disabled={!hasPerm('edit_restriction')}
            />
        </div>
    );

    const possibles = {
        date_started: {
            label: i18n.t('Date started'), key: 'date_started', renderer: DateRenderer, sortable: false,
        },
        date_ended: {
            label: i18n.t('Date ended'), key: 'date_ended', renderer: DateRenderer, sortable: false,
        },
        user_started: {
            label: i18n.t('User started'), key: 'user_started', renderer: UserRenderer, sortable: false,
        },
        user_ended: {
            label: i18n.t('User ended'), key: 'user_ended', renderer: UserRenderer, sortable: false,
        },
        restriction_type: {
            label: i18n.t('Restriction'), key: 'restriction_type', renderer: RestrictionTypeRenderer, sortable: false,
        },
        comment: {
            label: i18n.t('Comment'), key: 'comment', renderer: CommentRenderer, sortable: false,
        },
        event: {
            label: i18n.t('Event'), key: 'event', renderer: EventRenderer, sortable: false,
        },
        actions: { renderer: actionRenderer, sortable: false },
        homologation: {
            label: i18n.t('Impact'), key: 'impacts', renderer: homologationRenderer, sortable: false,
        },
    };

    let columns = [
        possibles.date_started,
        possibles.user_started,
        possibles.restriction_type,
        possibles.comment,
        possibles.event,
    ];

    if (props.homologation) {
        columns.push(possibles.homologation);
    }

    const mobile_arrangement = {
        primary_shorts: [
            { key: 'restriction_type' },
            { key: 'event' },
        ],
        secondary_shorts: [],
        secondary_longs: [
            { key: 'user_started' },
            { key: 'date_started' },
        ],
        primary_long: { key: 'comment' },
        actions: [],
    };
    if (props.restriction_state === 'open') {
        columns.push(possibles.actions);
        mobile_arrangement.actions.push(possibles.actions);
    } else {
        columns.push(possibles.user_ended);
        columns.push(possibles.date_ended);
        mobile_arrangement.secondary_longs.push({ key: 'user_ended' });
        mobile_arrangement.secondary_longs.push({ key: 'date_ended' });
    }

    if (props.fields) {
        columns = props.fields.map((field) => possibles[field]);
    }
    return (
        <div className="restriction-list-holder">
            <div className={`restriction-list ${props.restriction_state}`}>
                {
                    (_.isEmpty(props.restrictions) || !_.some(props.restrictions, (res) => res.state !== 'closed')) && props.noPlaceholder
                        ? (
                            <span className="no-open-restrictions">{i18n.t('No open restrictions')}</span>
                        ) : (
                            <Table
                                data={_data}
                                data_unique_id="id"
                                columns={columns}
                                mobile_arrangement={props.mobile_arrangement || mobile_arrangement}
                                theme={props.theme}
                                noMobileDisplay={props.noMobileDisplay}
                                noTabletDisplay={props.noTabletDisplay}
                                smallPlaceholder={props.smallPlaceholder}
                            />
                        )
                }
            </div>
            {
                _rowSelected
                && (
                    <Dialog
                        onClickClose={() => setRowSelected(null)}
                        className="restriction-list-dialog"
                    >
                        <div>
                            <AutoTranslatedDisplay
                                data={_rowSelected.comment}
                                noVerticalLine
                                overrideDevice="tablet"
                            />
                        </div>
                    </Dialog>
                )
            }
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const { asset_id } = props;
    const asset = asset_id && assetsSelectors.getAssetInfos(state, asset_id);
    const can_close = asset && usersSelectors.userHasPerm(state)('close_restriction', asset_id);
    const can_add = asset && usersSelectors.userHasPerm(state)('create_restriction', asset_id);
    const can_update = asset && usersSelectors.userHasPerm(state)('edit_restriction', asset_id);
    const can_view = asset && usersSelectors.userHasPerm(state)('view_restrictions', asset_id);
    const restrictions_selector = restrictionsSelectors.makeRestrictionsListForAsset();
    const restrictions = restrictions_selector(state, asset_id);
    const users = usersSelectors.getUsers(state);
    const getMaintenanceSettings = assetsSelectors.makeMaintenanceSettingsForAsset();
    const maintenance_settings = getMaintenanceSettings(state, asset_id);
    const restriction_type = maintenance_settings && maintenance_settings.restriction_type;
    return {
        asset,
        can_close,
        can_add,
        can_update,
        can_view,
        restrictions,
        users,
        restriction_type,
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchRestrictionsForAsset: (asset_id) => (
        dispatch(restrictionsOperations.fetchForAsset(asset_id))
    ),
    closeRestriction: (restriction) => (
        dispatch(restrictionsOperations.closeRestriction(restriction))
    ),
    setPushInfos: (push_infos, from = null) => dispatch(uxOperations.setPushInfos(push_infos, from)),
});

const RestrictionList = (props) => (
    <MultiDeviceLayout>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <RestrictionListComponent
            {...props}
        />
    </MultiDeviceLayout>
);

RestrictionList.defaultProps = {
    restriction_state: 'open',
    noTabletDisplay: true,
};

export default withNavigate(connect(mapStateToProps, mapDispatchToProps)(RestrictionList));
