import i18n from 'i18next';

// These should move to a COUNTER app whenever it appears

const COUNTER_UNIT_SHORT_TRANSLATION = {
    km: i18n.t('km'),
    hr: i18n.t('hr'),
    s: i18n.t('s'),
    mwh: i18n.t('mwh'),
    kwh: i18n.t('kwh'),
    days: i18n.t('days'),
    mm: i18n.t('mm'),
    l: i18n.t('l'),
};

const COUNTER_UNIT_TRANSLATION = {
    km: i18n.t('km'),
    long_hr: i18n.t('long_hr'),
    long_s: i18n.t('long_s'),
    mwh: i18n.t('mwh'),
    kwh: i18n.t('kwh'),
    long_days: i18n.t('long_days'),
    mm: i18n.t('mm'),
    l: i18n.t('l'),
};

export {
    COUNTER_UNIT_SHORT_TRANSLATION,
    COUNTER_UNIT_TRANSLATION,
};
