import React, { useState, useContext, useEffect } from 'react';
import './HomologationForm.scss';
import i18n from 'i18next';
import scrollToTop from 'railfleet/utils/scrollToTop';
import FormManager from 'components/forms/RailForms/FormManager';
import InputsWrapper from 'components/forms/RailForms/InputsWrapper';
import CutForm from 'components/forms/RailForms/cutForm/CutForm';
import useUnicodeFlag from 'components/hooks/useUnicodeFlag';
import FormMultiValueCheckbox from 'components/forms/RailForms/formMultiValueCheckbox/FormMultiValueCheckbox';
import { ContextDevice, withMultiDeviceLayout } from 'components/app/MultiDeviceLayout';
import usePatch from 'components/hooks/usePatch';
import { connect } from 'react-redux';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import HomologationContext from 'compounds/pages/assetView/HomologationContext/homologationContext';
import useDictToArray from 'components/hooks/useDictToArray';

const HomologationForm = (props) => {
    const { device } = useContext(ContextDevice);
    const context = useContext(HomologationContext);
    const can_allievate = context?.methods?.can_allievate(props.asset_id);
    const can_aggravate = context?.methods?.can_aggravate(props.asset_id);

    const countries = context?.data?.countries;
    const others = context?.data?.others;
    const homologations = context?.data?.homologations;
    const homologation = homologations && props.asset_id && homologations[props.asset_id];
    const orderedCountries = useDictToArray(homologation?.country_links, 'id', true, 'order');
    const orderedOthers = useDictToArray(homologation?.other_links, 'id', true, 'order');
    const [_locked, setLocked] = useState(false);
    const [_errorApi, setErrorApi] = useState(null);
    const [_formatedCountryValues, setFormatedCountryValues] = useState([]);
    const [_formatedOtherValues, setFormatedOtherValues] = useState([]);
    const [_countriesInput, setCountriesInput] = useState(null);
    const [_othersInput, setOthersInput] = useState(null);
    const { patchAll } = usePatch();
    const refreshData = context?.methods?.refreshData;
    const getFlag = useUnicodeFlag();

    const isReadOnly = (aptitude) => {
        if (aptitude) return !can_aggravate;
        return !can_allievate;
    };

    useEffect(() => {
        if (!orderedCountries || !countries) return;
        const formatedCountryValues = [];
        for (const homologation_country of orderedCountries) {
            const country = countries[homologation_country?.target]?.country;
            const readOnlyMethod = props.isReadOnly || isReadOnly;
            formatedCountryValues.push({
                id: props.id_getter(homologation_country),
                name: country?.code,
                values: ['true', 'false'],
                default: props.impact ? 'true' : (homologation_country?.aptitude ? 'true' : 'false'),
                readOnly: readOnlyMethod(homologation_country?.aptitude),
            });
        }
        setFormatedCountryValues(formatedCountryValues);
    }, [orderedCountries, countries, props.impact]);

    useEffect(() => {
        if (!orderedOthers || !others) return;
        const formatedOtherValues = [];
        for (const homologation_other of orderedOthers) {
            const other = others[homologation_other?.target];
            const readOnlyMethod = props.isReadOnly || isReadOnly;
            formatedOtherValues.push({
                id: props.id_getter(homologation_other),
                name: other?.name,
                values: ['true', 'false'],
                default: props.impact ? 'true' : (homologation_other.aptitude ? 'true' : 'false'),
                readOnly: readOnlyMethod(homologation_other?.aptitude),
            });
        }
        setFormatedOtherValues(formatedOtherValues);
    }, [orderedOthers, others, props.impact]);

    const format_data_onChange = (data) => {
        const formated_data = { ...data };
        const formated_countries = {};
        if (formated_data.countries && homologation?.country_links) {
            delete formated_data.countries;
            for (const homologation_country of homologation.country_links) {
                const new_value = data?.countries[props.id_getter(homologation_country)] === 'true';
                if (new_value === false) {
                    formated_countries[props.id_getter(homologation_country)] = new_value;
                }
            }
            formated_data.countries = formated_countries;
        }

        const formated_others = {};
        if (formated_data.others && homologation?.other_links) {
            delete formated_data.others;
            for (const homologation_other of homologation.other_links) {
                const new_value = data.others[props.id_getter(homologation_other)] === 'true';
                if (new_value === false) {
                    formated_others[props.id_getter(homologation_other)] = new_value;
                }
            }
            formated_data.others = formated_others;
        }
        if (_.isEmpty(formated_data.others)) {
            delete formated_data.others;
        }
        if (_.isEmpty(formated_data.countries)) {
            delete formated_data.countries;
        }
        return formated_data;
    };

    const format_data_submit = (data) => {
        const formated_data = { ...data };

        const formated_countries = [];
        if (formated_data.countries) {
            delete formated_data.countries;
            for (const homologation_country of homologation.country_links) {
                const value = homologation_country.aptitude;
                const new_value = data.countries[homologation_country?.id] === 'true';
                if (value !== new_value) {
                    formated_countries.push({
                        id: props.id_getter(homologation_country),
                        aptitude: new_value,
                        asset: props.asset_id,
                    });
                }
            }
            formated_data.country_links = formated_countries;
        }

        const formated_others = [];
        if (formated_data.others) {
            delete formated_data.others;
            for (const homologation_other of homologation.other_links) {
                const value = homologation_other.aptitude;
                const new_value = data.others[homologation_other?.id] === 'true';
                if (value !== new_value) {
                    formated_others.push({
                        id: props.id_getter(homologation_other),
                        aptitude: new_value,
                        asset: props.asset_id,
                    });
                }
            }
            formated_data.other_links = formated_others;
        }
        if (_.isEmpty(formated_data.other)) {
            delete formated_data.others;
            delete formated_data.other;
        }
        if (_.isEmpty(formated_data.country)) {
            delete formated_data.countries;
            delete formated_data.country;
        }
        return formated_data;
    };
    useEffect(() => {
        if (!props.onChange) return;
        const formated_data = format_data_onChange({
            countries: _countriesInput,
            others: _othersInput,
        });
        props.onChange(formated_data);
    }, [_countriesInput, _othersInput, props.onChange]);

    const onSubmit = (data) => {
        const formated_data = format_data_submit(data);
        if (!formated_data.country_links && !formated_data.other_links) return;
        setLocked(true);
        patchAll(`/api/homologation/asset/${props.asset_id}/`, formated_data).then((d) => {
            if (d && !d.error) {
                setLocked(false);
                setErrorApi(null);
                if (refreshData) refreshData();
                if (props.callback) props.callback(d);
            }
            if (d?.error) {
                setLocked(false);
                setErrorApi(d.payload.response || `${d.payload.name}: ${d.payload.message}`);
                scrollToTop();
            }
        });
    };

    if (_.isEmpty(_formatedCountryValues) && _.isEmpty(_formatedOtherValues)) {
        return (
            <div>{i18n.t('There is no homologation for this asset')}</div>
        );
    }
    return (
        <div className={`form-main ${device} homologation-form ${props.noBorder ? 'noBorder' : ''} ${props.withFlags ? 'withFlags' : ''}`}>
            <FormManager
                onSubmit={onSubmit}
                textSubmitButton={props.onChange ? null : i18n.t('Update aptitudes')}
                onCancel={props.onCancel}
                apiErrors={_errorApi}
                disabled={_locked || (_.isEmpty(_countriesInput) && _.isEmpty(_othersInput))}
                primary
            >
                <InputsWrapper
                    id="country"
                    title={props.titleCountry || i18n.t('Country')}
                    noTitle={props.noTitle}
                    standalone={props.standalone}
                    hidden={_.isEmpty(_formatedCountryValues)}
                >
                    {
                        !props.titleCountry && (
                            <div>{i18n.t('Activate/deactivate the aptitudes for which the locomotive can/can\'t run anymore')}</div>
                        )
                    }
                    <CutForm
                        device={device}
                    >
                        <FormMultiValueCheckbox
                            id="countries"
                            className={props.withFlags ? 'withFlags' : ''}
                            values={_formatedCountryValues}
                            labelizer={(item) => item.name}
                            multi
                            onChange={setCountriesInput}
                            isDisabled={props.isDisabled}
                            defaultValue={props.defaultValue?.countries}
                            render={(item) => (
                                /* eslint-disable-next-line react/jsx-one-expression-per-line */
                                <span>{item.name} {props.withFlags && countries ? getFlag(countries[item.id]?.unicode_flag, true) : ''}</span>
                            )}
                        />
                    </CutForm>
                </InputsWrapper>
                <InputsWrapper
                    id="others"
                    title={props.titleOther || i18n.t('Others')}
                    standalone={props.standalone}
                    hidden={_.isEmpty(_formatedOtherValues)}
                >
                    {
                        !props.titleOther && (
                            <div>{i18n.t('Activate/deactivate the aptitudes for which the locomotive can/can\'t run anymore')}</div>
                        )
                    }
                    <CutForm
                        device={device}
                    >
                        <FormMultiValueCheckbox
                            id="others"
                            className="columns"
                            values={_formatedOtherValues}
                            labelizer={(item) => item.name}
                            multi
                            onChange={setOthersInput}
                            defaultValue={props.defaultValue?.others}
                            isDisabled={props.isDisabled}
                            rowVersion
                        />
                    </CutForm>
                </InputsWrapper>
            </FormManager>
        </div>
    );
};

const mapStateToProps = (state, props) => ({
    asset: props.asset_id && assetsSelectors.getAssetInfos(state, props.asset_id),
    user: state.users.me,
});

export default withMultiDeviceLayout(connect(mapStateToProps, null)(HomologationForm));
