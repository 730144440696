import React, { useState } from 'react';

import './InlineDropdown.scss';
import WithPopper from '../../app/WithPopper';

const DISPLAY_TYPE = {
    TABLET_AND_MOBILE: 'tablet-mobile',
    DESKTOP: 'desktop',
};

const InlineDropdown = (props) => {
    const [_open, setOpen] = useState(false);
    return (
        <WithPopper
            placement="bottom"
            dontOpenOnHover
            visible={_open}
            openCallback={(v) => setOpen(v)}
        >
            <div className={`inline-dropdown-value-container ${props.display}`}>
                <div className="inline-dropdown-value">{props.labelizer(props.value)}</div>
                <div className="inline-dropdown-svg">
                    <svg
                        width="5"
                        height="4"
                        viewBox="0 0 5 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.5 4L0 0.428571L5 0.428571L2.5 4Z"
                            fill="#626C7C"
                        />
                    </svg>
                </div>
            </div>
            <div className="inline-dropdown-selection">
                {
                    props.options.map(
                        (v) => (
                            <div
                                className="inline-dropdown-selection-value"
                                onClick={() => {
                                    setOpen(false);
                                    props.onChange(v);
                                }}
                                key={v}
                            >
                                {props.labelizer(v)}
                            </div>
                        ),
                    )
                }
            </div>
        </WithPopper>
    );
};

InlineDropdown.defaultProps = {
    labelizer(item) {
        return item.label;
    },
    display: DISPLAY_TYPE.DESKTOP,
};

InlineDropdown.propTypes = {};

export default InlineDropdown;
export { DISPLAY_TYPE };
