import React from 'react';
import './NoDataDisplay.scss';

const NoDataDisplay = (props) => (
    <div className={`element-holder no-data ${props.className ? props.className : ''}`}>
        <div className="element-label disabled">{props.label}</div>
        <div className="element">
            <div>---</div>
            {
                props.action && (
                    <div
                        className="action-label"
                        onClick={props.action.onClick}
                    >
                        {props.action.label}
                    </div>
                )
            }
        </div>
    </div>
);

export default NoDataDisplay;
