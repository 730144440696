/**
 * Created by lpostula on 03/07/17.
 */

import { createSelector } from 'reselect';

const getCounters = (state) => state.counters;
const getCounter = (state, counter_id) => state.counters[counter_id];

const getCountersForAsset = (state, asset_id) => _.pick(
    state.counters,
    ((a) => a.asset === asset_id),
);

const getCountersListForAsset = createSelector(
    [getCountersForAsset],
    (counters) => {
        const out = [];
        for (const k in counters) {
            out.push(counters[k]);
        }
        // sort by id so that order is constant
        out.sort((a, b) => a.id - b.id);
        return out;
    },
);

export default {
    getCounter,
    getCounters,
    getCountersForAsset,
    getCountersListForAsset,
};
