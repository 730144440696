/**
 * Created by lpostula on 11/05/17.
 */
import { default as actions } from './actions';

export const { fetchList } = actions;
export const { fetchInfosList } = actions;
export const { fetchTelematicsList } = actions;
export const { fetchLiveDataList } = actions;
export const { fetchAlertsList } = actions;
export const { fetchLiveDataDetail } = actions;
export const { liveDataPartialUpdate } = actions;

export const { fetchOperationalsList } = actions;
export const { fetchLabelsList } = actions;
export const { fetchInfosDetail } = actions;
export const { fetchTelematicsDetail } = actions;
export const { fetchOperationalsDetail } = actions;
export const { fetchLabelsDetail } = actions;
export const { setLabels } = actions;
export const { fetchAssignTo } = actions;

export const incomingWS = (data) => (dispatch) => {
    switch (data.resource) {
    case 'assets_infos': {
        switch (data.type) {
        case 'update':
            dispatch(actions.setAssetInfos(data.payload));
            break;
        default:
            break;
        }
        break;
    }
    case 'assets_labels': {
        switch (data.type) {
        case 'update':
            dispatch(actions.setAssetLabels(data.payload));
            break;
        default:
            break;
        }
        break;
    }
    case 'assets_operationals': {
        switch (data.type) {
        case 'partial_update':
            dispatch(actions.operationalsPartialUpdate(data.payload));
            break;
        default:
            break;
        }
        break;
    }
    default:
        break;
    }
};

export default {
    fetchInfosList,
    fetchTelematicsList,
    fetchLiveDataList,
    fetchLiveDataDetail,
    fetchAlertsList,
    liveDataPartialUpdate,
    fetchOperationalsList,
    fetchLabelsList,
    fetchInfosDetail,
    fetchTelematicsDetail,
    fetchOperationalsDetail,
    fetchLabelsDetail,
    setLabels,
    fetchAssignTo,
    incomingWS,
};
