import moment from 'moment-timezone';
import { API_ROOT } from 'railfleet_state/config';
import intervention_types from '../interventions/types';
import fleet_availability_types from '../fleet_availability/types';
import events_types from '../maintenance_events/types';
import campaign_types from '../campaigns/types';
import log_types from '../logs/types';
import lineas_sap_types from '../lineas_gmao/types';
import asset_preparation_types from '../asset_preparations/types';
import telematic_types from '../telematics/types';
import monitoring_alerts_types from '../monitoring_alerts/types';
import hotline_types from '../hotlines/types';
import { getInterventions } from '../interventions/selectors';
import { getCampaigns } from '../campaigns/selectors';
import { getTelematics } from '../telematics/selectors';
import { getMonitoringAlerts } from '../monitoring_alerts/selectors';
import { getHotlines } from '../hotlines/selectors';
import logs_selectors from '../logs/selectors';
import m_e_selectors from '../maintenance_events/selectors';
import { getApiMessageLogsList } from '../lineas_gmao/selectors';
import { getAssetPreparationList } from '../asset_preparations/selectors';
import { getOperationsAndGroupsOptionsByAssets } from '../operations/selectors';
import { getFleetAvailabilityData } from '../fleet_availability/selectors';

export default {
    monitoring_alert: {
        url: `${API_ROOT}monitoring_alert.json`,
        selector: getMonitoringAlerts,
        redux_types: monitoring_alerts_types,
        non_asset: true,
        fields: {
            assets: {
                type: 'enum',
                multi: true,
                default: [],
            },
            rules: {
                type: 'enum',
                multi: true,
                default: [],
            },
            priorities: {
                type: 'enum',
                multi: true,
                default: [],
            },
            timestamp_gte: {
                type: 'date',
                default: moment().subtract(2, 'days'),
                valueFormatter: (v) => v && v.toISOString(),
            },
            timestamp_lte: {
                type: 'date',
                default: moment().add(2, 'hours'),
                valueFormatter: (v) => v && v.toISOString(),
            },
            advanced_search: {
                type: 'enum',
                multi: true,
                default: [],
            },
            ordering: {
                type: 'enum',
                multi: false,
                default: '-timestamp',
            },
            page_size: {
                type: 'enum',
                multi: false,
                default: '500',
            },
        },
    },
    hotline: {
        url: `${API_ROOT}hotline_call.json`,
        redux_types: hotline_types,
        selector: getHotlines,
        fields: {
            assets: {
                type: 'enum',
                multi: true,
                default: null,
            },
            asset_classes: {
                type: 'enum',
                multi: true,
                default: null,
            },
            hotliner: {
                type: 'enum',
                default: null,
            },
            attached_event: {
                type: 'enum',
                multi: true,
                strict: true,
                default: ['no_event', 'event_not_source', 'event_as_source'],
            },
            solved_status: {
                type: 'enum',
                multi: true,
                strict: true,
                default: ['not_solved', 'not_solved_immediate_intervention', 'solved'],
            },
            search: {
                type: 'string',
                default: '',
            },
            date_start_gte: {
                type: 'date',
                default: null,
            },
            date_start_lte: {
                type: 'date',
                default: null,
            },
            date_end_gte: {
                type: 'date',
                default: null,
            },
            date_end_lte: {
                type: 'date',
                default: null,
            },
            page_size: {
                type: 'enum',
                multi: false,
                default: '500',
            },
            ordering: {
                type: 'string',
                default: '-date_start',
            },
        },
    },
    driver_logbook: {
        redux_types: [
            'driver_logbook_types.FETCH_DRIVER_LOGBOOK_REQUEST',
            'driver_logbook_types.FETCH_DRIVER_LOGBOOK_COMPLETED',
            'driver_logbook_types.FETCH_DRIVER_LOGBOOK_FAILED',
        ],
        fields: {
            asset: {
                type: 'enum',
                multi: false,
                default: null,
            },
        },
    },
    telematics: {
        url: `${API_ROOT}flat_event.json`,
        selector: getTelematics,
        redux_types: telematic_types,
        non_asset: true,
        fields: {
            asset: {
                type: 'enum',
                multi: false,
                default: null,
            },
            device: {
                type: 'enum',
                multi: false,
                default: null,
            },
            type: {
                type: 'enum',
                multi: false,
                default: 'telematic',
            },
            timestamp_gte: {
                type: 'date',
                default: moment().subtract(2, 'days'),
                valueFormatter: (v) => v && v.toISOString(),
            },
            timestamp_lte: {
                type: 'date',
                default: moment().add(2, 'hours'),
                valueFormatter: (v) => v && v.toISOString(),
            },
            advanced_search: {
                type: 'enum',
                multi: true,
                default: [],
            },
            advanced_search_id: {
                type: 'enum',
                multi: false,
                default: null,
            },
            ordering: {
                type: 'enum',
                multi: false,
                default: '-timestamp',
            },
            page_size: {
                type: 'enum',
                multi: false,
                default: '500',
            },
        },
    },
    lineas_gmao: {
        url: '/gmao_interfaces/api_message_logs/',
        selector: getApiMessageLogsList,
        redux_types: [
            lineas_sap_types.FETCH_LINEAS_GMAO_API_MESSAGE_LOGS_REQUEST,
            lineas_sap_types.FETCH_LINEAS_GMAO_API_MESSAGE_LOGS_COMPLETED,
            lineas_sap_types.FETCH_LINEAS_GMAO_API_MESSAGE_LOGS_FAILED,
        ],
        fields: {
            interface: {
                type: 'enum',
                multi: false,
                default: null,
            },
            resource: {
                type: 'enum',
                multi: true,
                default: [],
            },
            search: {
                type: 'string',
                default: '',
            },
            traffic: {
                type: 'enum',
                multi: true,
                strict: true,
                default: ['send', 'receive'],
            },
            timestamp_gte: {
                type: 'date',
                default: moment().subtract(1, 'days').toISOString(),
            },
            timestamp_lte: {
                type: 'date',
                default: null,
            },
            success: {
                type: 'enum',
                multi: true,
                strict: true,
                default: ['True', 'False'],
            },
            page_size: {
                type: 'enum',
                multi: false,
                default: '500',
            },
            ordering: {
                type: 'string',
                default: '-timestamp',
            },
        },
    },
    logs: {
        url: `${API_ROOT}log.json`,
        redux_types: log_types,
        selector: logs_selectors.getLogs,
        fields: {
            users: {
                type: 'enum',
                multi: true,
                default: [],
            },
            categories: {
                type: 'enum',
                multi: true,
                default: [],
            },
            team: {
                type: 'integer',
                multi: false,
                default: null,
            },
            archived: {
                type: 'enum',
                multi: true,
                strict: true,
                default: ['False'],
            },
            search: {
                type: 'string',
                default: '',
            },
            ordering: {
                type: 'enum',
                multi: false,
                default: 'progress',
            },
        },
    },
    campaigns: {
        url: `${API_ROOT}campaign.json`,
        redux_types: campaign_types,
        selector: getCampaigns,
        fields: {
            asset_ids: {
                type: 'enum',
                multi: true,
                default: null,
            },
            asset_classes: {
                type: 'enum',
                multi: true,
                default: null,
            },
            asset_labels: {
                type: 'enum',
                multi: true,
                default: null,
            },
            states: {
                type: 'enum',
                multi: true,
                default: ['in_progress'],
            },
            search: {
                type: 'string',
                default: '',
            },
            page_size: {
                type: 'enum',
                multi: false,
                default: '50',
            },
            ordering: {
                type: 'string',
                default: '-date_started',
            },
        },
    },
    events: {
        url: `${API_ROOT}maintenance.json`,
        redux_types: events_types,
        selector: m_e_selectors.getMaintenanceEvents,
        filtersUpdate: (state, filters) => {
            if (!filters.fields.maintenance_operations_and_groups) return filters;
            const possible_mo = _.pluck(getOperationsAndGroupsOptionsByAssets(state), 'value');
            const possible = [];
            for (const maintenance_operation of filters.fields.maintenance_operations_and_groups) {
                if (possible_mo.includes(maintenance_operation)) {
                    possible.push(maintenance_operation);
                }
            }
            const fields = {

                ...filters.fields, maintenance_operations_and_groups: possible,
            };
            return { ...filters, fields };
        },
        fields: {
            asset_ids: {
                type: 'enum',
                multi: true,
                default: null,
            },
            asset_classes: {
                type: 'enum',
                multi: true,
                default: null,
            },
            asset_labels: {
                type: 'enum',
                multi: true,
                default: null,
            },
            maintenance_types: {
                type: 'enum',
                multi: true,
                strict: true,
                default: ['preventive'],
            },
            impact_status: {
                type: 'enum',
                multi: true,
                strict: true,
                default: [1, 2, 3],
            },
            maintenance_operations_and_groups: {
                type: 'enum',
                multi: true,
                default: [],
            },
            states: {
                type: 'enum',
                multi: true,
                default: null,
            },
            private: {
                type: 'boolean',
                default: false,
            },
            admin: {
                type: 'integer',
                default: null,
            },
            ecm: {
                type: 'integer',
                default: null,
            },
            search: {
                type: 'string',
                default: '',
                valueFormatter: (v) => v.replace(/\b[m|M]([0-9]* *)/g, '$1'),
            },
            ordering: {
                type: 'string',
                default: 'sorting_date',
            },
            date_opened_gte: {
                type: 'date_opened',
                default: null,
            },
            date_opened_lte: {
                type: 'date_opened',
                default: null,
            },
            page_size: {
                type: 'enum',
                multi: false,
                default: '50',
            },

        },
    },
    interventions: {
        url: `${API_ROOT}intervention.json`,
        redux_types: intervention_types,
        selector: getInterventions,
        fields: {
            asset_ids: {
                type: 'enum',
                multi: true,
                default: null,
            },
            asset_classes: {
                type: 'enum',
                multi: true,
                default: null,
            },
            asset_labels: {
                type: 'enum',
                multi: true,
                default: null,
            },
            assigned_companies: {
                type: 'enum',
                multi: true,
                default: [],
            },
            pois: {
                type: 'enum',
                multi: true,
                default: [],
            },
            states: {
                type: 'enum',
                multi: true,
                default: ['planned', 'in_progress'],
            },
            date_state_gte: {
                type: 'date',
                default: null,
            },
            date_state_lte: {
                type: 'date',
                default: null,
            },
            search: {
                type: 'string',
                default: '',
                valueFormatter: (v) => v.replace(/\b[i|I][n|N]([0-9]* *)/g, '$1'),
            },
            ordering: {
                type: 'enum',
                multi: false,
                default: '-date_state',
            },
            page_size: {
                type: 'enum',
                multi: false,
                default: '50',
            },
        },
    },
    asset_preparations: {
        url: `${API_ROOT}preparation.json`,
        selector: getAssetPreparationList,
        redux_types: [
            asset_preparation_types.FETCH_ASSET_PREPARATION_REQUEST,
            asset_preparation_types.FETCH_ASSET_PREPARATION_COMPLETED,
            asset_preparation_types.FETCH_ASSET_PREPARATION_FAILED,
        ],
        fields: {
            assets: {
                type: 'enum',
                multi: true,
                default: null,
            },
            asset_classes: {
                type: 'enum',
                multi: true,
                default: null,
            },
            timestamp_gte: {
                type: 'date',
                default: moment().subtract(2, 'days'),
                valueFormatter: (v) => v && v.toISOString(),
            },
            timestamp_lte: {
                type: 'date',
                default: moment().add(2, 'hours'),
                valueFormatter: (v) => v && v.toISOString(),
            },
            types: {
                type: 'enum',
                multi: true,
                default: [],
            },
            is_conform: {
                type: 'enum',
                multi: true,
                strict: true,
                default: ['True', 'False'],
            },
            page_size: {
                type: 'enum',
                multi: false,
                default: '500',
            },
            ordering: {
                type: 'string',
                default: '-timestamp',
            },
        },
    },
    fleet_availability_data: {
        url: `${API_ROOT}fleet_availability_data.json`,
        redux_types: fleet_availability_types,
        selector: getFleetAvailabilityData,
        fields: {
            asset_ids: {
                type: 'enum',
                multi: true,
                default: [],
            },
            asset_classes: {
                type: 'enum',
                multi: true,
                default: [],
            },
            asset_labels: {
                type: 'enum',
                multi: true,
                default: [],
            },
            assigns: {
                type: 'enum',
                multi: true,
                default: [],
            },
            states: {
                type: 'enum',
                multi: true,
                default: ['planned', 'in_progress', 'done'],
            },
            intervention_types: {
                type: 'enum',
                multi: true,
                default: [],
            },
        },
    },
};
