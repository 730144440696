import html2canvas from 'html2canvas';
import { useState } from 'react';

const useScreenshot = () => {
    const [_img, setImg] = useState(null);
    const takeScreenshot = async () => html2canvas(document.querySelector('#react_entry_point'), {
        allowTaint: true,
        useCORS: true,
        logging: false,
        height: window.outerHeight + window.innerHeight,
        windowHeight: window.outerHeight + window.innerHeight,
    })
        .then((canvas) => {
            const img = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
            setImg(img);
            return canvas;
        });

    return [_img, takeScreenshot];
};

export default useScreenshot;
