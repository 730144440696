/**
 * Created by lpostula on 12/05/17.
 */
import { createSelector } from 'reselect';

export const getLocations = (state) => _.omit(state.locations, ['search_results', 'search_query']);
const getSearchResultsRaw = (state) => state.locations.search_results;

const getLocationsByIds = (state, place_ids) => (place_ids ? _.filter(
    getLocations(state),
    ((p) => place_ids.includes(p.id)),
) : []);

const getLocationsById = (state, place_id) => (place_id ? _.find(
    getLocations(state),
    ((p) => p.id === place_id),
) : null);

const getSearchResults = createSelector(
    [getLocations, getSearchResultsRaw],
    (locations, search_results) => {
        // return sorted array as it was received !
        const rs = [];
        if (!search_results) return [];
        for (const id of search_results) {
            rs.push(locations[id]);
        }
        return rs;
    },
);

function getGlyphClass(type) {
    switch (type) {
    case 'location':
        return 'glyphicon glyphicon-home';
        // glyph =  'glyphicon glyphicon-globe';
    case 'workshop':
        return 'glyphicon glyphicon-wrench';
    case 'railpoint':
        return 'glyphicon glyphicon-map-marker';
        // glyph = 'glyphicon glyphicon-flag';
        // glyph = 'glyphicon glyphicon-road';
    case 'refuel_station':
        return 'glyphicon glyphicon-oil';
    case 'charging_station':
        return 'glyphicon glyphicon-flash';
    default:
        return '';
    }
}

const getSearchResultsOptions = createSelector(
    [getSearchResults],
    (locations) => {
        // array is already sorted keep given ordering
        const options = _.map(locations, (v) => (
            {
                value: v.id,
                label: `${v.poi_type}: ${v.name}`,
                poi: v,
                glyph: getGlyphClass(v.poi_type),
            }));
        return options;
    },
);

export default {
    getLocations,
    getLocationsById,
    getLocationsByIds,
    getSearchResultsOptions,
};
