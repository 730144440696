/**
 * Created by lpostula on 17/05/17.
 */
const FETCH_LIST_REQUEST = 'restrictions/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'restrictions/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'restrictions/FETCH_LIST_FAILED';

const FETCH_DETAIL_COMPLETED = 'restrictions/FETCH_DETAIL_COMPLETED';

const UPDATE_DETAILS_REQUEST = 'restrictions/UPDATE_DETAILS_REQUEST';
const UPDATE_DETAILS_FAILED = 'restrictions/UPDATE_DETAILS_FAILED';

const CREATE_RESTRICTION_REQUEST = 'restrictions/CREATE_RESTRICTION_REQUEST';
const CREATE_RESTRICTION_FAILED = 'restrictions/CREATE_RESTRICTION_FAILED';

const CLOSE_RESTRICTION_REQUEST = 'restrictions/CLOSE_RESTRICTION_REQUEST';
const CLOSE_RESTRICTION_FAILED = 'restrictions/CLOSE_RESTRICTION_FAILED';

export default {
    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,
    FETCH_DETAIL_COMPLETED,
    UPDATE_DETAILS_REQUEST,
    UPDATE_DETAILS_FAILED,
    CREATE_RESTRICTION_REQUEST,
    CREATE_RESTRICTION_FAILED,
    CLOSE_RESTRICTION_REQUEST,
    CLOSE_RESTRICTION_FAILED,
};
