/* eslint-disable max-len */
/**
 * Created by lpostula on 05/12/17.
 */
import types from './types';
import { createReducer } from '../../utils';
import { WS_CONFIG } from '../../config';

function guidGenerator() {
    const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
}

const initialState = {
    loading: false,
    connected: false,
    revert_to_polling: false,
    error: null,
    message: null,
    instance: null,
    reconnect_attempts: 0,
    reconnect_interval: WS_CONFIG.reconnectInterval,
    streams: {},
    polling_id: guidGenerator(),
};

const wsReducer = createReducer(initialState)({
    [types.CONNECTING]: (state) => ({ ...state, loading: true }),
    [types.RECONNECTING]: (state) => ({ ...state, loading: true }),
    [types.OPENED]: (state, action) => ({

        ...state,
        loading: false,
        connected: true,
        instance: action.payload,
        reconnect_attempts: 0,
    }),
    [types.MESSAGED]: (state, action) => ({ ...state, message: action.payload }),
    [types.ERROR]: (state, action) => ({ ...state, loading: false, error: action.payload }),
    [types.INC_ATTEMPT]: (state) => {
        const new_attempt = state.reconnect_attempts + 1;
        let new_interval = WS_CONFIG.reconnectInterval * (
            WS_CONFIG.reconnectDecay ** new_attempt);
        if (new_interval > WS_CONFIG.maxReconnectInterval) {
            new_interval = WS_CONFIG.maxReconnectInterval;
        }
        return {

            ...state,
            reconnect_attempts: new_attempt,
            reconnect_interval: new_interval,
        };
    },
    [types.REVERT_TO_POLLING]: (state) => ({ ...state, revert_to_polling: true }),
    [types.CLOSED]: () => ({ ...initialState }),
    [types.REGISTER_TO_STREAM]: (state, action) => {
        const current_stream = {

            ...state.streams[action.payload.stream] || {},
            [action.payload.asset_id]: action.payload.on_event,
        };
        const streams = {

            ...state.streams,
            [action.payload.stream]: current_stream,
        };
        return { ...state, streams };
    },
    [types.UNREGISTER_TO_STREAM]: (state, action) => {
        const current_stream = state.streams[action.payload.stream] || {};
        const new_stream = {};
        for (const asset_id in current_stream) {
            if (current_stream.hasOwnProperty(asset_id) && asset_id !== `${action.payload.asset_id}`) {
                new_stream[asset_id] = current_stream[asset_id];
            }
        }
        const streams = {

            ...state.streams,
            [action.payload.stream]: new_stream,
        };
        return { ...state, streams };
    },
    [types.REGISTER_TO_FLEET_STREAM]: (state, action) => {
        const current_stream = {

            ...state.streams[action.payload.stream] || {},
            any: action.payload.on_event,
        };
        const streams = {

            ...state.streams,
            [action.payload.stream]: current_stream,
        };
        return { ...state, streams };
    },
    [types.UNREGISTER_TO_FLEET_STREAM]: (state, action) => {
        // const current_stream = state.streams[action.payload.stream] || {};
        const new_stream = {};
        // ALL OF THEM MUST GO
        // for (const asset_id in current_stream) {
        //     if (current_stream.hasOwnProperty(asset_id) && asset_id !== `${action.payload.asset_id}`) {
        //         new_stream[asset_id] = current_stream[asset_id];
        //     }
        // }
        const streams = {

            ...state.streams,
            [action.payload.stream]: new_stream,
        };
        return { ...state, streams };
    },
});

export default wsReducer;
