/**
 * Created by lpostula on 12/10/17.
 */
import moment from 'moment-timezone';

export default class IncremetalPoller {
    constructor(url, converter, from, to, smallDelay, bigDelay) {
        if (
            (from && !moment(from, moment.ISO_8601).isValid())
            || (to && !moment(to, moment.ISO_8601).isValid())
        ) {
            console.error('Bad date format, expected ISO_8601');
            throw Error('bad error format');
        }
        if (!url) {
            console.error('need an endpoint');
            throw Error('missing endpoint');
        }
        if (!converter) {
            console.error('need a converter');
            throw Error('missing converter');
        }
        this.listeners = [];
        this.serie = [];
        this.inc = -1;
        this.url = url;
        this.converter = converter;
        this.max_date = to || moment().toISOString();
        this.min_date = from || moment().toISOString();
        this.smallDelay = smallDelay || 20000;
        this.bigDelay = bigDelay || 120000;
        this.active = false;

        this.smallLoop = this.smallLoop.bind(this);
        this.bigLoop = this.bigLoop.bind(this);
    }

    subscribe(listener) {
        this.listeners = this.listeners.concat([listener]);
        return () => {
            const index = this.listeners.indexOf(listener);
            return this.listeners.splice(index, 1);
        };
    }

    emit(...args) {
        for (const l of this.listeners) {
            l.apply(this, args);
        }
    }

    start() {
        this.active = true;
        this.bigLoop();
    }

    stop() {
        this.active = false;
        window.clearTimeout(this.bigTimeoutID);
        window.clearTimeout(this.smallTimeoutID);
    }

    zoomChanged(min, max) {
        const new_min_date = moment(min);
        const min_date = moment(this.min_date);
        const new_max_date = moment(max);
        const max_date = moment(this.max_date);
        let refresh = false;
        if (min_date.isAfter(new_min_date)) {
            refresh = true;
            this.min_date = new_min_date.toISOString();
        }
        if (max_date.isAfter(new_max_date)) {
            refresh = true;
            this.max_date = new_max_date.toISOString();
        }
        if (refresh) {
            this.start();
        }
    }

    smallLoop() {
        const dates = {
            from: this.max_date,
        };
        const updater = (serie, data) => serie.concat(data);
        this.load(dates, updater);
    }

    bigLoop() {
        window.clearTimeout(this.bigTimeoutID);
        const dates = {
            from: this.min_date,
            to: this.max_date,
        };
        const updater = (serie, data) => data;
        this.load(dates, updater);
        this.bigTimeoutID = setTimeout(this.bigLoop, this.bigDelay);
    }

    load(dates, updater) {
        window.clearTimeout(this.smallTimeoutID);
        if (Cookies.get('no_refresh') && Cookies.get('no_refresh') !== '0') {
            this.smallTimeoutID = setTimeout(this.smallLoop, this.smallDelay);
            return;
        }
        $.getJSON(this.url, dates).then((data) => {
            this.serie = _.uniq(_.sortBy(updater(this.serie, this.converter(data)), 'id'), true, (d) => d.id);
            const serie_size = this.serie.length;
            if (serie_size > 0) {
                const min_date = this.serie[0].type === 'POINT' ? this.serie[0].at : this.serie[0].from;
                const max_date = this.serie[serie_size - 1].type === 'POINT' ? this.serie[serie_size - 1].at : this.serie[serie_size - 1].to ? this.serie[serie_size - 1].to : moment().toISOString();
                const new_min_date = moment(min_date);
                const min = moment(this.min_date);
                const new_max_date = moment(max_date);
                const max = moment(this.max_date);
                if (min.isAfter(new_min_date)) {
                    this.min_date = new_min_date.toISOString();
                }
                if (max.isBefore(new_max_date)) {
                    this.max_date = new_max_date.toISOString();
                }
            }
            this.emit(this.serie);
            // this check is necessary because if we stop the poller during a request,
            // then it will restart itself because the query is not cancelled
            if (this.active) {
                this.smallTimeoutID = setTimeout(this.smallLoop, this.smallDelay);
            }
        });
    }
}
