import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import moment from 'moment-timezone';
import components from 'components';
import { maintenanceEventsOperations, maintenanceEventsSelectors } from 'railfleet_state/ducks/maintenance_events';
import { interventionsOperations, interventionsSelectors } from 'railfleet_state/ducks/interventions';
import { documentsSelectors, documentsOperations } from 'railfleet_state/ducks/documents';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import './CloseEventForm.scss';
import scrollToTop from '../../../utils/scrollToTop';
import FormCheckbox from '../../../components/forms/RailForms/formCheckbox/FormCheckbox';

const {
    FormManager,
    FormTextInput,
    CutForm,
    FormDateTime,
    FormFile,
    FormFaultCategory,
    FormCustomField,
    FormCounterDoneUsage,
    InputsWrapper,
} = components.forms;

const { THEMES } = components.selectors;
const { FocusableFrame } = components.frames;
const { MultiDeviceLayout } = components.app;
const { usePerm } = components.hooks;

const CloseEventFormComponent = (props) => {
    const [_locked, setLocked] = useState(false);
    const [_from, setFrom] = useState(null);
    const [_iea, setIea] = useState(null);
    const [_errorApi, setErrorApi] = useState(null);
    const hasPerm = usePerm(props.asset_id || null);
    let perm_name = '';
    if (props.maintenance_type === 'preventive') perm_name = 'close_preventive_event';
    else if (props.maintenance_type === 'campaign') perm_name = 'close_campaign_event';
    else perm_name = 'close_corrective_event';
    if (!hasPerm(perm_name) && !props.loginless) {
        return (<div>{`YOU DO NOT HAVE THE PERM: ${perm_name}`}</div>);
    }

    useEffect(() => {
        if (props.intervention_id) {
            props.fetchInterventionDetail(props.intervention_id);
        }
    }, [props.intervention_id]);

    useEffect(() => {
        let selectedIea = null;
        if (props.intervention && props.intervention.interventioneventaction) {
            for (const iea of props.intervention.interventioneventaction) {
                if (iea.event === props.event_id) selectedIea = iea;
            }
        }
        setIea(selectedIea);
    }, [props.intervention]);

    useEffect(() => {
        if (props.event.id) {
            props.fetchDocumentsForEvent(props.event.id);
        }
    }, [props.event.id]);

    const defaultValues = {
        date_started: (
            props.event.date_started && moment(props.event.date_started)
        ) || props.date_started || moment(),
        date_closed: (props.event.date_closed && moment(props.event.date_closed)) || moment(),
        rts_question_derogation: _iea && _iea.rts_question_derogation,
        rts_question_derogation_comment: _iea && _iea.rts_question_derogation_comment,
    };

    const format_data = (data) => {
        const formated_data = { ...data };
        formated_data.id = props.event.id;
        formated_data.result = 'closed';
        formated_data.intervention = props.intervention_id;
        formated_data.date_closed = data.date_closed.toISOString();
        formated_data.date_started = data.date_started.toISOString();
        formated_data.rts_question_derogation = formated_data.rts_question_derogation === 'true';
        return formated_data;
    };

    const onSubmit = (arg) => {
        if (props.locked) return;
        setLocked(true);
        if (props.setLocked) props.setLocked(true);
        const payload = format_data(arg);
        const method = _iea && _iea.result === 'closed' ? props.updateEvent : props.closeEvent;
        method(payload).then((d) => {
            if (props.setLocked) props.setLocked(false);
            setLocked(false);
            if (d.payload && !d.error) {
                setErrorApi(null);
                if (props.callback) props.callback(d.payload);
            }
            if (d.error) {
                setErrorApi(d.payload.response);
                scrollToTop();
            }
        });
    };

    const onChangeFrom = (arg) => {
        if (arg) setFrom(arg);
        else setFrom(null);
    };

    return (
        <div className={`form-main close-event-form ${props.device}`}>
            <div className="data-form">
                <FormManager
                    onSubmit={onSubmit}
                    textSubmitButton={props.textSubmit}
                    onCancel={props.onCancel}
                    disabled={props.locked || _locked}
                    apiErrors={_errorApi}
                >
                    <InputsWrapper
                        id="maintenance_report"
                        title={i18n.t('Maintenance report')}
                        standalone={props.standalone}
                    >
                        <CutForm
                            device={props.device}
                        >
                            <FormDateTime
                                id="date_started"
                                label={i18n.t('Date started')}
                                defaultValue={
                                    defaultValues.date_started
                                }
                                theme={THEMES.FORM_LIGHT}
                                fillFlex
                                required
                            />
                            <FormDateTime
                                id="date_closed"
                                label={i18n.t('Date closed')}
                                defaultValue={
                                    defaultValues.date_closed
                                }
                                theme={THEMES.FORM_LIGHT}
                                fillFlex
                                required
                                onChange={onChangeFrom}
                            />
                        </CutForm>
                        {
                            ['preventive', 'campaign'].includes(props.maintenance_type)
                                && (
                                    <FormCounterDoneUsage
                                        event_id={props.event_id}
                                        date={_from}
                                        defaultValue={defaultValues}
                                        required
                                    />
                                )
                        }
                        <CutForm
                            device={props.device}
                        >
                            <FormTextInput
                                id="rex"
                                label={i18n.t('Comment')}
                                defaultValue={
                                    props.event.rex
                                }
                                theme={THEMES.FORM_LIGHT}
                                large
                                required
                            />
                            <div className="event-form-full-line">
                                <FormFile
                                    id="documents"
                                    label={i18n.t('Documents')}
                                    defaultValue={props.event.documents}
                                    documents={props.event.id && props.documents_for_event}
                                    multi
                                    url="/api/document.json"
                                    large
                                />
                            </div>
                            {
                                props.maintenance_type === 'corrective'
                                    && (
                                        <FormFaultCategory
                                            id="fault_category"
                                            defaultValue={
                                                defaultValues.fault_category
                                            }
                                            event_id={props.event_id}
                                            asset_id={props.asset_id}
                                            label={props.fault_category_label || i18n.t('Fault Category')}
                                            large
                                            theme={THEMES.FORM_LIGHT}
                                        />
                                    )
                            }
                        </CutForm>
                        {
                            props.fromIntervention
                            && (
                                <div className="rts-part">
                                    <CutForm
                                        device={props.device}
                                    >
                                        <FormCheckbox
                                            id="rts_question_derogation"
                                            selectedValue={(defaultValues && defaultValues.rts_question_derogation ? 'true' : 'false') || 'false'}
                                            label={i18n.t('Was there a derogation against technical instructions during this intervention?')}
                                            values={[
                                                {
                                                    id: 'true',
                                                    name: i18n.t('Yes'),
                                                },
                                                {
                                                    id: 'false',
                                                    name: i18n.t('No'),
                                                },
                                            ]}
                                            trigger="false"
                                            labelizer={(item) => item.name}
                                            required
                                            disabled={defaultValues && defaultValues.rts_question_derogation}
                                        />
                                        <FormTextInput
                                            id="rts_question_derogation_comment"
                                            defaultValue={defaultValues && defaultValues.rts_question_derogation_comment}
                                            label={i18n.t('Comment')}
                                            large
                                            lockSize
                                            disabled={defaultValues && defaultValues.rts_question_derogation_comment}
                                        />
                                    </CutForm>
                                </div>
                            )
                        }
                        {
                            props.maintenance_type !== 'campaign'
                                && (
                                    <>
                                        <FormCustomField
                                            id="public_fields"
                                            defaultValue={props.event}
                                            public
                                            content_type="maintenanceevent"
                                            content_options={['close', props.event.maintenance_type]}
                                            asset={props.asset_id}
                                            asset_class={props.asset_class}
                                        />
                                        <FormCustomField
                                            id="private_fields"
                                            defaultValue={props.event}
                                            private
                                            content_type="maintenanceevent"
                                            content_options={['close', props.event.maintenance_type]}
                                            asset={props.asset_id}
                                            asset_class={props.asset_class}
                                        />
                                    </>
                                )
                        }
                    </InputsWrapper>
                </FormManager>
            </div>
        </div>
    );
};

const FormComponent = (props) => (
    <MultiDeviceLayout>
        <CloseEventFormComponent
            {...props}
        />
    </MultiDeviceLayout>
);

const CloseEventForm = (props) => {
    if (props.framed) {
        return (
            <FocusableFrame
                light
                noTabletDisplay={props.noTabletDisplay}
            >
                <FormComponent
                    {...props}
                />
            </FocusableFrame>
        );
    }
    return (
        <FormComponent
            {...props}
        />
    );
};

const mapStateToProps = (state, props) => {
    const { event_id } = props;
    const { intervention_id } = props;
    const event = event_id && maintenanceEventsSelectors.getMaintenanceEvent(state, event_id);
    const asset_id = event && event.asset;
    const asset = asset_id && assetsSelectors.getAssetInfos(state, asset_id);
    const asset_class = asset && asset.asset_class;
    const maintenance_type = event && event.maintenance_type;
    const intervention = intervention_id
        ? interventionsSelectors.getInterventionById(state, intervention_id)
        : null;
    const documents_for_event = event_id
        && documentsSelectors.getDocumentsForEvent(state, event_id);
    return {
        asset_id,
        asset_class,
        event: event || {},
        maintenance_type,
        intervention: intervention || {},
        documents_for_event: documents_for_event || {},
    };
};

const mapDispatchToProps = (dispatch) => ({
    closeEvent: (values) => dispatch(
        maintenanceEventsOperations.closeMaintenanceEvent(values),
    ),
    fetchDocumentsForEvent: (event_id) => (
        dispatch(documentsOperations.fetchForEvent(event_id))
    ),
    updateEvent: (payload) => dispatch(maintenanceEventsOperations.updateMaintenanceEvent(payload)),
    fetchInterventionDetail: (intervention_id) => dispatch(
        interventionsOperations.fetchDetails(intervention_id),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CloseEventForm);
