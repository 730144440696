import React from 'react';
import './FormCaptcha.scss';
import ReactSelectWrapper from '../ReactSelectWrapper';
import YourReCaptchaComponent from '../../internal/inputs/CaptchaInput';

const FormCaptcha = (props) => (
    <ReactSelectWrapper
        classname="captcha"
        // eslint-disable-next-line react/no-children-prop
        children={YourReCaptchaComponent}
        name={props.id}
        {...props}
    />
);

export default FormCaptcha;
