import React, { useEffect, useState, useRef } from 'react';
import railnovaLogo from './img/railnova-logo.png';
// import railnovaLogoGrey from '../img/logo_railnova_grey.svg';
import MainMenu from './main_menu';
import Quicklinks from './quick_links';
import './main_menu_button.css';

const MainMenuButton = ({
    user, product, page, logout_url, isMobile,
}) => {
    const node = useRef();
    const qlinksNode = useRef();
    const [isOpen, setOpen] = useState(false);

    const handleClickOutside = (e) => {
        if (node.current.contains(e.target)) {
            return;
        }
        // I think this is required because the Quicklinks are in a fixed position
        if (qlinksNode.current.contains(e.target)) {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    // find quick links items
    const quickLinksCateg = user && user.pages && user.pages.find((category) => (category.position === 'right'));
    const quickLinksItems = quickLinksCateg ? quickLinksCateg.pages : [];

    return (
        <>
            <div
                className={`main-nav-button ${product}-bkgcolor`}
                ref={node}
            >
                <button
                    className="dropdown-toggle"
                    onClick={() => setOpen(!isOpen && product)}
                    type="button"
                >
                    {
                        isOpen ? (
                            <span className="glyphicon glyphicon-remove glyphicon-light" />
                        ) : (
                            <img
                                src={railnovaLogo}
                                className="rn_logo"
                                alt="logo"
                            />
                        )
                    }
                    <span className="current-location">
                        {product && <span className="current-product">{product}</span>}
                        {/* product && */ <span className="glyphicon glyphicon-chevron-right glyphicon-light" />}
                        <span className="current-page">{page}</span>
                        <span className="sr-only">Toggle navigation</span>
                        {product && <span className={`caret ${isOpen ? 'transparent' : ''}`} />}
                    </span>
                </button>
                { isOpen
                    && (
                        <MainMenu
                            user={user}
                            logout_url={logout_url}
                            onClick={() => setOpen(false)}
                        />
                    )}
            </div>
            <Quicklinks
                items={quickLinksItems}
                isOpen={isOpen || !product}
                ref={qlinksNode}
                isMobile={isMobile}
            />
        </>
    );
};

export default MainMenuButton;
