/**
 * Created by lpostula on 12/05/17.
 */
import types from './types';

export const filterError = (error) => ({
    type: types.FILTER_ERROR,
    payload: error,
});

export const setSearchText = (text) => ({
    type: types.SET_SEARCH_TEXT,
    payload: text,
});

export const setSortBy = (text) => ({
    type: types.SET_SORT_BY,
    payload: text,
});

export const setActionMode = (text) => ({
    type: types.SET_ACTION_MODE,
    payload: text,
});

export const setSelectedLabels = (values) => ({
    type: types.SET_SELECTED_LABELS,
    payload: values,
});

export const setSelectedStatus = (values) => ({
    type: types.SET_SELECTED_STATUS,
    payload: values,
});

export const setSelectedTechStatus = (values) => ({
    type: types.SET_SELECTED_TECH_STATUS,
    payload: values,
});

export const setFilterKey = (payload) => ({
    type: types.SET_FILTER_KEY,
    payload,
});

export const updatedAssetInfos = (payload) => ({
    type: types.UPDATED_ASSET_INFOS,
    payload,
});

export const setMaskSearch = (payload) => ({
    type: types.SET_MASK_SEARCH,
    payload,
});

export const setMaskFilter = (payload) => ({
    type: types.SET_MASK_FILTER,
    payload,
});

export const refreshResult = () => ({
    type: types.REFRESH_RESULT,
});

export default {
    filterError,
    setSearchText,
    setSortBy,
    setActionMode,
    setFilterKey,
    updatedAssetInfos,
    setSelectedLabels,
    setSelectedStatus,
    setSelectedTechStatus,
    setMaskSearch,
    setMaskFilter,
    refreshResult,
};
