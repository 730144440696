/**
 * Created by lpostula on 11/05/17.
 */
import { RSAA } from 'redux-api-middleware';
import types from './types';
import { API_ROOT } from '../../config';

const API_RESOURCE_NAME = 'label_category';

export const fetchList = (long_polling = false) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            {
                type: types.FETCH_LIST_REQUEST,
                meta: { long_polling },
            },
            {
                type: types.FETCH_LIST_COMPLETED,
                meta: { long_polling },
            },
            types.FETCH_LIST_FAILED,
        ],
    },
});

export const updateCategoryInStore = (data) => ({
    type: types.FETCH_DETAIL_COMPLETED,
    payload: data,
});

export const removeCategoryInStore = (label_id) => ({
    type: types.DELETE_COMPLETED,
    meta: {
        id: label_id,
    },
});

export default {
    fetchList,
    updateCategoryInStore,
    removeCategoryInStore,
};
