import { combineReducers } from 'redux';
import types from './types';
import { createReducer, apiHelpers } from '../../utils';

const assetPreparationReducer = createReducer({})({
    [types.FETCH_ASSET_PREPARATION_COMPLETED]: apiHelpers.fetch_list_complete,
});

const AssetPreparationreducer = combineReducers({
    asset_preparations: assetPreparationReducer,
});

export default AssetPreparationreducer;
