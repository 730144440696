import React, { useState, useEffect, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import 'react-dropzone-component/styles/filepicker.css';
import 'dropzone/dist/min/dropzone.min.css';
import ReactSelectWrapper from '../ReactSelectWrapper';
import FileInput from '../../internal/inputs/FileInput';
import { StepContext } from '../step/Step';
import FormLabel from '../formLabel/FormLabel';
import './FormFile.scss';

const FileUpload = (props) => {
    const [_documents, setDocuments] = useState([]);

    // This is to be used with the Step Manager.
    // It allow to do Forms with multiple steps.
    // We have to register each field in the Step Manager to validate fields on each step instead of
    // validating each fields at the end of the form with the submit button.
    const stepContext = useContext(StepContext);
    useEffect(() => {
        if (stepContext && stepContext.addKey) stepContext.addKey(stepContext.step, props.id);
        if (props.defaultValue) {
            setDocuments(props.defaultValue);
        }
    }, []);

    useEffect(() => {
        props.onChange(_documents);
    }, [_documents]);

    const onChangeDocuments = (data) => {
        setDocuments(data);
    };
    return (
        <div className="form_file_holder">
            <FileInput
                name={props.id}
                values={props.documents}
                value={_documents}
                onChange={onChangeDocuments}
                url={props.url}
                multi={props.multi}
                deletable={props.deletable}
                deleteAction={props.deleteAction}
                unique={props.unique}
            />
        </div>
    );
};

const FormFile = (props) => {
    const { errors } = useFormContext();
    const valueError = errors && errors[props.id];

    const validate = (data) => {
        if (!data) return [];
        return data;
    };
    return (
        <div className={`form form_file ${props.className ? props.className : ''} ${props.id} ${!props.label ? 'no-label' : ''}`} key={props.id}>
            <FormLabel {...props} />
            <div className="form_file_holder">
                <ReactSelectWrapper
                    className="file"
                    // eslint-disable-next-line react/no-children-prop
                    children={FileUpload}
                    name={props.id}
                    validate={validate}
                    {...props}
                />
            </div>
            {valueError && (
                <div className="form-validation-error">
                    <div className="form-validation-error-arrow" />
                    <div className="form-error-message">
                        {
                            (valueError.message)
                                ? <div>{valueError.message}</div>
                                : <div>Please enter a value</div>
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

export default FormFile;
