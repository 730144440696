import React from 'react';
import { connect } from 'react-redux';
import { maintenanceEventsSelectors } from 'railfleet_state/ducks/maintenance_events';
import { labelCategoriesSelectors } from 'railfleet_state/ducks/label_categories';
import TextDisplay from '../../../display/textDisplay/TextDisplay';
import CutForm from '../cutForm/CutForm';
import MultiTextDisplay from '../../../display/multiTextDisplay/MultiTextDisplay';

const RenderLabelCategory = (props) => {
    const renderElm = (cat) => {
        if (cat.is_event_related) {
            const label_set = {};
            for (const label of cat.label_set) {
                label_set[label.id] = label;
            }
            let dv = [];
            if (props.labels) {
                if (Array.isArray(props.labels)) {
                    for (const defaultValue of props.labels) {
                        if (
                            Object.keys(label_set).includes(defaultValue)
                            || Object.keys(label_set).includes(String(defaultValue))
                        ) {
                            const label = label_set[defaultValue].name;
                            dv.push(label);
                        }
                    }
                } else {
                    const label = label_set[props.labels].name;
                    dv.push(label);
                }
            }
            if (dv.length === 0) dv = null;
            let Widget = TextDisplay;
            if (dv && dv.length > 1) {
                Widget = MultiTextDisplay;
            }
            return (
                <Widget
                    key={cat.name}
                    label={cat.name}
                    data={dv}
                />
            );
        }
        return null;
    };

    const renderFields = () => {
        const ret = [];
        for (const index of Object.keys(props.user_label_categories)) {
            const elm = props.user_label_categories[index];
            ret.push(renderElm(elm, index));
        }
        return (
            <CutForm
                device={props.device}
            >
                { ret }
            </CutForm>
        );
    };

    const fields = renderFields();
    if (!fields || fields.length === 0) return null;
    return fields;
};

const mapStateToProps = (state, props) => {
    const { event_id } = props;
    const user_label_categories = labelCategoriesSelectors.getLabelCategoriesForCurrentUser(
        state, props,
    );
    const event = event_id && maintenanceEventsSelectors.getMaintenanceEvent(state, event_id);
    const asset_id = event && event.asset;
    const labels = event && event.labels;
    return ({
        user_label_categories,
        asset_id,
        labels,
    });
};

export default connect(mapStateToProps, null)(RenderLabelCategory);
