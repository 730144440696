import React, { useEffect, useState, useRef } from 'react';
import UserMenu from './user_menu';
import './user_menu_button.css';

// logic inspired by
// https://medium.com/@pitipatdop/little-neat-trick-to-capture-click-outside-with-react-hook-ba77c37c7e82
// Update 17 April 2019: Shout out to Олег Чулановский. A better (optimised) version.
// https://codesandbox.io/s/9o3lw5792w

const UserMenuButton = ({ user, logout_url }) => {
    const node = useRef();
    const [isOpen, setOpen] = useState(false);
    const handleClickOutside = (e) => {
        if (node.current.contains(e.target)) {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div className="user-menu-button" ref={node}>
            <div
                className="dropdown-toggle"
                onClick={() => setOpen(!isOpen)}
            >
                <span className="railnova_current_user">{user && user.email}</span>
                <span className="caret" />
            </div>
            { isOpen
                && (
                    <UserMenu
                        logout_url={logout_url}
                    />
                )}
        </div>
    );
};

export default UserMenuButton;
