/**
 * Created by gknuts on 27/09/18.
 */
import { RSAA } from 'redux-api-middleware';
import types from './types';
import { API_ROOT } from '../../config';

const API_RESOURCE_NAME = 'flat_event';

export const fetchList = (long_polling = false) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            {
                type: types.FETCH_LIST_REQUEST,
                meta: { long_polling },
            },
            {
                type: types.FETCH_LIST_COMPLETED,
                meta: { long_polling },
            },
            types.FETCH_LIST_FAILED,
        ],
    },
});

const cancelSendQuery = (collection) => (dispatch) => dispatch({
    type: types.SEND_QUERY_CANCEL,
    metadata: { collection },
});

const send_query = (payload) => (dispatch) => {
    const controller = new window.AbortController();
    return dispatch({
        [RSAA]: {
            endpoint: `${API_ROOT + API_RESOURCE_NAME}/get_advanced_key/`,
            method: 'POST',
            headers: {
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
            },
            credentials: 'same-origin',
            options: {
                signal: controller.signal,
            },
            body: JSON.stringify(payload),
            types: [
                types.SEND_QUERY_REQUEST,
                types.SEND_QUERY_SUCCESS,
                types.SEND_QUERY_FAILED,
            ],
        },
    });
};

const get_advanced_key = (search) => (dispatch) => dispatch(send_query(search));

export default {
    fetchList,
    cancelSendQuery,
    get_advanced_key,
};
