/**
 * Created by lpostula on 11/05/17.
 */
const FETCH_LIST_REQUEST = 'asset_operational_status/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'asset_operational_status/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'asset_operational_status/FETCH_LIST_FAILED';

const UPDATE_DETAILS_REQUEST = 'asset_operational_status/UPDATE_DETAILS_REQUEST';
const UPDATE_DETAILS_COMPLETED = 'asset_operational_status/UPDATE_DETAILS_COMPLETED';
const UPDATE_DETAILS_FAILED = 'asset_operational_status/UPDATE_DETAILS_FAILED';

const CREATE_EVENT_REQUEST = 'asset_operational_status/CREATE_EVENT_REQUEST';
const CREATE_EVENT_COMPLETED = 'asset_operational_status/CREATE_EVENT_COMPLETED';
const CREATE_EVENT_FAILED = 'asset_operational_status/CREATE_EVENT_FAILED';

const FETCH_DETAIL_COMPLETED = 'asset_operational_status/FETCH_DETAIL_COMPLETED';
const DELETE_COMPLETED = 'asset_operational_status/types.DELETE_COMPLETED';

export default {
    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,
    UPDATE_DETAILS_REQUEST,
    UPDATE_DETAILS_COMPLETED,
    UPDATE_DETAILS_FAILED,
    CREATE_EVENT_REQUEST,
    CREATE_EVENT_COMPLETED,
    CREATE_EVENT_FAILED,
    FETCH_DETAIL_COMPLETED,
    DELETE_COMPLETED,
};
