import React from 'react';
import './MultiTextDisplay.scss';
import NoDataDisplay from '../noDataDisplay/NoDataDisplay';

const MultiTextDisplay = (props) => {
    if (!props.data || props.data.length === 0) {
        return (<NoDataDisplay {...props} />);
    }
    return (
        <div
            className={`multi-element-holder ${props.className ? props.className : ''}`}
            key={props.id}
            onClick={props.onClick}
        >
            {
                props.label
                    && <div className="multi-element-label">{props.label}</div>
            }
            {
                props.large ? (
                    <div className="multi-element large">
                        <textarea
                            cols={30}
                            rows={5}
                            value={props.data}
                            onChange={() => null}
                        />
                    </div>
                ) : (
                    <div className="multi-element">
                        {
                            props.data.map((elm, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <span key={index}>
                                    <span>{props.labelizer(elm)}</span>
                                </span>
                            ))
                        }
                    </div>
                )
            }
            { props.children }
        </div>
    );
};

MultiTextDisplay.defaultProps = {
    labelizer: (elm) => elm,
};

export default MultiTextDisplay;
