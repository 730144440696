import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import moment from 'moment-timezone';
import { useFormContext } from 'react-hook-form';
import './FormCounterDoneUsage.scss';
import FormTextInput from '../formTextInput/FormTextInput';
import CutForm from '../cutForm/CutForm';
import MultiDeviceLayout from '../../../app/MultiDeviceLayout';
import SuggestedBubble from '../../../display/suggestedBubble/SuggestedBubble';

let counterValueAtController;

const FormCounterDoneUsageComponent = (props) => {
    const [_date, setDate] = useState(null);
    const [_proposedDoneUsage, setProposedDoneUsage] = useState([]);
    const [_freshPdu, setFreshPdu] = useState(false);
    const methods = useFormContext();

    useEffect(() => {
        let date = null;
        if (props.date) {
            if (typeof props.date === 'string') {
                if (props.format) {
                    date = moment(props.date, props.format);
                } else {
                    date = moment(props.date);
                }
            } else {
                date = props.date;
            }
        }
        setDate(date);
    }, [props.date]);

    useEffect(() => {
        async function getCounterValueAt() {
            if (counterValueAtController) {
                counterValueAtController.abort();
            }

            counterValueAtController = new AbortController();
            const { signal } = counterValueAtController;
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
                },
                signal,
                body: JSON.stringify({
                    date: _date,
                }),
            };
            const url = `/api/maintenance/${props.event_id}/maintenance_event_counters_at_close.json`;
            const r = await fetch(url, settings);
            const d = await r.json();
            return d;
        }

        if (_date && _date.isValid()) {
            getCounterValueAt().then((d) => {
                setProposedDoneUsage(d);
            });
        } else {
            setProposedDoneUsage([]);
        }
    }, [_date]);

    useEffect(() => {
        let fresh_pdu = false;
        if (_proposedDoneUsage) {
            const then = moment(_proposedDoneUsage.increment_date).unix();
            const now = moment().unix();
            const diff_in_days = (now - then) / (60 * 60 * 24);
            fresh_pdu = diff_in_days < 15;
        }
        setFreshPdu(fresh_pdu);
    }, [_proposedDoneUsage]);

    const DisplaySuggestion = ({ pdu, callback }) => (
        <div className="suggested-done-usage-holder">
            {
                _freshPdu ? (
                    <SuggestedBubble
                        value={pdu.done_usage}
                        clickable={_freshPdu}
                        unit={pdu.unit}
                        label={i18n.t('suggested_done_usage')}
                        onClick={() => callback && callback(pdu)}
                    />
                ) : (
                    <div>
                        {i18n.t('Last update')}
                        :
                        {moment(pdu.increment_date).format('DD/MM/YYYY HH:mm')}
                    </div>
                )
            }
        </div>
    );

    const callbackUse = (arg, id) => {
        methods.setValue(id, arg.done_usage);
    };

    const getReading = (counter_id) => {
        if (props.defaultValue) {
            if (props.defaultValue.counter_reading) {
                if (props.defaultValue.counter_reading.counter === counter_id) {
                    return props.defaultValue.counter_reading.value;
                }
            } else if (props.defaultValue.counterreading_source_set) {
                for (const reading of props.defaultValue.counterreading_source_set) {
                    if (reading.counter === counter_id) return reading.value;
                }
            }
        }
        return null;
    };

    const getReadingId = (counter_id) => {
        if (props.defaultValue) {
            if (props.defaultValue.counter_reading) {
                if (props.defaultValue.counter_reading.counter === counter_id) {
                    return props.defaultValue.counter_reading.id;
                }
            } else if (props.defaultValue.counterreading_source_set) {
                for (const reading of props.defaultValue.counterreading_source_set) {
                    if (reading.counter === counter_id) return reading.id;
                }
            }
        }
        return null;
    };

    const renderFields = () => {
        if (!_proposedDoneUsage) return null;
        const fields = _proposedDoneUsage.map((pdu) => {
            const readingId = getReadingId(pdu.id);
            let id;
            if (readingId) {
                id = `done_usage_${readingId}_${pdu.counter_type}_${pdu.component_structure}`;
            } else {
                id = `done_usage_${pdu.counter_type}_${pdu.id}_${pdu.component_structure}`;
            }
            const defaultValue = getReading(pdu.id);
            return (
                <FormTextInput
                    key={pdu.id}
                    id={id}
                    defaultValue={defaultValue}
                    label={`${i18n.t('done usage')} (${pdu.counter_type_name} ${pdu.serial})`}
                    required={props.required}
                >
                    <DisplaySuggestion
                        pdu={pdu}
                        callback={(arg) => callbackUse(arg, id)}
                    />
                </FormTextInput>
            );
        });
        return (
            <CutForm
                device={props.device}
            >
                {fields}
            </CutForm>
        );
    };

    if (_proposedDoneUsage.length === 0) {
        return null;
    }

    const fields = renderFields();
    if (!fields || fields.length === 0) return null;
    return (
        <div
            className={`form_suggested_done_usage ${props.className ? props.className : ''} ${props.id}`}
            key={props.id}
        >
            {fields}
        </div>
    );
};

const FormCounterDoneUsage = (props) => (
    <MultiDeviceLayout>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <FormCounterDoneUsageComponent
            {...props}
        />
    </MultiDeviceLayout>
);

FormCounterDoneUsage.defaultProps = {
    format: '',
};

export default FormCounterDoneUsage;
