/**
 * Created by lpostula on 15/05/17.
 */
import { RSAA } from 'redux-api-middleware';
import types from './types';
import { API_ROOT } from '../../config';

const API_RESOURCE_NAME = 'intervention';

const fetchListFiltered = (filter_name, filter_value, filter_result, controller = null) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/sliced_list.json?${filter_name}=${filter_value}`,
        method: 'GET',
        credentials: 'same-origin',
        options: {
            signal: controller && controller.signal,
        },
        types: [
            types.FETCH_LIST_REQUEST,
            {
                type: types.FETCH_LIST_COMPLETED,
                meta: filter_result ? {
                    filter_out: { key: filter_name, value: filter_value },
                } : null,
            },
            types.FETCH_LIST_FAILED,
        ],
    },
});

export const fetchForAsset = (asset_id, controller = null) => fetchListFiltered('asset', asset_id, true, controller);

export const fetchForEvent = (event_id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}.json?event=${event_id}`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_LIST_REQUEST,
            {
                type: types.FETCH_LIST_COMPLETED,
                meta: { filter_out: { key: 'event', value: event_id } },
            },
            types.FETCH_LIST_FAILED,
        ],
    },
});

export const fetchDetails = (item_id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${item_id}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_DETAILS_REQUEST,
            types.FETCH_DETAILS_COMPLETED,
            types.FETCH_DETAILS_FAILED,
        ],
    },
});

export const fetchList = (long_polling = false) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            {
                type: types.FETCH_LIST_REQUEST,
                meta: { long_polling },
            },
            {
                type: types.FETCH_LIST_COMPLETED,
                meta: { long_polling },
            },
            types.FETCH_LIST_FAILED,
        ],
    },
});

export const updateIntervention = (values) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${values.id}.json/`,
        method: 'PATCH',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(values),
        credentials: 'same-origin',
        types: [
            types.UPDATE_DETAILS_REQUEST,
            types.UPDATE_DETAILS_COMPLETED,
            types.UPDATE_DETAILS_FAILED,
        ],
    },
});

export const updateInterventionDates = (id, date, rts = {}) => (dispatch) => new Promise((resolve, reject) => (
    dispatch({
        [RSAA]: {
            endpoint: `${API_ROOT + API_RESOURCE_NAME}/${id}/next_step.json`,
            body: JSON.stringify({ date, ...rts }),
            method: 'POST',
            headers: {
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
            },
            credentials: 'same-origin',
            types: [
                types.UPDATE_DETAILS_REQUEST,
                {
                    type: types.UPDATE_DETAILS_COMPLETED,
                    payload: (action, state, res) => res.json().then((json) => {
                        resolve();
                        return json;
                    }),
                },
                {
                    type: types.UPDATE_DETAILS_FAILED,
                    payload: (action, state, res) => res.json().then((json) => {
                        reject();
                        return json;
                    }),
                },
            ],
        },
    })
));

export const createIntervention = (values) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(values),
        credentials: 'same-origin',
        types: [
            types.CREATE_INTERVENTION_REQUEST,
            types.CREATE_INTERVENTION_COMPLETED,
            types.CREATE_INTERVENTION_FAILED,
        ],
    },
});

export const updateInterventionInStore = (data) => ({
    type: types.CANCEL_INTERVENTION_COMPLETED,
    payload: data,
});

export const removeInterventionInStore = (intervention_id) => ({
    type: types.REMOVE_INTERVENTION,
    // There's too much confusion
    // I can't get no relief
    // payload: intervention_id,
    meta: {
        id: intervention_id,
    },
});

export const cancelIntervention = (intervention_id) => (dispatch) => {
    dispatch({
        [RSAA]: {
            endpoint: `${API_ROOT + API_RESOURCE_NAME}/${intervention_id}/cancel/`,
            method: 'POST',
            headers: {
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
            },
            credentials: 'same-origin',
            types: [
                types.CANCEL_INTERVENTION_REQUEST,
                types.CANCEL_INTERVENTION_COMPLETED,
                types.CANCEL_INTERVENTION_FAILED,
            ],
        },
    });
    return Promise.resolve();
};

export const addEventsInIntervention = (intervention_id, events_id) => (dispatch) => dispatch({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${intervention_id}/add_events/`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        credentials: 'same-origin',
        body: JSON.stringify({ events: events_id }),
        types: [
            types.ADD_EVENTS_IN_INTERVENTION_REQUEST,
            types.ADD_EVENTS_IN_INTERVENTION_COMPLETED,
            types.ADD_EVENTS_IN_INTERVENTION_FAILED,
        ],
    },
});

export const removeEventsFromIntervention = (intervention_id, events_id) => (dispatch) => dispatch({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${intervention_id}/remove_events/`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        credentials: 'same-origin',
        body: JSON.stringify({ events: events_id }),
        types: [
            types.REMOVE_EVENTS_FROM_INTERVENTION_REQUEST,
            types.REMOVE_EVENTS_FROM_INTERVENTION_COMPLETED,
            types.REMOVE_EVENTS_FROM_INTERVENTION_FAILED,
        ],
    },
});

export default {
    fetchForAsset,
    fetchForEvent,
    fetchList,
    fetchDetails,
    updateIntervention,
    createIntervention,
    cancelIntervention,
    removeInterventionInStore,
    addEventsInIntervention,
    removeEventsFromIntervention,
    updateInterventionDates,
    updateInterventionInStore,
};
