import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Checklist.scss';
import NotOk from './NotOk.svg';
import Ok from './Ok.svg';

const Checklist = (props) => {
    const [_validated, setValidated] = useState(false);
    useEffect(() => {
        if (props.data && props.data.length > 0) {
            let validated = true;
            for (const data of props.data) {
                if (!data.valid) {
                    validated = false;
                    break;
                }
            }
            setValidated(validated);
        }
    }, [props.data]);

    if (_validated) {
        return (
            <div className="checklist-holder allValid">
                <div className="checklist-row">
                    <div>
                        <img className="ok-img" src={Ok} alt="ok" />
                        <div className="checklist-text valid">{props.validText}</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="checklist-holder">
            {
                _.map(props.data, (elm) => {
                    if (elm.valid) return null;
                    return (
                        <div className="checklist-row" key={elm.text}>
                            <div><div>{elm.text}</div></div>
                            <div>
                                <img className="not-ok-img" src={NotOk} alt="not ok" />
                                <div className="checklist-text invalid">{props.invalidText}</div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};

Checklist.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        valid: PropTypes.bool,
    })),
    validText: PropTypes.string,
    invalidText: PropTypes.string,
};

export default Checklist;
