import { useState } from 'react';

const usePost = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [_controller, setController] = useState(null);

    const patchAll = async (url, payload = null) => {
        setIsLoading(true);
        if (_controller) {
            _controller.abort();
        }
        const c = new AbortController();
        setController(c);
        const signal = c.signal;
        const _settings = {
            signal,
            method: 'PATCH',
            headers: {
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
            },
            body: JSON.stringify(payload),
        };
        try {
            const response = await fetch(url, _settings);
            const result = await response.json();
            if (response.ok) {
                setData(result);
                setHasError(null);
                return result;
            }
            setHasError(result);
            return {
                error: true,
                ...result,
            };
        } catch (err) {
            setHasError(err.message);
        } finally {
            setIsLoading(false);
        }

        return null;
    };

    return {
        patchAll, hasError, isLoading, data,
    };
};

export default usePost;
