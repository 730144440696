/**
 * Created by lpostula on 11/05/17.
 */
import {
    createSelector,
} from 'reselect';

export const getLabelCategories = (state) => state.label_categories;
const getLabels = (state) => state.labels;
const getUserCompany = (state) => {
    // not null nor undefined
    if (state.users.me) {
        return state.users.me.company;
    }
    return null;
};

function sort_by_name(a, b) {
    const sa = a.name ? a.name.toLowerCase() : null;
    const sb = b.name ? b.name.toLowerCase() : null;
    return -(sa < sb) || +(sa !== sb);
}

function sort_by_order(a, b) {
    return a.display_order - b.display_order;
}

export const getLabelCategoriesWithLabels = createSelector(
    [getLabelCategories, getLabels],
    (label_categories, labels) => {
        const out = {};
        for (const category_id in label_categories) {
            const category = { ...label_categories[category_id] };
            const category_labels = [];
            for (const label_id of category.label_set) {
                const child = labels[label_id];
                // this should not happen in normal circumstances
                // however it may be that categories are updated before list of labels
                if (child) {
                    category_labels.push(child);
                }
            }
            // sort labels within a category to give them a rank
            category_labels.sort(sort_by_name);

            for (let i = 0, end = category_labels.length; i < end; i += 1) {
                if (category_labels[i] !== undefined && category_labels[i] !== null) {
                    category_labels[i].rank = i;
                }
            }
            category.label_set = category_labels;

            out[category_id] = category;
        }
        return out;
    },
);

export const getLabelCategoriesForCurrentUser = createSelector(
    [getLabelCategoriesWithLabels, getUserCompany],
    (label_categories, user_company) => _.pick(
        label_categories, (category) => category.company === user_company,
    ),
);

export const getOperationalLabelCategories = createSelector(
    [getLabelCategoriesWithLabels],
    (label_categories) => _.pick(label_categories, (category) => category.is_operational),
);

export const getOperationalLabelCategoriesForCurrentUser = createSelector(
    [getLabelCategoriesForCurrentUser],
    (label_categories) => _.pick(label_categories, (category) => category.is_operational),
);

export const getOperationalLabelCategoriesForCurrentUserList = createSelector(
    [getOperationalLabelCategoriesForCurrentUser],
    (label_categories) => _.values(label_categories).sort(sort_by_order),
);

export default {
    getLabelCategories,
    getLabelCategoriesWithLabels,
    getLabelCategoriesForCurrentUser,
    getOperationalLabelCategories,
    getOperationalLabelCategoriesForCurrentUser,
    getOperationalLabelCategoriesForCurrentUserList,
};
