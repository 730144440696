/**
 * Created by gknuts on 19/09/21.
 */
/*
This hook allow us to write Routes like we used to with React-Router V3, but with React-Router V6.

This hook support:
- Nested Route
- Permissions check
- Optional parameters
- Redirect
- Optional component, rendering parent component
- Partial path, concatenating path with parents path

How to write the route configuration ?
A Route can contains these properties:
- redirect_to: tell to the browser to rewrite the url with the given value.
- path: tell to the browser when he meet this url, he should render the given component.
- component: tell to the browser which component he must render.
- permissions: check if the connected user is allow to render the component.
- children: an array of route, each children will know the parent's component, path and permissions, if none of them
is given to the child, the parent's one will be used, for the path, a full path is created based on parent's path and
child's path. all the permissions given to a parent are checked in each children.

For example if the parent's path is /foo and the child's path is /bar, the full path of the child will be /foo/bar.

Optional path are given with parenthesis, values are transmitted to the component with the ':'.
/log/:action(/type/:type) mean this path can either be
/log/:action
or
/log/:action/type/:type (this can be translated in the browser like this /log/new/type/corrective,
and the component will know that the param "type" is "corrective" and the param "action" is "new")

Here's an example of routeConfiguration:
const routeConfiguration = [
    {
        path: '/',
        component: Logbook,
        permissions: ['page_fleet_journal'],
        children: [
            {
                path: '/log/:action(/type/:type)(/many/:many)(/:v2)',
                children: [
                    {
                        path: '/id/:id', // <- this path in reality is parent's path + this path
                        // there's nos specified component, it means that the parent's component will be used,
                        // if the parent has no component either, the upper parent's component will be used and so on.
                    },
                ],
            },
        ],
    },
];

this hook is necessary because with React-Router V6, nested children's path can no longer be written like before,
it must contains the full path since it's first parent.
it does not longer support optional parameters, neither just giving the component to render and finally neither
redirection.

"nested children's path can no longer be written like before, it must contains the full path since it's first parent,
it does not longer support optional parameters"
this hook allow us to do it by generating every possible path with optional parameters
for example, if the parent's path is /foo(/bar) and the child's path is /baz, it will create 2 route with these path:
- /foo/baz
- /foo/bar/baz

"neither just giving the component to render"
this hook allow us to do it by creating a React element with the given component, allowing us to encapsulate it
inside an other component that check the given permissions, and to inject some props inside, like for example
the params and the navigate method that replace props.router.push.

"neither redirection"
this hook allow us to do it by checking if the route contains this props, if it's there, instead of rendering the given
React component, it will render an other component that trigger a navigate (to rewrite the url).
*/

import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { usersSelectors } from 'railfleet_state/ducks/users';
import { useSelector } from 'react-redux';
import {
    useNavigate,
    useParams,
    useRoutes,
    useLocation,
    Outlet,
    useOutlet,
    generatePath,
} from 'react-router-dom';

const { pathToRegexp } = require('path-to-regexp');

/** ************************************************************************************
 * PermissionDenied, component displayed when the user does not have one of the given permissions.
 * @returns Component
 */
const PermissionDenied = () => (
    <div>
        {i18n.t('Permission denied')}
    </div>
);

/** ************************************************************************************
 * InjectProps, return the given component with navigate and params added in its props.
 * @param props
 * @returns Component
 */
const InjectProps = (props) => {
    const getQuery = (search) => {
        const ret = {};
        if (search && search.includes('?')) {
            const location_search = search.slice(1);
            const elms = location_search.split('&');
            elms.forEach((elm) => {
                const elm_split = elm.split('=');
                ret[elm_split[0]] = elm_split[1] || null;
            });
        }
        return ret;
    };

    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    const setQuery = (search) => {
        let newUrl = location.pathname;
        if (search) {
            newUrl = generatePath(':url?:queryString', {
                url: location.pathname,
                queryString: search,
            });
        }
        navigate(newUrl);
    };

    const query = getQuery(location.search);
    const outlet = useOutlet();
    return React.createElement(props.component, {
        ...props.props_to_inject,
        router: {
            push: navigate,
            params,
            location: {
                ...location, query, setQuery,
            },
            goBack: () => navigate(-1),
            goForward: () => navigate(1),
        },
        params,
        setParams: () => null,
        location: {
            ...location, query, setQuery,
        },
    }, outlet);
};

// To be used by withNavigate
export {
    InjectProps,
};

/** ************************************************************************************
 * RedirectTo, a component that rewrite the url with the given path
 * @param path
 */
const RedirectTo = ({
    redirect_to,
    component,
    configuration,
    everyPaths,
    fullPath,
    props_to_inject,
}) => {
    const shouldRedirect = (every_paths, current_location, routePath) => {
        for (const path of every_paths) {
            const re = pathToRegexp(path);
            const match = current_location.match(re);
            if (match) {
                return path === routePath;
            }
        }
        return false;
    };
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        const redirect = shouldRedirect(everyPaths, location.pathname, fullPath);
        if (redirect_to && redirect) {
            navigate(redirect_to);
        }
    }, [redirect_to]);
    return component ? React.createElement(InjectProps, { component, configuration, props_to_inject }) : React.createElement(Outlet);
};

const useRouteConfiguration = (routeConfiguration = [], to_inject = null) => {
    const userHasPerm = useSelector((state) => usersSelectors.userHasPerm(state));
    const [_routes, setRoutes] = useState([]);
    const _elements = useRoutes(_routes);

    /** ************************************************************************************
     * generateCombinations, used by getPaths to generate the combinations to generate the combinations
     * of optional params.
     * @param input
     * @param j
     * @returns [] -> an array of array with every combinations.
     */
    const generateCombinations = (input, j = 0) => {
        const output = [input];

        input.forEach((elm, i) => {
            const reminder = [...input.slice(0, i), null, ...input.slice(i + 1, input.length)];
            if (_.some(reminder) && !_.isEqual(input, reminder) && i >= j) {
                const temp = generateCombinations(reminder, i);
                output.push(...temp);
            }
        });
        return output;
    };

    /** ************************************************************************************
     * purgeDuplicated
     * @param input
     * @returns [] -> return an array without any duplicates.
     */
    const purgeDuplicated = (input) => {
        const output = [];
        const outputStr = [];
        for (const value of input) {
            if (!outputStr.includes(value.join())) {
                output.push(value);
                outputStr.push(value.join());
            }
        }
        return output;
    };

    /** ************************************************************************************
     * getPaths, used by optionalPath to generate the combinations of optional params, add a combination with all
     * optional parameters to null, and remove the duplicates.
     * @param path
     * @returns [] => an array with every possible combination of optional params
     */
    const getPaths = (path) => {
        const paths = generateCombinations(path);
        const nullMatrix = _.map(path, () => null);
        paths.push(nullMatrix);
        return purgeDuplicated(paths.sort());
    };

    /** ************************************************************************************
     * optionalPath, used by getFullPaths to generate the paths
     * @param path
     * @returns [] => an array that contains every path with every optional params.
     */
    const optionalPath = (path) => {
        const matchAll = (str, re) => {
            // c/c from here:
            // https://gist.github.com/TheBrenny/039add509c87a3143b9c077f76aa550b
            let rx = re;
            if (typeof rx === 'string') rx = new RegExp(rx, 'g'); // coerce a string to be a global regex
            rx = new RegExp(rx); // Clone the regex so we don't update the last index on the regex they pass us
            let cap = []; // the single capture
            const all = []; // all the captures (return this)
            while (cap !== null) {
                cap = rx.exec(str);
                if (cap) {
                    all.push(cap); // execute and add
                }
            }
            return all;
        };

        let i;
        const getPathFilled = (_path, paths) => {
            const replacer = (elm) => {
                i += 1;
                return elm[i] ? `/${elm[i]}` : '';
            };
            const escapeRE = new RegExp(/(\()(.*?)(\))/g);
            const matches = [];
            for (const elm of paths) {
                i = -1;
                const match = _path.replace(escapeRE, () => replacer(elm));
                matches.push(match);
            }
            return matches;
        };
        // const escapeRE2 = new RegExp(/(?<=\(\/)(.*?)(?=\))/g);
        const escapeRE2 = new RegExp(/(?:\(\/)(.*?)(?=\))/g);
        const matches = matchAll(path, escapeRE2);
        const matched_paths = [];
        for (const match_path of matches) {
            matched_paths.push(match_path[0].substring(2));
        }
        const paths = getPaths(matched_paths);
        return getPathFilled(path, paths);
    };

    /** ************************************************************************************
     * getFullPaths
     * @param configuration
     * @param parents
     * @returns an array with every possible path with every optional path combination
     */
    const getFullPaths = (configuration, parents = []) => {
        const fullPath = [];
        if (parents.length > 0) {
            for (const parent of parents) {
                if (parent.path === '/') continue;
                fullPath.push(parent.path);
            }
            fullPath.push(configuration.path);
        }

        const currentPath = !_.isEmpty(fullPath) ? fullPath.join('') : configuration.path;

        if (currentPath !== '/' && currentPath.includes('(/')) {
            return optionalPath(currentPath);
        }

        return [currentPath];
    };

    const removeEmptyChildren = (configuration, parents = []) => {
        const newConfigurations = [];
        const newConfiguration = { ...configuration };
        newConfiguration.children = [];
        const fullPath = [];
        let comp;
        if (configuration.component === undefined) {
            if (parents.length > 0) {
                for (const parent of [...parents].reverse()) {
                    if (parent.path === '/') continue;
                    fullPath.push(parent.path);
                    if (parent.component !== undefined) {
                        comp = parent.component;
                        break;
                    }
                }
            }
            fullPath.push(configuration.path);
            newConfiguration.path = fullPath.join('');
            newConfiguration.component = comp;
            newConfiguration.isChildren = true;
        }

        if (!configuration.children || _.isEmpty(configuration.children)) {
            newConfigurations.push(newConfiguration);
        } else {
            const exluded = [];
            for (const children of configuration.children) {
                const sub_config = removeEmptyChildren(children, [...parents, configuration]);
                for (const sub of sub_config) {
                    if (sub.isChildren) {
                        exluded.push(sub);
                    } else {
                        newConfiguration.children.push(sub);
                    }
                }
            }
            newConfigurations.push(newConfiguration);
            for (const exc of exluded) {
                newConfigurations.push(_.omit(exc, 'isChildren'));
            }
        }
        return newConfigurations;
    };

    const removeEmptyChildrens = (configurations) => {
        const newConfigurations = [];

        for (const configuration of configurations) {
            const sub_config = removeEmptyChildren(configuration);
            newConfigurations.push(...sub_config);
        }
        return newConfigurations;
    };

    /** ************************************************************************************
     * getPath
     * @param configuration
     * @param parents
     * @returns fullPath => a string that either return the path if its a root route, or the concatenation of
     * path for every parents if it's a children.
     */
    const getPath = (configuration, parents = []) => {
        const fullPath = [];
        if (parents.length > 0) {
            for (const parent of parents) {
                if (parent.path === '/') continue;
                fullPath.push(parent.path);
            }
        }
        fullPath.push(configuration.path);
        return fullPath.join('');
    };

    /** ************************************************************************************
     * getPermissions
     * @param configuration
     * @param parents
     * @returns cumulatedPermissions => an array of permissions, contains every permission asked by the parents and
     * the given route.
     */
    const getPermissions = (configuration, parents = []) => {
        const mergeArrays = (a, b) => {
            if (b) {
                return [...a, b];
            }
            return a;
        };
        const cumulatedPermissions = [];
        if (parents.length > 0) {
            for (const parent of parents) {
                if (parent.permissions) {
                    cumulatedPermissions.push(...parent.permissions);
                }
            }
        }
        return mergeArrays(cumulatedPermissions, configuration.permissions);
    };

    /** ************************************************************************************
     * getComponent
     * @param configuration
     * @param parents
     * @returns Component => either the specified component, if none, look after a component in parents
     */
    const getComponent = (configuration, parents = []) => {
        const component = configuration.component;
        if (!component && !_.isEmpty(parents)) {
            for (const parent of [...parents].reverse()) {
                if (parent.component) return parent.component;
            }
        }
        return component;
    };

    /** ************************************************************************************
     * getEveryPaths -> compute every path possible by concatening paths from children
     * and optional params.
     * used by RedirectTo, to decide if we need to redirect the browser or not.
     * because for example, when we are here: /dashboard/asset/9067/events
     * we don't want the route '/' to redirect us to the dashboard.
     * @param configuration
     * @returns [] > every path possible from the root
     */

    const getEveryPaths = (configuration) => {
        const getChildrenPaths = (_configuration, parents = []) => {
            const paths = [];
            if (Array.isArray(_configuration)) {
                for (const config of _configuration) {
                    const fullPath = getPath(config, parents);
                    paths.push(fullPath);
                    if (config.children) {
                        for (const child of config.children) {
                            const part_paths = getChildrenPaths(child, [...parents, config]);
                            paths.push(...part_paths);
                        }
                    }
                }
            } else {
                const fullPath = getPath(_configuration, parents);
                paths.push(fullPath);
                if (_configuration.children) {
                    for (const child of _configuration.children) {
                        const part_paths = getChildrenPaths(child, [...parents, _configuration]);
                        paths.push(...part_paths);
                    }
                }
            }
            return paths;
        };
        const paths = getChildrenPaths(configuration);
        const optionalPaths = [];
        for (const path of paths) {
            const res = optionalPath(path);
            optionalPaths.push(...res);
        }
        return optionalPaths.reverse();
    };

    /** ************************************************************************************
     * getRoutes
     * @param configurations
     * @param parents
     * @returns routes => an array of Route used by useRoute for every route given in the routeConfiguration
     */
    const getRoutes = (configurations, everyPaths, props_to_inject, parents = []) => {
        const routes = [];
        for (const configuration of configurations) {
            const paths = getFullPaths(configuration);
            for (const path of paths) {
                // eslint-disable-next-line no-use-before-define
                const route = getRoute({ ...configuration, path }, parents, everyPaths, props_to_inject);
                routes.push(route);
            }
        }
        return routes;
    };

    /** **************************************************************************************
     * getRoute
     * @param configuration
     * @param parents
     * @returns newConfiguration => an array of Route for a given route configuration formatted for v6
     */
    const getRoute = (configuration, parents, everyPaths, props_to_inject) => {
        const newConfiguration = {};
        const path = getPath(configuration, parents);
        const permissions = getPermissions(configuration, parents);
        const component = getComponent(configuration, parents);
        const hasPerm = !permissions || _.isEmpty(permissions) ? true : userHasPerm(permissions);
        newConfiguration.path = path;
        if (!hasPerm) {
            newConfiguration.element = React.createElement(PermissionDenied);
        } else {
            newConfiguration.element = React.createElement(InjectProps, { component, configuration, props_to_inject });
        }
        if (configuration.children) newConfiguration.children = getRoutes(configuration.children, everyPaths, props_to_inject, [...parents, configuration]);
        if (configuration.redirect_to) {
            newConfiguration.element = React.createElement(RedirectTo, {
                redirect_to: configuration.redirect_to,
                component,
                configuration,
                everyPaths,
                fullPath: path,
                props_to_inject,
            });
        }
        return newConfiguration;
    };

    useEffect(() => {
        const formated_route = removeEmptyChildrens(routeConfiguration);
        const everyPaths = getEveryPaths(formated_route);
        const routes = getRoutes(formated_route, everyPaths, to_inject);
        setRoutes(routes);
    }, [routeConfiguration]);

    return _elements;
};

export default useRouteConfiguration;
