/**
 * Created by lpostula on 11/05/17.
 */
import { default as actions } from './actions';

export const { fetchList } = actions;

export default {
    fetchList,
};
