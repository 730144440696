/**
 * Created by lpostula on 26/10/17.
 */
import { default as actions } from './actions';
import {
    getActiveSnackBar,
    getQueuedSnackBar,
} from './selectors';

const SNACKBAR_DURATION = 5000;
const MAX_ACTIVE_SNACKBAR = 3;

const addAndTimeRemoveSnack = (snack) => (dispatch) => {
    dispatch(actions.addSnackbar(snack));
    const duration = snack.duration || SNACKBAR_DURATION;
    setTimeout(() => {
        // eslint-disable-next-line no-use-before-define
        dispatch(removeSnackBar(snack));
    }, duration);
};

export const createSnackBar = (snack) => (dispatch, getState) => {
    if (!snack.id) return;
    const state = getState();
    const active = getActiveSnackBar(state);
    const queued = getQueuedSnackBar(state);
    if (active.length < MAX_ACTIVE_SNACKBAR && queued.length === 0) {
        dispatch(addAndTimeRemoveSnack(snack));
    } else {
        dispatch(actions.queueSnackbar(snack));
    }
};

export const removeSnackBar = (snack) => (dispatch, getState) => {
    const state = getState();
    dispatch(actions.removeSnackbar(snack));
    const queued = getQueuedSnackBar(state);
    if (!queued.length) return;
    const new_snack = queued[0];
    dispatch(actions.unqueueSnackbar(new_snack));
    dispatch(addAndTimeRemoveSnack(new_snack));
};

export const incomingWS = (data) => (dispatch) => {
    switch (data.type) {
    case 'reload_page':
        dispatch(actions.reloadPage(data.payload));
        break;
    default:
        break;
    }
};

const { selectCounterReadingAsset } = actions;
const { dismissReloadPage } = actions;

export default {
    createSnackBar,
    removeSnackBar,
    incomingWS,
    selectCounterReadingAsset,
    dismissReloadPage,
};
