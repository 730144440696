/**
 * Created by lpostula on 12/05/17.
 */
import { createSelector } from 'reselect';

const getAlerts = (state) => state.alerts;

const getAlertsForAsset = (state, asset_id) => _.filter(state.alerts, (a) => a.asset === asset_id);

const getAlertsByIds = (state, alerts_ids) => _.pick(state.alerts, alerts_ids);

export const makeAlertsForAsset = () => createSelector(
    [getAlertsForAsset],
    (alerts) => alerts,
);

export const makeAlertsListForAsset = () => {
    const alerts_for_asset = makeAlertsForAsset();
    return createSelector(
        [alerts_for_asset],
        (alerts) => _.values(alerts),
    );
};

export default {
    getAlerts,
    makeAlertsForAsset,
    makeAlertsListForAsset,
    getAlertsByIds,
};
