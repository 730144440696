import React from 'react';
import i18n from 'i18next';
import ReactDOM from 'react-dom';
import railfleet from 'railfleet';
import { components } from 'react-select';
import { prettyDate } from 'railfleet/utils/prettyDate';
import './procedures.css';
import { HashRouter } from 'react-router-dom';
import RoutesWithRouterContext from '../../../../railfleet/railfleet/lib/components/app/routesWithRouterContext';

const { Alert } = railfleet.components.alerts;
const { Button } = railfleet.components.buttons;
const { DataGrid } = railfleet.components.tables.DataGrid;

const { Selector } = railfleet.components.selectors;

const { ApplicationManager } = railfleet.utils;

const renderProcedureSelectOption = (props) => (
    <components.Option {...props}>
        <span>
            <strong>
                { props.data.label }
            </strong>
            { ` - ${props.data.description}` }
        </span>
    </components.Option>
);

class ProcedureForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            possibleChoicesArgs: {},
            possibleInputStrArgs: {},
            selectedTemplate: null,
            selectedTemplateArgs: {},
        };

        this.launchProcedure = this.launchProcedure.bind(this);
        this.onChangeTemplateChoicesArg = this.onChangeTemplateChoicesArg.bind(this);
        this.onChangeTemplate = this.onChangeTemplate.bind(this);
    }

    isFormValid() {
        // need a target and a base template
        if ((!this.props.selectedAsset && !this.props.serial) || !this.state.selectedTemplate) {
            return false;
        }

        const names = [].concat(
            Object.keys(this.state.possibleInputStrArgs),
            Object.keys(this.state.possibleChoicesArgs),
        );

        // check if we have valid template args
        return names.every((name, index, array) => {
            const val = this.state.selectedTemplateArgs[name];
            return (val !== null && val !== undefined && (typeof val !== 'string' || val !== ''));
        });
    }

    launchProcedure(e) {
        e.preventDefault();

        if (!this.state.selectedTemplate) {
            this.setState(
                {
                    error: (
                        <Alert type="warning">
                            Please, select a procedure.
                        </Alert>
                    ),
                },
            );
            return;
        }

        for (const name in this.state.possibleChoicesArgs) {
            const val = this.state.selectedTemplateArgs[name];
            if (val === null || val === undefined || (typeof val === 'string' && val === '')) {
                this.setState(
                    {
                        error: (
                            <Alert type="warning">
                                { `Missing template argument '${name}' for procedure '${this.state.selectedTemplate}'` }
                            </Alert>
                        ),
                    },
                );
                return;
            }
        }

        this.setState({ error: (<Alert type="info">Launching procedure...</Alert>) });

        const args = {
            asset: this.props.selectedAsset,
            template_name: this.state.selectedTemplate,
            args: this.state.selectedTemplateArgs,
        };
        if (this.props.serial) {
            args.railster = this.props.serial;
        }
        $.post('/procedures/procedure/', args).fail((ee) => this.setState(
            {
                error: (
                    <Alert type="danger">
                        { `'Error creating procedure: ${ee.status} ${ee.statusText}` }
                    </Alert>
                ),
            },
        )).done((res) => {
            this.setState(
                {
                    error: (
                        <Alert type="success">
                            Procedure successfully launched!
                        </Alert>
                    ),
                },
            );
            if (this.props.onNewProcedure) {
                this.props.onNewProcedure(res);
            }
        });
    }

    onChangeTemplateChoicesArg(arg) {
        const val = (arg && arg[0]) || null;
        const new_state = { ...this.state.selectedTemplateArgs };
        new_state[val.arg_name] = val.value;
        this.setState({ selectedTemplateArgs: new_state });
    }

    onChangeTemplate(arg) {
        const val = (arg && arg[0]) || null;
        this.setState({
            selectedTemplate: val,
            selectedTemplateArgs: {},
        });
        if (val != null) {
            $.getJSON(`/procedures/template_args.json?template=${val}`, (res) => {
                const new_state = {};
                if (res.choices != null) {
                    new_state.possibleChoicesArgs = res.choices;
                }
                if (res.inputs_str != null) {
                    new_state.possibleInputStrArgs = res.inputs_str;
                }
                this.setState(new_state);
            });
        }
    }

    render() {
        const styleRow = {
            flexGrow: 1,
            padding: 5,
        };

        const styleWrap = {
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
            marginRight: 10,
            marginLeft: 0,
            marginTop: 0,
            marginBottom: 10,
        };

        const styleWrapChild = {
            flex: '45%',
            marginLeft: 3,
            marginRight: 3,
            marginTop: 5,
            marginBottom: 5,
        };

        const styleWrapChildTextbox = {
            marginLeft: 3,
            marginRight: 3,
            marginTop: 5,
            marginBottom: 5,
        };

        return (
            <form style={{ display: 'flex', flex: '2 1 100%' }}>
                <input type="hidden" id="railster" value={this.props.serial || ''} />
                <input type="hidden" id="asset" value={this.props.selectedAsset || ''} />
                <div>{ this.state.error ? this.state.error : null }</div>

                { this.props.assetsOptions ? (
                    <div style={styleRow}>
                        <div>
                            <label>
                                {' '}
                                { i18n.t('Asset') }
                                {' '}
                            </label>
                            <Selector
                                name="asset_id"
                                values={this.props.assetsOptions}
                                onChange={this.props.onChangeAsset}
                                selected={this.props.selectedAsset}
                                id_getter={(elm) => elm.value}
                                // virtual
                            />
                        </div>
                    </div>
                ) : null}

                <div style={styleRow}>
                    <label>{ i18n.t('Remote action') }</label>

                    <Selector
                        values={this.props.templates}
                        onChange={this.onChangeTemplate}
                        selected={this.state.selectedTemplate}
                        components={{ Option: renderProcedureSelectOption }}
                        id_getter={(elm) => elm.value}
                    />

                    <div style={styleWrap}>
                        {
                            Object.keys(this.state.possibleChoicesArgs).map((name) => {
                                const choices = this.state.possibleChoicesArgs[name];
                                return (
                                    <div style={styleWrapChild} key={name}>
                                        <label>{name}</label>
                                        <Selector
                                            values={choices.map((c) => ({
                                                label: `${c}`,
                                                value: c,
                                                arg_name: name,
                                            }))}
                                            onChange={this.onChangeTemplateChoicesArg}
                                            selected={this.state.selectedTemplateArgs[name]}
                                            id_getter={(elm) => elm.value}
                                            response_template={['value', 'arg_name']}
                                            sort_func={(a, b) => 0} // Keep the Python order
                                        />
                                    </div>
                                );
                            })
                        }
                        {
                            Object.keys(this.state.possibleInputStrArgs).map((name) => {
                                const validator = this.state.possibleInputStrArgs[name];
                                return (
                                    <div style={styleWrapChild} key={name}>
                                        <label>{name}</label>
                                        <ProcedureTextbox
                                            validator={validator}
                                            onValidation={(is_valid, value) => {
                                                const new_state = { ...this.state.selectedTemplateArgs };
                                                if (is_valid) {
                                                    if (value === '') {
                                                        new_state[name] = ' ';
                                                    } else {
                                                        new_state[name] = value;
                                                    }
                                                } else {
                                                    new_state[name] = null;
                                                }
                                                this.setState({ selectedTemplateArgs: new_state });
                                            }}
                                        />
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>

                <div style={{ marginTop: 'auto', paddingBottom: 15 }}>
                    <Button
                        primary
                        large
                        onClick={this.launchProcedure}
                        disabled={!this.isFormValid()}
                        label={i18n.t('Start')}
                    />
                </div>

            </form>
        );
    }
}

ProcedureForm.defaultProps = {
    serial: null, // required
    templates: [],
};

// todo merge list and listdate together
const ListRenderer = (cellData) => (
    <div>
        <ul>
            {
                Array.from(cellData).map((items, index) => (
                    // eslint-disable-next-line
                    <li key={index}>
                        { items.join(' ') }
                    </li>
                ))
            }
        </ul>
    </div>
);

const DateRenderer = (cellData) => prettyDate(cellData);

const ListDateRenderer = (cellData) => (
    <div>
        <ul>
            {
                Array.from(cellData).map((item, index) => (
                    // eslint-disable-next-line
                    <li key={index}>
                        { `${prettyDate(item[0])} ${item[1]}`}
                    </li>
                ))
            }
        </ul>
    </div>
);

const DictRenderer = (cellData) => (
    <div>
        <ul>
            {
                Object.keys(cellData).map((k) => {
                    const v = cellData[k];
                    return (
                        <li key={k}>
                            <b>
                                { `${k} ${v}` }
                            </b>
                        </li>
                    );
                })
            }
        </ul>
    </div>
);

const ProcedureDetail = (procedure) => (
    <ul className="list-group">
        <li className="list-group-item">
            <dt>id</dt>
            <dd>{procedure.id}</dd>
        </li>
        <li className="list-group-item">
            <dt>name</dt>
            <dd>{procedure.name}</dd>
        </li>
        <li className="list-group-item">
            <dt>Start date</dt>
            <dd>{procedure.start_date}</dd>
        </li>
        <li className="list-group-item">
            <dt>User</dt>
            <dd>{procedure.user}</dd>
        </li>
        <li className="list-group-item">
            <dt>todo</dt>
            <dd>{ ListRenderer(procedure.todo) }</dd>
        </li>
        <li className="list-group-item">
            <dt>logs</dt>
            { ListDateRenderer(procedure.logs) }
        </li>
        <li className="list-group-item">
            <dt>data</dt>
            <dd>{ DictRenderer(procedure.data) }</dd>
        </li>
    </ul>
);

class ProcedureTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: null,
            history: [],
            height: window.innerHeight - 270,
        };
        this.timeoutID = null;
        this.cancel = this.cancel.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.loop = this.loop.bind(this);
    }

    cancel(procedure) {
        $.ajax({
            url: `/procedures/procedure/${procedure.id}/`,
            type: 'POST',
            data: {
                alive: false,
            },
        }).fail((e) => {
            console.log('error saving procedure modification');
            console.log(e);
            return (
                <Alert>
                    An error occured please see the console log for more detail
                </Alert>
            );
        });

        // find the line in the orignal state to modify it
        const { history } = this.state;
        const i = _.findIndex(history, (r) => r.id === procedure.id);
        history[i].alive = false; this.setState(history);
    }

    loadData() {
        let args;
        if (this.props.serial) {
            args = { railster_serial: this.props.serial };
        } else if (this.props.selectedAsset) {
            args = { asset_id: this.props.selectedAsset };
        } else {
            args = {};
        }
        const url = '/procedures/procedure.json';

        window.clearTimeout(this.timeoutID);
        $.getJSON(url, args, (data) => {
            for (const r of Array.from(data)) {
                r.cancel = this.cancel;
            }
            this.setState({ history: data });
        }).always(() => { this.timeoutID = setTimeout(this.loop, 5000); });
    }

    loop() {
        if (!(Cookies.get('no_refresh')) || (Cookies.get('no_refresh') === '0')) {
            this.loadData();
        } else {
            window.clearTimeout(this.timeoutID);
            this.timeoutID = setTimeout(this.loop, 5000);
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.loadData();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
        this.setState({ height: window.innerHeight - 270 });
    }

    handleCancel(procedure) {
        if (window.confirm('Are you sure you want to cancel this procedure')) {
            this.cancel(procedure);
        }
    }

    render() {
        if (this.state.selectedItem) {
            return (
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <a onClick={() => this.setState({ selectedItem: null })} style={{ padding: 5 }}>
                        <span className="glyphicon glyphicon-arrow-left" style={{ marginRight: 5 }} />
                        Back to list
                    </a>
                    <div style={{ overflowY: 'auto' }}>{ProcedureDetail(this.state.selectedItem)}</div>
                </div>
            );
        }
        const cols = [{
            dataKey: 'id',
            width: 15,
            cellRenderer: (cellData, rowData) => (
                <a onClick={() => this.setState({ selectedItem: rowData })}>{cellData}</a>
            ),
        }, {
            dataKey: 'device',
            label: 'Railster',
            width: 15,
            // cellRenderer: (cellData, rowData) => (
            //     <a onClick={() => this.setState({ selectedItem: rowData })}>{cellData}</a>
        }, {
            dataKey: 'asset',
            label: 'Asset',
            width: 40,
        }, {
            dataKey: 'name',
            width: 40,
            cellRenderer: (cellData, rowData) => (
                <a onClick={() => this.setState({ selectedItem: rowData })}>{cellData}</a>
            ),
        }, {
            dataKey: 'start_date',
            label: 'Start date',
            width: 40,
            cellRenderer: DateRenderer,
        },
        {
            dataKey: 'user',
            label: 'User',
            width: 40,
        }, {
            dataKey: 'alive',
            label: 'Status',
            filterActive: false,
            sortActive: false,
            width: 15,
            cellRenderer: (cellData, rowData) => {
                const actual = rowData.logs.length;
                const total = rowData.todo.length + rowData.logs.length;
                const status_class = (actual < total) ? 'label label-warning' : 'label label-success';
                // <span class="label label-default">Default</span>
                // <span class="label label-primary">Primary</span>
                // <span class="label label-success">Success</span>
                // <span class="label label-info">Info</span>
                // <span class="label label-warning">Warning</span>
                // <span class="label label-danger">Danger</span>
                return (<span><span className={status_class}>{`${actual}/${total}`}</span></span>);
            },
        }, {
            dataKey: 'action',
            width: 20,
            filterActive: false,
            sortActive: false,
            cellRenderer: (cellData, rowData) => {
                if (rowData.alive) {
                    return (
                        <button
                            className="btn btn-danger"
                            style={{ fontSize: 10, paddingTop: 1, paddingBottom: 1 }}
                            onClick={() => this.handleCancel(rowData)}
                        >
                            Cancel
                        </button>
                    );
                }
                return null;
            },
        }];

        return (
            <DataGrid
                storageKey="proceduresList"
                showFooter={false}
                items={this.state.history}
                columns={cols}
            />
        );
    }
}

ProcedureTable.defaultProps = {
    serial: null,
    asset: null,
};

class ProcedureHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: [],
        };
    }

    componentDidMount() {
        const url = '/procedures/procedure_templates.json';
        $.getJSON(url, null, (data) => {
            this.setState({ templates: data });
        });
    }

    render() {
        return (
            <div className="panel panel-primary">
                <div className="panel-heading">{ i18n.t('Remote actions') }</div>
                <div className="panel-body">
                    <ProcedureForm
                        serial={this.props.serial}
                        templates={this.state.templates}
                    />
                </div>
                <div style={{ height: 400, paddingRight: 1, overflow: 'hidden' }}>
                    <ProcedureTable
                        serial={this.props.serial}
                    />
                </div>
            </div>
        );
    }
}

ProcedureHistory.defaultProps = {
    // only display log for one serial if passed
    serial: null,
};

class RPCPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: [],
            assetsOptions: [],
            assetsSerials: {},
            selectedAsset: null,
        };
        this.onChangeAsset = this.onChangeAsset.bind(this);
    }

    componentDidMount() {
        const url = '/procedures/procedure_templates.json';
        $.getJSON(url, null, (data) => {
            this.setState({ templates: data });
        });

        $.getJSON('/procedures/assets_for_rpc.json', (res) => {
            const assetsOptions = [];
            const assetsSerials = [];
            for (const a of res) {
                assetsSerials[a.asset_id] = a.device_id;
                if (a.device_id) {
                    assetsOptions.push({ value: a.asset_id, label: `${a.asset_name} - railster ${a.device_id}` });
                } else {
                    assetsOptions.push({ value: a.asset_id, label: a.asset_name });
                }
            }
            this.setState({ assetsOptions, assetsSerials });
        });
    }

    onChangeAsset(arg) {
        console.log(arg);
        const id = (arg && arg[0]) || null;
        this.setState({ selectedAsset: id });
    }

    render() {
        const device_id = this.state.assetsSerials[this.state.selectedAsset];
        return (
            <div style={{
                display: 'flex', flexDirection: 'column', width: '100%', height: '100%',
            }}
            >
                <div className="rpc_box" style={{ flex: 0 }}>
                    <div className="rpc_title">{ i18n.t('Remote actions') }</div>
                    <ProcedureForm
                        serial={device_id}
                        selectedAsset={this.state.selectedAsset}
                        templates={this.state.templates}
                        assetsOptions={this.state.assetsOptions}
                        onChangeAsset={this.onChangeAsset}

                    />
                </div>
                <div className="rpc_box" style={{ flex: 1 }}>
                    <ProcedureTable
                        serial={device_id}
                        asset={this.state.selectedAsset}
                    />
                </div>
            </div>
        );
    }
}

class ProcedureTextbox extends React.Component {
    constructor(props) {
        super(props);

        const val = props.validator;
        let validator = (_) => true;

        if (
            val !== null
            && val !== undefined
            && typeof val === 'string' && val !== ''
        ) {
            const regex = new RegExp(`${ val }`);
            validator = (text) => regex.test(text);
        }

        this.state = {
            text: '',
            is_valid: true,
            validator,
            validate_cb: props.onValidation,
        };

        this.validateText = this.validateText.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
    }

    componentDidMount() {
        this.validateText();
    }

    validateText() {
        this.setState({ is_valid: this.state.validator(this.state.text) }, () => {
            if (
                this.state.validate_cb !== null
                && this.state.validate_cb !== undefined
            ) {
                this.state.validate_cb(this.state.is_valid, this.state.text);
            }
        });
    }

    onTextChange(event) {
        // Sanitization
        const str = event.target.value.replace(/[^a-zA-Z0-9_-]/gim, '').trim();
        if (str === this.state.text) return;
        this.setState({ text: str }, () => {
            this.validateText();
        });
    }

    render() {
        const styleInputValid = {
            padding: '7px 10px 7px 10px',
            borderRadius: '4px',
            border: 'solid 1px var(--Grey-6)',
            backgroundColor: '#ffffff',
            marginRight: 3,
            marginLeft: 3,
            width: '100%',
        };

        const styleInputInvalid = {
            ...styleInputValid,
            color: 'red',
            border: 'solid 1px red',
            outlineColor: 'red',
        };

        return (
            <input
                style={this.state.is_valid ? styleInputValid : styleInputInvalid}
                value={this.state.text}
                type="text"
                onChange={this.onTextChange}
            />
        );
    }
}

const routeConfiguration = [{
    path: '/',
    component: RPCPage,
}];

const router = (new_props) => (
    <HashRouter>
        <RoutesWithRouterContext
            routeConfiguration={routeConfiguration}
            to_inject={{ ...new_props }}
        />
    </HashRouter>
);

export function render(props) {
    ReactDOM.render(
        React.createElement(ApplicationManager(router, [], props)),
        document.getElementById('react_entry_point'),
    );
}

export default {
    ProcedureHistory,
    render,
};
