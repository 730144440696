/**
 * Created by lpostula on 12/05/17.
 */
import { createSelector } from 'reselect';
import config from './config';

const getLoading = (state) => state.loading;

const getSchedulingLoading = createSelector(
    [getLoading],
    (loading) => loading.maintenance_events_loading.scheduling,
);

const overideGeneratedExport = {
    getSchedulingLoading,
};

const getSelectorName = (key_name) => {
    let name = 'get';
    const splitted_keys = key_name.split('_');
    for (const key of splitted_keys) {
        name = name + key[0].toUpperCase() + key.substr(1);
    }
    return name;
};

const generateExport = (loading_config = {}, overide = {}) => {
    const exported_func = {
        getLoading,
    };
    for (const state_key in loading_config) {
        // first we need to create the selector name
        const name = getSelectorName(state_key);
        exported_func[name] = createSelector(
            [getLoading],
            (sub_state) => sub_state[state_key],
        );
    }
    for (const overide_key in overide) {
        exported_func[overide_key] = overide[overide_key];
    }
    return exported_func;
};

export const testExports = {
    getSelectorName,
    generateExport,
};

export default generateExport(config.loading, overideGeneratedExport);
