import React from 'react';

import './TableSortIndicator.scss';

const SORT_OPTIONS = {
    ASC: true,
    DESC: false,
};

const TableSortIndicator = (props) => (
    <div className="table-sort-indicator">
        <svg
            width="7"
            height="5"
            viewBox="0 0 7 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.5 0L7 5H0L3.5 0Z"
                fill={props.option === SORT_OPTIONS.DESC ? '#027AFF' : '#848EA0'}
                fillOpacity={props.option === SORT_OPTIONS.ASC ? '0.7' : '1'}
            />
        </svg>
        <svg
            width="7"
            height="5"
            viewBox="0 0 7 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.5 5L0 0L7 0L3.5 5Z"
                fill={props.option === SORT_OPTIONS.ASC ? '#027AFF' : '#848EA0'}
                fillOpacity={props.option === SORT_OPTIONS.DESC ? '0.7' : '1'}
            />
        </svg>
    </div>
);

export default TableSortIndicator;
export { SORT_OPTIONS };
