/**
 * Created by lpostula on 26/10/17.
 */
import {
    createSelector,
} from 'reselect';

import {
    getCurrentUserPage,
} from '../users/selectors';

const getUIState = (state) => state.ui;

export const getSelectedCounterReadingAsset = createSelector(
    [getUIState],
    (ui) => ui.counter_reading && ui.counter_reading.selected_asset,
);

const getCurrentProduct = createSelector(
    [getCurrentUserPage],
    (pages) => {
        const routing = {
            base_name: window.location.pathname,
            path: window.location.hash,
        };
        if (!pages) {
            return null;
        }
        const base_name = routing.base_name.split('/')[1];
        for (const product of pages) {
            if (!['railfleet', 'railgenius', 'railster'].includes(product.id)) {
                continue;
            }
            for (const page of product.pages) {
                if (page.routing === base_name || page.url.includes(base_name)) {
                    return product.id;
                }
            }
        }
        return null;
    },
);

export const getSnackBar = createSelector(
    [getUIState],
    (ui) => ui.snackbar,
);

export const getActiveSnackBar = createSelector(
    [getSnackBar],
    (snacks) => snacks.messages,
);

export const getQueuedSnackBar = createSelector(
    [getSnackBar],
    (snacks) => snacks.queue,
);

export const getReloadPageState = createSelector(
    [getUIState],
    (ui) => ui.reload_page,
);

export const isReloadPageActive = createSelector(
    [getReloadPageState],
    (r) => r.active,
);

export const isReloadPageForced = createSelector(
    [getReloadPageState],
    (r) => r.force,
);

export const getReloadPageMessage = createSelector(
    [getReloadPageState],
    (r) => r.message,
);

export default {
    getSelectedCounterReadingAsset,
    getSnackBar,
    getActiveSnackBar,
    getQueuedSnackBar,
    isReloadPageActive,
    isReloadPageForced,
    getReloadPageMessage,
    getCurrentProduct,
};
