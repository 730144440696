import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import moment from 'moment-timezone';
import components from 'components';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { companiesSelectors } from 'railfleet_state/ducks/companies';
import { locationsOperations, locationsSelectors } from 'railfleet_state/ducks/locations';
import { interventionsOperations, interventionsSelectors } from 'railfleet_state/ducks/interventions';
import { maintenanceEventsSelectors, maintenanceEventsOperations } from 'railfleet_state/ducks/maintenance_events';
import { uxOperations } from 'railfleet_state/ducks/ux';
import { assetClassesSelectors } from 'railfleet_state/ducks/asset_classes';
import './InterventionHistory.scss';
import { getCurrentStep } from '../../../../../dashboard/static/intervention/steps';
import withNavigate from '../../../components/app/withNavigate';
import useFetch from '../../../components/hooks/useFetch';
import { ContextDevice, withMultiDeviceLayout } from '../../../components/app/MultiDeviceLayout';

const { Table } = components.tables;
const { DetailFrame } = components.frames;
const { useArrayToDict } = components.hooks;

const InterventionStateTranslation = {
    unplanned: i18n.t('unplanned'),
    done: i18n.t('done'),
    transfer_out: i18n.t('transfer_out'),
    wait_out: i18n.t('wait_out'),
    started: i18n.t('started'),
    wait_in: i18n.t('wait_in'),
    transfer_in: i18n.t('transfer_in'),
    planned: i18n.t('planned'),
    cancelled: i18n.t('cancelled'),
};

const InterventionResultTranslation = {
    keep_open: i18n.t('Kept open'),
    closed: i18n.t('Closed'),
    cancelled: i18n.t('Cancelled'),
};

const InterventionHistory = (props) => {
    const [_data, setData] = useState([]);
    const { device } = useContext(ContextDevice);
    const [_dataClosed, setDataClosed] = useState([]);
    const [_workshops, setWorkshops] = useState(null);
    const workshops = useArrayToDict(_workshops, 'id');
    const { fetchAll } = useFetch();
    const _interventions = useArrayToDict(props.interventions, 'id');

    useEffect(() => {
        if (props.event_id) {
            props.fetchEventDetails(props.event_id);
        }
    }, []);
    useEffect(() => {
        if (props.asset_id) {
            props.fetchInterventionsForAsset(props.asset_id);
        }
    }, [props.asset_id]);

    useEffect(() => {
        if (props.interventions && !props.locations) {
            const locations = new Set(_.pluck(props.interventions, 'poi'));
            props.fetchLocations(Array.from(locations)
                .join());
        }
    }, [props.interventions]);

    useEffect(() => {
        if (props.loginless) {
            setWorkshops(props.workshops);
        } else if (props.ecm_id) {
            fetchAll('/api/workshop.json', {
                company: props.ecm_id,
            }).then((d) => {
                if (d) {
                    setWorkshops(d);
                } else {
                    setWorkshops(null);
                }
            });
        } else {
            setWorkshops(null);
        }
    }, [props.ecm_id, props.loginless]);

    useEffect(() => {
        const opened = [];
        const closed = [];
        if (props.event.id
            && props.event.interventioneventaction
            && props.event.interventioneventaction.length > 0
        ) {
            for (const iea of props.event.interventioneventaction) {
                const intervention = _interventions[iea.intervention];
                if (intervention) {
                    if (!iea.result) {
                        opened.push(intervention);
                    } else {
                        closed.push(intervention);
                    }
                }
            }
        }
        setData(opened);
        setDataClosed(closed);
    }, [props.event, _interventions]);

    const interventionIdRenderer = (cellData) => {
        if (props.noLink) {
            return (<span>{ `IN${cellData}` }</span>);
        }
        return (
            <a
                className="intervention-id-renderer"
                onClick={(evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    const from = `/operational/#${props.router.location.pathname}`;
                    const push_infos = {
                        from,
                    };
                    props.setPushInfos(push_infos);
                    if (props.interventionIdRedirect) {
                        const url = props.interventionIdRedirect(cellData);
                        if (url.includes('#')) {
                            window.location.href = url;
                        } else {
                            props.router.push(url);
                        }
                    } else {
                        props.router.push(`/asset/${props.asset_id}/intervention/${cellData}/view`);
                    }
                }}
            >
                {' '}
                { `IN${cellData}` }
                {' '}

            </a>
        );
    };

    const interventionNameRenderer = (cellData) => (
        <span className="name-renderer">{cellData}</span>
    );

    const assetNameRenderer = () => {
        let { asset_name } = props;
        if (props.asset_class_name) {
            asset_name = `${asset_name} (${props.asset_class_name})`;
        }
        return (
            <span>{asset_name}</span>
        );
    };

    const interventionTypeRenderer = (cellData) => {
        if (!props.asset_id) {
            return <span />;
        }
        const i_type = props.intervention_types[cellData];
        return (
            <span>{(i_type && i_type.name) || '-'}</span>
        );
    };

    const companyRenderer = (cellData) => {
        const company = props.companies[cellData];
        return (
            <span>{(company && company.name) || '-'}</span>
        );
    };

    const assignToRenderer = (cellData) => {
        if (_.isEmpty(workshops)) return null;
        const name = workshops[cellData] && workshops[cellData].name;
        return (
            <span>
                { name }
            </span>
        );
    };

    const locationRenderer = (cellData) => {
        const location = props.locations && props.locations[cellData];
        return (
            <span className="location-renderer">{(location && location.name) || '-'}</span>
        );
    };

    const interventionDateRenderer = (cellData, rowData) => {
        let state;
        if (['cancelled', 'done', 'planned'].includes(cellData)) {
            state = cellData;
        } else {
            const step = getCurrentStep(rowData);
            state = step.state;
        }
        return (
            <div className="intervention-state-renderer">
                <div className="intervention_status_text" style={{ fontWeight: 'bold' }}>
                    { `${InterventionStateTranslation[state]} ${i18n.t('on_date')}` }
                </div>
                <div>
                    { moment(rowData.date_state).format('DD/MM/YYYY HH:mm') }
                </div>
            </div>
        );
    };

    const interventionResultRenderer = (cellData, rowData) => {
        const id_intervention = rowData.id;
        if (!props.interventions) return (<span>-</span>);
        const intervention = _.find(
            props.interventions,
            (a) => a.id === id_intervention,
        );
        const res = _.find(
            intervention.interventioneventaction,
            (a) => a.event === props.event_id,
        );
        let result;
        if (res && res.result) {
            result = InterventionResultTranslation[res.result];
        }
        return (
            <span style={{ fontWeight: 'bold' }}>{result}</span>
        );
    };

    const interventionCommentRenderer = (cellData, rowData) => {
        const id_intervention = rowData.id;
        if (!props.interventions) return (<span>-</span>);
        const intervention = _.find(
            props.interventions,
            (a) => a.id === id_intervention,
        );
        const res = _.find(
            intervention.interventioneventaction,
            (a) => a.event === props.event_id,
        );

        return (
            <span>{(res && res.comment) || '-'}</span>
        );
    };

    const columns = [
        {
            label: i18n.t('Ref'), key: 'id', renderer: interventionIdRenderer, sortable: false,
        },
        {
            label: i18n.t('Name'), key: 'name', renderer: interventionNameRenderer, sortable: false,
        },
        {
            label: i18n.t('Asset'), key: 'asset', renderer: assetNameRenderer, sortable: false,
        },
        {
            label: i18n.t('Type'), key: 'intervention_type', renderer: interventionTypeRenderer, sortable: false,
        },
        {
            label: i18n.t('Assigned To'), key: 'workshop_in_charge', renderer: assignToRenderer, sortable: false,
        },
        {
            label: i18n.t('Location'), key: 'poi', renderer: locationRenderer, sortable: false,
        },
        {
            label: i18n.t('State'), key: 'state', renderer: interventionDateRenderer, sortable: false,
        },
    ];

    const closeColumns = [...columns];
    closeColumns.push({ label: i18n.t('Comment'), renderer: interventionCommentRenderer, sortable: false });
    closeColumns.push({ label: i18n.t('Intervention result'), renderer: interventionResultRenderer, sortable: false });

    const mobile_arrangement = {
        primary_shorts: [
            { key: 'id' },
            { key: 'name' },
        ],
        secondary_shorts: [
            { key: 'state' },
        ],
        secondary_longs: [
            { key: 'intervention_type' },
            { key: 'workshop_in_charge' },
            { key: 'poi' },
        ],
        actions: [],
    };

    const close_mobile_arrangement = {
        primary_shorts: [
            { key: 'id' },
            { key: 'name' },
        ],
        primary_long: { label: i18n.t('Comment'), renderer: interventionCommentRenderer },
        secondary_shorts: [
            { key: 'state' },
        ],
        secondary_longs: [
            { key: 'intervention_type' },
            { key: 'workshop_in_charge' },
            { key: 'poi' },
            { label: i18n.t('Intervention result'), renderer: interventionResultRenderer },
        ],
        actions: [],
    };

    if (!props.event
        || !props.event.interventioneventaction
        || props.event.interventioneventaction.length === 0
    ) return null;
    return (
        <DetailFrame
            title={i18n.t('Intervention History')}
        >
            <div className={`intervention-history ${device}`}>
                {
                    _data && _data.length > 0
                        && (
                            <div className="intervention-history-opened">
                                <span>{i18n.t('Planned')}</span>
                                <Table
                                    data={_data}
                                    data_unique_id="id"
                                    columns={columns}
                                    noTabletDisplay
                                    mobile_arrangement={mobile_arrangement}
                                />
                            </div>
                        )
                }
                {
                    _dataClosed && _dataClosed.length > 0
                        && (
                            <div className="intervention-history-closed">
                                <span>{i18n.t('Done and cancelled')}</span>
                                <Table
                                    data={_dataClosed}
                                    data_unique_id="id"
                                    columns={closeColumns}
                                    noTabletDisplay
                                    mobile_arrangement={close_mobile_arrangement}
                                />
                            </div>
                        )
                }
            </div>
        </DetailFrame>
    );
};

const mapStateToProps = (state, props) => {
    if (props.loginless) return props.mapStateToProps(state, props);
    const { event_id } = props;
    const event = event_id && maintenanceEventsSelectors.getMaintenanceEvent(state, event_id);
    const asset_id = event && event.asset;
    const asset = asset_id && assetsSelectors.getAssetInfos(state, asset_id);
    const asset_name = asset && asset.name;
    const asset_class_id = asset && asset.asset_class;
    const asset_class = asset_class_id
        && assetClassesSelectors.getAssetClass(state, asset_class_id);
    const asset_class_name = asset_class && asset_class.name;
    const getAllInterventions = interventionsSelectors.makeInterventionsListForAsset();
    const interventions = asset_id && getAllInterventions(state, asset_id);
    const getMaintenanceSettings = assetsSelectors.makeMaintenanceSettingsForAsset();
    const maintenance_settings = asset_id && getMaintenanceSettings(state, asset_id);

    const intervention_types = maintenance_settings && maintenance_settings.intervention_type;
    const companies = companiesSelectors.getCompanies(state);
    const locations = locationsSelectors.getLocations(state);
    return {
        event: event || {},
        asset_id,
        asset_name,
        asset_class_name,
        intervention_types: intervention_types || {},
        interventions: interventions || [],
        companies: companies || {},
        locations,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    if (props.loginless) return props.mapDispatchToProps(dispatch, props);
    return ({
        fetchEventDetails: (event_id) => dispatch(
            maintenanceEventsOperations.fetchDetails(event_id),
        ),
        fetchLocation: (id) => dispatch(locationsOperations.fetchDetails(id)),
        fetchLocations: (ids) => dispatch(locationsOperations.fetchListFiltered('ids', ids)),
        fetchInterventionsForAsset: (asset_id) => (
            dispatch(interventionsOperations.fetchForAsset(asset_id))
        ),
        setPushInfos: (push_infos, from = null) => dispatch(uxOperations.setPushInfos(push_infos, from)),
    });
};

export default withMultiDeviceLayout(withNavigate(connect(mapStateToProps, mapDispatchToProps)(InterventionHistory)));
