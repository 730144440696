import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import components from 'components';
import moment from 'moment-timezone';
import { maintenanceEventsOperations, maintenanceEventsSelectors } from 'railfleet_state/ducks/maintenance_events';

import './EventsSelected.scss';

const { MultiDeviceLayout } = components.app;
const { Table, TABLE_THEMES } = components.tables;

const MaintenanceStateTranslation = {
    open: i18n.t('open'),
    unplanned: i18n.t('unplanned'),
    planned: i18n.t('planned'),
    in_progress: i18n.t('in_progress'),
    closed: i18n.t('closed'),
    due: i18n.t('due'),
    overdue: i18n.t('overdue'),
    due_tl: i18n.t('due_tl'),
    overdue_tl: i18n.t('overdue_tl'),
    init: i18n.t('init'),
};

const EventSelectedComponent = (props) => {
    const [_data, setData] = useState([]);
    const [_old, setOld] = useState(null);

    useEffect(() => {
        if (props.event_ids && !_.isEqual(props.event_ids, _old)) {
            setOld(props.event_ids);
            props.fetchByIds(props.event_ids);
        }
    }, [props.event_ids]);

    useEffect(() => {
        if (
            props.maintenance_events
            && Object.keys(props.maintenance_events).length > 0
            && props.event_ids
            && props.event_ids.length > 0
        ) {
            const events = [];
            for (const id of props.event_ids) {
                const event = props.maintenance_events[id];
                if (event) events.push(event);
            }
            setData(events);
        }
    }, [props.maintenance_events, props.event_ids]);

    const EventNumberRenderer = (cellData) => (
        <span>{`M${cellData}`}</span>
    );

    const DescriptionRenderer = (cellData) => (
        <div className="description-renderer">
            {cellData || '-'}
        </div>
    );

    const MaintenanceDateStateRenderer = (cellData) => {
        let { status } = cellData;
        const date = moment(cellData.date);

        const date_label = moment(cellData.date)
            .format('DD/MM/YYYY');
        let tolerance_days = null;

        // handle tolerance extra days, the hard way
        const splitted = status.split('_');
        // handle "+ XX" tolerance date in the status
        if ((splitted.length === 2) && !isNaN(splitted[1])) {
            status = splitted[0];
            tolerance_days = ` +${splitted[1]}${i18n.t('day_short_notation')}`;
        }
        const in14days = moment().endOf('day').add(14, 'days');

        const color = status.includes('overdue') ? 'overdue'
            : status.includes('unplanned') ? 'unplanned'
                : status.includes('due') && date.isSameOrBefore(in14days) ? 'unplanned'
                    : status.includes('due') ? 'due' : status;
        status = MaintenanceStateTranslation[status].toUpperCase();

        return (
            <div className="status-block">
                <div className={`status ${color}`}>{status}</div>
                <div className="date">
                    {date_label}
                    {(tolerance_days) && <span className="plus_days">{tolerance_days}</span>}
                </div>
            </div>
        );
    };

    if (!props.event_ids || props.event_ids.length === 0) return null;

    return (
        <div className={`events-selected-holder ${props.device}`}>
            <div className="events-selected-header">
                <span>{i18n.t('Selected events')}</span>
            </div>
            <div className="events-selected-body">
                <Table
                    data={_data}
                    data_unique_id="id"
                    theme={TABLE_THEMES.INLINE}
                    columns={[
                        { label: i18n.t('Event number'), key: 'id', renderer: EventNumberRenderer },
                        { label: i18n.t('Type'), key: 'maintenance_type' },
                        { label: i18n.t('Event description'), key: 'description', renderer: DescriptionRenderer },
                        { label: i18n.t('State'), key: 'dated_state', renderer: MaintenanceDateStateRenderer },
                    ]}
                    noTabletDisplay
                    noMobileDisplay
                    noHeader
                />
            </div>
        </div>

    );
};

const EventSelected = (props) => (
    <MultiDeviceLayout>
        <EventSelectedComponent {...props} />
    </MultiDeviceLayout>
);

const mapStateToProps = (state, props) => {
    const { asset_id } = props;
    const getEventForAsset = maintenanceEventsSelectors.makeMaintenanceEventsForAsset();
    const maintenance_events = asset_id && getEventForAsset(state, asset_id);
    return {
        maintenance_events,
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchByIds: (event_ids) => dispatch(maintenanceEventsOperations.fetchByIds(event_ids)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventSelected);
