import React from 'react';
import components from 'components';
import './corrective_detail.scss';
import CorrectiveEventDetail
    from '../../../../railfleet/lib/compounds/details/correctiveEventDetail/CorrectiveEventDetail';

const { MultiDeviceLayout } = components.app;

const CorrectiveDetailComponent = (props) => {
    const loginlessData = props.loginless ? props : null;
    return (
        <CorrectiveEventDetail
            asset_id={props.asset_id}
            event_id={props.event_id}
            onCloseModal={props.onCloseModal}
            onClickEdit={props.onClickEdit}
            onClickClose={props.onClickClose}
            readOnly={props.readOnly}
            interventionIdRedirect={props.interventionIdRedirect}
            push_infos={props.push_infos}
            {...loginlessData}
        />
    );
};

const CorrectiveDetail = (props) => (
    <MultiDeviceLayout>
        <CorrectiveDetailComponent {...props} />
    </MultiDeviceLayout>
);

export default CorrectiveDetail;
