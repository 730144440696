import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { usersSelectors } from 'railfleet_state/ducks/users';
import CutForm from '../cutForm/CutForm';
import TextDisplay from '../../../display/textDisplay/TextDisplay';
import FormDropdown from '../formDropdown/FormDropdown';
import { THEMES } from '../../../filters/selectFilter/SelectFilter';

import './RenderRestrictions.scss';

const RenderRestrictions = (props) => {
    const renderElm = (restriction, index) => (
        <CutForm
            device={props.device}
            className={`restriction-elm${!restriction.new ? ' readOnly' : ''}`}
            key={`restriction_type_${index}`}
        >
            {
                !_.isEmpty(props.restriction_type)
                    && (
                        <FormDropdown
                            id={`restriction_type_${index}`}
                            defaultValue={
                                restriction
                                && restriction.restriction_type
                            }
                            label={i18n.t('Restriction type')}
                            values={props.restriction_type}
                            id_getter={(elm) => elm.id}
                            labelizer={(elm) => elm.name}
                            theme={THEMES.FORM_LIGHT}
                            readOnly
                            orphan
                        />
                    )
            }
            <TextDisplay
                id={`comment${index}`}
                className="large"
                data={
                    restriction
                        && restriction.comment
                }
                label={i18n.t('Restriction comment')}
                theme={THEMES.FORM_LIGHT}
            />
        </CutForm>
    );

    const renderFields = () => {
        const ret = [];
        for (const index of Object.keys(props.restrictions)) {
            const elm = props.restrictions[index];
            ret.push(renderElm(elm, index));
        }
        return ret;
    };

    const fields = renderFields();
    if (!fields || fields.length === 0 || !props.restriction_type) return null;

    return fields;
};
const mapStateToProps = (state, props) => {
    if (props.loginless) return props.mapStateToProps(state, props);
    const { asset_id } = props;
    const asset = asset_id && assetsSelectors.getAssetInfos(state, asset_id);
    const getMaintenanceSettings = assetsSelectors.makeMaintenanceSettingsForAsset();
    const maintenance_settings = getMaintenanceSettings(state, asset_id);
    const restriction_type = maintenance_settings && maintenance_settings.restriction_type;
    const can_view = asset && usersSelectors.userHasPerm(state)('view_restrictions', asset_id);
    return {
        can_view,
        maintenance_settings: maintenance_settings || {},
        restriction_type,
    };
};

export default connect(mapStateToProps, null)(RenderRestrictions);
