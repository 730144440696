import React, { useState, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import FormLabel from '../formLabel/FormLabel';

const FormFieldSwitcher = (props) => {
    const [_selected, setSelected] = useState(0);
    const [_name, setName] = useState(null);
    const { setValue, watch, trigger } = useFormContext();
    const [_saved, setSaved] = useState({});

    const onClickHelperText = () => {
        setSaved((prevState) => {
            const ret = { ...prevState };
            ret[_name] = watch(_name);
            return ret;
        });
        setSelected(_selected === 0 ? 1 : 0);
    };

    useEffect(() => {
        const child = props.children[_selected];
        const { id } = child.props;
        setName(id);
        if (_.isEmpty(_saved)) return;
        setValue(id, _saved[id]);
    }, [_selected]);

    useEffect(() => {
        if (props.defaultValue) {
            setValue(_name, props.defaultValue, { shouldValidate: true });
            if (props.onChange) props.onChange(props.defaultValue);
        }
        if (props.defaultField) {
            const child = props.children[0];
            if (child.props.id === props.defaultField) {
                setSelected(0);
            } else {
                setSelected(1);
            }
        }
    }, []);

    const handleClear = () => {
        setValue(_name, null);
        trigger(_name);
        if (props.onChange) props.onChange(null);
    };

    const handleChange = (value) => {
        if (value) {
            setValue(_name, value);
            trigger(_name);
            if (props.onChange) props.onChange(value);
        } else {
            handleClear();
        }
    };

    const children = React.cloneElement(props.children[_selected], {
        onChange: handleChange,
        onClear: handleClear,
        selected: watch(_name),
        required: props.required,
    });

    return (
        <div className="form form_switcher">
            <div className="form_labels">
                <FormLabel {...props} />
                <span
                    className="helper_text"
                    onClick={onClickHelperText}
                >
                    {props.help_text[_selected]}
                </span>
            </div>
            {
                children
            }
        </div>
    );
};

export default FormFieldSwitcher;
