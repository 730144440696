import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useFormContext } from 'react-hook-form';
import ReactSelectWrapper from '../ReactSelectWrapper';
import DateTimeSelector from '../../../filters/dateTimeSelector/DateTimeSelector';
import { StepContext } from '../step/Step';
import './FormDateTime.scss';
import TextDisplay from '../../../display/textDisplay/TextDisplay';

const FormDateTime = (props) => {
    const [_defaultValue, setDefaultValue] = useState(props.defaultValue);
    const { errors, unregister } = useFormContext();
    const valueError = errors && errors[props.id];

    // This is to be used with the Step Manager.
    // It allow to do Forms with multiple steps.
    // We have to register each field in the Step Manager to validate fields on each step instead of
    // validating each fields at the end of the form with the submit button.
    const stepContext = useContext(StepContext);
    useEffect(() => {
        if (stepContext && stepContext.addKey) stepContext.addKey(stepContext.step, props.id);
    }, []);
    const validate = (data) => {
        if (!data || (Array.isArray(data) && data.length === 0)) return null;
        if (data && !data.value) return null;
        if (data && !data.value.isValid()) return null;
        return data.value;
    };

    useEffect(() => {
        if (props.defaultValue) {
            if (typeof props.defaultValue === 'string') {
                setDefaultValue(moment(props.defaultValue));
            } else {
                setDefaultValue(props.defaultValue);
            }
        } else {
            setDefaultValue(null);
        }
    }, [props.defaultValue]);

    useEffect(() => {
        // unregister in case it was registered before
        // as it is not a field for the form anymore
        if (props.disabled) {
            unregister(props.id);
        }
    }, [props.disabled]);

    if (_defaultValue === null && !props.allowEmpty) return null;

    if (props.disabled) {
        return (
            <TextDisplay
                {...props}
                data={props.defaultValue && props.defaultValue.format('DD/MM/YYYY HH:mm:ss')}
            />
        );
    }

    return (
        <div className={`form form_datetime ${props.className ? props.className : ''} ${props.id}`} key={props.id}>
            <ReactSelectWrapper
                // eslint-disable-next-line react/no-children-prop
                children={DateTimeSelector}
                name={props.id}
                validate={validate}
                defaultValue={_defaultValue}
                {..._.omit(props, ['defaultValue', 'children'])}
            />
            {valueError && (
                <div className="form-validation-error">
                    <div className="form-validation-error-arrow" />
                    <div className="form-error-message">
                        {
                            (valueError.message)
                                ? <div>{valueError.message}</div>
                                : <div>Please enter a value</div>
                        }
                    </div>
                </div>
            )}
            { props.children }
        </div>
    );
};

FormDateTime.defaultProps = {
    dateFormat: 'DD/MM/YYYY',
    timeFormat: 'HH:mm',
};

export default FormDateTime;
