import React from 'react';
import './quick_links.css';
import BrandingBar from './branding';

const Quicklink = ({ url, title }) => (
    <div className="quick-link">
        <a href={url}>{title}</a>
    </div>
);

const Quicklinks = React.forwardRef(({ items, isOpen, isMobile }, ref) => (
    <div className="quick-links" ref={ref} id={isOpen ? 'open' : ''}>
        {items.map((link) => (
            <Quicklink
                url={link.url}
                title={link.title}
                key={link.url}
            />
        ))}
        <div className="spacer" />
        {isMobile && <BrandingBar />}
    </div>
));

export default Quicklinks;
