import {
    createSelector,
} from 'reselect';

export const getLineasGmao = (state) => (state.lineas_gmao);

export const getApiMessageLogsList = createSelector(
    [getLineasGmao],
    (lineas_gmao) => lineas_gmao.api_message_logs,
);

export default {
    getApiMessageLogsList,
};
