/**
 * Created by lpostula on 28/12/17.
 */
const FETCH_LIST_REQUEST = 'operation_groups/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'operation_groups/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'operation_groups/FETCH_LIST_FAILED';
const FETCH_DETAIL_REQUEST = 'operation_groups/FETCH_DETAIL_REQUEST';
const FETCH_DETAIL_COMPLETED = 'operation_groups/FETCH_DETAIL_COMPLETED';
const FETCH_DETAIL_FAILED = 'operation_groups/FETCH_DETAIL_FAILED';
const DELETE_COMPLETED = 'operation_groups/DELETE_COMPLETED';

export default {
    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,
    FETCH_DETAIL_REQUEST,
    FETCH_DETAIL_COMPLETED,
    FETCH_DETAIL_FAILED,
    DELETE_COMPLETED,
};
