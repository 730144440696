import React, { useEffect, useRef, useState } from 'react';
import { useResponsiveSize } from '../../hooks/useResponsiveBreakpoints';
import Button from '../button/Button';
import AddItemButton from '../addItemButton/AddItemButton';

import './ButtonGrouper.scss';
import DropdownMenuButton from '../dropdownMenuButton/DropdownMenuButton';

const Sizer = (props) => {
    const element = useRef();
    const { current_width } = useResponsiveSize(element);

    useEffect(() => props.onSizeChange(current_width), [current_width]);

    return (
        <div ref={element} className={props.className}>
            {props.children}
        </div>
    );
};

const ButtonGrouper = (props) => {
    const [ndButtonSizes, setNDButtonSizes] = useState(props.config.map(() => 0));
    const [dDButtonSizes, setDDButtonSizes] = useState([]);
    const [normal_display, setNormalDisplay] = useState(props.config);
    const [dropdown_display, setDropdownDisplay] = useState(props.dropdown_display || []);

    const element = useRef();
    const { current_width } = useResponsiveSize(element);

    useEffect(() => {
        setDropdownDisplay(props.dropdown_display);
    }, [props.dropdown_display]);

    const updateElementSize = (i, v) => {
        setNDButtonSizes(ndButtonSizes.map((value, index) => {
            if (index === i) return v;
            return value;
        }));
    };

    useEffect(() => {
        if (!ndButtonSizes.includes(null) && !ndButtonSizes.includes(0)) {
            const margins = Math.max(0, ndButtonSizes.length - 1) * 10;
            const sum_width = ndButtonSizes.reduce((acc, v) => acc + v, margins);

            let overflow = sum_width - current_width;
            const new_normal_display = [...normal_display];
            const new_dropdown_display = dropdown_display ? [...dropdown_display] : [];
            const new_nd_button_sizes = [...ndButtonSizes];
            const new_dd_button_sizes = [...dDButtonSizes];
            if (overflow > 0) {
                let i = new_normal_display.length - 1;
                while (i > -1 && overflow > 0) {
                    const elm = new_normal_display.pop();
                    const size = new_nd_button_sizes.pop();
                    new_dropdown_display.unshift(elm);
                    new_dd_button_sizes.unshift(size);
                    overflow -= size;
                    i -= 1;
                }
            } else {
                while (new_dropdown_display.length > 0 && overflow < 0) {
                    const size = new_dd_button_sizes[0];
                    if (size < -overflow) {
                        const elm = new_dropdown_display.shift();
                        new_dd_button_sizes.shift();
                        new_normal_display.push(elm);
                        new_nd_button_sizes.push(size);
                        overflow += size;
                    } else {
                        break;
                    }
                }
            }
            setNormalDisplay(new_normal_display);
            setDropdownDisplay(new_dropdown_display);
            setNDButtonSizes(new_nd_button_sizes);
            setDDButtonSizes(new_dd_button_sizes);
        }
    }, [current_width]);

    const displayNormalButtons = (buttons) => (
        <>
            {
                buttons.map((action, index) => (
                    action.type !== 'add' ? (
                        <Sizer
                            onSizeChange={(v) => updateElementSize(index, v)}
                            className="action-container"
                            key={`action-${action.type}-${action.label}`}
                        >
                            <Button
                                primary
                                {...action}
                            />
                        </Sizer>
                    ) : (
                        <Sizer
                            onSizeChange={(v) => updateElementSize(index, v)}
                            className="action-container"
                            key={`action-${action.type}-${action.label}`}
                        >
                            <AddItemButton
                                primary
                                {...action}
                            />
                        </Sizer>
                    )
                ))
            }
        </>
    );

    const displayDropdown = (button, dropdowns) => {
        const index = ndButtonSizes.length - 1;
        const actions = [button, ...dropdowns];
        return (
            <Sizer onSizeChange={(v) => updateElementSize(index, v)} className="action-container">
                <DropdownMenuButton
                    primary
                    {...button}
                    actions={actions}
                />
            </Sizer>
        );
    };
    return (
        <div className={`button-grouper-main ${props.className || ''}`} ref={element}>
            {(!dropdown_display || dropdown_display?.length === 0)
            && displayNormalButtons(normal_display)}
            {dropdown_display?.length > 0
            && (
                <>
                    {displayNormalButtons(normal_display.slice(0, normal_display.length - 1))}
                    {displayDropdown(normal_display[normal_display.length - 1], dropdown_display)}
                </>
            )}
        </div>
    );
};

// ButtonGrouper.defaultProps = {
//     config: [{ label: 'Add something', onClick: () => console.log('add s') },
//         {
//             label: 'Something else',
//             onClick: () => console.log('it works'),
//             type: 'add',
//         }],
// };

ButtonGrouper.defaultProps = {
    config: [],
};

export default ButtonGrouper;
