import React, {
    useState, useEffect, useRef, createContext,
} from 'react';
import Portal from '../../app/portal';

import './Modal.scss';
import LoadingOverlay from '../../animations/loadingOverlay/LoadingOverlay';
import MultiDeviceLayout from '../../app/MultiDeviceLayout';

export const LoaderContext = createContext({});

const ModalComponent = (props) => {
    const [_toLoad, setToLoad] = useState({});
    const [_loaded, setLoaded] = useState(true);
    const modalRef = useRef(null);

    useEffect(() => {
        document.body.style.overflowY = 'hidden';
        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, []);

    if (!props.children) {
        return null;
    }
    let clonedChildren = props.children;
    if (props.onClose) {
        clonedChildren = React.cloneElement(props.children, {
            onCloseModal: props.onClose,
        });
    }

    const registerDataToLoad = (data) => {
        setToLoad((prevState) => {
            // eslint-disable-next-line no-param-reassign
            prevState[data.id] = !!data.loaded;
            let loaded = true;
            for (const id of Object.keys(prevState)) {
                const elm = _toLoad[id];
                if (!elm) loaded = false;
            }
            setLoaded(loaded);
            return prevState;
        });
    };

    const context = {
        registerDataToLoad,
    };

    return (
        <Portal>
            <div className={`modal-container ${props.device} ${props.layout} ${props.position}${!_loaded ? ' loading' : ''} ${props.className ? props.className : ''}`} ref={modalRef}>
                <div className="content">
                    <LoaderContext.Provider value={context}>
                        {clonedChildren}
                    </LoaderContext.Provider>
                    <LoadingOverlay
                        loaded={_loaded}
                    />
                </div>
                {
                    !props.handleCloseInside
                        && (
                            <div className="close-actions">
                                <div
                                    className="button_close"
                                    onClick={props.onClose}
                                >
                                    <span className="glyphicon glyphicon-remove" />
                                </div>
                            </div>
                        )
                }
            </div>
        </Portal>
    );
};

const Modal = (props) => (
    <MultiDeviceLayout>
        <ModalComponent {...props} />
    </MultiDeviceLayout>
);

export default Modal;
