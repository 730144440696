import React from 'react';
import PropTypes from 'prop-types';

import './InformationBlock.scss';

const InformationBlock = (props) => (
    <div
        className="sscustom-infoblock"
    >
        {props.title
        && <div className="title">{props.title}</div>}
        <div>
            {props.children}
        </div>
    </div>
);

InformationBlock.defaultProps = {
    title: 'Information',
};

InformationBlock.propTypes = {
    title: PropTypes.string,
};

export default InformationBlock;
