/**
 * Created by lpostula on 12/05/17.
 *
 *
 *
 * This ducks hold the state of the asset filtering.
 * ================================================
 * State
 * -----
 * For every filter, there is a key in the state, this
 * key hold the query, the result of this filter and wether
 * this filter is active or not.
 * (NOTE from LP: maybe this can be drop in favor of testing the query state).
 * For example:
 * assets = {
 *      query: [],  // list of query params
 *      result: [],  // list of assets ids
 *      active: false,
 * }
 * Operations
 * ----------
 * When an action is done that will change the state of a filter,
 * for example selecting a new asset in the assets filter. The actions need
 * to do three things:
 *   - update the query.
 *   - compute the results.
 *   - update the active flag.
 * So the filtering state is a collection of results.
 * Selectors
 * ---------
 * There is two main selectors for the filtering:
 *   - getFilteredAssetIdList:
 *          returns the list of asset ids that are currently filtered in
 *   - getFilteredAssetList:
 *          returns the list of asset objects that are currently filtered in
 * The main difference, outside the fact that one returns the ids and the other
 * the asset object, is that given a set of filter that yields the following lists:
 * l_id = [1, 2, 3];
 * l_obj = [{id: 1}, {id: 2}, {id: 3}];
 * If the asset with id 2 is updated but remain in the filter, then the selector
 * for l_id will return the same list (same spec) whereas l_obj will be a new list
 * containing a new object for id 2 and the same object 1 and 3.
 *
 *
 *
 *
 */
// import reducer from './reducers';

export { default as filteringOperations } from './operations';
export { default as filteringSelectors } from './selectors';

// empty reducer, to comply with the combinedreducer api
const empty_reducer = (state = {}) => state;

export default empty_reducer;
