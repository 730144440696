/**
 * Created by lpostula on 11/05/17.
 */
import { RSAA } from 'redux-api-middleware';
import types from './types';
import { API_ROOT } from '../../config';

const API_RESOURCE_NAME = 'asset';

export const fetchInfosList = (long_polling = false) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            { type: types.FETCH_LIST_INFOS_REQUEST, meta: { long_polling } },
            { type: types.FETCH_LIST_INFOS_COMPLETED, meta: { long_polling } },
            types.FETCH_LIST_INFOS_FAILED,
        ],
    },
});

export const fetchLiveDataDetail = (asset_id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/live_data/${asset_id}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_DETAIL_LIVE_DATA_REQUEST,
            types.FETCH_DETAIL_LIVE_DATA_COMPLETED,
            types.FETCH_DETAIL_LIVE_DATA_FAILED,
        ],
    },
});

// export const liveDataPartialUpdate = (asset_id, data) => ({
//     type: types.LIVE_DATA_PARTIAL_UPDATE,
//     payload: data,
//     asset_id,
// });

export const liveDataPartialUpdate = (batch_data, timestamped = false) => ({
    type: types.LIVE_DATA_PARTIAL_UPDATE,
    payload: batch_data,
    meta: { timestamped },
});

export const fetchLiveDataList = (long_polling = false) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/live_data.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            { type: types.FETCH_LIST_LIVE_DATA_REQUEST, meta: { long_polling } },
            { type: types.FETCH_LIST_LIVE_DATA_COMPLETED, meta: { long_polling } },
            types.FETCH_LIST_LIVE_DATA_FAILED,
        ],
    },
});

export const fetchAlertsList = (long_polling = false) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/alerts.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            { type: types.FETCH_LIST_ALERTS_REQUEST, meta: { long_polling } },
            { type: types.FETCH_LIST_ALERTS_COMPLETED, meta: { long_polling } },
            types.FETCH_LIST_ALERTS_FAILED,
        ],
    },
});

export const fetchOperationalsList = (long_polling = false) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/operational.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            { type: types.FETCH_LIST_OPERATIONALS_REQUEST, meta: { long_polling } },
            { type: types.FETCH_LIST_OPERATIONALS_COMPLETED, meta: { long_polling } },
            types.FETCH_LIST_OPERATIONALS_FAILED,
        ],
    },
});

export const fetchLabelsList = (long_polling = false) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/labels.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            { type: types.FETCH_LIST_LABELS_REQUEST, meta: { long_polling } },
            { type: types.FETCH_LIST_LABELS_COMPLETED, meta: { long_polling } },
            types.FETCH_LIST_LABELS_FAILED,
        ],
    },
});

export const fetchInfosDetail = (asset_id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${asset_id}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_DETAIL_INFOS_REQUEST,
            types.FETCH_DETAIL_INFOS_COMPLETED,
            types.FETCH_DETAIL_INFOS_FAILED,
        ],
    },
});

export const setAssetInfos = (data) => ({
    type: types.FETCH_DETAIL_INFOS_COMPLETED,
    payload: data,
});

export const fetchOperationalsDetail = (asset_id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/operational/${asset_id}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_DETAIL_OPERATIONALS_REQUEST,
            types.FETCH_DETAIL_OPERATIONALS_COMPLETED,
            types.FETCH_DETAIL_OPERATIONALS_FAILED,
        ],
    },
});

export const operationalsPartialUpdate = (data) => ({
    type: types.OPERATIONAL_PARTIAL_UPDATE,
    payload: data,
});

export const fetchLabelsDetail = (asset_id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/labels/${asset_id}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_DETAIL_LABELS_REQUEST,
            types.FETCH_DETAIL_LABELS_COMPLETED,
            types.FETCH_DETAIL_LABELS_FAILED,
        ],
    },
});

export const setAssetLabels = (data) => ({
    type: types.FETCH_DETAIL_LABELS_COMPLETED,
    payload: data,
});

export const setLabels = (asset_id, category, values) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/labels/${asset_id}/set_label/`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        credentials: 'same-origin',
        body: JSON.stringify({ category, labels: values }),
        types: [
            types.SET_LABELS_REQUEST,
            types.SET_LABELS_COMPLETED,
            types.SET_LABELS_FAILED,
        ],
    },
});

export const fetchAssignTo = (asset_id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${asset_id}/assign_to_prediction.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_ASSIGN_TO_REQUEST,
            types.FETCH_ASSIGN_TO_COMPLETED,
            types.FETCH_ASSIGN_TO_FAILED,
        ],
    },
});

export default {
    fetchInfosList,
    fetchLiveDataList,
    fetchLiveDataDetail,
    liveDataPartialUpdate,
    fetchAlertsList,
    fetchOperationalsList,
    fetchLabelsList,
    fetchInfosDetail,
    fetchOperationalsDetail,
    operationalsPartialUpdate,
    fetchLabelsDetail,
    setLabels,
    fetchAssignTo,
    setAssetInfos,
    setAssetLabels,
};
