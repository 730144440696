/**
 * Created by lpostula on 17/05/18.
 */
import { RSAA } from 'redux-api-middleware';
import types from './types';
import { API_ROOT } from '../../config';
import { fetchDetails as fetchCampaignsDetails } from '../campaigns/operations';

const API_RESOURCE_NAME = 'campaign_documents';

export const fetchList = (long_polling = false) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            {
                type: types.FETCH_LIST_REQUEST,
                meta: { long_polling },
            },
            {
                type: types.FETCH_LIST_COMPLETED,
                meta: { long_polling },
            },
            types.FETCH_LIST_FAILED,
        ],
    },
});

export const fetchForCampaign = (campaign_id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}.json?campaign=${campaign_id}`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_LIST_REQUEST,
            types.FETCH_LIST_COMPLETED,
            types.FETCH_LIST_FAILED,
        ],
    },
});

export const updateDocument = (values) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${values.id}.json`,
        method: 'PATCH',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(values),
        credentials: 'same-origin',
        types: [
            types.UPDATE_DETAILS_REQUEST,
            types.UPDATE_DETAILS_COMPLETED,
            types.UPDATE_DETAILS_FAILED,
        ],
    },
});

export const createDocument = (values) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(values),
        credentials: 'same-origin',
        types: [
            types.CREATE_DOCUMENT_REQUEST,
            types.CREATE_DOCUMENT_COMPLETED,
            types.CREATE_DOCUMENT_FAILED,
        ],
    },
});

export const deleteDocument = (document) => (dispatch) => {
    dispatch({
        [RSAA]: {
            endpoint: `${API_ROOT + API_RESOURCE_NAME}/${document.id}`,
            method: 'DELETE',
            headers: {
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
            },
            credentials: 'same-origin',
            types: [
                types.DELETE_DOCUMENT_REQUEST,
                {
                    type: types.DELETE_DOCUMENT_COMPLETED,
                    payload: () => {
                        dispatch(fetchCampaignsDetails(document.campaign));
                    },
                },
                types.DELETE_DOCUMENT_FAILED,
            ],
        },
    });
    return Promise.resolve();
};

export const setDocumentInStore = (document) => ({
    type: types.FETCH_DETAIL_COMPLETED,
    payload: document,
});

export default {
    fetchList,
    fetchForCampaign,
    updateDocument,
    createDocument,
    deleteDocument,
    setDocumentInStore,
};
