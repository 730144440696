import React from 'react';
import { connect } from 'react-redux';

import './viewas.scss';
import Selector from '../selectors/selector/Selector';
import Button from '../buttons/button/Button';

class ViewAsClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            to_impersonate: null,
        };
        this.onChange = this.onChange.bind(this);
        this.searchUser = this.searchUser.bind(this);
    }

    async searchUser(email) {
        let url = '/api/v2/user/impersonation.json';
        if (email) {
            url += `?email=${email}`;
        }
        const response = await fetch(url);
        const json = await response.json();
        return json.map((u) => ({ value: u.id.toString(), id: u.id, label: `${u.full_name} - [${u.email}]` }));
    }

    componentDidMount() {
        window.addEventListener('keydown', (event) => {
            if (event.which === this.props.toggle_key) {
                this.setState({ expanded: !this.state.expanded });
            }
        });
    }

    onChange(user) { this.setState({ to_impersonate: user }); }

    render() {
        const impersonate = (this.props.user.actual_user != null)
            && (this.props.user.actual_user.id !== this.props.user.id);
        return (
            <div className={`viewas_wrapper${impersonate ? ' impersonating' : ''}`}>
                {
                    !this.state.expanded ? (
                        <div
                            className="toggle_viewas"
                            onClick={() => { this.setState({ expanded: !this.state.expanded }); }}
                        >
                            <span className="glyphicon glyphicon-user inverse" />
                        </div>
                    ) : null
                }
                {
                    this.state.expanded ? (
                        <div>
                            {
                                impersonate ? (
                                    <div className="viewas_user col-sm-12">
                                        You are impersonating
                                        <strong>
                                            {' '}
                                            { this.props.user.full_name }
                                            {' '}
                                        </strong>
                                        { ` (${this.props.user.full_name})` }
                                    </div>
                                ) : null
                            }
                            {
                                (this.props.user.actual_user != null) ? (
                                    <div
                                        className="viewas_actual_user col-sm-12"
                                        onClick={() => {
                                            console.log('state:', this.props.state);
                                        }}
                                    >
                                        You are
                                        <strong>
                                            {' '}
                                            { this.props.user.actual_user.full_name }
                                            {' '}
                                        </strong>
                                    </div>
                                ) : null
                            }
                            <div className="viewas_users_selector onTop col-sm-8">
                                <div className="form-group label-floating">
                                    <Selector
                                        name="view-as-users"
                                        selected={this.state.to_impersonate}
                                        onChange={this.onChange}
                                        search={this.searchUser}
                                        // style={{
                                        //     menu: { color: 'black' },
                                        // }}
                                        autoload
                                        openTop
                                    />
                                </div>
                            </div>
                            <div className="viewas_buttons">
                                <form onSubmit={this.submit} method="POST">
                                    <input
                                        type="hidden"
                                        name="csrfmiddlewaretoken"
                                        value={
                                            Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME)
                                        }
                                    />
                                    <input
                                        name="login_as"
                                        type="hidden"
                                        value={this.state.to_impersonate ? this.state.to_impersonate.id : ''}
                                    />
                                    <input
                                        type="submit"
                                        className={`btn btn-primary ${!this.state.to_impersonate ? 'disabled' : ''}`}
                                        value="Impersonate"
                                    />
                                    <input
                                        type="submit"
                                        className={`btn btn-danger  ${!impersonate ? 'disabled' : ''}`}
                                        value="logout"
                                        onClick={() => this.setState({ to_impersonate: null })}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-warning"
                                        onClick={() => this.setState({ expanded: false })}
                                    >
                                        <span className="glyphicon glyphicon-remove" />
                                    </button>
                                </form>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.users.me,
    state,
});

export default connect(mapStateToProps)(ViewAsClass);
