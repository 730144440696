import { RSAA } from 'redux-api-middleware';
import types from './types';
import { API_ROOT } from '../../config';

const API_RESOURCE_NAME = 'counters';

export const fetchCounterReadingForCounter = (id_counter) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${id_counter}/readings.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_LIST_REQUEST,
            types.FETCH_LIST_COMPLETED,
            types.FETCH_LIST_FAILED,
        ],
    },
});

export const createReadings = (values) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/bulk_create_readings.json`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(values),
        credentials: 'same-origin',
        types: [
            types.CREATE_REQUEST,
            types.CREATE_COMPLETED,
            {
                type: types.CREATE_FAILED,
                meta: { noToast: true },
            },
        ],
    },
});

export const updateCounterReadingInStore = (data) => ({
    type: types.FETCH_DETAIL_COMPLETED,
    payload: data,
});

export default {
    fetchCounterReadingForCounter,
    createReadings,
    updateCounterReadingInStore,
};
