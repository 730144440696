/**
 * Created by lpostula on 11/05/17.
 */
import { combineReducers } from 'redux';
import types from './types';
import { createReducer, apiHelpers } from '../../utils';

/* State shape
{
    assets_infos: {asset_id: asset_infos},
    assets_live_data: {asset_id: live_data},
    assets_alerts: {asset_id: alert},
    assets_operationals: {asset_id: asset_operationals},
    assets_labels: {asset_id: asset_labels},
}
 */

const initialState = {};

const assetsInfosReducer = createReducer(initialState)({
    [types.FETCH_LIST_INFOS_COMPLETED]: apiHelpers.fetch_list_complete,
    [types.FETCH_DETAIL_INFOS_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.FETCH_ASSIGN_TO_COMPLETED]: (state, action) => {
        const asset_data = {

            ...state[action.payload.asset],
            assign_to: action.payload.company,
        };
        const new_data = {
            [asset_data.id]: asset_data,
        };
        return { ...state, ...new_data };
    },
});

const assetsLiveDataReducer = createReducer(initialState)({
    [types.FETCH_LIST_LIVE_DATA_COMPLETED]: apiHelpers.fetch_list_complete,
    [types.FETCH_DETAIL_LIVE_DATA_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.LIVE_DATA_PARTIAL_UPDATE]: (state, action) => {
        const { payload } = action;
        const to_merge = {};
        for (const asset_id in payload) {
            if (!payload.hasOwnProperty(asset_id)) continue;
            const partial = payload[asset_id];
            const asset_data = {

                ...state[asset_id],
            };

            if (action.meta && action.meta.timestamped) {
                Object.assign(asset_data, partial);
            } else {
                const { timestamp } = partial;

                for (const k in partial) {
                    if (!partial.hasOwnProperty(k)) continue;
                    if (k !== 'timestamp') {
                        asset_data[k] = [partial[k], timestamp];
                    }
                }
            }
            to_merge[asset_id] = asset_data;
        }

        return { ...state, ...to_merge };
    },
});

const assetsAlertsReducer = createReducer(initialState)({
    [types.FETCH_LIST_ALERTS_COMPLETED]: apiHelpers.fetch_list_complete,
    // [types.FETCH_DETAIL_ALERTS_COMPLETED]: apiHelpers.fetch_details_complete,
    // [types.ALERTS_PARTIAL_UPDATE]: (state, action) => {
    //     const asset_data = Object.assign(
    //         {},
    //         state[action.payload.id],
    //         action.payload,
    //     );
    //     const new_data = {
    //         [asset_data.id]: asset_data,
    //     };
    //     return Object.assign({}, state, new_data);
    // },
});

const assetsOperationalsReducer = createReducer(initialState)({
    [types.FETCH_LIST_OPERATIONALS_COMPLETED]: apiHelpers.fetch_list_complete,
    [types.FETCH_DETAIL_OPERATIONALS_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.OPERATIONAL_PARTIAL_UPDATE]: (state, action) => {
        const asset_data = {

            ...state[action.payload.id],
            ...action.payload,
        };
        const new_data = {
            [asset_data.id]: asset_data,
        };
        return { ...state, ...new_data };
    },
});

const assetsLabelsReducer = createReducer(initialState)({
    [types.FETCH_LIST_LABELS_COMPLETED]: apiHelpers.fetch_list_complete,
    [types.FETCH_DETAIL_LABELS_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.SET_LABELS_COMPLETED]: apiHelpers.fetch_details_complete,
});

export default combineReducers({
    assets_infos: assetsInfosReducer,
    assets_operationals: assetsOperationalsReducer,
    assets_labels: assetsLabelsReducer,
    assets_live_data: assetsLiveDataReducer,
    assets_alerts: assetsAlertsReducer,
});
