import React, { useEffect, useContext, useState } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import moment from 'moment-timezone';
import { countersSelectors } from 'railfleet_state/ducks/counters';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import formatNumber from 'railfleet/utils/formatNumber';
import ReactSelectWrapper from '../ReactSelectWrapper';
import SelectFilter, { THEMES } from '../../../filters/selectFilter/SelectFilter';
import { StepContext } from '../step/Step';
import FormLabel from '../formLabel/FormLabel';
import './FormCounter.scss';
import { COUNTER_UNIT_TRANSLATION } from './constants';

const CounterInfo = (props) => {
    const [_counter, setCounter] = useState(null);
    useEffect(() => {
        if (props.counters && props.counters.length > 0) {
            for (const counter of props.counters) {
                if (counter.id === props.selected) {
                    setCounter(counter);
                    props.setCounter(counter);
                }
            }
        }
    }, [props.counters, props.selected]);

    if (!_counter) return null;
    const unit_text = COUNTER_UNIT_TRANSLATION[_counter.unit] || _counter.unit;
    const value_date = moment(_counter.value_date);
    const limit = moment().subtract(15, 'days');
    const text_to_use = value_date < limit
        ? 'counter_value_info_stale' : 'counter_value_info';
    const text = {
        __html: i18n.t(text_to_use, {
            value: `<b>${formatNumber(_counter.value)} ${unit_text}</b>`,
            date: `<b>${value_date.format('DD/MM/YYYY')}</b>`,
            interpolation: { escapeValue: false },
        }),
    };

    return (
        <small dangerouslySetInnerHTML={text} /> // eslint-disable-line react/no-danger
    );
};

const FormCounter = (props) => {
    const [_counter, setCounter] = useState(null);
    const { errors, watch } = useFormContext();
    const valueError = errors && errors[props.id];

    const stepContext = useContext(StepContext);
    useEffect(() => {
        if (stepContext && stepContext.addKey) stepContext.addKey(stepContext.step, props.id);
    }, []);

    const validate = (data) => {
        if (!data || (Array.isArray(data) && data.length === 0)) return null;
        if (props.multi) return data;
        return data[0];
    };

    const getCountersValues = (counters) => ({

        ...counters,
        [Number.MAX_SAFE_INTEGER]: {
            id: Number.MAX_SAFE_INTEGER,
            disabled: true,
            counter_type_name: props.is_admin
                ? i18n.t('Counter missing? Add it in the admin')
                : i18n.t('Counter missing? Ask an admin'),
        },
    });
    const values = getCountersValues(props.counters);
    const sort_func = (a, b) => {
        if (a.id === Number.MAX_SAFE_INTEGER) return 1;
        if (b.id === Number.MAX_SAFE_INTEGER) return -1;
        const sa = String(a.label)
            .toLowerCase();
        const sb = String(b.label)
            .toLowerCase();
        return -(sa < sb) || +(sa !== sb);
    };

    useEffect(() => {
        if (props.onChange) props.onChange(_counter);
    }, [_counter]);

    return (
        <div
            className={`form form_counter ${props.className ? props.className : ''} ${props.id} ${!props.label ? 'no-label' : ''}${props.large ? ' large' : ''}`}
            key={props.id}
        >
            <FormLabel {...props} />
            <ReactSelectWrapper
                classname="dropdown"
                // eslint-disable-next-line react/no-children-prop
                children={SelectFilter}
                name={props.id}
                validate={validate}
                values={values}
                id_getter={(elm) => elm.id}
                labelizer={(elm) => elm.counter_type_name}
                sort_func={sort_func}
                {..._.omit(props, 'onChange')}
            />
            {valueError && (
                <div className="form-validation-error">
                    <div className="form-validation-error-arrow" />
                    <div className="form-error-message">
                        {
                            (valueError.message)
                                ? <div>{valueError.message}</div>
                                : <div>Please enter a value</div>
                        }
                    </div>
                </div>
            )}
            <CounterInfo
                counters={props.counters}
                selected={watch(props.id)}
                setCounter={setCounter}
            />
        </div>
    );
};

FormCounter.defaultProps = {
    theme: THEMES.FORM_LIGHT,
};

const mapStateToProps = (state, props) => {
    const getAllAssetData = assetsSelectors.makeAllAssetData();
    const asset = getAllAssetData(state, props.asset_id);
    const counters = countersSelectors.getCountersListForAsset(state, asset.info.id);
    return {
        is_admin: state.users.me.is_staff,
        counters: counters || [],
    };
};

export default connect(mapStateToProps)(FormCounter);
