/**
 * Created by lpostula on 12/05/17.
 */
/*
This is an HOC that provide an Application Manager.

The goal of the Application Manager, is to:
 1) Render the navbar, the application content, and the viewAs middleware
 2) Provide an easy to use mechanisms for loading data into the store with a polling interval
 3) By specifying which data is needed for the object, a global loader can be displayed in lieu
 and place of the application content for the time the data is not ready.

The Application Manager will:
 - Initialise the redux store
 - Dispatch an action to set the legacy data (from old managers and co) in it.
 - Dispatch a fetch to the `user/me` API endpoint
 - Provide the redux store to the entire app
 - Render a loader if there is no data yet then display the component

The Application Manager take as input:
 - The Application content:
    - A React Component which extends React.Component
    - A React Component which extends React.PureComponent
    - A React Component created with the legacy helper createClass (
        from the npm package `create-react-class`)
    - NOT A React Factory created with React.createFactory
 - A list of action polling configuration: [
    {
        key: A key identifier,
        label: A label to display,
        action: A Redux action or operation,
        selector: A Reselect selector to get the loading information,
        data_selector: A Reselect selector to get the root of the data, in order to look
                        if it's populated or not
        [normal_polling]: should it start normal polling (default to false),
        [long_polling]: should it start long polling (default to false),
    },
   ]
   Please note that if no polling selector are given, then it will only be launch at the start
 - The props to pass to the custom component
 - Redux actions or operations categorised as follows:
    - no_polling_actions: Those actions will be dispatch once at the launch;
    - normal_polling_actions: Those actions will be dispatch every XXXX seconds;
    - long_polling_actions: Those actions will be dispatch every XXXX seconds;

The Application Manager returns a React Component that can be render in the 'react_entry_point'

 The idea between the differenciation between normal and long polling is done with the idea in
 mind that during the normal polling,
 the data receive from those actions (if those actions return data) will be append only,
  whereas the actions from the long polling would replace the data altogether.

 Taking for example the asset endpoint, with the normal polling, we only append the data,
 meaning that if an asset is deleted, it will still remains in the local dataset.
 But once in while, when the long_polling is triggered, those deleted assets will disappear.
 */

import React, { Component } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { Provider as ReduxProvider, connect } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/browser';

import configureStore from 'railfleet_state/store';
import { usersSelectors, usersOperations } from 'railfleet_state/ducks/users';
import { wsOperations } from 'railfleet_state/ducks/websocket';
import { WS_CONFIG } from 'railfleet_state/config';

import NavBar from '../components/app/navbar/navbar';
import SnackBar from '../components/app/SnackBar';
import ReloadPage from '../components/app/ReloadPage';
import MainError from '../components/app/errors';
import ViewAs from '../components/app/viewas';
import MainLoader from '../components/app/MainLoader';

import './applicationManager.scss';

export default function ApplicationManager(
    ApplicationComponent,
    polling_configuration = [],
    props = null,
    routes = null,
    ws_disabled = false,
    sync_routes = true,
) {
    // we create an app class that can be pass props, to handle the loading,
    // the authentication (at one point), ...
    class ApplicationManagerComponentClass extends Component {
        constructor(am_props) {
            super(am_props);
            this.pausePolling = this.pausePolling.bind(this);
            this.unpausePolling = this.unpausePolling.bind(this);
            this.polling_timeout = {
                normal: {},
                long: {},
            };
            // initialize the long polling to false
            // this is use to trigger the normal polling, after the first long polling
            const first_long_polling_done = {};
            const data_loading = {};
            for (const polling of polling_configuration) {
                first_long_polling_done[polling.key] = false;
                data_loading[polling.key] = am_props.root_data_size[polling.key] === 0;
            }
            this.state = {
                data_loading,
                first_long_polling_done,
                has_error: false,
                user_loaded: false,
                paused_polling: {
                    long: [],
                    normal: [],
                },
            };
            window.pausePolling = this.pausePolling;
            window.unpausePolling = this.unpausePolling;
        }

        componentDidMount() {
            if (!ws_disabled) {
                // init the ws connection
                this.props.initWebSocket();
            }
            // get the current user info
            this.props.fetchCurrentUser().then(() => {
                this.setState({ user_loaded: true });
                // start all data loading at the mounting
                // with the long_polling mechanism but only once
                // the current user is loaded
                for (const polling of polling_configuration) {
                    this.props.loading_actions[polling.key](true);
                }
            });
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            // here is the logic to trigger the polling at the right moment
            const { first_long_polling_done } = this.state;
            const { data_loading } = this.state;
            let should_update_state = false; // to prevent setState when not needed
            for (const polling of polling_configuration) {
                // for each polling we look at the previous request count
                // if the request count is different in this.props than in nextProps
                // and is 0 we'll trigger the timeout that will trigger the action
                // we do that both for long and for normal polling
                // LONG POLLING FIRST
                if (nextProps.loading_state[polling.key].long_polling === 0
                    && this.props.loading_state[polling.key].long_polling > 0) {
                    // the loading of the data has just finished
                    // we lauch the polling if it is configured as such
                    if (polling.long_polling) {
                        this.startLongPolling(
                            polling.key,
                            nextProps.loading_actions[polling.key],
                        );
                    }
                    // there is a custom behaviour on the first load, at this point,
                    // we can set up the normal polling for the first time, if it's not done yet
                    if (!first_long_polling_done[polling.key]) {
                        // set it as done
                        first_long_polling_done[polling.key] = true;
                        // set the data as loaded
                        data_loading[polling.key] = false;
                        should_update_state = true;
                        // we lauch the polling if it is configured as such
                        if (polling.normal_polling) {
                            this.startNormalPolling(
                                polling.key,
                                nextProps.loading_actions[polling.key],
                            );
                        }
                    }
                }
                // NORMAL POLLING
                if (nextProps.loading_state[polling.key].normal_polling === 0
                    && this.props.loading_state[polling.key].normal_polling > 0) {
                    // the loading of the data has just finished and we can start it again
                    // we lauch the polling if it is configured as such
                    if (polling.normal_polling) {
                        this.startNormalPolling(
                            polling.key,
                            nextProps.loading_actions[polling.key],
                        );
                    }
                }
            }
            if (should_update_state) {
                this.setState({
                    first_long_polling_done,
                    data_loading,
                });
            }
        }

        pausePolling(keys) {
            const { paused_polling } = this.state;
            for (const key of keys) {
                if (Object.keys(this.polling_timeout.long).includes(key)) {
                    clearTimeout(this.polling_timeout.long[key]);
                    paused_polling.long[key] = this.polling_timeout.long[key];
                    delete this.polling_timeout.long[key];
                } else if (Object.keys(this.polling_timeout.normal).includes(key)) {
                    clearTimeout(this.polling_timeout.normal[key]);
                    paused_polling.normal[key] = this.polling_timeout.normal[key];
                    delete this.polling_timeout.normal[key];
                }
            }
            this.setState({ paused_polling });
        }

        unpausePolling(keys) {
            const { paused_polling } = this.state;
            for (const key of keys) {
                const action = this.props.loading_actions[key];
                if (this.state.paused_polling.long.includes(key)) {
                    this.polling_timeout.long[key] = setTimeout(() => action(true), 600000);
                    delete paused_polling.long[key];
                } else {
                    this.polling_timeout.normal[key] = setTimeout(action, 30000);
                    delete paused_polling.normal[key];
                }
            }
            this.setState({ paused_polling });
        }

        startNormalPolling(key, action) {
            clearTimeout(this.polling_timeout.normal[key]);
            // 5 seconds for now
            this.polling_timeout.normal[key] = setTimeout(action, 30000);
        }

        startLongPolling(key, action) {
            clearTimeout(this.polling_timeout.long[key]);
            // 10 minutes for now
            this.polling_timeout.long[key] = setTimeout(() => action(true), 600000);
        }

        isLoaded() {
            return this.state.user_loaded && !(_.some(this.state.data_loading));
        }

        componentDidCatch(error, info) {
            // Display fallback UI
            this.setState({ has_error: true });
            Sentry.captureException(error, { extra: info });
        }

        render() {
            if (!this.isLoaded()) {
                const loading_steps = {};
                let loading = 0;
                for (const polling of polling_configuration) {
                    const is_loading = this.state.data_loading[polling.key];
                    if (is_loading) {
                        loading += 1;
                    }
                    loading_steps[polling.key] = {
                        loading: is_loading,
                        label: polling.label,
                    };
                }
                loading_steps.user_info = {
                    loading: !this.state.user_loaded,
                    label: i18n.t('current user info'),
                };
                const loading_size = _.size(polling_configuration) + 1; // for the user info;
                if (this.state.user_loaded) {
                    loading += 1;
                }
                return (
                    <MainLoader
                        not_loaded={loading_size - loading}
                        to_load={loading_size}
                        loading_steps={loading_steps}
                    />
                );
            }
            const user = this.props?.user;
            const actual_user = user?.actual_user;
            const can_impersonate = this.props?.user_can_impersonate
                || (actual_user && actual_user?.id !== user?.id);
            const network_error = !this.props.ws_state.connected
                        && this.props.ws_state.reconnect_attempts > 0
                        && !this.props.ws_state.revert_to_polling;
            return (
                <div>
                    <ReloadPage />
                    <SnackBar />
                    <div id="supernova_navbar">
                        <NavBar
                            home_url={window.settings.home_url}
                            logout_url={window.settings.logout_url}
                            railnova_logo={window.settings.logo}
                            current_url={window.location.href}
                        />
                    </div>
                    <div id="supernova_component" className="container-fluid">
                        {
                            this.state.has_error ? (
                                <MainError />
                            ) : (
                                <ApplicationComponent
                                    {...props}
                                />
                            )
                        }
                    </div>
                    { can_impersonate ? (
                        <div id="view_as_entry">
                            <ViewAs toggle_key={window.settings.VIEWAS_TOGGLE_KEY} />
                        </div>
                    ) : null}
                    {
                        network_error ? (
                            <div className="network_error_message">
                                <span>
                                    {'Oops, it looks live we have lost the connection, we will try again in '}
                                </span>
                                <span>
                                    {(
                                        (this.props.ws_state.reconnect_interval || 0) / 1000
                                    ).toFixed()}
                                </span>
                                <span>
                                    {'s. Or you can refresh yourself '}
                                </span>
                                <a
                                    href="#"
                                    onClick={() => window.location.reload(true)}
                                >
                                    here
                                </a>
                                <span>
                                    {`. (${this.props.ws_state.reconnect_attempts}/${WS_CONFIG.maxReconnectAttempts})`}
                                </span>
                            </div>
                        ) : null
                    }
                </div>
            );
        }
    }

    ApplicationManagerComponentClass.propTypes = {
        fetchCurrentUser: PropTypes.func.isRequired,
        loading_actions: PropTypes.object.isRequired,
        loading_state: PropTypes.object.isRequired,
        user: PropTypes.object,
    };

    function mapStateToProps(state) {
        const mapped_props = {
            user: state.users.me,
            user_can_impersonate: usersSelectors.userHasPerm(state)('can_impersonate'),
            loading_state: {},
            root_data_size: {},
            ws_state: state.ws,
        };
        for (const polling of polling_configuration) {
            if (polling.data_selector !== undefined && polling.data_selector !== null) {
                mapped_props.root_data_size[polling.key] = _.size(polling.data_selector(state));
            }
            mapped_props.loading_state[polling.key] = polling.selector(state);
        }
        return mapped_props;
    }

    function mapDispatchToProps(dispatch) {
        const dispatched_props = {
            loading_actions: {},
            initWebSocket: () => dispatch(wsOperations.initSocket()),
            fetchCurrentUser: () => dispatch(usersOperations.fetchMe()),
        };
        for (const polling of polling_configuration) {
            dispatched_props.loading_actions[polling.key] = (long_polling) => dispatch(polling.action(long_polling));
        }
        return dispatched_props;
    }

    const ApplicationManagerComponent = connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ApplicationManagerComponentClass);

    // HOC of an HOC providing the store
    class ApplicationManagerComponentWrapper extends Component {
        constructor(am_props) {
            super(am_props);
            this.state = configureStore(() => null);
        }

        render() {
            return (
                <ReduxProvider store={this.state.store}>
                    <PersistGate
                        persistor={this.state.persistor}
                        loading={<MainLoader not_loaded="0" to_load="1" loading_steps={[{ loading: true, label: 'app' }]} />}
                    >
                        <ApplicationManagerComponent />
                    </PersistGate>
                </ReduxProvider>
            );
        }
    }

    return ApplicationManagerComponentWrapper;
}
