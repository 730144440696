import types from './types';

// console.log('setPushInfos de ', push_infos, from);
export const setPushInfos = (push_infos, from = null) => ({
    type: types.SET_PUSH_INFOS,
    payload: push_infos,
});

export default {
    setPushInfos,
};
