import React, { createContext } from 'react';

export const LoginlessContext = createContext({});

const LoginlessComponent = (props) => {
    const context = {
        ..._.omit(props, 'children'),
        loginless: true,
    };

    return (
        <LoginlessContext.Provider value={context}>
            {props.children}
        </LoginlessContext.Provider>
    );
};

export default LoginlessComponent;
