/**
 * Created by lpostula on 18/05/17.
 */
const getDocuments = (state) => state.documents;

export const getDocumentsForEvent = (state, event_id) => {
    if (event_id === undefined || event_id === null) {
        return [];
    }
    const event = state.maintenance_events[event_id];
    if (event) {
        return _.pick(state.documents, event.documents);
    }
    return {};
};

export default {
    getDocuments,
    getDocumentsForEvent,
};
