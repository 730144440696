/**
 * Created by lpostula on 17/05/18.
 */
const FETCH_LIST_REQUEST = 'campaigns/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'campaigns/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'campaigns/FETCH_LIST_FAILED';

const FETCH_DETAILS_REQUEST = 'campaigns/FETCH_DETAILS_REQUEST';
const FETCH_DETAILS_COMPLETED = 'campaigns/FETCH_DETAILS_COMPLETED';
const FETCH_DETAILS_FAILED = 'campaigns/FETCH_DETAILS_FAILED';

const CREATE_REQUEST = 'campaigns/CREATE_REQUEST';
const CREATE_COMPLETED = 'campaigns/CREATE_COMPLETED';
const CREATE_FAILED = 'campaigns/CREATE_FAILED';

const UPDATE_REQUEST = 'campaigns/UPDATE_REQUEST';
const UPDATE_COMPLETED = 'campaigns/UPDATE_COMPLETED';
const UPDATE_FAILED = 'campaigns/UPDATE_FAILED';

const CANCEL_REQUEST = 'campaigns/CANCEL_REQUEST';
const CANCEL_COMPLETED = 'campaigns/CANCEL_COMPLETED';
const CANCEL_FAILED = 'campaigns/CANCEL_FAILED';

const DUPLICATE_REQUEST = 'campaigns/DUPLICATE_REQUEST';
const DUPLICATE_COMPLETED = 'campaigns/DUPLICATE_COMPLETED';
const DUPLICATE_FAILED = 'campaigns/DUPLICATE_FAILED';

const APPROVE_REQUEST = 'campaigns/APPROVE_REQUEST';
const APPROVE_COMPLETED = 'campaigns/APPROVE_COMPLETED';
const APPROVE_FAILED = 'campaigns/APPROVE_FAILED';

const ASSIGN_REQUEST = 'campaigns/ASSIGN_REQUEST';
const ASSIGN_COMPLETED = 'campaigns/ASSIGN_COMPLETED';
const ASSIGN_FAILED = 'campaigns/ASSIGN_FAILED';

const DEASSIGN_REQUEST = 'campaigns/DEASSIGN_REQUEST';
const DEASSIGN_COMPLETED = 'campaigns/DEASSIGN_COMPLETED';
const DEASSIGN_FAILED = 'campaigns/DEASSIGN_FAILED';

export default {
    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,
    FETCH_DETAILS_REQUEST,
    FETCH_DETAILS_COMPLETED,
    FETCH_DETAILS_FAILED,
    CREATE_REQUEST,
    CREATE_COMPLETED,
    CREATE_FAILED,
    UPDATE_REQUEST,
    UPDATE_COMPLETED,
    UPDATE_FAILED,
    CANCEL_REQUEST,
    CANCEL_COMPLETED,
    CANCEL_FAILED,
    DUPLICATE_REQUEST,
    DUPLICATE_COMPLETED,
    DUPLICATE_FAILED,
    APPROVE_REQUEST,
    APPROVE_COMPLETED,
    APPROVE_FAILED,
    ASSIGN_REQUEST,
    ASSIGN_COMPLETED,
    ASSIGN_FAILED,
    DEASSIGN_REQUEST,
    DEASSIGN_COMPLETED,
    DEASSIGN_FAILED,
};
