import { default as actions } from './actions';
import {
    getCollectionFilter,
    getCollectionPaginationLinks,
    getCollectionController,
} from './selectors';

import collections from './collections';

const cancelApply = (collection_key) => (dispatch, getState) => {
    dispatch(actions.cancelApply(collection_key));
    const controller = getCollectionController(getState(), collection_key);
    if (!controller) {
        return null;
    }
    return controller.abort();
};

const applyFilter = (collection_key, asset_ids = null) => (dispatch, getState) => {
    dispatch(cancelApply(collection_key));
    const state = getState();
    let filters = getCollectionFilter(state, collection_key);
    const collection = collections[collection_key];
    if (collection.filtersUpdate) {
        filters = collection.filtersUpdate(state, filters);
    }
    let _asset_ids = [];
    if (asset_ids) {
        _asset_ids = asset_ids;
    }
    return dispatch(actions.applyFilter(filters, _asset_ids, collection_key));
};

const { setField } = actions;

const field_set_timer = {};

const setFieldAndApply = (field, payload, collection, timeout = 0, apply = true) => (dispatch) => {
    // dispatch the field
    dispatch(setField(collection, field, payload));
    if (!field_set_timer[collection]) {
        field_set_timer[collection] = {};
    }
    const _applyFilter = () => dispatch(applyFilter(collection));
    clearTimeout(field_set_timer[collection][field]);
    if (apply) {
        field_set_timer[collection][field] = setTimeout(_applyFilter, timeout);
    }
};

const navigate = (rel, collection) => (dispatch, getState) => {
    const links = getCollectionPaginationLinks(getState(), collection);
    if (!links.hasOwnProperty(rel)) return Promise.resolve();
    return dispatch(actions.navigate(rel, links, collection));
};

const resetFilter = (collection, apply = true) => (dispatch) => {
    dispatch(actions.resetFilter(collection));
    if (apply) dispatch(applyFilter(collection));
};

export default {
    setField,
    setFieldAndApply,
    cancelApply,
    applyFilter,
    resetFilter,
    navigate,
};
