/* eslint-disable react/no-array-index-key */
import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import './FlexTable.scss';

function __guard__(value, transform) {
    return (typeof value !== 'undefined' && value !== null) ? transform(value) : null;
}

const defaultCellRenderer = (cellData) => (
    <span>
        { cellData != null ? cellData.toString() : null }
    </span>
);

const dateCellRenderer = (cellData) => {
    if ((cellData == null)) {
        return (
            <span>
                {' '}
                -
                {' '}
            </span>
        );
    }
    return (
        <span>
            { moment(cellData).format('DD/MM/YYYY HH:mm') }
        </span>
    );
};

const dateFromNowCellRenderer = (cellData) => {
    if ((cellData == null)) {
        return (
            <span>
                {' '}
                -
                {' '}
            </span>
        );
    }
    const d = moment(cellData);
    return (
        <span>
            { `${d.format('DD/MM/YYYY')} (${i18n.t('date_from_now', { date: d.toNow(true) })})` }
        </span>
    );
};

const floatCellRenderer = (cellData) => (
    <span>
        { cellData != null ? cellData.toFixed() : null }
    </span>
);

const downloadCellRenderer = (cellData) => (
    <span>
        <a href={`download/${cellData != null ? cellData.toString() : null}`}>
            <button className="btn btn-link btn-large btn-raised" type="button" style={{ margin: 5 }}>
                { cellData != null ? cellData.toString() : null }
            </button>
        </a>
    </span>
);

const FlexTableSeparator = (props) => (
    <tbody className="table-separator">
        <tr />
        <tr>
            <td colSpan={props.colSpan} className="separator-line">
                <div className="separator-table">
                    <div className="separator-boxed-container">
                        <div className="separator-boxed">
                            {
                                props.expandable_callback ? (
                                    <a
                                        onClick={props.expandable_callback}
                                        className="dropdown-toggle"
                                        data-toggle="dropdown"
                                        href="#"
                                    >
                                        <span
                                            className={`filter-box ${props.expanded ? 'dropup' : ''}`}
                                        >
                                            {props.label}
                                            <span className="caret" />
                                        </span>
                                    </a>
                                ) : (
                                    <span>{ props.label }</span>
                                )
                            }
                        </div>
                    </div>
                    <div className="dashed-container">
                        <div className="dashed-line" />
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
);

class FlexTableHeader extends React.Component {
    constructor(props) {
        super(props);
        this.renderSortableColumn = this.renderSortableColumn.bind(this);
    }

    renderSortableColumn(key) {
        const key_desc = key.includes('-') ? key : `-${key}`;
        const activated = this.props.index_sort.includes(key)
            || this.props.index_sort.includes(key_desc);
        const ascendant = activated && this.props.index_sort.includes(key);
        const position = ascendant
            ? this.props.index_sort.indexOf(key) + 1
            : this.props.index_sort.indexOf(key_desc) + 1;

        if (!activated) {
            return (<span className="sortable flex-table-header-label glyphicon glyphicon-resize-vertical" />);
        } if (ascendant) {
            return (<span className="sortable flex-table-header-label glyphicon glyphicon-chevron-down">{position}</span>);
        }
        return (<span className="sortable flex-table-header-label glyphicon glyphicon-chevron-up">{position}</span>);
    }

    render() {
        return (
            <thead>
                <tr>
                    {
                        this.props.columns.map((col, k) => {
                            const { label } = col;
                            const sortableColumn = !!(this.props.sortCallback
                                && col.sortable
                                && this.props.index_sort);
                            return (
                                <th
                                    key={`${k}_${col.key}`}
                                    className={`${sortableColumn ? 'sortable' : ''} flex-table-header-label ${col.custom_class}`}
                                    onClick={
                                        (e) => {
                                            e.preventDefault();
                                            if (sortableColumn) this.props.sortCallback(col.key);
                                        }
                                    }
                                >
                                    { label }
                                    {' '}
                                    { sortableColumn
                                        ? this.renderSortableColumn(col.key)
                                        : null}
                                </th>
                            );
                        })
                    }
                    {
                        this.props.selectable ? (
                            this.props.selectAllCallback ? (
                                <th style={{ textAlign: 'right' }} onClick={(evt) => evt.stopPropagation()}>
                                    <input
                                        checked={this.props.isAllSelected}
                                        type="checkbox"
                                        className="fat_checkbox"
                                        onChange={(evt) => {
                                            evt.stopPropagation();
                                            this.props.selectAllCallback();
                                        }}
                                    />
                                </th>
                            ) : <th />
                        ) : null
                    }
                    {
                        this.props.expandable ? (
                            <th className="flex-table-header-label expandLabel">
                                {
                                    this.props.expandLabel ? this.props.expandLabel : null
                                }
                            </th>
                        ) : null
                    }
                    {
                        this.props.deletable ? <th className="flex-table-header-label deletable" /> : null
                    }
                </tr>
            </thead>
        );
    }
}

const SelectableRenderer = (props) => {
    if (!props.prefs || (props.prefs != null ? props.prefs.selectable : null)) {
        let callback;
        let classNames = '';
        if (__guard__(props.prefs != null ? props.prefs.selectable : null, (x) => x.disabled)) {
            classNames += ' disabled';
            callback = null;
        } else {
            callback = (e) => {
                e.stopPropagation();
                props.selectCallback(
                    props.row_id,
                    e.target.checked,
                );
            };
        }
        return (
            <td onClick={(e) => e.stopPropagation()} className="icon not_expand">
                <input
                    checked={props.selected}
                    type="checkbox"
                    className={`${classNames} fat_checkbox`}
                    onClick={callback}
                    onChange={() => {}}
                />
            </td>
        );
    }
    return (<td />);
};

const ExpandedRenderer = (props) => {
    if (props.expanded) {
        if (props.collapseActionRenderer) {
            return props.collapseActionRenderer(props.row_id, props.row, props.key_number, 'collapse', null);
        }
        return (
            <span title={i18n.t('Collapse')} />
        );
    } if (props.expandActionRenderer) {
        return props.expandActionRenderer(props.row_id, props.row, props.key_number, 'expand', null);
    }
    return (
        <span title={i18n.t('Expand')} className="glyphicon glyphicon-ok" />
    );
};

const ExpandableRenderer = (props) => (
    <td
        className="not_expand shrink"
        onClick={(e) => e.stopPropagation()}
    >
        <div
            className="clickable not_expand"
            onClick={() => {
                if (props.expanded) {
                    props.collapseRow(props.row_id);
                } else {
                    props.expandRow(props.row_id);
                }
            }}
        >
            <ExpandedRenderer
                expanded={props.expanded}
                collapseActionRenderer={props.collapseActionRenderer}
                expandActionRenderer={props.expandActionRenderer}
                row_id={props.row_id}
                row={props.row}
                key_number={props.key_number}
            />
        </div>
    </td>
);

const FlexTableBody = (props) => {
    const openDetail = (props.openDetailCallback !== null);
    const expandable = (props.expandActionRenderer !== null);
    const rows = [];
    if (props.data_expanded === false) {
        return (<tbody />);
    }
    let i = 0;
    for (const key in props.data) {
        if (props.limited && i >= 5) {
            continue;
        }
        let selectable = (props.selectCallback !== null);
        let deletable = (props.deleteCallback !== null);
        const row = props.data[key];
        if (deletable && props.checkDeletable) {
            deletable = props.checkDeletable(row);
        }
        if (props.isSelectable && props.selectCallback) {
            selectable = props.isSelectable(row);
        }
        const roww_id = row[props.id_column];
        const prefs = row.flextable_preferences;
        // eslint-disable-next-line no-loop-func
        ((row_id) => {
            const selected = props.selected[row_id] || false;
            const expanded = props.expanded[row_id] || false;
            if (!props.isValidateData || props.isValidateData(row)) {
                rows.push(
                    <tr
                        key={`tbody_tr_${key}`}
                        className={`${openDetail ? 'clickable' : ''} ${selected ? 'active' : ''}`}
                    >
                        {
                            props.columns.map((col, k) => {
                                const renderer = col.renderer || defaultCellRenderer;
                                const shrink = col.shrink ? ' shrink' : '';
                                const className = col.className ? col.className : '';
                                const { preventOpenDetailCallback } = col;
                                return (
                                    <td
                                        key={`tbody_tr_${key}_td_${k}`}
                                        className={`not_expand${shrink} ${className}`}
                                        data-label={col.label}
                                        onClick={() => {
                                            if (openDetail && !preventOpenDetailCallback) {
                                                props.openDetailCallback(row_id);
                                            }
                                        }}
                                    >
                                        {renderer(row[col.key], row, key, col, k) || <span />}
                                    </td>
                                );
                            })
                        }
                        {
                            selectable ? (
                                <SelectableRenderer
                                    prefs={prefs}
                                    selected={selected}
                                    row_id={row_id}
                                    selectCallback={props.selectCallback}
                                />
                            ) : null
                        }
                        {
                            expandable ? (
                                <ExpandableRenderer
                                    expanded={expanded}
                                    collapseRow={props.collapseRow}
                                    expandRow={props.expandRow}
                                    collapseActionRenderer={props.collapseActionRenderer}
                                    expandActionRenderer={props.expandActionRenderer}
                                    row_id={row_id}
                                    row={row}
                                    key_number={key}
                                />
                            ) : null
                        }
                        {
                            deletable ? (
                                <td
                                    className="clickable icon not_expand shrink"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        props.deleteCallback(row_id);
                                    }}
                                >
                                    <span
                                        title={i18n.t(props.delete_message === 'Remove event from campaign' ? 'Remove event from campaign' : 'Remove event from intervention')}
                                        className="glyphicon glyphicon-trash"
                                    />
                                </td>
                            ) : null
                        }
                    </tr>,
                ); // end of rows.push(
            }
            if (expanded) {
                // dummy row so that alternate colors on the table stays coherent
                rows.push(
                    <tr key={`tbody_tr_${key}_expanded_dummy`} style={{ padding: 0, margin: 0 }} />,
                );
                rows.push(
                    <tr key={`tbody_tr_${key}_expanded`} className="expand">
                        <td className="expand" style={{ padding: 0 }} colSpan={props.colSpan}>
                            { props.expandCellRenderer(row_id, props.collapseRow) }
                        </td>
                    </tr>,
                );
            }
            i += 1;
        })(roww_id);
    }

    return (
        <tbody>
            { rows }
        </tbody>
    );
};

class FlexTable extends React.Component {
    constructor(props) {
        super(props);
        this.expandRow = this.expandRow.bind(this);
        this.collapseRow = this.collapseRow.bind(this);
    }

    expandRow(row_elem_id) {
        this.props.expandCallback(row_elem_id, true);
    }

    collapseRow(row_elem_id) {
        this.props.expandCallback(row_elem_id, false);
    }

    render() {
        let colSpan = this.props.columns.length;
        if (this.props.openDetailCallback != null) {
            colSpan += 1;
        }
        if (this.props.selectCallback != null) {
            colSpan += 1;
        }
        if (this.props.deleteCallback != null) {
            colSpan += 1;
        }
        if (this.props.expandCallback != null) {
            colSpan += 1;
        }

        let expanded = true;
        return (
            <table className={`flextable table table-striped ${this.props.hoverable ? 'table-hover' : ''} ${this.props.customClass}`}>
                <FlexTableHeader
                    columns={this.props.columns}
                    openDetail={(this.props.openDetailCallback != null)}
                    selectable={(this.props.selectCallback != null)}
                    deletable={(this.props.deleteCallback != null)}
                    expandable={(this.props.expandCallback != null)}
                    expandLabel={this.props.expandLabel}
                    selectAllCallback={this.props.selectAllCallback}
                    isAllSelected={this.props.isAllSelected}
                    sortCallback={this.props.sortCallback}
                    index_sort={this.props.index_sort}
                />
                {
                    this.props.data.map((data, key) => {
                        if (Array.isArray(data)) {
                            return (
                                <FlexTableBody
                                    key={`table_body_${key}`}
                                    columns={this.props.columns}
                                    data={data}
                                    openDetailCallback={this.props.openDetailCallback}
                                    selectCallback={this.props.selectCallback}
                                    isSelectable={this.props.isSelectable}
                                    deleteCallback={this.props.deleteCallback}
                                    checkDeletable={this.props.checkDeletable}
                                    expandActionRenderer={
                                        (this.props.expandCallback != null)
                                            ? this.props.expandActionRenderer
                                            : null
                                    }
                                    collapseActionRenderer={
                                        (this.props.expandCallback != null)
                                            ? this.props.collapseActionRenderer
                                            : null
                                    }
                                    expandCellRenderer={this.props.expandCellRenderer}
                                    expandRow={this.expandRow}
                                    collapseRow={this.collapseRow}
                                    id_column={this.props.id_column}
                                    selected={this.props.selected}
                                    expanded={this.props.expanded}
                                    colSpan={colSpan}
                                    isValidateData={this.props.isValidateData}
                                    delete_message={this.props.delete_message}
                                    data_expanded={expanded}
                                    limited={this.props.limited}
                                />
                            );
                        }
                        expanded = data.expanded;
                        return (
                            <FlexTableSeparator
                                key={`table_body_${key}`}
                                colSpan={colSpan}
                                label={data.separator_label}
                                expandable_callback={data.expandable_callback}
                                expanded={data.expanded}
                            />
                        );
                    })
                }
            </table>
        );
    }
}

FlexTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string,
            renderer: PropTypes.func,
        }),
    ),
    data: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.object),
            PropTypes.shape({
                separator_label: PropTypes.string.isRequired,
            }),
        ]),
    ),
    selectCallback: PropTypes.func,
    selectAllCallback: PropTypes.func,
    openDetailCallback: PropTypes.func,
    expandCallback: PropTypes.func,
    expandActionRenderer: PropTypes.func,
    expandCellRenderer: PropTypes.func,
    isAllSelected: PropTypes.bool,
    selected: PropTypes.object,
    id_column: PropTypes.string,
    hoverable: PropTypes.bool,
    expanded: PropTypes.object,
    delete_message: PropTypes.string,
    sortCallback: PropTypes.func,
    deleteCallback: PropTypes.func,
    checkDeletable: PropTypes.func,
    index_sort: PropTypes.array,
    limit: PropTypes.number,
    customClass: PropTypes.string,
};

FlexTable.defaultProps = {
    columns: [],
    data: [],
    selectCallback: null,
    selectAllCallback: null,
    openDetailCallback: null,
    expandCallback: null,
    expandActionRenderer: null,
    expandCellRenderer: null,
    selected: {},
    expanded: {},
    id_column: 'id',
    hoverable: true,
    delete_message: 'Remove event from intervention',
    deleteCallback: null,
    sortCallback: null,
    checkDeletable: null,
    index_sort: [],
    limit: null,
    customClass: '',
};

export default {
    FlexTable,
    renderers: {
        defaultCellRenderer,
        dateCellRenderer,
        dateFromNowCellRenderer,
        downloadCellRenderer,
        floatCellRenderer,
    },
};
