/**
 * Created by lpostula on 10/05/17.
 */
import { combineReducers } from 'redux';
import types from './types';
import { createReducer } from '../../utils';

/* State shape
{
    me: user,
    all: { user_id: user,  },
}
 */

// There is a strange comportement here at the first glance:
//     both the meReducer and the listReducer match the same action, it is done in order
//     to have the correct data at both point
// (it is still strange at the second glance)

const meReducer = createReducer(null)({
    [types.FETCH_ME_COMPLETED]: (state, action) => action.payload,
    [types.SET_LANGUAGE_COMPLETED]: (state, action) => ({ ...state, ...action.payload }),
    [types.SET_TIMEZONE_COMPLETED]: (state, action) => ({ ...state, ...action.payload }),
});

const listReducer = createReducer({})({
    [types.FETCH_LIST_COMPLETED]: (state, action) => {
        let new_state;
        if (action.meta.long_polling) {
            new_state = {};
        } else {
            new_state = { ...state };
        }
        if (!action?.payload) return new_state;
        for (const user of action.payload) {
            new_state[user.id] = user;
        }
        return new_state;
    },
    [types.SET_LANGUAGE_COMPLETED]: (state, action) => {
        const new_state = { ...state };
        new_state[action.payload.id] = action.payload;
        return new_state;
    },
    [types.SET_TIMEZONE_COMPLETED]: (state, action) => {
        const new_state = { ...state };
        new_state[action.payload.id] = action.payload;
        return new_state;
    },
});

export default combineReducers({
    me: meReducer,
    all: listReducer,
});
