/**
 * Created by lpostula on 05/12/17.
 */

const CONNECTING = 'websocket/CONNECTING';
const RECONNECTING = 'websocket/RECONNECTING';
const OPENED = 'websocket/OPENED';
const MESSAGED = 'websocket/MESSAGED';
const ERROR = 'websocket/ERROR';
const CLOSED = 'websocket/CLOSED';
const INC_ATTEMPT = 'websocket/INC_ATTEMPT';
const REVERT_TO_POLLING = 'websocket/REVERT_TO_POLLING';
const REGISTER_TO_STREAM = 'websocket/REGISTER_TO_STREAM';
const UNREGISTER_TO_STREAM = 'websocket/UNREGISTER_TO_STREAM';
const REGISTER_TO_FLEET_STREAM = 'websocket/REGISTER_TO_FLEET_STREAM';
const UNREGISTER_TO_FLEET_STREAM = 'websocket/UNREGISTER_TO_FLEET_STREAM';

export default {
    CONNECTING,
    RECONNECTING,
    OPENED,
    MESSAGED,
    ERROR,
    CLOSED,
    INC_ATTEMPT,
    REVERT_TO_POLLING,
    REGISTER_TO_STREAM,
    UNREGISTER_TO_STREAM,
    REGISTER_TO_FLEET_STREAM,
    UNREGISTER_TO_FLEET_STREAM,
};
