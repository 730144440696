import React from 'react';
import './FormLabel.scss';
import HintIcon from '../../../display/hintIcon/HintIcon';

const FormLabel = (props) => {
    if (!props.label) return null;
    return (
        <div className={`form_label ${props.className ? props.className : ''}${props.required ? ' mandatory' : ''}`}>
            <div className="form_label_text">{props.label}</div>
            {
                props.hint
                    && (
                        <HintIcon
                            text={props.hint}
                        />
                    )
            }
        </div>
    );
};

export default FormLabel;
