import React, { useState, useEffect, useContext } from 'react';
import i18n from 'i18next';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import components from 'components';
import { interventionsOperations, interventionsSelectors } from 'railfleet_state/ducks/interventions';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { uxSelectors, uxOperations } from 'railfleet_state/ducks/ux';
import { locationsOperations } from 'railfleet_state/ducks/locations';
import TextAction from 'components/buttons/textAction/TextAction';
import InterventionDetail from '../../details/interventionDetail/InterventionDetail';
import EventsPart from '../../lists/eventsPart/EventsPart';
import OperationalStatusForm from '../operationalStatusForm/OperationalStatusForm';
import './InterventionWorkflow.scss';
import getInterventionSteps, { getCurrentStep } from './steps';
import AuthorizedComponent from '../../../components/app/AuthorizedComponent';
import InterventionForm from '../interventionForm/InterventionForm';
import { LoaderContext } from '../../../components/containers/Modals/Modal';
import FormDateTime from '../../../components/forms/RailForms/formDateTime/FormDateTime';
import RtsForm from '../rtsForm/RtsForm';
import ConfirmationDialog from '../../../components/containers/ConfirmationDialog/ConfirmationDialog';

const { NumberedTitle, HeaderModal } = components.headers;
const { CancelButton, EditButton } = components.buttons;
const { StateTimeline } = components.display;
const { FocusableFrame } = components.frames;
const { DateTimeSelector } = components.filters;
const { FormManager, FormTimeline, CutForm } = components.forms;
const { MultiDeviceLayout } = components.app;
const { usePerm } = components.hooks;

const whichWidget = {
    planned: <OperationalStatusForm />,
    transfer_in: null,
    wait_in: null,
    started: <EventsPart />,
    wait_out: null,
    transfer_out: <OperationalStatusForm />,
};

const labels = {
    planned: i18n.t('Change asset operational status'),
    transfer_in: null,
    wait_in: null,
    started: i18n.t('Events part of this intervention'),
    wait_out: null,
    transfer_out: i18n.t('Change asset operational status'),
};

const MoveIntervention = (props) => {
    const [_from, setFrom] = useState(props.intervention.date_start_planned);
    const [_locked, setLocked] = useState(false);

    const format_data = (data) => {
        const formated_data = {};
        formated_data.id = props.intervention.id;
        if (data.date_start_planned && !['cancelled', 'done', 'in_progress'].includes(props.intervention.state)) {
            formated_data.date_start_planned = data.date_start_planned.toISOString();
        }
        formated_data.date_done_planned = data.date_done_planned.toISOString();
        return formated_data;
    };

    const onSubmit = (arg) => {
        if (_locked) return;
        setLocked(true);
        const payload = format_data(arg);
        props.updateIntervention(payload).then(() => {
            setLocked(false);
        });
    };

    const onChangeFrom = (arg) => {
        setFrom(arg);
    };

    const defaultValues = {
        date_start_planned: props.intervention.date_start_planned,
        date_done_planned: props.intervention.date_done_planned,
    };

    return (
        <div className="intervention-workflow-move">
            <FormManager
                onSubmit={onSubmit}
                textSubmitButton={i18n.t('Move Intervention')}
                primary={false}
                disabled={_locked || ['cancelled', 'done'].includes(props.intervention.state)}
            >
                <CutForm
                    device={props.device}
                >
                    <FormDateTime
                        id="date_start_planned"
                        defaultValue={
                            defaultValues
                            && defaultValues.date_start_planned
                            && moment(defaultValues.date_start_planned)
                        }
                        label={i18n.t('Planned Start Date')}
                        className="trs-datepicker-container"
                        required
                        onChange={onChangeFrom}
                        late={props.lateStart}
                        disabled={['cancelled', 'done', 'in_progress'].includes(props.intervention.state)}
                    />
                    <div className="arrow-separator">
                        <span className="glyphicon glyphicon-arrow-right" />
                    </div>
                    <FormDateTime
                        id="date_done_planned"
                        defaultValue={
                            defaultValues
                            && defaultValues.date_done_planned
                            && moment(defaultValues.date_done_planned)
                        }
                        label={i18n.t('Planned End Date')}
                        className="trs-datepicker-container"
                        minDate={_from || defaultValues.date_start_planned}
                        required
                        late={props.lateDone}
                        disabled={['cancelled', 'done'].includes(props.intervention.state)}
                    />
                </CutForm>
            </FormManager>
        </div>
    );
};

const InterventionDetailTab = (props) => (
    <div>
        <div className={`intervention-workflow-detail${props.editMode ? ' edit-mode' : ''}`}>
            {
                props.editMode ? (
                    <InterventionForm
                        intervention_id={props.intervention_id}
                        asset_id={props.asset_id}
                        ecm_id={props.ecm_id}
                        datesTimeline={props.datesTimeline}
                        cancelCallback={() => props.setEditMode(false)}
                        callback={() => props.setEditMode(false)}
                    />
                ) : (
                    <InterventionDetail
                        intervention_id={props.intervention_id}
                        asset_id={props.asset_id}
                    />
                )
            }
        </div>
        {
            !props.editMode
                && (
                    <div className="intervention-workflow-events">
                        {
                            !props.opened
                            && (
                                <EventsPart
                                    intervention_id={props.intervention_id}
                                    location_id={props.poi}
                                    readOnly={props.readOnly}
                                    framed
                                    nFrom={window.location.href}
                                    nToViewEvent={props.nToViewEvent}
                                    fromIntervention
                                    createEventRedirect={props.createEventRedirect}
                                />
                            )
                        }
                    </div>
                )
        }
    </div>
);

const CancelButtonManager = (props) => {
    const [_display, setDisplay] = useState(false);
    useEffect(() => {
        let show = false;
        if (props.intervention.state !== 'cancelled' && props.intervention.state !== 'done') {
            show = true;
            if (props.intervention.interventioneventaction) {
                for (const event of props.intervention.interventioneventaction) {
                    if (event.result === 'closed') show = false;
                }
            }
        }
        setDisplay(show);
    }, [props.intervention]);

    if (!_display) return null;
    return (
        <AuthorizedComponent
            asset_id={props.asset_id}
            permission_name="cancel_intervention"
            no_message
        >
            <CancelButton
                text={i18n.t('Cancel intervention')}
                onClick={() => props.cancelIntervention(props.intervention)}
                disabled={props.editMode}
            />
        </AuthorizedComponent>
    );
};

const InterventionFormComponent = (props) => {
    const [_date, setDate] = useState(moment());
    const [_children, setChildren] = useState(null);
    const [_widget, setWidget] = useState(null);
    const [_labelFrame, setLabelFrame] = useState(null);
    const [_steps, setSteps] = useState(null);
    const [_currentStep, setCurrentStep] = useState({});
    const [_displayEmptyForm, setDisplayEmptyForm] = useState(false);
    const [_timeline_data, setTimelineData] = useState({});
    const [_opened, setOpened] = useState(false);
    const [_editMode, setEditMode] = useState(props.editMode || false);
    const [_areAllEventsClosed, setAreALlEventsClosed] = useState(false);
    const [_readOnlyEvents, setReadOnlyEvent] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [_datesTimeline, setDatesTimeline] = useState({});
    const { registerDataToLoad } = useContext(LoaderContext);
    const [_pushInfos, setPushInfos] = useState(null);
    const [_minDate, setMinDate] = useState(null);
    const [_lateStart, setLateStart] = useState(false);
    const [_lateDone, setLateDone] = useState(false);
    const hasPerm = usePerm(props.asset_id || null);
    const [_dialog, setDialog] = useState(null);

    const getInterventionStepIndex = (step_state) => {
        for (const index in _steps) {
            const in_step = _steps[index];
            if (in_step.state === step_state) return index;
        }
        return null;
    };

    const getInterventionStepByIndex = (lf_index) => {
        for (const index in _steps) {
            const in_step = _steps[index];
            if (parseInt(index, 10) === lf_index) return in_step;
        }
        return null;
    };

    useEffect(() => {
        if ((!props.intervention || _.isEmpty(props.intervention)) && props.intervention_id) {
            props.fetchInterventionDetail(props.intervention_id);
        }
        if (props.intervention_id) {
            props.fetchLocationForIntervention(props.intervention_id);
        }
    }, [props.intervention_id]);

    useEffect(() => {
        let date = null;
        if (props.intervention && _currentStep) {
            const index = getInterventionStepIndex(_currentStep.state);
            const step = getInterventionStepByIndex(index - 1);
            if (step) {
                date = props.intervention[step.field];
            }
        }
        setMinDate(date);
    }, [props.intervention, _currentStep, _steps]);

    useEffect(() => {
        setPushInfos(props.push_infos);
        props.setPushInfos(null, 'intervention workflow');
    }, []);

    useEffect(() => {
        registerDataToLoad({
            id: 'intervention_interventionWorkflow',
            loaded: props.intervention && props.intervention.id,
        });
        let lateStart = false;
        let lateDone = false;
        if (props.intervention.state === 'planned') {
            const date_start_planned = moment(props.intervention.date_start_planned);
            const date_done_planned = moment(props.intervention.date_done_planned);
            if (moment() > date_start_planned) lateStart = true;
            if (moment() > date_done_planned) lateDone = true;
        } else if (props.intervention.state === 'in_progress') {
            let intervention_type_impacting_date = 'date_start_actual';
            if (props.intervention && props.intervention.has_handover_process) {
                intervention_type_impacting_date = 'date_transfer_in';
            }
            const date_start_planned = moment(props.intervention.date_start_planned);
            const date_done_planned = moment(props.intervention.date_done_planned);
            const date_start_actual = moment(props.intervention[intervention_type_impacting_date]);
            if (date_start_actual > date_start_planned) lateStart = true;
            if (moment() > date_done_planned) lateDone = true;
        } else if (props.intervention.state === 'done') {
            const date_done_planned = moment(props.intervention.date_done_planned);
            const date_done_actual = moment(props.intervention.date_done_actual);
            if (date_done_actual > date_done_planned) lateDone = true;
        }
        setLateStart(lateStart);
        setLateDone(lateDone);
    }, [props.intervention]);

    useEffect(() => {
        window.pausePolling(['all_assets_live_data']);
        if (window.pause_timeline_polling) {
            window.pause_timeline_polling();
        }
        return () => {
            window.unpausePolling(['all_assets_live_data']);
            if (window.unpause_timeline_polling) {
                window.unpause_timeline_polling();
            }
        };
    }, []);

    useEffect(() => {
        if (_editMode && _opened) {
            setOpened(false);
        }
    }, [_editMode]);

    useEffect(() => {
        const readOnly = !_currentStep || Object.keys(_currentStep).length === 0 || _currentStep.state === 'cancelled';
        if (_currentStep) {
            const displayOpeStatusForm = (hasPerm('edit_status') || hasPerm('aggravate_status'));
            if ((_currentStep.state === 'planned' || _currentStep.state === 'transfer_out') && !displayOpeStatusForm) {
                // TODO remove whichWidget
                setWidget(null);
                setLabelFrame(null);
            } else {
                const widget = whichWidget[_currentStep.state];
                const label = labels[_currentStep.state];
                setWidget(widget);
                setLabelFrame(label);
            }
        }
        setReadOnlyEvent(readOnly);
    }, [_currentStep]);

    // get all the steps of the intervention
    useEffect(() => {
        if (props.intervention) {
            const steps = getInterventionSteps(props.intervention);
            setSteps(steps);
        }
    }, [props.intervention]);

    // set the current step of the intervention
    useEffect(() => {
        if (props.intervention && _steps) {
            const current_step = getCurrentStep(props.intervention, _steps);

            const timeline_data = { ..._timeline_data };
            _steps.map((step) => {
                const value = props.intervention[step.field];
                if (!value) return null;
                const user = props.users[props.intervention[step.user_field]];
                timeline_data[step.state] = {
                    label: user && user.full_name,
                    value: moment(value).format('DD/MM/YYYY HH:mm'),
                };
                return null;
            });
            setCurrentStep(current_step);
            setTimelineData(timeline_data);
        }
    }, [_steps, props.intervention]);

    useEffect(() => {
        setDisplayEmptyForm(false);
    }, [_currentStep]);

    const onChangeDisplay = (val) => {
        setDisplayEmptyForm(!val);
    };

    const onCancel = () => {
        setOpened(false);
    };

    const eventPartCallback = (allClosed) => {
        setAreALlEventsClosed(allClosed);
    };

    const onClickYesChangeStatus = () => {
        const local_from = `/asset/${props.asset_id}/interventions/${props.intervention_id}/view`;
        const push_infos = {
            from: local_from,
            extras: {
                id: props.intervention_id,
                asset: props.asset_id,
            },
        };

        props.setPushInfos(push_infos);
        props.router.push(`/asset/${props.asset_id}`);
    };

    const updateInterventionDate = ({
        date,
        intervention,
        rts = null,
    }) => {
        if (date && intervention) {
            setOpened(false);
            return props.updateInterventionDate(intervention.id, date, rts).then(() => {
                if (!_.isEmpty(rts)) {
                    setDialog(ConfirmationDialog({
                        className: 'inModal',
                        variant: 'info',
                        confirmation_message: i18n.t('Intervention successfully finished.'),
                        description_message: i18n.t('Do you want to change current asset operational status ?'),
                        buttons_text: ['No', 'Yes'],
                        inModal: true,
                        onClickResolve: () => onClickYesChangeStatus(),
                        onClickReject: () => setDialog(null),
                        onClickOutside: () => null,
                    }));
                }
                return Promise.resolve();
            });
        }
        return Promise.resolve();
    };

    useEffect(() => {
        if (_widget) {
            const clonedChildren = React.cloneElement(_widget, {
                ...props,
                date_action: _date,
                onChange: onChangeDisplay,
                textSubmit: _currentStep.nextStepLabel,
                eventPartCallback,
                operationalStatusCallback: updateInterventionDate,
                onCancel,
                canAddNewEvent: _opened,
                nToViewEvent: props.nToViewEvent,
                fromIntervention: true,
                createEventRedirect: props.createEventRedirect,
            });
            setChildren(clonedChildren);
        } else {
            setChildren(null);
        }
    }, [_widget, _date]);

    const onChangeTimeline = ({ id, date }) => {
        _datesTimeline[id] = date;
    };

    const onChangeFormTimeline = (id, date) => {
        _datesTimeline[id] = date;
    };

    const onClickPrevious = () => {
        setEditMode(false);
    };

    const detailRenderer = () => (
        <div className="intervention-refs">
            <div className="intervention-ref">
                <span>{ i18n.t('Réf') }</span>
                {
                    props.intervention && props.intervention.id ? (
                        <span>{`IN${props.intervention.id}`}</span>
                    ) : (
                        <span>-</span>
                    )
                }
            </div>
            <div className="intervention-ref">
                <span>{ i18n.t('Name') }</span>
                {
                    props.intervention && props.intervention.name ? (
                        <span>{ props.intervention.name }</span>
                    ) : (
                        <span>-</span>
                    )
                }
                {
                    props.is_railnovian_impersonating && props.intervention?.id && (
                        <TextAction
                            onClick={() => {
                                const link = `/admin/maintenance/intervention/?q=${props.intervention.id}`;
                                window.open(
                                    link,
                                    '_blank',
                                );
                            }}
                            text={i18n.t('View in admin')}
                        />
                    )
                }
            </div>
        </div>
    );

    const actionRenderer = () => (
        <div className="intervention-workflow-header-actions">
            <CancelButtonManager
                {...props}
                editMode={_editMode}
            />
            {
                !_editMode
                    && (
                        <AuthorizedComponent
                            asset_id={props.asset_id}
                            permission_name="edit_intervention"
                            no_message
                        >
                            <EditButton
                                text={i18n.t('Edit intervention')}
                                onClick={() => setEditMode(true)}
                            />
                        </AuthorizedComponent>
                    )
            }
        </div>
    );

    let FrameWidget;
    if (_currentStep && _currentStep.state === 'cancelled') {
        FrameWidget = (arg) => arg.children;
    } else {
        FrameWidget = FocusableFrame;
    }

    const onClickPreviousToEvent = () => {
        window.location.href = _pushInfos.from;
    };

    const displayOpeStatusForm = (hasPerm('edit_status') || hasPerm('aggravate_status'));

    if (!hasPerm('view_interventions')) return (<div>YOU DO NOT HAVE THE PERM: view_interventions</div>);
    return (
        <>
            <div className={`intervention-workflow ${props.device}`}>
                <HeaderModal
                    title={i18n.t('Intervention details')}
                    subtitle={_editMode ? i18n.t('Edit') : null}
                    onCloseModal={props.onCloseModal}
                    previous_link={_editMode ? onClickPrevious : (_pushInfos && onClickPreviousToEvent)}
                    actionsRenderer={actionRenderer}
                    detailRenderer={detailRenderer}
                />
                {
                    !_editMode && hasPerm('manage_intervention_date')
                        && (
                            <MoveIntervention
                                {...props}
                                lateStart={_lateStart}
                                lateDone={_lateDone}
                            />
                        )
                }
                <div className="intervention-workflow-timeline">
                    <div>
                        <FormManager>
                            {
                                _editMode || (_currentStep && _currentStep.state === 'cancelled') ? (
                                    <div className="state-timeline">
                                        {
                                            Object.keys(_timeline_data).length > 0
                                                && (
                                                    <FrameWidget
                                                        title={i18n.t('Timeline')}
                                                        className="timeline"
                                                    >
                                                        <FormTimeline
                                                            values={_timeline_data}
                                                            editMode={_editMode}
                                                            onChange={onChangeFormTimeline}
                                                            steps={_steps}
                                                            cancelled={_currentStep && _currentStep.state === 'cancelled'}
                                                        />
                                                    </FrameWidget>
                                                )
                                        }
                                    </div>
                                ) : (
                                    <StateTimeline
                                        asset_id={props.asset_id}
                                        steps={_steps}
                                        data={_timeline_data}
                                        selected={_currentStep && _currentStep.state}
                                        opened={_opened}
                                        buttonText={_currentStep && _currentStep.nextStepLabel}
                                        onClick={() => setOpened(true)}
                                        cancelled={_currentStep && _currentStep.state === 'cancelled'}
                                        editMode={_editMode}
                                        onChange={onChangeTimeline}
                                        late={_lateStart || _lateDone}
                                        done={!_currentStep}
                                    />
                                )
                            }
                        </FormManager>
                        {
                            _currentStep && _opened
                                && (
                                    <div className="intervention-workflow-timeline-context">
                                        <FocusableFrame>
                                            <NumberedTitle
                                                number="1"
                                                title={i18n.t('Choose a date')}
                                            />
                                            <div className="date-input">
                                                <DateTimeSelector
                                                    id="date"
                                                    name="date"
                                                    label={_currentStep.label}
                                                    selected={_date}
                                                    dateFormat="DD/MM/YYYY"
                                                    timeFormat="HH:mm"
                                                    onChange={(arg) => setDate(arg.value)}
                                                    minDate={_minDate}
                                                />
                                            </div>
                                            {
                                                !_children
                                                && (
                                                    <EmptyForm
                                                        {...props}
                                                        nextStepLabel={_currentStep.nextStepLabel}
                                                        date={_date}
                                                        setOpened={setOpened}
                                                    />
                                                )
                                            }
                                        </FocusableFrame>
                                        {
                                            _children
                                            && (
                                                <FocusableFrame>
                                                    <NumberedTitle
                                                        number="2"
                                                        title={_labelFrame}
                                                    />
                                                    { _children }
                                                    {
                                                        (_displayEmptyForm || _currentStep.state === 'started') && (_currentStep.field !== 'date_done_actual')
                                                        && (
                                                            <EmptyForm
                                                                {...props}
                                                                nextStepLabel={
                                                                    _currentStep.nextStepLabel
                                                                }
                                                                date={_date}
                                                                setOpened={setOpened}
                                                                disabled={!_areAllEventsClosed && _currentStep.state === 'started'}
                                                            />
                                                        )
                                                    }
                                                </FocusableFrame>
                                            )
                                        }
                                        {
                                            _currentStep.field === 'date_done_actual'
                                            && (
                                                <FocusableFrame>
                                                    <NumberedTitle
                                                        number="3"
                                                        title={i18n.t('Release to service (RTS)')}
                                                    />
                                                    <RtsForm
                                                        {...props}
                                                        date_action={_date}
                                                        textSubmit={_currentStep.nextStepLabel}
                                                        rtsCallback={
                                                            updateInterventionDate
                                                        }
                                                        onCancel={onCancel}
                                                        disabled={!_areAllEventsClosed}
                                                    />
                                                </FocusableFrame>
                                            )
                                        }
                                    </div>
                                )
                        }
                    </div>
                    <InterventionDetailTab
                        {...props}
                        editMode={_editMode}
                        setEditMode={setEditMode}
                        readOnly={_readOnlyEvents}
                        currentStep={_currentStep}
                        datesTimeline={_datesTimeline}
                        opened={_opened}
                    />
                </div>
            </div>
            { _dialog }
        </>
    );
};

const EmptyForm = (props) => {
    const [_locked, setLocked] = useState(false);
    const onCancel = () => {
        if (props.setOpened) props.setOpened(false);
    };

    const onSubmit = () => {
        if (props.date && props.intervention) {
            setLocked(true);
            const date = props.date.toISOString();
            props.updateInterventionDate(props.intervention_id, date).then(() => {
                setLocked(false);
                if (props.setOpened) props.setOpened(false);
            });
        }
    };

    return (
        <FormManager
            textSubmitButton={props.nextStepLabel}
            onCancel={onCancel}
            onSubmit={onSubmit}
            disabled={props.disabled || _locked}
        />
    );
};

const InterventionWorkflow = (props) => (
    <MultiDeviceLayout>
        <InterventionFormComponent {...props} />
    </MultiDeviceLayout>
);

const mapStateToProps = (state, props) => {
    const { intervention_id } = props;
    const intervention = intervention_id
        ? interventionsSelectors.getInterventionById(state, intervention_id)
        : null;
    const poi = intervention && intervention.poi;
    const asset_id = intervention && intervention.asset;
    const push_infos = uxSelectors.getPushInfos(state);
    const asset = asset_id && assetsSelectors.getAssetInfos(state, asset_id);
    const ecm_id = asset && asset.ecm_id;
    const is_railnovian_impersonating = (state.users.me != null && state.users.me.actual_user != null ? state.users.me.actual_user.email.endsWith('railnova.eu') : false);
    return {
        intervention: intervention || {},
        asset_id,
        ecm_id,
        users: state.users.all,
        poi,
        push_infos,
        is_railnovian_impersonating,
    };
};

const mapDispatchToProps = (dispatch, props) => ({
    fetchInterventionDetail: (intervention_id) => dispatch(
        interventionsOperations.fetchDetails(intervention_id),
    ),
    fetchLocationForIntervention: (intervention_id) => dispatch(
        locationsOperations.fetchForIntervention(intervention_id),
    ),
    cancelIntervention: (intervention) => dispatch(
        interventionsOperations.cancelIntervention(intervention, true),
    ).then(() => props.cancelCallback()),
    updateInterventionDate: (id, date, rts) => dispatch(
        interventionsOperations.updateInterventionDates(id, date, rts),
    ),
    updateIntervention: (values) => dispatch(
        interventionsOperations.updateIntervention(values),
    ),
    setPushInfos: (push_infos, from = null) => dispatch(uxOperations.setPushInfos(push_infos, from)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InterventionWorkflow);
