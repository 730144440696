/**
 * Created by lpostula on 11/05/17.
 */
import types from './types';
import { createReducer, apiHelpers } from '../../utils';

/* State shape
{
    {event_id: event},
}
 */

const initialState = {};

const listReducer = createReducer(initialState)({
    [types.FETCH_LIST_COMPLETED]: apiHelpers.fetch_list_complete,
    [types.FETCH_DETAILS_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.UPDATE_DETAILS_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.CREATE_EVENT_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.PROCESS_COMPLETED]: apiHelpers.fetch_list_complete,
    [types.DELETE_EVENT_COMPLETED]: apiHelpers.delete_completed,
});

export default listReducer;
