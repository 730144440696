import types from './types';
import { createReducer } from '../../utils';

const uxInitialState = {};

const uxReducer = createReducer(uxInitialState)({
    [types.SET_PUSH_INFOS]: (state, action) => ({
        ...state, push_infos: action.payload,
    }),
});

export default uxReducer;
