/**
 * Created by gknuts on 29/04/2022.
 */
import { RSAA } from 'redux-api-middleware';
import types from './types';
import { API_ROOT } from '../../config';

const API_RESOURCE_NAME = 'component_structures';

export const fetchList = (long_polling = false) => ({
    [RSAA]: {
        endpoint: '/campaign/api/next_component_structure.json',
        method: 'GET',
        credentials: 'same-origin',
        types: [
            {
                type: types.FETCH_LIST_REQUEST,
                meta: { long_polling },
            },
            {
                type: types.FETCH_LIST_COMPLETED,
                meta: { long_polling },
            },
            types.FETCH_LIST_FAILED,
        ],
    },
});

export const updateInStore = (data) => ({
    type: types.FETCH_DETAIL_COMPLETED,
    payload: data,
});

export const removeInStore = (group_id) => ({
    type: types.DELETE_COMPLETED,
    meta: {
        id: group_id,
    },
});

export default {
    fetchList,
    updateInStore,
    removeInStore,
};
