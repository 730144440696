import React from 'react';
import './CompactDuplicateDisplay.scss';
import i18n from 'i18next';
import OutLink from './out_link.svg';
import Chevron from './chevron.svg';

const CompactDuplicateDisplay = (props) => {
    const duplicat_count = props.duplicat?.length;
    const confirmed = !_.isEmpty(props.confirmed_duplicat);
    const confirmed_count = props.confirmed_duplicat?.length;
    const See = () => (
        <div className="see-holder">
            <div>{i18n.t('See')}</div>
            <img src={Chevron} alt=">" />
        </div>
    );

    if (!duplicat_count && !confirmed) return null;

    return (
        <div className="compact-duplicate-display-holder">
            <div className={`compact-duplicate-display${confirmed ? ' confirmed' : ''}`}>
                {
                    confirmed ? (
                        <>
                            <img src={OutLink} alt="out link" />
                            <div>{i18n.t('Confirmed as a duplicate of')}</div>
                            <div>{confirmed_count}</div>
                            <div>{i18n.t('similar event(s)')}</div>
                            <See />
                        </>
                    ) : (
                        <>
                            <img src={OutLink} alt="out link" />
                            <div>{duplicat_count}</div>
                            <div>{i18n.t('Similar event(s) found')}</div>
                            <See />
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default CompactDuplicateDisplay;
