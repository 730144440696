/**
 * Created by gknuts on 29/04/2022.
 */
const FETCH_LIST_REQUEST = 'component_structures/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'component_structures/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'component_structures/FETCH_LIST_FAILED';
const FETCH_DETAIL_REQUEST = 'component_structures/FETCH_DETAIL_REQUEST';
const FETCH_DETAIL_COMPLETED = 'component_structures/FETCH_DETAIL_COMPLETED';
const FETCH_DETAIL_FAILED = 'component_structures/FETCH_DETAIL_FAILED';
const DELETE_COMPLETED = 'component_structures/DELETE_COMPLETED';

export default {
    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,
    FETCH_DETAIL_REQUEST,
    FETCH_DETAIL_COMPLETED,
    FETCH_DETAIL_FAILED,
    DELETE_COMPLETED,
};
