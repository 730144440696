import React, { useEffect, useState } from 'react';
import components from 'components';
import i18n from 'i18next';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { maintenanceEventsOperations, maintenanceEventsSelectors } from 'railfleet_state/ducks/maintenance_events';
import { companiesSettingsSelectors } from 'railfleet_state/ducks/companies_settings';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { documentsSelectors, documentsOperations } from 'railfleet_state/ducks/documents';
import { operationsSelectors } from 'railfleet_state/ducks/operations';
import formatNumber from 'railfleet/utils/formatNumber';
import './PreventiveEventForm.scss';
import { COUNTER_UNIT_TRANSLATION } from '../../../../../dashboard/static/constants';
import scrollToTop from '../../../utils/scrollToTop';
import ComponentDisplay from '../../../components/display/componentDisplay/ComponentDisplay';
import FormComponent from '../../../components/forms/RailForms/formComponent/FormComponent';

const {
    FormManager,
    FormDateTime,
    FormTextInput,
    FormCustomField,
    CutForm,
    FormCheckbox,
    InputsWrapper,
    FormFile,
    FormCounter,
    FormCounterDoneUsage,
} = components.forms;

const { MultiDeviceLayout } = components.app;
const { TextDisplay } = components.display;
const { usePerm } = components.hooks;

const DueUsageInfo = ({
    counter, dueUsage, remainingUsage, event, increment_date,
}) => {
    const remaining_usage = remainingUsage || (event ? event.remaining_usage : null);
    if (!counter || !remaining_usage) return null;
    const unit = COUNTER_UNIT_TRANSLATION[counter.unit] || counter.unit;
    let due_usage = dueUsage;
    if (due_usage && typeof due_usage === 'number') {
        due_usage = formatNumber(due_usage);
    }
    return (
        <small
            dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
                __html: i18n.t('due_usage_info', {
                    value: `<b>${formatNumber(counter.value)} ${unit}</b>`,
                    remaining_usage: `<b>${formatNumber(remaining_usage)} ${unit}</b>`,
                    due_usage: `<b>${due_usage} ${unit}</b>`,
                    interpolation: { escapeValue: false },
                    avg: `<b>${formatNumber(counter.daily_avg)} ${unit}</b>`,
                    reference_date_text: `<b>${moment(increment_date).format('DD/MM/YYYY')}</b>`,
                }),
            }}
        />
    );
};

const PreventiveEventForm = (props) => {
    const [_sent, setSent] = useState(false);
    const [_schedulingMode, setSchedulingMode] = useState('no_counter');
    const [_component, setComponent] = useState(props.event && props.event.component);
    const [_counter, setCounter] = useState(null);
    const [_dueUsage, setDueUsage] = useState(null);
    const [_remainingUsage, setRemainingUsage] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [_dateDue, setDateDue] = useState(null);
    const [_errorApi, setErrorApi] = useState(null);
    const [_from, setFrom] = useState(null);
    const hasPerm = usePerm(props.asset_id || null);
    if (!hasPerm('create_preventive_event') && !props.loginless) return (<div>YOU DO NOT HAVE THE PERM: create_preventive_event</div>);
    const hasComponents = !!props.asset?.component_structure;

    useEffect(() => {
        async function counter_due_usage_change(counter, newValue) {
            const value = newValue.replace(/\s/g, '');
            const url = `/api/counters/${counter.id}/compute_from_next_usage.json`;
            $.post(url, {
                due_usage: value,
            }).then((data) => {
                setRemainingUsage(data.remaining_usage);
                setDateDue(data.date_due);
            });
        }

        if (_dueUsage && _counter) {
            counter_due_usage_change(_counter, _dueUsage);
        }
    }, [_dueUsage, _counter]);

    useEffect(() => {
        window.pausePolling(['all_assets_live_data']);
        if (window.pause_timeline_polling) {
            window.pause_timeline_polling();
        }
        return () => {
            window.unpausePolling(['all_assets_live_data']);
            if (window.unpause_timeline_polling) {
                window.unpause_timeline_polling();
            }
        };
    }, []);

    useEffect(() => {
        if (props.event.id) {
            props.fetchDocumentsForEvent(props.event.id);
        }
    }, [props.event.id]);

    const defaultValues = props.event_id ? (props.event) : {
        reported_by: props.user.full_name,
    };

    if (props.event_id) {
        defaultValues.scheduling_mode = props.event.counter ? 'counter' : 'no_counter';
        defaultValues.date_closed = (
            props.event.date_closed && moment(props.event.date_closed)
        ) || moment();
    }

    const format_data = (data) => {
        const formated_data = { ...data };
        if (props.event_id) {
            formated_data.id = props.event.id;
        }
        formated_data.date_due = data.date_due && data.date_due.toISOString();
        formated_data.maintenance_type = 'preventive';
        formated_data.reported_by = defaultValues.reported_by;
        formated_data.asset = props.asset_id;
        formated_data.date_tolerance = data.date_tolerance && data.date_tolerance.toISOString();
        formated_data.date_limit = data.date_limit && data.date_limit.toISOString();
        return formated_data;
    };

    const onSubmit = (data) => {
        const formated_data = format_data(data);
        setSent(true);
        const method = props.event.id ? props.updateEvent : props.createEvent;
        method(formated_data).then((d) => {
            setSent(false);
            if (d.payload && !d.error) {
                setErrorApi(null);
                if (props.callback) props.callback(d.payload);
            }
            if (d.error) {
                setErrorApi(d.payload.response);
                scrollToTop();
            }
        });
    };

    const onCancel = () => {
        if (props.cancelCallback) props.cancelCallback();
    };

    const onChangeFrom = (arg) => {
        if (arg) setFrom(arg);
        else setFrom(null);
    };

    const counter_unit = _counter ? COUNTER_UNIT_TRANSLATION[_counter.unit] || _counter.unit : '';
    if (props.event_state === 'init') {
        return (
            <div className={`form-main ${props.device} preventive-form`}>
                <FormManager
                    onSubmit={onSubmit}
                    textSubmitButton={props.event_id ? i18n.t('Update event') : i18n.t('Create event')}
                    disabled={_sent}
                    onCancel={onCancel}
                    apiErrors={_errorApi}
                    hasPerm={hasPerm('create_preventive_event')}
                >
                    <InputsWrapper
                        id="general"
                        title={i18n.t('General')}
                    >
                        <CutForm
                            device={props.device}
                        >
                            <TextDisplay
                                label={i18n.t('Asset')}
                                data={props.asset_name}
                            />
                            {
                                hasComponents && (
                                    <FormComponent
                                        id="component"
                                        asset_id={props.asset_id}
                                        defaultValue={
                                            props.event
                                            && props.event.component
                                        }
                                        label={i18n.t('Component')}
                                        disabled={!!props.event_id}
                                    />
                                )
                            }
                            <TextDisplay
                                label={i18n.t('Maintenance Type')}
                                data="preventive"
                            />
                            <FormTextInput
                                id="description"
                                defaultValue={defaultValues && defaultValues.description}
                                label={i18n.t('Description')}
                                large
                                required
                                lockSize
                            />
                            <div className="event-form-full-line">
                                <FormFile
                                    id="documents"
                                    label={i18n.t('Documents')}
                                    defaultValue={props.event.documents}
                                    documents={props.event.id && props.documents_for_event}
                                    url="/api/document.json"
                                    multi
                                    deletable
                                    deleteAction={(file) => props.deleteDocument(file)}
                                />
                            </div>
                        </CutForm>
                    </InputsWrapper>
                </FormManager>
            </div>
        );
    }

    return (
        <div className={`form-main ${props.device} preventive-form`}>
            <FormManager
                onSubmit={onSubmit}
                textSubmitButton={props.event_id ? i18n.t('Update event') : i18n.t('Create event')}
                disabled={_sent}
                onCancel={onCancel}
                apiErrors={_errorApi}
                hasPerm={hasPerm('create_preventive_event')}
            >
                <InputsWrapper
                    id="general"
                    title={i18n.t('General')}
                >
                    <CutForm
                        device={props.device}
                    >
                        <TextDisplay
                            label={i18n.t('Asset')}
                            data={props.asset_name}
                        />
                        {
                            hasComponents && (
                                <FormComponent
                                    id="component"
                                    asset_id={props.asset_id}
                                    defaultValue={
                                        props.event
                                        && props.event.component
                                    }
                                    label={i18n.t('Component')}
                                    disabled={!!props.event_id}
                                    onChange={setComponent}
                                />
                            )
                        }
                        <TextDisplay
                            label={i18n.t('Maintenance Type')}
                            data="preventive"
                        />
                        <FormTextInput
                            id="description"
                            defaultValue={defaultValues && defaultValues.description}
                            label={i18n.t('Description')}
                            large
                            required
                            lockSize
                        />
                        <div className="event-form-full-line">
                            <FormFile
                                id="documents"
                                label={i18n.t('Documents')}
                                defaultValue={props.event.documents}
                                documents={props.event.id && props.documents_for_event}
                                url="/api/document.json"
                                multi
                                deletable
                                deleteAction={(file) => props.deleteDocument(file)}
                            />
                        </div>
                    </CutForm>
                    <FormCustomField
                        id="public_fields"
                        asset={props.asset_id}
                        defaultValue={defaultValues}
                        public
                        content_type="maintenanceevent"
                        content_options={props.content_options}
                        asset_class={props.asset_class}
                    />
                    <CutForm
                        device={props.device}
                    >
                        <FormCheckbox
                            id="scheduling_mode"
                            defaultValue={
                                (defaultValues && defaultValues.scheduling_mode) || _schedulingMode
                            }
                            label={i18n.t('scheduling_mode')}
                            values={[
                                { id: 'no_counter', label: i18n.t('no_counter_scheduling_mode') },
                                { id: 'counter', label: i18n.t('counter_scheduling_mode'), disabled: (hasComponents && !_component) },
                            ]}
                            labelizer={(item) => item.label}
                            onChange={setSchedulingMode}
                            disabled={!!props.event_id}
                        />
                        {
                            props.event && props.event.id && props.operation_name
                            && (
                                <TextDisplay
                                    label={i18n.t('operation')}
                                    data={props.operation_name}
                                />
                            )
                        }
                    </CutForm>
                    <CutForm
                        device={props.device}
                        hide={_schedulingMode !== 'no_counter'}
                    >
                        <FormDateTime
                            id="date_due"
                            label={i18n.t('static due date')}
                            defaultValue={
                                (defaultValues
                                    && defaultValues.date_due
                                    && moment(defaultValues.date_due))
                                || moment()
                            }
                            allowEmpty
                            fillFlex
                            required
                            disabled={!!props.operation_name}
                        />
                        <FormDateTime
                            id="date_tolerance"
                            label={i18n.t('date_tolerance')}
                            defaultValue={
                                (defaultValues
                                    && defaultValues.date_tolerance
                                    && moment(defaultValues.date_tolerance))
                            }
                            allowEmpty
                            fillFlex
                            disabled={!!props.operation_name}
                            required={false}
                        />
                        <FormDateTime
                            id="date_limit"
                            label={i18n.t('date limit')}
                            defaultValue={
                                (defaultValues
                                    && defaultValues.date_limit
                                    && moment(defaultValues.date_limit))
                            }
                            allowEmpty
                            fillFlex
                            disabled={!!props.operation_name}
                            required={false}
                        />
                    </CutForm>
                    <CutForm
                        device={props.device}
                        hide={_schedulingMode !== 'counter'}
                    >
                        <FormCounter
                            id="counter"
                            label={i18n.t('counter')}
                            asset_id={props.asset_id}
                            defaultValue={(defaultValues && defaultValues.counter)}
                            onChange={setCounter}
                            disabled={!!props.operation_name}
                        />
                        <FormTextInput
                            id="due_usage"
                            label={`${i18n.t('due usage')} ${counter_unit}`}
                            defaultValue={(defaultValues && defaultValues.due_usage)}
                            onChange={setDueUsage}
                            disabled={!!props.operation_name}
                        >
                            <DueUsageInfo
                                counter={_counter}
                                dueUsage={_dueUsage}
                                remainingUsage={_remainingUsage}
                                event={props.event}
                                increment_date={_counter && _counter.increment_date}
                            />
                        </FormTextInput>
                        <FormDateTime
                            id="date_limit"
                            label={i18n.t('date limit')}
                            defaultValue={
                                (defaultValues
                                    && defaultValues.date_limit
                                    && moment(defaultValues.date_limit))
                                || moment()
                            }
                            allowEmpty
                            fillFlex
                            disabled={!!props.operation_name}
                        />
                    </CutForm>
                </InputsWrapper>
                {
                    props.private_fields && props.private_fields.length > 0
                    && (
                        <InputsWrapper
                            id="private"
                            title={i18n.t('Private')}
                            private
                        >
                            <FormCustomField
                                id="private_fields"
                                defaultValue={defaultValues}
                                private
                                content_type="maintenanceevent"
                                content_options={props.content_options}
                                asset_class={props.asset_class}
                            />
                        </InputsWrapper>
                    )
                }
                {
                    props.event_id && props.event.state === 'closed'
                    && (
                        <InputsWrapper
                            id="maintenance_report"
                            title={i18n.t('Maintenance report')}
                        >
                            <CutForm
                                device={props.device}
                            >
                                <FormDateTime
                                    id="date_closed"
                                    label={i18n.t('Date closed')}
                                    defaultValue={
                                        defaultValues.date_closed
                                    }
                                    fillFlex
                                    required
                                    onChange={onChangeFrom}
                                />
                                <FormTextInput
                                    id="rex"
                                    defaultValue={defaultValues && defaultValues.rex}
                                    label={i18n.t('Observations')}
                                    large
                                    lockSize
                                />
                            </CutForm>
                            {
                                props.event.counter
                                && (
                                    <FormCounterDoneUsage
                                        event_id={props.event_id}
                                        date={_from}
                                        defaultValue={defaultValues}
                                    />
                                )
                            }
                            <CutForm>
                                <FormCustomField
                                    id="public_fields"
                                    asset={props.asset_id}
                                    defaultValue={defaultValues}
                                    public
                                    content_type="maintenanceevent"
                                    content_options={props.content_options_close}
                                    asset_class={props.asset_class}
                                />
                            </CutForm>
                            {
                                props.private_fields_close
                                && props.private_fields_close.length > 0
                                && (
                                    <InputsWrapper
                                        id="private"
                                        title={i18n.t('Private')}
                                        private
                                    >
                                        <FormCustomField
                                            id="private_fields"
                                            defaultValue={defaultValues}
                                            private
                                            content_type="maintenanceevent"
                                            content_options={props.content_options_close}
                                            asset_class={props.asset_class}
                                        />
                                    </InputsWrapper>
                                )
                            }
                        </InputsWrapper>
                    )
                }
            </FormManager>
        </div>
    );
};
const mapStateToProps = (state, props) => {
    const user = state.users.me;
    const { event_id } = props;
    const { asset_id } = props;
    const event = event_id && maintenanceEventsSelectors.getMaintenanceEvent(state, event_id);
    const content_options = ['preventive', 'open'];
    const content_options_close = ['preventive', 'close'];
    const maintenance_type = event && event.maintenance_type;
    const asset = asset_id && assetsSelectors.getAssetInfos(state, asset_id);
    const asset_class = asset && asset.asset_class;
    const asset_name = asset && asset.name;
    const documents_for_event = event_id
        && documentsSelectors.getDocumentsForEvent(state, event_id);
    const content_type = 'maintenanceevent';
    const private_fields = content_type
        && companiesSettingsSelectors.getPrivateFieldsForUserWithContentTypeAndOptions(
            content_type, content_options,
        )(state);
    const private_fields_close = content_type
        && companiesSettingsSelectors.getPrivateFieldsForUserWithContentTypeAndOptions(
            content_type, content_options,
        )(state);
    const operations = operationsSelectors.getOperations(state);
    const operation = operations
        && event
        && event.operation
        && operations[event.operation];
    const operation_name = operation && operation.name;
    const event_state = event && event.state;
    return {
        asset: assetsSelectors.getAssetInfos(state, props.asset_id),
        users: state.users.all,
        user,
        content_options,
        content_options_close,
        operation_name,
        event: event || {},
        asset_name,
        asset_class,
        event_state,
        private_fields,
        private_fields_close,
        maintenance_type,
        documents_for_event: documents_for_event || {},
    };
};

const mapDispatchToProps = (dispatch) => ({
    createEvent: (payload) => dispatch(maintenanceEventsOperations.createEvent(payload)),
    updateEvent: (payload) => dispatch(maintenanceEventsOperations.updateMaintenanceEvent(payload)),
    fetchDocumentsForEvent: (event_id) => dispatch(documentsOperations.fetchForEvent(event_id)),
    deleteDocument: (file) => dispatch(documentsOperations.deleteDocument(file)),
});

const Comp = (props) => (
    <MultiDeviceLayout>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <PreventiveEventForm
            {...props}
        />
    </MultiDeviceLayout>
);

export default connect(mapStateToProps, mapDispatchToProps)(Comp);
