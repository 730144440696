/**
 * Created by gknuts on 29/04/2022.
 */
import types from './types';
import { createReducer, apiHelpers } from '../../utils';

const initialState = {};

const listReducer = createReducer(initialState)({
    [types.FETCH_LIST_COMPLETED]: apiHelpers.fetch_list_complete,
    [types.FETCH_DETAIL_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.DELETE_COMPLETED]: apiHelpers.delete_completed,
});

export default listReducer;
