import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormLabel from '../formLabel/FormLabel';
import ReactSelectWrapper from '../ReactSelectWrapper';
import FormDropdown from '../formDropdown/FormDropdown';
import { THEMES } from '../../../filters/selectFilter/SelectFilter';
import useFetch from '../../../hooks/useFetch';

const FormComponentManager = (props) => {
    const { fetchAll } = useFetch();
    const [_components, setComponents] = useState(null);
    const [_root, setRoot] = useState(null);

    useEffect(() => {
        if (!_.isEmpty(_components)) {
            for (const component of _components) {
                if (component.is_root) {
                    setRoot(component.id);
                    if (!props.defaultValue) {
                        props.onChange(component.id);
                    }
                    return;
                }
            }
        }
        setRoot(null);
    }, [_components]);

    useEffect(() => {
        if (_.isEmpty(_components) && props.asset_id) {
            const headers = props.secret ? {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'SUPERNOVA-LOGINLESS-ASSET-ID': props.asset_id,
                'SUPERNOVA-LOGINLESS-SECRET': props.secret,
            } : null;
            fetchAll(`/api/asset/${props.asset_id}/components`, {}, headers).then((d) => {
                if (d) {
                    setComponents(d);
                } else {
                    setComponents(null);
                }
            });
        } else {
            setComponents(null);
        }
    }, [props.asset_id, props.secret]);

    return (
        <FormDropdown
            id={props.id}
            id_getter={(elm) => elm.id}
            labelizer={(elm) => elm.label}
            defaultValue={props.defaultValue}
            value={props.defaultValue || _root}
            values={_components}
            theme={THEMES.FORM_LIGHT}
            required={!!props.required}
            clearable={false}
            onChange={() => null}
            disabled={props.disabled}
        />
    );
};

const FormComponent = (props) => {
    const { errors } = useFormContext();
    const valueError = errors && errors[props.id];

    const validate = (data) => {
        if (data.length === 0) return null;
        return data;
    };

    return (
        <div
            className={`form form_component ${props.className ? props.className : ''} ${props.id} ${!props.label ? 'no-label' : ''}${props.large ? ' large' : ''}`}
            key={props.id}
        >
            <FormLabel {...props} />
            <ReactSelectWrapper
                classname="components"
                // eslint-disable-next-line react/no-children-prop
                children={FormComponentManager}
                name={props.id}
                validate={validate}
                {..._.omit(props, ['children'])}
            />
            {valueError && (
                <div className="form-validation-error">
                    <div className="form-validation-error-arrow" />
                    <div className="form-error-message">
                        {
                            (valueError.message)
                                ? <div>{valueError.message}</div>
                                : <div>Please enter a value</div>
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

export default FormComponent;
