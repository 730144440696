/** @flow */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */

import * as React from 'react';
// import PropTypes from 'prop-types';
import i18n from 'i18next';
import memoize from 'memoize-one';
import { saveAs } from 'file-saver';
import moment from 'moment-timezone';
import { AutoSizer } from 'react-virtualized';
import { VariableSizeGrid, areEqual } from 'react-window';

import WithPopper from '../app/WithPopper';
import info from '../filters/verticalMultiOptionFilter/info.svg';
import info_on_hover from '../filters/verticalMultiOptionFilter/info-on-hover.svg';
import no_description_option from '../filters/verticalMultiOptionFilter/no-description-option.svg';
import no_description_option_on_hover from '../filters/verticalMultiOptionFilter/no-description-option-on-hover.svg';
import './DataGrid.scss';
import warning_old_tech_status from './warning.svg';
import info_dark from './info-dark.svg';
import linkSign from './link-sign.svg';
import { deltaTimeFromNow, prettyDateFromNow } from '../../utils/prettyDate';

/*
const SampleColumn = {
    dataKey: 'id', // mandatory
    label: null,
    csvDataFormatter: null, // optional formatter for csv dump: function(item, column) return string or int
    dataGetter: null, // custom data getter function(item, index){ return value; }
    dataType: 'auto', // 'auto', string, number, date

    isFixed: false, // column is fixed on the left side
    alwaysVisible: false, // prevent a column to be hidden
    // width: 75, // explicit with
    // minWidth: 20, // column min width if flexColumns is active
    // maxWidth: null, // column max width if flexColumns is active

    cellRenderer: null, // custom cellRenderer
    cellClassGetter: null, // custom cellClassGetter
    headerRenderer: null, // custom headerRenderer
    sortActive: true, // allow to disable sorting for this column
    sortFunc: null, // custom sortFunc
    filterActive: true, // allow to disable filtering for this column
    filterRenderer: null, // custom filterRenderer
    filterFunc: null, // custom filterFunc
};
*/
const GPS_STATUS_TO_LABEL = {
    unknown: i18n.t('Unknown'),
    no_gps: i18n.t('No gps'),
    vehicle: i18n.t('Vehicle'),
    parking: i18n.t('Parking'),
    traction: i18n.t('Traction'),
    poweron: i18n.t('Power on'),
    standby: i18n.t('Idle'),
};

const LOCAL_STORAGE_PREFIX = 'PREF_dgpref_';

/* eslint-disable eqeqeq */
const OPERATORS = [
    ['<=', (val, filter) => Number(val) <= Number(filter)],
    ['>=', (val, filter) => Number(val) >= Number(filter)],
    ['==', (val, filter) => val == filter],
    ['!=', (val, filter) => val != filter],
    // is empty / has no value
    ['!?', (val, filter) => val === null || val === undefined || val === ''],
    ['<', (val, filter) => val < filter],
    ['>', (val, filter) => val > filter],
    // TEXT exists and does not contain val
    ['!', (val, filter) => val !== null && val !== undefined && val.toString().toLowerCase().indexOf(filter) === -1],
    // is not empty / has a value
    ['?', (val, filter) => val != null && val !== ''],
];
/* eslint-enable eqeqeq */

// TEXT exists and contains val
const OPERATOR_DEFAULT = (val, filter) => val !== null && val !== undefined && val.toString().toLowerCase().indexOf(filter) > -1;

function evaluateFilters(item, filters) {
    for (const key in filters) {
        const filter = filters[key];
        const val = (key in item) ? item[key] : null;

        if (!filter.operator(val, filter.arg)) {
            return false;
        }
    }
    return true;
}

function filterItems(items, columns, filterMask) {
    // sanitize filters
    const filters = {};
    for (const column of columns) {
        const { dataKey } = column;
        const filterText = filterMask[dataKey];
        if (typeof (filterText) === 'string' && filterText.length > 0) {
            // check operators
            for (const op of OPERATORS) {
                if (filterText.startsWith(op[0])) {
                    filters[dataKey] = {
                        operator: op[1],
                        arg: filterText.substring(op[0].length, filterText.length).toLowerCase(),
                    };
                    break;
                }
            }

            // use default operator if no operator is found
            if (!filters[dataKey]) {
                filters[dataKey] = {
                    operator: OPERATOR_DEFAULT,
                    arg: filterText.toLowerCase(),
                };
            }
        }
    }

    const out = [];
    if (!items) return out;
    for (const item of items) {
        if (evaluateFilters(item, filters)) {
            out.push(item);
        }
    }
    return out;
}

// split the columns props into visible fixed and unfixed columns
function computeVisibleColumns(columns, visibilityMask) {
    const leftColumns = [];
    const rightColumns = [];

    for (const column of columns) {
        if (visibilityMask[column.dataKey] !== false) {
            if (column.isFixed) {
                leftColumns.push(column);
            } else {
                rightColumns.push(column);
            }
        }
    }

    return {
        leftColumns,
        rightColumns,
        visibleColumns: leftColumns.concat(rightColumns),
    };
}

function computeColumnsSize(leftColumns, rightColumns, width, defaultColumnWidth) {
    const leftWidths = [];
    const rightWidths = [];
    let leftTotalWidth = 0;
    let rightTotalWidth = 0;

    let leftScalableWidth = 0;
    let rightScalableWidth = 0;

    // compute initial width
    for (const column of leftColumns) {
        const w = column.width || defaultColumnWidth;
        leftWidths.push(w);
        leftTotalWidth += w;
        if (!column.isFixed) {
            leftScalableWidth += w;
        }
    }

    for (const column of rightColumns) {
        const w = column.width || defaultColumnWidth;
        rightWidths.push(w);
        rightTotalWidth += w;
        if (!column.isFixed) {
            rightScalableWidth += w;
        }
    }

    // rescale columns if there is more space
    const totalWidth = leftTotalWidth + rightTotalWidth;
    const scalableWidth = leftScalableWidth + rightScalableWidth;
    if (totalWidth < width && scalableWidth > 0) {
        const unscalableWidth = totalWidth - scalableWidth;
        const ratio = (width - unscalableWidth) / scalableWidth;

        leftTotalWidth = 0;
        rightTotalWidth = 0;

        for (let i = 0; i < leftWidths.length; i += 1) {
            let w = leftWidths[i];
            if (!leftColumns[i].isFixed) {
                w = Math.round(w * ratio);
            }

            leftWidths[i] = w;
            leftTotalWidth += w;
        }

        for (let i = 0; i < rightWidths.length; i += 1) {
            let w = rightWidths[i];
            if (!rightColumns[i].isFixed) {
                w = Math.round(w * ratio);
            }
            rightWidths[i] = w;
            rightTotalWidth += w;
        }

        // use last column to cleaning match the remaining space
        // to avoid leftover pixels
        if (rightWidths.length > 0) {
            const lastId = rightWidths.length - 1;
            rightTotalWidth -= rightWidths[lastId];
            rightWidths[lastId] = width - rightTotalWidth - leftTotalWidth;
            rightTotalWidth += rightWidths[lastId];
        } else if (leftWidths.length > 0) {
            const lastId = leftWidths.length - 1;
            leftTotalWidth -= leftWidths[lastId];
            leftWidths[lastId] = width - rightTotalWidth - leftTotalWidth;
            leftTotalWidth += leftWidths[lastId];
        }
    }

    return {
        leftWidths,
        rightWidths,
        leftTotalWidth,
        rightTotalWidth,
    };
}

// selectedItems is only there to make sure the view is re-rendered when the selection changes
function sortItems(items, sortOrder, sortColumn, defaultSortColumn, selectedItems) {
    if (sortColumn) {
        const order = (sortOrder === 'ascending') ? 1 : -1;
        const key = sortColumn.dataKey;
        // TODO there should be a better fix to the absence of default sort column
        const secondKey = defaultSortColumn ? defaultSortColumn.dataKey : key;
        let sortFunc = function (x, y) {
            // degenerate to null because undefined has bad properties
            let a = x[key] || null;
            let b = y[key] || null;

            // attempt to sort by second key
            if (a === b) {
                a = x[secondKey] || null;
                b = y[secondKey] || null;
            }

            // We always want null values at the end
            if (a == null) {
                return 1;
            }

            if (b == null) {
                return -1;
            }

            // normal ordering
            if (a > b) {
                return order;
            }

            if (a < b) {
                return -order;
            }

            return 0;
        };

        if (key === '__selection__') {
            sortFunc = function (x, y) {
                const idA = x[sortColumn.selectionKey];
                const idB = y[sortColumn.selectionKey];
                // degenerate to false because undefined has bad properties
                let a = selectedItems[idA] || false;
                let b = selectedItems[idB] || false;

                // attempt to sort by second key
                if (a === b) {
                    // degenerate to null because undefined has bad properties
                    a = x[secondKey] || null;
                    b = y[secondKey] || null;
                    // We always want null values at the end
                    // but only for second key
                    if (a == null) {
                        return 1;
                    }

                    if (b == null) {
                        return -1;
                    }

                    // normal ordering
                    if (a > b) {
                        return order;
                    }

                    if (a < b) {
                        return -order;
                    }
                }

                // INVERSED ordering
                // because we want the selected items to by default
                // but with the sorted items in ASCENDING order
                if (a > b) {
                    return -order;
                }

                if (a < b) {
                    return order;
                }

                return 0;
            };
        }

        // sorted copy
        return items.slice().sort(sortFunc);
    }

    return items;
}

function makeItemData(items, columns) {
    return { items, columns };
}

function shallowDiffers(prev, next) {
    for (const attribute in prev) {
        if (!(attribute in next)) {
            return true;
        }
    }
    for (const attribute in next) {
        if (prev[attribute] !== next[attribute]) {
            return true;
        }
    }
    return false;
}

class ItemRenderer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPopperOpen: false,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {
            columnIndex, data, rowIndex, style,
        } = this.props;
        if (this.state.isPopperOpen !== nextState.isPopperOpen) {
            return true;
        }
        // TODO test item instance instead of array ?
        if (shallowDiffers(nextProps.style, style)
            || nextProps.columnIndex !== columnIndex
            || nextProps.rowIndex !== rowIndex
            || nextProps.data !== data) {
            return true;
        }

        return false;
    }

    handleMouseEnter = (open) => {
        this.setState({
            isPopperOpen: open,
        });
    };

    render() {
        const {
            columnIndex, data, rowIndex, style,
        } = this.props;
        // Access the data source using the "data" prop:
        const item = data.items[rowIndex];
        const column = data.columns[columnIndex];
        let value = item[column.dataKey];
        const itemDataKey = item[column.dataKey];
        if (column.cellRenderer) {
            value = column.cellRenderer(value, item, rowIndex, column, columnIndex);
        } else {
            value = (value !== undefined && value !== null) ? value.toString() : '';
        }
        let cellClass = '';

        if (column.cellClassGetter) {
            cellClass = column.cellClassGetter(value, item, rowIndex, column, columnIndex);
        } else if (value === undefined || value === null) {
            cellClass = 'dg-cell-empty';
        }
        let valueCell = (
            <div className={`dg-cell ${cellClass}`} style={style}>
                {value}
            </div>
        );
        const asset_status = (item['telematic.status']) || 'no_gps';
        if (column.dataKey === 'telematic.status') {
            const deltaTimeFromNowOfLastUpdate = deltaTimeFromNow(item.last_update);
            if ((20 * 60 * 1000) < deltaTimeFromNowOfLastUpdate && asset_status !== 'no_gps' && asset_status !== 'parking') {
                const warningInfoPopper = (
                    <WithPopper
                        visible={this.state.isPopperOpen}
                        modifiers={[
                            {
                                name: 'offset',
                                options: {
                                    offset: ({ placement, reference, popper }) => {
                                        if (placement === 'bottom-end' || placement === 'bottom' || placement === 'bottom-start') {
                                            return [-10, 15];
                                        }
                                        return [0, -2];
                                    },
                                },
                            }]}
                        extraClass="popper-warning-info"
                        usePortal
                    >
                        <div />
                        <div className="info-content">
                            <div>
                                {i18n.t('The locomotive is in "{{asset_status}}" status and we didn’t receive update since {{dateStatusLastChange}}.', { asset_status: GPS_STATUS_TO_LABEL[itemDataKey], dateStatusLastChange: prettyDateFromNow(item['telematic.status_last_change']) })}
                                <br />
                                <a href="https://help.railnova.eu/en/articles/581328-asset-states" target="_blank" rel="noreferrer">
                                    <button className="button-go-intercom-article">
                                        <span>{i18n.t('More info in help guide')}</span>
                                        {' '}
                                        <img
                                            src={linkSign}
                                            alt="link sign"
                                        />
                                    </button>
                                </a>
                            </div>
                        </div>
                    </WithPopper>
                );
                valueCell = (
                    <div className={`dg-cell ${cellClass}`} style={style}>
                        <div className="old-tech-status-datagrid">
                            <img src={warning_old_tech_status} alt="old-tech-status" />
                            <div className="old-value">{value}</div>
                            &nbsp;
                            <img
                                src={this.state.isPopperOpen ? info_on_hover : info_dark}
                                alt="optionInfo"
                                onMouseEnter={() => this.handleMouseEnter(true)}
                                onMouseLeave={() => this.handleMouseEnter(false)}
                            />
                            <div
                                className="warning-info-popper"
                                onMouseEnter={() => this.handleMouseEnter(true)}
                                onMouseLeave={() => this.handleMouseEnter(false)}
                            >
                                {warningInfoPopper}
                            </div>
                        </div>
                    </div>
                );
            }
        }
        if (column.dataKey === 'telematic.status_last_change') {
            const deltaTimeFromNowOfLastUpdate = deltaTimeFromNow(item.last_update);
            if ((20 * 60 * 1000) < deltaTimeFromNowOfLastUpdate && asset_status !== 'no_gps' && asset_status !== 'parking') {
                valueCell = (
                    <div className={`dg-cell ${cellClass}`} style={style}>
                        <div className="old-tech-status-datagrid">
                            {value}
                        </div>
                    </div>
                );
            }
        }
        if (!!column.displayAlertBranding && !!column.user && item.company.id !== column.user.company) {
            const alertBranding = (
                <div className="alertBranding">
                    <div className="alertBranding-text">
                        {i18n.t('this alert is powered by')}
                        &nbsp;
                        <div className="company-name">
                            {item.company.name}
                        </div>
                    </div>
                </div>
            );
            valueCell = (
                <div className={`dg-cell ${cellClass}`} style={style}>
                    <div className="dg-value-container">
                        <div className="dg-value">
                            {value}
                        </div>
                        {alertBranding}
                    </div>
                </div>
            );
        }
        return valueCell;
    }
}

const SelectionCellRenderer = (cellData, rowData, rowIndex, column) => (
    <input
        type="checkbox"
        checked={column.isItemSelected(rowData)}
        style={{
            borderRadius: 0,
            height: 15,
            width: 15,
            outline: 0,
            margin: 0,
        }}
        onChange={(e) => column.onChangeItemSelected(rowData)}
    />
);

const SelectionHeaderRenderer = (column) => {
    const state = column.getHeaderSelectionState();
    // 0 == Undetermined
    // 1 == All
    // 2 == None
    const checked = (state === 1);
    const indeterminate = (state === 0);

    return (
        <input
            type="checkbox"
            checked={checked}
            style={{
                borderRadius: 0,
                height: 15,
                width: 15,
                outline: 0,
                margin: 0,
            }}
            onChange={(e) => column.toggleHeaderSelectionState()}
            ref={(el) => el && (el.indeterminate = indeterminate)}
        />
    );
};

class Footer extends React.PureComponent {
    render() {
        const { props } = this;
        return (
            <div className="dg-footer">
                {!props.hideDownload
                    && (
                        <div>
                            {'Download '}
                            <a onClick={() => props.downloadJSON()} style={{ cursor: 'pointer' }} download="code.json">
                                JSON
                            </a>
                            {' - '}
                            <a onClick={() => props.downloadCSV()} style={{ cursor: 'pointer' }} download="code.csv">
                                CSV
                            </a>
                        </div>
                    )}
                <div className="dg-filler"></div>
                {!props.hideCount
                    && (
                        <div>
                            {`Items: ${props.itemCounter} of ${props.totalItems}`}
                        </div>
                    )}
            </div>
        );
    }
}

function loadColumnsPref(storageKey) {
    const key = LOCAL_STORAGE_PREFIX + storageKey;
    return JSON.parse(localStorage.getItem(key));
}

function scrollbarSize() {
    const scrollDiv = document.createElement('div');
    scrollDiv.style.position = 'absolute';
    scrollDiv.style.top = '-9999px';
    scrollDiv.style.width = '50px';
    scrollDiv.style.height = '50px';
    scrollDiv.style.overflow = 'scroll';
    document.body.appendChild(scrollDiv);
    const size = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    return size;
}

class DataGrid extends React.PureComponent {
    constructor(props, context) {
        super(props, context);

        // TODO this will not react to a change of storage key
        const visibilityMask = loadColumnsPref(props.storageKey) || props.defaultVisibilityMask || {};

        this.state = {
            filters: {},
            visibilityMask,
            selectedItems: {},
            sortOrder: props.defaultSortOrder,
            sortColumn: props.defaultSortColumn,
            loadedStorageKey: props.storageKey,
            headerSelectionState: 0,
            // 0 == Undetermined
            // 1 == All
            // 2 == None
        };

        // cached data
        this._cacheColumnsSize = null;
        this._scrollBarSize = scrollbarSize();

        // event handlers
        this.onSortChange = this.onSortChange.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onScrollLeft = this.onScrollLeft.bind(this);
        this.onScrollRight = this.onScrollRight.bind(this);
        this.onScrollTop = this.onScrollTop.bind(this);

        // changers
        this.showAllColumns = this.showAllColumns.bind(this);
        this.hideAllColumns = this.hideAllColumns.bind(this);
        this.toggleColumn = this.toggleColumn.bind(this);
        this.onColumnSetChange = this.onColumnSetChange.bind(this);
        this.onChangeItemSelected = this.onChangeItemSelected.bind(this);
        this.isItemSelected = this.isItemSelected.bind(this);
        this.getHeaderSelectionState = this.getHeaderSelectionState.bind(this);
        this.toggleHeaderSelectionState = this.toggleHeaderSelectionState.bind(this);
        this.notififySelectionChanged = this.notififySelectionChanged.bind(this);

        // utilities
        this.downloadJSON = this.downloadJSON.bind(this);
        this.downloadCSV = this.downloadCSV.bind(this);

        // Refs
        this.topLeftRef = React.createRef();
        this.topRightRef = React.createRef();
        this.bottomLeftRef = React.createRef();
        this.bottomRightRef = React.createRef();

        // memoized functions
        this.computeVisibleColumns = memoize(computeVisibleColumns);
        this.computeColumnsSize = memoize(computeColumnsSize);
        this.filterItems = memoize(filterItems);
        this.sortItems = memoize(sortItems);
        this.makeLeftItemData = memoize(makeItemData);
        this.makeRightItemData = memoize(makeItemData);

        this.getColumns = memoize(this.getColumns);

        // renderers
        this.renderHeaderCell = React.memo(this.renderHeaderCell.bind(this), areEqual);
    }

    componentDidMount() {
        if (this.props.cacheFilters) {
            const key_storage = `${LOCAL_STORAGE_PREFIX}${this.props.storageKey}_filters`;
            const raw_filters = localStorage.getItem(key_storage);
            const filters = JSON.parse(raw_filters);
            if (!filters) {
                return;
            }
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({ filters });
        }
    }

    static getDerivedStateFromProps(props, state) {
        let new_state = null;
        // react to a change of initial sort column
        if (state.sortColumn === null && props.defaultSortColumn !== null) {
            new_state = { sortColumn: props.defaultSortColumn };
        }

        if (props.storageKey !== state.loadedStorageKey || props.defaultVisibilityMask !== state.visibilityMask) {
            new_state = new_state || {};
            new_state.loadedStorageKey = props.storageKey;
            new_state.visibilityMask = loadColumnsPref(props.storageKey) || props.defaultVisibilityMask || {};
        }
        return new_state;
    }

    onSortChange(column) {
        if (column.sortActive === false) {
            // maybe better to remove onclick handler ?
            return;
        }

        if (this.state.sortColumn === column.dataKey) {
            const sortOrder = (this.state.sortOrder === 'ascending') ? 'descending' : 'ascending';
            this.setState({ sortOrder });
        } else {
            this.setState({ sortColumn: column.dataKey });
        }
    }

    onFilterChange(dataKey, value) {
        const update = {};
        update[dataKey] = value;

        const filters = { ...this.state.filters, ...update };
        if (this.props.cacheFilters) {
            const key_storage = `${LOCAL_STORAGE_PREFIX}${this.props.storageKey}_filters`;
            const raw_filters = JSON.stringify(filters);
            localStorage.setItem(key_storage, raw_filters);
        }
        this.setState({ filters }, this.notififySelectionChanged);
    }

    onScrollLeft({ scrollTop, scrollUpdateWasRequested }) {
        if (scrollUpdateWasRequested) {
            return;
        }

        if (this.bottomRightRef.current) {
            this.bottomRightRef.current.scrollTo({ scrollTop });
        }
    }

    onScrollTop({ scrollLeft, scrollUpdateWasRequested }) {
        if (scrollUpdateWasRequested) {
            return;
        }

        if (this.bottomRightRef.current) {
            this.bottomRightRef.current.scrollTo({ scrollLeft });
        }
    }

    onScrollRight({ scrollLeft, scrollTop, scrollUpdateWasRequested }) {
        if (scrollUpdateWasRequested) {
            return;
        }

        if (this.topRightRef.current) {
            this.topRightRef.current.scrollTo({ scrollLeft });
        }

        if (this.bottomLeftRef.current) {
            this.bottomLeftRef.current.scrollTo({ scrollTop });
        }
    }

    getVisibilityMask() {
        return { ...this.state.visibilityMask };
    }

    saveColumnsPref(prefs) {
        const key = LOCAL_STORAGE_PREFIX + this.props.storageKey;
        const raw_data = JSON.stringify(prefs);
        localStorage.setItem(key, raw_data);
    }

    setVisibilityMask(visibilityMask) {
        this.setState({ visibilityMask });
        this.saveColumnsPref(visibilityMask);
        return { ...visibilityMask };
    }

    showAllColumns() {
        return this.setVisibilityMask({});
    }

    hideAllColumns() {
        const visibilityMask = {};
        for (const column of this.props.columns) {
            if (column.alwaysVisible !== true) {
                visibilityMask[column.dataKey] = false;
            }
        }
        return this.setVisibilityMask(visibilityMask);
    }

    toggleColumn(dataKey) {
        const visibilityMask = { ...this.state.visibilityMask };
        visibilityMask[dataKey] = (visibilityMask[dataKey] === false);
        return this.setVisibilityMask(visibilityMask);
    }

    onColumnSetChange(selected) {
        const visibilityMask = {};

        for (const column of this.props.columns) {
            visibilityMask[column.dataKey] = (column.alwaysVisible === true);
        }

        const columnSet = this.props.getColumnSet(selected.value);

        for (const dataKey of columnSet) {
            visibilityMask[dataKey] = true;
        }
        return this.setVisibilityMask(visibilityMask);
    }

    onChangeItemSelected(item) {
        const itemID = item[this.props.selectionKey];
        const selectedItems = { ...this.state.selectedItems };
        selectedItems[itemID] = !this.state.selectedItems[itemID];

        this.setState({ selectedItems, headerSelectionState: 0 }, this.notififySelectionChanged);
    }

    isItemSelected(item) {
        const itemID = item[this.props.selectionKey];
        return (this.state.selectedItems[itemID] === true);
    }

    getHeaderSelectionState() {
        return this.state.headerSelectionState;
    }

    notififySelectionChanged() {
        if (this.props.onChangeSelection) {
            const items = [];

            // restrict to the items that are visible
            const visibleItems = this.getItems();
            for (const item of visibleItems) {
                const itemID = item[this.props.selectionKey];
                if (this.state.selectedItems[itemID] === true) {
                    items.push(itemID);
                }
            }
            // restrict to every visible items
            // for (const k in this.state.selectedItems) {
            //     if (this.state.selectedItems[k] === true) {
            //         items.push(k);
            //     }
            // }

            // TODO items or key ?
            this.props.onChangeSelection(items);
        }
    }

    toggleHeaderSelectionState() {
        const state = this.state.headerSelectionState;
        // 0 == Undetermined
        // 1 == All
        // 2 == None
        if (state === 0 || state === 2) {
            // select all visible items
            // and set new state to 1
            const items = this.getItems();
            const selectedItems = {};
            if (items) {
                for (const item of items) {
                    const itemID = item[this.props.selectionKey];
                    selectedItems[itemID] = true;
                }
            }

            this.setState({ selectedItems, headerSelectionState: 1 }, this.notififySelectionChanged);
        } else {
            // unselect all items and set new state to 2
            const selectedItems = {};
            this.setState({ selectedItems, headerSelectionState: 2 }, this.notififySelectionChanged);
        }
    }

    getColumns(columns, showSelection) {
        if (showSelection) {
            const out = [{
                dataKey: '__selection__', // trick name  see sortItems
                title: 'Selection',
                width: 40,
                isFixed: true,
                // sortActive: false,
                filterActive: false,
                selectionKey: this.props.selectionKey,
                cellRenderer: SelectionCellRenderer,
                headerRenderer: SelectionHeaderRenderer,
                // cellClassGetter: ColoredCellClassGetter,
                isItemSelected: this.isItemSelected,
                onChangeItemSelected: this.onChangeItemSelected,
                getHeaderSelectionState: this.getHeaderSelectionState,
                toggleHeaderSelectionState: this.toggleHeaderSelectionState,
            }];
            return out.concat(columns);
        }

        return columns;
    }

    getItems() {
        const columns = this.getColumns(this.props.columns, this.props.selectionActive);
        const { visibleColumns } = this.computeVisibleColumns(columns, this.state.visibilityMask);
        // filtering
        const filteredItems = this.filterItems(this.props.items, visibleColumns, this.state.filters);

        // sorting
        const sortColumn = columns.find((e) => e.dataKey === this.state.sortColumn);

        let defaultSortColumn = visibleColumns[0];
        if (this.props.defaultSortColumn !== null) {
            defaultSortColumn = columns.find((e) => e.dataKey === this.props.defaultSortColumn);
        }

        // selectedItems is only there to make sure the view is re-rendered when the selection changes
        const items = this.sortItems(filteredItems, this.state.sortOrder, sortColumn, defaultSortColumn, this.state.selectedItems);
        return items;
    }

    _renderGrid(width, height) {
        if (this.props.columns.length === 0) {
            return null;
        }

        const columns = this.getColumns(this.props.columns, this.props.selectionActive);

        // visible columns
        const {
            leftColumns,
            rightColumns,
        } = this.computeVisibleColumns(columns, this.state.visibilityMask);

        const items = this.getItems();

        const {
            headerHeight, showFilters, filterHeight, overscanRowsCount,
        } = this.props;

        let { footerHeight } = this.props;
        if (!this.props.showFooter) {
            footerHeight = 0;
        }

        const topHeight = (showFilters) ? headerHeight + filterHeight : headerHeight;
        const headerRows = (showFilters) ? 2 : 1;

        const { rowHeight } = this.props;

        // check if right side scrollbar is visible
        const rightSB = (topHeight + footerHeight + (items.length * rowHeight) > height) ? this._scrollBarSize : 0;

        const columnsSize = this.computeColumnsSize(leftColumns, rightColumns, width - rightSB, this.props.defaultColumnWidth);

        if (this._cacheColumnsSize !== columnsSize) {
            if (this.topLeftRef.current) {
                this.topLeftRef.current.resetAfterColumnIndex(0, false);
            }
            if (this.topRightRef.current) {
                this.topRightRef.current.resetAfterColumnIndex(0, false);
            }
            if (this.bottomLeftRef.current) {
                this.bottomLeftRef.current.resetAfterColumnIndex(0, false);
            }
            if (this.bottomRightRef.current) {
                this.bottomRightRef.current.resetAfterColumnIndex(0, false);
            }
            this._cacheColumnsSize = columnsSize;
        }

        const {
            leftWidths,
            rightWidths,
            leftTotalWidth,
            rightTotalWidth,
        } = columnsSize;

        // check if bottom scrollbar is visible
        const bottomSB = (leftTotalWidth + rightTotalWidth > width) ? this._scrollBarSize : 0;

        const leftItemData = this.makeLeftItemData(items, leftColumns);
        const rightItemData = this.makeLeftItemData(items, rightColumns);

        const bottomHeight = height - topHeight - footerHeight;
        const leftWidth = leftTotalWidth;
        const rightWidth = width - leftWidth;
        const topLeft = leftColumns.length > 0 && (
            <VariableSizeGrid
                ref={this.topLeftRef}
                width={leftWidth}
                height={topHeight}
                columnCount={leftColumns.length}
                columnWidth={(index) => leftWidths[index]}
                rowCount={headerRows}
                rowHeight={(index) => ((index === 0) ? this.props.headerHeight : this.props.filterHeight)}
                // hide the overflow so the scroll bar never shows
                style={{
                    top: 0,
                    left: 0,
                    overflow: 'hidden',
                    borderBottom: '1px solid gray',
                    borderRight: '1px solid gray',
                }}
                itemData={leftItemData}
            >
                {this.renderHeaderCell}
            </VariableSizeGrid>
        );

        const bottomLeft = leftColumns.length > 0 && (
            <VariableSizeGrid
                ref={this.bottomLeftRef}
                width={leftWidth}
                height={bottomHeight - bottomSB}
                columnCount={leftColumns.length}
                columnWidth={(index) => leftWidths[index]}
                overscanRowsCount={overscanRowsCount}
                rowCount={items.length}
                rowHeight={() => rowHeight}
                onScroll={this.onScrollLeft}
                className="dg-bottom-left"
                itemData={leftItemData}
            >
                {ItemRenderer}
            </VariableSizeGrid>
        );

        const topRight = (
            <VariableSizeGrid
                ref={this.topRightRef}
                width={rightWidth - rightSB}
                height={topHeight}
                columnCount={rightColumns.length}
                columnWidth={(index) => rightWidths[index]}
                rowCount={headerRows}
                rowHeight={(index) => ((index === 0) ? this.props.headerHeight : this.props.filterHeight)}
                onScroll={this.onScrollTop}
                // hide the overflow so the scroll bar never shows
                style={{
                    overflow: 'hidden',
                    borderBottom: '1px solid gray',
                }}
                itemData={rightItemData}
            >
                {this.renderHeaderCell}
            </VariableSizeGrid>
        );

        const bottomRight = (
            <VariableSizeGrid
                ref={this.bottomRightRef}
                width={rightWidth}
                height={bottomHeight}
                columnCount={rightColumns.length}
                columnWidth={(index) => rightWidths[index]}
                overscanRowsCount={overscanRowsCount}
                estimatedRowHeight={rowHeight}
                rowCount={items.length}
                rowHeight={() => rowHeight}
                onScroll={this.onScrollRight}
                itemData={rightItemData}
                // hide the overflow so the scroll bar never shows
                style={{
                }}
            >
                {ItemRenderer}
            </VariableSizeGrid>
        );

        const footer = (
            <Footer
                downloadJSON={this.downloadJSON}
                downloadCSV={this.downloadCSV}
                itemCounter={items.length}
                totalItems={this.props.items.length}
                hideCount={this.props.hideCount}
                hideDownload={this.props.hideDownload}
            />
        );

        return (
            <div style={{ position: 'absolute', width, height }}>
                <div style={{ position: 'absolute', top: 0, left: 0 }}>
                    {topLeft}
                </div>
                <div style={{ position: 'absolute', top: topHeight, left: 0 }}>
                    {bottomLeft}
                </div>
                <div style={{ position: 'absolute', top: 0, left: leftWidth }}>
                    {topRight}
                </div>
                <div style={{ position: 'absolute', top: topHeight, left: leftWidth }}>
                    {bottomRight}
                </div>
                {this.props.showFooter
                    && (
                        <div style={{ position: 'absolute', top: height - footerHeight, width }}>
                            {footer}
                        </div>
                    )}
            </div>
        );
    }

    render() {
        // This should NOT be here :(
        if (this.props.promptSearch && Object.keys(this.props.items).length === 0 && this.props.items.constructor === Object) {
            // First render, without any data
            return (
                <div className="center-message">
                    <h1>
                        <span className="glyphicon glyphicon-search" aria-hidden="true" />
                        {' '}
                        Run a search by using the form above.
                    </h1>
                </div>
            );
        } if (this.props.warnEmpty && Array.isArray(this.props.items) && !this.props.items.length) {
            // If we got an empty dataset
            return (
                <div className="center-message">
                    <h1>
                        <span className="glyphicon glyphicon-search" aria-hidden="true" />
                        {' '}
                        Your search returned no data.
                    </h1>
                    Try changing the date range or broadening the filters.
                </div>
            );
        } if (this.props.noWarnEmpty && Array.isArray(this.props.items) && !this.props.items.length) {
            return null;
        }
        return (
            <AutoSizer>
                {({ width, height }) => this._renderGrid(width, height)}
            </AutoSizer>
        );
    }

    renderHeaderCell({
        columnIndex, data, rowIndex, style,
    }) {
        // Access the data source using the "data" prop:
        const column = data.columns[columnIndex];
        if (rowIndex === 0) {
            return this._renderHeaderCell(column, style);
        }

        return this._renderFilterCell(column, style);
    }

    _renderHeaderCell(column, style) {
        // SORT ICON
        let sortIcon = null;
        if (this.state.sortColumn === column.dataKey) {
            if (this.state.sortOrder === 'ascending') {
                sortIcon = (<i className="dg-sort-by-asc"></i>);
            } else {
                sortIcon = (<i className="dg-sort-by-desc"></i>);
            }
        }
        const [isPopperOpen, setIsPopperOpen] = React.useState(false);
        let infoPopper = null;
        let imageInfo = null;
        const imageInfoWidth = 25;
        if (column.description && this.props.showDescription) {
            imageInfo = (
                <img
                    src={isPopperOpen ? info_on_hover : info}
                    alt="optionInfo"
                    className="optionInfo"
                    onMouseEnter={() => setIsPopperOpen(true)}
                    onMouseLeave={() => setIsPopperOpen(false)}
                />
            );
            let unit_of_measure = null;
            if (column.unit_of_measure && column.unit_of_measure !== 'no_unit') {
                unit_of_measure = (
                    <div className="unit-measure">
                        {`${i18n.t('Unit')}: ${column.unit_of_measure}`}
                    </div>
                );
            }
            infoPopper = (
                <WithPopper
                    visible={isPopperOpen}
                    modifiers={[
                        {
                            name: 'offset',
                            options: {
                                offset: [15, -12],
                            },
                        }]}
                    extraClass="popper-header-description"
                    usePortal
                >
                    <div />
                    <div
                        className="info-content"
                        onMouseEnter={() => setIsPopperOpen(true)}
                        onMouseLeave={() => setIsPopperOpen(false)}
                    >
                        <div>
                            {column.description}
                            <br />
                            {unit_of_measure}
                        </div>
                    </div>
                </WithPopper>
            );
        }
        if (!column.description && this.props.requestDescription) {
            imageInfo = (
                <img
                    src={isPopperOpen ? no_description_option_on_hover : no_description_option}
                    alt="optionInfo"
                    className="optionInfo"
                    onMouseEnter={() => setIsPopperOpen(true)}
                    onMouseLeave={() => setIsPopperOpen(false)}
                />
            );
            infoPopper = (
                <WithPopper
                    visible={isPopperOpen}
                    modifiers={[
                        {
                            name: 'offset',
                            options: {
                                offset: [15, -12],
                            },
                        }]}
                    extraClass="popper-header-description"
                    usePortal
                >
                    <div />
                    <div
                        className="no-description-popper"
                        onMouseEnter={() => setIsPopperOpen(true)}
                        onMouseLeave={() => setIsPopperOpen(false)}
                    >
                        {i18n.t('No description for this symbol yet.')}
                        <br />
                        <br />
                        <div
                            className="request-description"
                            onClick={
                                () => {
                                    const symbolTitle = `${column.dataKey} (${column.intercom_message_title} — ${column.intercom_message_type})`;
                                    const request_documentation_text = i18n.t('Your request for description of {{symbolTitle}} has been sent to our support team. We will respond to you with a description and update the documentation.', { symbolTitle });
                                    window.intercomSettings.request_documentation_text = request_documentation_text;
                                    Intercom('update');
                                }
                            }
                        >
                            {i18n.t('Ask us for a description')}
                        </div>
                    </div>
                </WithPopper>
            );
        }
        return (
            <div onClick={() => this.onSortChange(column)} className="dg-cell-header" style={style} title={isPopperOpen ? '' : column.title || column.label || column.dataKey}>
                { // allow custom header renderer
                    column.headerRenderer ? column.headerRenderer(column)
                        : (
                            <div>
                                <div className="header-label" style={{ width: style.width - imageInfoWidth }}>
                                    <div className="label-text">
                                        {column.label || column.dataKey}
                                        &nbsp;
                                    </div>
                                    {imageInfo}
                                    {infoPopper}
                                </div>

                            </div>
                        )
                }
                {sortIcon}
            </div>
        );
    }

    _renderFilterCell(column, style) {
        if (column.filterActive === false) {
            return null;
        }

        // allow custom header renderer
        if (column.filterRenderer) {
            return column.filterRenderer(column, style);
        }

        // default filter
        return (
            <div className="dg-cell-header" style={style}>
                <input
                    className="form-control dg-filter-input"
                    value={this.state.filters[column.dataKey] || ''}
                    onChange={(e) => this.onFilterChange(column.dataKey, e.target.value)}
                    type="text"
                    placeholder=""
                />
            </div>
        );
    }

    downloadJSON() {
        const data = this.getItems();
        const blob = new Blob([JSON.stringify(data)], {
            type: 'application/json;charset=utf-8',
        });
        saveAs(blob, 'table_data.json');
    }

    downloadCSV() {
        const items = this.getItems();
        // visible columns
        const { visibleColumns } = this.computeVisibleColumns(this.props.columns, this.state.visibilityMask);

        const labels = [];
        let out = '';
        for (const column of visibleColumns) {
            // TODO find a way to remove columns that are not pure data
            labels.push(column.label);
        }
        out = `${labels.join(';')}\n`;

        if (items) {
            for (const item of items) {
                for (const column of visibleColumns) {
                    let value = item[column.dataKey];
                    // TODO support data getter/formatter
                    value = (value !== undefined && value !== null) ? value.toString() : '';

                    // TODO this is legacy code that need to be ported
                    const key = column.dataKey;
                    if (isNaN(key) && (((key.indexOf('runtime') < 0) && (key.indexOf('timer') < 0) && (key.indexOf('uptime') < 0)) && ((key.indexOf('time') >= 0) || (key.indexOf('date') >= 0)))) {
                        if (value !== '') { if (moment(value).isValid()) { value = moment(value).format('YYYY-MM-DD HH:mm:ss'); } }
                    } else if (typeof value === 'string') {
                        value = `"${value}"`;
                    } else if (isNaN(key) && (key.indexOf('redmine_tickets') >= 0)) {
                        value = JSON.stringify(value);
                    }
                    out += value;
                    out += ';';
                }
                out += '\n';
            }
        }
        const blob = new Blob([out], {
            type: 'application/csv;charset=utf-8',
        });
        saveAs(blob, 'table_data.csv');
    }
}

DataGrid.defaultProps = {
    items: [],
    columns: [],

    headerHeight: 30, // height of the column name row

    showFilters: true, // enable/disable filter row in the header
    filterHeight: 34, // height of the filter row

    showFooter: true, // enable/disable the footer
    footerHeight: 30, // height of the footer

    overscanRowsCount: 10, // performance tweak: see react-window for details

    rowHeight: 25, // height of a data row
    // TODO stripedRows: false,

    /** functions to get options for the telematics key set selector
    TODO this should be data props, not data getter
    */
    getColumnSet: null, // (preset_value) => [keyval1, keyval2, ...]

    /** Manage Selection column */
    selectionActive: false, // enable selection column
    selectionKey: 'id', // key used to uniquely identify items
    onChangeSelection: null, // called when selection changes ([selectedItems])

    /** Local storage persistence */
    storageKey: 'default', // identifier in the localstorage this should be different for each data grid view
    cacheFilters: false, // enable persistence of filters

    defaultColumnWidth: 75,
    defaultSortColumn: null,
    defaultSortOrder: 'ascending',
    defaultVisibilityMask: {},
};

export default {
    DataGrid,
};
