import components from './components';
import compounds from './compounds';
import manager from './manager/manager';
import CsrfMiddlewareToken from './utils/CsrfToken';
import ApplicationManager from './utils/applicationManager';
import AuthorizedRoute from './utils/authorized_route';
import AutoRefreshWrapper from './wrapper/AutoRefreshWrapper';
import {
    UrlParamSync, getParamsFromHash, paramsToUrlHash, mergeParams,
} from './utils/UrlParamSync';
import DeepMerge from './utils/deepMerge';
import dateEqual from './utils/dateEqual';

const railfleet = {
    components,
    compounds,
    manager,
    utils: {
        dateEqual,
        CsrfMiddlewareToken,
        ApplicationManager,
        AuthorizedRoute,
        UrlParamSync,
        getParamsFromHash,
        paramsToUrlHash,
        mergeParams,
        DeepMerge,
    },
    wrapper: {
        AutoRefreshWrapper,
    },
};

export default railfleet;
