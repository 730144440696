import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import components from 'components';
import moment from 'moment-timezone';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { maintenanceEventsOperations } from 'railfleet_state/ducks/maintenance_events';
import { documentsSelectors, documentsOperations } from 'railfleet_state/ducks/documents';
import { uxSelectors, uxOperations } from 'railfleet_state/ducks/ux';

import './CampaignEventDetail.scss';
import InterventionHistory from '../../lists/interventionHistory/InterventionHistory';

const { DetailFrame } = components.frames;
const { TextDisplay } = components.display;
const { CutForm } = components.forms;
const { MultiDeviceLayout } = components.app;
const { HeaderModal } = components.headers;
const { RenderDocuments, RenderCounterDoneUsage } = components.forms;
const { EditButton, CancelButton } = components.buttons;
const { usePerm } = components.hooks;

const CampaignEventDetailComponent = (props) => {
    const [_pushInfos, setPushInfos] = useState(null);
    const hasPerm = usePerm(props.asset_id || null);

    useEffect(() => {
        setPushInfos(props.push_infos);
        props.setPushInfos(null, 'campaign event detail');
    }, []);

    useEffect(() => {
        if (props.event_id) props.fetchEventDetails(props.event_id);
    }, [props.event_id]);

    useEffect(() => {
        if (props.maintenance_event && props.maintenance_event.id) {
            props.fetchDocumentsForEvent(props.maintenance_event.id);
        }
    }, [props.maintenance_event]);

    const CancelButtonManager = () => {
        if (props.maintenance_event.next_planned_intervention) return null;
        if (props.maintenance_event.state === 'closed') return null;
        return (
            <CancelButton
                text={i18n.t('Close event')}
                onClick={props.onClickClose}
                disabled={!hasPerm('close_campaign_event')}
            />
        );
    };

    const detailRenderer = () => (
        <div className="preventive-event-refs">
            <div className="preventive-event-ref">
                <span>{i18n.t('Réf')}</span>
                <span>{`M${props.event_id}`}</span>
            </div>
        </div>
    );

    const actionsRenderer = () => (
        <div className="preventive-event-header-actions">
            <CancelButtonManager
                text={i18n.t('Close event')}
                onClick={props.onClickClose}
            />
            <EditButton
                text={i18n.t('Edit event')}
                onClick={props.onClickEdit}
                disabled={!hasPerm('edit_campaign_event')}
            />
        </div>
    );

    const getCloseFields = () => {
        if (props.maintenance_event.state !== 'closed') return null;
        if (!props.maintenance_event.counterreading_source_set) return null;
        return (
            <DetailFrame
                title={i18n.t('Maintenance report')}
            >
                <CutForm
                    device={props.device}
                >
                    <TextDisplay
                        label={i18n.t('Observations')}
                        data={props.rex}
                        large
                    />
                    <TextDisplay
                        label={i18n.t('Date closed')}
                        data={props.date_closed && moment(props.date_closed).format('DD/MM/YYYY HH:mm')}
                    />
                    <TextDisplay
                        label={i18n.t('Closed by')}
                        data={props.users && props.users[props.maintenance_event.user_closed]?.email}
                    />
                </CutForm>
                {
                    props.maintenance_event.counterreading_source_set
                        && (
                            <RenderCounterDoneUsage
                                event_id={props.event_id}
                                asset_id={props.asset_id}
                            />
                        )
                }
            </DetailFrame>
        );
    };

    const closeFields = getCloseFields();

    const onClickPrevious = () => {
        window.location.href = _pushInfos.from;
    };

    if (!hasPerm('view_campaign_events')) {
        return (
            <div className={`preventive-event-detail ${props.device}`}>
                <HeaderModal
                    title={i18n.t('Event details')}
                    onCloseModal={props.onCloseModal}
                />
                <div>YOU DO NOT HAVE THE PERM: view_campaign_events</div>
            </div>
        );
    }

    return (
        <div className={`preventive-event-detail ${props.device}`}>
            <HeaderModal
                title={i18n.t('Event details')}
                detailRenderer={detailRenderer}
                actionsRenderer={!props.readOnly && actionsRenderer}
                onCloseModal={props.onCloseModal}
                previous_link={_pushInfos && onClickPrevious}
            />
            <DetailFrame
                title={i18n.t('General informations')}
            >
                <CutForm
                    device={props.device}
                >
                    <TextDisplay
                        label={i18n.t('Asset')}
                        data={props.asset.name}
                    />
                    <TextDisplay
                        label={i18n.t('Maintenance type')}
                        data={props.maintenance_type}
                    />
                    <TextDisplay
                        label={i18n.t('Description')}
                        data={props.maintenance_event.description}
                        large={!!props.maintenance_event.description}
                    />
                </CutForm>
            </DetailFrame>
            <DetailFrame
                title={i18n.t('Documents')}
            >
                <RenderDocuments
                    label={i18n.t('Documents')}
                    data={props.maintenance_event.documents}
                    documents_for_event={props.documents_for_event}
                    device={props.device}
                />
            </DetailFrame>
            { closeFields }
            <InterventionHistory
                event_id={props.event_id}
                interventionIdRedirect={props.interventionIdRedirect}
                ecm_id={props.asset.ecm_id}
            />
        </div>
    );
};

const CampaignEventDetail = (props) => (
    <MultiDeviceLayout>
        <CampaignEventDetailComponent {...props} />
    </MultiDeviceLayout>
);

const mapStateToProps = (state, props) => {
    const { event_id } = props;
    const { asset_id } = props;
    const maintenance_event = event_id ? state.maintenance_events[event_id] : null;
    const rex = maintenance_event && maintenance_event.rex;
    const date_closed = maintenance_event && maintenance_event.date_closed;
    const maintenance_type = maintenance_event && maintenance_event.maintenance_type;
    const asset = asset_id && assetsSelectors.getAssetInfos(state, asset_id);
    const documents_for_event = event_id
        && documentsSelectors.getDocumentsForEvent(state, event_id);
    const push_infos = props.push_infos || uxSelectors.getPushInfos(state);
    return {
        maintenance_event: maintenance_event || {},
        maintenance_type,
        rex,
        date_closed,
        push_infos,
        asset: asset || {},
        documents_for_event: documents_for_event || {},
        users: state.users.all,
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchEventDetails: (event_id) => dispatch(
        maintenanceEventsOperations.fetchDetails(event_id),
    ),
    fetchDocumentsForEvent: (event_id) => (
        dispatch(documentsOperations.fetchForEvent(event_id))
    ),
    setPushInfos: (push_infos, from = null) => dispatch(uxOperations.setPushInfos(push_infos, from)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignEventDetail);
