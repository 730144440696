import React, {
    useContext, useEffect, useRef, useState,
} from 'react';
import './HomologationList.';
import useUnicodeFlag from 'components/hooks/useUnicodeFlag';
import useDictToArray from 'components/hooks/useDictToArray';
import HomologationContext from 'compounds/pages/assetView/HomologationContext/homologationContext';
import ValidIcon from './valid_icon.svg';
import NotValidIcon from './not_valid_icon.svg';

const HomologationList = (props) => {
    const context = useContext(HomologationContext);
    const countries = props.loginless ? props.homologation?.countries : context?.data?.countries;
    const others = props.loginless ? props.homologation?.others : context?.data?.others;
    const homologations = props.loginless ? props.homologation?.homologations : context?.data?.homologations;
    const homologation = homologations && props.asset_id && homologations[props.asset_id];
    const orderedCountries = useDictToArray(homologation?.country_links, 'id', true, 'order');
    const orderedOthers = useDictToArray(homologation?.other_links, 'id', true, 'order');
    const getFlag = useUnicodeFlag();

    const [_visible, setVisible] = useState(false);
    const [_clicked, setClicked] = useState(false);
    const baseRef = useRef(null);

    const handleDocumentClick = (event) => {
        if (
            (baseRef && baseRef.current
            && (baseRef.current.contains(event.target) || baseRef.current.contains(event.target)))
        ) {
            return;
        }
        setVisible(false);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleDocumentClick);
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, []);

    const Vignet = (arg) => {
        const icon = arg.valid ? ValidIcon : NotValidIcon;
        return (
            <div className={`homologations-vignet ${arg.valid ? 'valid' : 'not-valid'}`}>
                <div><img src={icon} alt="validity_icon" /></div>
                <div>{arg.children}</div>
            </div>
        );
    };

    if (props.impact) {
        return (
            <div className="homologation-list" ref={baseRef}>
                <div className="homologations-vignets">
                    <div>
                        {
                            props.impacts && props.impacts.map((country) => (
                                <Vignet
                                    key={country?.code}
                                    valid={false}
                                >
                                    {country?.code}
                                </Vignet>
                            ))
                        }
                        {
                            props.impacts_other && props.impacts_other.map((other) => (
                                <Vignet
                                    key={other?.name}
                                    valid={false}
                                >
                                    {other?.name}
                                </Vignet>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }

    if (_.isEmpty(countries)) {
        return (
            <div className="homologation-list">
                <div className="homologations-vignets">
                    <div>{props.textNone}</div>
                </div>
            </div>
        );
    }
    if (
        _.isEmpty(homologation)
        || (_.isEmpty(homologation?.other_links) && _.isEmpty(homologation?.country_links))
    ) return null;

    const vignetCountryList = orderedCountries.map((elm) => ({
        key: elm?.target,
        valid: elm.aptitude,
        code: countries[elm?.target]?.country?.code,
        unicode_flag: countries[elm?.target]?.unicode_flag,
    }));
    const vignetOtherList = orderedOthers.map((elm) => ({
        key: elm?.target,
        valid: elm.aptitude,
        code: others[elm?.target]?.name,
    }));
    const vignets = [...vignetCountryList, ...vignetOtherList];
    const target = vignetCountryList.length !== 0 ? vignetCountryList : vignetOtherList;
    const max_vignets = target.length < 4 ? target.length : 4;
    const size = vignets?.length;

    if (props.driver_logbook) {
        return (
            <div className="homologation-list driverlogbook" ref={baseRef}>
                <div className="homologations-vignets">
                    <div>
                        {
                            vignets?.map((elm) => (
                                <Vignet
                                    key={elm.key}
                                    valid={elm.valid}
                                >
                                    {elm.code}
                                    {
                                        props.withFlags && elm.unicode_flag && (
                                            <span className="unicode-flag">{getFlag(elm.unicode_flag)}</span>
                                        )
                                    }
                                </Vignet>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="homologation-list" ref={baseRef}>
            <div className="homologations-vignets">
                <div>
                    {
                        vignets.slice(0, max_vignets)?.map((elm) => (
                            <Vignet
                                key={elm.key}
                                valid={elm.valid}
                            >
                                {elm.code}
                            </Vignet>
                        ))
                    }
                </div>
                {
                    size > max_vignets && (
                        <div
                            className="vignet-more"
                            onClick={() => {
                                setVisible(true);
                                setClicked(true);
                            }}
                            onMouseEnter={() => setVisible(true)}
                            onMouseLeave={() => {
                                if (!_clicked) {
                                    setVisible(false);
                                }
                            }}
                        >
                            <div>{`+${size - max_vignets}`}</div>
                        </div>
                    )
                }
            </div>
            {
                _visible && (
                    <div className="custom_tooltip">
                        <div className="homologations-vignets">
                            <div>
                                {
                                    vignets.slice(max_vignets)?.map((elm) => (
                                        <Vignet
                                            key={elm.key}
                                            valid={elm.valid}
                                        >
                                            {elm.code}
                                        </Vignet>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default HomologationList;
