import React from 'react';

import './EditButton.scss';

const EditButton = (props) => (
    <div
        className={`edit-button-action ${props.disabled ? 'disabled' : ''}`}
        onClick={props.disabled ? () => null : props.onClick}
    >
        <span className="glyphicon glyphicon-pencil shape circle" />
        <span className="edit-button-action-text">{props.text}</span>
    </div>
);

export default EditButton;
