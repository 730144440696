/**
 * Created by lpostula on 11/05/17.
 */
import i18n from 'i18next';
import components from 'components';
import { default as actions } from './actions';
import { createSnackBar } from '../ui/operations';

const { ConfirmationDialog, AsyncConfirmationDialog } = components.containers;

export const { fetchForAsset } = actions;
export const { fetchForEvent } = actions;
export const { fetchList } = actions;
export const { fetchDetails } = actions;
export const { updateIntervention } = actions;
export const { createIntervention } = actions;
export const cancelIntervention = (intervention, need_confirm) => (dispatch) => {
    if (intervention && intervention.id) {
        if (need_confirm) {
            return ConfirmationDialog({
                variant: 'danger',
                confirmation_message: i18n.t('Are you sure you want to cancel this intervention?'),
                buttons_text: ['No', 'Yes'],
            }).then(() => {
                dispatch(actions.cancelIntervention(intervention.id));
            });
        }
        dispatch(actions.cancelIntervention(intervention.id));
    }
    return Promise.resolve();
};
export const removeEventsFromIntervention = (
    intervention,
    event_ids,
    need_confirm,
) => (dispatch) => {
    if (intervention && intervention.id) {
        if (!need_confirm) {
            return dispatch(actions.removeEventsFromIntervention(intervention.id, event_ids));
        }
        return AsyncConfirmationDialog({
            variant: 'danger',
            description_message: i18n.t('Are you sure you want to remove this event from this intervention?'),
            buttons_text: ['No', 'Yes'],
            actionOnYes: () => dispatch(
                actions.removeEventsFromIntervention(intervention.id, event_ids),
            ),
            in_progress_message: i18n.t('Removing event ...'),
            done_message: i18n.t('Event successfuly removed'),
        });
    }
    return Promise.resolve();
};

export const addEventsInIntervention = (intervention_id, events_id) => (dispatch) => dispatch(
    actions.addEventsInIntervention(intervention_id, events_id),
).then(
    (d) => {
        if (d.error) {
            dispatch(createSnackBar({
                id: `add_event_${events_id}_in_intervention_${intervention_id}`,
                message: d.payload.response.events[0],
                className: 'danger',
            }));
            return Promise.resolve(d);
        }
        dispatch(createSnackBar({
            id: `add_event_${events_id}_in_intervention_${intervention_id}`,
            message: i18n.t('events successfully added'),
            className: 'success',
        }));
        return Promise.resolve(d);
    },
);

export const { updateInterventionDates } = actions;

export const incomingWS = (data) => (dispatch) => {
    switch (data.type) {
    case 'update':
        dispatch(actions.updateInterventionInStore(data.payload));
        break;
    case 'delete':
        dispatch(actions.removeInterventionInStore(data.payload));
        break;
    default:
        break;
    }
};
export default {
    fetchForAsset,
    fetchForEvent,
    fetchList,
    fetchDetails,
    updateIntervention,
    createIntervention,
    cancelIntervention,
    addEventsInIntervention,
    removeEventsFromIntervention,
    updateInterventionDates,
    incomingWS,
};
