import React, { useEffect, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactSelectWrapper from '../ReactSelectWrapper';
import SelectFilter from '../../../filters/selectFilter/SelectFilter';
import { StepContext } from '../step/Step';
import FormLabel from '../formLabel/FormLabel';
import './FormDropdown.scss';
import TextDisplay from '../../../display/textDisplay/TextDisplay';
import MultiTextDisplay from '../../../display/multiTextDisplay/MultiTextDisplay';

const FormDropdownComponent = (props) => {
    const {
        errors, unregister, watch, setValue, clearErrors,
    } = useFormContext();
    const valueError = errors && errors[props.id];

    // This is to be used with the Step Manager.
    // It allow to do Forms with multiple steps.
    // We have to register each field in the Step Manager to validate fields on each step instead of
    // validating each fields at the end of the form with the submit button.
    const stepContext = useContext(StepContext);
    useEffect(() => {
        if (stepContext && stepContext.addKey) stepContext.addKey(stepContext.step, props.id);

        return (() => {
            unregister(props.id);
            if (stepContext && stepContext.deleteKey) {
                stepContext.deleteKey(stepContext.step, props.id);
            }
        });
    }, []);

    useEffect(() => {
        if (props.value !== undefined) {
            if (props.value !== watch(props.id)) {
                setValue(props.id, props.value);
                props.onChange(props.value);
                clearErrors();
            }
        }
    }, [props.value]);

    const validate = (data) => {
        if (!data || (Array.isArray(data) && data.length === 0)) return null;
        if (props.multi) return data;
        return data[0];
    };

    return (
        <div className={`form form_dropdown ${props.className ? props.className : ''} ${props.id} ${!props.label ? 'no-label' : ''}${props.large ? ' large' : ''}`} key={props.id}>
            <FormLabel {...props} />
            <ReactSelectWrapper
                classname="dropdown"
                // eslint-disable-next-line react/no-children-prop
                children={SelectFilter}
                name={props.id}
                validate={validate}
                label={props.label || props.placeholder}
                {...props}
            />
            {valueError && (
                <div className="form-validation-error">
                    <div className="form-validation-error-arrow" />
                    <div className="form-error-message">
                        {
                            (valueError.message)
                                ? <div>{valueError.message}</div>
                                : <div>Please enter a value</div>
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

const FormDropdown = (props) => {
    if (props.readOnly) {
        let data = null;
        if (props.defaultValue) {
            if (Array.isArray(props.defaultValue)) data = [];
            if (Array.isArray(props.values)) {
                for (const elm of props.values) {
                    if (Array.isArray(props.defaultValue)) {
                        for (const id of props.defaultValue) {
                            if (props.id_getter(elm) === id) data.push(props.labelizer(elm));
                        }
                    } else if (props.id_getter(elm) === props.defaultValue) {
                        data = props.labelizer(elm);
                    }
                }
            } else {
                data = props.labelizer(props.values[props.defaultValue]);
            }
        }
        if (Array.isArray(props.defaultValue)) {
            return (
                <MultiTextDisplay
                    className="dropdown"
                    label={props.label}
                    data={data}
                />
            );
        }
        return (
            <TextDisplay
                className="dropdown"
                label={props.label}
                data={data}
            />
        );
    }
    return (<FormDropdownComponent {...props} />);
};

export default FormDropdown;
