import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import './SwitchSelector.scss';

class SwitchSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange() {
        this.props.onChange(!this.state.value);
        this.setState({
            value: !this.state.value,
        });
    }

    render() {
        const inside = this.props.inside && (this.state.value ? <div className="text_inside">On</div> : <div className="text_inside">Off</div>);
        return (
            <div className="switch-selector">
                <div className="label-holder">
                    {this.props.label
                        && <span className="label">{this.props.label}</span>}
                </div>
                <div className="switch-holder">
                    <span className="choice left">{!inside ? this.props.choices[0] : null}</span>
                    <label className="switch">
                        <input
                            type="checkbox"
                            onClick={this.handleChange}
                        />
                        <span className={`slider round ${this.state.value ? 'on' : 'off'}`}>{inside}</span>
                    </label>
                    <span className="choice right">{!inside ? this.props.choices[1] : null}</span>
                </div>
            </div>
        );
    }
}

SwitchSelector.defaultProps = {
    choices: [i18n.t('On'), i18n.t('Off')],
};

SwitchSelector.propTypes = {
    choices: PropTypes.array,
    label: PropTypes.string,
};

export default SwitchSelector;
