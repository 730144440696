import React, { useEffect, useState } from 'react';
import DatetimePicker from 'react-datetime';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { strToMoment } from '../utils';

import './TimeInput.scss';

const TimeInput = (props) => {
    const [_value, setValue] = useState(props.value);

    const renderInput = (input_props, openCalendar) => (
        <div className="ts-input-holder">
            <input
                {...input_props}
                onChange={(e) => input_props.onChange(e.target.value)}
            />
            <div className="ts-icon" onClick={openCalendar}>
                <span className="glyphicon glyphicon-triangle-bottom" />
            </div>
        </div>
    );

    const onChange = (value) => {
        // we always update the state so the input field can be refreshed
        setValue(value);

        let date = value;
        let date_and_time = false;

        if (value && typeof value === 'string') {
            // check if string in a partial format
            date = strToMoment(value, props.format, props.timezone);

            if (!date) {
                // check if string in a date + time format
                date = strToMoment(value, props.datetimeFormat, props.timezone);
                if (date) date_and_time = true;
            }
        }

        if (date) {
            if (date_and_time && props.onChangeSpread) {
                props.onChangeSpread(date);
            } else if (props.onChange) {
                props.onChange(date);
            }
        } else {
            props.onChange(null);
        }
    };

    useEffect(() => {
        if (props.value) setValue(props.value);
    }, [props.value]);

    return (
        <DatetimePicker
            timeFormat={props.format}
            renderInput={renderInput}
            inputProps={{
                disabled: props.disabled,
                size: 1,
                style: { width: `${props.format.length}ch` },
                className: 'not-form-control',
                onChange,
            }}
            onChange={onChange}
            value={_value}
            dateFormat={false}
            className={`ts-main ${!props.valid ? 'has-error' : ''}`}
        />
    );
};

TimeInput.defaultProps = {
    value: null,
    format: 'HH:mm',
    datetimeFormat: 'DD/MM/YYYY HH:mm',
    timezone: (window.user_info && window.user_info.timezone) || 'Europe/Brussels',
};

TimeInput.propTypes = {
    value: PropTypes.instanceOf(moment),
    format: PropTypes.string,
    datetimeFormat: PropTypes.string,
    timezone: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onChangeSpread: PropTypes.func,
};

export default TimeInput;
