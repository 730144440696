/**
 * Created by lpostula on 7/3/17.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './MultiActionButton.scss';

export default class MultiActionButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hovered_button: 0,
        };
        this.handleOnClick = this.handleOnClick.bind(this);
        this.onHoverButton = this.onHoverButton.bind(this);
        this.onUnHoverButton = this.onUnHoverButton.bind(this);
    }

    handleOnClick(evt, button_number) {
        this.setState({ hovered_button: 0 });
        this.props.actions[button_number].onClick(evt);
    }

    onHoverButton(button_number) {
        this.setState({ hovered_button: button_number });
    }

    onUnHoverButton() {
        this.setState({ hovered_button: 0 });
    }

    render() {
        const first_button = this.props.actions[0];
        return (
            <div
                className="input-group multiaction-dd"
            >
                <button
                    type="button"
                    className={`btn btn-block btn-${this.state.hovered_button === 0 ? (this.props.className || 'default') : 'default'} multiaction-dd main-button`}
                    onClick={first_button.onClick}
                    disabled={this.props.disabled || first_button.disabled}
                    aria-expanded="false"
                >
                    {first_button.label}
                </button>
                {this.props.actions.length > 1 ? (
                    <div
                        className="input-group-addon multiaction-dd"
                    >
                        <button
                            type="button"
                            className={`btn btn-block btn-${this.props.className || 'default'} dropdown-toggle multiaction-dd`}
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            disabled={this.props.disabled}
                        >
                            <div className="caret" />
                        </button>
                        <ul className="dropdown-menu-right dropdown-menu multiaction-dd">
                            {_.map(this.props.actions, (v, k) => {
                                if (k >= 1) {
                                    return (
                                        <li key={`${k}_action`}>
                                            <button
                                                className={`multiaction-dd btn btn-block btn-${this.state.hovered_button === k ? (this.props.className || 'default') : 'default'}`}
                                                onClick={((evt) => { this.handleOnClick(evt, k); })}
                                                onMouseEnter={(() => { this.onHoverButton(k); })}
                                                onMouseLeave={this.onUnHoverButton}
                                                disabled={this.props.disabled || v.disabled}
                                            >
                                                {v.label}
                                            </button>
                                        </li>
                                    );
                                }
                                return null;
                            })}
                        </ul>
                    </div>
                ) : null}
            </div>
        );
    }
}

MultiActionButton.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    actions: PropTypes.array.isRequired,
};

MultiActionButton.defaultProps = {
    className: '',
    disabled: false,
};
