import React, { useState, useEffect, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import useArrayToDict from 'components/hooks/useArrayToDict';
import ReactSelectWrapper from '../ReactSelectWrapper';
import { StepContext } from '../step/Step';
import './FormMultiValueCheckbox.scss';
import FormLabel from '../formLabel/FormLabel';
import Invalid from './Invalid.svg';
import BlackInvalid from './BlackInvalid.svg';
import Valid from './Valid.svg';
import BlackValid from './BlackValid.svg';

const ReadOnlyCheckbox = (props) => {
    const { value } = props;
    const checkImg = value ? BlackValid : BlackInvalid;
    return (
        <div className={`read-only-checkbox ${value ? 'enabled' : 'disabled'}`}>
            <div>
                <img src={checkImg} alt="checkbox checked" />
            </div>
        </div>
    );
};

const Checkboxes = (props) => {
    const [_selection, setSelection] = useState({});
    const values = useArrayToDict(props.values);
    const cycle = props.cycle || ['null', 'true', 'false'];

    // This is to be used with the Step Manager.
    // It allow to do Forms with multiple steps.
    // We have to register each field in the Step Manager to validate fields on each step instead of
    // validating each fields at the end of the form with the submit button.
    const stepContext = useContext(StepContext);
    useEffect(() => {
        if (stepContext && stepContext.addKey) stepContext.addKey(stepContext.step, props.id);
    }, []);

    useEffect(() => {
        const newSelection = { ..._selection };
        if (props.defaultValue) {
            for (const key of Object.keys(props.defaultValue)) {
                const value = props.defaultValue[key];
                newSelection[key] = value;
            }
        }
        for (const elm of props.values) {
            const elm_is_selected = newSelection[props.id_getter(elm)];
            if (elm_is_selected === undefined) {
                newSelection[props.id_getter(elm)] = elm.default ? elm.default : elm.nullable ? cycle[0] : cycle[1];
            }
        }
        setSelection(newSelection);
    }, [props.defaultValue, props.values]);

    const _onChange = (id_elm) => {
        if (props.disabled) return;
        const newSelection = { ..._selection };
        const current_value = newSelection[id_elm] || 'null';
        const pos_cycle = cycle.indexOf(current_value, 0);
        const last_elm = pos_cycle === cycle.length - 1;
        if (last_elm) {
            if (values[id_elm].nullable) {
                delete newSelection[id_elm];
            } else {
                newSelection[id_elm] = cycle[1];
            }
        } else {
            newSelection[id_elm] = cycle[pos_cycle + 1];
        }
        setSelection(newSelection);
        props.onChange(newSelection);
    };

    const get_custom_class_getter = (elm) => {
        const value = _selection[elm];
        if (value === 'true') return 'elm_true';
        if (value === 'false') return 'elm_false';
        if (!values[elm] || !values[elm].nullable) {
            return 'elm_true';
        }
        return '';
    };

    const is_selected = (elm) => {
        const value = _selection[props.id_getter(elm)];
        const nullable = values[props.id_getter(elm)]?.nullable;
        if (value === 'true' || value === 'false') {
            return true;
        }
        if (!value && !nullable) {
            return true;
        }
        return false;
    };

    const typecheckbox_color = {
        elm_true: '#00B380',
        elm_false: '#FF3E62',
    };

    const typecheckbox_img = {
        elm_true: Valid,
        elm_false: Invalid,
    };

    const onChange = (elm) => {
        if (props.isDisabled && props.isDisabled(elm)) return;
        _onChange(props.id_getter(elm));
    };

    return _.map(props.values, (elm) => {
        const elm_is_selected = is_selected(elm);
        const custom_class_getter = (props.custom_class_getter && props.custom_class_getter(props.id_getter(elm))) || get_custom_class_getter(props.id_getter(elm));
        return (
            <div className={`form_checkbox_elm ${props.isDisabled && props.isDisabled(elm) ? 'disabled' : ''} ${custom_class_getter}`} key={props.id_getter(elm)}>
                {
                    elm.readOnly ? (
                        <ReadOnlyCheckbox
                            value={elm.default === 'true'}
                        />
                    ) : (
                        <>
                            <input
                                type="checkbox"
                                checked={elm_is_selected}
                                onChange={() => onChange(elm)}
                            />
                            {
                                custom_class_getter && (
                                    <div
                                        className="after-checkbox"
                                        onClick={() => onChange(elm)}
                                    >
                                        <div
                                            className="inner-after-checkbox"
                                            style={{
                                                backgroundColor: typecheckbox_color[custom_class_getter],
                                            }}
                                        />
                                        <img src={typecheckbox_img[custom_class_getter]} alt="checkbox checked" />
                                    </div>
                                )
                            }
                        </>
                    )
                }
                <div
                    className={`form_checkbox_elm_label ${custom_class_getter}`}
                    onClick={() => !elm.readOnly && onChange(elm)}
                >
                    {
                        props.render ? (
                            props.render(elm)
                        ) : (
                            <span>{props.labelizer(elm)}</span>
                        )
                    }
                </div>
            </div>
        );
    });
};

const FormMultiValueCheckbox = (props) => {
    const {
        errors, watch, setValue, clearErrors,
    } = useFormContext();
    const valueError = errors && errors[props.id];

    const validate = (data) => {
        if (!data || (Array.isArray(data) && data.length === 0)) return null;
        return data;
    };

    return (
        <div className={`form form_multivalue_checkbox ${props.className ? props.className : ''} ${props.id} ${!props.label ? 'no-label' : ''}`} key={props.id}>
            <FormLabel {...props} />
            <div className="form_multivalue_checkbox_holder">
                <ReactSelectWrapper
                    classname="checkbox"
                    // eslint-disable-next-line react/no-children-prop
                    children={Checkboxes}
                    name={props.id}
                    validate={validate}
                    {..._.omit(props, 'children')}
                />
                { props.children }
            </div>
            {valueError && (
                <div className="form-validation-error">
                    <div className="form-validation-error-arrow" />
                    <div className="form-error-message">
                        {
                            (valueError.message)
                                ? <div>{valueError.message}</div>
                                : <div>Please enter a value</div>
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

FormMultiValueCheckbox.defaultProps = {
    id_getter: (val) => val.id,
    labelizer: (val) => val.label,
    selectedValue: undefined,
};

export default FormMultiValueCheckbox;
