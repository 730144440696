import React from 'react';
import './Loader.scss';

const Loader = (props) => (
    <div className={`loading-holder ${props.className}`}>
        <div className="spinner-rolling">
            <div className="spinner">
                <div className="outer"></div>
                <div className="inner"></div>
            </div>
        </div>
    </div>
);
export default Loader;
