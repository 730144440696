/**
 * Created by lpostula on 15/05/17.
 */
const FETCH_LIST_REQUEST = 'maintenance_events/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'maintenance_events/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'maintenance_events/FETCH_LIST_FAILED';

const FETCH_DETAILS_REQUEST = 'maintenance_events/FETCH_DETAILS_REQUEST';
const FETCH_DETAILS_COMPLETED = 'maintenance_events/FETCH_DETAILS_COMPLETED';
const FETCH_DETAILS_FAILED = 'maintenance_events/FETCH_DETAILS_FAILED';

const CLOSE_EVENT_REQUEST = 'maintenance_events/CLOSE_EVENT_REQUEST';
const CLOSE_EVENT_COMPLETED = 'maintenance_events/CLOSE_EVENT_COMPLETED';
const CLOSE_EVENT_FAILED = 'maintenance_events/CLOSE_EVENT_FAILED';

const PROCESS_EVENT_REQUEST = 'maintenance_events/PROCESS_EVENT_REQUEST';
const PROCESS_EVENT_COMPLETED = 'maintenance_events/PROCESS_EVENT_COMPLETED';
const PROCESS_EVENT_FAILED = 'maintenance_events/PROCESS_EVENT_FAILED';

const UPDATE_DETAILS_REQUEST = 'maintenance_events/UPDATE_DETAILS_REQUEST';
const UPDATE_DETAILS_COMPLETED = 'maintenance_events/UPDATE_DETAILS_COMPLETED';
const UPDATE_DETAILS_FAILED = 'maintenance_events/UPDATE_DETAILS_FAILED';

const CREATE_EVENT_REQUEST = 'maintenance_events/CREATE_EVENT_REQUEST';
const CREATE_EVENT_COMPLETED = 'maintenance_events/CREATE_EVENT_COMPLETED';
const CREATE_EVENT_FAILED = 'maintenance_events/CREATE_EVENT_FAILED';

const RUN_SCHEDULER_REQUEST = 'maintenance_events/RUN_SCHEDULER_REQUEST';
const RUN_SCHEDULER_COMPLETED = 'maintenance_events/RUN_SCHEDULER_COMPLETED';
const RUN_SCHEDULER_FAILED = 'maintenance_events/RUN_SCHEDULER_FAILED';

const DELETE_EVENT_REQUEST = 'maintenance_events/DELETE_EVENT_REQUEST';
const DELETE_EVENT_COMPLETED = 'maintenance_events/DELETE_EVENT_COMPLETED';
const DELETE_EVENT_FAILED = 'maintenance_events/DELETE_EVENT_FAILED';

const ASSIGN_FAULT_CAT_REQUEST = 'maintenance_events/ASSIGN_FAULT_CAT_REQUEST';
const ASSIGN_FAULT_CAT_COMPLETED = 'maintenance_events/ASSIGN_FAULT_CAT_COMPLETED';
const ASSIGN_FAULT_CAT_FAILED = 'maintenance_events/ASSIGN_FAULT_CAT_FAILED';

const CLEAR_EVENTS_FOR_ASSET = 'maintenance_events/CLEAR_EVENTS_FOR_ASSET';

export default {
    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,
    FETCH_DETAILS_REQUEST,
    FETCH_DETAILS_COMPLETED,
    FETCH_DETAILS_FAILED,
    CLOSE_EVENT_REQUEST,
    CLOSE_EVENT_COMPLETED,
    CLOSE_EVENT_FAILED,
    PROCESS_EVENT_REQUEST,
    PROCESS_EVENT_COMPLETED,
    PROCESS_EVENT_FAILED,
    UPDATE_DETAILS_REQUEST,
    UPDATE_DETAILS_COMPLETED,
    UPDATE_DETAILS_FAILED,
    CREATE_EVENT_REQUEST,
    CREATE_EVENT_COMPLETED,
    CREATE_EVENT_FAILED,
    RUN_SCHEDULER_REQUEST,
    RUN_SCHEDULER_COMPLETED,
    RUN_SCHEDULER_FAILED,
    DELETE_EVENT_REQUEST,
    DELETE_EVENT_COMPLETED,
    DELETE_EVENT_FAILED,
    CLEAR_EVENTS_FOR_ASSET,
    ASSIGN_FAULT_CAT_REQUEST,
    ASSIGN_FAULT_CAT_COMPLETED,
    ASSIGN_FAULT_CAT_FAILED,
};
