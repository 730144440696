import L from 'leaflet';
import 'leaflet.polylinemeasure';
import './PolylineMeasure.css';

import MapControl from './MapControl';

class PolylineMeasure extends MapControl {
    createLeafletElement() {
        // TODO not set position to 'topright' everytime but
        // get it from the props.
        return new L.Control.PolylineMeasure({ position: 'topright' });
    }
}

export default PolylineMeasure;
