/**
 * Created by lpostula on 11/05/17.
 */
import types from './types';
import { createReducer } from '../../utils';

/* State shape
{
    {config_id: config},
}
 */

const initialState = {};

const listReducer = createReducer(initialState)({
    [types.FETCH_LIST_COMPLETED]: (state, action) => {
        // We dont want to merge with previous state here
        // since we receive a complete list it gotta overwrite what we have
        const new_state = {};
        if (!action?.payload) return new_state;
        for (const config of action.payload) {
            new_state[config.id] = config;
        }
        return new_state;
    },
});

export default listReducer;
