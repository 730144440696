const DeepMerge = (target, source, override = false) => {
    const copy = { ...target };
    for (const key of Object.keys(source)) {
        if (source[key] instanceof Object) {
            if (!override) {
                Object.assign(source[key], DeepMerge(copy[key], source[key]));
            } else {
                Object.assign(source[key], source[key]);
            }
        }
    }
    Object.assign(copy || {}, source);
    return copy;
};

export default DeepMerge;
