/**
 * Created by lpostula on 22/12/17.
 *
 *
 * Utils used for the filtering
 *
 */

const isId = (cand) => Number.isInteger(cand);

export const validate_list_of_ids = (cand) => {
    if (!Array.isArray(cand)) return false;
    if (!cand.every(isId)) return false;
    return true;
};

export const filterBySelectedAssets = (filter) => [].concat(filter);

export const filterBySelectedAssetClasses = (filter, cands) => {
    const result = [];
    for (const cand_id in cands) {
        const cand = cands[cand_id];
        if (filter.includes(cand.asset_class)) {
            result.push(cand.id);
        }
    }
    return result;
};

const getLabelCategoryWithLabel = (labels_categories, label_target) => {
    if (!labels_categories) return null;
    for (const label_category_id in labels_categories) {
        const labels_category = labels_categories[label_category_id];
        for (const label of labels_category.label_set) {
            if (label.id === label_target) return labels_category.id;
        }
    }
    return null;
};

export const filterBySelectedLabels = (filter, cands, labels_categories) => {
    const result = [];
    const categories = {};
    for (const label_id of filter) {
        const label_category = getLabelCategoryWithLabel(labels_categories, label_id);
        if (label_category) {
            if (!categories[label_category]) {
                categories[label_category] = [];
            }
            categories[label_category].push(label_id);
        }
    }

    for (const cand_id in cands) {
        const cand = cands[cand_id];
        let cpt = 0;
        for (const category_id in categories) {
            const category = categories[category_id];
            let ok = false;
            for (let i = 0; !ok && i < cand.label_set.length; i += 1) {
                const asset_label_id = cand.label_set[i];
                if (category.includes(asset_label_id)) {
                    cpt += 1;
                    ok = true;
                }
            }
        }
        if (cpt === Object.keys(categories).length) {
            result.push(cand.id);
        }
    }
    return result;
};

export const filterBySelectedStatus = (filter, cands) => {
    const result = [];
    const filter_size = filter.length;
    for (const cand_id in cands) {
        const cand = cands[cand_id];
        let ok = false;
        for (let i = 0; !ok && i < filter_size; i += 1) {
            const status_id = filter[i];
            if (cand.operational_status === status_id
                || cand.operational_detailed_status === status_id
            ) {
                ok = true;
            }
        }
        if (ok) {
            result.push(cand.id);
        }
    }
    return result;
};

export const filterBySelectedTechStatus = (filter, cands) => {
    const result = [];
    const filter_size = filter.length;
    for (const cand_id in cands) {
        const cand = cands[cand_id];
        const status = cand['telematic.status'];
        if (!status) continue;
        const [value] = status;

        let ok = false;
        for (let i = 0; !ok && i < filter_size; i += 1) {
            const status_value = filter[i];
            if (value === status_value || (status_value === 'no_gps' && !value)) {
                ok = true;
            }
        }
        if (ok) {
            result.push(cand.id);
        }
    }
    return result;
};

export const filterBySelectedOperations = (filter, cands) => {
    const result = [];
    const filter_size = filter.length;
    for (const cand_id in cands) {
        const cand = cands[cand_id];
        if (!cand.events_count_per_operation) continue;
        let ok = false;
        for (let i = 0; !ok && i < filter_size; i += 1) {
            const operation_id = filter[i];
            const event_count = cand.events_count_per_operation[operation_id];
            if (event_count && event_count > 0) {
                ok = true;
            }
        }
        if (ok) {
            result.push(cand.id);
        }
    }
    return result;
};

export const compute_alert_count = (op, mode) => {
    if (!op) return 0;
    if (!mode) return 0;
    if (mode.indexOf('alert_open_by__') !== -1) {
        const company = mode.split('__')[1];
        return op.alerts_count_per_company[company] || 0;
    }
    return op.alerts_count;
};

export const compute_event_count = (op, mode) => {
    if (!op || !op.maintenance_events_count) return 0;
    if (!mode) return 0;
    switch (mode) {
    case 'event_unplanned':
        return op.maintenance_events_count.unplanned;
    case 'event_planned':
        return op.maintenance_events_count.planned;
    case 'event_preventive_due_within_4_weeks':
        return op.maintenance_events_count.preventive_due_within_4_weeks;
    case 'event_preventive_due':
        return op.maintenance_events_count.preventive_due;
    default:
        return op.maintenance_events_count.total;
    }
};

export const compute_intervention_count = (op, mode) => {
    if (!op) return 0;
    if (!mode) return 0;
    if (!op.interventions_count) return 0;
    switch (mode) {
    case 'intervention_in_progress':
        return op.interventions_count.in_progress;
    case 'intervention_today':
        return op.interventions_count.start_today
            + op.interventions_count.end_today;
    case 'intervention_after_today':
        return op.interventions_count.start_after_today;
    default:
        return op.interventions_count.open;
    }
};

export default {
    filterBySelectedAssets,
    validate_list_of_ids,
    filterBySelectedAssetClasses,
    filterBySelectedLabels,
    filterBySelectedStatus,
    filterBySelectedTechStatus,
    filterBySelectedOperations,
    compute_alert_count,
    compute_event_count,
    compute_intervention_count,
};
