import { RSAA } from 'redux-api-middleware';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import types from './types';
import collections from './collections';

const getReqTypes = (collection) => {
    const { redux_types } = collections[collection];
    if (Array.isArray(redux_types) && redux_types.length === 3) {
        return redux_types;
    }
    return [
        redux_types.FETCH_LIST_REQUEST,
        redux_types.FETCH_LIST_COMPLETED,
        redux_types.FETCH_LIST_FAILED,
    ];
};

const load_page = (url, collection) => (dispatch) => {
    const req_types = getReqTypes(collection);
    const controller = new window.AbortController();
    return dispatch({
        [RSAA]: {
            endpoint: url,
            method: 'GET',
            credentials: 'same-origin',
            options: {
                signal: controller.signal,
            },
            types: [
                {
                    type: req_types[0],
                    meta: { controller },
                },
                {
                    type: req_types[1],
                    payload: (a, s, r) => r.json()
                        .then((json) => {
                            if (r.ok) {
                                const link = r.headers.get('Link');
                                dispatch({
                                    type: types.FILTER_APPLY,
                                    payload: json,
                                    metadata: {
                                        link,
                                        collection,
                                    },
                                });
                            }
                            return json;
                        }),
                },
                req_types[2],
            ],
        },
    });
};

const navigate = (rel, links, collection) => (dispatch) => {
    const url = links[rel] && links[rel].url;
    return dispatch(load_page(url, collection));
};

const defaultEnumMultiFormatter = (v) => v.join();
const defaultFormatter = (v) => v;

const cancelApply = (collection) => (dispatch) => dispatch({
    type: types.FILTER_APPLY_CANCEL,
    metadata: { collection },
});

const applyFilter = (filters, asset_ids, collection) => (dispatch) => {
    let { url } = collections[collection];
    let formatted_filters = [];
    if (!collections[collection].non_asset && asset_ids.length > 0) {
        formatted_filters.push(
            `asset_ids=${asset_ids.join()}`,
        );
    }
    const { fields } = collections[collection];
    for (const f_key in fields) {
        const field = fields[f_key];
        const value = filters.fields[f_key];
        let active = false;
        let formatter = null;
        if (field.activeChecker && field.activeChecker(value)) {
            active = true;
            formatter = field.valueFormatter || defaultFormatter;
        } else if (field.type === 'enum' && field.multi) {
            if (value) {
                if (value.length > 0) {
                    active = true;
                    formatter = field.valueFormatter || defaultEnumMultiFormatter;
                } else if (field.strict) {
                    active = true;
                }
            }
        } else if (value) {
            active = true;
            formatter = field.valueFormatter || defaultFormatter;
        }
        if (active) {
            if (field.filterFormatter) {
                formatted_filters = field.filterFormatter(value, formatted_filters);
            } else if (formatter) {
                formatted_filters.push(`${f_key}=${formatter(value)}`);
            } else if (field.strict) {
                formatted_filters.push(`${f_key}__isnull=True`);
            }
        }
    }
    url += `?${formatted_filters.join('&')}`;
    return dispatch(load_page(url, collection));
};

const resetFilter = (collection) => ({
    type: types.FILTER_RESET,
    payload: null,
    metadata: { collection },
});

const setField = (collection, field, payload) => ({
    type: types.FILTER_SET_FIELD,
    metadata: { collection, field },
    payload,
});

export default {
    cancelApply,
    navigate,
    applyFilter,
    resetFilter,
    setField,
};
