/**
 * Created by lpostula on 17/05/18.
 */
import types from './types';
import { createReducer, apiHelpers } from '../../utils';

/* State shape
{
    {campaign_id: campaign},
}
 */

const initialState = {};

const listReducer = createReducer(initialState)({
    [types.FETCH_LIST_COMPLETED]: apiHelpers.fetch_list_complete,
    [types.FETCH_DETAILS_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.CREATE_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.UPDATE_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.CANCEL_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.DUPLICATE_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.APPROVE_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.ASSIGN_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.DEASSIGN_COMPLETED]: apiHelpers.fetch_details_complete,
});

export default listReducer;
