const FILTER_APPLY = 'filtering_collections/FILTER_APPLY';
const FILTER_APPLY_CANCEL = 'filtering_collections/FILTER_APPLY_CANCEL';
const FILTER_RESET = 'filtering_collections/FILTER_RESET';
const FILTER_SET_FIELD = 'filtering_collections/FILTER_SET_FIELD';

export default {
    FILTER_APPLY,
    FILTER_APPLY_CANCEL,
    FILTER_RESET,
    FILTER_SET_FIELD,
};
