import { default as actions } from './actions';

export const { fetchLogs } = actions;
export const { fetchDetails } = actions;
export const { createLog } = actions;
export const { editLog } = actions;
export const { archiveLog } = actions;
export const { unarchiveLog } = actions;

export const incomingWS = (data) => (dispatch) => {
    switch (data.type) {
    case 'create':
        dispatch(actions.updateLogInStore(data.payload, true));
        break;
    case 'update':
        dispatch(actions.updateLogInStore(data.payload));
        break;
    default:
        break;
    }
};

export default {
    fetchLogs,
    fetchDetails,
    createLog,
    editLog,
    archiveLog,
    unarchiveLog,
    incomingWS,
};
