/**
 * Created by lpostula on 11/05/17.
 */
import moment from 'moment';
import i18n from 'i18next';
import { default as actions } from './actions';
import { formatErrors } from '../../utils/formatErrors';
import { createSnackBar } from '../ui/operations';
import { userHasPerm } from '../users/selectors';
import selectors from './selectors';

export const { fetchForAsset } = actions;
export const { fetchForIntervention } = actions;
export const { fetchForCampaign } = actions;
export const { fetchByIds } = actions;
export const { fetchList } = actions;
export const { fetchDetails } = actions;
export const { processMaintenanceEvent } = actions;
export const { runPreventiveScheduler } = actions;
export const { discardEvent } = actions;
export const { createEvent } = actions;

const is_permitted = (perm, asset_id, state) => {
    const has_perm = userHasPerm(state)(perm, asset_id);
    if (!has_perm) {
        throw new Error('You dont have the permission');
    }
};

export const closeMaintenanceEvent = (values) => (dispatch) => {
    const done_usages = values.done_usages || [];
    const keys = [];
    const copied_values = { ...values };
    Object.keys(copied_values).forEach((elm) => {
        if (elm.includes('done_usage_')) {
            keys.push(elm);
        }
    });
    Object.keys(keys).forEach((index) => {
        const counter_name = keys[index];
        let counter_type_id = counter_name.split('done_usage_')[1].split('_')[0];
        counter_type_id = counter_type_id === 'undefined' || counter_type_id === 'null' ? null : parseInt(counter_type_id, 10);
        let counter_id = counter_name.split('done_usage_')[1].split('_')[1];
        counter_id = counter_id === 'undefined' || counter_id === 'null' ? null : parseInt(counter_id, 10);
        let component_structure_id = counter_name.split('done_usage_')[1].split('_')[2];
        component_structure_id = component_structure_id === 'undefined' || counter_id === 'null' ? null : parseInt(component_structure_id, 10);
        const value = parseFloat(copied_values[counter_name]);
        done_usages.push({
            counter_type_id,
            value,
            counter_id,
            component_structure_id,
        });
        delete copied_values[counter_name]; // eslint-disable-line no-param-reassign
    });

    copied_values.done_usages = done_usages;

    return dispatch(
        actions.closeMaintenanceEvent(copied_values),
    ).then(
        (d) => {
            // If we get here, it means either we succeed to close the event, or we got an error
            // but we have 2 situations:
            // 1 - If we got an error and in this error, we have a key called error_type
            //     We want to display a confirmation box to allow the user to ignore this error
            // 2 - If we don't, it's a validation error that can't pass

            // Here, we check if we got the key error_type in the response
            const error_type = d.payload.response ? 'error_type' in d.payload.response ? d.payload.response.error_type[0] : null : null;

            // If we got error_type, we want to display a message box with the type of the counter
            // we can retrieve these info with id and counter_type_name
            if (d.error && error_type === 'suspicious_done_usage') {
                const counter_id = d.payload.response ? 'id' in d.payload.response ? parseInt(d.payload.response.id[0], 10) : null : null;
                const counter_type_name = d.payload.response ? 'counter_type_name' in d.payload.response ? d.payload.response.counter_type_name[0] : null : null;
                if (!counter_id && !counter_type_name) {
                    return Promise.resolve(d);
                }
                let old_key = null;
                // we browse all the done_usages we got, if it's a preventive event,
                // we got only one, but if it's a campaign event, we got many.
                for (const key in copied_values.done_usages) {
                    const elm = copied_values.done_usages[key];
                    // If the counter match the concerned counter, we ask a confirmation
                    if (!old_key && elm.counter_id === counter_id) {
                        if (window.confirm(`Value is unexpectedly high compared to daily usage for ${counter_type_name}, do you confirm ${elm.value} as done usage ?`)) {
                            // If ok, we save the location of the counter in the list
                            old_key = key;
                        } else {
                            // otherwise, we send a cancelation of the request
                            return Promise.resolve(d);
                        }
                    }
                }

                // if we haven't found a key, in case of, we send a cancelation too
                if (!old_key) {
                    return Promise.resolve(d);
                }
                // now, for this counter, if we get here, it means the user has agreed
                // to continue with this suspicious value
                // so we need to set a boolean in the payload to let know the backend
                // to ignore this error
                const old_done_usage = copied_values.done_usages;
                old_done_usage[old_key].allow_suspicious_done_usage = true;
                const new_readings = { ...copied_values, done_usages: old_done_usage };
                return dispatch(closeMaintenanceEvent(new_readings));
            } if (d.error) {
                // If we got an error and we don't have the error_type,
                // it means it's a standard validation error
                return Promise.resolve(d);
            }

            // If we get here, it means we got zero errors so the event close normally
            const { restrictions } = d.payload;
            const event_id = d.payload.id;
            let snack_message = i18n.t('close_event_snack', { event_id });
            if (restrictions && restrictions.length) {
                snack_message = i18n.t(
                    'close_event_with_restrictions_snack',
                    {
                        event_id,
                        restrictions: restrictions.join(),
                    },
                );
            }
            dispatch(createSnackBar({
                id: `close_event_${event_id}`,
                message: snack_message,
                className: 'success',
            }));
            return Promise.resolve(d);
        },
    );
};

export const updateMaintenanceEvent = (values) => (dispatch, getState) => {
    const done_usages = [];
    const keys = [];
    Object.keys(values).forEach((elm) => {
        if (elm.includes('done_usage_')) {
            keys.push(elm);
        }
    });
    Object.keys(keys).forEach((index) => {
        const counter_name = keys[index];
        const right = counter_name.split('done_usage_')[1];
        const id_counter = right.split('_')[1];
        const id_counter_reading = right.split('_')[0];
        const counter_value = values[counter_name];
        done_usages[id_counter_reading] = {
            id_counter, counter_value,
        };
        delete values[counter_name]; // eslint-disable-line no-param-reassign
    });

    const state = getState();
    const event = selectors.getMaintenanceEvents(state)[values.id];
    const maintenance_type = values.maintenance_type || event.maintenance_type;

    let counter_reading = {};
    let counterreading_source_set = [];
    if (values.counter_reading) {
        counter_reading = values.counter_reading;
    }
    if (values.counterreading_source_set) {
        counterreading_source_set = values.counterreading_source_set;
    }
    Object.keys(done_usages).map((id_counter_reading) => {
        const elm = done_usages[id_counter_reading];
        const value = elm.counter_value;

        if (maintenance_type === 'preventive' && !values.counter_reading) {
            counter_reading.id = id_counter_reading;
            counter_reading.value = value;
        } else if (maintenance_type === 'campaign' && !values.counterreading_source_set) {
            counterreading_source_set.push({
                id: id_counter_reading,
                value,
            });
        }
        return true;
    });

    let new_values;
    if (maintenance_type === 'preventive') {
        new_values = { ...values, counter_reading };
    } else if (maintenance_type === 'campaign') {
        new_values = { ...values, counterreading_source_set };
    } else {
        new_values = { ...values };
    }

    const asset_id = new_values.asset || event.asset;
    const perm = `edit_${maintenance_type}_event`;
    is_permitted(perm, asset_id, state);
    return dispatch(
        actions.updateMaintenanceEvent(new_values),
    ).then(
        (d) => {
            const error_type = d.payload.response ? 'error_type' in d.payload.response ? d.payload.response.error_type[0] : null : null;
            if (d.error && error_type === 'suspicious_done_usage') {
                const reading_id = d.payload.response ? 'id' in d.payload.response ? parseInt(d.payload.response.id[0], 10) : null : null;
                const counter_type_name = d.payload.response ? 'counter_type_name' in d.payload.response ? d.payload.response.counter_type_name[0] : null : null;
                if (!reading_id && !counter_type_name) {
                    return Promise.resolve(d);
                }
                let old_key = null;

                if (maintenance_type === 'preventive') {
                    const reading = new_values.counter_reading;
                    if (parseInt(reading.id, 10) === reading_id) {
                        if (window.confirm(`Value is unexpectedly high compared to daily usage for ${counter_type_name}, do you confirm ${reading.value} as done usage ?`)) {
                            // If ok, we save the location of the counter in the list
                            old_key = reading.id;
                        } else {
                            // otherwise, we send a cancelation of the request
                            return Promise.resolve(d);
                        }
                    }
                    if (!old_key) {
                        return Promise.resolve(d);
                    }
                    const old_reading = new_values.counter_reading;
                    old_reading.allow_suspicious_done_usage = true;
                    const new_reading = { ...new_values, counter_reading: old_reading };
                    return dispatch(updateMaintenanceEvent(new_reading));
                } if (maintenance_type === 'campaign') {
                    for (const key in new_values.counterreading_source_set) {
                        const reading = new_values.counterreading_source_set[key];
                        if (parseInt(reading.id, 10) === reading_id) {
                            if (window.confirm(`Value is unexpectedly high compared to daily usage for ${counter_type_name}, do you confirm ${reading.value} as done usage ?`)) {
                                old_key = key;
                            } else {
                                return Promise.resolve(d);
                            }
                        }
                    }
                    if (!old_key) {
                        return Promise.resolve(d);
                    }
                    const old_reading = new_values.counterreading_source_set;
                    old_reading[old_key].allow_suspicious_done_usage = true;
                    const new_reading = { ...new_values, counterreading_source_set: old_reading };
                    return dispatch(updateMaintenanceEvent(new_reading));
                }
            } else if (d.error) {
                return Promise.resolve(d);
            }

            const event_id = d.payload.id;
            const snack_message = i18n.t('update_event_snack', { event_id });
            dispatch(createSnackBar({
                id: `update_event_${event_id}`,
                message: snack_message,
                className: 'success',
            }));
            return Promise.resolve(d);
        },
    );
};

export const createMaintenanceEvent = (values) => (dispatch, getState) => {
    // check that the date_incident is not greater than now or the date opened
    const state = getState();
    const perm = `create_${values.maintenance_type === 'servicing' ? 'corrective' : values.maintenance_type}_event`;
    const asset_id = values.asset;
    is_permitted(perm, asset_id, state);
    const errors = {};
    let has_errors = false;
    let max_date = moment();
    if (values.date_opened) {
        max_date = values.date_opened;
    }
    if (values.maintenance_type !== 'preventive' && values.date_incident.isAfter(max_date)) {
        errors.date_incident = ['cannot be greater than the date_opened'];
        has_errors = true;
    }
    if (has_errors) {
        return Promise.resolve(formatErrors(errors));
    }
    return dispatch(
        actions.createMaintenanceEvent(values),
    );
};

export const incomingWS = (data) => (dispatch) => {
    switch (data.type) {
    case 'update':
        dispatch(actions.updateEventInStore(data.payload));
        break;
    case 'delete':
        dispatch(actions.discardEventInStore(data.payload));
        break;
    default:
        break;
    }
};

export const assignFaultCategory = (id, fault_category_id) => (dispatch) => dispatch(
    actions.assignFaultCategoryToEvent(id, fault_category_id),
).then(
    (d) => {
        // forward errors if the assignation failed
        if (d.error) {
            return dispatch(createSnackBar({
                id: `error_classify_event_${id}`,
                message: d.error || 'error',
                className: 'danger',
            }));
        }
        return dispatch(createSnackBar({
            id: `success_classify_event_${id}`,
            message: i18n.t('Fault category successfully assign to event'),
            className: 'success',
        }));
    },
);

export default {
    fetchForAsset,
    fetchForIntervention,
    fetchForCampaign,
    fetchByIds,
    fetchDetails,
    fetchList,
    closeMaintenanceEvent,
    processMaintenanceEvent,
    updateMaintenanceEvent,
    createMaintenanceEvent,
    runPreventiveScheduler,
    discardEvent,
    createEvent,
    incomingWS,
    assignFaultCategory,
};
