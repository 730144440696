/**
 * Created by lpostula on 11/05/17.
 */
import types from './types';
import { createReducer, apiHelpers } from '../../utils';

/* State shape
{
    {place_id: place},
}
 */
const initialState = {
    search_results: [],
    search_query: 0,
};

// Note From Jeremie:
// search query is used to display loading
// if you want to enable multiple query loader you could
//      replace "search_query: 0" with "search_query: (state.search_query - 1)"
//      replace "search_query: 1" with "search_query: (state.search_query + 1)"
// but in that case you must make the state volatile (not stored in localstorage)
// because at the first query that break it will stay in loading until storage is wiped
// also the benefits of such an action isn't as great as one could assume
// since all the loaders will load at the same time

const listReducer = createReducer(initialState)({
    [types.FETCH_LIST_COMPLETED]: apiHelpers.fetch_list_complete,
    [types.FETCH_DETAILS_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.SEARCH_COMPLETED]: (state, action) => {
        // we will put the location in the store, and put the id of the result in a list
        // on the search_results key. A selector, will recreate a correct search_results
        // based on that.
        const new_state = apiHelpers.fetch_list_complete(state, action);
        const search_result = _.map(action.payload, (l) => l.id);
        return {

            ...new_state,
            search_results: search_result,
            search_query: 0,
        };
    },
    [types.SEARCH_FAILED]: (state) => ({
        ...state, search_query: 0,
    }),
    [types.SEARCH_REQUEST]: (state) => ({
        ...state, search_query: 1,
    }),
    [types.SEARCH_FOR_ASSET_BY_DATE_COMPLETED]: (state, action) => ({

        ...apiHelpers.fetch_details_complete(state, action),
        search_query: 0,
    }),
    [types.SEARCH_FOR_ASSET_BY_DATE_FAILED]: (state) => ({
        ...state, search_query: 0,
    }),
    [types.SEARCH_FOR_ASSET_BY_DATE_REQUEST]: (state) => ({
        ...state, search_query: 1,
    }),

});

export default listReducer;
