import PropTypes from 'prop-types';
import 'leaflet';
import 'leaflet.markercluster';

import MapLayer from './MapLayer';

import './ClusturableMap.css';
import './ClusturableMap.Default.css';

const GPS_STATUS_TO_COLOR = {
    no_gps: '#717B8C',
    vehicle: '#717B8C',
    parking: '#717B8C',
    traction: '#039F73',
    poweron: '#039F73',
    standby: '#039F73',
    unknown: '#717B8C',
};

class ClusterIcon extends L.DivIcon {
    static initClass() {
        this.prototype.options = {
            iconSize: new L.Point(40, 40),
            className: 'map-cluster-icon',
        };
    }

    createIcon() {
        const e = document.createElement('canvas');
        this._setIconStyles(e, 'icon');
        const s = this.options.iconSize;
        if (L.Browser.retina) {
            e.width = s.x + s.x;
            e.height = s.y + s.y;
        } else {
            e.width = s.x;
            e.height = s.y;
        }

        this.draw(e.getContext('2d'), e);
        return e;
    }

    draw(canvas, e) {
        let can = canvas;
        const count = this.options.cluster.getChildCount();
        const inRadius = 14;
        const outRadius = 19;
        let center = e.width / 2;

        if (L.Browser.retina) {
            canvas.scale(2, 2);
            center /= 2;
            can.lineWidth = 0.5;
        }

        // compute stats...
        const stats = {
            no_gps: 0,
            vehicle: 0,
            parking: 0,
            traction: 0,
            poweron: 0,
            standby: 0,
            unknown: 0,
        };

        const markers = this.options.cluster.getAllChildMarkers();
        for (const marker of Array.from(markers)) {
            const { live_data } = marker.options.asset;
            const asset_status = (live_data['telematic.status'] && live_data['telematic.status'][0]) || 'no_gps';
            stats[asset_status] += 1;
        }

        // draw piecharts
        can.strokeStyle = 'rgba(0,0,0,0.25)';
        let start = 0;
        let stroke = true;
        for (const status in stats) {
            const val = stats[status];
            if (val > 0) {
                const size = val / count;
                stroke = size !== 1;
                can.beginPath();
                can.moveTo(center, center);
                can.fillStyle = GPS_STATUS_TO_COLOR[status];
                let from = start + 0.14;
                const to = start + (size * Math.PI * 2);
                if ((to < from) || (size === 1)) {
                    from = start;
                }

                can.arc(center, center, outRadius, from, to);
                start += (size * Math.PI * 2);
                can.lineTo(center, center);
                can.fill();
                if (stroke) {
                    can.stroke();
                }

                can.closePath();
            }
        }

        if (!stroke) {
            can.beginPath();
            can.arc(center, center, outRadius, 0, Math.PI * 2);
            can.stroke();
            can.closePath();
        }

        // draw inner disk
        can.beginPath();
        can = Object.assign(can, {
            fillStyle: 'white',
        });
        can.moveTo(center, center);
        can.arc(center, center, inRadius, 0, Math.PI * 2);
        can.fill();
        can.closePath();
        can = Object.assign(can, {
            fillStyle: '#454545',
            textAlign: 'center',
            textBaseline: 'middle',
            font: `bold ${count < 100 ? '12' : (count < 1000 ? '11' : '9')}px sans-serif`,
        });

        can.fillText(count, center, center, inRadius * 2);
    }
}
ClusterIcon.initClass();

export default class ClusturableMap extends MapLayer {
    getChildContext() {
        return {
            clusterable: true,
            map: this.leafletElement,
        };
    }

    createLeafletElement() {
        return new L.MarkerClusterGroup({
            maxClusterRadius: this.props.maxClusterRadius,
            showCoverageOnHover: this.props.showCoverageOnHover,
            disableClusteringAtZoom: this.props.disableClusteringAtZoom,
            iconCreateFunction: (cluster) => new ClusterIcon({ cluster, map: this.context.map }),
        });
    }

    render() {
        return this.props.children;
    }
}
ClusturableMap.defaultProps = {
    maxClusterRadius: 30,
    showCoverageOnHover: false,
    disableClusteringAtZoom: 15,
};
ClusturableMap.propTypes = {
    maxClusterRadius: PropTypes.number,
    showCoverageOnHover: PropTypes.bool,
    disableClusteringAtZoom: PropTypes.number,
};
ClusturableMap.contextTypes = {
    map: PropTypes.object,
};
ClusturableMap.childContextTypes = {
    clusterable: PropTypes.bool,
    map: PropTypes.object,
};
