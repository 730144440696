/**
 * Created by lpostula on 05/12/17.
 */
import types from './types';

export const connectWS = () => ({
    type: types.CONNECTING,
});
export const reconnectWS = () => ({
    type: types.RECONNECTING,
});
export const openWS = (instance) => ({
    type: types.OPENED,
    payload: instance,
});
export const messageWS = (data) => ({
    type: types.MESSAGED,
    payload: data,
});
export const errorWS = (error) => ({
    type: types.ERROR,
    payload: error,
});
export const incAttemptWS = () => (dispatch) => {
    dispatch({
        type: types.INC_ATTEMPT,
    });
    return Promise.resolve();
};

export const closeWS = () => ({
    type: types.CLOSED,
});

export const revertToPolling = () => ({
    type: types.REVERT_TO_POLLING,
});

export const registerToStream = (stream, asset_id, on_event) => ({
    type: types.REGISTER_TO_STREAM,
    payload: {
        stream,
        asset_id,
        on_event,
    },
});

export const unRegisterToStream = (stream, asset_id) => ({
    type: types.UNREGISTER_TO_STREAM,
    payload: {
        stream,
        asset_id,
    },
});

export const registerToFleetStream = (stream, on_event) => ({
    type: types.REGISTER_TO_FLEET_STREAM,
    payload: {
        stream,
        on_event,
    },
});

export const unRegisterToFleetStream = (stream) => ({
    type: types.UNREGISTER_TO_FLEET_STREAM,
    payload: {
        stream,
    },
});

export default {
    connectWS,
    reconnectWS,
    openWS,
    messageWS,
    errorWS,
    incAttemptWS,
    revertToPolling,
    closeWS,
    registerToStream,
    unRegisterToStream,
    registerToFleetStream,
    unRegisterToFleetStream,
};
