/**
 * Created by lpostula on 17/05/18.
 */

import { createSelector } from 'reselect';

import m_e_selectors from '../maintenance_events/selectors';

export const getCampaigns = (state) => state.campaigns;

const EMPTY_OBJECT = {};

const getEventsIdForCampaign = (state, id) => (
    state.campaigns[id] || EMPTY_OBJECT
).events;

export const getEventsForCampaign = createSelector(
    [getEventsIdForCampaign, m_e_selectors.getMaintenanceEvents],
    (ids, events) => ids.map((i) => events[i] || EMPTY_OBJECT),
);
export default {
    getCampaigns,
    getEventsForCampaign,
};
