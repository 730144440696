/**
 * Created by lpostula on 26/10/17.
 */

import reducer from './reducers';

export { default as uiOperations } from './operations';
export { default as uiSelectors } from './selectors';

export default reducer;
