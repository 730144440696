/* eslint-disable react/no-this-in-sfc */

import React from 'react';
import i18n from 'i18next';

import moment from 'moment-timezone';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import addNoDataModule from 'highcharts/modules/no-data-to-display';
import XRange from 'highcharts/modules/xrange';
import Boost from 'highcharts/modules/boost';
import More from 'highcharts/highcharts-more';
import lang_options from './langs';
import degroup_icon from './degroup.svg';

import './LineGraph.scss';

addNoDataModule(Highcharts);
// test for webgl
// see https://developer.mozilla.org/en-US/docs/Web/API/WebGL_API/By_example/Detect_WebGL
const canvas = document.createElement('canvas');
const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
const has_webgl = gl && gl instanceof WebGLRenderingContext;
if (has_webgl) {
    Boost(Highcharts);
}
More(Highcharts);
XRange(Highcharts);

/**
 * Override the reset function, we don't need to hide the tooltips and
 * crosshairs when the pointer gets out of the charts.
 */
Highcharts.Pointer.prototype.reset = () => undefined;

const setHighChartsOptions = (props) => {
    Highcharts.setOptions({
        global: {
            useUTC: true,
        },
        time: {
            timezone: props.timezone,
            moment,
        },
    });
    const o = lang_options[props.lang];
    if (o !== undefined) {
        Highcharts.setOptions({
            lang: o,
        });
    }
};

class LineGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.charts = {}; // hold a dictionary of charts created.
        this.hoverListener = this.handleHover.bind(this);
        this.mouseOutListener = this.handleMouseOut.bind(this);
    }

    handleHover(originalEvent) {
        for (const item of Object.entries(this.charts)) {
            const chart = item[1];
            if (chart && chart.series) {
                const event = chart.pointer.normalize(originalEvent);
                const points = [];
                for (const subserie of chart.series) {
                    if (subserie.points.length > 0) {
                        const point = subserie.searchPoint(event, true);
                        if (point) {
                            point.onMouseOver();
                            chart.xAxis[0].drawCrosshair(event, point);
                            points.push(point);
                        }
                    }
                }
                if (points.length) {
                    chart.tooltip.refresh(points);
                }
            }
        }
    }

    handleMouseOut() {
        for (const item of Object.entries(this.charts)) {
            const chart = item[1];
            if (chart && chart.series) {
                chart.tooltip.hide();
            }
        }
    }

    componentDidMount() {
        setHighChartsOptions(this.props);
        const element = document.body.querySelector('div.graphs');
        for (const eventType of ['mousemove', 'touchmove', 'touchstart']) {
            element.addEventListener(eventType, this.hoverListener);
        }
        element.addEventListener('mouseout', this.mousOutListener);
    }

    componentDidUpdate() {
        setHighChartsOptions(this.props);
    }

    componentWillUnmount() {
        const element = document.body.querySelector('div.graphs');
        for (const eventType of ['mousemove', 'touchmove', 'touchstart']) {
            element.removeEventListener(eventType, this.hoverListener);
        }
        element.removeEventListener('mouseout', this.mousOutListener);
        delete this.charts;
    }

    render() {
        const self = this;
        const registerChart = (k, chart) => {
            self.charts[k] = chart;
        };
        const graphs = this.props.graphs.filter((graph) => graph.length > 0)
            .map((graph, i, all_graphs) => {
                const selected = _.indexOf(this.props.mergeChecked, i) !== -1;
                const onSelectChange = (e) => {
                    this.props.checkGroupChange(i, e.target.checked);
                };
                const onCickDegroup = () => {
                    this.props.degroup(i);
                };
                const combinedKey = graph.map((serie) => serie.key).join(',');
                // Issue #8649 - a minimal height for the X axis, labels and stacked tooltips.
                let height = (graph.length * 30) + 80;
                if (all_graphs.length === 1) {
                    height = Math.max(600, height);
                } else if (all_graphs.length === 2) {
                    height = Math.max(370, height);
                } else {
                    height = Math.max(200, height);
                }
                return (
                    <Chart
                        key={combinedKey}
                        height={height}
                        series={graph}
                        start_date={this.props.start_date}
                        end_date={this.props.end_date}
                        selectable={this.props.selectable}
                        selected={selected}
                        onSelectChange={onSelectChange}
                        onCickDegroup={onCickDegroup}
                        highChartCallback={(c) => { registerChart(combinedKey, c); }}
                        zoomIn={this.props.zoomIn}
                    />
                );
            });
        const step_graphs = this.props.step_graphs.map((graph) => (
            <Chart
                key={graph.key}
                height="140"
                series={[graph]}
                start_date={this.props.start_date}
                end_date={this.props.end_date}
                isFaultCode
                highChartCallback={(c) => { registerChart(graph.key, c); }}
                zoomIn={this.props.zoomIn}
            />
        ));
        const alert_graphs = this.props.alert_graphs.map((graph) => (
            <Chart
                key={graph.key}
                height="140"
                series={[graph]}
                start_date={this.props.start_date}
                end_date={this.props.end_date}
                isAlert
                highChartCallback={(c) => { registerChart(graph.key, c); }}
                zoomIn={this.props.zoomIn}
            />
        ));
        const rule_graphs = this.props.rule_graphs.map((graph) => (
            <Chart
                key={graph.key}
                height="140"
                series={[graph]}
                start_date={this.props.start_date}
                end_date={this.props.end_date}
                isRule
                highChartCallback={(c) => { registerChart(graph.key, c); }}
                zoomIn={this.props.zoomIn}
            />
        ));
        return (
            <div className="graphs">
                {graphs}
                {step_graphs}
                {alert_graphs}
                {rule_graphs}
            </div>
        );
    }
}

LineGraph.defaultProps = {
    lang: 'en',
    timezone: 'Europe/Brussels',
    graphs: [],
    step_graphs: [],
    alert_graphs: [],
    rule_graphs: [],
    selectable: false,
    mergeChecked: [],
};
const Chart = (props) => {
    const isCombined = props.series.length > 1;
    let labelTitle = '';
    if (props.isFaultCode) {
        labelTitle = "<span class='label-title'>FAULT CODE </span>";
    }
    if (props.isAlert) {
        labelTitle = "<span class='label-title'>ALERT </span>";
    }
    if (props.isRule) {
        labelTitle = "<span class='label-title'>RULE </span>";
    }
    const title = props.series.map((serie) => {
        const subtitle = serie.subtitle.length > 0 ? `<span class="subtitle">(${serie.subtitle})</span>` : '';
        if (serie.data.length > 0) {
            return `${labelTitle}<span class="graph-title multi" style="background-color: ${serie.color}">${serie.title} ${subtitle}</span>`;
        }
        if (serie.errorMessage != null) {
            const errorTitle = serie.errorTitle;
            return `${labelTitle}<span class="graph-title multi" style="background-color: #E3E8EF; color: #717B8C">${serie.title} ${subtitle} - <span style="background-color: #E3E8EF; color: black">${errorTitle}</span></span>`;
        }
        return `${labelTitle}<span class="graph-title multi" style="background-color: #E3E8EF; color: #717B8C">${serie.title} ${subtitle} - <span style="background-color: #E3E8EF; color: black">${i18n.t('No data found')}</span></span>`;
    }).join('');
    const tooltip = props.isFaultCode || props.isAlert || props.isRule ? {
        valueDecimals: 0,
        split: true,
        pointFormatter() {
            return this.y === 0 ? 'Closed' : 'Open';
        },
    } : {
        valueDecimals: 2,
        split: true,
    };

    const series = props.series.map((serie) => ({
        ...serie,
        name: serie.title,
        tooltip: {
            valueSuffix: ` ${serie.unit}`,
        },
    }));
    const options = {
        chart: {
            marginLeft: 80, // Keep all charts left aligned
            spacingTop: 10,
            spacingBottom: 10,
            zoomType: 'x',
            height: props.height,
            series: props.series,
            animation: false,
            style: { fontFamily: 'Roboto' },
            events: {
                selection: (event) => {
                    event.preventDefault();
                    props.zoomIn(event.xAxis[0].min, event.xAxis[0].max);
                },
                render() {
                    const chart = this;
                    if (chart.noDataLabel != null && chart.noDataLabel.element != null) {
                        chart.noDataLabel.element.remove();
                        delete chart.noDataLabel;
                    }
                    if (chart.warningLabel != null && chart.warningLabel.element != null) {
                        chart.warningLabel.element.remove();
                        delete chart.warningLabel;
                    }
                    const seriesWithWarningMessage = this.userOptions.chart.series.filter((serie) => serie.warningMessage != null);
                    if (seriesWithWarningMessage.length > 0) {
                        const titleBBox = chart.title.getBBox();
                        let warningPositonX = 80;
                        let warningPositonY = titleBBox.height + 23;
                        if (this.userOptions.chart.series.length === 1) {
                            const childrenChart = chart.title.element.children;
                            const lastChildChart = childrenChart[childrenChart.length - 1].getBoundingClientRect();
                            warningPositonX = lastChildChart.width + titleBBox.x;
                            warningPositonY = lastChildChart.height + titleBBox.y;
                        }
                        let seriesTitle = '';
                        let i;
                        for (i = 0; i < seriesWithWarningMessage.length; i += 1) {
                            seriesTitle += `${seriesWithWarningMessage[i].title} ${seriesWithWarningMessage[i].subtitle}`;
                            if (i < seriesWithWarningMessage.length - 1) {
                                seriesTitle += ', ';
                            }
                        }
                        const limitQuery = seriesWithWarningMessage[0].limitQuery;
                        const warningMessages = i18n.t('Your query was limited to {{limitQuery}} points on {{seriesTitle}}. Consider a smaller date range to get below this limit.', { limitQuery, seriesTitle });
                        if (chart.renderer && warningPositonY > 0) {
                            chart.warningLabel = chart.renderer.html(`<div class="warning-Message"><img class="warning-SVG"><div class="warning-Label">${warningMessages}</div><div class="btn-close"/></div>`, warningPositonX, warningPositonY)
                                .on('click', () => {
                                    chart.warningLabel.element.remove();
                                    delete chart.warningLabel;
                                })
                                .add();
                        }
                    }
                    const isSeriesWithoutData = this.userOptions.chart.series.every((serie) => serie.data.length === 0);
                    if (isSeriesWithoutData && chart.renderer && !chart.noDataLabel) {
                        const noDataOptions = chart.options && chart.options.noData;
                        let noDataErrorLabelHTML = '';
                        let noDataLabelHTML = '';
                        const seriesWithErrorMessage = this.userOptions.chart.series.filter((serie) => serie.errorMessage != null);
                        if (seriesWithErrorMessage.length > 0) {
                            const seriesMessageError = seriesWithErrorMessage.map((serieWithErrorMessage) => {
                                const subtitle = serieWithErrorMessage.subtitle !== '' ? `${serieWithErrorMessage.subtitle} ` : ' ';
                                return `<li>${serieWithErrorMessage.title} ${subtitle}- ${serieWithErrorMessage.errorMessage}</li>`;
                            }).join('');
                            noDataErrorLabelHTML = `<div class="grid-errordata-image"><img class="errorData-SVG"></div><div class="grid-errordata-message"><ul class="errorData-Label">${seriesMessageError}</ul></div>`;
                        }
                        const seriesWithoutData = this.userOptions.chart.series.filter((serie) => serie.errorMessage == null);
                        if (seriesWithoutData.length > 0) {
                            const seriesMessageNoData = seriesWithoutData.map((serieWithoutData) => {
                                const subtitle = serieWithoutData.subtitle !== '' ? `${serieWithoutData.subtitle}` : ' ';
                                return `<li>${serieWithoutData.title} ${subtitle}- ${i18n.t('No data found for that period')}</li>`;
                            }).join('');
                            noDataLabelHTML = `<div class="grid-nodata-image"><img class="noData-SVG"></div>
                            <div class="grid-nodata-message"><ul class="noData-Label">${seriesMessageNoData}</ul></div>`;
                        }
                        chart.noDataLabel = chart.renderer.html(`
                        <div class="message-container">
                        <div class="grid-container">${noDataErrorLabelHTML}</div>
                        <div style="height:5px"></div>
                        <div class="grid-container">${noDataLabelHTML}</div>
                        </div>
                        `).add();
                        chart.noDataLabel.align(Highcharts.extend(chart.noDataLabel.getBBox(), { ...noDataOptions.position, x: 5 }), false, 'plotBox');
                    }
                },
            },
        },
        lang: {
            noData: '',
        },
        noData: {
            position: {
                align: 'left',
                x: 58,
            },
        },
        plotOptions: {
            series: {
                ...!props.isFaultCode && {
                    // 300s in ms. Way greater than the 40s period but way lower
                    // than the 2h wakup period
                    gapSize: 300 * 1000,
                    gapUnit: 'value',
                },
                animation: false,
                states: {
                    hover: { lineWidthPlus: 0 },
                    inactive: { enabled: false },
                },
            },
        },
        title: {
            text: title,
            align: 'left',
            margin: 0,
            x: 80,
            useHTML: true,
        },
        credits: { enabled: false },
        legend: { enabled: false },
        xAxis: {
            type: 'datetime',
            crosshair: true,
            min: props.start_date,
            max: props.end_date,
        },
        yAxis: {
            title: { text: null },
            ...props.isFaultCode && { categories: ['0', '1'] },
            ...props.isAlert && { categories: ['0', '1'] },
            ...props.isRule && { categories: ['0', '1'] },
        },
        tooltip,
        series,
    };

    let right_elem;

    if (!props.isFaultCode || !props.isAlert || !props.isRule) {
        if (props.selectable) {
            right_elem = (
                <div className="combiner">
                    <input type="checkbox" checked={props.selected} onChange={props.onSelectChange} />
                </div>
            );
        } else if (isCombined) {
            right_elem = (
                <div className="combiner combined">
                    {i18n.t('Grouped symbols')}
                    <span className="symbol-count">{props.series.length}</span>
                    <button onClick={props.onCickDegroup} className="degroup">
                        <img src={degroup_icon} alt={i18n.t('De-group')} />
                    </button>
                </div>
            );
        }
    }

    return (
        <div className="graph-container">
            {right_elem}
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                callback={props.highChartCallback}
            />
        </div>
    );
};

Chart.defaultProps = {
    isFaultCode: false,
    isAlert: false,
    isRule: false,
};

export default LineGraph;
