/**
 * Created by lpostula on 26/10/17.
 */

const FETCH_LINEAS_GMAO_API_MESSAGE_LOGS_REQUEST = 'lineas_gmao/FETCH_LINEAS_GMAO_API_MESSAGE_LOGS_REQUEST';
const FETCH_LINEAS_GMAO_API_MESSAGE_LOGS_COMPLETED = 'lineas_gmao/FETCH_LINEAS_GMAO_API_MESSAGE_LOGS_COMPLETED';
const FETCH_LINEAS_GMAO_API_MESSAGE_LOGS_FAILED = 'lineas_gmao/FETCH_LINEAS_GMAO_API_MESSAGE_LOGS_FAILED';

export default {
    FETCH_LINEAS_GMAO_API_MESSAGE_LOGS_REQUEST,
    FETCH_LINEAS_GMAO_API_MESSAGE_LOGS_COMPLETED,
    FETCH_LINEAS_GMAO_API_MESSAGE_LOGS_FAILED,
};
