import React, { useState } from 'react';
import i18n from 'i18next';
import './PreparationCard.scss';
import moment from 'moment-timezone';
import MultiDeviceLayout from '../../app/MultiDeviceLayout';
import ClockLogo from './Clock.svg';
import ClockLogoBlack from './ClockBlack.svg';
import Conform from './Conform.svg';
import NotConform from './NotConform.svg';

const PreparationCardComponent = (props) => {
    if (!props.lastExecution) {
        return (
            <div
                className={`preparation-card-root ${props.device}`}
                onClick={props.onClick}
            >
                <div className={`preparation-card ${props.remainingTime > 0 ? 'remaining' : ''}`}>
                    <div className="preparation-card-content">
                        <div>
                            <div className="preparation-card-type">
                                <div>{`${i18n.t('Preparation')}`}</div>
                            </div>
                            <div className="preparation-card-label">
                                <div>{props.content}</div>
                            </div>
                            <div className="preparation-card-remaining" />
                        </div>
                        <div>
                            <div className="preparation-card-last" />
                            <div className="preparation-card-conformity" />
                        </div>
                    </div>
                    <div className="preparation-card-link">
                        <span className="glyphicon glyphicon-chevron-right" />
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div
            className={`preparation-card-root ${props.device}`}
            onClick={props.onClick}
        >
            <div className={`preparation-card ${props.remainingTime > 0 && props.remainingTime <= 3 && props.lastExecution ? 'remaining' : ''} ${(props.remainingTime !== null && props.remainingTime <= 0) && props.lastExecution ? 'overdue' : ''}`}>
                <div className="preparation-card-content">
                    <div>
                        <div className="preparation-card-type">
                            <div>{`${i18n.t('Preparation')}`}</div>
                        </div>
                        <div className="preparation-card-label">
                            <div>{props.content}</div>
                        </div>
                        <div className="preparation-card-remaining">
                            {
                                props.remainingTime > 0
                                && (
                                    <>
                                        <img src={props.remainingTime <= 3 ? ClockLogo : ClockLogoBlack} alt="clock logo" />
                                        <div>{`${props.remainingTime}h ${i18n.t('left')}`}</div>
                                    </>
                                )
                            }
                            {
                                props.remainingTime === 0
                                && (
                                    <>
                                        <img src={props.remainingTime <= 3 ? ClockLogo : ClockLogoBlack} alt="clock logo" />
                                        <div>{`< 1h ${i18n.t('left')}`}</div>
                                    </>
                                )
                            }
                            {
                                (props.remainingTime !== null && props.remainingTime < 0) && props.lastExecution
                                && (
                                    <>
                                        <div>{i18n.t('EXPIRED')}</div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <div>
                        <div className="preparation-card-last">
                            <div>{`${i18n.t('Latest execution:')} ${moment(props.lastExecution).format('DD/MM/YYYY HH:mm:ss')}`}</div>
                        </div>
                        <div className={`preparation-card-conformity ${props.conform ? 'ok' : 'notOk'}`}>
                            <img src={props.conform ? Conform : NotConform} alt="conform logo" />
                            {
                                props.conform
                                    ? <div>{i18n.t('Was conform')}</div>
                                    : <div>{i18n.t('Was not conform')}</div>
                            }
                        </div>
                    </div>
                </div>
                <div className="preparation-card-link">
                    <span className="glyphicon glyphicon-chevron-right" />
                </div>
            </div>
        </div>
    );
};

const PreparationCard = (props) => (
    <MultiDeviceLayout>
        <PreparationCardComponent {...props} />
    </MultiDeviceLayout>
);

export default PreparationCard;
