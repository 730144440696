import { alertsOperations } from '../alerts';
import { assetsOperations } from '../assets';
import { labelsOperations } from '../labels';
import { labelCategoriesOperations } from '../label_categories';
import { delegationsOperations } from '../delegations';
import { operationsOperations } from '../operations';
import { operationGroupsOperations } from '../operation_groups';
import { assetOperationalStatusOperations } from '../asset_operational_status';
import { restrictionsOperations } from '../restrictions';
import { interventionsOperations } from '../interventions';
import { maintenanceEventsOperations } from '../maintenance_events';
import { logsOperations } from '../logs';
import { logDocumentsOperations } from '../log_documents';
import { campaignOperations } from '../campaigns';
import { campaignDocumentsOperations } from '../campaign_documents';
import { maintenancePlansOperations } from '../maintenance_plans';
import { countersOperations } from '../counters';
import { uiOperations } from '../ui';
import { hotlinesOperations } from '../hotlines';
import { default as actions } from './actions';
import { WS_ROOT, WS_CONFIG, API_ROOT } from '../../config';
import errors from './errors';
import ws_selectors from './selectors';

const { connectWS } = actions;
const { reconnectWS } = actions;
const { openWS } = actions;
const { messageWS } = actions;
const { errorWS } = actions;
const { incAttemptWS } = actions;
const { closeWS } = actions;

const waitForSocket = (getState, callback) => {
    const state = ws_selectors.getWSState(getState()) || {};
    const socket = state.instance;
    setTimeout(() => {
        if (state.revert_to_polling) {
            callback();
        } else if (socket && socket.readyState === 1) {
            callback(socket);
        } else {
            waitForSocket(getState, callback);
        }
    }, 5);
};

const sendMessage = (payload) => (dispatch, getState) => {
    waitForSocket(getState, (s) => {
        if (s) {
            // socket is established
            s.send(JSON.stringify(payload));
        } else {
            const id = ws_selectors.getPollingID(getState());
            const message = { ...payload };
            if (id) {
                if (!message.meta) {
                    message.meta = {};
                }
                message.meta.polling_id = id;
            }
            $.ajax({
                type: 'POST',
                url: `${API_ROOT}ws_poll/push.json`,
                data: JSON.stringify(message),
                contentType: 'application/json',
            });
        }
    });
};

let BATCH_CACHE = {};
let LAST_BATCH_UPDATE = Date.now();

const incomingStream = (action) => (dispatch, getState) => {
    // console.log('incomming', action);
    const { stream } = action.payload;
    const { asset_id } = action.payload;
    const payload = action.payload.content;
    const state = getState().ws;
    // console.log(stream, asset_id, state.streams[stream]);
    if (!state) return;
    // if (!state.streams.hasOwnProperty(stream)) {
    //     dispatch(unRegisterToStream(stream, asset_id));
    //     return;
    // }
    const stream_info = state.streams[stream];
    if (stream_info) {
        if (stream_info.hasOwnProperty('any')) {
            const callback = stream_info.any;
            if (callback) {
                callback(payload, stream, asset_id);
            }
        } else if (stream_info.hasOwnProperty(asset_id)) {
            const callback = stream_info[asset_id];
            if (callback) {
                callback(payload, stream, asset_id);
            }
        } else {
            // dispatch(unRegisterToStream(stream, asset_id));
        }
    }
    // const to_dispatch = assetsOperations.liveDataPartialUpdate(asset_id, payload);
    // dispatch(to_dispatch);

    // handle batch update
    if (stream === 'live_data') {
        if (!BATCH_CACHE[asset_id]) {
            BATCH_CACHE[asset_id] = {};
        }
        const { timestamp } = payload;

        for (const k in payload) {
            if (!payload.hasOwnProperty(k)) continue;
            if (k !== 'timestamp') {
                BATCH_CACHE[asset_id][k] = [payload[k], timestamp];
            }
        }

        const delta = Date.now() - LAST_BATCH_UPDATE;
        const interval = Document.hidden ? 30000 : 500;
        if (delta > interval) {
            const to_dispatch = assetsOperations.liveDataPartialUpdate(BATCH_CACHE, true);
            dispatch(to_dispatch);

            BATCH_CACHE = {};
            LAST_BATCH_UPDATE = Date.now();
        }
    } else if (stream === 'counters') {
        for (const received_action of payload) {
            dispatch(countersOperations.incomingWS(received_action));
        }
    }
};

const WS_HANDLERS = {
    alerts: alertsOperations.incomingWS,
    assets_infos: assetsOperations.incomingWS,
    assets_labels: assetsOperations.incomingWS,
    assets_operationals: assetsOperations.incomingWS,
    labels: labelsOperations.incomingWS,
    label_categories: labelCategoriesOperations.incomingWS,
    delegation: delegationsOperations.incomingWS,
    operations: operationsOperations.incomingWS,
    operation_groups: operationGroupsOperations.incomingWS,
    asset_operational_status: assetOperationalStatusOperations.incomingWS,
    restrictions: restrictionsOperations.incomingWS,
    interventions: interventionsOperations.incomingWS,
    maintenance_events: maintenanceEventsOperations.incomingWS,
    logs: logsOperations.incomingWS,
    log_documents: logDocumentsOperations.incomingWS,
    campaigns: campaignOperations.incomingWS,
    campaign_documents: campaignDocumentsOperations.incomingWS,
    maintenance_plan: maintenancePlansOperations.incomingWS,
    counters: countersOperations.incomingWS,
    stream: incomingStream,
    ui: uiOperations.incomingWS,
    hotlines: hotlinesOperations.incomingWS,
};

const handleWSMessage = (data) => (dispatch) => {
    // when upgrading to redux 7, try to use batch() here
    // see https://react-redux.js.org/api/batch
    for (const action of data) {
        const handler = WS_HANDLERS[action.resource];
        if (handler !== undefined) {
            dispatch(handler(action));
        } else {
            dispatch(errorWS(errors.UNMATCH_RESOURCE));
        }
    }
};

const startPolling = (_polling_done = 0, _reconnect_attempts = 0) => (dispatch, getState) => {
    let polling_done = _polling_done;
    let reconnect_attempts = _reconnect_attempts;
    const state = getState();
    if (polling_done > WS_CONFIG.maxPolling) {
        reconnect_attempts += 1;
        // eslint-disable-next-line no-use-before-define
        return dispatch(initSocket(reconnect_attempts));
    }
    polling_done += 1;
    const polling_id = ws_selectors.getPollingID(state);
    let url = `${API_ROOT}ws_poll/pull.json`;
    if (polling_id) {
        url = `${url}?polling_id=${polling_id}`;
    }
    const polling_interval = (Document.hidden
        ? WS_CONFIG.hiddenPollingINterval : WS_CONFIG.pollingInterval
    );
    return $.getJSON(
        url,
        (d) => dispatch(handleWSMessage(d)),
    ).always(() => setTimeout(() => dispatch(startPolling(polling_done, reconnect_attempts)), polling_interval));
};

const revertToPolling = (_reconnect_attempts = 0) => (dispatch, getState) => {
    const state = getState();
    if (!state.ws.revert_to_polling) {
        dispatch(actions.revertToPolling());
    }
    dispatch(
        sendMessage({
            type: 'REGISTER_TO_WS',
        }),
    );
    dispatch(startPolling(0, _reconnect_attempts));
};

export const registerToStream = (stream, asset_id, on_event) => (dispatch) => {
    dispatch(
        sendMessage({
            type: 'REGISTER_TO_STREAM',
            payload: {
                stream,
                asset_id,
            },
        }),
    );
    return dispatch(actions.registerToStream(stream, asset_id, on_event));
};

export const unRegisterToStream = (stream, asset_id) => (dispatch) => {
    dispatch(
        sendMessage({
            type: 'UNREGISTER_TO_STREAM',
            payload: {
                stream,
                asset_id,
            },
        }),
    );
    return dispatch(actions.unRegisterToStream(stream, asset_id));
};

export const registerToFleetStream = (stream, on_event) => (dispatch) => {
    dispatch(
        sendMessage({
            type: 'REGISTER_TO_FLEET_STREAM',
            payload: {
                stream,
            },
        }),
    );
    return dispatch(actions.registerToFleetStream(stream, on_event));
};

export const unRegisterToFleetStream = (stream) => (dispatch) => {
    dispatch(
        sendMessage({
            type: 'UNREGISTER_TO_FLEET_STREAM',
            payload: {
                stream,
            },
        }),
    );
    return dispatch(actions.unRegisterToFleetStream(stream));
};

const initSocket = (_reconnect_attempts = 0) => (dispatch, getState) => {
    if (!('WebSocket' in window) || !window.WebSocket) {
        dispatch(revertToPolling(_reconnect_attempts));
        return;
    }
    const state = getState().ws || {};
    if (_reconnect_attempts >= WS_CONFIG.maxReconnectAttempts) {
        dispatch(revertToPolling(_reconnect_attempts));
        return;
    }
    const socket = new WebSocket(WS_ROOT);
    // if it's the first time that we connect we dispatch a connect, else
    // a reconnect
    if (_reconnect_attempts) {
        dispatch(reconnectWS());
    } else {
        dispatch(connectWS());
    }

    let timeout = setTimeout(() => {
        // the connection has timeout (we clear the timeout otherwise)
        dispatch(incAttemptWS());
        socket.close();
    }, WS_CONFIG.timeoutInterval);

    socket.onopen = () => {
        // first we remove the timeout;
        clearTimeout(timeout);
        // then we set ourselve as opened
        dispatch(openWS(socket));
    };
    socket.onclose = () => {
        // first we remove the timeout;
        clearTimeout(timeout);
        // we dispatch the close if it's the first time
        if (!state.reconnect_attempts) {
            dispatch(closeWS());
        }
        timeout = setTimeout(() => {
            dispatch(incAttemptWS());
            dispatch(initSocket(_reconnect_attempts + 1));
        }, state.reconnect_interval);
    };
    socket.onerror = () => {
        dispatch(errorWS(true));
    };
    socket.onmessage = (evt) => {
        const data = JSON.parse(evt.data);
        dispatch(handleWSMessage(data));
    };
};

export default {
    connectWS,
    reconnectWS,
    openWS,
    messageWS,
    errorWS,
    closeWS,
    initSocket,
    registerToStream,
    unRegisterToStream,
    registerToFleetStream,
    unRegisterToFleetStream,
};
