/**
 * Created by lpostula on 11/05/17.
 */
import _ from 'underscore';
import moment from 'moment-timezone';
import {
    createSelector,
} from 'reselect';
import {
    getMaintenanceSettings,
} from '../maintenance_settings/selectors';
import {
    getCompaniesSettings,
} from '../companies_settings/selectors';
import {
    getUser,
    userHasPerm,
} from '../users/selectors';
import {
    getActionMode as filteringActionMode,
    getFilteredAssetList as filteringFilteredAssetList,
    getSortedFilteredAssetList as filteringSortedFilteredAssetList,
} from '../filtering/selectors';
import {
    compute_alert_count,
    compute_event_count,
    compute_intervention_count,
} from '../filtering/utils';

const EMPTY_ARRAY = [];

/* return the root of the asset state
{
    assets_info:{ id:{} ... }
    assets_operationals:{ id:{} ... }
    ...
}
*/

export const getAllAssets = (state) => state.assets;
export const getAssetInfos = (state, asset_id) => state.assets.assets_infos[asset_id];
const getAssetOperationals = (state, asset_id) => state.assets.assets_operationals[asset_id];
const getAssetLabels = (state, asset_id) => state.assets.assets_labels[asset_id];
const getAssetLiveData = (state, asset_id) => state.assets.assets_live_data[asset_id];
const getAssetAlerts = createSelector(
    [(state, asset_id) => state.assets_alerts[asset_id]],
    (a) => a,
);

export const companyHasPerm = (state, asset) => (permission) => {
    const user = state.users.me;
    if (!user) return false;
    const user_company = user.company;
    if (!user_company) return false;
    if (user_company === asset.info.admin_id) {
        // admin company so every perm
        return true;
    }
    const delegation_id = asset.info.delegation;
    const delegation = state.delegations[delegation_id];
    if (!delegation) return false;
    if (!delegation.permissions) return false;
    return delegation.permissions.includes(permission);
};

export const getAssets = createSelector(
    [getAllAssets],
    (assets) => {
        const out = {};
        for (const id in assets.assets_infos) {
            const asset_infos = assets.assets_infos[id];
            if (asset_infos && asset_infos.has_access_now) {
                for (const cat in assets) {
                    if (assets.hasOwnProperty(cat)) {
                        if (!out[cat]) {
                            out[cat] = {};
                        }
                        if (assets[cat] && assets[cat][id]) {
                            out[cat][id] = assets[cat][id];
                        }
                    }
                }
            }
        }
        return out;
    },
);

export const getAssetsInfos = createSelector(
    [getAssets],
    (assets) => assets.assets_infos,
);

export const getAssetsInfosArray = createSelector(
    [getAssets],
    (assets) => {
        const infos = assets.assets_infos;
        const out = [];
        for (const id in infos) {
            const info = infos[id];
            out.push(info);
        }
        return out;
    },
);

export const getAssetsInfosWithPermissions = (permissions) => (state) => createSelector(
    [getAssetsInfos],
    (assets) => {
        const user_perm_check = userHasPerm(state);
        const out = {};
        for (const asset_id in assets) {
            if (permissions.every((p) => user_perm_check(p, asset_id))) {
                out[asset_id] = assets[asset_id];
            }
        }
        return out;
    },
)(state);

export const getHistoricAssetsInfos = createSelector(
    [getAllAssets],
    (assets) => assets.assets_infos,
);

export const getAssetsLabels = createSelector(
    [getAssets],
    (assets) => assets.assets_labels,
);

export const getAssetsOperationals = createSelector(
    [getAssets],
    (assets) => assets.assets_operationals,
);

export const getAssetsLiveData = createSelector(
    [getAllAssets],
    (assets) => assets.assets_live_data,
);

export const getAssetsAlerts = createSelector(
    [getAllAssets],
    (assets) => assets.assets_alerts,
);

export const getAssetsIdByDeviceId = createSelector(
    [getAssets],
    (assets) => {
        const out = {};
        for (const id in assets.assets_infos) {
            const asset = assets.assets_infos[id];
            out[asset.assign_serial] = id;
        }
        return out;
    },
);

export const getAlertCount = createSelector(
    [getAssets, filteringActionMode],
    (assets, mode) => (
        _.mapObject(assets.assets_operationals, (a) => compute_alert_count(a, mode))
    ),
);

export const getEventCount = createSelector(
    [getAssets, filteringActionMode],
    (assets, mode) => (
        _.mapObject(assets.assets_operationals, (a) => compute_event_count(a, mode))
    ),
);

export const getInterventionCount = createSelector(
    [getAssets, filteringActionMode],
    (assets, mode) => (
        _.mapObject(assets.assets_operationals, (a) => compute_intervention_count(a, mode))
    ),
);

export const getFilteredAssetList = createSelector(
    [
        filteringFilteredAssetList,
        getAssetInfos,
        getAssetLiveData,
        getAssetOperationals,
        getAssetLabels,
    ],
    (
        asset_list,
        infos,
        live_datas,
        operationals,
        labels,
    ) => asset_list.map(
        (asset_id) => ({
            info: infos[asset_id],
            live_data: live_datas[asset_id],
            operational: operationals[asset_id],
            labels: labels[asset_id],
        }),
    ),
);

export const getSortedFilteredAssetList = createSelector(
    [
        filteringSortedFilteredAssetList,
        getAssetsInfos,
        getAssetsOperationals,
        getAssetsLabels,
        getAssetsLiveData,
    ],
    (
        asset_list,
        infos,
        operationals,
        labels,
        live_datas,
    ) => asset_list.map((asset_id) => ({
        info: infos[asset_id],
        operational: operationals[asset_id],
        labels: labels[asset_id],
        live_data: live_datas[asset_id],
    })),
);

export const getSortedFilteredAssetsInfos = createSelector(
    [
        getAssetsInfos,
        filteringSortedFilteredAssetList,
    ],
    (assets, assets_id) => _.pick(assets, assets_id),
);

export const getSortedFilteredAssetsInfosList = createSelector(
    [getSortedFilteredAssetsInfos],
    (assets) => _.values(assets),
);

export const getSortedFilteredAssetsOperationals = createSelector(
    [
        getAssetsOperationals,
        filteringSortedFilteredAssetList,
    ],
    (assets, assets_id) => _.pick(assets, assets_id),
);

export const getSortedFilteredAssetsOperationalsList = createSelector(
    [getSortedFilteredAssetsOperationals],
    (assets) => _.values(assets),
);

export const getSortedFilteredAssetsLabels = createSelector(
    [
        getAssetsLabels,
        filteringSortedFilteredAssetList,
    ],
    (assets, assets_id) => _.pick(assets, assets_id),
);

export const getSortedFilteredAssetsLabelsList = createSelector(
    [getSortedFilteredAssetsLabels],
    (assets) => _.values(assets),
);

export const getAssetsMerged = createSelector(
    [
        getSortedFilteredAssetsInfos,
        getSortedFilteredAssetsOperationals,
        getSortedFilteredAssetsLabels,
        getAssetsLiveData,
    ],
    (infos, operationals, labels, live_data) => {
        const out = {};
        for (const asset_id in infos) {
            out[asset_id] = {

                ...infos[asset_id],
                ...operationals[asset_id],
                ...labels[asset_id],
                ...live_data[asset_id],
            };
        }
        return out;
    },
);

export const getAssetsMergedList = createSelector(
    [getAssetsMerged],
    (assets) => _.values(assets),
);

// this is for the live data page
// asset info and live data are flattened so that it can be easily sorted on a key
export const getAssetsLiveDataList = (state) => createSelector(
    [
        getAssetsInfos,
        getAssetsLiveData,
    ],
    (infos, live_datas) => {
        const user_perm_check = userHasPerm(state);
        const out = [];

        for (const asset_id in infos) {
            // make sure user can see the asset realtime data
            if (!user_perm_check('page_live_monitoring', asset_id)) {
                continue;
            }
            const item = {

                ...infos[asset_id],
            };

            const live_data = live_datas[asset_id];

            let last_update = '1970-01-01';

            if (live_data) {
                // flatten live_data :s
                for (const i in live_data) {
                    if (Array.isArray(live_data[i])) {
                        const [value, date] = live_data[i];
                        item[i] = value;
                        item[`${i}_date`] = moment(date);
                        if (date > last_update) {
                            last_update = date;
                        }
                        // legacy
                    } else if (i === 'sillon') {
                        item.sillon_num = live_data[i].sillon_num;
                    } else {
                        item[i] = live_data[i];
                    }
                }
            }
            item.last_update = last_update;

            out.push(item);
        }
        return out;
    },
)(state);

export const makeAllAssetData = () => createSelector(
    [getAssetInfos, getAssetOperationals, getAssetLabels, getAssetLiveData],
    (infos, operationals, labels, live_data) => ({
        info: infos || {},
        operational: operationals || {},
        labels: labels || {},
        live_data: live_data || {},
    }),
);

export const makeMaintenanceSettingsForAsset = () => createSelector(
    [getAssetInfos, getMaintenanceSettings],
    (asset, maintenance_settings) => (asset
        ? maintenance_settings[asset.ecm_id] : {}),
);

export const makePublicFieldsForAsset = () => createSelector(
    [getAssetInfos, getCompaniesSettings],
    (asset, all_settings) => {
        if (!all_settings) return EMPTY_ARRAY;
        // Public fields are always those of the ecm
        const company_settings = all_settings[asset.ecm_id];
        if (!company_settings) return EMPTY_ARRAY;
        const fields_settings = company_settings.fields;
        if (!fields_settings) return EMPTY_ARRAY;
        return fields_settings.public || EMPTY_ARRAY;
    },
);

export const makePublicFieldsForAssetWithContentType = (content_type) => createSelector(
    [getAssetInfos, getCompaniesSettings],
    (asset, all_settings) => {
        if (!all_settings) return EMPTY_ARRAY;
        // Public fields are always those of the ecm
        const company_settings = all_settings[asset.ecm_id];
        if (!company_settings) return EMPTY_ARRAY;
        const fields_settings = company_settings.fields;
        if (!fields_settings) return EMPTY_ARRAY;
        if (!fields_settings.public) return EMPTY_ARRAY;
        const filtered = _.filter(
            fields_settings.public,
            (elm) => elm.content_type === content_type,
        );
        return filtered || EMPTY_ARRAY;
    },
);

// eslint-disable-next-line max-len
export const makePublicFieldsForAssetWithContentTypeAndOptions = (content_type, content_options) => createSelector(
    [getAssetInfos, getCompaniesSettings],
    (asset, all_settings) => {
        if (!all_settings) return EMPTY_ARRAY;
        // Public fields are always those of the ecm
        const company_settings = all_settings[asset.ecm_id];
        if (!company_settings) return EMPTY_ARRAY;
        const fields_settings = company_settings.fields;
        if (!fields_settings) return EMPTY_ARRAY;
        if (!fields_settings.public) return EMPTY_ARRAY;
        let filtered = _.filter(
            fields_settings.public,
            (elm) => elm.content_type === content_type,
        );
        if (content_options && filtered) {
            filtered = _.filter(
                filtered,
                (elm) => {
                    if (!elm.content_options) return null;
                    let toCheck = elm;
                    if (toCheck && content_options.includes('corrective') && content_options.includes('preventive')) {
                        if (!(toCheck.content_options.includes('corrective') || toCheck.content_options.includes('preventive'))) toCheck = null;
                    } else {
                        if (toCheck && content_options.includes('corrective')) {
                            if (!toCheck.content_options.includes('corrective')) toCheck = null;
                        }
                        if (toCheck && content_options.includes('preventive')) {
                            if (!toCheck.content_options.includes('preventive')) toCheck = null;
                        }
                    }
                    if (toCheck && content_options.includes('open') && content_options.includes('close')) {
                        if (!(toCheck.content_options.includes('open') || toCheck.content_options.includes('close'))) toCheck = null;
                    } else {
                        if (toCheck && content_options.includes('open')) {
                            if (!toCheck.content_options.includes('open')) toCheck = null;
                        }
                        if (toCheck && content_options.includes('close')) {
                            if (!toCheck.content_options.includes('close')) toCheck = null;
                        }
                    }
                    if (toCheck && content_type === 'measurement') {
                        for (const content_option of content_options) {
                            if (elm.content_options.includes(content_option)) {
                                return elm;
                            }
                        }
                        toCheck = null;
                    }
                    return toCheck;
                },
            );
        }
        return filtered || EMPTY_ARRAY;
    },
);

export const makePrivateFieldsForAsset = () => createSelector(
    [getAssetInfos, getCompaniesSettings, getUser],
    (asset, all_settings, current_user) => {
        if (!all_settings) return EMPTY_ARRAY;
        // Private fields are those of the connected user
        const company_settings = all_settings[current_user.company];
        if (!company_settings) return EMPTY_ARRAY;
        const fields_settings = company_settings.fields;
        if (!fields_settings) return EMPTY_ARRAY;
        return fields_settings.private || EMPTY_ARRAY;
    },
);

export const getSortedFilteredAssetListWithPermissions = (permissions) => (state) => createSelector(
    [getSortedFilteredAssetList],
    (assets) => {
        const perm_len = permissions.length;
        const user_perm_check = userHasPerm(state);
        return assets.filter((a) => {
            let i = 0;
            while (i < perm_len) {
                const permission = permissions[i];
                if (!a.info || !user_perm_check(permission, a.info.id)) return false;
                i += 1;
            }
            return true;
        });
    },
)(state);

const sort_options = function (a, b) {
    const sa = a.label.toLowerCase();
    const sb = b.label.toLowerCase();
    return -(sa < sb) || +(sa !== sb);
};

export const getUnfilteredAssetOptions = createSelector(
    [getAssetsInfos],
    (assets) => {
        const a = [];
        for (const asset_id in assets) {
            const asset = assets[asset_id];
            a.push({
                value: asset.id,
                label: asset.name.trim(),
            });
        }
        a.sort(sort_options);
        return a;
    },
);

export const getHistoricUnfilteredAssetOptions = (state) => createSelector(
    [getHistoricAssetsInfos],
    (assets) => {
        const a = [];
        const user_perm_check = userHasPerm(state);
        for (const asset_id in assets) {
            const asset = assets[asset_id];
            if (user_perm_check('view_gps_history_data', asset_id)) {
                a.push({
                    value: asset.id,
                    label: asset.name.trim(),
                });
            }
        }
        a.sort(sort_options);
        return a;
    },
)(state);

export const getAssetOptions = createSelector(
    [getSortedFilteredAssetsInfos],
    (assets) => {
        const a = [];
        for (const asset_id in assets) {
            const asset = assets[asset_id];
            a.push({
                value: asset.id,
                label: asset.name.trim(),
            });
        }
        a.sort(sort_options);
        return a;
    },
);

export default {
    getAssets,
    getAssetsIdByDeviceId,
    getAssetsInfos,
    getAssetsInfosArray,
    getAssetsInfosWithPermissions,
    getAssetInfos,
    getAssetOperationals,
    getAssetsLabels,
    getAssetsOperationals,
    getAssetLiveData,
    getAssetsLiveData,
    getAssetAlerts,
    getAssetsAlerts,
    getFilteredAssetList,
    getSortedFilteredAssetList,
    getAlertCount,
    getEventCount,
    getInterventionCount,
    getSortedFilteredAssetsInfos,
    getSortedFilteredAssetsInfosList,
    getSortedFilteredAssetsOperationals,
    getSortedFilteredAssetsOperationalsList,
    getSortedFilteredAssetsLabels,
    getSortedFilteredAssetsLabelsList,
    getAssetsMerged,
    getAssetsMergedList,
    getAssetsLiveDataList,
    getUnfilteredAssetOptions,
    getHistoricUnfilteredAssetOptions,
    getAssetOptions,
    makeAllAssetData,
    makePublicFieldsForAsset,
    makePublicFieldsForAssetWithContentType,
    makePublicFieldsForAssetWithContentTypeAndOptions,
    makePrivateFieldsForAsset,
    makeMaintenanceSettingsForAsset,
    companyHasPerm,
    getSortedFilteredAssetListWithPermissions,
};
