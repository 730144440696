/**
 * Created by lpostula on 12/05/17.
 */
import i18n from 'i18next';
import { createSelector } from 'reselect';

export const getInterventions = (state) => state.interventions;
export const getInterventionById = (state, intervention_id) => state.interventions[intervention_id];

const getInterventionsForAsset = (state, asset_id) => _.filter(
    state.interventions, ((a) => a.asset === asset_id),
);
const getInterventionsForEvent = (state, event_id) => _.filter(
    state.interventions,
    ((i) => {
        if (!i.hasOwnProperty('interventioneventaction')) return {};
        return !!_.find(i.interventioneventaction, (iea) => iea.event === event_id);
    }),
);

export const makeInterventionsForAsset = () => createSelector(
    [getInterventionsForAsset],
    (items) => items,
);

export const makeInterventionsForEvent = () => createSelector(
    [getInterventionsForEvent],
    (items) => items,
);

export const makeInterventionsListForAsset = () => {
    const items_for_asset = makeInterventionsForAsset();
    return createSelector(
        [items_for_asset],
        (items) => _.values(items),
    );
};

export const makeInterventionsListForEvent = () => {
    const items_for_event = makeInterventionsForEvent();
    return createSelector(
        [items_for_event],
        (items) => _.values(items),
    );
};

export const makeInterventionsListForTimeline = () => {
    const items_for_timeline = makeInterventionsListForAsset();

    return createSelector(
        [items_for_timeline],
        (items) => {
            let interventions = [];

            _.map(items, (item) => {
                if (item.state === 'planned') {
                    interventions = interventions.concat({
                        from: item.date_start_planned,
                        fromLabel: i18n.t('Planned start'),
                        to: item.date_done_planned,
                        toLabel: i18n.t('Planned end'),
                        type: 'INTERVAL',
                        value: 'intervention_planned',
                        id: `${item.id}_intervention_planned`,
                    });
                } else if (item.state !== 'cancelled') {
                    let previous = 'date_start_actual';
                    let dates = [];

                    if (item.has_handover_process) {
                        previous = 'date_transfer_in';
                        dates = [
                            'date_wait_in',
                            'date_start_actual',
                        ];
                    }

                    interventions = interventions.concat({
                        at: item[previous],
                        type: 'POINT',
                        value: `intervention_${previous}`,
                        id: `${item.id}_${previous}_point`,
                    });

                    // Function called internally to reduce verbosity
                    const new_section = (
                        interventions_to_section,
                        item_to_section,
                        previous_to_section,
                        date,
                    ) => (
                        interventions_to_section.concat([{
                            from: item_to_section[previous],
                            to: item_to_section[date],
                            type: 'INTERVAL',
                            value: `intervention_started_${item_to_section.intervention_type}`,
                            id: `${item_to_section.id}_started_${item_to_section.intervention_type}_${date}`,
                        }, {
                            at: item_to_section[date],
                            type: 'POINT',
                            value: `intervention_${date}`,
                            id: `${item_to_section.id}_${date}_point`,
                        }])
                    );

                    // First iteration through "not done yet" steps.
                    for (const date of dates) {
                        if (item[date]) {
                            interventions = new_section(interventions, item, previous, date);
                            previous = date;
                        }
                    }

                    let done = false;

                    // Second iteration to check if the interevention is done.
                    for (const date of ['date_done_actual', 'date_transfer_out', 'date_transfer_out_finished']) {
                        if (item[date]) {
                            done = true;
                            interventions = new_section(interventions, item, previous, date);
                            previous = date;
                        }
                    }

                    if (!done) {
                        interventions = interventions.concat([{
                            from: item[previous],
                            to: item.date_done_planned,
                            toLabel: i18n.t('Planned end'),
                            type: 'INTERVAL',
                            value: `intervention_started_${item.intervention_type}`,
                            id: `${item.id}_started_${item.intervention_type}_date_done_planned`,
                        }, {
                            at: item.date_done_planned,
                            type: 'POINT',
                            value: 'intervention_date_done_planned',
                            id: `${item.id}_date_done_planned_point`,
                        }]);
                    }
                }
            });

            return interventions;
        },
    );
};

export default {
    getInterventions,
    getInterventionById,
    makeInterventionsListForAsset,
    makeInterventionsListForEvent,
    makeInterventionsListForTimeline,
};
