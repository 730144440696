import React, { useState, useEffect } from 'react';
import './TabbedFrame.scss';

const TabbedFrame = (props) => {
    const [_tabSelected, setTabSelected] = useState(0);
    useEffect(() => {
        if (props.forcedTab) {
            setTabSelected(props.forcedTab);
        }
    }, [props.forcedTab]);
    if (props.hide) {
        return props.children;
    }
    const childrenSelected = React.Children.map(props.children, (elm, index) => {
        if (index === _tabSelected) return elm;
        return null;
    });
    return (
        <div className="tabbed-frame-holder">
            <div className={`tabbed-frame-header${props.disabled ? ' disabled' : ''}`}>
                {
                    props.titles.map((elm, index) => (
                        <div key={elm}>
                            <div
                                className={`tabbed-frame-tab${_tabSelected === index ? ' selected' : ''}`}
                                key={elm}
                                onClick={() => {
                                    if (props.disabled) return;
                                    if (props.onClick) props.onClick(index);
                                    setTabSelected(index);
                                }}
                            >
                                <div>
                                    {elm}
                                    <span className="glyphicon glyphicon-chevron-down" />
                                </div>
                            </div>
                            <span className="tab-separator" />
                        </div>
                    ))
                }
            </div>
            <div className="tabbed-frame-body">
                { childrenSelected }
            </div>
        </div>
    );
};

export default TabbedFrame;
