import React from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import {
    Map, Marker, Popup, TileLayer, ZoomControl,
} from 'react-leaflet';
import moment from 'moment-timezone';
import railfleet from 'railfleet';
import L from 'leaflet';
import { monitoringAlertsSelectors } from 'railfleet_state/ducks/monitoring_alerts';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { dataConfigsSelectors } from 'railfleet_state/ducks/data_configs';

// stupid hack so that leaflet's images work after going through webpack
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';

import './alertDetail.scss';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({ iconRetinaUrl, iconUrl, shadowUrl });

const { TelematicField } = railfleet.components.display;

const { PrimaryModal } = railfleet.components.containers;

class AlertMap extends React.PureComponent {
    render() {
        let latitude = null;
        let longitude = null;
        const { snapshot } = this.props.alert;
        if (snapshot) {
            const lat_field = snapshot['position.latitude'];
            const lon_field = snapshot['position.longitude'];
            if (lat_field && lon_field) {
                latitude = lat_field[0] / 1e6;
                longitude = lon_field[0] / 1e6;
            }
        }

        // const { latitude, longitude } = this.props.alert.content;

        let marker;
        let pos = [52.089451, 5.109987];

        if (latitude && longitude) {
            pos = [latitude, longitude];
            marker = (
                <Marker position={pos}>
                    <Popup>
                        <ul>
                            <li>{`${i18n.t('Latitude')}: ${latitude.toFixed(5)}`}</li>
                            <li>{`${i18n.t('Longitude')}: ${longitude.toFixed(5)}`}</li>
                        </ul>
                    </Popup>
                </Marker>
            );
        }

        const viewport = {
            zoom: 13,
            center: pos,
        };
        const mapboxAccessToken = 'pk.eyJ1IjoicmFpbG5vdmEiLCJhIjoiY2tkN2Zta3J1MDVhNTJybXM2cWJ4NWdhYiJ9.s0XtzZA08KHLg2l-UkoOnQ';
        return (
            <div className="alert-detail-map">
                <Map
                    style={{ width: '100%', height: '100%', minHeight: '540px' }}
                    zoomControl={false}
                    viewport={viewport}
                >
                    <ZoomControl position="topright" />
                    <TileLayer
                        attribution="© <a href=&quot;https://apps.mapbox.com/feedback/&quot;>Mapbox</a> © <a href=&quot;http://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a>"
                        url={`https://api.mapbox.com/styles/v1/railnova/ckc4uqsn312kj1ip6s8jjqbd9/tiles/{z}/{x}/{y}?access_token=${mapboxAccessToken}`}
                        tileSize={512}
                        zoomOffset={-1}
                    />
                    {marker}
                </Map>
            </div>
        );
    }
}

const AlertValues = (props) => {
    const {
        alert, rule, closeAlert, error, closing, asset_name,
    } = props;
    const { start_time, end_time } = alert;
    const linkToDataInspector = alert.data_link.inspector;
    const linkToTelematicPage = alert.data_link.telematic;
    return (
        <div className="alert-detail-values">
            <ul>
                <li>
                    <label>{i18n.t('Locomotive')}</label>
                    {' '}
                    {asset_name}
                </li>
                <li>
                    <label>{i18n.t('Start time')}</label>
                    {' '}
                    {moment(start_time).format('DD/MM/YYYY HH:mm:ss')}
                </li>
                <li>
                    <label>{i18n.t('End time')}</label>
                    {
                        alert.end_time ? moment(end_time).format('DD/MM/YYYY HH:mm:ss') : (
                            <button type="button" className="btn btn-danger" disabled={closing} onClick={closeAlert}>
                                {i18n.t('Close this alert')}
                            </button>
                        )
                    }
                </li>
                <li>
                    <label>{i18n.t('URL')}</label>
                    {rule.help_url && (<a target="_blank" rel="noopener noreferrer" href={rule.help_url}>{i18n.t('Knowledge bank')}</a>)}
                </li>
                <li>
                    <label>{i18n.t('Description')}</label>
                    <div dangerouslySetInnerHTML={{ /* eslint-disable-line react/no-danger */__html: rule.description_rendered }} />
                </li>
            </ul>
            <div className="alert-inspect-telematic-data">
                {i18n.t('Inspect data in the')}
                {' '}
                <a href={linkToDataInspector}>
                    {i18n.t('Data Inspector')}
                    {' '}
                </a>
                {i18n.t('or the')}
                {' '}
                <a href={linkToTelematicPage}>{i18n.t('Telematic page')}</a>
            </div>
            {
                error ? (
                    <div className="alert alert-danger">
                        {error}
                    </div>
                ) : null
            }
        </div>
    );
};

class AlertSnapshot extends React.PureComponent {
    render() {
        const live_data = this.props.snapshot;
        const { language } = this.props;
        const cfg = this.props.data_config;
        const { ref_date } = this.props;

        if (!cfg || !live_data) {
            return null;
        }

        const fields = [];
        for (const field_config of cfg.fields) {
            // only show related columns
            if (field_config.show_alert === false) {
                continue;
            }

            const data = live_data[field_config.id];
            if (!data) {
                continue;
            }
            const [value, date] = data;

            if (value === null || value === undefined) {
                continue;
            }
            fields.push(
                <li key={field_config.id}>
                    <label>{field_config.name}</label>
                    <TelematicField
                        config={field_config}
                        value={value}
                        date={date}
                        ref_date={ref_date}
                        language={language}
                    />
                </li>,
            );
        }

        return (
            <div className="alert-detail-values">
                <ul>
                    {fields}
                </ul>
            </div>
        );
    }
}

class _AlertDetail extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            rule: null,
            error: null,
            closing: false,
        };

        this.loadRule = this.loadRule.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
    }

    componentDidMount() {
        this.loadRule(this.props.alert.rule);
    }

    async closeAlert() {
        this.setState({ closing: true });
        const new_state = { closing: false };
        if (window.confirm(i18n.t('Are you sure you want to close this alert?'))) {
            const url = `/api/monitoring_alert/${this.props.alert.id}.json`;
            const res = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    credentials: 'same-origin',
                    'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
                },
                body: JSON.stringify({ end_time: moment().toISOString() }),
            });
            const alert = await res.json();
            if (res.ok) {
                this.props.onChange(alert);
            } else {
                new_state.error = alert.detail;
            }
        }
        this.setState(new_state);
    }

    loadRule(rule_id) {
        const url = `/api/monitoring_rule/${rule_id}.json`;
        $.getJSON(url, (rule) => {
            this.setState({ rule });
        });
    }

    render() {
        const { alert, asset_name } = this.props;
        const { rule } = this.state;
        if (!rule || !alert) return null;

        const cfg = this.props.data_config;
        return (
            <PrimaryModal
                actions={{
                    close: this.props.close,
                }}
                className="alert-detail"
                title={alert.name}
            >
                <div className="alert-detail-holder">
                    <AlertValues
                        alert={alert}
                        asset_name={asset_name}
                        rule={rule}
                        closeAlert={this.closeAlert}
                        error={this.state.error}
                        closing={this.state.closing}
                    />
                    <AlertMap alert={alert} />
                    <AlertSnapshot
                        ref_date={alert.start_time}
                        data_config={cfg}
                        snapshot={alert.snapshot}
                        language={window.user_info.language}
                    />
                </div>
            </PrimaryModal>
        );
    }
}

const mapStateToProps = (state, props) => {
    // only connect if there is an alert_id
    if (!props.alert_id) return {};
    const alerts = monitoringAlertsSelectors.getMonitoringAlerts(state);
    let alert;
    for (alert of alerts) {
        if (alert.id === props.alert_id) {
            break;
        }
    }
    if (!alert) return {};
    const asset_id = alert.asset;
    const asset = assetsSelectors.getAssetInfos(state, asset_id);
    const data_config = dataConfigsSelectors.getDataConfig(state, asset.data_config_id);
    return {
        alert,
        asset_name: `${asset.name} [${asset.class_name}]`,
        data_config,
    };
};

export const AlertDetail = connect(mapStateToProps)(_AlertDetail);

export default {};
