import { useState } from 'react';

const useFetch = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [_controller, setController] = useState({});

    const trimmPayload = (payload) => {
        const newPayload = {};
        for (const key of Object.keys(payload)) {
            if (payload[key] !== undefined) {
                newPayload[key] = payload[key];
            }
        }
        return newPayload;
    };

    const fetchAll = async (url, _payload = {}, headers = null) => {
        const payload = trimmPayload(_payload);
        const queryString = (Object.keys(payload)
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`)
            .join('&')) || null;
        setIsLoading(true);
        if (_controller[url]) {
            _controller[url].abort();
        }
        const c = new AbortController();
        setController((prevState) => {
            const newState = { ...prevState };
            newState[url] = c;
            return newState;
        });
        const signal = c.signal;
        const _settings = {
            signal,
        };
        if (!_.isEmpty(headers)) _settings.headers = headers;
        try {
            const qString = queryString ? `?${queryString}` : '';
            const response = await fetch(`${url}${qString}`, _settings);
            const result = await response.json();
            if (response.ok) {
                setData(result);
                setHasError(null);
                return result;
            }
            setHasError(result);
        } catch (err) {
            setHasError(err.message);
        } finally {
            setIsLoading(false);
        }

        return null;
    };

    const parseLinkHeader = (linkHeader) => {
        if (!linkHeader) {
            return {
                links: {},
                current_page: 1,
                total_page: 1,
            };
        }
        const prev_url_raw = linkHeader.match(/<([^>]+)>;rel="prev"/);
        const prev_url = prev_url_raw && prev_url_raw[1];

        const next_url_raw = linkHeader.match(/<([^>]+)>;rel="next"/);
        const next_url = next_url_raw && next_url_raw[1];

        const last_url_raw = linkHeader.match(/<([^>]+)>;rel="last"/);
        const last_url = last_url_raw && last_url_raw[1];

        const first_url_raw = linkHeader.match(/<([^>]+)>;rel="first"/);
        const first_url = first_url_raw && first_url_raw[1];

        const next_page_raw = next_url && next_url.match(/page=([^&]+)/);
        const next_page = next_page_raw && next_page_raw[1] && parseInt(next_page_raw[1], 10);

        const prev_page_raw = prev_url && prev_url.match(/page=([^&]+)/);
        const prev_page = prev_page_raw && prev_page_raw[1] && parseInt(prev_page_raw[1], 10);

        const url_whatever = next_url || prev_url || first_url || last_url;

        const page_size_raw = url_whatever && url_whatever.match(/page_size=([^&]+)/);
        const page_size = page_size_raw && page_size_raw[1] && parseInt(page_size_raw[1], 10);

        const last_page_raw = last_url && last_url.match(/page=([^&]+)/);
        const last_page = last_page_raw && last_page_raw[1] && parseInt(last_page_raw[1], 10);

        const current_page = next_page ? next_page && Math.max(next_page - 1, 1) : prev_page && prev_page + 1;

        const ret = {
            links: {},
            current_page,
            total_page: last_page || current_page,
            page_size,
            navigate: () => null,
        };

        if (first_url) {
            ret.links.first = {
                page: 1,
                page_size,
                rel: 'first',
                url: first_url,
            };
        }

        if (next_url) {
            ret.links.next = {
                page: next_page,
                page_size,
                rel: 'next',
                url: next_url,
            };
        }

        if (prev_url) {
            ret.links.prev = {
                page: prev_page,
                page_size,
                rel: 'prev',
                url: prev_url,
            };
        }

        if (last_url) {
            ret.links.last = {
                page: last_page,
                page_size,
                rel: 'last',
                url: last_url,
            };
        }
        return ret;
    };

    const fetchPaginated = async (url, _payload = {}, headers = null) => {
        const payload = trimmPayload(_payload);
        const queryString = (Object.keys(payload)
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`)
            .join('&')) || null;
        setIsLoading(true);
        if (_controller[url]) {
            _controller[url].abort();
        }
        const c = new AbortController();
        setController((prevState) => {
            const newState = { ...prevState };
            newState[url] = c;
            return newState;
        });
        const signal = c.signal;
        const _settings = {
            signal,
        };
        if (!_.isEmpty(headers)) _settings.headers = headers;
        try {
            const qString = queryString ? `?${queryString}` : '';
            const response = await fetch(`${url}${qString}`, _settings);
            const linkHeader = response.headers.get('link');
            const pagination = parseLinkHeader(linkHeader);
            const result = await response.json();
            if (response.ok) {
                setData({
                    pagination,
                    result,
                });
                setHasError(null);
                return {
                    pagination,
                    result,
                };
            }
            setHasError(result);
        } catch (err) {
            setHasError(err.message);
        } finally {
            setIsLoading(false);
        }

        return null;
    };

    return {
        fetchAll, fetchPaginated, hasError, isLoading, data,
    };
};

export default useFetch;
