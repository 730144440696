import { useState, useEffect } from 'react';
import memoize from 'memoize-one';

const fn_dict_to_array = (dict, key, flat, sort_key) => {
    const ret = [];
    for (const key_elm in dict) {
        const elm = dict[key_elm];
        const toPush = {};
        toPush[key] = key_elm;
        toPush.data = elm;
        if (flat) ret.push(elm);
        else ret.push(toPush);
    }
    if (sort_key) {
        ret.sort((a, b) => a[sort_key] - b[sort_key]);
    }
    return ret;
};

const useDictToArray = (dict, key = 'id', flat = false, sort_key = null) => {
    const dict_to_array = memoize(fn_dict_to_array);
    const [_array, setArray] = useState([]);

    useEffect(() => {
        if (dict && Object.keys(dict).length > 0) {
            setArray(dict_to_array(dict, key, flat, sort_key));
        } else {
            setArray([]);
        }
    }, [dict]);
    return _array;
};

export default useDictToArray;
