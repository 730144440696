/**
 * Created by lpostula on 12/05/17.
 */

import {
    createSelector,
} from 'reselect';

const EMPTY_ARRAY = [];

const getFiltering = (state) => state.filtering;

export const getVisibility = createSelector(
    [getFiltering],
    (filtering) => filtering.visible,
);

export const getSortBy = createSelector(
    [getFiltering],
    (filtering) => filtering.sort_by || '',
);

export const getActionMode = createSelector(
    [getFiltering],
    (filtering) => filtering.mode || '',
);

const getFilters = createSelector(
    [getFiltering],
    (filtering) => filtering.filters,
);

export const getSelectedAssets = createSelector(
    [getFilters],
    (filtering) => filtering.assets.query || EMPTY_ARRAY,
);

export const getSelectedAssetClasses = createSelector(
    [getFilters],
    (filtering) => filtering.asset_classes?.query || EMPTY_ARRAY,
);

export const getSelectedOthers = createSelector(
    [getFilters],
    (filtering) => filtering.others?.query || EMPTY_ARRAY,
);

export const getSelectedAssetClassesNegId = createSelector(
    [getSelectedAssetClasses],
    (filtering) => filtering.map((c) => -1 * c),
);
export const getSelectedOthersNegId = createSelector(
    [getSelectedOthers],
    (filtering) => filtering.map((c) => -1 * c),
);

export const getSelectedLabels = createSelector(
    [getFilters],
    (filtering) => filtering.labels?.query || EMPTY_ARRAY,
);

export const getSelectedCountries = createSelector(
    [getFilters],
    (filtering) => filtering.countries?.query || EMPTY_ARRAY,
);

export const getSelectedStatus = createSelector(
    [getFilters],
    (filtering) => filtering.statuses?.query || EMPTY_ARRAY,
);

export const getSelectedOperations = createSelector(
    [getFilters],
    (filtering) => filtering.operations?.query || EMPTY_ARRAY,
);

export const getSelectedTechStatus = createSelector(
    [getFilters],
    (filtering) => filtering.tech_statuses?.query || EMPTY_ARRAY,
);

export const getFilteredAssetList = createSelector(
    [getFiltering],
    (filtering) => filtering.unordered_result,
);

export const getSortedFilteredAssetList = createSelector(
    [getFiltering],
    (filtering) => filtering.ordered_result,
);

export const isFilterActive = createSelector(
    [
        getActionMode,
        getFilters,
    ],
    (
        action_mode,
        filters,
    ) => action_mode !== 'all' || _.any(filters, (f) => f.active),
);

export default {
    isFilterActive,
    getVisibility,
    getActionMode,
    getSortBy,
    getSelectedAssets,
    getSelectedAssetClasses,
    getSelectedAssetClassesNegId,
    getSelectedOthersNegId,
    getSelectedLabels,
    getSelectedCountries,
    getSelectedStatus,
    getSelectedTechStatus,
    getSelectedOperations,
    getFilteredAssetList,
    getSortedFilteredAssetList,
};
