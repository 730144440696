/**
 * Created by lpostula on 15/05/17.
 */
const FETCH_LIST_REQUEST = 'locations/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'locations/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'locations/FETCH_LIST_FAILED';

const FETCH_DETAILS_REQUEST = 'locations/FETCH_DETAILS_REQUEST';
const FETCH_DETAILS_COMPLETED = 'locations/FETCH_DETAILS_COMPLETED';
const FETCH_DETAILS_FAILED = 'locations/FETCH_DETAILS_FAILED';

const SEARCH_REQUEST = 'locations/SEARCH_REQUEST';
const SEARCH_COMPLETED = 'locations/SEARCH_COMPLETED';
const SEARCH_FAILED = 'locations/SEARCH_FAILED';

const SEARCH_FOR_ASSET_BY_DATE_REQUEST = 'locations/SEARCH_FOR_ASSET_BY_DATE_REQUEST';
const SEARCH_FOR_ASSET_BY_DATE_COMPLETED = 'locations/SEARCH_FOR_ASSET_BY_DATE_COMPLETED';
const SEARCH_FOR_ASSET_BY_DATE_FAILED = 'locations/SEARCH_FOR_ASSET_BY_DATE_FAILED';

export default {
    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,
    FETCH_DETAILS_REQUEST,
    FETCH_DETAILS_COMPLETED,
    FETCH_DETAILS_FAILED,
    SEARCH_REQUEST,
    SEARCH_COMPLETED,
    SEARCH_FAILED,
    SEARCH_FOR_ASSET_BY_DATE_REQUEST,
    SEARCH_FOR_ASSET_BY_DATE_COMPLETED,
    SEARCH_FOR_ASSET_BY_DATE_FAILED,
};
