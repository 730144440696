/**
 * Created by lpostula on 11/10/17.
 */
import React from 'react';
import PropTypes from 'prop-types';

import './ButtonCounter.scss';

const ButtonCounter = (props) => (
    <div className={`button-counter-wrapper${props.onClick ? ' clickable' : ''}`} onClick={props.onClick} id={props.id}>
        <div className="button-counter">
            <span className={`label-text label-text-${props.value < 1 ? 'default' : 'danger'}`}>
                {props.value}
            </span>
            <div className="button-counter-label">
                {props.label}
            </div>
        </div>
    </div>
);

ButtonCounter.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    onClick: PropTypes.func,
};

export default ButtonCounter;
