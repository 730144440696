import { useState, useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

// Given a size and a list of breakpoints in the format:
// [
//     { classname: 'small', min: 0, max: 100 },
//     { classname: 'medium', min: 100, max: 700 },
//     { classname: 'large', min: 700, max: 1200 },
//     { classname: 'large', min: 1200 },
// ]
// returns the class name of the interval including the size parameter
const findBreakPoint = (breakpoints, size) => {
    if (size) {
        const breakpoint = _.find(
            breakpoints,
            ({ min, max }) => ((!min || size >= min) && (!max || size < max)),
        );
        if (breakpoint) return breakpoint.classname;
    }
    return null;
};

// This hook tracks the real width and height of a DOM element
const useResponsiveSize = (elRef) => {
    const [_width, setWidth] = useState(null);
    const [_height, setHeight] = useState(null);

    // To track width and height, we use the ResizeObserver api:
    // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
    const observer = useRef(
        new ResizeObserver((entries) => {
            // Only care about the first element, we expect one element ot be watched
            const { width, height } = entries[0].contentRect;

            setWidth(width);
            setHeight(height);
        }),
    );

    useEffect(() => {
        if (elRef.current) {
            observer.current.observe(elRef.current);
        }

        return () => {
            // should unobserve element when it changes
            if (elRef.current) observer.current.unobserve(elRef.current);
        };
    }, [elRef.current, observer]);

    return { current_width: _width, current_height: _height };
};

export { findBreakPoint, useResponsiveSize };
