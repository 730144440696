/**
 * Created by lpostula on 14/05/19.
 */
import types from './types';
import { createReducer } from '../../utils';

const initialState = {
    data: {},
    ordering: {},
};

const listReducer = createReducer(initialState)({
    [types.FETCH_LIST_COMPLETED]: (state, action) => ('results' in action.payload ? action.payload.results : action.payload),
    [types.FETCH_DETAIL_COMPLETED]: (state, action) => {
        const index = state.findIndex((a) => a.id === action.payload.id);
        if (index === -1) return state;
        return [
            ...state.slice(0, index),
            action.payload,
            ...state.slice(index + 1),
        ];
    },
});

export default listReducer;
