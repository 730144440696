import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import moment from 'moment-timezone';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { usersSelectors } from 'railfleet_state/ducks/users';
import { restrictionsOperations, restrictionsSelectors } from 'railfleet_state/ducks/restrictions';
import components from 'components';

import './RestrictionForm.scss';
import FormCheckbox from 'components/forms/RailForms/formCheckbox/FormCheckbox';
import HomologationForm from 'compounds/forms/homologationForm/HomologationForm';
import HomologationContext from 'compounds/pages/assetView/HomologationContext/homologationContext';
import scrollToTop from '../../../utils/scrollToTop';

const {
    FormManager,
    FormTextInput,
    CutForm,
    FormDateTime,
    InputsWrapper,
    FormDropdown,
} = components.forms;

const { THEMES } = components.selectors;
const { MultiDeviceLayout } = components.app;
const { usePerm } = components.hooks;

const RestrictionFormComponent = (props) => {
    const context = useContext(HomologationContext);
    const refreshData = context?.methods?.refreshData;
    const can_aggravate = context?.methods?.can_aggravate(props.asset_id);
    const [_loading, setLoading] = useState(true);
    const [_locked, setLocked] = useState(false);
    const [_errorApi, setErrorApi] = useState(null);
    const [_impact, setImpact] = useState(false);
    const [_homologations, setHomologations] = useState(null);
    const hasPerm = usePerm(props.asset_id || null);
    if (!props.restriction_id && !hasPerm('create_restriction')) return (<div>YOU DO NOT HAVE THE PERM: create_restriction</div>);
    if (props.restriction_id && !hasPerm('edit_restriction')) return (<div>YOU DO NOT HAVE THE PERM: edit_restriction</div>);

    useEffect(() => {
        if (props.asset_id && props.restriction_id) {
            props.fetchRestrictionsForAsset(props.asset_id).then(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, []);

    const format_data = (data) => {
        const formated_data = { ...data };
        let impacts = [];
        let impacts_other = [];
        if ((_impact || props.restriction?.impacts) && _homologations?.countries) {
            impacts = Object.keys(_homologations.countries).map((key) => parseInt(key, 10));
        }
        if ((_impact || props.restriction?.impacts_other) && _homologations?.others) {
            impacts_other = Object.keys(_homologations.others).map((key) => parseInt(key, 10));
        }
        if (!_.isEmpty(impacts)) {
            formated_data.impacts = impacts;
        }
        if (!_.isEmpty(impacts_other)) {
            formated_data.impacts_other = impacts_other;
        }
        if (props.restriction_id) {
            formated_data.id = props.restriction_id;
        }
        formated_data.date_started = data.date_started && data.date_started.toISOString();
        formated_data.asset = props.asset_id;
        return formated_data;
    };

    const onSubmit = (data) => {
        const formated_data = format_data(data);
        setLocked(true);
        const method = props.restriction_id ? props.updateRestriction : props.createRestriction;
        method(formated_data).then((d) => {
            if (d.payload && !d.error) {
                setLocked(false);
                setErrorApi(null);
                if (refreshData) refreshData();
                if (props.callback) props.callback(d.payload.id);
            }
            if (d.error) {
                setLocked(false);
                setErrorApi(d.payload.response || `${d.payload.name}: ${d.payload.message}`);
                scrollToTop();
            }
        });
    };

    const onCancel = () => {
        if (props.cancelCallback) props.cancelCallback();
    };
    const defaultValues = {
        restriction_type: props.restriction.restriction_type,
        comment: props.restriction.comment,
        date_started: (
            props.restriction
            && props.restriction.date_started
            && moment(props.restriction.date_started)
        ) || props.date_started || moment(),
    };
    if (props.restriction.impacts) {
        defaultValues.impacts = {
            countries: {},
        };
        for (const impact of props.restriction.impacts) {
            defaultValues.impacts.countries[impact] = 'false';
        }
    }

    if (props.restriction.impacts_other) {
        if (_.isEmpty(defaultValues.impacts)) {
            defaultValues.impacts = {
                others: {},
            };
        } else {
            defaultValues.impacts.others = {};
        }
        for (const impact_other of props.restriction.impacts_other) {
            defaultValues.impacts.others[impact_other] = 'false';
        }
    }

    if (_loading) return null;
    return (
        <div className={`form-main ${props.device} restrictions-form ${props.homologation ? 'withHomologation' : ''}`}>
            <FormManager
                onSubmit={onSubmit}
                textSubmitButton={props.restriction_id ? i18n.t('Update restriction') : i18n.t('Create restriction')}
                disabled={_locked}
                onCancel={onCancel}
                apiErrors={_errorApi}
            >
                <InputsWrapper
                    id="general"
                    title={i18n.t('Restriction informations\n')}
                >
                    <CutForm
                        device={props.device}
                    >
                        {
                            props.restriction_type
                            && Object.keys(props.restriction_type).length > 0
                                && (
                                    <FormDropdown
                                        id="restriction_type"
                                        defaultValue={
                                            defaultValues
                                        && defaultValues.restriction_type
                                        }
                                        label={i18n.t('Restriction type')}
                                        values={props.restriction_type}
                                        id_getter={(elm) => elm.id}
                                        labelizer={(elm) => elm.name}
                                        clearable
                                        theme={THEMES.FORM_LIGHT}
                                        required
                                    />
                                )
                        }
                        <FormDateTime
                            id="date_started"
                            label={i18n.t('Date')}
                            defaultValue={
                                defaultValues
                                && defaultValues.date_started
                                && moment(defaultValues.date_started)
                            }
                            allowEmpty
                            fillFlex
                            required
                        />
                        <FormTextInput
                            id="comment"
                            defaultValue={
                                defaultValues
                                && defaultValues.comment
                            }
                            label={i18n.t('Restriction comment')}
                            theme={THEMES.FORM_LIGHT}
                            large
                            required
                        />
                    </CutForm>
                </InputsWrapper>
                {
                    props.homologation && can_aggravate && (
                        <InputsWrapper
                            id="homologation"
                            title={i18n.t('Approvals')}
                            className="homologation"
                        >
                            <FormCheckbox
                                id="impact"
                                defaultValue={((_impact || !_.isEmpty(defaultValues?.impacts?.countries) || !_.isEmpty(defaultValues?.impacts?.others)) ? 'true' : 'false') || 'false'}
                                label={i18n.t('Does this restriction has an impact on the current aptitudes ?')}
                                values={[
                                    {
                                        id: 'true',
                                        name: i18n.t('Yes'),
                                    },
                                    {
                                        id: 'false',
                                        name: i18n.t('No'),
                                    },
                                ]}
                                trigger="false"
                                labelizer={(item) => item.name}
                                required
                                disabled={!_.isEmpty(defaultValues?.impacts?.countries) || !_.isEmpty(defaultValues?.impacts?.others)}
                                onChange={() => {
                                    setImpact(!_impact);
                                }}
                            />
                            {
                                (_impact || (!_.isEmpty(defaultValues?.impacts?.countries) || !_.isEmpty(defaultValues?.impacts?.others))) && (
                                    <HomologationForm
                                        asset_id={props.asset_id}
                                        titleCountry={i18n.t('Countries (aptitudes that may be impacted)')}
                                        titleOther={i18n.t('Others (aptitudes that may be impacted)')}
                                        standalone
                                        onChange={setHomologations}
                                        defaultValue={
                                            defaultValues?.impacts
                                        }
                                        isDisabled={(arg) => defaultValues?.impacts?.countries[arg.id] === 'false'}
                                        impact
                                        id_getter={(elm) => elm.target}
                                        isReadOnly={() => false}
                                    />
                                )
                            }
                        </InputsWrapper>
                    )
                }
            </FormManager>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const { asset_id } = props;
    const { restriction_id } = props;
    const asset = asset_id && assetsSelectors.getAssetInfos(state, asset_id);
    const getMaintenanceSettings = assetsSelectors.makeMaintenanceSettingsForAsset();
    const can_add = asset && usersSelectors.userHasPerm(state)('create_restriction', asset_id);
    const can_update = asset && usersSelectors.userHasPerm(state)('edit_restriction', asset_id);
    const maintenance_settings = getMaintenanceSettings(state, asset_id);
    const restriction_type = maintenance_settings && maintenance_settings.restriction_type;
    const restriction = restrictionsSelectors.getRestrictionsById(state, restriction_id);
    return {
        can_add,
        can_update,
        maintenance_settings: maintenance_settings || {},
        users: state.users.all,
        asset: asset || {},
        restriction_type,
        restriction: restriction || {},
        user: state.users.me,
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchRestrictionsForAsset: (asset_id) => (
        dispatch(restrictionsOperations.fetchForAsset(asset_id))
    ),
    createRestriction: (restriction) => (
        dispatch(restrictionsOperations.createRestriction(restriction))
    ),
    updateRestriction: (restriction) => (
        dispatch(restrictionsOperations.updateRestriction(restriction))
    ),
});

const RestrictionForm = (props) => (
    <MultiDeviceLayout>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <RestrictionFormComponent
            {...props}
        />
    </MultiDeviceLayout>
);

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionForm);
