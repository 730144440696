/**
 * Created by lpostula on 27/02/18.
 */
import { RSAA } from 'redux-api-middleware';

import types from './types';
import { API_ROOT } from '../../config';

// we should at a moment change this to his own api endpoint
const API_RESOURCE_NAME = 'company';

export const fetchList = (long_polling = false) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/complete.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            {
                type: types.FETCH_LIST_REQUEST,
                meta: { long_polling },
            },
            {
                type: types.FETCH_LIST_COMPLETED,
                meta: { long_polling },
            },
            types.FETCH_LIST_FAILED,
        ],
    },
});

export default {
    fetchList,
};
