import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Portal from '../../app/portal';
import './ConfirmationDialog.scss';
import ExclamationLogo from './exclamation.svg';
import LogoOk from './logo_ok.svg';

const ConfirmationDialogComp = (props) => {
    const dialogRef = useRef(null);
    const handleClickOutside = (event) => {
        if (dialogRef.current
            && dialogRef.current.contains(event.target)
        ) {
            return;
        }
        if (props.onClickOutside) {
            props.onClickOutside();
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleTimeout = () => {
        props.onClickResolve();
    };

    useEffect(() => {
        if (props.timed) setTimeout(handleTimeout, 5000);
    }, []);

    const BlurredBackground = (arg) => (
        <div className="blurred-background">
            <div>
                { arg.children }
            </div>
        </div>
    );

    const CustomTag = props.inModal ? BlurredBackground : Portal;
    return (
        <CustomTag>
            <div className={`confirmation-message-holder ${props.className ? props.className : ''}`} ref={dialogRef}>
                <div className={`confirmation-message-frame ${props.variant}`}>
                    <div className="confirmation-message-logo">
                        {
                            props.variant === 'danger' ? (
                                <img src={ExclamationLogo} alt="exclamation logo" />
                            ) : (
                                <img src={LogoOk} alt="ok logo" />
                            )
                        }
                    </div>
                    <div className={`confirmation-message-title ${props.variant}`}>
                        {(props.confirmation_message_renderer && props.confirmation_message_renderer()) || props.confirmation_message}
                    </div>
                    <div className="confirmation-message-description">
                        {props.description_message}
                    </div>
                    <div className="confirmation-message-action">
                        {
                            props.variant === 'danger' || props.variant === 'info' ? (
                                <>
                                    <button
                                        className="btn btn-default"
                                        onClick={props.onClickReject}
                                    >
                                        {(props.buttons_text && props.buttons_text[0]) || 'Cancel'}
                                    </button>
                                    <button
                                        className="btn btn-default"
                                        id="blue-fill"
                                        onClick={props.onClickResolve}
                                    >
                                        {(props.buttons_text && props.buttons_text[1]) || 'OK'}
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button
                                        className="btn btn-default"
                                        onClick={props.onClickResolve}
                                    >
                                        {(props.buttons_text && props.buttons_text[0]) || 'Ok'}
                                    </button>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </CustomTag>
    );
};

const ConfirmationDialog = (
    {
        variant, confirmation_message, description_message, buttons_text, confirmation_message_renderer, className, inModal, onClickResolve, onClickReject, onClickOutside,
    },
) => {
    if (inModal) {
        return (
            <ConfirmationDialogComp
                className={className}
                variant={variant}
                confirmation_message={confirmation_message}
                confirmation_message_renderer={confirmation_message_renderer}
                description_message={description_message}
                buttons_text={buttons_text}
                inModal={inModal}
                onClickResolve={onClickResolve}
                onClickReject={onClickReject}
                onClickOutside={onClickOutside || onClickReject}
            />
        );
    }
    return new Promise((resolve, reject) => {
        const modal = document.createElement('div');
        document.body.appendChild(modal);
        ReactDOM.render(<ConfirmationDialogComp
            className={className}
            variant={variant}
            confirmation_message={confirmation_message}
            confirmation_message_renderer={confirmation_message_renderer}
            description_message={description_message}
            buttons_text={buttons_text}
            onClickOutside={onClickOutside || onClickReject}
            onClickResolve={() => {
                ReactDOM.render(null, modal);
                resolve();
            }}
            onClickReject={() => {
                ReactDOM.render(null, modal);
                if (variant === 'danger') {
                    reject();
                } else {
                    resolve();
                }
            }}
        />, modal);
    });
};

export default ConfirmationDialog;
