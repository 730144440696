import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import './LanguageSelector.scss';
import Triangle from './Triangle.svg';
import Tooltip from '../../../../components/frames/tooltip/Tooltip';

export const possible_languages = ['EN', 'FR', 'DE', 'NL', 'IT', 'SV'];

const LanguageSelector = () => {
    const [_visible, setVisible] = useState(false);
    const [_languageSelected, setLanguageSelected] = useState('EN');
    const [_languages, setLanguages] = useState([]);

    useEffect(() => {
        const languages = [];
        _.forEach(possible_languages, (language) => {
            if (language !== _languageSelected) languages.push(language);
        });
        setLanguages(languages);
    }, [_languageSelected]);

    useEffect(() => {
        const persisted_language = window.sessionStorage.getItem('user_language');
        if (persisted_language) {
            setLanguageSelected(persisted_language);
            i18n.changeLanguage(persisted_language.toLowerCase());
        }
    }, []);

    return (
        <div
            className="language-selector"
            onClick={() => setVisible(!_visible)}
        >
            <div>{_languageSelected}</div>
            <img src={Triangle} alt="triangle-img" />
            <Tooltip
                noIcon
                visible={_visible}
                noTitle
                placement="bottom"
            >
                <ul>
                    {
                        _languages.map((language) => (
                            <li
                                onClick={() => {
                                    window.sessionStorage.setItem('user_language', language);
                                    window.location.reload();
                                }}
                            >
                                {language}
                            </li>
                        ))
                    }
                </ul>
            </Tooltip>
        </div>
    );
};

export default LanguageSelector;
