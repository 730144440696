/**
 * Created by lpostula on 11/05/17.
 */
import types from './types';
import { createReducer, apiHelpers } from '../../utils';

/* State shape
{
    {status_id: status},
}
 */

const initialState = {};

export default createReducer(initialState)({
    [types.FETCH_LIST_COMPLETED]: apiHelpers.fetch_list_complete,
    [types.FETCH_DETAIL_COMPLETED]: apiHelpers.fetch_details_complete,
    [types.PROCESS_COMPLETED]: apiHelpers.fetch_details_complete,
});
