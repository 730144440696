import React, { useState } from 'react';
import './HintIcon.scss';
import HintSymbol from './HintSymbol.svg';
import WithPopper from '../../app/WithPopper';

const HintIcon = (props) => {
    const [_isOpen, setIsOpen] = useState(false);
    const openSrc = props.opened || props.src || HintSymbol;
    const closedSrc = props.closed || props.src || HintSymbol;
    return (
        <div>
            <WithPopper
                placement={props.placement || 'auto'}
                visible={props.visible === undefined ? _isOpen : props.visible}
                openCallback={(v) => {
                    setIsOpen(v);
                    if (props.setVisible) props.setVisible(v);
                }}
                noEvent={props.noEvent}
            >
                {
                    !props.noIcon
                    && (
                        <div>
                            <div
                                className={`hint-icon-holder ${_isOpen ? 'open' : 'closed'}`}
                            >
                                <img className="hint-icon" src={_isOpen ? openSrc : closedSrc} alt="important" />
                            </div>
                        </div>
                    )
                }
                <div className="hint-box">
                    {
                        props.text
                        && (
                            <div>{ props.text }</div>
                        )
                    }
                    { props.children }
                </div>
            </WithPopper>
        </div>
    );
};

export default HintIcon;
