/**
 * Created by lpostula on 26/10/17.
 */

const HIDE_ASSET_STATUS_FORM = 'ui/HIDE_ASSET_STATUS_FORM';

const RELOAD_PAGE = 'ui/RELOAD_PAGE';
const DISMISS_RELOAD = 'ui/DISMISS_RELOAD';

const SB_CREATE = 'ui/SB_CREATE';
const SB_QUEUE = 'ui/SB_QUEUE';
const SB_QUEUE_REMOVE = 'ui/SB_QUEUE_REMOVE';
const SB_REMOVE = 'ui/SB_REMOVE';

const SELECT_ASSET_FOR_PERM_CHECK = 'ui/SELECT_ASSET_FOR_PERM_CHECK';
const SELECT_COUNTER_READING_ASSET = 'ui/SELECT_COUNTER_READING_ASSET';

export default {
    HIDE_ASSET_STATUS_FORM,
    RELOAD_PAGE,
    DISMISS_RELOAD,
    SB_CREATE,
    SB_QUEUE,
    SB_QUEUE_REMOVE,
    SB_REMOVE,
    SELECT_ASSET_FOR_PERM_CHECK,
    SELECT_COUNTER_READING_ASSET,
};
