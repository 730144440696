/**
 * Created by lpostula on 10/05/17.
 */

// GET / LIST API BASE
const FETCH_ME_REQUEST = 'users/FETCH_ME_REQUEST';
const FETCH_ME_COMPLETED = 'users/FETCH_ME_COMPLETED';
const FETCH_ME_FAILED = 'users/FETCH_ME_FAILED';

const FETCH_LIST_REQUEST = 'users/FETCH_LIST_REQUEST';
const FETCH_LIST_COMPLETED = 'users/FETCH_LIST_COMPLETED';
const FETCH_LIST_FAILED = 'users/FETCH_LIST_FAILED';

// UPDATE USER ATTRIBUTES
const SET_LANGUAGE_REQUEST = 'users/SET_LANGUAGE_REQUEST';
const SET_LANGUAGE_COMPLETED = 'users/SET_LANGUAGE_COMPLETED';
const SET_LANGUAGE_FAILED = 'users/SET_LANGUAGE_FAILED';

const SET_TIMEZONE_REQUEST = 'users/SET_TIMEZONE_REQUEST';
const SET_TIMEZONE_COMPLETED = 'users/SET_TIMEZONE_COMPLETED';
const SET_TIMEZONE_FAILED = 'users/SET_TIMEZONE_FAILED';

export default {
    FETCH_ME_REQUEST,
    FETCH_ME_COMPLETED,
    FETCH_ME_FAILED,
    FETCH_LIST_REQUEST,
    FETCH_LIST_COMPLETED,
    FETCH_LIST_FAILED,
    SET_LANGUAGE_REQUEST,
    SET_LANGUAGE_COMPLETED,
    SET_LANGUAGE_FAILED,
    SET_TIMEZONE_REQUEST,
    SET_TIMEZONE_COMPLETED,
    SET_TIMEZONE_FAILED,
};
