import React, { useState, useEffect, useContext } from 'react';
import './LocoPreparation.scss';
import '../../../components/containers/Modals/ModalLayout.scss';
import i18n from 'i18next';
import moment from 'moment-timezone';
import MultiDeviceLayout from '../../../components/app/MultiDeviceLayout';
import HeaderModal from '../../../components/headers/headerModal/HeaderModal';
import usePerm from '../../../components/hooks/usePerm';
import Stamp from '../../../components/display/stamp/Stamp';
import Button from '../../../components/buttons/button/Button';
import LocoPreparationDetail from '../../details/locoPreparationDetail/LocoPreparationDetail';
import LocoPreparationForm from '../../forms/locoPreparationForm/LocoPreparationForm';
import useFetch from '../../../components/hooks/useFetch';
import { LoaderContext } from '../../../components/containers/Modals/Modal';

const LocoPreparationViewMode = (props) => {
    const conformityTextValid = i18n.t('Was conform on the');
    const conformityTextInvalid = i18n.t('Was not conform on the');
    const selectedConformityText = props.preparation_detail && props.preparation_detail.is_conform
        ? conformityTextValid
        : conformityTextInvalid;
    const date = props.preparation_detail && props.preparation_detail.timestamp;
    const dateStr = date ? moment(date).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY');
    return (
        <div className="loco-preparation-holder">
            <div className="loco-preparation-top">
                <Stamp
                    valid={props.preparation_detail && props.preparation_detail.is_conform}
                    text={`${selectedConformityText} ${dateStr}`}
                />
                {
                    !props.noForm
                        && (
                            <Button
                                label={i18n.t('Start the asset preparation')}
                                onClick={() => props.setEditMode(true)}
                                disabled={!props.hasPerm('create_preparation')}
                            />
                        )
                }
            </div>
            <div className="loco-preparation-bottom">
                <LocoPreparationDetail
                    asset_id={props.asset_id}
                    preparation_detail={props.preparation_detail}
                    steps={props.steps}
                />
            </div>
        </div>
    );
};

const LocoPreparationEditMode = (props) => (
    <div className="loco-preparation-holder">
        <div className="loco-preparation-bottom">
            <LocoPreparationForm
                asset_id={props.asset_id}
                steps={props.steps}
                callback={props.callback}
                type={props.preparation_type_id}
                {...props}
            />
        </div>
    </div>
);

const LocoPreparationComponent = (props) => {
    const { registerDataToLoad } = useContext(LoaderContext);
    const [_editMode, setEditMode] = useState(props.editMode);
    const [_steps, setSteps] = useState(props.steps);
    const { fetchAll } = useFetch();
    const hasPerm = usePerm(props.asset_id || null);

    useEffect(() => {
        if (registerDataToLoad) {
            registerDataToLoad({
                id: 'preparation_LocoPreparationComponent',
                loaded: !!props.preparation_detail || !_editMode,
            });
            registerDataToLoad({
                id: '_steps_LocoPreparationComponent',
                loaded: _steps && _steps.length > 0,
            });
        }
    }, [props.latestPreparations, _steps]);

    useEffect(() => {
        if (props.loginless) {
            setSteps(props.steps);
        } else if (props.preparation_type_id && !props.steps) {
            fetchAll('/api/preparation_step.json', {
                preparation_type: props.preparation_type_id,
            }).then((d) => {
                if (d && !d.error) {
                    setSteps(d);
                } else {
                    setSteps(null);
                }
            });
        } else if (!props.preparation_type_id) {
            setSteps(null);
        }
    }, [props.preparation_type_id, props.steps, props.loginless]);

    const detailRenderer = () => (
        <div className="detail-modal-refs">
            <div className="detail-modal-ref">
                <span>{i18n.t('Asset')}</span>
                <span>{`${props.asset_name}`}</span>
            </div>
            <div className="detail-modal-ref">
                <span>{i18n.t('Preparation')}</span>
                <span>{`${props.preparation_type_name}`}</span>
            </div>
        </div>
    );

    if (!_steps) return null;
    return (
        <div className={`loco-preparation root-modal ${props.device}`}>
            <HeaderModal
                title={i18n.t('Digital Logbook')}
                subtitle={i18n.t('Add a preparation')}
                detailRenderer={detailRenderer}
                onCloseModal={props.onCloseModal}
            />
            {
                _editMode || !(props.preparation_detail) ? (
                    <LocoPreparationEditMode
                        {...props}
                        steps={_steps}
                        callback={props.callback}
                    />
                ) : (
                    <LocoPreparationViewMode
                        {...props}
                        setEditMode={setEditMode}
                        steps={_steps}
                        hasPerm={hasPerm || props.hasPerm}
                    />
                )
            }
        </div>
    );
};

const LocoPreparation = (props) => (
    <MultiDeviceLayout>
        <LocoPreparationComponent {...props} />
    </MultiDeviceLayout>
);

export default LocoPreparation;
