import React, { useState } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import Creatable from 'react-select/creatable';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { FixedSizeList as List } from 'react-window';
import './Selector.scss';
import UseConfirmation from '../../containers/ConfirmationDialog/ConfirmationDialog';
import triangle from './triangle.svg';
import info from '../../filters/verticalMultiOptionFilter/info.svg';
import info_on_hover from '../../filters/verticalMultiOptionFilter/info-on-hover.svg';
import WithPopper from '../../app/WithPopper';

const allowed_passed_props = [
    'autoFocus', 'autosize', 'onBlur', 'clearable', 'multi', 'isLoading', 'autoload', 'onInputChange', 'defaultOptions', 'cacheOptions', 'onKeyDown',
    'closeMenuOnSelect',
];

const getSelected = (selected, options, id_getter, legacy) => {
    if (!selected || !legacy) {
        return selected;
    }
    if (Array.isArray(options)) {
        if (Array.isArray(selected)) {
            return options.filter((o) => selected.some((s) => s === o.value));
        }
        for (const option of options) {
            if (Array.isArray(option.options)) {
                const elm = option.options.filter((o) => selected === o.value);
                if (elm && elm.length > 0) {
                    return elm;
                }
            }
        }
        return options.filter((o) => selected === o.value);
    }
    if (Array.isArray(selected)) {
        return selected.map((s) => options[s]);
    }
    return options[selected];
};

const isOptionDisabled = (o) => o.hasOwnProperty('disabled') && o.disabled;

export const getStyle = (props) => {
    const radius = props.hasAddon ? {
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
    } : {};
    return ({
        control: (styles, state) => {
            const focus = (
                state.selectProps.menuIsOpen && props.style.control
            ) ? props.style.control.focus : {};
            const disabled = (
                state.isDisabled && props.style.control
            ) ? props.style.control.disabled : {};
            const error = (
                !props.isValid && props.style.control
            ) ? props.style.control.error : {};
            return ({
                ...styles,
                backgroundColor: 'white',
                boxShadow: 'inherit',
                ...props.style.control,
                ...radius,
                ...focus,
                ...disabled,
                ...error,
            });
        },
        option: (styles) => ({
            ...styles,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...props.style.option,
        }),
        input: (styles) => ({ ...styles, ...props.style.input }),
        menuList: (styles) => ({ ...styles, ...props.style.menuList }),
        placeholder: (styles, state) => {
            const focus = (
                state.selectProps.menuIsOpen && props.style.placeholder
            ) ? props.style.placeholder.focus : {};
            const disabled = (
                state.isDisabled && props.style.placeholder
            ) ? props.style.placeholder.disabled : {};
            return ({
                ...styles, ...props.style.placeholder, ...focus, ...disabled,
            });
        },
        singleValue: (styles) => ({ ...styles, ...props.style.singleValue }),
        multiValue: (styles, state) => {
            const disabled = (
                state.isDisabled && props.style.multiValue
            ) ? props.style.multiValue.disabled : {};
            return ({ ...styles, ...props.style.multiValue, ...disabled });
        },
        multiValueLabel: (styles) => ({ ...styles, ...props.style.multiValueLabel }),
        multiValueRemove: (styles) => ({ ...styles, ...props.style.multiValueRemove }),
        group: (styles) => ({ ...styles, ...props.style.group }),
        groupHeading: (styles) => ({ ...styles, ...props.style.groupHeading }),
        menu: (styles) => ({
            ...styles,
            zIndex: 1005,
            top: props.openTop ? 'auto' : null,
            bottom: props.openTop ? '32px' : null,
            borderBottomLeftRadius: props.openTop ? '3px' : null,
            borderBottomRightRadius: props.openTop ? '3px' : null,
            borderTopLeftRadius: props.openTop ? '3px' : null,
            borderTopRightRadius: props.openTop ? '3px' : null,
            color: 'black',
            ...props.style.menu,
        }),
        container: (styles, state) => {
            const base = { ...styles, width: props.width, ...props.style.container };
            if (props.inline_label) {
                const focus = (
                    state.selectProps.menuIsOpen && props.style.inlineLabel
                ) ? props.style.inlineLabel.focus : {};
                const disabled = (
                    state.isDisabled && props.style.inlineLabel
                ) ? props.style.inlineLabel.disabled : {};
                base.inlineLabel = { ...props.style.inlineLabel, ...focus, ...disabled };
            }
            return base;
        },
        indicatorsContainer: (styles) => ({ ...styles, ...props.style.indicatorsContainer }),
        dropdownIndicator: (styles, state) => {
            const focus = (
                state.selectProps.menuIsOpen && props.style.dropdownIndicator
            ) ? props.style.dropdownIndicator.focus : {};
            const disabled = (
                state.isDisabled && props.style.dropdownIndicator
            ) ? props.style.dropdownIndicator.disabled : {};
            return {
                ...styles, ...props.style.dropdownIndicator, ...focus, ...disabled,
            };
        },
        clearIndicator: (styles, state) => {
            const focus = (
                state.selectProps.menuIsOpen && props.style.clearIndicator
            ) ? props.style.clearIndicator.focus : {};
            const disabled = (
                state.isDisabled && props.style.clearIndicator
            ) ? props.style.clearIndicator.disabled : {};
            return {
                ...styles, ...props.style.clearIndicator, ...focus, ...disabled,
            };
        },
        indicatorSeparator: (styles, state) => {
            const focus = (
                state.selectProps.menuIsOpen && props.style.indicatorSeparator
            ) ? props.style.indicatorSeparator.focus : {};
            const disabled = (
                state.isDisabled && props.style.indicatorSeparator
            ) ? props.style.indicatorSeparator.disabled : {};
            return {
                ...styles, ...props.style.indicatorSeparator, ...focus, ...disabled,
            };
        },
        valueContainer: (styles) => ({ ...styles, ...props.style.valueContainer }),
    });
};

/**
 * Filter the options displayed by label only, case-insensitive (and not by value)
 */
const _filterOption = (option, input) => {
    if (!option.label) console.log('you forgot to put a labelizer');
    const label = `${option.label}`;
    return (
        label.toLowerCase()
            .includes(input.toLowerCase())
    );
};

class Selector extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onChangeAction = this.onChangeAction.bind(this);
        this.constructOptions = this.constructOptions.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.onMenuOpen = this.onMenuOpen.bind(this);
        this.onMenuClose = this.onMenuClose.bind(this);
        // this.sort_func = this.sort_func.bind(this);
        this.getSelected = memoize(getSelected);
        this.sort_func = this.sort_func.bind(this);

        this.state = { menuIsOpen: false };

        this.customElements = props.components;
        if (props.virtual) {
            this.customElements = { MenuList: (p) => (<MenuList {...p} is_asset_SelectFilter={props.is_asset_SelectFilter} />), ...this.customElements };
        }
        if (props.grouped && !this.customElements.hasOwnProperty('Group')) {
            this.customElements = {
                Group: (p) => (
                    <components.Group
                        style={{ width: '98%' }}
                        {...p}
                    />
                ),
                ...this.customElements,
            };
        }
        if (props.inline_label) {
            this.customElements = {
                ValueContainer: (p) => (
                    <ValueContainer {...p} inline_label={props.inline_label} />),
                ...this.customElements,
            };
        }
        this.customElements = {
            MultiValueLabel: (p) => (
                <span title={p.data.label} style={{ overflow: 'hidden' }}>
                    <components.MultiValueLabel {...p} />
                </span>
            ),
            ...this.customElements,
        };
        this.customElements = {
            SingleValue: (p) => (
                <div title={p.data.label} style={{ flex: 1 }}>
                    <components.SingleValue {...p} />
                </div>
            ),
            ...this.customElements,
        };
        this.customElements = {
            Option: (p) => {
                let label = p.data.label;
                let sublabel = null;
                if (this.props.is_asset_SelectFilter) {
                    label = p.data.name ? p.data.name : label;
                    if (p.data.class_name) {
                        sublabel = <div className={`sublabel ${p.isSelected ? 'selected' : ''}`}>{p.data.class_name}</div>;
                    }
                }
                const [isPopperOpen, setIsPopperOpen] = useState(false);
                let option = (
                    <div title={p.data.label}>
                        <components.Option {...p}>
                            {label}
                            {sublabel}
                        </components.Option>
                    </div>
                );
                if (typeof p.data.has_telematic_data !== 'undefined' && !p.data.has_telematic_data && (typeof this.props.enableLabelNoTelematicData === 'boolean' && this.props.enableLabelNoTelematicData === true)) {
                    option = (
                        <div title={p.data.label} className="box-assets-no-telematic-data">
                            <components.Option {...p} isDisabled innerProps={{ ...p.innerProps, onClick: (event) => event.stopPropagation() }}>
                                <div className="flex-box-ellipsis-assets">
                                    {' '}
                                    <div>
                                        {label}
                                        {sublabel}
                                    </div>
                                    <img className="triangle" src={triangle} alt="no_telematic_data_image" />
                                    {' '}
                                    <div className="no-telematic-data">
                                        {' '}
                                        {i18n.t('No Railster or associated data')}
                                    </div>
                                </div>
                            </components.Option>
                        </div>
                    );
                }
                if (!!p.data.description && p.data.description !== '') {
                    option = (
                        <components.Option {...p} innerProps={{ ...p.innerProps, onMouseEnter: () => setIsPopperOpen(true), onMouseLeave: () => setIsPopperOpen(false) }}>
                            <div
                                className="grid-element-selector-option"
                            >
                                <div className="grid-child option">{p.data.label}</div>
                                <div className="grid-child option-info">
                                    <img className="info" src={isPopperOpen ? info_on_hover : info} alt="info_on_hover" />
                                    <WithPopper
                                        visible={isPopperOpen}
                                        placement="right-start"
                                    >
                                        <div />
                                        <div className="info-content">
                                            <div>
                                                {i18n.t(p.data.description)}
                                                <br />
                                            </div>
                                        </div>
                                    </WithPopper>
                                </div>
                            </div>
                        </components.Option>
                    );
                }
                return option;
            },
            ...this.customElements,
        };
    }

    onMenuOpen() {
        if (this.props.onMenuOpen) this.props.onMenuOpen();
        this.setState({ menuIsOpen: true });
    }

    onMenuClose() {
        if (this.props.onMenuClose) this.props.onMenuClose();
        this.setState({ menuIsOpen: false });
    }

    // this is necessary to support select all shortcuts since react-select v3
    handleKeyDown(evt) {
        if (evt.key === 'Home') {
            evt.preventDefault();
            if (evt.shiftKey) {
                // eslint-disable-next-line no-param-reassign
                evt.target.selectionStart = 0;
            } else {
                evt.target.setSelectionRange(0, 0);
            }
        } else if (evt.key === 'End') {
            evt.preventDefault();
            const len = evt.target.value.length;
            if (evt.shiftKey) {
                // eslint-disable-next-line no-param-reassign
                evt.target.selectionEnd = len;
            } else {
                evt.target.setSelectionRange(len, len);
            }
        } else if (evt.key === 'Enter') {
            if (this.props.onEnterKey && !this.state.menuIsOpen) {
                this.props.onEnterKey();
            }
        }
    }

    sort_func(a, b) {
        const sa = String(a.label)
            .toLowerCase();
        const sb = String(b.label)
            .toLowerCase();
        return -(sa < sb) || +(sa !== sb);
    }

    constructOptions() {
        const options = [];
        const get_id = this.props.id_getter;
        const get_label = this.props.labelizer;
        let { values } = this.props;
        if (!this.props.values) {
            return [];
        }
        if (typeof values === 'object') {
            values = _.values(values);
        }
        values.map((val) => {
            const o = {
                ...val,
                value: get_id(val),
                label: get_label(val),
            };
            options.push(o);
            return val;
        });
        // sort by label
        if (this.props.sort || this.props.sort_func) {
            options.sort(this.props.sort_func || this.sort_func);
        }
        return options;
    }

    onChangeAction(select, action) {
        if (!this.props.legacy) {
            return this.props.onChange(select, action);
        }
        let values = select;
        if (!this.props.search) {
            values = [];
            for (const s of [].concat(select)) {
                if (s !== undefined && s !== null) {
                    if (this.props.response_template) {
                        const elm = {};
                        for (const template of this.props.response_template) {
                            elm[template] = s[template];
                        }
                        values.push(elm);
                    } else {
                        values.push(s.value);
                    }
                }
            }
        }
        let ret;
        if (this.props.onClear && action.action === 'clear') {
            ret = this.props.onClear();
        } else {
            ret = this.props.onChange(values);
        }
        if (this.props.onChangeCallback) {
            this.props.onChangeCallback();
        }
        return ret;
    }

    onChange(select, action) {
        if (this.props.confirmation) {
            UseConfirmation({
                variant: 'danger',
                confirmation_message: this.props.confirmation_message || i18n.t('Are you sure you want to do this ?'),
                description_message: this.props.description_message,
                buttons_text: this.props.confirmation_buttons_text || ['No', 'Yes'],
            })
                .then(() => this.onChangeAction(select, action));
            // .catch(() => {
            //     return;
            // });
        } else {
            return this.onChangeAction(select, action);
        }
        return null;
    }

    render() {
        const auto_passed_props = {};
        for (const authorized_props of allowed_passed_props) {
            if (this.props.hasOwnProperty(authorized_props)) {
                auto_passed_props[authorized_props] = this.props[authorized_props];
            }
        }
        if (this.props.hasOwnProperty('clearable')) {
            auto_passed_props.isClearable = this.props.clearable;
        }
        if (this.props.multi) {
            auto_passed_props.isMulti = this.props.multi;
        }
        if (!this.props.writable) {
            auto_passed_props.isSearchable = false;
        }
        if (this.props.autoload) {
            auto_passed_props.defaultOptions = this.props.autoload;
        }
        if (this.props.matchProp) {
            auto_passed_props.matchProp = this.props.matchProp;
        }

        if (this.props.filterOption) {
            auto_passed_props.filterOption = this.props.filterOption;
        } else {
            // By default options are filtered on label only for this component
            // (unlike the react-select's default which is to filter on id too).
            // See issues #7403 and #7338.
            auto_passed_props.filterOption = _filterOption;
        }
        let Widget;
        if (this.props.creatable) {
            Widget = Creatable;
            auto_passed_props.options = this.constructOptions();
        } else if (this.props.search) {
            Widget = AsyncSelect;
            auto_passed_props.loadOptions = this.props.search;
        } else {
            Widget = Select;
            if (!this.props.grouped) {
                auto_passed_props.options = this.constructOptions();
            } else {
                auto_passed_props.options = this.props.values;
            }
        }

        let { selected } = this.props;
        if (!this.props.search) {
            selected = this.getSelected(
                this.props.selected,
                auto_passed_props.options,
                this.props.id_getter,
                this.props.legacy,
            );
        }
        const componentStyles = getStyle(this.props);
        return (
            <div
                className="flex-box-ellipsis-fixer"
                style={{ maxWidth: this.props.customMaxWidth ? this.props.customMaxWidth : '700px' }}
            >
                <Widget
                    value={selected}
                    isOptionDisabled={isOptionDisabled}
                    openMenuOnFocus={this.props.autoFocus}
                    components={this.customElements}
                    openOnFocus={this.props.autoFocus}
                    onChange={this.onChange}
                    onMenuOpen={this.onMenuOpen}
                    onMenuClose={this.onMenuClose}
                    placeholder={this.props.label}
                    isDisabled={!!this.props.disabled}
                    styles={componentStyles}
                    onKeyDown={this.handleKeyDown}
                    {...auto_passed_props}
                />
            </div>
        );
    }
}

const ValueContainer = ({ children, ...props }) => {
    const label_style = props.getStyles('container', props).inlineLabel;
    return (
        <components.ValueContainer {...props}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '3px 2px 0px 0px',
                    flex: 1,
                    overflow: 'hidden',
                }}
            >
                <div style={label_style}>{props.inline_label}</div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                }}
                >
                    {children}
                </div>
            </div>
        </components.ValueContainer>
    );
};

const MenuList = (props) => {
    let height = 35;
    if (props.is_asset_SelectFilter) {
        height = 40;
    }
    const {
        options, children, maxHeight, getValue,
    } = props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    const size = children.length * height;
    let realHeight = maxHeight;
    let realOffset = initialOffset;
    // if there's less element to display than the max size of the dropdown, we do not need to hide anything
    // and we can crop the size of the dropdown to the actual amount of element
    if (size <= maxHeight) {
        realHeight = children.length * height;
        realOffset = 0;
    }

    return (
        <List
            height={realHeight}
            itemCount={children.length}
            itemSize={height}
            initialScrollOffset={realOffset}
        >
            {({ index, style }) => <div style={style}>{children[index]}</div>}
        </List>
    );
};

Selector.propTypes = {
    onChange: PropTypes.func.isRequired,
    creatable: PropTypes.bool,
    onEnterKey: PropTypes.func,
};

Selector.defaultProps = {
    values: [],
    selected: null,
    label: `${i18n.t('Select')}...`,
    item_name: 'item',
    multi: false,
    autoFocus: false,
    autosize: false,
    clearable: true,
    sort: true,
    creatable: false,
    legacy: true,
    isValid: true,
    writable: true,
    components: {},
    style: {},
    onBlur() {
    },
    labelizer(item) {
        return item.label;
    },
    id_getter(item) {
        return item.id;
    },
    response_template: null,
};

export default Selector;
