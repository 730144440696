import React, { useState } from 'react';
import PropTypes from 'prop-types';

import '../CommonButton.scss';
import './DropdownMenuButton.scss';
import WithPopper from '../../app/WithPopper';

const DropdownMenuButton = (props) => {
    const [_open, setOpen] = useState(false);
    return (
        <WithPopper
            placement="bottom"
            dontOpenOnHover
            dontOpenOnClick={props.disabled}
            visible={_open}
            openCallback={(v) => setOpen(v)}
        >
            <button
                className={`sscustom-button ${props.primary ? 'primary' : 'secondary'} ${props.large ? 'large' : ''} ${props.disabled ? 'disabled' : ''}`}
                // onClick={!props.disabled ? props.onClick : null}
                onClick={(evt) => evt.preventDefault()}
            >
                <span className="label">{props.label}</span>
                <span className="glyphicon glyphicon-triangle-bottom" />
            </button>
            <div className="dropdown-menu-button-dd">
                {
                    props.actions.map((v) => {
                        if (!v) return null;
                        return (
                            <div
                                key={v.label}
                                className={`dmbdd-option${v.disabled ? ' disabled' : ''}`}
                                onClick={(evt) => {
                                    if (v.disabled) return;
                                    setOpen(false);
                                    v.onClick(evt);
                                }}
                            >
                                <span className="dmbdd-option-label">{v.label}</span>
                                <svg
                                    width="5"
                                    height="8"
                                    viewBox="0 0 5 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.32667 0L5 3.99637L1.32667 8L0 6.56131L2.35333 3.99637L0 1.43143L1.32667 0Z"
                                    />
                                </svg>

                            </div>
                        );
                    })
                }
            </div>
        </WithPopper>
    );
};

DropdownMenuButton.defaultProps = {
    primary: false,
    large: false,
    label: '...',
    disabled: false,
    actions: [],
};

DropdownMenuButton.propTypes = {
    primary: PropTypes.bool,
    label: PropTypes.string,
    large: PropTypes.bool,
    disabled: PropTypes.bool,
    actions: PropTypes.array,
};

export default DropdownMenuButton;
