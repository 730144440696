import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './MultiOptionFilter.scss';

const Option = (props) => {
    const handleInputChange = (event) => props.onChange(props.option, event.target.checked);
    return (
        <div className="option-checkbox-wrapper">
            <input
                type="checkbox"
                checked={props.value}
                onChange={handleInputChange}
                className="option-checkbox"
                id={props.label}
            />
            {props.label_prepend
                && <span className="label-prepend">{props.label_prepend}</span>}
            <label
                htmlFor={props.label}
                style={props.color ? { color: props.color } : {}}
                className="option-label"
            >
                {props.label}
            </label>
        </div>
    );
};

const MultiOptionFilter = (props) => {
    const [_values, setValues] = useState(props.values || []);

    useEffect(() => {
        if (props.defaultValue !== undefined) {
            setValues(props.defaultValue);
        }
    }, []);

    const onOptionChange = (option, value) => {
        if (props.disabled) return;
        let new_values = null;
        if (value) {
            new_values = [..._values, option];
            setValues(new_values);
        } else {
            new_values = _.without(_values, option);
            setValues(_.without(_values, option));
        }
        if (props.onChange) props.onChange(new_values);
    };

    const displayOption = (position) => {
        const display = props.options.length >= position + 1;
        if (!display) return null;
        const option = props.options[position];
        return (
            <Option
                value={_.contains(_values, option.key)}
                option={option.key}
                label={option.label}
                color={option.color}
                onChange={onOptionChange}
                label_prepend={props.labelPrependRenderer && props.labelPrependRenderer(option.key)}
            />
        );
    };

    return (
        <div
            className={`multi-option-select ${props.disabled ? 'disabled' : ''}`}
        >
            <div className="grid-element field-label">{props.label}</div>
            <div className="grid-element">
                {displayOption(3)}
            </div>
            <div className="grid-element">
                {displayOption(4)}
            </div>
            <div className="grid-element">
                {displayOption(0)}
            </div>
            <div className="grid-element">
                {displayOption(1)}
            </div>
            <div className="grid-element">
                {displayOption(2)}
            </div>
        </div>
    );
};

MultiOptionFilter.defaultProps = {
    disabled: false,
};

MultiOptionFilter.propTypes = {
    values: PropTypes.array,
    options: PropTypes.array,
    label: PropTypes.string,
    onChange: PropTypes.func,
    is_fetching: PropTypes.bool,
    disabled: PropTypes.bool,
    labelPrependRenderer: PropTypes.func,
};

export default MultiOptionFilter;
