/**
 * Created by lpostula on 16/04/18.
 */
import React from 'react';
import { connect } from 'react-redux';

import { uiOperations, uiSelectors } from 'railfleet_state/ducks/ui';
import './SnackBar.scss';

const SnackBar = (props) => (
    <div className="snackbar">
        <div className={`alert alert-${props.className || 'info'} ${props.dismissible ? 'alert-dismissible' : ''}`} role="alert">
            {
                props.dismissible ? (
                    <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={props.removeSnackBar}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                ) : null
            }
            { props.message }
        </div>
    </div>
);

const SnackBarHolder = (props) => (
    <div className="snackbar_holder">
        {
            props.snackbars.map((snack) => (
                <SnackBar
                    key={snack.id}
                    removeSnackBar={() => props.removeSnackBar(snack)}
                    {...snack}
                />
            ))
        }
    </div>
);

const mapStateToProps = (state) => ({
    snackbars: uiSelectors.getActiveSnackBar(state),
});

const mapDispatchToProps = (dispatch) => ({
    removeSnackBar: (s) => dispatch(uiOperations.removeSnackBar(s)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SnackBarHolder);
