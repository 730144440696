/**
 * Created by lpostula on 26/10/17.
 */

const FETCH_ASSET_PREPARATION_REQUEST = 'lineas_gmao/FETCH_ASSET_PREPARATION_REQUEST';
const FETCH_ASSET_PREPARATION_COMPLETED = 'lineas_gmao/FETCH_ASSET_PREPARATION_COMPLETED';
const FETCH_ASSET_PREPARATION_FAILED = 'lineas_gmao/FETCH_ASSET_PREPARATION_FAILED';

export default {
    FETCH_ASSET_PREPARATION_REQUEST,
    FETCH_ASSET_PREPARATION_COMPLETED,
    FETCH_ASSET_PREPARATION_FAILED,
};
