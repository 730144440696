/**
 * Created by lpostula on 12/10/17.
 */
import React from 'react';
import PropTypes from 'prop-types';

const default_labelizer = (item) => item.label;
const default_idgetter = (item) => item.id;

class MultiStateSelector extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(id) {
        let payload;
        if (!this.props.multi) {
            // classic onChange mechanism
            payload = id;
        } else if (!this.props.selected) {
            payload = [id];
        } else {
            // in a multi field configuration we need to dispatch the onChange
            // with the id added or removed from the current values
            const index = this.props.selected.indexOf(id);
            if (index === -1) {
                // the value was not in the selected index
                payload = this.props.selected.concat([id]);
            } else {
                // the value should be removed from the array
                payload = [
                    ...this.props.selected.slice(0, index),
                    ...this.props.selected.slice(index + 1),
                ];
            }
        }
        this.props.onChange(payload);
        if (this.props.onChangeCallback) {
            this.props.onChangeCallback();
        }
    }

    render() {
        const labelizer = this.props.labelizer || default_labelizer;
        const id_getter = this.props.id_getter || default_idgetter;
        return (
            <div
                className={`multistateselector btn-group btn-group-justified ${this.props.className || ''}`}
                role="group"
            >
                {
                    this.props.values.map((value) => {
                        let active = false;
                        const id = id_getter(value);
                        if (this.props.multi) {
                            active = _.contains(this.props.selected, id);
                        } else {
                            active = this.props.selected === id;
                        }
                        return (
                            <div
                                className="btn-group"
                                key={`multistateselector_${this.props.name}_value_${id}`}
                            >
                                <button
                                    type="button"
                                    id={id}
                                    className={`btn btn-${value.classname || 'default'} ${(active && 'active') || ''}`}
                                    disabled={value.disabled}
                                    onClick={() => this.onChange(id)}
                                >
                                    {labelizer(value)}
                                </button>
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}

MultiStateSelector.defaultProps = {
    values: [],
    selected: null,
    multi: false,
};

MultiStateSelector.propTypes = {
    name: PropTypes.string.isRequired,
    values: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    labelizer: PropTypes.func,
    id_getter: PropTypes.func,
    selected: PropTypes.any, // eslint-disable-line react/no-unused-prop-types
    className: PropTypes.string,
    multi: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
};

export default MultiStateSelector;
