import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import moment from 'moment-timezone';
import { countersSelectors } from 'railfleet_state/ducks/counters';
import TextDisplay from '../../../display/textDisplay/TextDisplay';
import { COUNTER_UNIT_TRANSLATION } from '../formCounter/constants';
import formatNumber from '../../../../utils/formatNumber';

const RenderCounter = (props) => {
    const displayCounterInfo = () => {
        const unit_text = COUNTER_UNIT_TRANSLATION[props.unit] || props.unit;
        const value_date = moment(props.value_date);
        const limit = moment().subtract(15, 'days');
        const text_to_use = value_date < limit
            ? 'counter_value_info_stale' : 'counter_value_info';
        return (
            <small
                dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
                    __html: i18n.t(text_to_use, {
                        value: `<b>${formatNumber(props.value)} ${unit_text}</b>`,
                        date: `<b>${value_date.format('DD/MM/YYYY')}</b>`,
                        interpolation: { escapeValue: false },
                    }),
                }}
            />
        );
    };
    const counter_infos = displayCounterInfo();
    return (
        <TextDisplay
            label={i18n.t('Counter')}
            data={props.name}
        >
            { counter_infos }
        </TextDisplay>
    );
};

const mapStateToProps = (state, props) => {
    const { counter_id } = props;
    const counter = countersSelectors.getCounter(state, counter_id);
    const name = counter && counter.counter_type_name;
    const value = counter && counter.value;
    const unit = counter && counter.unit;
    const value_date = counter && counter.value_date;
    return {
        counter,
        name,
        value,
        unit,
        value_date,
    };
};

export default connect(mapStateToProps, null)(RenderCounter);
