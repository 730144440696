import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import './DetailFrame.scss';
import DetailTitle from '../../headers/detailTitle/DetailTitle';
import MultiDeviceLayout from '../../app/MultiDeviceLayout';

const DetailFrameComponent = (props) => {
    const [_hidden, setHidden] = useState(false);

    useEffect(() => {
        if (props.hidden !== undefined) {
            setHidden(props.hidden);
            if (props.onHide) {
                props.onHide(props.hidden);
            }
        }
    }, [props.hidden]);

    if (!props.children) return null;
    if (props.hide) return null;
    return (
        <div className={`detail-frame ${props.device} ${props.className ? props.className : ''}`}>
            <div className={`detail-frame-header${props.private ? ' private' : ''}`}>
                {
                    props.private
                        && (
                            <div className="lock-icon">
                                <div className="shape circle">
                                    <span className="glyphicon glyphicon-eye-close" />
                                </div>
                            </div>
                        )
                }
                {
                    (props.titleRenderer && props.titleRenderer())
                    || (
                        <>
                            <DetailTitle
                                title={props.title}
                            />
                            {
                                props.hidable
                                && (
                                    <div
                                        className="hidden-content-action"
                                        onClick={() => {
                                            const res = !_hidden;
                                            setHidden(res);
                                            if (props.onHide) {
                                                props.onHide(res);
                                            }
                                        }}
                                    >
                                        <div>{_hidden ? i18n.t('Show') : i18n.t('Hide')}</div>
                                        <div className="arrow-container">
                                            <div className={_hidden ? 'arrow-direction down' : 'arrow-direction up'} />
                                        </div>
                                    </div>
                                )
                            }
                        </>
                    )
                }
            </div>
            {
                !_hidden
                && (
                    <div className="detail-frame-body">
                        <div className="vertical-decorator" />
                        <div className="detail-frame-content">
                            {props.children}
                        </div>
                    </div>
                )
            }
        </div>
    );
};

const DetailFrame = (props) => (
    <MultiDeviceLayout>
        <DetailFrameComponent {...props} />
    </MultiDeviceLayout>
);

export default DetailFrame;
