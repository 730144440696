import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { companiesSettingsSelectors } from 'railfleet_state/ducks/companies_settings';
import { useFormContext } from 'react-hook-form';
import FormDropdown from '../formDropdown/FormDropdown';
import { THEMES } from '../../../filters/selectFilter/SelectFilter';
import MultiDeviceLayout, { ContextDevice } from '../../../app/MultiDeviceLayout';
import CutForm from '../cutForm/CutForm';
import FormTextInput from '../formTextInput/FormTextInput';
import FormBoolean from '../formBoolean/FormBoolean';
import FormDateTime from '../formDateTime/FormDateTime';
import './FormCustomField.scss';
import WithLoginless from '../../../app/withLoginless';
import usePerm from '../../../hooks/usePerm';
import NoFields from './NoFields.svg';

const getWidget = (type) => {
    switch (type) {
    case 'enum':
        return FormDropdown;
    case 'text':
        return FormTextInput;
    case 'int':
        return FormTextInput;
    case 'bool':
        return FormBoolean;
    case 'date':
        return FormDateTime;
    default:
        return FormTextInput;
    }
};

const getDefaultValue = (field, type, key, _raw_value, prev_value) => {
    let raw_value = _raw_value;
    if (!raw_value && field.default_value !== null) {
        raw_value = field.default_value;
    }
    if (type === 'date') return moment(raw_value);
    return raw_value || prev_value;
};

const renderCustomFields = (props, fields) => {
    const { watch } = useFormContext();
    const { device } = useContext(ContextDevice);
    const hasPerm = usePerm(props.asset || null);
    if (!fields) return null;
    const temp = {};
    _.forEach(fields, (field) => {
        const Widget = getWidget(field.data_type);
        const id = props.namer(field.key);
        const defaultValue = getDefaultValue(
            field,
            field.data_type,
            field.key,
            props.defaultValue && props.defaultValue[field.key],
            watch(id),
        );
        if (!hasPerm(field.permission_string)) return null;
        const hint = props.hint
            && field[props.hint]
            && field[props.hint][props.user.language];
        const widget = (
            <Widget
                key={id}
                id={id}
                label={field.label}
                values={
                    _.filter(field.values, (elm) => !elm.asset_class || elm.asset_class === props.asset_class)
                }
                defaultValue={defaultValue}
                id_getter={(a) => a.value}
                labelizer={(a) => a.label}
                clearable
                theme={THEMES.FORM_LIGHT}
                required={field.required}
                multi={field.multi}
                large={field.small === false}
                fillFlex
                hint={hint}
                sort={props.sort}
                sort_func={props.sort_func}
            />
        );
        const order = field.order || id;
        if (temp.hasOwnProperty(order)) {
            temp[order].push(widget);
        } else {
            temp[order] = [widget];
        }
        return widget;
    });

    const sort_func = (a, b) => {
        const sa = parseInt(a, 10);
        const sb = parseInt(b, 10);
        return -(sa < sb) || +(sa !== sb);
    };
    const sorted_keys = props.ordered ? Object.keys(temp).sort(sort_func) : Object.keys(temp);
    let newFields = [];
    _.forEach(sorted_keys, (key) => {
        newFields = [...newFields, ...temp[key]];
    });
    return (
        <CutForm
            device={device}
            size={props.size}
        >
            { newFields }
        </CutForm>
    );
};

const _FormCustomField = (props) => {
    useEffect(() => {
        if (props.callbackEmptyField) {
            if (!props.fields || props.fields.length === 0) {
                props.callbackEmptyField(true);
            } else {
                props.callbackEmptyField(false);
            }
        }
    }, [props.fields, props.callbackEmptyField]);
    const fields = renderCustomFields(props, props.fields);
    if (!props.fields || props.fields.length === 0) {
        if (!props.noFieldText) return null;
        return (
            <div className="field no-fields">
                <img src={NoFields} alt="ok logo" />
                <div>{props.noFieldText}</div>
            </div>
        );
    }
    return (
        <>
            <div className={`form_custom_fields ${props.className ? props.className : ''} ${props.id}`} key={props.id}>
                { fields }
            </div>
            { props.children }
        </>
    );
};

const mapStateToProps = (state, props) => {
    if (props.loginless) return props.mapStateToProps(state, props);
    const user = state.users.me;
    if (props.loginless) {
        const new_state = props.mapStateToProps(state, props);
        return {
            ...new_state,
            fields: props.public ? new_state.public_fields : new_state.private_fields,
        };
    }
    const asset_id = props.asset;
    let content_options = null;
    if (props.content_options) {
        if (Array.isArray(props.content_options)) {
            content_options = props.content_options;
        } else {
            const str_content = `${props.content_options}`;
            content_options = [str_content];
        }
    }
    let fields;
    if (props.public) {
        if (asset_id) {
            let publicFieldConfigSelector;
            if (props.content_type) {
                if (!content_options) {
                    // eslint-disable-next-line max-len
                    publicFieldConfigSelector = assetsSelectors.makePublicFieldsForAssetWithContentType(
                        props.content_type,
                    );
                } else {
                    // eslint-disable-next-line max-len
                    publicFieldConfigSelector = assetsSelectors.makePublicFieldsForAssetWithContentTypeAndOptions(
                        props.content_type,
                        content_options,
                    );
                }
            } else {
                publicFieldConfigSelector = assetsSelectors.makePublicFieldsForAsset();
            }
            fields = publicFieldConfigSelector(state, asset_id);
        }
    } else if (props.content_type) {
        if (!props.content_options) {
            fields = companiesSettingsSelectors.getPrivateFieldsForUserWithContentType(
                props.content_type,
            )(state);
        } else {
            fields = companiesSettingsSelectors.getPrivateFieldsForUserWithContentTypeAndOptions(
                props.content_type,
                content_options,
            )(state);
        }
    } else {
        fields = companiesSettingsSelectors.getPrivateFieldsForUser(state);
    }
    return {
        fields,
        user,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    if (props.loginless) return props.mapDispatchToProps(dispatch, props);
    return ({});
};

_FormCustomField.defaultProps = {
    nb_parts: 3,
    namer: (id) => id,
};

const FormCustomField = (props) => (
    <MultiDeviceLayout noClone>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <_FormCustomField
            {...props}
        />
    </MultiDeviceLayout>
);

export default WithLoginless(connect(mapStateToProps, mapDispatchToProps)(FormCustomField));
