import React from 'react';
import PropTypes from 'prop-types';

// Creating HOC for uirl syncing based on fields

const getParamsFromHash = (hash) => {
    let useful_hash = hash;
    if (useful_hash.startsWith('#/')) {
        useful_hash = useful_hash.slice(2);
    }
    const out = {};
    const params = useful_hash.split('&');
    for (const param of params) {
        const splitted_params = param.split('=');
        if (splitted_params.length === 2) {
            if (splitted_params[0].startsWith('?')) {
                splitted_params[0] = splitted_params[0].slice(1);
            }
            const key = splitted_params[0];
            out[decodeURIComponent(key)] = decodeURIComponent(splitted_params[1]);
        }
    }
    return out;
};

const mergeParams = (params) => {
    const match_params = [];
    for (const param in params) {
        if (!params.hasOwnProperty(param)) continue;
        const value = params[param];
        match_params.push(`${param}=${value}`);
    }
    return match_params;
};

const paramsToUrlHash = (params) => {
    const match_params = mergeParams(params);
    if (match_params.length > 1) {
        return `#/?${match_params.join('&')}`;
    }
    return `#/?${match_params.join('&')}`;
};

class UrlParamSync extends React.Component {
    constructor(props) {
        super(props);
        // load params from url
        const params = getParamsFromHash(window.location.hash);
        this.state = { params };
        this.hashChanged = this.hashChanged.bind(this);
        this.setParams = this.setParams.bind(this);
    }

    setParams(new_params) {
        const params = { ...new_params };
        this.setState({ params }, () => {
            window.location.hash = paramsToUrlHash(params);
        });
    }

    hashChanged(e) {
        const new_hash = e.newURL.substring(e.newURL.indexOf('#/') + 2);
        const params = getParamsFromHash(new_hash);
        const new_params = {};
        const state_params = this.state.params;
        let changed = false;
        for (const param_key in params) {
            if (!params.hasOwnProperty(param_key)) continue;
            const param_value = params[param_key];
            if (!state_params.hasOwnProperty(param_key)
                || state_params[param_key] !== param_value
            ) {
                new_params[param_key] = param_value;
                changed = true;
            } else {
                new_params[param_key] = state_params[param_key];
            }
        }
        if (changed) {
            this.setParams(new_params);
        }
    }

    componentDidMount() {
        if (!this.props.unidirectional) {
            window.addEventListener('hashchange', this.hashChanged);
        }
        const new_values = { ...this.state.params };
        let set = false;
        for (const default_key in this.props.defaults) {
            if (!this.props.defaults.hasOwnProperty(default_key)
                || new_values.hasOwnProperty(default_key)) {
                continue;
            }
            new_values[default_key] = this.props.defaults[default_key];
            set = true;
        }
        if (set) {
            this.setParams(new_values);
        }
    }

    componentWillUnmount() {
        if (!this.props.unidirectional) {
            window.removeEventListener('hashchange', this.hashChanged);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.params !== this.state.params;
    }

    render() {
        return React.cloneElement(
            this.props.children,
            { params: this.state.params, setParams: this.setParams },
        );
    }
}

UrlParamSync.propTypes = {
    defaults: PropTypes.object,
};

UrlParamSync.defaultProps = {
    defaults: {},
};

export {
    UrlParamSync, getParamsFromHash, paramsToUrlHash, mergeParams,
};
