import { createSelector } from 'reselect';

const getUXState = (state) => state.ux;

export const getPushInfos = createSelector(
    [getUXState],
    (ux) => ux.push_infos,
);

export default {
    getPushInfos,
};
