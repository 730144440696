import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './SearchFilter.scss';

const SearchFilter = (props) => {
    const [_focus, setFocus] = useState(false);
    const [_hover, setHover] = useState(false);

    useEffect(() => {
        if (props.defaultValue && props.onChange) {
            props.onChange(props.defaultValue);
        }
    }, [props.defaultValue]);

    const onChange = (evt) => {
        if (props.onChange) {
            props.onChange(evt.target.value);
        }
    };

    const onFocus = () => {
        setFocus(true);
    };

    const onBlur = () => {
        setFocus(false);
    };

    const onHover = () => {
        setHover(true);
    };

    const onLeave = () => {
        setHover(false);
    };

    const checkEnter = (evt) => {
        if (evt.key === 'Enter' && props.onEnterKey) {
            props.onEnterKey();
        }
    };

    return (
        <div className="search-field" onMouseEnter={onHover} onMouseLeave={onLeave}>
            <div
                className={`prepand-block ${_focus ? 'focus' : ''} ${_hover ? 'hover' : ''} ${props.disabled ? 'disabled' : ''}`}
            >
                <span className="glyphicon glyphicon-search" />
            </div>
            <input
                value={props.value}
                defaultValue={props.defaultValue}
                type="text"
                size={1}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                placeholder={props.placeholder}
                disabled={props.disabled}
                className={`${_focus ? 'focus' : ''} ${_hover ? 'hover' : ''}`}
                onKeyDown={checkEnter}
            />
        </div>
    );
};

SearchFilter.defaultProps = {
    placeholder: 'Search...',
    disabled: false,
};

SearchFilter.propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    is_fetching: PropTypes.bool,
    disabled: PropTypes.bool,
    onEnterKey: PropTypes.func,
};

export default SearchFilter;
